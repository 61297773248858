import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Box,
  Button,
  TextField as MaterialTextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DetailActions from "design/molecules/detail-actions";
import DetailHeader from "design/molecules/detail-header";
import NextButton from "design/atoms/next-button";
import PrevButton from "design/atoms/prev-button";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { usePermissions } from "features/roles";
import {
  pricingProfilesSelector,
  filteredPricingProfilesSelector,
  getPricingProfiles,
  deletePricingProfileById,
  updatePricingProfile,
} from "features/pricing-profiles";
import { useAppDispatch } from "features/store";
import UsersList from "../_components/users-list";
import ProductsList from "../_components/products-list";
import {
  productsSelector,
  productsLoadingSelector,
  getProducts,
} from "features/products";
import { usersSelector, usersLoadingSelector, getUsers } from "features/users";
import * as T from "types/engine-types";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

type Params = {
  id: string;
};

export default function ViewPricingProfilePage() {
  const { id } = useParams<Params>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const pricingProfiles = useSelector(filteredPricingProfilesSelector);
  const pricingProfile = pricingProfiles.find((r) => r.id === id);
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState<string>("");
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [cancelConfirmOpen, setCancelConfirmOpen] = useState<boolean>(false);
  const hasPermission = usePermissions();
  const hasEditPerm = hasPermission("pricing-profiles-edit");
  const hasDeletePerm = hasPermission("pricing-profiles-delete");
  const pricingProfilesState = useSelector(pricingProfilesSelector);
  const { errors, loading } = pricingProfilesState;
  const positionInList =
    pricingProfile && pricingProfiles.indexOf(pricingProfile) + 1;
  const usersState = useSelector(usersSelector);
  const productsState = useSelector(productsSelector);
  const productsLoading = useSelector(productsLoadingSelector);
  const usersLoading = useSelector(usersLoadingSelector);
  const [usersToAdd, setUsersToAdd] = useState<T.DetailedUser[]>([]);
  const [productsToAdd, setProductsToAdd] = useState<T.ProductHeader[]>([]);
  const {
    myPricingProfile,
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);

  useEffect(() => {
    if (!pricingProfiles || pricingProfiles.length === 0)
      dispatch(getPricingProfiles());
  }, [dispatch, pricingProfiles, pricingProfiles.length]);

  useEffect(() => {
    pricingProfile?.name && setName(pricingProfile.name);
  }, [pricingProfile, editMode]);

  useEffect(() => {
    if (!usersLoading) dispatch(getUsers());
  }, [dispatch, usersLoading]);

  useEffect(() => {
    if (!productsLoading) dispatch(getProducts());
  }, [dispatch, productsLoading]);

  if (loading) {
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert>Loading</MuiAlert>
      </Snackbar>
    );
  } else if (!pricingProfile) {
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert severity="error">This pricing profile was not found</MuiAlert>
      </Snackbar>
    );
  } else {
    return (
      <>
        {errors && (
          <Snackbar
            open={true}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert severity="error">{errors}</MuiAlert>
          </Snackbar>
        )}
        <DetailActions>
          {editMode ? (
            <>
              <Button
                className="cancel-pricingProfile"
                variant="outlined"
                startIcon={<CloseIcon />}
                onClick={() => {
                  setCancelConfirmOpen(true);
                }}
              >
                Cancel
              </Button>
              <Box flex="1" />

              <Dialog
                open={cancelConfirmOpen}
                onClose={() => setCancelConfirmOpen(false)}
              >
                <DialogTitle>
                  Cancel Editing {pricingProfile?.name} Pricing Profile
                </DialogTitle>

                <DialogContent>
                  <DialogContentText>
                    <strong>Warning!</strong> All unsaved progress will be lost.
                    Click "Cancel" to finish editing the pricing profile, or "Go
                    to Pricing Profiles View" to abandon edits.
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button onClick={() => setCancelConfirmOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setEditMode(false);
                      setCancelConfirmOpen(false);
                    }}
                  >
                    Go to {pricingProfile?.name}
                  </Button>
                </DialogActions>
              </Dialog>

              <Button
                className="save-pricing-profile"
                variant="outlined"
                startIcon={<DoneIcon />}
                onClick={async () => {
                  if (editMode) {
                    dispatch(
                      updatePricingProfile({
                        pricingProfile: {
                          id: pricingProfile && pricingProfile.id,
                          changeset: {
                            name,
                          },
                        },
                        usersToAdd,
                        productsToAdd,
                      }),
                    )
                      .then(unwrapResult)
                      .then((status: "error" | "success") => {
                        if (status === "success") {
                          dispatch(getPricingProfiles());
                          setEditMode(false);
                        }
                      });
                  }
                }}
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => history.push(`/c/${accessId}/pricing-profiles`)}
              >
                Back to Pricing Profiles ({positionInList}/
                {pricingProfiles.length})
                {pricingProfilesState.searchTerm && (
                  <span style={{ fontWeight: 100, textTransform: "none" }}>
                    , filtered: {pricingProfilesState.searchTerm}
                  </span>
                )}
              </Button>

              <PrevButton
                list={pricingProfiles}
                path={`/c/${accessId}/pricing-profiles`}
                id={id}
                label="Previous Profile"
              />

              <Button
                className="current-pricing-profile"
                style={{ cursor: "default", background: "transparent" }}
                variant="text"
              >
                {pricingProfile?.name}
              </Button>

              <NextButton
                list={pricingProfiles}
                path={`/c/${accessId}/pricing-profiles`}
                id={id}
                label="Next Profile"
              />

              <Box flex="1" />

              <Button
                disabled={!hasEditPerm}
                className="edit-pricing-profile"
                variant="outlined"
                startIcon={<EditIcon />}
                onClick={() => setEditMode(true)}
              >
                Edit
              </Button>

              <Button
                disabled={
                  !hasDeletePerm ||
                  myPricingProfile?.id === id ||
                  pricingProfiles.length === 1
                }
                className="delete-pricing-profile"
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={() => setDeleteConfirmOpen(true)}
              >
                Delete
              </Button>

              <Dialog
                open={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
              >
                <DialogTitle>
                  Deleting {pricingProfile?.name} Pricing Profile
                </DialogTitle>

                <DialogContent>
                  <DialogContentText>
                    <strong>Warning!</strong> Deletion is permanent. If you've
                    clicked Delete by mistake you can click "Cancel" below.
                    Otherwise click "Continue with Deletion" to proceed.
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button onClick={() => setDeleteConfirmOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      if (pricingProfile?.id) {
                        setDeleteConfirmOpen(false);
                        dispatch(deletePricingProfileById(pricingProfile.id))
                          .then(unwrapResult)
                          .then((status: "error" | "success") => {
                            if (status === "success") {
                              history.push(`/c/${accessId}/pricing-profiles`);
                            }
                          });
                      }
                    }}
                  >
                    Continue with Deletion
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </DetailActions>

        <DetailHeader style={{ padding: "0 8px" }}>
          {editMode ? (
            <MaterialTextField
              autoFocus={true}
              style={{ margin: "16px 0", width: "500px" }}
              label="Profile Name"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          ) : (
            <Typography variant="h4">{pricingProfile?.name}</Typography>
          )}
        </DetailHeader>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: "1 1 auto",
            overflowX: "scroll",
          }}
        >
          <UsersList
            users={usersState.users}
            createMode={false}
            editMode={editMode}
            pricingProfileId={pricingProfile.id}
            usersToAdd={usersToAdd}
            setUsersToAdd={setUsersToAdd}
          />
          <ProductsList
            products={productsState.products ? productsState.products : []}
            createMode={false}
            editMode={editMode}
            pricingProfileId={pricingProfile.id}
            productsToAdd={productsToAdd}
            setProductsToAdd={setProductsToAdd}
          />
        </div>
      </>
    );
  }
}
