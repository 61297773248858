import * as T from "types/engine-types";
import { Map as IMap, OrderedMap as IOrderedMap } from "immutable";
import { Snapshot } from "..";

export function getAllFieldIdsByKey(
  snapshot: Snapshot,
  applicationFieldsByKey: IOrderedMap<
    string,
    T.RawCreditApplicationFieldDefinition
  >,
  productFieldsByKey: IOrderedMap<string, T.RawProductFieldDefinition>,
): IMap<string, T.FieldId> {
  const snapshotMap = IMap(
    Array.from(snapshot.config.allFieldsById.values()).map((f) => [f.id, f.id]),
  );
  const applicationFieldsMap = IMap(applicationFieldsByKey.map((k) => k.id));
  const productFieldsMap = IMap(productFieldsByKey.map((k) => k.id));

  return snapshotMap.merge(applicationFieldsMap).merge(productFieldsMap);
}
