import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, TextField } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  nonNullApplicationInitializationSelector,
  expandedConfigSelector,
} from "features/application-initialization";

import { Dropdown, SearchableDropdown } from "design/molecules/dropdown";
import * as T from "types/engine-types";
import * as FieldValueTypes from "types/field-value-types";
import {
  resolveEnum,
  Setter,
  UiValidationError,
  unreachable,
  usePropertySetter,
  validation,
} from "features/utils";

export type FieldValueTypeState = {
  fieldType: FieldType | null;
  enumState: T.FieldValueType.Enum | null;
  objectRefState: T.FieldValueType.ObjectRef | null;
  numberState: T.FieldValueType.Number;
  durationState: T.FieldValueType.Duration;
  dateState: T.FieldValueType.Date;
};

export type FieldType =
  | "enum"
  | "number"
  | "string"
  | "duration"
  | "date"
  | "object-ref"
  | "us-state-code"
  | "us-county-code"
  | "header";

const FIELD_TYPES: readonly FieldType[] = [
  "enum",
  "object-ref",
  "number",
  "string",
  "duration",
  "date",
  "us-state-code",
  "us-county-code",
  "header",
] as const;

export function newFieldValueTypeState(): FieldValueTypeState {
  return {
    fieldType: null,
    enumState: null,
    objectRefState: null,
    numberState: {
      type: "number",
      minimum: null,
      maximum: null,
      precision: 2,
      style: "plain",
    },
    durationState: {
      type: "duration",
      minimumDays: null,
      maximumDays: null,
      minimumMonths: null,
      maximumMonths: null,
    },
    dateState: {
      type: "date",
    },
  };
}

export function convertFieldValueTypeToState(
  valueType: T.FieldValueType,
): FieldValueTypeState {
  const state = newFieldValueTypeState();

  switch (valueType.type) {
    case "enum":
      state.fieldType = "enum";
      state.enumState = valueType;
      break;
    case "object-ref":
      state.fieldType = "object-ref";
      state.objectRefState = valueType;
      break;
    case "string":
      switch (valueType.format) {
        case "plain":
          state.fieldType = "string";
          break;
        case "us-state-code":
          state.fieldType = "us-state-code";
          break;
        case "us-county-code":
          state.fieldType = "us-county-code";
          break;
      }
      break;
    case "number":
      state.fieldType = "number";
      state.numberState = valueType;
      break;
    case "duration":
      state.fieldType = "duration";
      state.durationState = valueType;
      break;
    case "date":
      state.fieldType = "date";
      state.dateState = valueType;
      break;
    case "header":
      state.fieldType = "header";
      break;
    default:
      return unreachable(valueType);
  }

  return state;
}

export function convertStateToFieldValueType(
  state: FieldValueTypeState,
): T.FieldValueType {
  if (state.fieldType === null) {
    throw new UiValidationError("Data type required");
  }

  switch (state.fieldType) {
    case "enum":
      if (!state.enumState) {
        throw new UiValidationError("Select an enumeration type");
      }

      return state.enumState;
    case "object-ref":
      if (!state.objectRefState) {
        throw new UiValidationError("Select an object type");
      }

      return state.objectRefState;
    case "string":
      return {
        type: "string",
        format: "plain",
      };
    case "us-state-code":
      return {
        type: "string",
        format: "us-state-code",
      };
    case "us-county-code":
      return {
        type: "string",
        format: "us-county-code",
      };
    case "number":
      return state.numberState;
    case "duration":
      return state.durationState;
    case "date":
      return state.dateState;
    case "header":
      return {
        type: "header",
      };
    default:
      return unreachable(state.fieldType);
  }
}

export const validateFieldValueTypeState = validation(
  convertStateToFieldValueType,
);

const useStyles = makeStyles((t) =>
  createStyles({
    fieldType: {
      margin: t.spacing(1, 0),
      width: 400,
    },
    enumType: {
      margin: t.spacing(2, 0),
      width: 400,
    },
    objectType: {
      margin: t.spacing(2, 0),
      width: 400,
    },
    numberStyle: {
      margin: t.spacing(1, 0),
      width: 200,
    },
    numberPrecision: {
      margin: t.spacing(1, 0),
      width: 200,
    },
  }),
);

export const FieldValueTypeDisplay = React.memo(
  ({
    state,
    showErrors,
    allowPricingProfiles,
    enumTypes,
  }: {
    state: FieldValueTypeState;
    showErrors: boolean;
    allowPricingProfiles: boolean;
    enumTypes: readonly T.RawEnumType[];
  }) => {
    const C = useStyles();
    const config = useSelector(expandedConfigSelector);
    const { client } = useSelector(nonNullApplicationInitializationSelector);

    const filteredObjectTypes = FieldValueTypes.ALL_OBJECT_TYPES.filter((t) => {
      // For now, there's only one object type that can appear on one screen and
      // not the other (this control is used by both the data table creator and
      // the field library, and pricing profiles should only be availabe for data
      // tables). If more are needed in the future, expand this filter with more
      // types and boolean flags.
      if (t === "pricing-profile" && !allowPricingProfiles) return false;
      return true;
    });

    const getEnumTypeNameById = useCallback(
      (id: T.EnumTypeId | null | undefined) => {
        const enumType = enumTypes.find((e) => e.id === id);
        return enumType !== undefined
          ? resolveEnum(
              enumType,
              config.systemEnumTypesById,
              client.displayNewInheritedEnumVariants,
            ).name
          : null;
      },
      [enumTypes, config, client],
    );

    const enumTypeName = getEnumTypeNameById(state.enumState?.enumTypeId);

    return (
      <Box>
        <TextField
          disabled
          className={C.fieldType}
          label="Data Type"
          value={
            state.fieldType ? fieldTypeToString(state.fieldType) : "<Unknown>"
          }
          variant="outlined"
        />
        {state.fieldType === "number" && (
          <Box display="flex">
            <TextField
              disabled
              className={C.numberStyle}
              label="Number Format"
              value={FieldValueTypes.getNumberFieldStyleName(
                state.numberState.style,
              )}
              variant="outlined"
            />
            <Box pl={2} />
            <TextField
              disabled
              className={C.numberPrecision}
              InputLabelProps={{ shrink: true }}
              label="Precision"
              title="Number of digits after the decimal point"
              variant="outlined"
              value={state.numberState.precision}
            />
          </Box>
        )}
        {state.fieldType === "enum" && (
          <TextField
            disabled
            className={C.enumType}
            label="Enumeration"
            value={enumTypeName !== null ? enumTypeName : "<Unknown>"}
            variant="outlined"
            error={
              showErrors && (state.enumState === null || enumTypeName === null)
            }
          />
        )}
        {state.fieldType === "object-ref" && filteredObjectTypes.length > 0 && (
          <TextField
            disabled
            className={C.objectType}
            label="Object Type"
            value={
              state.objectRefState ? state.objectRefState.objectType : null
            }
            variant="outlined"
          />
        )}
      </Box>
    );
  },
);

export const FieldValueTypeEditor = React.memo(
  ({
    state,
    showErrors,
    setState,
    enumTypes,
    sortEnumTypeToTop,
    allowHeaders,
    allowPricingProfiles,
  }: {
    state: FieldValueTypeState;
    showErrors: boolean;
    setState: Setter<FieldValueTypeState>;
    enumTypes: readonly T.RawEnumType[];
    sortEnumTypeToTop?: (enumType: T.RawEnumType) => boolean;
    allowHeaders: boolean;
    allowPricingProfiles: boolean;
  }) => {
    const C = useStyles();
    const { client } = useSelector(nonNullApplicationInitializationSelector);
    const config = useSelector(expandedConfigSelector);

    const sortedEnumTypeIds = useMemo(() => {
      return _.sortBy(enumTypes, [
        (enumType) =>
          sortEnumTypeToTop ? (sortEnumTypeToTop(enumType) ? 0 : 1) : 0,
        (enumType) =>
          resolveEnum(
            enumType,
            config.systemEnumTypesById,
            client.displayNewInheritedEnumVariants,
          ).name.toLowerCase(),
      ]).map((e) => e.id);
    }, [enumTypes, sortEnumTypeToTop, config, client]);

    const getEnumTypeNameById = useCallback(
      (id: T.EnumTypeId) => {
        const enumType = enumTypes.find((e) => e.id === id);
        return enumType !== undefined
          ? resolveEnum(
              enumType,
              config.systemEnumTypesById,
              client.displayNewInheritedEnumVariants,
            ).name
          : "<Unknown>";
      },
      [enumTypes, config, client],
    );

    const setFieldType = usePropertySetter(setState, "fieldType");
    const setNumberState = usePropertySetter(setState, "numberState");
    const setNumberStyle = usePropertySetter(setNumberState, "style");
    const setNumberPrecision = usePropertySetter(setNumberState, "precision");
    const setEnumState = usePropertySetter(setState, "enumState");
    const setObjectRef = usePropertySetter(setState, "objectRefState");

    const handleNumberPrecisionChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (!isNaN(+e.target.value) && +e.target.value >= 0) {
          setNumberPrecision(+e.target.value);
        }
      },
      [setNumberPrecision],
    );

    const unknownEnumTypeId =
      !!state.enumState &&
      !sortedEnumTypeIds.includes(state.enumState.enumTypeId);

    const setEnumTypeId = useCallback(
      (enumTypeId: T.EnumTypeId | null) => {
        if (enumTypeId) {
          setEnumState({ type: "enum", enumTypeId });
        } else {
          setEnumState(null);
        }
      },
      [setEnumState],
    );

    const setObjectType = useCallback(
      (objectType: T.ObjectType | null) => {
        if (objectType) {
          setObjectRef({ type: "object-ref", objectType });
        } else {
          setObjectRef(null);
        }
      },
      [setObjectRef],
    );

    const filteredObjectTypes = FieldValueTypes.ALL_OBJECT_TYPES.filter((t) => {
      // For now, there's only one object type that can appear on one screen and
      // not the other (this control is used by both the data table creator and
      // the field library, and pricing profiles should only be availabe for data
      // tables). If more are needed in the future, expand this filter with more
      // types and boolean flags.
      if (t === "pricing-profile" && !allowPricingProfiles) return false;
      return true;
    });

    // Don't show Object as a field type option if there are no object types
    // available to select.
    const filteredFieldTypes =
      filteredObjectTypes.length > 0
        ? FIELD_TYPES
        : FIELD_TYPES.filter((t) => t !== "object-ref");

    return (
      <Box>
        <SearchableDropdown<FieldType>
          className={C.fieldType}
          label="Data Type"
          options={
            allowHeaders
              ? filteredFieldTypes
              : filteredFieldTypes.filter((t) => t !== "header")
          }
          getOptionLabel={fieldTypeToString}
          value={state.fieldType}
          error={showErrors && state.fieldType === null}
          setValue={setFieldType}
        />
        {state.fieldType === "number" && (
          <Box display="flex">
            <Dropdown<T.NumberFieldStyle>
              className={C.numberStyle}
              label="Number Format"
              options={FieldValueTypes.ALL_NUMBER_FIELD_STYLES}
              getOptionLabel={FieldValueTypes.getNumberFieldStyleName}
              value={state.numberState.style}
              setValue={setNumberStyle}
            />
            <Box pl={2} />
            <TextField
              className={C.numberPrecision}
              InputLabelProps={{ shrink: true }}
              label="Precision"
              title="Number of digits after the decimal point"
              variant="outlined"
              value={state.numberState.precision}
              onChange={handleNumberPrecisionChange}
            />
          </Box>
        )}
        {state.fieldType === "enum" && (
          <SearchableDropdown<T.EnumTypeId>
            className={C.enumType}
            label="Enumeration"
            options={sortedEnumTypeIds}
            getOptionLabel={getEnumTypeNameById}
            value={state.enumState ? state.enumState.enumTypeId : null}
            error={
              showErrors && (state.enumState === null || unknownEnumTypeId)
            }
            setValue={setEnumTypeId}
          />
        )}
        {state.fieldType === "object-ref" && filteredObjectTypes.length > 0 && (
          <SearchableDropdown<T.ObjectType>
            className={C.objectType}
            label="Object Type"
            options={filteredObjectTypes}
            getOptionLabel={FieldValueTypes.getObjectTypeName}
            value={
              state.objectRefState ? state.objectRefState.objectType : null
            }
            setValue={setObjectType}
          />
        )}
      </Box>
    );
  },
);

function fieldTypeToString(fieldType: FieldType): string {
  switch (fieldType) {
    case "enum":
      return "Enumeration";
    case "object-ref":
      return "Object";
    case "string":
      return "Text";
    case "us-state-code":
      return "US State Code (2-letter)";
    case "us-county-code":
      return "US County Code (5-digit)";
    case "number":
      return "Number";
    case "duration":
      return "Duration";
    case "date":
      return "Date";
    case "header":
      return "Header";
    default:
      return unreachable(fieldType);
  }
}
