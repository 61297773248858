import styled from "styled-components";
import { Link } from "react-router-dom";
import color from "design/subatomics/colors";
import whitespace from "design/subatomics/whitespace";
import fontSizes from "design/subatomics/font-sizes";
import colors from "design/subatomics/colors";
import { styles } from "design/atoms/typography";
import breakpoints from "design/subatomics/breakpoints";
import radiusOptions from "design/subatomics/corners";

export const IconA = styled.a`
  ${styles.link};
  color: ${colors({ color: "brandPrimary" })};

  text-align: center;
  display: block;

  .label {
    font-size: ${fontSizes.xs};
  }

  &.inline-icon {
    .icon {
      font-size: ${fontSizes.m};
      margin-right: ${whitespace("less")};
      display: inline-block;
    }

    .label {
      position: relative;
      top: -1px;
      font-size: ${fontSizes.s};
      display: inline-block;
    }
  }

  &:hover {
    ${styles.linkHover};
  }

  &:active,
  &:focus,
  &:focus-visible {
    color: ${colors({ color: "green", shade: 6 })};
  }

  ${breakpoints.mobile} {
    margin: ${whitespace("less")} 0;
    text-align: right;
    border: 1px solid ${color({ color: "lightBorder" })};

    &.hide-for-mobile {
      display: none;
    }

    .icon {
      font-size: ${fontSizes.m};
      margin-right: ${whitespace("less")};
      display: inline-block;
    }

    .label {
      font-size: ${fontSizes.s};
      display: inline-block;
      position: relative;
      top: -1px;
    }
  }

  ${breakpoints.desktop} {
    margin: ${whitespace()};

    .icon {
      font-size: 2.4rem;
    }

    &.hide-for-desktop {
      display: none;
    }
  }
`;

export const IconLink = styled(Link)`
  ${styles.link};
  color: ${colors({ color: "brandPrimary" })};
  text-align: center;
  display: block;

  .label {
    font-size: ${fontSizes.xs};
  }

  &.inline-icon {
    .icon {
      font-size: ${fontSizes.m};
      margin-right: ${whitespace("less")};
      display: inline-block;
    }

    .label {
      font-size: ${fontSizes.s};
      display: inline-block;
      position: relative;
      top: -1px;
    }
  }

  &:hover {
    ${styles.linkHover};
  }

  &:active,
  &:focus,
  &:focus-visible {
    color: ${colors({ color: "green", shade: 6 })};
  }

  ${breakpoints.mobile} {
    margin: ${whitespace("less")} 0;
    text-align: right;
    border: 1px solid ${color({ color: "lightBorder" })};

    &.hide-for-mobile {
      display: none;
    }

    .icon {
      font-size: ${fontSizes.m};
      margin-right: ${whitespace("less")};
      display: inline-block;
    }

    .label {
      font-size: ${fontSizes.s};
      display: inline-block;
      position: relative;
      top: -1px;
    }
  }

  ${breakpoints.desktop} {
    &.hide-for-desktop {
      display: none;
    }

    .icon {
      font-size: 2.4rem;
    }
  }
`;

export const IconSpan = styled.span`
  ${styles.link};
  color: ${colors({ color: "brandPrimary" })};

  text-align: center;
  display: block;

  .label {
    font-size: ${fontSizes.xs};
  }

  &.inline-icon {
    .icon {
      font-size: ${fontSizes.m};
      margin-right: ${whitespace("less")};
      display: inline-block;
    }

    .label {
      font-size: ${fontSizes.s};
      display: inline-block;
      position: relative;
      top: -1px;
    }
  }

  &:hover {
    ${styles.linkHover};
    color: ${colors({ color: "green", shade: 6 })};
  }

  &:active,
  &:focus,
  &:focus-visible {
    ${styles.linkActive};
  }

  ${breakpoints.mobile} {
    margin: ${whitespace("less")} 0;
    text-align: right;
    border: 1px solid ${color({ color: "lightBorder" })};
    border-radius: ${radiusOptions.medium};

    &.hide-for-mobile {
      display: none;
    }

    .icon {
      font-size: ${fontSizes.m};
      margin-right: ${whitespace("less")};
      display: inline-block;
    }

    .label {
      font-size: ${fontSizes.s};
      display: inline-block;
      position: relative;
      top: -1px;
    }
  }

  ${breakpoints.desktop} {
    &.hide-for-desktop {
      display: none;
    }

    .icon {
      font-size: 2.4rem;
    }
  }
`;
