import React from "react";
import * as Api from "api";

function Heartbeat(): JSX.Element {
  React.useEffect(() => {
    const interval = setInterval(() => {
      Api.heartbeat()
        .then(() => {
          console.log("heartbeat");
        })
        .catch((err: string) => {
          const error = new Error(`heartbeat failed: ${err}`);
          console.error(error);
          newrelic.noticeError(error);
        });
    }, 5 * 60 * 1000 /* every 5 minutes */);

    return () => {
      clearInterval(interval);
    };
  });

  return <></>;
}

export default Heartbeat;
