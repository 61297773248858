import * as T from "types/engine-types";

export function getPricingProfileKey(
  pricingProfile: T.PricingProfileHeader,
): string {
  return pricingProfile.name.toLowerCase();
}

export function getDataTableKey(dataTable: T.NewDataTable): string {
  return dataTable.name.toLowerCase();
}

export function getDataTableColumnKey(column: T.DataTableColumn): string {
  return column.name.toLowerCase();
}

export function getRuleKey(rule: T.NewRule): string {
  return rule.name.toLowerCase();
}
