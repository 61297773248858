import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Set as ISet, Map as IMap } from "immutable";
import _ from "lodash";
import { Box, Button, Paper, Tabs, Tab } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import * as Api from "api";
import { Configuration, getFieldsFromStage } from "config";
import * as T from "types/engine-types";
import {
  enumTypeNameToIdentifier,
  enumVariantNameToIdentifier,
  fieldNameToIdentifier,
  getNameFromId,
  getRawFieldStateName,
  getRawPipelineFieldStateName,
} from "features/formulas-util";
import {
  UiValidationError,
  resolveEnum,
  getErrorMessage,
  isPresent,
  unreachable,
} from "features/utils";
import { getRoles, usePermissions } from "features/roles";
import {
  LoggedInInfo,
  loadAppInit,
  nonNullApplicationInitializationSelector,
  expandedConfigSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import ErrorBoundary from "features/utils/error-boundary";
import {
  FieldValueTypeState,
  convertFieldValueTypeToState,
  convertStateToFieldValueType,
} from "design/organisms/field-value-type-editor";
import {
  ObjectListState,
  newObjectListState,
} from "design/organisms/object-list-editor";
import UnloadPrompt from "design/atoms/unload-prompt";
import {
  FieldConditionState,
  convertFieldConditionToState,
  convertStateToFieldCondition,
  convertIncludedFieldConditionToState,
  convertStateToIncludedFieldCondition,
} from "./_components/field-condition-editor";
import { PipelineFieldsEditor } from "./_components/pipeline-field-editor";
import { EnumerationsEditor } from "./_components/enums-editor";
import { FieldsEditor } from "./_components/fields-editor";

export type FieldDefinitionState = {
  oldId: T.FieldId | null;
  id: T.FieldId;
  disposition: FieldDispositionState;
  domainValidationErrors: {
    hasErrors: boolean;
    id: T.ServerValidationError[];
  };
};

type FieldDispositionState =
  | NativeFieldDispositionState
  | InheritedFieldDispositionState;

export type IncludedFieldConditionState = {
  id: T.FieldConditionId;
  domainValidationErrors: { hasErrors: boolean; id: T.ServerValidationError[] };
};

type PipelineFieldUnion =
  | ({ type: "pipeline-only" } & T.RawPipelineFieldDefinition)
  | { type: "referenced"; id: T.FieldId };

export type PipelineFieldState =
  | {
      type: "pipeline-only";
      oldId: T.FieldId | null;
      id: T.FieldId;
      disposition: FieldDispositionState;
      domainValidationErrors: {
        hasErrors: boolean;
        id: T.ServerValidationError[];
      };
    }
  | { type: "product-specifications"; id: T.FieldId }
  | { type: "credit-applications"; id: T.FieldId }
  | { type: "calculations"; id: T.FieldId };

export type NativeFieldDefinitionState = FieldDefinitionState & {
  disposition: { kind: "native" };
};

export type InheritedFieldDefinitionState = FieldDefinitionState & {
  disposition: { kind: "inherited" };
};

type NativeFieldDispositionState = {
  kind: "native";
  name: string;
  description: string;
  valueType: FieldValueTypeState;
  conditions: readonly FieldConditionState[];
  domainValidationErrors: {
    hasErrors: boolean;
    name: T.ServerValidationError[];
    description: T.ServerValidationError[];
  };
};

type InheritedFieldDispositionState = {
  kind: "inherited";
  nameAlias: string | null;
  descriptionAlias: string;
  includeConditions: ObjectListState<IncludedFieldConditionState>;
  additionalConditions: readonly FieldConditionState[];
  domainValidationErrors: {
    hasErrors: boolean;
    nameAlias: T.ServerValidationError[];
    descriptionAlias: T.ServerValidationError[];
  };
};

const { newrelic } = window;

function mapFieldDefinitionsValidation(
  states: readonly FieldDefinitionState[],
  validation: readonly T.RawCommonFieldDefinitionValidation[],
): FieldDefinitionState[] {
  return states.map((s, i) => mapFieldDefinitionValidation(s, validation[i]));
}

function mapFieldDefinitionValidation(
  state: FieldDefinitionState,
  validation: T.RawCommonFieldDefinitionValidation,
): FieldDefinitionState {
  return {
    oldId: state.oldId,
    id: state.id,
    disposition: mapFieldDispositionValidation(
      state.disposition,
      validation.disposition,
    ),
    domainValidationErrors: {
      hasErrors: validation.hasErrors,
      id: validation.id,
    },
  };
}

function mapFieldDispositionValidation(
  state: FieldDispositionState,
  validation: T.FieldDispositionValidation,
): FieldDispositionState {
  if (state.kind === "native" && validation.kind === "native") {
    return {
      kind: state.kind,
      name: state.name,
      description: state.description,
      valueType: state.valueType,
      conditions: state.conditions.map((c, i) =>
        mapFieldConditionValidation(c, validation.conditions[i]),
      ),
      domainValidationErrors: {
        hasErrors: validation.hasErrors,
        name: validation.name,
        description: validation.description,
      },
    };
  } else if (state.kind === "inherited" && validation.kind === "inherited") {
    return {
      ...state,
      includeConditions: {
        ...state.includeConditions,
        objects: state.includeConditions.objects.map((c, i) =>
          mapIncludeConditionValidation(c, validation.includeConditions[i]),
        ),
      },
      domainValidationErrors: {
        hasErrors: validation.hasErrors,
        nameAlias: validation.nameAlias,
        descriptionAlias: validation.descriptionAlias,
      },
    };
  }

  return {
    ...state,
  };
}

function mapFieldConditionValidation(
  state: FieldConditionState,
  validation: T.FieldConditionValidation,
): FieldConditionState {
  return {
    ...state,
    id: state.id,
    parentFieldId: state.parentFieldId,
    domainValidationErrors: {
      hasErrors: validation.hasErrors,
      id: validation.id,
      parentFieldId: validation.parentFieldId,
    },
  };
}

function mapIncludeConditionValidation(
  state: IncludedFieldConditionState,
  validation: T.IncludedFieldConditionValidation,
): IncludedFieldConditionState {
  return {
    id: state.id,
    domainValidationErrors: {
      hasErrors: validation.hasErrors,
      id: validation.id,
    },
  };
}

export type EnumTypeState = {
  oldId: T.EnumTypeId | null;
  id: T.EnumTypeId;
  disposition: EnumDispositionState;
  domainValidationErrors: {
    hasErrors: boolean;
    id: T.ServerValidationError[];
  };
};

export type EnumDispositionState =
  | NativeEnumDispositionState
  | InheritedEnumDispositionState;

export type NativeEnumState = EnumTypeState & {
  disposition: { kind: "native" };
};

export type InheritedEnumState = EnumTypeState & {
  disposition: { kind: "inherited" };
};

export type NativeEnumDispositionState = {
  kind: "native";
  name: string;
  variants: ObjectListState<EnumVariantState>;
  domainValidationErrors: {
    hasErrors: boolean;
    name: T.ServerValidationError[];
  };
};

export type InheritedEnumDispositionState = {
  kind: "inherited";
  nameAlias: string | null;
  includeVariants: ObjectListState<IncludedEnumVariantState>;
  excludeVariants: ObjectListState<ExcludedEnumVariantState>;
  domainValidationErrors: {
    hasErrors: boolean;
    nameAlias: T.ServerValidationError[];
  };
};

export type EnumVariantState = {
  oldId: T.EnumVariantId | null;
  id: T.EnumVariantId;
  name: string;
  domainValidationErrors: {
    hasErrors: boolean;
    id: T.ServerValidationError[];
    name: T.ServerValidationError[];
  };
};

export type IncludedEnumVariantState = {
  id: T.EnumVariantId;
  nameAlias: string | null;
  domainValidationErrors: {
    hasErrors: boolean;
    id: T.ServerValidationError[];
    nameAlias: T.ServerValidationError[];
  };
};

export type ExcludedEnumVariantState = {
  id: T.EnumVariantId;
  domainValidationErrors: {
    hasErrors: boolean;
    id: T.ServerValidationError[];
  };
};

function mapEnumTypesValidation(
  states: readonly EnumTypeState[],
  validations: readonly T.RawEnumTypeValidation[],
): EnumTypeState[] {
  return states.map((s, i) => mapEnumTypeValidation(s, validations[i]));
}

function mapEnumTypeValidation(
  state: EnumTypeState,
  validation: T.RawEnumTypeValidation,
): EnumTypeState {
  return {
    ...state,
    disposition: mapEnumDispositionValidation(
      state.disposition,
      validation.disposition,
    ),
    domainValidationErrors: {
      hasErrors: validation.hasErrors,
      id: validation.id,
    },
  };
}

function mapEnumDispositionValidation(
  state: EnumDispositionState,
  validation: T.EnumDispositionValidation,
): EnumDispositionState {
  if (state.kind === "native" && validation.kind === "native") {
    return {
      kind: "native",
      name: state.name,
      variants: {
        ...state.variants,
        objects: state.variants.objects.map((v, i) =>
          mapEnumVariantValidation(v, validation.variants[i]),
        ),
      },
      domainValidationErrors: {
        hasErrors: validation.hasErrors,
        name: validation.name,
      },
    };
  } else if (state.kind === "inherited" && validation.kind === "inherited") {
    return {
      kind: "inherited",
      nameAlias: state.nameAlias,
      includeVariants: {
        ...state.includeVariants,
        objects: state.includeVariants.objects.map((v, i) =>
          mapIncludedEnumVariantValidation(v, validation.includeVariants[i]),
        ),
      },
      excludeVariants: {
        ...state.excludeVariants,
        objects: state.excludeVariants.objects.map((v, i) =>
          mapExcludedEnumVariantValidation(v, validation.excludeVariants[i]),
        ),
      },
      domainValidationErrors: {
        hasErrors: validation.hasErrors,
        nameAlias: validation.nameAlias,
      },
    };
  }

  return {
    ...state,
  };
}

function mapEnumVariantValidation(
  state: EnumVariantState,
  validation: T.EnumVariantValidation,
): EnumVariantState {
  return {
    oldId: state.oldId,
    id: state.id,
    name: state.name,
    domainValidationErrors: {
      hasErrors: validation.hasErrors,
      id: validation.id,
      name: validation.name,
    },
  };
}

function mapIncludedEnumVariantValidation(
  state: IncludedEnumVariantState,
  validation: T.IncludedEnumVariantValidation,
): IncludedEnumVariantState {
  return {
    id: state.id,
    nameAlias: state.nameAlias,
    domainValidationErrors: {
      hasErrors: validation.hasErrors,
      id: validation.id,
      nameAlias: validation.nameAlias,
    },
  };
}

function mapExcludedEnumVariantValidation(
  state: ExcludedEnumVariantState,
  validation: T.ExcludedEnumVariantValidation,
): ExcludedEnumVariantState {
  return {
    id: state.id,
    domainValidationErrors: {
      hasErrors: validation.hasErrors,
      id: validation.id,
    },
  };
}

const useStyles = makeStyles((t) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      height: "100%",
    },
    paper: {
      flex: "1",
      margin: t.spacing(2),
    },
  }),
);

function convertStateToPipelineFieldDefinition(
  pipelineField: PipelineFieldState,
  fieldIndex: number,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
  existingFieldNames: ISet<string>,
  enumTypes: T.RawEnumType[] | null,
  allPipelineFieldIds: T.FieldId[],
  config: Configuration,
): PipelineFieldUnion {
  switch (pipelineField.type) {
    case "pipeline-only": {
      const name = getRawPipelineFieldStateName(
        pipelineField,
        inheritableFields,
        config,
      );

      if (name.trim() === "") {
        throw new UiValidationError("Field name is empty");
      }

      const lowerCaseIdentifier = fieldNameToIdentifier(name).toLowerCase();
      const existingFieldName = existingFieldNames.find(
        (field) =>
          fieldNameToIdentifier(field).toLowerCase() === lowerCaseIdentifier,
      );

      const existingPipelineField = allPipelineFieldIds.find(
        (field, index) => field === pipelineField.id && index !== fieldIndex,
      );

      if (existingFieldName) {
        if (existingFieldName.toLowerCase() === name.toLowerCase()) {
          throw new UiValidationError(
            `Duplicate field name "${existingFieldName}"`,
          );
        }

        throw new UiValidationError(
          `Field names "${name}" and "${existingFieldName}" are too similar.`,
        );
      }

      if (existingPipelineField) {
        if (existingPipelineField === pipelineField.id) {
          throw new UiValidationError(
            `Duplicate id "${existingPipelineField}"`,
          );
        }

        throw new UiValidationError(
          `Field ids "${name}" and "${existingPipelineField}" are too similar.`,
        );
      }

      let dataType: T.FieldValueType | undefined;
      if (pipelineField.disposition.kind === "native") {
        dataType = convertStateToFieldValueType(
          pipelineField.disposition.valueType,
        );
      } else {
        const inheritedField = inheritableFields.find(
          (f) => f.id === pipelineField.id,
        );
        if (!inheritedField) {
          throw new UiValidationError(
            `Attempting to inherit nonexistent field with ID '${pipelineField.id}'`,
          );
        }

        dataType = inheritedField.valueType;
      }

      if (dataType.type === "enum") {
        // For some reason this line is needed to make Typescript
        // realize that dataType is an enum type at this point.
        const enumDataType = dataType;
        const referencedEnum = enumTypes?.find((e) => {
          if (e.id !== enumDataType.enumTypeId) return false;
          if (
            pipelineField.disposition.kind === "inherited" &&
            e.disposition.kind !== "inherited"
          )
            return false;
          return true;
        });
        if (!referencedEnum) {
          if (pipelineField.disposition.kind === "native") {
            throw new UiValidationError(
              `Enumeration with ID '${enumDataType.enumTypeId}' does not exist. You must create or inherit this enumeration.`,
            );
          } else {
            throw new UiValidationError(
              `Inherited enumeration with ID '${enumDataType.enumTypeId}' does not exist. You must inherit this enumeration.`,
            );
          }
        }
      }

      switch (pipelineField.disposition.kind) {
        case "native": {
          return {
            type: "pipeline-only",
            oldId: pipelineField.oldId,
            id: pipelineField.id,
            disposition: {
              name: pipelineField.disposition.name,
              conditions: [],
              description: pipelineField.disposition.description,
              kind: "native",
              valueType: convertStateToFieldValueType(
                pipelineField.disposition.valueType,
              ),
            },
          };
        }
        case "inherited": {
          return {
            type: "pipeline-only",
            oldId: pipelineField.oldId,
            id: pipelineField.id,
            disposition: {
              kind: pipelineField.disposition.kind,
              nameAlias: pipelineField.disposition.nameAlias,
              descriptionAlias: pipelineField.disposition.descriptionAlias,
              includeConditions: [],
              additionalConditions: [],
            },
          };
        }
        default:
          return unreachable(pipelineField.disposition);
      }
    }
    case "calculations":
    case "product-specifications":
    case "credit-applications": {
      const name = getRawPipelineFieldStateName(
        pipelineField,
        inheritableFields,
        config,
      );

      const existingPipelineField = allPipelineFieldIds.find((field, index) => {
        const fieldName = getNameFromId(field, config);
        return fieldName === name && index !== fieldIndex;
      });

      const pipelineNameFromId = getNameFromId(
        existingPipelineField ? existingPipelineField : ("" as T.FieldId),
        config,
      );

      if (pipelineNameFromId) {
        if (pipelineNameFromId.toLowerCase() === name.toLowerCase()) {
          throw new UiValidationError(
            `Duplicate field name "${pipelineNameFromId}"`,
          );
        }

        throw new UiValidationError(
          `Field names "${name}" and "${pipelineNameFromId}" are too similar.`,
        );
      }

      if (!name) {
        throw new UiValidationError(`Please select a field`);
      }

      return { type: "referenced", id: pipelineField.id };
    }
    default:
      unreachable(pipelineField);
  }
}

function convertPipelineFieldDefinitionToState(
  field: T.RawPipelineFieldDefinition,
): PipelineFieldState {
  return field.disposition.kind === "native"
    ? {
        type: "pipeline-only",
        oldId: field.id,
        id: field.id,
        disposition: {
          kind: "native",
          name: field.disposition.name,
          description: field.disposition.description,
          valueType: convertFieldValueTypeToState(field.disposition.valueType),
          conditions: [],
          domainValidationErrors: {
            hasErrors: false,
            name: [],
            description: [],
          },
        } as NativeFieldDispositionState,
        domainValidationErrors: {
          hasErrors: false,
          id: [],
        },
      }
    : {
        type: "pipeline-only",
        oldId: field.id,
        id: field.id,
        disposition: {
          kind: "inherited",
          nameAlias: field.disposition.nameAlias,
          descriptionAlias: field.disposition.descriptionAlias,
          includeConditions: { objects: [], selectedIndex: 0 },
          additionalConditions: [],
          domainValidationErrors: {
            hasErrors: false,
            nameAlias: [],
            descriptionAlias: [],
          },
        } as InheritedFieldDispositionState,
        domainValidationErrors: {
          hasErrors: false,
          id: [],
        },
      };
}

export function convertPipelineFieldStateListItem(
  pipelineField: PipelineFieldState,
  fieldIndex: number,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
  enumTypes: T.RawEnumType[] | null,
  existingFieldNames: ISet<string>,
  pipelineState: ObjectListState<PipelineFieldState>,
  allPipelineFieldIds: T.FieldId[],
  config: Configuration,
): PipelineFieldUnion {
  const allExistingFieldNames = existingFieldNames.union(
    pipelineState.objects
      .filter((_, idx) => idx !== fieldIndex)
      .map((f) => getRawPipelineFieldStateName(f, inheritableFields, config)),
  );

  return convertStateToPipelineFieldDefinition(
    pipelineField,
    fieldIndex,
    inheritableFields,
    allExistingFieldNames,
    enumTypes,
    allPipelineFieldIds,
    config,
  );
}

function tryConvertPipelineFields(
  pipelineState: ObjectListState<PipelineFieldState>,
  allPipelineFieldIds: T.FieldId[],
  enumTypes: T.RawEnumType[] | null,
  existingFieldNames: ISet<string>,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
  config: Configuration,
):
  | [
      null,
      {
        pipelineOnlyFields: T.RawPipelineFieldDefinition[];
        pipelineFieldsState: T.FieldId[];
      },
    ]
  | [UiValidationError, null] {
  try {
    const pipelineStateArray = pipelineState.objects.map((field) => field.id);
    const newFields = _.isEqual(allPipelineFieldIds, pipelineStateArray)
      ? allPipelineFieldIds
      : pipelineStateArray;
    const newPipelineOnlyFields = pipelineState.objects.map(
      (field, fieldIndex) =>
        convertPipelineFieldStateListItem(
          field,
          fieldIndex,
          inheritableFields,
          enumTypes,
          existingFieldNames,
          pipelineState,
          allPipelineFieldIds,
          config,
        ),
    );

    const removeUndefined: T.RawPipelineFieldDefinition[] =
      newPipelineOnlyFields
        .filter((field) => field.type === "pipeline-only" && isPresent(field))
        .map((field) => field as T.RawPipelineFieldDefinition);

    return [
      null,
      {
        pipelineOnlyFields: removeUndefined,
        pipelineFieldsState: newFields.filter((field) => !!field),
      },
    ];
  } catch (err) {
    if (err instanceof UiValidationError) {
      newrelic.noticeError(err);
      return [err, null];
    }

    throw err;
  }
}

/**
 * Function for rebuilding the pipeline fields state in the ui based off of what's saved in config. Pipeline fields are saved in 2 places and must be aggregated before being validated by tryConvertPipelineFields
 * @param allPipelineFieldIds list of all pipeline field ids from config
 * @param savedProductFields list of all product fields from config
 * @param savedCreditApplicationFields list of all credit app fields from config
 * @param rawPipelineOnlyFields list of all pipeline-only fields from config
 * @returns
 */
const rebuildPipelineFields = (
  allPipelineFieldIds: T.FieldId[],
  savedProductFields: T.BaseFieldDefinition[],
  savedCreditApplicationFields: T.CreditApplicationFieldDefinition[],
  rawPipelineOnlyFields: T.RawPipelineFieldDefinition[],
): PipelineFieldState[] => {
  const productFields = allPipelineFieldIds.map((id) => {
    const field = savedProductFields.find((field) => field.id === id);
    if (field) {
      return {
        type: "product-specifications",
        id: field.id,
      } as PipelineFieldState;
    }
  });
  const uniqueProductFields = Array.from(new Set(productFields));

  const creditFields = allPipelineFieldIds.map((id) => {
    const field = savedCreditApplicationFields.find((field) => field.id === id);
    if (field) {
      return {
        type: "credit-applications",
        id: field.id,
      } as PipelineFieldState;
    }
  });
  const uniqueCreditFields = Array.from(new Set(creditFields));

  const calcFieldsMap = allPipelineFieldIds.filter((field) =>
    field.includes("calc"),
  );
  const calcFields = calcFieldsMap.map((calc) => {
    return { type: "calculations", id: calc } as PipelineFieldState;
  });

  const pipelineOnlyFields: PipelineFieldState[] = rawPipelineOnlyFields.map(
    convertPipelineFieldDefinitionToState,
  );

  const noUndefined = [
    ...uniqueProductFields,
    ...uniqueCreditFields,
    ...calcFields,
    ...pipelineOnlyFields,
  ].filter((field) => field) as PipelineFieldState[];

  return noUndefined.sort(
    (a, b) =>
      allPipelineFieldIds.indexOf(a.id) - allPipelineFieldIds.indexOf(b.id),
  );
};

function listEnumNamesExcludingFieldLibrary(
  config: Configuration,
): ISet<string> {
  return config.enumTypesById
    .deleteAll(config.enumTypes.map((e) => e.id))
    .valueSeq()
    .map((e) => e.name)
    .toSet();
}

function listFieldNamesExcludingFieldLibrary(
  config: Configuration,
): ISet<string> {
  return config.allFieldsById
    .deleteAll(config.creditApplicationFields.map((f) => f.id))
    .deleteAll(config.productFields.map((f) => f.id))
    .valueSeq()
    .map((f) => f.name)
    .toSet();
}

function listFieldNames(
  fieldStates: (FieldDefinitionState | PipelineFieldState)[],
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
  config: Configuration,
): ISet<string> {
  return ISet(
    fieldStates.map((f) =>
      "type" in f
        ? getRawPipelineFieldStateName(f, inheritableFields, config)
        : getRawFieldStateName(f, inheritableFields),
    ),
  );
}

function tryConvertFieldsState(
  existingFieldNames: ISet<string>,
  state: ObjectListState<FieldDefinitionState>,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
  enumTypes: T.RawEnumType[] | null,
): [null, T.RawProductFieldDefinition[]] | [UiValidationError, null] {
  try {
    return [
      null,
      state.objects.map((field, fieldIndex) =>
        convertFieldStateListItem(
          existingFieldNames,
          state,
          field,
          fieldIndex,
          inheritableFields,
          enumTypes,
        ),
      ),
    ];
  } catch (err) {
    if (err instanceof UiValidationError) {
      newrelic.noticeError(err);
      return [err, null];
    }

    throw err;
  }
}

function tryConvertEnumsState(
  existingEnumNames: ISet<string>,
  state: ObjectListState<EnumTypeState>,
  loggedInInfo: LoggedInInfo,
): [null, T.RawEnumType[]] | [UiValidationError, null] {
  try {
    return [
      null,
      state.objects.map((enumType, enumIndex) =>
        convertEnumStateListItem(
          existingEnumNames,
          state,
          enumType,
          enumIndex,
          loggedInInfo,
        ),
      ),
    ];
  } catch (err) {
    if (err instanceof UiValidationError) {
      newrelic.noticeError(err);
      return [err, null];
    }

    throw err;
  }
}

export function convertFieldStateListItem(
  existingFieldNames: ISet<string>,
  fieldListState: ObjectListState<FieldDefinitionState>,
  field: FieldDefinitionState,
  fieldIndex: number,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
  enumTypes: T.RawEnumType[] | null,
): T.RawProductFieldDefinition | T.RawCreditApplicationFieldDefinition {
  const previousFields = fieldListState.objects.slice(0, fieldIndex);
  const allExistingFieldNames = existingFieldNames.union(
    fieldListState.objects
      .filter((_, idx) => idx !== fieldIndex)
      .map((f) => getRawFieldStateName(f, inheritableFields)),
  );
  return convertStateToFieldDefinition(
    allExistingFieldNames,
    previousFields,
    field,
    inheritableFields,
    enumTypes,
  );
}

export function convertEnumStateListItem(
  existingEnumNames: ISet<string>,
  enumListState: ObjectListState<EnumTypeState>,
  enumType: EnumTypeState,
  enumIndex: number,
  loggedInInfo: LoggedInInfo,
): T.RawEnumType {
  const allExistingEnumNames = existingEnumNames.union(
    enumListState.objects
      .filter((f, idx) => idx !== enumIndex)
      .map((f) => {
        return resolveEnum(
          convertStateToEnumType(f),
          loggedInInfo.config.systemEnumTypesById,
          loggedInInfo.client.displayNewInheritedEnumVariants,
        ).name;
      }),
  );

  validateEnumState(allExistingEnumNames, enumType, loggedInInfo);
  return convertStateToEnumType(enumType);
}

export function getListLabel(
  name: string,
  isBold: boolean,
  isCustom: boolean,
): JSX.Element {
  let style = {
    position: "relative",
    float: "left",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  } as {};

  if (isBold) style = { ...style, fontWeight: "bold" };

  if (isCustom)
    style = {
      ...style,
      width: "75%",
    };

  const customFlag = (
    <div
      style={{
        color: "#fff",
        backgroundColor: "#aaa",
        marginTop: "2px",
        fontSize: "0.8em",
        position: "relative",
        float: "right",
        width: "20%",
        textAlign: "center",
        borderRadius: "4px",
      }}
    >
      custom
    </div>
  );

  return (
    <div>
      <div style={style}>{name}</div>
      {isCustom ? customFlag : ""}
    </div>
  );
}

function convertFieldDefinitionToState(
  field: T.RawProductFieldDefinition | T.RawCreditApplicationFieldDefinition,
): FieldDefinitionState {
  if (field.disposition.kind === "native") {
    return {
      oldId: field.id,
      id: field.id,
      disposition: {
        kind: "native",
        name: field.disposition.name,
        description:
          field.disposition.description !== null
            ? field.disposition.description
            : "",
        valueType: convertFieldValueTypeToState(field.disposition.valueType),
        conditions: convertFieldConditionToState(field.disposition.conditions),
        domainValidationErrors: { hasErrors: false, name: [], description: [] },
      },
      domainValidationErrors: { hasErrors: false, id: [] },
    };
  } else {
    return {
      oldId: field.id,
      id: field.id,
      disposition: {
        kind: "inherited",
        nameAlias: field.disposition.nameAlias,
        descriptionAlias:
          field.disposition.descriptionAlias !== null
            ? field.disposition.descriptionAlias
            : "",
        includeConditions: newObjectListState(
          convertIncludedFieldConditionToState(
            field.disposition.includeConditions,
          ),
        ),
        additionalConditions: convertFieldConditionToState(
          field.disposition.additionalConditions,
        ),
        domainValidationErrors: {
          hasErrors: false,
          nameAlias: [],
          descriptionAlias: [],
        },
      },
      domainValidationErrors: { hasErrors: false, id: [] },
    };
  }
}

function convertStateToFieldDefinition(
  existingFieldNames: ISet<string>,
  validParentFields: FieldDefinitionState[],
  state: FieldDefinitionState,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
  enumTypes: T.RawEnumType[] | null,
): T.RawProductFieldDefinition | T.RawCreditApplicationFieldDefinition {
  const name = getRawFieldStateName(state, inheritableFields);

  if (name.trim() === "") {
    throw new UiValidationError("Field name is empty");
  }

  const lowerCaseIdentifier = fieldNameToIdentifier(name).toLowerCase();
  const existingFieldName = existingFieldNames.find(
    (field) =>
      fieldNameToIdentifier(field).toLowerCase() === lowerCaseIdentifier,
  );
  if (existingFieldName) {
    if (existingFieldName.toLowerCase() === name.toLowerCase()) {
      throw new UiValidationError(
        `Duplicate field name "${existingFieldName}"`,
      );
    }

    throw new UiValidationError(
      `Field names "${name}" and "${existingFieldName}" are too similar.`,
    );
  }

  let dataType: T.FieldValueType | undefined;
  if (state.disposition.kind === "native") {
    dataType = convertStateToFieldValueType(state.disposition.valueType);
  } else {
    const inheritedField = inheritableFields.find((f) => f.id === state.id);
    if (!inheritedField) {
      throw new UiValidationError(
        `Attempting to inherit nonexistent field with ID '${state.id}'`,
      );
    }

    dataType = inheritedField.valueType;
  }

  if (dataType.type === "enum") {
    // For some reason this line is needed to make Typescript
    // realize that dataType is an enum type at this point.
    const enumDataType = dataType;
    const referencedEnum = enumTypes?.find((e) => {
      if (e.id !== enumDataType.enumTypeId) return false;
      if (
        state.disposition.kind === "inherited" &&
        e.disposition.kind !== "inherited"
      )
        return false;
      return true;
    });
    if (!referencedEnum) {
      if (state.disposition.kind === "native") {
        throw new UiValidationError(
          `Enumeration with ID '${enumDataType.enumTypeId}' does not exist. You must create or inherit this enumeration.`,
        );
      } else {
        throw new UiValidationError(
          `Inherited enumeration with ID '${enumDataType.enumTypeId}' does not exist. You must inherit this enumeration.`,
        );
      }
    }
  }

  if (state.disposition.kind === "native") {
    return {
      oldId: state.oldId,
      id: state.id,
      disposition: {
        kind: "native",
        name: state.disposition.name,
        description:
          state.disposition.description === ""
            ? null
            : state.disposition.description,
        valueType: convertStateToFieldValueType(state.disposition.valueType),
        conditions: state.disposition.conditions
          ? convertStateToFieldCondition(
              validParentFields,
              state.disposition.conditions,
              inheritableFields,
            )
          : [],
      },
    };
  } else {
    return {
      oldId: state.oldId,
      id: state.id,
      disposition: {
        kind: "inherited",
        nameAlias: state.disposition.nameAlias,
        descriptionAlias:
          state.disposition.descriptionAlias === ""
            ? null
            : state.disposition.descriptionAlias,
        includeConditions: convertStateToIncludedFieldCondition(
          state.disposition.includeConditions.objects,
        ),
        additionalConditions: state.disposition.additionalConditions
          ? convertStateToFieldCondition(
              validParentFields,
              state.disposition.additionalConditions,
              inheritableFields,
            )
          : [],
      },
    };
  }
}

function convertEnumTypeToState(enumType: T.RawEnumType): EnumTypeState {
  if (enumType.disposition.kind === "native") {
    return {
      oldId: enumType.oldId,
      id: enumType.id,
      disposition: {
        kind: "native",
        name: enumType.disposition.name,
        variants: newObjectListState(
          enumType.disposition.variants.map(convertEnumVariantToState),
        ),
        domainValidationErrors: {
          hasErrors: false,
          name: [],
        },
      },
      domainValidationErrors: {
        hasErrors: false,
        id: [],
      },
    };
  } else {
    return {
      oldId: enumType.oldId,
      id: enumType.id,
      disposition: {
        kind: "inherited",
        nameAlias: enumType.disposition.nameAlias,
        includeVariants: newObjectListState(
          enumType.disposition.includeVariants.map(
            convertIncludedEnumVariantToState,
          ),
        ),
        excludeVariants: newObjectListState(
          enumType.disposition.excludeVariants.map(
            convertExcludedEnumVariantToState,
          ),
        ),
        domainValidationErrors: {
          hasErrors: false,
          nameAlias: [],
        },
      },
      domainValidationErrors: {
        hasErrors: false,
        id: [],
      },
    };
  }
}

function convertEnumVariantToState(
  enumVariant: T.EnumVariant,
): EnumVariantState {
  return {
    oldId: enumVariant.id,
    id: enumVariant.id,
    name: enumVariant.name,
    domainValidationErrors: {
      hasErrors: false,
      id: [],
      name: [],
    },
  };
}

function convertIncludedEnumVariantToState(
  enumVariant: T.IncludedEnumVariant,
): IncludedEnumVariantState {
  return {
    id: enumVariant.id,
    nameAlias: enumVariant.nameAlias,
    domainValidationErrors: {
      hasErrors: false,
      id: [],
      nameAlias: [],
    },
  };
}

function convertExcludedEnumVariantToState(
  enumVariant: T.ExcludedEnumVariant,
): ExcludedEnumVariantState {
  return {
    id: enumVariant.id,
    domainValidationErrors: {
      hasErrors: false,
      id: [],
    },
  };
}

function validateEnumState(
  existingEnumTypeNames: ISet<string>,
  state: EnumTypeState,
  loggedInInfo: LoggedInInfo,
): void {
  const resolvedEnum = resolveEnum(
    convertStateToEnumType(state),
    loggedInInfo.config.systemEnumTypesById,
    loggedInInfo.client.displayNewInheritedEnumVariants,
  );

  if (resolvedEnum.name.trim() === "") {
    throw new UiValidationError("Enumeration name is missing");
  }

  const lowerCaseIdentifier = enumTypeNameToIdentifier(
    resolvedEnum.name,
  ).toLowerCase();
  const existingEnumTypeName = existingEnumTypeNames.find(
    (name) =>
      enumTypeNameToIdentifier(name).toLowerCase() === lowerCaseIdentifier,
  );

  if (existingEnumTypeName) {
    if (
      existingEnumTypeName.toLowerCase() === resolvedEnum.name.toLowerCase()
    ) {
      throw new UiValidationError(
        `Duplicate enumeration name "${existingEnumTypeName}"`,
      );
    }

    throw new UiValidationError(
      `Enumeration names "${resolvedEnum.name}" and "${existingEnumTypeName}" are too similar.`,
    );
  }

  if (resolvedEnum.variants.length < 2) {
    throw new UiValidationError("Enumeration must have at least two variants");
  }

  resolvedEnum.variants.forEach((variant, variantIndex) => {
    const previousVariants = resolvedEnum.variants.slice(0, variantIndex);
    const variantErr = validateEnumVariant(previousVariants, variant);

    if (variantErr !== null) {
      throw variantErr;
    }
  });
}

function convertStateToEnumType(state: EnumTypeState): T.RawEnumType {
  if (state.disposition.kind === "native") {
    return {
      oldId: state.oldId,
      id: state.id,
      disposition: {
        kind: "native",
        name: state.disposition.name,
        variants: state.disposition.variants.objects.map(
          convertStateToEnumVariant,
        ),
      },
    };
  } else {
    return {
      oldId: state.oldId,
      id: state.id,
      disposition: {
        kind: "inherited",
        nameAlias: state.disposition.nameAlias,
        includeVariants: state.disposition.includeVariants.objects.map(
          convertStateToIncludedEnumVariant,
        ),
        excludeVariants: state.disposition.excludeVariants.objects.map(
          convertStateToExcludedEnumVariant,
        ),
      },
    };
  }
}

function convertStateToEnumVariant(state: EnumVariantState): T.EnumVariant {
  return {
    oldId: state.oldId,
    id: state.id,
    name: state.name,
  };
}

function convertStateToIncludedEnumVariant(
  state: IncludedEnumVariantState,
): T.IncludedEnumVariant {
  return {
    id: state.id,
    nameAlias: state.nameAlias,
  };
}

function convertStateToExcludedEnumVariant(
  state: ExcludedEnumVariantState,
): T.ExcludedEnumVariant {
  return {
    id: state.id,
  };
}

function validateEnumVariant(
  previousVariants: T.EnumVariant[],
  variant: T.EnumVariant,
): UiValidationError | null {
  if (variant.name.trim() === "") {
    return new UiValidationError("Enumeration variant must have a name");
  }

  const lowerCaseIdentifier = enumVariantNameToIdentifier(
    variant.name,
  ).toLowerCase();
  const existingVariant = previousVariants.find(
    (v) =>
      enumVariantNameToIdentifier(v.name).toLowerCase() === lowerCaseIdentifier,
  );
  if (existingVariant) {
    if (existingVariant.name.toLowerCase() === variant.name.toLowerCase()) {
      return new UiValidationError(
        `Enumeration already has a variant with the name "${existingVariant.name}"`,
      );
    }

    return new UiValidationError(
      `Enumeration variant names "${variant.name}" and "${existingVariant.name}" are too similar`,
    );
  }

  return null;
}

export const useObjectEditorStyles = makeStyles((t) =>
  createStyles({
    objectEditor: {
      overflow: "auto",
      scrollbarWidth: "thin",
    },
    standardField: {
      margin: t.spacing(1, 0, 3),
      width: "100%",
      maxWidth: 400,
    },
    availableItemsList: {
      height: "250px",
      overflow: "auto",
      scrollbarWidth: "thin",
      marginRight: t.spacing(2),
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      width: 400,

      "&.error": {
        borderColor: "hsl(4.1, 89.6%, 58.4%)",
      },
    },
    availableItemsListItem: {
      display: "flex",
      alignItems: "center",
      padding: "0 12px",
      cursor: "pointer",

      "& .label": {
        flex: "1",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        padding: "8px 0",
      },

      "&:hover": {
        background: "#eee",
      },

      "& .buttonIcons": {
        display: "flex",
        flex: "auto",
        padding: "4px 0",
        justifyContent: "right",
      },
    },
  }),
);

export const FieldsPage = React.memo(() => {
  const C = useStyles();
  const dispatch = useDispatch();
  const nonNullState = useSelector(nonNullApplicationInitializationSelector);
  const objectDetailsMap = useSelector(objectDetailsMapSelector);
  const expandedConfigMap = useSelector(expandedConfigSelector);

  const loggedInInfo = {
    user: nonNullState.user,
    client: nonNullState.client,
    config: expandedConfigMap,
    objectDetails: objectDetailsMap,
    notifications: nonNullState.notifications,
  };

  const savedConfig = loggedInInfo.config;
  const existingFieldNames = listFieldNamesExcludingFieldLibrary(savedConfig);
  const existingEnumNames = listEnumNamesExcludingFieldLibrary(savedConfig);
  const hasPermission = usePermissions();
  const hasFieldsModifyPerm = hasPermission("field-enum-library-modify");
  const [enumsState, setEnumsState] = useState(
    newObjectListState(savedConfig.rawEnumTypes.map(convertEnumTypeToState)),
  );
  const [productFieldsState, setProductFieldsState] = useState(
    newObjectListState(
      savedConfig.rawProductFields.map(convertFieldDefinitionToState),
    ),
  );
  const [creditApplicationFieldsState, setCreditApplicationFieldsState] =
    useState(
      newObjectListState(
        savedConfig.rawCreditApplicationFields.map(
          convertFieldDefinitionToState,
        ),
      ),
    );

  const stage = savedConfig.stages.find(
    (stage) => stage.id === "split-on-product",
  );

  const [pipelineFieldsState, setPipelineFieldsState] = useState(
    newObjectListState(
      rebuildPipelineFields(
        savedConfig.settings.pipelineFields,
        getFieldsFromStage(savedConfig.original, stage!),
        savedConfig.creditApplicationFields.map((field) => field),
        savedConfig.rawPipelineOnlyFields.map((field) => field),
      ),
    ),
  );

  const pipelineFieldsWithoutReferences = newObjectListState(
    pipelineFieldsState.objects.filter(
      (field) => field.type === "pipeline-only",
    ),
  );

  const fieldNamesWithoutProductFields = existingFieldNames.union(
    listFieldNames(
      [
        ...creditApplicationFieldsState.objects,
        ...pipelineFieldsWithoutReferences.objects,
      ],
      loggedInInfo.config.allSystemFieldsById,
      loggedInInfo.config,
    ),
  );
  const fieldNamesWithoutCreditApplicationFields = existingFieldNames.union(
    listFieldNames(
      [
        ...productFieldsState.objects,
        ...pipelineFieldsWithoutReferences.objects,
      ],
      loggedInInfo.config.allSystemFieldsById,
      loggedInInfo.config,
    ),
  );

  const [enumsError, unsavedEnumTypes] = tryConvertEnumsState(
    existingEnumNames,
    enumsState,
    loggedInInfo,
  );

  const [pipelineFieldsStateError, unsavedPipelineFieldsState] =
    tryConvertPipelineFields(
      pipelineFieldsState,
      pipelineFieldsState.objects.map((field) => field.id),
      unsavedEnumTypes,
      existingFieldNames,
      savedConfig.allSystemFieldsById,
      savedConfig,
    );

  const [productFieldsError, unsavedProductFields] = tryConvertFieldsState(
    fieldNamesWithoutProductFields,
    productFieldsState,
    loggedInInfo.config.allSystemFieldsById,
    unsavedEnumTypes,
  );

  const [creditApplicationFieldsError, unsavedCreditApplicationFields] =
    tryConvertFieldsState(
      fieldNamesWithoutCreditApplicationFields,
      creditApplicationFieldsState,
      loggedInInfo.config.allSystemFieldsById,
      unsavedEnumTypes,
    );

  const anyUnsaved = useMemo(() => {
    const unsavedPipelineOnlyFields: T.RawPipelineFieldDefinition[] =
      unsavedPipelineFieldsState
        ? unsavedPipelineFieldsState.pipelineOnlyFields.map((field) => {
            return {
              id: field.id,
              oldId: field.oldId,
              disposition: field.disposition,
            } as T.RawPipelineFieldDefinition;
          })
        : [];

    return !!(
      enumsError ||
      !_.isEqual(
        _.sortBy(savedConfig.rawEnumTypes, "id"),
        _.sortBy(unsavedEnumTypes, "id"),
      ) ||
      productFieldsError ||
      !_.isEqual(savedConfig.rawProductFields, unsavedProductFields) ||
      creditApplicationFieldsError ||
      !_.isEqual(
        savedConfig.rawCreditApplicationFields,
        unsavedCreditApplicationFields,
      ) ||
      pipelineFieldsStateError ||
      (loggedInInfo.client.accessId !== "super" &&
        !_.isEqual(
          savedConfig.settings.pipelineFields,
          unsavedPipelineFieldsState.pipelineFieldsState,
        )) ||
      !_.isEqual(savedConfig.rawPipelineOnlyFields, unsavedPipelineOnlyFields)
    );
  }, [
    savedConfig,
    productFieldsError,
    creditApplicationFieldsError,
    enumsError,
    unsavedProductFields,
    unsavedCreditApplicationFields,
    unsavedEnumTypes,
    pipelineFieldsStateError,
    unsavedPipelineFieldsState,
    loggedInInfo.client.accessId,
  ]);

  const anyError = useMemo(
    () =>
      productFieldsError ||
      creditApplicationFieldsError ||
      enumsError ||
      pipelineFieldsStateError,
    [
      productFieldsError,
      creditApplicationFieldsError,
      enumsError,
      pipelineFieldsStateError,
    ],
  );

  const saveChanges = useCallback(() => {
    (async () => {
      if (enumsError) {
        alert("Could not save enumerations. " + enumsError.message);
        return;
      }

      if (productFieldsError) {
        alert(
          "Could not save product specifications. " +
            productFieldsError.message,
        );
        return;
      }

      if (creditApplicationFieldsError) {
        alert(
          "Could not save application form. " +
            creditApplicationFieldsError.message,
        );
        return;
      }

      if (pipelineFieldsStateError) {
        alert(
          "Could not save pipeline fields. " + pipelineFieldsStateError.message,
        );
        return;
      }

      let enumsOk = false;
      let fieldsOk = false;

      try {
        await Api.updateEnumerations(unsavedEnumTypes);
        enumsOk = true;
      } catch (e) {
        newrelic.noticeError(getErrorMessage(e));
        if (
          e instanceof Api.DetailedInvalidRequest &&
          e.errors.type === "enum-types"
        ) {
          setEnumsState({
            ...enumsState,
            objects: mapEnumTypesValidation(enumsState.objects, e.errors.value),
          });
        }
      }

      const fieldsRequest = {
        productFields: unsavedProductFields,
        creditApplicationFields: unsavedCreditApplicationFields,
        pipelineFields:
          unsavedPipelineFieldsState?.pipelineOnlyFields !== undefined
            ? unsavedPipelineFieldsState.pipelineOnlyFields
            : [],
      };

      try {
        await Api.updateFieldDefinitions(fieldsRequest);
        fieldsOk = true;

        if (
          unsavedPipelineFieldsState?.pipelineFieldsState !== undefined &&
          loggedInInfo.client.accessId !== "super"
        ) {
          const pipelineStateIdArray: T.FieldId[] =
            pipelineFieldsState.objects.map((field) => field.id);

          const newSettings = unsavedPipelineFieldsState.pipelineFieldsState
            .map((field) => field)
            .sort(
              (a, b) =>
                pipelineStateIdArray.indexOf(a) -
                pipelineStateIdArray.indexOf(b),
            );

          await Api.updateClientSettings({
            defaultPricingFilters: savedConfig.settings.defaultPricingFilters,
            priceScenarioTable: savedConfig.settings.priceScenarioTable,
            pipelineFields: newSettings,
          });
        }

        // Refresh the roles because the new field will have been automatically added to them
        dispatch(getRoles());
      } catch (e) {
        newrelic.noticeError(getErrorMessage(e));
        if (
          e instanceof Api.DetailedInvalidRequest &&
          e.errors.type === "fields"
        ) {
          setCreditApplicationFieldsState({
            ...creditApplicationFieldsState,
            objects: mapFieldDefinitionsValidation(
              creditApplicationFieldsState.objects,
              e.errors.value.creditApplicationFields,
            ),
          });
          setProductFieldsState({
            ...productFieldsState,
            objects: mapFieldDefinitionsValidation(
              productFieldsState.objects,
              e.errors.value.productFields,
            ),
          });
          setPipelineFieldsState({
            ...pipelineFieldsState,
            objects: pipelineFieldsState.objects,
          });
        } else {
          throw e;
        }
      }

      if (enumsOk && fieldsOk) {
        dispatch(loadAppInit());
      }
    })();
  }, [
    productFieldsError,
    creditApplicationFieldsError,
    creditApplicationFieldsState,
    productFieldsState,
    pipelineFieldsState,
    enumsError,
    enumsState,
    unsavedProductFields,
    unsavedCreditApplicationFields,
    unsavedEnumTypes,
    dispatch,
    savedConfig.settings,
    unsavedPipelineFieldsState,
    pipelineFieldsStateError,
    loggedInInfo.client.accessId,
  ]);

  const tabs = ["enums", "credit-application", "product", "pipeline"];

  const [requestedTabIdx, setSelectedTabIdx] = useState(0);
  const selectedTabIdx = enumsError ? tabs.indexOf("enums") : requestedTabIdx;

  const handleTabSwitch = useCallback(
    (e, idx: React.SetStateAction<number>) => setSelectedTabIdx(idx),
    [],
  );

  const hasEnumServerErrors = enumsState.objects.some(
    (o) => o.domainValidationErrors.hasErrors,
  );

  return (
    <ErrorBoundary>
      <UnloadPrompt when={anyUnsaved} />

      <Box className={C.container}>
        <Paper className={C.paper}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box display="flex">
              <Tabs value={selectedTabIdx} onChange={handleTabSwitch}>
                <Tab
                  label={
                    <Box display="flex">
                      {(enumsError || hasEnumServerErrors) && (
                        <Box
                          mr={1}
                          display="flex"
                          alignItems="center"
                          title={
                            !!enumsError
                              ? enumsError.message
                              : "Some enumerations have errors"
                          }
                          color="error.main"
                        >
                          <ErrorOutlineIcon fontSize="small" />
                        </Box>
                      )}
                      <Box flex="1" mr={1}>
                        Enumerations
                      </Box>
                    </Box>
                  }
                />
                <Tab
                  label={
                    <Box display="flex">
                      {(creditApplicationFieldsError ||
                        creditApplicationFieldsState.objects.some(
                          (o) => o.domainValidationErrors.hasErrors,
                        )) && (
                        <Box
                          mr={1}
                          display="flex"
                          alignItems="center"
                          title={
                            !!creditApplicationFieldsError
                              ? creditApplicationFieldsError.message
                              : "Some credit application fields have errors"
                          }
                          color="error.main"
                        >
                          <ErrorOutlineIcon fontSize="small" />
                        </Box>
                      )}
                      <Box flex="1" mr={1}>
                        Application Form
                      </Box>
                    </Box>
                  }
                  disabled={!!enumsError}
                />
                <Tab
                  label={
                    <Box display="flex">
                      {(productFieldsError ||
                        productFieldsState.objects.some(
                          (o) => o.domainValidationErrors.hasErrors,
                        )) && (
                        <Box
                          mr={1}
                          display="flex"
                          alignItems="center"
                          title={
                            !!productFieldsError
                              ? productFieldsError.message
                              : "Some product fields have errors"
                          }
                          color="error.main"
                        >
                          <ErrorOutlineIcon fontSize="small" />
                        </Box>
                      )}
                      <Box flex="1" mr={1}>
                        Product Specifications
                      </Box>
                    </Box>
                  }
                  disabled={!!enumsError}
                />

                {expandedConfigMap.featureFlags.pipeline && (
                  <Tab
                    label={
                      <Box display="flex">
                        {(pipelineFieldsStateError ||
                          pipelineFieldsState.objects.some(
                            (o) =>
                              o.type === "pipeline-only" &&
                              o.domainValidationErrors.hasErrors,
                          )) && (
                          <Box
                            mr={1}
                            display="flex"
                            alignItems="center"
                            title={
                              !!pipelineFieldsStateError
                                ? pipelineFieldsStateError.message
                                : "Some product fields have errors"
                            }
                            color="error.main"
                          >
                            <ErrorOutlineIcon fontSize="small" />
                          </Box>
                        )}
                        <Box flex="1" mr={1}>
                          Pipeline Settings
                        </Box>
                      </Box>
                    }
                    disabled={!!enumsError}
                  />
                )}
              </Tabs>
              <Box flex="1" />
              <Box display="flex" alignItems="center" mr={1}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CheckIcon />}
                  disabled={!!anyError || !anyUnsaved || !hasFieldsModifyPerm}
                  onClick={saveChanges}
                >
                  {anyUnsaved ? "Save" : "Saved"}
                </Button>
              </Box>
            </Box>
            <Box
              flex="1"
              display="flex"
              overflow="hidden"
              justifyContent="stretch"
            >
              {tabs[selectedTabIdx] === "credit-application" && (
                <FieldsEditor
                  fieldType="credit-application"
                  disableControls={!hasFieldsModifyPerm}
                  fieldsState={creditApplicationFieldsState}
                  setFieldsState={setCreditApplicationFieldsState}
                  enumTypes={unsavedEnumTypes!}
                  existingFieldNames={fieldNamesWithoutCreditApplicationFields}
                  allowHeaders={true}
                />
              )}
              {tabs[selectedTabIdx] === "product" && (
                <FieldsEditor
                  fieldType="product"
                  disableControls={!hasFieldsModifyPerm}
                  fieldsState={productFieldsState}
                  setFieldsState={setProductFieldsState}
                  enumTypes={unsavedEnumTypes!}
                  existingFieldNames={fieldNamesWithoutProductFields}
                  allowHeaders={false}
                />
              )}
              {tabs[selectedTabIdx] === "enums" && (
                <EnumerationsEditor
                  disableControls={!hasFieldsModifyPerm}
                  enumsState={enumsState}
                  setState={setEnumsState}
                  existingEnumNames={existingEnumNames}
                />
              )}
              {tabs[selectedTabIdx] === "pipeline" && (
                <>
                  <PipelineFieldsEditor
                    existingFieldNames={existingFieldNames}
                    pipelineFieldsState={pipelineFieldsState}
                    setPipelineFieldsState={setPipelineFieldsState}
                    disableControls={!hasFieldsModifyPerm}
                    enumTypes={unsavedEnumTypes!}
                  />
                </>
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </ErrorBoundary>
  );
});
