import { Set as ISet } from "immutable";
import React, { useMemo, useState, useCallback, useRef } from "react";
import {
  TextField,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import Peg from "pegjs";

import { Configuration } from "config";
import * as T from "types/engine-types";
import {
  getFieldIdentifier,
  fieldNameToIdentifier,
} from "features/formulas-util";
import {
  Setter,
  UiValidationError,
  usePropertySetter,
  getValidationError,
  unreachable,
  getErrorMessage,
} from "features/utils";
import { LocalOrGlobalFieldId, FieldPicker } from "../field-picker";
const { newrelic } = window;

export type StringFieldExpressionState = {
  text: string;
};

export const StringFieldExpressionEditor = React.memo(
  ({
    allowInterpolation,
    className,
    state,
    required,
    multiline,
    showErrors,
    error,
    valueType,
    label,
    margin,
    environment,
    localFields,
    config,
    setState,
  }: {
    allowInterpolation?: boolean;
    className?: string;
    state: StringFieldExpressionState;
    required: boolean;
    multiline?: boolean;
    showErrors: boolean;
    error?: boolean;
    valueType: T.FieldValueType.String;
    label?: string;
    margin?: "normal" | "dense";
    environment: ISet<T.FieldId>;
    localFields: readonly T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
    setState: Setter<StringFieldExpressionState>;
  }) => {
    const setText = usePropertySetter(setState, "text");

    const errorMessage = useMemo(
      () =>
        getValidationError(() =>
          convertStateToStringFieldExpression(
            !!allowInterpolation,
            valueType,
            state,
            config,
            environment,
            localFields,
          ),
        )?.message,
      [allowInterpolation, valueType, state, config, environment, localFields],
    );

    const textInputRef = useRef<HTMLInputElement>();

    const insertInterpolation = useCallback(
      (interpolationText: string) => {
        if (!allowInterpolation) {
          return;
        }

        const { start, end } = getSelectedRange(textInputRef.current);

        setText((text) => {
          return text.slice(0, start) + interpolationText + text.slice(end);
        });

        setTimeout(() => {
          textInputRef.current?.focus();
          textInputRef.current?.setSelectionRange(
            start,
            start + interpolationText.length,
          );
        }, 50);
      },
      [setText, allowInterpolation],
    );

    const hasErrorsToShow =
      showErrors &&
      // error from outside this component
      (error ||
        // required and missing
        (required && !state.text.trim()) ||
        // invalid input
        !!errorMessage);

    return (
      <Box flexGrow="1" flexBasis="100%">
        <div style={{ display: "flex", flexGrow: 1, flexBasis: "100%" }}>
          <TextField
            inputRef={textInputRef}
            className={className}
            type="text"
            label={label}
            InputLabelProps={{ shrink: true }}
            multiline={multiline}
            margin={margin}
            variant="outlined"
            value={state.text}
            error={hasErrorsToShow}
            helperText={errorMessage}
            onChange={(e) => setText(e.target.value)}
          />
          <InsertFieldButton
            config={config}
            environment={environment}
            localFields={localFields}
            insertInterpolation={insertInterpolation}
          />
        </div>
      </Box>
    );
  },
);

type SelectionRange = {
  start: number;
  end: number;
};

/// gets the selected range of the text input element, with `start` always <= `end`
/// default to 0..0 if inputElement or current selected range is undefined
function getSelectedRange(
  inputElement: HTMLInputElement | undefined,
): SelectionRange {
  const start = inputElement?.selectionStart;
  const end = inputElement?.selectionEnd;

  if (!start || !end) {
    return { start: 0, end: 0 };
  }

  return start <= end ? { start, end } : { start: end, end: start };
}

const InsertFieldButton = React.memo(function ({
  config,
  environment,
  localFields,
  insertInterpolation,
}: {
  config: Configuration;
  environment: ISet<T.FieldId>;
  localFields: readonly T.RuleDataTableLookupFieldDefinition[];
  insertInterpolation: (interpolationText: string) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedFieldId, setSelectedFieldId] =
    useState<LocalOrGlobalFieldId | null>(null);

  const showDialog = useCallback(() => {
    // reset to no selected field
    // (otherwise it will default to the last inserted field)
    setSelectedFieldId(null);
    setIsOpen(true);
  }, []);

  const onFieldPickerChange = useCallback(
    (selected: React.SetStateAction<LocalOrGlobalFieldId | null>) => {
      setSelectedFieldId(selected);
    },
    [setSelectedFieldId],
  );

  const insertSelectedField = useCallback(() => {
    if (!selectedFieldId) {
      return;
    }

    let fieldIdentifier: string;

    switch (selectedFieldId.type) {
      case "local": {
        const localField = localFields.find(
          (f) => f.id === selectedFieldId.fieldId,
        );
        fieldIdentifier = localField
          ? fieldNameToIdentifier(localField.name)
          : "Unknown_Field";
        break;
      }
      case "global": {
        const globalField = config.allFieldsById.get(selectedFieldId.fieldId);
        fieldIdentifier = globalField
          ? fieldNameToIdentifier(globalField.name)
          : "Unknown_Field";
        break;
      }
      default:
        unreachable(selectedFieldId);
    }

    insertInterpolation(`{ ${fieldIdentifier} }`);

    setIsOpen(false);
  }, [
    selectedFieldId,
    setIsOpen,
    insertInterpolation,
    localFields,
    config.allFieldsById,
  ]);

  const cancel = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <>
      <IconButton onClick={showDialog}>
        <LibraryBooksIcon />
      </IconButton>

      <Dialog open={isOpen}>
        <DialogTitle>Select field to insert</DialogTitle>
        <DialogContent>
          <Box width="300px">
            <FieldPicker
              localFields={localFields}
              environment={environment}
              config={config}
              fieldType="any"
              selected={selectedFieldId}
              onChange={onFieldPickerChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel}>Cancel</Button>
          <Button
            color="primary"
            disabled={selectedFieldId === null}
            onClick={insertSelectedField}
          >
            Insert Field
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export function convertStringFieldExpressionToState(
  valueType: T.FieldValueType.String,
  expression: T.Expression,
  config: Configuration,
  environment: ISet<T.FieldId>,
  localFields: readonly T.RuleDataTableLookupFieldDefinition[] = [],
): StringFieldExpressionState {
  const text = stringFieldExpressionToString(
    valueType,
    expression,
    config,
    environment,
    localFields,
  );

  return { text };
}

export function stringFieldExpressionToString(
  valueType: T.FieldValueType.String,
  expression: T.Expression,
  config: Configuration,
  environment: ISet<T.FieldId>,
  localFields: readonly T.RuleDataTableLookupFieldDefinition[] = [],
): string {
  // Must be a call to CONCAT()
  if (expression.kind !== "function-call") {
    throw new Error("Unexpected expression kind: " + expression.kind);
  }
  if (expression.func.kind !== "literal") {
    throw new Error(
      "Unexpected function expression kind: " + expression.func.kind,
    );
  }
  if (expression.func.value.type !== "builtin-func") {
    throw new Error(
      "Unexpected function expression value type: " +
        expression.func.value.type,
    );
  }
  if (expression.func.value.value !== "concat") {
    throw new Error("Unexpected function name: " + expression.func.value.value);
  }

  const text = expression.args
    .map((arg) => {
      switch (arg.kind) {
        case "literal": {
          if (arg.value.type === "string") {
            // escape `{`, `}`, and `\`
            return arg.value.value.replace(
              /[{}\\]/g,
              (matched) => `\\${matched}`,
            );
          } else {
            throw new Error(`Unexpected literal value type: ${arg.value.type}`);
          }
        }
        case "field-value-to-string": {
          const field = config.allFieldsById.get(arg.fieldId);
          const fieldIdentifier = field
            ? getFieldIdentifier(field)
            : "Unknown_Field";
          return `{ ${fieldIdentifier} }`;
        }
        case "local-field-value-to-string": {
          const field = localFields.find((f) => f.id === arg.fieldId);
          const fieldIdentifier = field
            ? fieldNameToIdentifier(field.name)
            : "Unknown_Field";
          return `{ ${fieldIdentifier} }`;
        }
        default: {
          throw new Error(`Unexpected expression kind: ${arg.kind}`);
        }
      }
    })
    .join("");

  return text;
}

export function convertStateToStringFieldExpression(
  allowInterpolation: boolean,
  valueType: T.FieldValueType.String,
  state: StringFieldExpressionState,
  config: Configuration,
  environment: ISet<T.FieldId>,
  localFields: readonly T.RuleDataTableLookupFieldDefinition[] = [],
): T.Expression {
  let parsedSegments: InterpolationSegment[];

  try {
    parsedSegments = stringIntepolationParser.parse(
      state.text,
    ) as InterpolationSegment[];
  } catch (err) {
    newrelic.noticeError(getErrorMessage(err));
    // if .parse() throws an error it should be a peg syntax error, but
    // we don't have a good way to check if it is or not
    // This comment was generated when upgrading react-scripts and eslint
    if (err instanceof Error) {
      throw new UiValidationError(err.message);
    } else {
      throw new UiValidationError(
        "error converting state to string field expression",
      );
    }
  }

  if (
    !allowInterpolation &&
    parsedSegments.some((segment) => segment.kind !== "literal")
  ) {
    throw new UiValidationError(
      `interpolation of field values with "{ }" is not allowed here (help: use \\{ instead of {)`,
    );
  }

  const args = parsedSegments.map((segment): T.Expression => {
    switch (segment.kind) {
      case "identifier": {
        const field = config.getFieldByIdentifier(segment.text);
        const localField = localFields.find(
          (f) =>
            fieldNameToIdentifier(f.name).toLowerCase() ===
            segment.text.toLowerCase(),
        );

        if (field && localField) {
          throw new UiValidationError(
            `field identifier ${
              segment.text
            } could refer to either the field ${JSON.stringify(
              field.name,
            )} or the data table lookup field ${JSON.stringify(
              localField.name,
            )} in this rule. Please rename one of the fields`,
          );
        }

        if (field && !environment.includes(field.id)) {
          throw new UiValidationError(
            `field ${JSON.stringify(
              field.name,
            )} is not available at this stage in engine execution`,
          );
        }

        if (field) {
          return {
            kind: "field-value-to-string",
            fieldId: field.id,
          };
        } else if (localField) {
          return {
            kind: "local-field-value-to-string",
            fieldId: localField.id,
          };
        } else {
          throw new UiValidationError(
            `unrecognized field identifier "${segment.text}"`,
          );
        }
      }
      default:
        return segment;
    }
  });

  return {
    kind: "function-call",
    func: {
      kind: "literal",
      value: {
        type: "builtin-func",
        value: "concat",
      },
    },
    args,
  };
}

type InterpolationSegment =
  | T.Expression.Literal
  | { kind: "identifier"; text: string };

// TODO: generate parser ahead of time using webpack loader
function generateStringInterpolationParser(): Peg.Parser {
  const grammarText = String.raw`
    {
      // using literal "{" or "}" in error messages below breaks the peg grammar parser,
      // so we define them as constants here
      const LCURLY = "{";
      const RCURLY = "}";
    }

    interpolationString
      = interpolationSegment*

    interpolationSegment
      = text:$(notInterpolated+) {
        return {
          kind: "literal",
          value: {
            type: "string",
            value: text
              .replace(/\\[{}\\]/g, (matched) => matched.slice(1)),
          }
        }
      }
      / "{" W expr:expression W "}" {
        return expr;
      }
      / "{" W "{" {
        throw new Error("expected a field identifier after \"" + LCURLY + "\", found a second \"" + LCURLY + "\"");
      }
      / text:$("{" W "}") {
        throw new Error("empty \"" + text + "\" (should contain a field identifier)");
      }
      / "{" {
        throw new Error("unclosed \"" + LCURLY + "\"");
      }
      / "}" {
        throw new Error("unexpected \"" + RCURLY + "\"");
      }

    notInterpolated
      = "\\{"
      / "\\}"
      / ! ("{" / "}") .

    expression
      = identifier

    identifier "field reference"
      = ident:$([a-zA-Z_] [a-zA-Z0-9_]*) {
        return {
          kind: "identifier",
          text: ident,
        }
      }
    
    W "whitespace" = [ \t\n\r]*
  `;

  try {
    return Peg.generate(grammarText, { cache: true });
  } catch (err) {
    if (err instanceof Error) {
      const error = new Error(`failed to generate parser from grammar: `);
      newrelic.noticeError(error);
      newrelic.noticeError(err);
      console.error(`failed to generate parser from grammar: ${err.message}`);
    }
    throw err;
  }
}

const stringIntepolationParser = generateStringInterpolationParser();
