import { Set as ISet } from "immutable";
import React from "react";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import * as T from "types/engine-types";
import { SearchableDropdown } from "design/molecules/dropdown";
import {
  DurationFieldValueEditor,
  DurationFieldValueState,
  newDurationFieldValueState,
  convertDurationFieldValueToState,
  convertStateToDurationFieldValue,
} from "design/organisms/field-value-editor";
import { Configuration } from "config";
import {
  ALL_DURATION_FIELD_PREDICATE_OPS,
  DurationFieldPredicateOp,
  getDurationFieldPredicateOpName,
} from "features/field-predicates";
import { Setter, UiValidationError, usePropertySetter } from "features/utils";

export type DurationFieldPredicateState = {
  op: DurationFieldPredicateOp | null;
  operand: DurationFieldValueState;
  operandBottom: DurationFieldValueState;
  operandTop: DurationFieldValueState;
};

export function newDurationFieldPredicateState(
  valueType: T.FieldValueType.Duration,
): DurationFieldPredicateState {
  return {
    op: null,
    operand: newDurationFieldValueState(valueType),
    operandBottom: newDurationFieldValueState(valueType),
    operandTop: newDurationFieldValueState(valueType),
  };
}

export function convertDurationFieldPredicateToState(
  valueType: T.FieldValueType.Duration,
  pred: T.DurationFieldPredicateKind,
): DurationFieldPredicateState {
  function expressionToDurationFieldValue(
    expr: T.Expression,
  ): T.FieldValue.Duration {
    if (expr.kind !== "duration") {
      throw new Error("not supported");
    }

    if (expr.count.kind !== "literal") {
      throw new Error("not supported");
    }

    if (expr.count.value.type !== "number") {
      throw new Error("Invalid duration count value type");
    }

    return {
      type: "duration",
      count: expr.count.value.value,
      unit: expr.unit,
    };
  }

  switch (pred.op) {
    case "is-blank":
    case "is-not-blank":
      return {
        op: pred.op,
        operand: newDurationFieldValueState(valueType),
        operandBottom: newDurationFieldValueState(valueType),
        operandTop: newDurationFieldValueState(valueType),
      };
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return {
        op: pred.op,
        operand: convertDurationFieldValueToState(
          expressionToDurationFieldValue(pred.other),
        ),
        operandBottom: newDurationFieldValueState(valueType),
        operandTop: newDurationFieldValueState(valueType),
      };
    case "between":
    case "not-between":
      return {
        op: pred.op,
        operand: newDurationFieldValueState(valueType),
        operandBottom: convertDurationFieldValueToState(
          expressionToDurationFieldValue(pred.bottom),
        ),
        operandTop: convertDurationFieldValueToState(
          expressionToDurationFieldValue(pred.top),
        ),
      };
  }
}

export function convertStateToDurationFieldPredicate(
  config: Configuration,
  valueType: T.FieldValueType.Duration,
  state: DurationFieldPredicateState,
): T.DurationFieldPredicateKind {
  function durationFieldValueToExpression(
    d: T.FieldValue.Duration,
  ): T.Expression {
    return {
      kind: "duration",
      count: {
        kind: "literal",
        value: {
          type: "number",
          value: d.count,
        },
      },
      unit: d.unit,
    };
  }

  if (!state.op) {
    throw new UiValidationError("Select an operator");
  }

  switch (state.op) {
    case "is-blank":
    case "is-not-blank":
      return {
        type: "generic",
        op: state.op,
      };
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      const operand = convertStateToDurationFieldValue(
        valueType,
        state.operand,
      );

      if (!operand) {
        throw new UiValidationError("Duration value is required");
      }

      return {
        type: "duration",
        op: state.op,
        other: durationFieldValueToExpression(operand),
      };
    case "between":
    case "not-between":
      const operandBottom = convertStateToDurationFieldValue(
        valueType,
        state.operandBottom,
      );
      const operandTop = convertStateToDurationFieldValue(
        valueType,
        state.operandTop,
      );

      if (!operandBottom) {
        throw new UiValidationError("Minimum duration value is required");
      }
      if (!operandTop) {
        throw new UiValidationError("Maximum duration value is required");
      }

      return {
        type: "duration",
        op: state.op,
        bottom: durationFieldValueToExpression(operandBottom),
        top: durationFieldValueToExpression(operandTop),
      };
  }
}

const useStyles = makeStyles((t) =>
  createStyles({
    fields: {
      "& .MuiTextField-root": {
        margin: t.spacing(1, 0),
        width: 270,
      },
    },
    field: {
      width: 270,
      "& > div:first-child": {
        margin: t.spacing(1, 0),
      },
    },
  }),
);

export const DurationFieldPredicateEditor = React.memo(
  ({
    state,
    valueType,
    setState,
    showValidationErrors,
    environment,
    localFields,
    config,
  }: {
    state: DurationFieldPredicateState;
    valueType: T.FieldValueType;
    setState: Setter<DurationFieldPredicateState>;
    showValidationErrors: boolean;
    environment: ISet<T.FieldId>;
    localFields: T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
  }) => {
    const C = useStyles();

    const setOp = usePropertySetter(setState, "op");
    const setOperand = usePropertySetter(setState, "operand");
    const setOperandBottom = usePropertySetter(setState, "operandBottom");
    const setOperandTop = usePropertySetter(setState, "operandTop");

    return (
      <>
        <div className={C.fields}>
          <SearchableDropdown
            label="Operation"
            options={ALL_DURATION_FIELD_PREDICATE_OPS}
            getOptionLabel={getDurationFieldPredicateOpName}
            value={state.op}
            error={showValidationErrors && !state.op}
            setValue={setOp}
          />
        </div>
        {state.op && opHasSingleOperand(state.op) && (
          <div className={C.field}>
            <DurationFieldValueEditor
              label="Duration"
              state={state.operand}
              minimumDays={null}
              maximumDays={null}
              minimumMonths={null}
              maximumMonths={null}
              showErrors={showValidationErrors}
              setState={setOperand}
            />
          </div>
        )}
        {state.op && opHasBottomTopOperands(state.op) && (
          <>
            <Box mt={1} mb={2}>
              <DurationFieldValueEditor
                label="Minimum"
                state={state.operandBottom}
                minimumDays={null}
                maximumDays={null}
                minimumMonths={null}
                maximumMonths={null}
                showErrors={showValidationErrors}
                setState={setOperandBottom}
              />
            </Box>
            <Box my={2}>
              <DurationFieldValueEditor
                label="Maximum"
                state={state.operandTop}
                minimumDays={null}
                maximumDays={null}
                minimumMonths={null}
                maximumMonths={null}
                showErrors={showValidationErrors}
                setState={setOperandTop}
              />
            </Box>
          </>
        )}
      </>
    );
  },
);

function opHasSingleOperand(op: DurationFieldPredicateOp): boolean {
  switch (op) {
    case "is-blank":
    case "is-not-blank":
      return false;
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return true;
    case "between":
    case "not-between":
      return false;
  }
}

function opHasBottomTopOperands(op: DurationFieldPredicateOp): boolean {
  switch (op) {
    case "is-blank":
    case "is-not-blank":
      return false;
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return false;
    case "between":
    case "not-between":
      return true;
  }
}
