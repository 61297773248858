import styled from "styled-components";
import whitespace from "design/subatomics/whitespace";
import color from "design/subatomics/colors";
import radiusOptions from "design/subatomics/corners";
import breakpoints from "design/subatomics/breakpoints";

export const Scenarios = styled.section`
  overflow-y: auto;
  overflow-x: hidden;

  ${breakpoints.mobile} {
    max-height: 160px;
  }

  ${breakpoints.desktop} {
    max-height: 400px;
  }
`;

export const Scenario = styled.div`
  position: relative;
  padding: ${whitespace("least")} ${whitespace("less")} ${whitespace("less")};
  padding-right: 100px;

  &:hover {
    background: ${color({ color: "gray", shade: 8 })};
    border-radius: ${radiusOptions.small};
  }

  ${breakpoints.mobile} {
    .typography-small-text {
      display: block;
    }
  }

  ${breakpoints.desktop} {
    .typography-small-text {
      display: block;
    }

    .atom-button {
      opacity: 0;
    }

    &:hover {
      .atom-button {
        opacity: 1;
      }
    }
  }
`;

export const ButtonGroup = styled.div`
  position: absolute;

  .atom-button {
    display: inline-block;
    margin-right: ${whitespace("less")};
  }

  right: -4px;

  &.global {
    top: 0;
  }

  &.custom {
    top: 6px;
  }
`;
