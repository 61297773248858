import React from "react";
import * as T from "types/engine-types";
import { Setter, useSetter } from "features/utils";
import {
  NumberFieldValueEditor,
  NumberFieldValueState,
  newNumberFieldValueState,
  NumberFieldValueEditorProps,
} from "design/organisms/field-value-editor";

/** This does the same as NumberFieldValueEditor, except it acts on a `number` instead of
 * `NumberFieldValueState`. The name comes from the fact that it is used in the default min/max
 * settings for interest rate and price.
 */
export const NumberFieldDefaultValueEditor = React.memo(
  function NumberFieldDefaultValueEditor({
    state,
    setState,
    valueType,
    ...props
  }: {
    state: string | null;
    setState: Setter<string | null>;
    valueType: T.FieldValueType.Number;
  } & Pick<
    NumberFieldValueEditorProps,
    "className" | "error" | "label" | "margin" | "showErrors"
  >) {
    const numberStateToFieldValueState = (
      numberState: string | null,
    ): NumberFieldValueState =>
      numberState
        ? { type: "number", value: numberState }
        : newNumberFieldValueState(valueType);

    const fieldValueState = numberStateToFieldValueState(state);

    const setFieldValueState: Setter<NumberFieldValueState> = useSetter(
      setState,
      (oldNumberState, transformNumberFieldValueState) => {
        const oldNumberFieldValueState =
          numberStateToFieldValueState(oldNumberState);
        const newNumberFieldValueState = transformNumberFieldValueState(
          oldNumberFieldValueState,
        );

        return newNumberFieldValueState.value;
      },
      [],
    );

    return (
      <NumberFieldValueEditor
        state={fieldValueState}
        setState={setFieldValueState}
        precision={valueType.precision}
        style={valueType.style}
        {...props}
      />
    );
  },
);
