import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Box,
  Button,
  Typography,
  TextField as MaterialTextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import DetailActions from "design/molecules/detail-actions";
import DetailHeader from "design/molecules/detail-header";
import PermissionsList from "../_components/permissions-list";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import * as T from "types/engine-types";
import FieldsList from "../_components/fields-list";
import { fieldBuckets } from "config";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import { useAppDispatch } from "features/store";
import {
  rolesSelector,
  filteredRolesSelector,
  getRoles,
  createRole,
  configurationView,
} from "features/roles";

export default function ViewRolePage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const roles = useSelector(filteredRolesSelector);
  const [name, setName] = useState("");
  const [cancelConfirmOpen, setCancelConfirmOpen] = useState<boolean>(false);
  const [perms, setPerms] = useState<T.PermissionKind[]>([]);
  const {
    config,
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);
  const [fieldPermIds, setFieldPermIds] = useState<T.FieldId[]>([]);
  const allFieldPermissions = config && fieldBuckets(config);
  const rolesState = useSelector(rolesSelector);
  const { errors, loading } = rolesState;

  useEffect(() => {
    if (!roles || roles.length === 0) dispatch(getRoles());
  }, [dispatch, roles, roles.length]);

  const handleSave = async () => {
    dispatch(
      createRole({
        name,
        permissions: [configurationView, ...perms],
        pricingVisibleFieldIds: fieldPermIds,
      }),
    )
      .then(unwrapResult)
      .then((status: "error" | "success") => {
        if (status === "success") {
          history.push(`/c/${accessId}/roles`);
        }
      });
  };

  if (loading) {
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert>Loading</MuiAlert>
      </Snackbar>
    );
  } else {
    return (
      <>
        {errors && (
          <Snackbar
            open={true}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert severity="error">{errors}</MuiAlert>
          </Snackbar>
        )}
        <DetailActions>
          <>
            <Button
              className="cancel-role"
              variant="outlined"
              startIcon={<CloseIcon />}
              onClick={() => setCancelConfirmOpen(true)}
            >
              Cancel
            </Button>

            <Dialog
              open={cancelConfirmOpen}
              onClose={() => setCancelConfirmOpen(false)}
            >
              <DialogTitle>Leaving the Screen</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  <strong>Warning!</strong> All unsaved progress will be lost.
                  Click "Cancel" to finish creating the new role, or "Go to
                  Roles List" to abandon role creation.
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setCancelConfirmOpen(false)}>
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    setCancelConfirmOpen(false);
                    history.push(`/c/${accessId}/roles`);
                  }}
                >
                  Go to Roles List
                </Button>
              </DialogActions>
            </Dialog>

            <Box flex="1" />

            <Button
              className="save-role"
              variant="outlined"
              startIcon={<DoneIcon />}
              onClick={handleSave}
            >
              Save
            </Button>
          </>
        </DetailActions>

        <DetailHeader>
          <div>
            <Typography style={{ margin: "32px 0 16px" }} variant="h4">
              Creating Role
            </Typography>

            <MaterialTextField
              style={{ margin: "16px 0" }}
              label="Role Name"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setName(e.currentTarget.value)}
            />
          </div>
        </DetailHeader>

        <div
          style={{
            overflowX: "scroll",
            display: "flex",
            flexDirection: "row",
            flex: "1 1 auto",
          }}
        >
          <>
            <PermissionsList
              mode={"edit"}
              chosenPerms={perms}
              setChosenPerms={setPerms}
            />

            {allFieldPermissions?.map((b) => (
              <FieldsList
                fieldPermIds={fieldPermIds}
                key={b[0]}
                fields={b[1]}
                allFields={b[1]}
                setFields={setFieldPermIds}
                groupLabel={b[0]}
                createMode={true}
                copyMode={false}
                editMode={false}
              />
            ))}
          </>
        </div>
      </>
    );
  }
}
