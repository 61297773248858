import { Set as ISet } from "immutable";
import _ from "lodash";

import { Configuration } from "config";
import * as T from "types/generated-types";
import * as Builtins from "features/formulas-builtins";
import {
  BuiltinFunction,
  ValueType,
  isSubtype,
  isSameType,
  printValueType,
  anyT,
  booleanT,
  durationT,
  numberT,
  dateT,
} from "types/formulas-types";

const allowedInputTypesByBinaryOp = {
  eq: [anyT()],
  ne: [anyT()],
  lt: [durationT(), numberT(), dateT()],
  gt: [durationT(), numberT(), dateT()],
  lte: [durationT(), numberT(), dateT()],
  gte: [durationT(), numberT(), dateT()],
} as const;

const displayTextByBinaryOp = {
  eq: "=",
  ne: "<>",
  lt: "<",
  gt: ">",
  lte: "<=",
  gte: ">=",
} as const;

export class UnknownFieldIdError extends Error {
  fieldId: T.FieldId;
  constructor(fieldId: T.FieldId) {
    super(`no field with ID ${JSON.stringify(fieldId)}`);
    this.name = "UnknownFieldIdError";
    this.fieldId = fieldId;
  }
}

export class NotInEnvironmentError extends Error {
  fieldId: T.FieldId;
  constructor(fieldId: T.FieldId) {
    super(
      "cannot access field not yet in environment " + JSON.stringify(fieldId),
    );
    this.name = "NotInEnvironmentError";
    this.fieldId = fieldId;
  }
}

export class UnknownFunctionError extends Error {
  funcName: string;
  constructor(funcName: string) {
    super(`no known function by name ${JSON.stringify(funcName)}`);
    this.name = "UnknownFunctionError";
    this.funcName = funcName;
  }
}

export class InvalidFunctionCallError extends Error {
  func: BuiltinFunction;
  argTypes: ValueType[];
  constructor(func: BuiltinFunction, argTypes: ValueType[]) {
    super(
      `incorrect argument types passed to function ${JSON.stringify(
        func.name,
      )}: ${JSON.stringify(argTypes)}`,
    );
    this.name = "InvalidFunctionCallError";
    this.func = func;
    this.argTypes = argTypes;
  }
}

export class TypeCheckError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "TypeCheckError";
  }
}

export function getExpressionOutputType(
  config: Configuration,
  environment: ISet<T.FieldId>,
  ir: T.Expression,
): ValueType {
  function recurse(ir: T.Expression): ValueType {
    return getExpressionOutputType(config, environment, ir);
  }

  function pvt(valueType: ValueType): string {
    return printValueType(config, valueType);
  }

  function getConditionOutputType(condition: T.Condition): ValueType {
    switch (condition.kind) {
      case "and":
      case "or": {
        for (const child of condition.children) {
          const childType = recurse(child);

          if (!isSameType(childType, booleanT())) {
            throw new TypeCheckError(
              `All parameters to ${condition.kind.toUpperCase()} must be a ${pvt(
                booleanT(),
              )}. One of the parameters is currently a ${pvt(childType)}.`,
            );
          }
        }

        return { kind: "boolean" };
      }
      case "predicate":
        return getPredicateOutputType(condition.predicate);
      default:
        throw new Error(
          "type checker doesn't know about conditions of type " +
            JSON.stringify(condition.kind),
        );
    }
  }

  function getPredicateOutputType(predicate: T.Predicate): ValueType {
    switch (predicate.kind) {
      case "unary":
        return getUnaryPredicateOutputType(predicate);
      case "binary":
        return getBinaryPredicateOutputType(predicate);
    }
  }

  function getUnaryPredicateOutputType(predicate: T.UnaryPredicate): ValueType {
    switch (predicate.op) {
      case "is-blank":
        // Recurse anyway even though any type is except so that the
        // expression gets type checked
        recurse(predicate.left);

        return { kind: "boolean" };
    }
  }

  function getBinaryPredicateOutputType(
    predicate: T.BinaryPredicate,
  ): ValueType {
    switch (predicate.op) {
      case "eq":
      case "ne":
      case "lt":
      case "gt":
      case "lte":
      case "gte": {
        const left = recurse(predicate.left);
        const right = recurse(predicate.right);

        if (!isSameType(left, right)) {
          throw new TypeCheckError(
            "Cannot compare values of types " +
              pvt(left) +
              " and " +
              pvt(right) +
              ".",
          );
        }

        const allowedInputTypes = allowedInputTypesByBinaryOp[predicate.op];

        if (allowedInputTypes.every((t) => !isSubtype(t, left))) {
          throw new TypeCheckError(
            "Operation " +
              pvt(left) +
              " " +
              displayTextByBinaryOp[predicate.op] +
              " " +
              pvt(right) +
              " is not allowed.",
          );
        }

        return { kind: "boolean" };
      }
      default:
        throw new Error(
          "type checker doesn't know about predicate ops of type " +
            JSON.stringify(predicate.op),
        );
    }
  }

  switch (ir.kind) {
    case "if-else":
      const conditionType = recurse(ir.cond);
      const thenType = recurse(ir.ifTrue);
      const elseType = recurse(ir.ifFalse);

      if (!isSameType(conditionType, { kind: "boolean" })) {
        throw new TypeCheckError(
          "First part of IF statement must be a condition, but a " +
            pvt(conditionType) +
            " was found instead.",
        );
      }

      if (!isSameType(thenType, elseType)) {
        throw new TypeCheckError(
          "The second and third parts of an IF statement must produce the same type of value (" +
            pvt(thenType) +
            " is incompatible with " +
            pvt(elseType) +
            ").",
        );
      }

      return thenType;
    case "condition":
      return getConditionOutputType(ir.cond);
    case "function-call":
      if (ir.func.kind !== "literal") {
        throw new Error("expected function name to be a literal");
      }
      if (ir.func.value.type !== "builtin-func") {
        throw new Error("expected function name to be a literal");
      }

      const funcName: string = ir.func.value.value;
      const builtin = Builtins.builtinFunctions.find(
        (f) => f.name === funcName,
      );

      if (!builtin) {
        throw new UnknownFunctionError(funcName);
      }

      const args = ir.args;
      const argTypes = args.map(recurse);
      const typeSig = builtin.typeSignatures.find((sig) =>
        _.zip(sig.paramTypes, argTypes).every(
          ([paramType, argType]) =>
            paramType && argType && isSubtype(paramType, argType),
        ),
      );

      if (!typeSig) {
        throw new InvalidFunctionCallError(builtin, argTypes);
      }

      return typeSig.returnType;
    case "field-value":
      const field = config.allFieldsById.get(ir.fieldId);

      if (!field) {
        throw new UnknownFieldIdError(ir.fieldId);
      }

      if (!environment.has(field.id)) {
        throw new NotInEnvironmentError(field.id);
      }

      return fieldValueTypeToValueType(field.valueType);
    case "duration":
      return durationT();
    case "literal": {
      const value = ir.value;

      switch (value.type) {
        case "boolean":
          return { kind: "boolean" };
        case "number":
          return { kind: "number" };
        case "date":
          return { kind: "date" };
        case "string":
          return { kind: "string" };
        case "enum":
          return { kind: "enum", enumTypeId: value.value.enumTypeId };
        case "object-ref":
          return { kind: "object-ref", objectType: value.value.type };
        default:
          throw new Error(
            "type checker doesn't know about literals of type " +
              JSON.stringify(value.type),
          );
      }
    }
    default:
      throw new Error(
        "type checker doesn't know about expression nodes of type " +
          JSON.stringify(ir.kind),
      );
  }
}

export function fieldValueTypeToValueType(
  fieldValueType: T.FieldValueType,
): ValueType {
  switch (fieldValueType.type) {
    case "enum":
      return {
        kind: "enum",
        enumTypeId: fieldValueType.enumTypeId,
      };
    case "object-ref":
      return {
        kind: "object-ref",
        objectType: fieldValueType.objectType,
      };
    default:
      return { kind: fieldValueType.type };
  }
}
