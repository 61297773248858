import React, { ReactElement } from "react";
import { Box } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const MessageSection = React.memo(
  ({
    icon,
    isListItem,
    isInDialog,
    children,
  }: {
    icon?: ReactElement;
    isListItem?: boolean;
    isInDialog?: boolean;
    children: React.ReactNode;
  }) => {
    return (
      <Box
        px={isInDialog ? 1 : 5}
        my={isListItem ? 3 : 4}
        display="flex"
        alignItems="center"
      >
        {icon || <HelpOutlineIcon />}
        <Box ml={3}>{children}</Box>
      </Box>
    );
  },
);

export default MessageSection;
