import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Set as ISet } from "immutable";
import { Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { getFieldsFromStage } from "config";
import * as T from "types/engine-types";
import { Setter, getValidationError, useSetter } from "features/utils";
import {
  nonNullApplicationInitializationSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import { newFieldValueTypeState } from "design/organisms/field-value-type-editor";
import {
  ObjectListEditor,
  ObjectListState,
  newObjectListState,
  ObjectListEditorButton,
} from "design/organisms/object-list-editor";
import { SearchableDropdown } from "design/molecules/dropdown";
import {
  FieldDefinitionState,
  PipelineFieldState,
  NativeFieldDefinitionState,
  InheritedFieldDefinitionState,
  useObjectEditorStyles,
  convertPipelineFieldStateListItem,
} from "pages/fields";
import { NativeFieldEditor } from "../native-field-editor";
import { InheritedFieldEditor } from "../inherited-field-editor";
import { PrimaryTitle, PrimaryTitleSub } from "design/atoms/typography";
import KeyValue from "design/atoms/key-value";

/**
 * Function that handles the ui for editing referenced pipeline fields in the ObjectListEditor
 */
const PipelineReferenceEditor = React.memo(function PipelineReferenceEditor({
  fieldsState,
  setFieldsState,
}: {
  fieldsState: PipelineFieldState;
  setFieldsState: Setter<PipelineFieldState>;
}) {
  const C = useObjectEditorStyles();
  const nonNullState = useSelector(nonNullApplicationInitializationSelector);
  const objectDetailsMap = useSelector(objectDetailsMapSelector);
  const loggedInInfo = useMemo(
    () => ({
      user: nonNullState.user,
      client: nonNullState.client,
      config: nonNullState.config,
      objectDetails: objectDetailsMap,
      notifications: nonNullState.notifications,
    }),
    [nonNullState, objectDetailsMap],
  );

  const [inheritedField, setInheritedField] =
    useState<T.BaseFieldDefinition | null>(null);

  /**
   * Function that returns the correct bucket of fields for selecting a referenced field
   */
  const getReferenceOptions = ():
    | T.ProductFieldDefinition[]
    | T.CreditApplicationFieldDefinition[]
    | T.BaseFieldDefinition[] => {
    switch (fieldsState.type) {
      case "product-specifications":
        {
          const stage = loggedInInfo.config.stages.find(
            (stage) => stage.id === "split-on-product",
          );
          const fields = getFieldsFromStage(
            loggedInInfo.config.original,
            stage!,
          );

          return fields;
        }
        break;
      case "credit-applications":
        return [...loggedInInfo.config.creditApplicationFields];
        break;
      case "calculations":
        return [
          ...loggedInInfo.config.calcStages.flatMap((stage) => {
            return getFieldsFromStage(loggedInInfo.config.original, stage);
          }),
        ];
        break;
      default:
        return [];
        break;
    }
  };
  const inheritableOptions = fieldsState ? getReferenceOptions() : [];

  inheritableOptions.sort((a, b) => {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();

    if (aName > bName) return 1;
    else if (aName < bName) return -1;
    else return 0;
  });

  let bucketName = "";
  switch (fieldsState.type) {
    case "calculations":
      bucketName = "Calculations";
      break;
    case "credit-applications":
      bucketName = "Application Form";
      break;
    case "product-specifications":
      bucketName = "Product Specifications";
      break;
    case "pipeline-only":
      break;
  }

  // these 2 useEffects handle the correct displaying of referenced field information in the dropdown, as well as preventing some UI bugs in the ObjectListEditor
  useEffect(() => {
    if (inheritedField && fieldsState.type !== "pipeline-only") {
      setFieldsState({
        id: inheritedField.id,
        type: fieldsState.type,
      });
    }
    // eslint is complaining about including fieldsState in dependancies, but that causes things to go infinite
    //eslint-disable-next-line
  }, [inheritedField, fieldsState.type, setFieldsState]);

  useEffect(() => {
    if (!fieldsState.id && fieldsState.type !== "pipeline-only") {
      setFieldsState({
        id: "" as T.FieldId,
        type: fieldsState.type,
      });
      setInheritedField(null);
    } else {
      const statefulField = inheritableOptions.find(
        (field) => field.id === fieldsState.id,
      );

      if (statefulField) {
        setFieldsState({
          id: statefulField.id,
          type: fieldsState.type as
            | "calculations"
            | "product-specifications"
            | "credit-applications",
        });
        setInheritedField(statefulField);
      }
    }
    // eslint is complaining about including fieldsState and inheritableOptions in dependancies, both of which cause things to go infinite
    //eslint-disable-next-line
  }, [setInheritedField, setFieldsState, fieldsState.id, fieldsState.type]);

  return (
    <div
      style={{
        width: "500px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        margin: "24px",
      }}
    >
      <SearchableDropdown<T.BaseFieldDefinition>
        className={C.standardField}
        label={`Reference from ${bucketName}`}
        value={inheritedField || null}
        options={inheritableOptions}
        getOptionLabel={(systemField) => systemField.name}
        setValue={(systemField) => {
          if (systemField) {
            setInheritedField(systemField);
          }
        }}
      />
    </div>
  );
});

/**
 * Function that handles rendering the UI for editing pipeline fields, passed to makeFieldEditor for use in the ObjectListEditor. Abstracting this into its own component allows us to make use of useSetter correctly.
 */
const PipelineMakeFieldEditor = React.memo(function PipelineMakeFieldEditor({
  value,
  showErrors,
  setValue,
  enumTypes,
  allowHeaders,
}: {
  value: PipelineFieldState;
  showErrors: boolean;
  setValue: Setter<PipelineFieldState>;
  enumTypes: T.RawEnumType[];
  allowHeaders: false;
}) {
  const C = useObjectEditorStyles();
  const nonNullState = useSelector(nonNullApplicationInitializationSelector);
  const objectDetailsMap = useSelector(objectDetailsMapSelector);
  const loggedInInfo = useMemo(
    () => ({
      user: nonNullState.user,
      client: nonNullState.client,
      config: nonNullState.config,
      objectDetails: objectDetailsMap,
      notifications: nonNullState.notifications,
    }),
    [nonNullState, objectDetailsMap],
  );

  /**
   * We pass the setPipelineFieldState() setter to useSetter, giving us a function that controls both the outer state and inner elements individually for use with pipelineOnlyFields in renderPipelineFieldsEditor()
   */
  const setPipelineOnlyFieldDefinitionState: Setter<FieldDefinitionState> =
    useSetter(
      setValue,
      (oldPipelineFieldState, transformFieldDefinitionState) => {
        if (oldPipelineFieldState.type === "pipeline-only") {
          const { type, ...oldFieldDefinitionState } = oldPipelineFieldState;
          const newFieldDefinitionState: FieldDefinitionState =
            transformFieldDefinitionState(oldFieldDefinitionState);
          return { ...newFieldDefinitionState, type };
        } else {
          console.warn(
            "setPipelineOnlyFieldDefinitionState was called on a non pipeline-only field",
          );
          return oldPipelineFieldState;
        }
      },
      [],
    );

  /**
   * Function that returns the correct editor and setter depending on the type of field selected
   */
  const renderPipelineFieldsEditor = (value: PipelineFieldState) => {
    switch (value.type) {
      case "calculations":
        return (
          <PipelineReferenceEditor
            fieldsState={value}
            setFieldsState={setValue}
          />
        );
        break;
      case "product-specifications":
        return (
          <PipelineReferenceEditor
            fieldsState={value}
            setFieldsState={setValue}
          />
        );
        break;
      case "credit-applications":
        return (
          <PipelineReferenceEditor
            fieldsState={value}
            setFieldsState={setValue}
          />
        );
        break;
      case "pipeline-only":
        if (value.disposition) {
          if (value.disposition.kind === "native") {
            return (
              <NativeFieldEditor
                fieldType={"pipeline"}
                state={value as NativeFieldDefinitionState}
                setState={
                  setPipelineOnlyFieldDefinitionState as Setter<NativeFieldDefinitionState>
                }
                showErrors={showErrors}
                previousFields={[]} // todo add support for conditional pipeline only fields
                enumTypes={enumTypes}
                allowHeaders={allowHeaders}
              />
            );
          }
          if (value.disposition.kind === "inherited") {
            return (
              <InheritedFieldEditor
                fieldType={"pipeline"}
                state={value as InheritedFieldDefinitionState}
                showErrors={showErrors}
                setState={
                  setPipelineOnlyFieldDefinitionState as Setter<InheritedFieldDefinitionState>
                }
                disableControls={false}
                previousFields={[]} // todo add support for conditional pipeline only fields
                inheritableFields={loggedInInfo.config.allSystemFieldsById}
                enumTypes={enumTypes}
              />
            );
          }
        }

        break;
    }
  };

  return (
    <Box className={C.objectEditor}>{renderPipelineFieldsEditor(value)}</Box>
  );
});

/**
 * existingFieldNames is a list of all field names excluding pipeline only fields. It is used in convertPipelineFieldStateListItem for validation
 */
export const PipelineFieldsEditor = React.memo(function PipelineFieldsEditor({
  enumTypes,
  disableControls = false,
  pipelineFieldsState,
  setPipelineFieldsState,
  existingFieldNames,
}: {
  disableControls?: boolean;
  enumTypes: T.RawEnumType[];
  pipelineFieldsState: ObjectListState<PipelineFieldState>;
  setPipelineFieldsState: Setter<ObjectListState<PipelineFieldState>>;
  existingFieldNames: ISet<string>;
}) {
  const C = useObjectEditorStyles();
  const nonNullState = useSelector(nonNullApplicationInitializationSelector);
  const objectDetailsMap = useSelector(objectDetailsMapSelector);
  const loggedInInfo = useMemo(
    () => ({
      user: nonNullState.user,
      client: nonNullState.client,
      config: nonNullState.config,
      objectDetails: objectDetailsMap,
      notifications: nonNullState.notifications,
    }),
    [nonNullState, objectDetailsMap],
  );

  /**
   * Function passed to ObjectListEditor as validateObject(), it's called on each item in the ObjectListEditor state and either returns a valid state object or throws a UI validation error
   */
  const validatePipelineFieldDefinitionState = useCallback(
    (state: PipelineFieldState, fieldIndex: number) =>
      getValidationError(() => {
        convertPipelineFieldStateListItem(
          state,
          fieldIndex,
          loggedInInfo.config.allSystemFieldsById,
          enumTypes,
          existingFieldNames,
          pipelineFieldsState,
          pipelineFieldsState.objects.map((field) => field.id),
          loggedInInfo.config,
        );
      }),
    [loggedInInfo.config, enumTypes, existingFieldNames, pipelineFieldsState],
  );

  /**
   * Function passed to ObjectListEditor as makeObjectEditor(), it calls PipelineMakeFieldEditor to produce the UI for creating and editing pipeline fields
   */
  const makeFieldEditor = useCallback(
    ({
      value,
      showErrors,
      setValue,
    }: {
      value: PipelineFieldState;
      showErrors: boolean;
      setValue: Setter<PipelineFieldState>;
    }) => {
      return (
        <Box className={C.objectEditor}>
          <PipelineMakeFieldEditor
            value={value}
            setValue={setValue}
            showErrors={showErrors}
            enumTypes={enumTypes}
            allowHeaders={false}
          />
        </Box>
      );
    },
    [C.objectEditor, enumTypes],
  );

  /**
   * List of buttons for adding new objects to the ObjectListEditor, the type of object added is controlled by the button itself
   */
  const newButtons: ObjectListEditorButton<T.FieldId, PipelineFieldState>[] = [
    {
      name: "New",
      icon: <AddIcon />,
      onClick: (callbacks) =>
        callbacks.addObject({
          type: "pipeline-only",
          kind: "native",
          oldId: null,
          id: "" as T.FieldId,
          disposition: {
            kind: "native",
            name: "",
            description: "",
            valueType: newFieldValueTypeState(),
            conditions: [],
            domainValidationErrors: {
              hasErrors: false,
              name: [],
              description: [],
            },
          },
          domainValidationErrors: { hasErrors: false, oldId: [], id: [] },
        } as PipelineFieldState),
    },
  ];

  if (loggedInInfo.client.accessId !== "super") {
    [
      {
        name: "From System",
        icon: <AddIcon />,
        onClick: (callbacks) =>
          callbacks.addObject({
            type: "pipeline-only",
            oldId: null,
            id: "" as T.FieldId,
            disposition: {
              kind: "inherited",
              nameAlias: null,
              descriptionAlias: "",
              includeConditions: newObjectListState([]),
              additionalConditions: [],
              domainValidationErrors: {
                hasErrors: false,
                nameAlias: [],
                descriptionAlias: [],
              },
            },
            domainValidationErrors: { hasErrors: false, oldId: [], id: [] },
          } as PipelineFieldState),
      } as ObjectListEditorButton<T.FieldId, PipelineFieldState>,
    ].map((btn) => newButtons.unshift(btn));
    (
      [
        {
          name: "From Application Form",
          icon: <AddIcon />,
          onClick: (callbacks) =>
            callbacks.addObject({
              type: "credit-applications",
              id: "" as T.FieldId,
            } as PipelineFieldState),
        },
        {
          name: "From Product Specs",
          icon: <AddIcon />,
          onClick: (callbacks) =>
            callbacks.addObject({
              type: "product-specifications",
              id: "" as T.FieldId,
            } as PipelineFieldState),
        },
        {
          name: "From Calculations",
          icon: <AddIcon />,
          onClick: (callbacks) =>
            callbacks.addObject({
              type: "calculations",
              id: "" as T.FieldId,
            } as PipelineFieldState),
        },
      ] as ObjectListEditorButton<T.FieldId, PipelineFieldState>[]
    ).map((btn) => newButtons.push(btn));
  }

  /**
   * Function that handles displaying the appropriate name and flag for each item in the ObjectListEditor drag 'n drop list
   */
  const pipelineObjectLabel = (field: PipelineFieldState): JSX.Element => {
    const getFlag = (flag: string) => (
      <div
        style={{
          color: "#fff",
          backgroundColor: "#aaa",
          marginTop: "2px",
          fontSize: "0.8em",
          position: "relative",
          float: "right",
          width: "20%",
          textAlign: "center",
          borderRadius: "4px",
        }}
      >
        {flag}
      </div>
    );

    const getFieldName = (): JSX.Element => {
      switch (field.type) {
        case "pipeline-only":
          if (field.disposition.kind === "native") {
            return (
              <div>
                {field.disposition.name}
                {loggedInInfo.client.accessId !== "super" && getFlag("custom")}
              </div>
            );
          } else {
            const inherited = loggedInInfo.config.allSystemFieldsById.get(
              field.id,
            );

            return (
              <div>
                {field.disposition.nameAlias
                  ? field.disposition.nameAlias
                  : inherited?.name}
              </div>
            );
          }
          break;
        case "calculations":
          {
            const availableCalcs = [
              ...loggedInInfo.config.calcStages.flatMap((stage) => {
                return getFieldsFromStage(loggedInInfo.config.original, stage);
              }),
            ];
            const currentCalc = availableCalcs.find(
              (calc) => calc.id === field.id,
            );
            return (
              <div>
                {currentCalc?.name}
                {getFlag("calculations")}
              </div>
            );
          }
          break;
        case "credit-applications":
          {
            const currentCreditApp =
              loggedInInfo.config.creditApplicationFields.find(
                (app) => app.id === field.id,
              );
            return (
              <div>
                {currentCreditApp?.name}
                {getFlag("credit")}
              </div>
            );
          }
          break;
        case "product-specifications":
          {
            const stage = loggedInInfo.config.stages.find(
              (stage) => stage.id === "split-on-product",
            );
            const fields = getFieldsFromStage(
              loggedInInfo.config.original,
              stage!,
            );
            const currentProductSpec = fields.find(
              (app) => app.id === field.id,
            );

            return (
              <div>
                {currentProductSpec?.name}
                {getFlag("product")}
              </div>
            );
          }
          break;
      }
    };

    return getFieldName();
  };

  return (
    <Box
      style={{
        overflowY: "scroll",
      }}
      m={3}
      flex="1"
    >
      <div>
        <PrimaryTitle>
          {
            loggedInInfo.config.pipelineOnlyFields.find(
              (f) => f.id === pipelineFieldsState.objects[0].id,
            )?.name
          }
        </PrimaryTitle>
        <PrimaryTitleSub>Created by: Value</PrimaryTitleSub>
        <div>
          <KeyValue label="Created At" value="Value" />
          {pipelineFieldsState.objects.slice(1).map((f) => {
            return (
              <KeyValue
                label={
                  loggedInInfo.config.pipelineOnlyFields.find(
                    (x) => x.id === f.id,
                  )?.name ||
                  loggedInInfo.config.allFieldsById.get(f.id)?.name ||
                  ""
                }
                value="Value"
              />
            );
          })}
        </div>
      </div>

      <ObjectListEditor
        disableControls={disableControls}
        height="100%"
        state={pipelineFieldsState}
        additionalButtons={newButtons}
        getObjectLabel={pipelineObjectLabel}
        validateObject={validatePipelineFieldDefinitionState}
        makeObjectEditor={makeFieldEditor}
        setState={setPipelineFieldsState}
        emptyText="Click &ldquo;New&rdquo; to insert a new field into this list"
        itemHasAdditionalErrors={(obj) =>
          obj.type === "pipeline-only" && obj.domainValidationErrors.hasErrors
        }
        showErrors={true}
      />
    </Box>
  );
});
