import styled from "styled-components";

const DetailActions = styled.div.attrs(() => ({ className: "detail-actions" }))`
  flex: 0 0 auto;
  background-color: #f5f5f5;
  padding: 8px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 3;

  button {
    margin-left: 16px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

export default DetailActions;
