import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

type props = {
  text: string;
  type: string | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      padding: `10px`,
    },
  }),
);

export default function MessageBar(props: props) {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Typography
        variant="subtitle2"
        color={props.type === "success" ? "primary" : "secondary"}
      >
        {props.text}
      </Typography>
    </Box>
  );
}
