import { Map as IMap } from "immutable";
import React from "react";
import { Box } from "@material-ui/core";
import FieldViewer from "design/molecules/field-viewer";
import { Configuration } from "config";
import * as T from "types/generated-types";
import { useVisibleFields } from "features/roles";

const ProductFieldsSection = React.memo(
  ({
    config,
    fieldValuesById,
  }: {
    config: Configuration;
    fieldValuesById: IMap<T.FieldId, T.FieldValue | null>;
  }) => {
    const hasVisibleField = useVisibleFields();

    return (
      <>
        <Box fontSize={"1.25rem"}>Product Specifications</Box>
        <Box
          data-selector="product-fields-section"
          display="flex"
          flexWrap="wrap"
          mx={-1}
        >
          {config.productFields
            .filter((field) => hasVisibleField(field.id))
            .map((field) => (
              <FieldViewer
                key={field.id}
                fieldId={field.id}
                fieldsById={config.allFieldsById}
                fieldValuesById={fieldValuesById}
              />
            ))}
        </Box>
      </>
    );
  },
);

export default ProductFieldsSection;
