import { Snapshot } from "..";
import { Map as IMap, Set as ISet } from "immutable";
import * as T from "types/engine-types";
import {
  exportEnumTypeId,
  exportEnumVariantId,
  exportEnumVariantValue,
} from "../enums/export";
import { unreachable } from "features/utils";
import {
  getDataTableColumnKey,
  getDataTableKey,
  getPricingProfileKey,
  getRuleKey,
} from ".";
import {
  exportFieldId,
  exportFieldValue,
  exportFieldValueType,
} from "../fields/export";
import { exportProductId } from "../products/export";

export function exportRules(
  snapshot: Snapshot,
  ruleIds: ISet<T.RuleId>,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): T.NewRule[] {
  return snapshot.rules
    .filter((r) => ruleIds.has(r.id))
    .map((r) => ({
      name: r.name,
      stageId: r.stageId,
      activationTimestamp: r.activationTimestamp,
      deactivationTimestamp: r.deactivationTimestamp,
      grid: null,
      body: r.body && {
        precondition:
          r.body.precondition &&
          exportRuleCondition(snapshot, r.body.precondition, inheritableFields),
        lookup:
          r.body.lookup &&
          exportRuleDataTableLookup(snapshot, r.body.lookup, inheritableFields),
        condition: exportRuleCondition(
          snapshot,
          r.body.condition,
          inheritableFields,
        ),
        action: exportRuleAction(snapshot, r.body.action, inheritableFields),
      },
      productIds: r.productIds.map((productId) =>
        exportProductId(snapshot, productId),
      ),
    }));
}

function exportRuleDataTableLookup(
  snapshot: Snapshot,
  lookup: T.RuleDataTableLookup,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): T.RuleDataTableLookup {
  return {
    id: "" as T.DataTableLookupId,
    tableId: exportDataTableId(snapshot, lookup.tableId),
    predicates: lookup.predicates.map((predicate) =>
      exportDataTableColumnPredicate(
        snapshot,
        lookup.tableId,
        predicate,
        inheritableFields,
      ),
    ),
    fields: lookup.fields.map((field) =>
      exportRuleDataTableLookupFieldDefinition(snapshot, lookup.tableId, field),
    ),
  };
}

function exportDataTableColumnPredicate(
  snapshot: Snapshot,
  dataTableId: T.DataTableId,
  pred: T.DataTableColumnPredicate,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): T.DataTableColumnPredicate {
  switch (pred.kind) {
    case "equals":
      return {
        kind: pred.kind,
        expression: exportExpression(
          snapshot,
          pred.expression,
          inheritableFields,
        ),
        other: exportDataTableColumnId(snapshot, dataTableId, pred.other),
      };
    case "in-range":
      return {
        kind: pred.kind,
        expression: exportExpression(
          snapshot,
          pred.expression,
          inheritableFields,
        ),
        bottom: exportRangeBound(
          pred.bottom,
          exportDataTableColumnId.bind(null, snapshot, dataTableId),
        ),
        top: exportRangeBound(
          pred.top,
          exportDataTableColumnId.bind(null, snapshot, dataTableId),
        ),
      };
  }
}

function exportRangeBound<T>(
  bound: T.RangeBound<T>,
  exportT: (v: T) => T,
): T.RangeBound<T> {
  switch (bound.type) {
    case "included":
    case "excluded":
      return {
        type: bound.type,
        value: exportT(bound.value),
      };
    case "unbounded":
      return {
        type: "unbounded",
      };
  }
}

function exportRuleDataTableLookupFieldDefinition(
  snapshot: Snapshot,
  tableId: T.DataTableId,
  field: T.RuleDataTableLookupFieldDefinition,
): T.RuleDataTableLookupFieldDefinition {
  return {
    id: field.id, // Preserve LocalFieldId as is because it is local to this object
    name: field.name,
    valueType: exportFieldValueType(snapshot, field.valueType),
    columnId: exportDataTableColumnId(snapshot, tableId, field.columnId),
  };
}

function exportRuleCondition(
  snapshot: Snapshot,
  condition: T.RuleCondition,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): T.RuleCondition {
  switch (condition.type) {
    case "and":
    case "or":
      return {
        type: condition.type,
        children: condition.children.map((child) =>
          exportRuleCondition(snapshot, child, inheritableFields),
        ),
      };
    case "field-predicate":
      return {
        type: condition.type,
        predicate: exportFieldPredicate(
          snapshot,
          condition.predicate,
          inheritableFields,
        ),
      };
  }
}

function exportFieldPredicate(
  snapshot: Snapshot,
  predicate: T.FieldPredicate,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): T.FieldPredicate {
  switch (predicate.type) {
    case "generic":
      return exportGenericFieldPredicate(snapshot, predicate);
    case "enum":
      return exportEnumFieldPredicate(snapshot, predicate);
    case "object-ref":
      return exportObjectRefFieldPredicate(snapshot, predicate);
    case "string":
      return exportStringFieldPredicate(snapshot, predicate, inheritableFields);
    case "number":
      return exportNumberFieldPredicate(snapshot, predicate, inheritableFields);
    case "duration":
      return exportDurationFieldPredicate(
        snapshot,
        predicate,
        inheritableFields,
      );
    case "date":
      return exportDateFieldPredicate(snapshot, predicate, inheritableFields);
  }
}

function exportGenericFieldPredicate(
  snapshot: Snapshot,
  predicate: T.FieldPredicate.Generic,
): T.FieldPredicate.Generic {
  switch (predicate.op) {
    case "is-blank":
    case "is-not-blank":
      return {
        type: "generic" as const,
        op: predicate.op,
        fieldId: exportFieldId(snapshot, predicate.fieldId),
      };
  }
}

function exportEnumFieldPredicate(
  snapshot: Snapshot,
  predicate: T.FieldPredicate.Enum,
): T.FieldPredicate.Enum {
  switch (predicate.op) {
    case "matches":
    case "does-not-match":
      return {
        type: "enum" as const,
        op: predicate.op,
        fieldId: exportFieldId(snapshot, predicate.fieldId),
        enumTypeId: exportEnumTypeId(snapshot, predicate.enumTypeId),
        variantIds: predicate.variantIds.map((id) =>
          exportEnumVariantId(snapshot, predicate.enumTypeId, id),
        ),
      };
  }
}

function exportObjectRefFieldPredicate(
  snapshot: Snapshot,
  predicate: T.FieldPredicate.ObjectRef,
): T.FieldPredicate.ObjectRef {
  switch (predicate.op) {
    case "matches":
    case "does-not-match":
      return {
        type: "object-ref",
        op: predicate.op,
        fieldId: exportFieldId(snapshot, predicate.fieldId),
        objectRefs: predicate.objectRefs.map((ref) =>
          exportObjectRef(snapshot, ref),
        ),
      };
  }
}

function exportStringFieldPredicate(
  snapshot: Snapshot,
  predicate: T.FieldPredicate.String,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): T.FieldPredicate.String {
  switch (predicate.op) {
    case "equals":
    case "does-not-equal":
      return {
        type: "string" as const,
        op: predicate.op,
        fieldId: exportFieldId(snapshot, predicate.fieldId),
        other: exportExpression(snapshot, predicate.other, inheritableFields),
      };
    case "starts-with":
    case "does-not-start-with":
      return {
        type: "string" as const,
        op: predicate.op,
        fieldId: exportFieldId(snapshot, predicate.fieldId),
        prefix: exportExpression(snapshot, predicate.prefix, inheritableFields),
      };
    case "ends-with":
    case "does-not-end-with":
      return {
        type: "string" as const,
        op: predicate.op,
        fieldId: exportFieldId(snapshot, predicate.fieldId),
        suffix: exportExpression(snapshot, predicate.suffix, inheritableFields),
      };
    case "contains":
    case "does-not-contain":
      return {
        type: "string" as const,
        op: predicate.op,
        fieldId: exportFieldId(snapshot, predicate.fieldId),
        substring: exportExpression(
          snapshot,
          predicate.substring,
          inheritableFields,
        ),
      };
  }
}

function exportNumberFieldPredicate(
  snapshot: Snapshot,
  predicate: T.FieldPredicate.Number,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): T.FieldPredicate.Number {
  switch (predicate.op) {
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return {
        type: "number" as const,
        op: predicate.op,
        fieldId: exportFieldId(snapshot, predicate.fieldId),
        other: exportExpression(snapshot, predicate.other, inheritableFields),
      };
    case "between":
    case "not-between":
      return {
        type: "number" as const,
        op: predicate.op,
        fieldId: exportFieldId(snapshot, predicate.fieldId),
        bottom: exportExpression(snapshot, predicate.bottom, inheritableFields),
        top: exportExpression(snapshot, predicate.top, inheritableFields),
      };
  }
}

function exportDurationFieldPredicate(
  snapshot: Snapshot,
  predicate: T.FieldPredicate.Duration,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): T.FieldPredicate.Duration {
  switch (predicate.op) {
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return {
        type: "duration" as const,
        op: predicate.op,
        fieldId: exportFieldId(snapshot, predicate.fieldId),
        other: exportExpression(snapshot, predicate.other, inheritableFields),
      };
    case "between":
    case "not-between":
      return {
        type: "duration" as const,
        op: predicate.op,
        fieldId: exportFieldId(snapshot, predicate.fieldId),
        bottom: exportExpression(snapshot, predicate.bottom, inheritableFields),
        top: exportExpression(snapshot, predicate.top, inheritableFields),
      };
  }
}

function exportDateFieldPredicate(
  snapshot: Snapshot,
  predicate: T.FieldPredicate.Date,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): T.FieldPredicate.Date {
  switch (predicate.op) {
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return {
        type: "date" as const,
        op: predicate.op,
        fieldId: exportFieldId(snapshot, predicate.fieldId),
        other: exportExpression(snapshot, predicate.other, inheritableFields),
      };
    case "between":
    case "not-between":
      return {
        type: "date" as const,
        op: predicate.op,
        fieldId: exportFieldId(snapshot, predicate.fieldId),
        bottom: exportExpression(snapshot, predicate.bottom, inheritableFields),
        top: exportExpression(snapshot, predicate.top, inheritableFields),
      };
  }
}

function exportRuleAction(
  snapshot: Snapshot,
  action: T.RuleAction,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): T.RuleAction {
  switch (action.type) {
    case "reject":
    case "require-review":
      return {
        type: action.type,
        reason: exportExpression(snapshot, action.reason, inheritableFields),
      };
    case "add-price-adjustment":
    case "add-rate-adjustment":
    case "add-margin-adjustment":
    case "final-rate-adjustment":
    case "final-price-adjustment":
    case "final-margin-adjustment":
      return {
        type: action.type,
        adjustmentValue: exportExpression(
          snapshot,
          action.adjustmentValue,
          inheritableFields,
        ),
        adjustmentName: exportExpression(
          snapshot,
          action.adjustmentName,
          inheritableFields,
        ),
      };
    case "add-stipulation":
      return {
        type: action.type,
        stipulationText: exportExpression(
          snapshot,
          action.stipulationText,
          inheritableFields,
        ),
      };
  }
}

export function exportFieldValueMapping(
  snapshot: Snapshot,
  mapping: T.FieldValueMapping,
): T.FieldValueMapping {
  return {
    fieldId: exportFieldId(snapshot, mapping.fieldId),
    value: exportFieldValue(snapshot, mapping.value),
  };
}

function exportExpression(
  snapshot: Snapshot,
  expr: T.Expression,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): T.Expression {
  function recurse(expr: T.Expression): T.Expression {
    return exportExpression(snapshot, expr, inheritableFields);
  }

  switch (expr.kind) {
    case "field-value":
    case "field-value-to-string":
      return {
        kind: expr.kind,
        fieldId: exportFieldId(snapshot, expr.fieldId),
      };
    case "local-field-value":
    case "local-field-value-to-string":
      // We assume that local fields are local to the object being exported and
      // preserve their LocalFieldIds as is
      return expr;
    case "list":
      return {
        kind: expr.kind,
        elements: expr.elements.map(recurse),
      };
    case "duration":
      return {
        kind: expr.kind,
        count: recurse(expr.count),
        unit: expr.unit,
      };
    case "literal":
      return {
        kind: expr.kind,
        value: exportValue(snapshot, expr.value),
      };
    case "condition":
      return {
        kind: expr.kind,
        cond: exportCondition(snapshot, expr.cond, inheritableFields),
      };
    case "if-else":
      return {
        kind: expr.kind,
        cond: recurse(expr.cond),
        ifTrue: recurse(expr.ifTrue),
        ifFalse: recurse(expr.ifFalse),
      };
    case "function-call":
      return {
        kind: expr.kind,
        func: recurse(expr.func),
        args: expr.args.map(recurse),
      };
  }
}

function exportCondition(
  snapshot: Snapshot,
  cond: T.Condition,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): T.Condition {
  switch (cond.kind) {
    case "and":
    case "or":
      return {
        kind: cond.kind,
        children: cond.children.map((child) =>
          exportExpression(snapshot, child, inheritableFields),
        ),
      };
    case "not":
      return {
        kind: cond.kind,
        child: exportExpression(snapshot, cond.child, inheritableFields),
      };
    case "predicate":
      return {
        kind: cond.kind,
        predicate: exportPredicate(snapshot, cond.predicate, inheritableFields),
      };
  }
}

function exportPredicate(
  snapshot: Snapshot,
  pred: T.Predicate,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): T.Predicate {
  switch (pred.kind) {
    case "unary":
      return {
        kind: pred.kind,
        left: exportExpression(snapshot, pred.left, inheritableFields),
        op: pred.op,
      };
    case "binary":
      return {
        kind: pred.kind,
        left: exportExpression(snapshot, pred.left, inheritableFields),
        op: pred.op,
        right: exportExpression(snapshot, pred.right, inheritableFields),
      };
  }
}

function exportValue(snapshot: Snapshot, value: T.Value): T.Value {
  function recurse(value: T.Value): T.Value {
    return exportValue(snapshot, value);
  }

  switch (value.type) {
    case "enum":
      return {
        type: value.type,
        value: exportEnumVariantValue(snapshot, value.value),
      };
    case "object-ref":
      return {
        type: value.type,
        value: exportObjectRef(snapshot, value.value),
      };
    case "list":
      return {
        type: value.type,
        value: value.value.map(recurse),
      };
    case "number":
    case "string":
    case "duration":
    case "date":
    case "boolean":
    case "builtin-func":
      return value;
    default:
      return unreachable(value);
  }
}

export function exportObjectRef(
  snapshot: Snapshot,
  objectRef: T.ObjectRef,
): T.ObjectRef {
  switch (objectRef.type) {
    case "pricing-profile":
      const pricingProfile = snapshot.objectDetails.pricingProfiles.get(
        objectRef.id,
      );
      if (!pricingProfile) {
        throw new Error(
          `Pricing profile not found: ID = ${JSON.stringify(objectRef.id)}`,
        );
      }

      return {
        type: "pricing-profile",
        id: getPricingProfileKey(pricingProfile) as T.PricingProfileId,
      };
    default:
      return unreachable(objectRef.type);
  }
}

function exportDataTableId(
  snapshot: Snapshot,
  dataTableId: T.DataTableId,
): T.DataTableId {
  const dataTable = snapshot.dataTables.find((t) => t.id === dataTableId);

  if (!dataTable) {
    throw new Error(
      "Data table not found: ID = " + JSON.stringify(dataTableId),
    );
  }

  return getDataTableKey(dataTable) as T.DataTableId;
}

function exportDataTableColumnId(
  snapshot: Snapshot,
  dataTableId: T.DataTableId,
  dataTableColumnId: T.DataTableColumnId,
): T.DataTableColumnId {
  const dataTable = snapshot.dataTables.find((t) => t.id === dataTableId);

  if (!dataTable) {
    throw new Error(
      "Data table not found: ID = " + JSON.stringify(dataTableId),
    );
  }

  const column = dataTable.columnDefs.find((c) => c.id === dataTableColumnId);

  if (!column) {
    throw new Error(
      "Data table column not found: ID = " + JSON.stringify(dataTableColumnId),
    );
  }

  return getDataTableColumnKey(column) as T.DataTableColumnId;
}

export function exportRuleId(snapshot: Snapshot, ruleId: T.RuleId): T.RuleId {
  const rule = snapshot.rules.find((r) => r.id === ruleId);

  if (!rule) {
    throw new Error("Rule not found: ID = " + JSON.stringify(ruleId));
  }

  return getRuleKey(rule) as T.RuleId;
}
