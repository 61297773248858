import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as Api from "api";
import * as T from "types/engine-types";
import { createSelector } from "reselect";
import { PricingProfileChangeset } from "types/generated-types";
import filterResults from "features/filter-list";
import { getMyPricingProfiles } from "features/application-initialization";

export type PricingProfilesState = {
  pricingProfiles: T.PricingProfileHeader[];
  searchTerm: string;
  loading: boolean;
  errors: string;
};

const initialState: PricingProfilesState = {
  pricingProfiles: [],
  loading: false,
  searchTerm: "",
  errors: "",
};

type RepositionPricingProfileInput = {
  id: T.PricingProfileId;
  repositionRequest: T.PricingProfileRepositionRequest;
};

export const savePositioning = createAsyncThunk(
  "pricingProfiles/savePositioning",
  async (input: RepositionPricingProfileInput) => {
    return await Api.repositionPricingProfile(
      input.id,
      input.repositionRequest,
    );
  },
);

export const getPricingProfiles = createAsyncThunk(
  "pricingProfiles/getPricingProfiles",
  async () => {
    return await Api.getPricingProfiles();
  },
);

type CreatePricingProfileInput = {
  pricingProfile: T.NewPricingProfile;
  usersToAdd: T.DetailedUser[];
  productsToAdd: T.ProductHeader[];
};

export const createPricingProfile = createAsyncThunk(
  "pricingProfiles/create",
  async (input: CreatePricingProfileInput, thunkAPI) => {
    try {
      const newProfile = await Api.createPricingProfile(input.pricingProfile);
      thunkAPI.dispatch(getMyPricingProfiles());
      thunkAPI.dispatch(getPricingProfiles());

      Api.assignPricingProfileUsers(
        newProfile.id,
        input.usersToAdd.map((u) => u.id),
      );
      Api.assignPricingProfileProducts(
        newProfile.id,
        input.productsToAdd.map((p) => p.id),
      );

      return "success";
    } catch (error: unknown) {
      if (error instanceof Error) {
        newrelic.noticeError(error);
        if (error.message.includes("pricing_profiles_client_id_name_key")) {
          thunkAPI.dispatch(
            setErrors(
              "Save failed because a Pricing Profile with this name already exists.",
            ),
          );
        } else if (error.message) {
          thunkAPI.dispatch(setErrors(error.message));
        } else {
          thunkAPI.dispatch(setErrors("Save failed because of an error."));
        }
      }

      setTimeout(() => thunkAPI.dispatch(setErrors("")), 5000);

      return "error";
    }
  },
);

export const updatePricingProfile = createAsyncThunk(
  "pricingProfiles/update",
  async (
    params: {
      pricingProfile: {
        id: T.PricingProfileId;
        changeset: PricingProfileChangeset;
      };
      usersToAdd: T.DetailedUser[];
      productsToAdd: T.ProductHeader[];
    },
    thunkAPI,
  ) => {
    try {
      const { pricingProfile, usersToAdd, productsToAdd } = params;
      await Api.updatePricingProfile(
        pricingProfile.id,
        pricingProfile.changeset,
      );
      thunkAPI.dispatch(getMyPricingProfiles());
      thunkAPI.dispatch(getPricingProfiles());

      Api.assignPricingProfileUsers(
        pricingProfile.id,
        usersToAdd.map((u) => u.id),
      );
      Api.assignPricingProfileProducts(
        pricingProfile.id,
        productsToAdd.map((p) => p.id),
      );

      return "success";
    } catch (error: unknown) {
      if (error instanceof Error) {
        newrelic.noticeError(error);
        if (error.message.includes("pricing_profiles_client_id_name_key")) {
          thunkAPI.dispatch(
            setErrors(
              "Save failed because a Pricing Profile with this name already exists.",
            ),
          );
        } else if (error.message) {
          thunkAPI.dispatch(setErrors(error.message));
        } else {
          thunkAPI.dispatch(setErrors("Save failed because of an error."));
        }
      }
      setTimeout(() => thunkAPI.dispatch(setErrors("")), 5000);

      return "error";
    }
  },
);

export const deletePricingProfileById = createAsyncThunk(
  "pricingProfiles/delete",
  async (pricingProfileId: T.PricingProfileId, thunkAPI) => {
    try {
      thunkAPI.dispatch(setErrors(""));
      await Api.deletePricingProfile(pricingProfileId);
      thunkAPI.dispatch(getMyPricingProfiles());
      thunkAPI.dispatch(getPricingProfiles());
      return "success";
    } catch (error) {
      if (error instanceof Error) {
        newrelic.noticeError(error);
        if (error.message) {
          thunkAPI.dispatch(setErrors(error.message));
        } else {
          thunkAPI.dispatch(setErrors("Save failed because of an error."));
        }
      }
      setTimeout(() => thunkAPI.dispatch(setErrors("")), 5000);

      return "error";
    }
  },
);

const pricingProfileSlice = createSlice({
  name: "PricingProfiles",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(deletePricingProfileById.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getPricingProfiles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPricingProfiles.fulfilled, (state, { payload }) => {
      state.pricingProfiles = payload;
      state.loading = false;
    });
  },
  reducers: {
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },
    setPricingProfiles: (
      state,
      { payload }: PayloadAction<T.PricingProfileHeader[]>,
    ) => {
      state.pricingProfiles = payload;
    },
  },
});

export const { setLoading, setErrors, setPricingProfiles, setSearchTerm } =
  pricingProfileSlice.actions;

export default pricingProfileSlice.reducer;

export const pricingProfilesSelector = (state: {
  pricingProfiles: PricingProfilesState;
}) => state.pricingProfiles;

export const filteredPricingProfilesSelector = createSelector(
  [
    (state: { pricingProfiles: PricingProfilesState }) =>
      state.pricingProfiles.pricingProfiles,
    (state: { pricingProfiles: PricingProfilesState }) =>
      state.pricingProfiles.searchTerm,
  ],
  (pricingProfiles, searchTerm) => {
    const filtered: T.PricingProfileHeader[] = [];
    pricingProfiles.forEach((pricingProfile: T.PricingProfileHeader) => {
      const { noNotQuoteMatches, quoteMatch, restMatch } = filterResults(
        searchTerm,
        [pricingProfile?.name || ""],
      );

      if (noNotQuoteMatches && quoteMatch && restMatch)
        filtered.push(pricingProfile);
    });

    return filtered;
  },
);
