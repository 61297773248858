const fontSizes = {
  xxl: "24px",
  xl: "18px",
  l: "15px",
  m: "13px",
  s: "12px",
  xs: "11px",
};

export default fontSizes;
