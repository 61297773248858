import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Set as ISet, Map as IMap } from "immutable";
import { Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import * as T from "types/engine-types";
import { Setter, getValidationError } from "features/utils";
import {
  nonNullApplicationInitializationSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import {
  newFieldValueTypeState,
  FieldType,
} from "design/organisms/field-value-type-editor";
import {
  ObjectListEditor,
  ObjectListState,
  newObjectListState,
  ObjectListEditorButton,
} from "design/organisms/object-list-editor";
import {
  FieldDefinitionState,
  NativeFieldDefinitionState,
  InheritedFieldDefinitionState,
  useObjectEditorStyles,
  getListLabel,
  convertFieldStateListItem,
} from "pages/fields";
import { NativeFieldEditor } from "../native-field-editor";
import { InheritedFieldEditor } from "../inherited-field-editor";

function getFieldObjectListLabel(
  state: FieldDefinitionState,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
  isSuperClient: boolean,
): JSX.Element {
  let fieldType: FieldType | null = null;
  let name: string | null = null;

  if (state.disposition.kind === "native") {
    fieldType = state.disposition.valueType.fieldType;
    name = state.disposition.name;
  } else {
    const inherited = inheritableFields.get(state.id);
    if (inherited) {
      fieldType = inherited.valueType.type;
      name = state.disposition.nameAlias
        ? state.disposition.nameAlias
        : inherited.name;
    }
  }

  return getListLabel(
    name || "<Unknown>",
    fieldType === "header",
    state.disposition.kind === "native" && !isSuperClient,
  );
}

export const FieldsEditor = React.memo(
  ({
    fieldType,
    fieldsState,
    setFieldsState,
    enumTypes,
    existingFieldNames,
    allowHeaders,
    disableControls = false,
  }: {
    fieldType: "credit-application" | "product";
    disableControls?: boolean;
    fieldsState: ObjectListState<FieldDefinitionState>;
    setFieldsState: Setter<ObjectListState<FieldDefinitionState>>;
    enumTypes: T.RawEnumType[];
    existingFieldNames: ISet<string>;
    allowHeaders: boolean;
  }) => {
    const C = useObjectEditorStyles();
    const nonNullState = useSelector(nonNullApplicationInitializationSelector);
    const objectDetailsMap = useSelector(objectDetailsMapSelector);

    const loggedInInfo = {
      user: nonNullState.user,
      client: nonNullState.client,
      config: nonNullState.config,
      objectDetails: objectDetailsMap,
      notifications: nonNullState.notifications,
    };
    const makeFieldEditor = useCallback(
      ({
        value,
        showErrors,
        index,
        setValue,
      }: {
        value: FieldDefinitionState;
        showErrors: boolean;
        index: number;
        setValue: Setter<FieldDefinitionState>;
      }) => {
        const previousFields = fieldsState.objects.slice(0, index);
        return (
          <Box className={C.objectEditor}>
            {value.disposition.kind === "native" && (
              <NativeFieldEditor
                fieldType={fieldType}
                state={value as NativeFieldDefinitionState}
                showErrors={showErrors}
                setState={setValue as Setter<NativeFieldDefinitionState>}
                previousFields={previousFields}
                enumTypes={enumTypes}
                allowHeaders={allowHeaders}
              />
            )}

            {value.disposition.kind === "inherited" && (
              <InheritedFieldEditor
                fieldType={fieldType}
                state={value as InheritedFieldDefinitionState}
                showErrors={showErrors}
                setState={setValue as Setter<InheritedFieldDefinitionState>}
                disableControls={disableControls}
                previousFields={previousFields}
                inheritableFields={loggedInInfo.config.allSystemFieldsById}
                enumTypes={enumTypes}
              />
            )}
          </Box>
        );
      },
      [
        enumTypes,
        fieldsState,
        fieldType,
        allowHeaders,
        disableControls,
        C.objectEditor,
        loggedInInfo.config.allSystemFieldsById,
      ],
    );

    const validateFieldDefinitionState = useCallback(
      (state: FieldDefinitionState, fieldIndex: number) =>
        getValidationError(() => {
          convertFieldStateListItem(
            existingFieldNames,
            fieldsState,
            state,
            fieldIndex,
            loggedInInfo.config.allSystemFieldsById,
            enumTypes,
          );
        }),
      [
        existingFieldNames,
        fieldsState,
        loggedInInfo.config.allSystemFieldsById,
        enumTypes,
      ],
    );

    const newButtons = [
      {
        name: "New",
        icon: <AddIcon />,
        onClick: (callbacks) =>
          callbacks.addObject({
            oldId: null,
            id: "" as T.FieldId,
            disposition: {
              kind: "native",
              name: "",
              description: "",
              valueType: newFieldValueTypeState(),
              conditions: [],
              domainValidationErrors: {
                hasErrors: false,
                name: [],
                description: [],
              },
            },
            domainValidationErrors: { hasErrors: false, oldId: [], id: [] },
          } as FieldDefinitionState),
      } as ObjectListEditorButton<T.FieldId, FieldDefinitionState>,
    ];

    // Super client doesn't have anywhere to inherit enums from, so
    // we don't show the "From System" button.
    if (loggedInInfo.client.accessId !== "super") {
      newButtons.unshift({
        name: "From System",
        icon: <AddIcon />,
        onClick: (callbacks) =>
          callbacks.addObject({
            oldId: null,
            id: "" as T.FieldId,
            disposition: {
              kind: "inherited",
              nameAlias: null,
              descriptionAlias: "",
              includeConditions: newObjectListState([]),
              additionalConditions: [],
              domainValidationErrors: {
                hasErrors: false,
                nameAlias: [],
                descriptionAlias: [],
              },
            },
            domainValidationErrors: { hasErrors: false, oldId: [], id: [] },
          } as FieldDefinitionState),
      });
    }
    return (
      <Box m={3} flex="1">
        <ObjectListEditor
          disableControls={disableControls}
          height="100%"
          state={fieldsState}
          additionalButtons={newButtons}
          getObjectLabel={(o) =>
            getFieldObjectListLabel(
              o,
              loggedInInfo.config.allSystemFieldsById,
              loggedInInfo.client.accessId === "super",
            )
          }
          validateObject={validateFieldDefinitionState}
          makeObjectEditor={makeFieldEditor}
          setState={setFieldsState}
          emptyText="Click &ldquo;New&rdquo; to insert a new field into this list"
          itemHasAdditionalErrors={(obj) =>
            obj.domainValidationErrors.hasErrors
          }
        />
      </Box>
    );
  },
);
