import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import Loader from "react-loader";
import { Map as IMap, Set as ISet } from "immutable";
import _ from "lodash";
import { getProducts } from "features/products";
import { expandedConfigSelector } from "features/application-initialization";
import { useSelector } from "react-redux";

import * as Api from "api";
import MessageBar from "design/atoms/message-bar";
import {
  ProductState,
  validateProductState,
  convertProductToState,
  convertStateToProduct,
  ProductEditor,
} from "../_components/product-editor";
import UnloadPrompt from "design/atoms/unload-prompt";
import {
  Product,
  ProductId,
  DecoratedRuleHeader,
  DecoratedProductHeader,
  DecoratedInvestorHeader,
} from "types/engine-types";
import { multimap } from "features/utils";
import { useDispatch } from "react-redux";
import { localAccessId } from "features/access-id";

type Params = { id: ProductId };

export default function EditProductPage() {
  const { id } = useParams<Params>();

  const [rulesLoadState] = Api.useRules();
  const [productLoadState] = Api.useProduct(id);
  const [productsLoadState] = Api.useProducts();
  const [investorsLoadState] = Api.useInvestors();
  const combinedLoadState = Api.combineLoadStates(
    rulesLoadState,
    productLoadState,
    productsLoadState,
    investorsLoadState,
  );

  if (combinedLoadState.status === "loading") {
    return <Loader loaded={false} />;
  }

  if (combinedLoadState.status === "error") {
    return (
      <MessageBar
        text="Something went wrong, please check your internet connection and try again later. If you see this message again, please contact support."
        type="error"
      />
    );
  }

  const [rules, product, products, investors] = combinedLoadState.value;

  return <EditProductPageLoaded {...{ rules, product, products, investors }} />;
}

type LoadedProps = {
  product: Product;
  rules: DecoratedRuleHeader[];
  products: DecoratedProductHeader[];
  investors: DecoratedInvestorHeader[];
};

function EditProductPageLoaded({
  product,
  rules,
  products,
  investors,
}: LoadedProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const accessId = localAccessId();

  const config = useSelector(expandedConfigSelector);

  const rulesById = IMap(rules.map((r) => [r.id, r]));

  const [productStateModified, setProductState] = useState(() =>
    convertProductToState(config, product, rulesById),
  );

  const [isSave, setIsSave] = useState(false);

  const otherProducts = products.filter((p) => p.id !== product.id);
  const existingNamesByInvestorId = multimap(
    otherProducts.map((p) => [p.investorId, p.name]),
  );
  const existingCodes = ISet(otherProducts.map((p) => p.code));

  const copyOfOriginalProductState: ProductState | null = _.cloneDeep(
    convertProductToState(config, product, rulesById),
  );

  // Use product data from server if there have been no state changes yet
  const productState =
    productStateModified || convertProductToState(config, product, rulesById);

  const currentError = validateProductState(
    config,
    productState,
    existingNamesByInvestorId,
    existingCodes,
  );

  const saveProduct = async () => {
    setIsSave(true);

    setShowValidationErrors(true);

    if (currentError) {
      setIsSave(false);
      return;
    }

    const updatedProduct = convertStateToProduct(
      productState,
      config,
      existingNamesByInvestorId,
      existingCodes,
    ) as Product;
    updatedProduct.id = product.id;

    setLoading(true);
    const newProduct = await Api.saveProduct(updatedProduct);
    setLoading(false);
    dispatch(getProducts());
    history.push(`/c/${accessId}/products/${newProduct.id}`);
  };

  // The route change will be blocked if the state has changed AND user did not hit the save button
  const shouldBlock =
    !_.isEqual(copyOfOriginalProductState, productState) && !isSave;

  return (
    <>
      <UnloadPrompt when={shouldBlock} />
      <Loader loaded={!loading} />
      <Box m={2} display="flex">
        <Button
          variant="outlined"
          startIcon={<CloseIcon />}
          onClick={() => history.push(`/c/${accessId}/products/${product.id}`)}
        >
          Cancel
        </Button>
        <Box flex="1" />
        {showValidationErrors && currentError && (
          <Box display="flex" alignItems="center" px={2}>
            <Typography color="secondary">
              Error: {currentError.message}
            </Typography>
          </Box>
        )}
        <Button
          variant="outlined"
          startIcon={<DoneIcon />}
          onClick={saveProduct}
          disabled={loading || (showValidationErrors && !!currentError)}
        >
          Save
        </Button>
      </Box>

      <ProductEditor
        title="Edit product"
        rules={rules}
        investors={investors}
        existingNamesByInvestorId={existingNamesByInvestorId}
        existingCodes={existingCodes}
        state={productState}
        showValidationErrors={showValidationErrors}
        setState={setProductState}
      />
    </>
  );
}
