import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DoneIcon from "@material-ui/icons/Done";

import {
  RateSheetFormatEditor,
  convertStateToRateSheetFormat,
  newRateSheetFormatState,
  validateRateSheetFormatState,
} from "../_components/rate-sheet-format-editor";
import UnloadPrompt from "design/atoms/unload-prompt";
import * as Api from "api";
import { localAccessId } from "features/access-id";

export const CreateRateSheetFormatPage = React.memo(() => {
  const history = useHistory();
  const accessId = localAccessId();

  const [state, setState] = useState(() => newRateSheetFormatState());

  const validationError = useMemo(
    () => validateRateSheetFormatState(state),
    [state],
  );

  const [isUnsaved, setIsUnsaved] = useState(true);

  const handleCreate = useCallback(async () => {
    if (validationError) {
      alert(validationError.message);
      return;
    }

    const newFormat = convertStateToRateSheetFormat(state);

    const format = await Api.Admin.createRateSheetFormat(newFormat);

    setIsUnsaved(false);

    history.push(`/c/${accessId}/__admin/rate-sheet-formats/${format.id}`);
  }, [state, validationError, history, accessId]);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <UnloadPrompt when={isUnsaved} />

      <Box>
        <Box mt={2} mx={2} display="flex">
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() =>
              history.push(`/c/${accessId}/__admin/rate-sheet-formats`)
            }
          >
            Back to Rate Sheet Formats
          </Button>
          <Box flex="1" />
          {validationError && (
            <Box display="flex" alignItems="center" px={2}>
              <Typography color="secondary">
                Error: {validationError.message}
              </Typography>
            </Box>
          )}
          <Button
            variant="outlined"
            startIcon={<DoneIcon />}
            onClick={handleCreate}
          >
            Create
          </Button>
        </Box>
      </Box>

      <Box flex="1" overflow="hidden">
        <RateSheetFormatEditor state={state} setState={setState} />
      </Box>
    </Box>
  );
});
