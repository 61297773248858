import { Portal } from "react-portal";
import React, { useCallback, useEffect } from "react";
import { Typography, Paper } from "@material-ui/core";
import * as Ta from "design/organisms/table";
import TableChartIcon from "@material-ui/icons/TableChart";
import * as T from "types/engine-types";
import { setOpenProduct, loanStatusToString } from "features/loans";
import Draggable from "react-draggable";
import { filteredSummaryProductsSelector } from "features/pricing-summaries";
import { getLowest } from "features/pricing-summaries";
import { durationValueToString } from "features/fields";
import { ObjectDetails } from "features/objects";
import SummaryPriceScenarioTable from "../summary-price-scenario-table";
import ClearIcon from "@material-ui/icons/Clear";
import { Configuration } from "config";
import { useDispatch, useSelector } from "react-redux";
import color from "design/subatomics/colors";
import Popover from "design/layout/popover";
import Icon from "design/atoms/icon";

import {
  faCircle,
  faExclamationCircle,
  faCheckCircle,
  faCircleXmark,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";

const ProductResultsListItem = React.memo(
  ({
    investors,
    requestTime,
    index,
    result,
    config,
    objectDetails,
    size,
    start,
  }: {
    investors: T.DecoratedInvestorHeader[];
    requestTime: string;
    index: number;
    size: number;
    start: number;
    result: T.ExecutionProductSummary;
    config: Configuration;
    objectDetails: ObjectDetails;
  }) => {
    const dispatch = useDispatch();
    const [offset, setOffset] = React.useState<string>("0px");
    const [open, setOpen] = React.useState(false);
    const lowest = getLowest(result);
    const summaryProducts = useSelector(filteredSummaryProductsSelector);
    const withPricing = summaryProducts.some(
      (p) => p.status === "approved" || p.status === "review-required",
    );
    const isInvestorPricingEnabled = investors.find(
      (i) => result.investorCode === i.code,
    )?.isPricingEnabled;

    useEffect(() => {
      const tableBody: HTMLDivElement | null = document.querySelector(
        ".table-body-wrapper",
      );

      const stringOffset =
        String(
          Number(
            (tableBody?.offsetTop || 0) -
              (tableBody?.scrollTop ? tableBody.scrollTop : 0),
          ) + Number(start + size),
        ) + "px";

      setOffset(stringOffset);
      setOpen(false);
    }, [start, size, requestTime]);

    let missingFieldName;
    if (result.status === "available") {
      missingFieldName = result.requiredFieldIds?.map((id) => {
        const field = config.allFieldsById.get(id);
        return field ? field.name : "<Unknown Field>";
      })[0];
    }

    const status = result.status;
    const statusText = loanStatusToString(status);

    const handleClick = useCallback(() => {
      dispatch(setOpenProduct(result.productId));
    }, [dispatch, result]);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const rateConfigType =
      config.settings.priceScenarioTable?.type === "rate-with-lock-period"
        ? config.allFieldsById.get(
            config.settings.priceScenarioTable.adjustedRateFieldId,
          )?.valueType
        : null;

    const priceConfigType =
      config.settings.priceScenarioTable?.type === "rate-with-lock-period"
        ? config.allFieldsById.get(
            config.settings.priceScenarioTable.adjustedPriceFieldId,
          )?.valueType
        : null;

    let iconColor;
    let icon = faCircle;
    let rowBg = "transparent";
    switch (status) {
      case "approved":
        icon = faCheckCircle;
        rowBg = color({ color: "green", shade: 10 });
        iconColor = color({ color: "green", shade: 5 });
        break;
      case "available":
        iconColor = color({ color: "blue", shade: 5 });
        break;
      case "error":
        icon = faExclamationCircle;
        rowBg = color({ color: "red", shade: 10 });
        iconColor = color({ color: "red", shade: 5 });
        break;
      case "missing-configuration":
        icon = faExclamationCircle;
        rowBg = color({ color: "red", shade: 10 });
        iconColor = color({ color: "red", shade: 5 });
        break;
      case "no-pricing":
        icon = faExclamationCircle;
        rowBg = color({ color: "red", shade: 10 });
        iconColor = color({ color: "red", shade: 5 });
        break;
      case "rejected":
        icon = faCircleXmark;
        rowBg = color({ color: "gray", shade: 10 });
        iconColor = color({ color: "gray", shade: 7 });
        break;
      case "review-required":
        icon = faCircleQuestion;
        iconColor = color({ color: "orange", shade: 5 });
        break;
      default:
        break;
    }

    return (
      <Ta.TableRow
        key={index}
        style={{
          height: `${size}px`,
          transform: `translateY(${start}px)`,
          background: rowBg,
          color:
            status === "rejected"
              ? color({ color: "gray", shade: 6 })
              : color({ color: "gray", shade: 1 }),
          borderBottom: `1px solid ${color({ color: "lightBorder" })}`,
        }}
      >
        <Ta.TableCell onClick={handleClick} style={{ flexBasis: "10%" }}>
          <span style={{ marginRight: "8px", color: iconColor }}>
            <Icon icon={icon} />
          </span>
          <Popover
            trigger={statusText}
            content={statusText}
            className={"custom-tooltip"}
            delayShow={500}
          />
        </Ta.TableCell>

        <Ta.TableCell onClick={handleClick} style={{ flexBasis: "12.5%" }}>
          <Popover
            trigger={missingFieldName}
            content={missingFieldName}
            className={"custom-tooltip"}
            delayShow={500}
          />
        </Ta.TableCell>

        <Ta.TableCell onClick={handleClick} style={{ flexBasis: "30.5%" }}>
          <Popover
            trigger={result.productName}
            content={result.productName}
            className={"custom-tooltip"}
            delayShow={500}
          />
        </Ta.TableCell>

        <Ta.TableCell onClick={handleClick} style={{ flexBasis: "15%" }}>
          <Popover
            trigger={result.investorName}
            content={result.investorName}
            className={"custom-tooltip"}
            delayShow={500}
          />
        </Ta.TableCell>

        {withPricing && (
          <>
            <Ta.TableCell
              onClick={handleClick}
              style={{ flexBasis: "8.5%", textAlign: "right" }}
            >
              {(result.status === "approved" ||
                result.status === "review-required") &&
                lowest?.adjustedPrice &&
                priceConfigType?.type === "number" && (
                  <>
                    {parseFloat(lowest.adjustedPrice).toFixed(
                      priceConfigType.precision,
                    )}
                  </>
                )}
            </Ta.TableCell>

            <Ta.TableCell
              onClick={handleClick}
              style={{ flexBasis: "8.5%", textAlign: "right" }}
            >
              {(result.status === "approved" ||
                result.status === "review-required") &&
                lowest?.adjustedRate &&
                rateConfigType?.type === "number" && (
                  <>
                    {parseFloat(lowest.adjustedRate).toFixed(
                      rateConfigType.precision,
                    ) + "%"}{" "}
                  </>
                )}
            </Ta.TableCell>

            <Ta.TableCell
              onClick={handleClick}
              style={{ flexBasis: "8.5%", textAlign: "right" }}
            >
              {(result.status === "approved" ||
                result.status === "review-required") &&
                lowest?.adjustedRateLockPeriod &&
                durationValueToString(lowest.adjustedRateLockPeriod)}
            </Ta.TableCell>
          </>
        )}

        <Ta.TableCell style={{ flexBasis: "6.5%", textAlign: "right" }}>
          {(result.status === "approved" ||
            result.status === "review-required") &&
            config.settings.priceScenarioTable?.type ===
              "rate-with-lock-period" && (
              <>
                <TableChartIcon
                  data-selector="draggable-popup-button"
                  onClick={open ? handleClose : handleClickOpen}
                />
                <Portal>
                  {open && (
                    <Draggable handle={`#product${result.productId}`}>
                      <Paper
                        style={{
                          position: "absolute",
                          top: offset,
                          right: "9px",
                          padding: "8px",
                          minWidth: "500px",
                          zIndex: (index + 1) * 9999,
                        }}
                        id={`product${result.productId}`}
                      >
                        <ClearIcon
                          data-selector="draggable-close-button"
                          onClick={handleClose}
                          style={{
                            color: "rgba(0, 0, 0, 0.87)",
                            cursor: "pointer",
                            position: "absolute",
                            top: "8px",
                            right: "8px",
                          }}
                        />
                        <Typography>{result.productName}</Typography>
                        <Typography>{result.investorName}</Typography>
                        <SummaryPriceScenarioTable
                          isInvestorPricingEnabled={isInvestorPricingEnabled}
                          result={result}
                          config={config}
                          objectDetails={objectDetails}
                        />
                      </Paper>
                    </Draggable>
                  )}
                </Portal>
              </>
            )}
        </Ta.TableCell>
      </Ta.TableRow>
    );
  },
);

export default ProductResultsListItem;
