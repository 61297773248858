import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useSelector, useDispatch } from "react-redux";
import { useVirtual } from "react-virtual";
import * as T from "types/engine-types";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import {
  productsSelector,
  filteredProductsSelector,
  productsLoadingSelector,
  getProducts,
  setSearchTerm,
  setSort,
} from "features/products";
import {
  filteredRateSheetsSelector,
  getRateSheets,
} from "features/rate-sheets";
import { filteredInvestorsSelector, getInvestors } from "features/investors";
import { usePermissions } from "features/roles";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

export function getInvestorNameById(
  investors: T.DecoratedInvestorHeader[],
  investorId: T.InvestorId,
): string {
  return investors.find((i) => i.id === investorId)?.name || "";
}

export default function ProductsPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const parentRef = useRef<HTMLDivElement>(null);
  const investors = useSelector(filteredInvestorsSelector);
  const products = useSelector(filteredProductsSelector);
  const rateSheets = useSelector(filteredRateSheetsSelector);
  const productsState = useSelector(productsSelector);
  const productsLoading = useSelector(productsLoadingSelector);
  const { searchTerm, sortField } = productsState;
  const hasPermission = usePermissions();
  const hasCreatePerm = hasPermission("products-create");
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);

  useEffect(() => {
    if (!productsLoading) dispatch(getProducts());
  }, [dispatch, productsLoading]);

  useEffect(() => {
    if (investors.length === 0) dispatch(getInvestors());
  }, [dispatch, investors.length]);

  useEffect(() => {
    if (rateSheets.length === 0) dispatch(getRateSheets());
  }, [dispatch, rateSheets.length]);

  const rowVirtualizer = useVirtual({
    size: products?.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, []),
  });

  const onRowClick = React.useCallback(
    (event: unknown, product: T.DecoratedProductHeader | undefined) => {
      if (product) {
        history.push(`/c/${accessId}/products/${product.id}`);
      }
    },
    [history, accessId],
  );

  const createProduct = () => {
    history.push(`/c/${accessId}/create-product`);
  };

  return (
    <Table>
      <TableActions>
        <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
          <SearchInput
            label="products"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        {!!productsState.products && (
          <ResultsCount>
            <span className="visible">
              {products.length} <VisibilityIcon />
            </span>
            <span className="separator">|</span>
            <span className="total">{productsState.products.length} Total</span>
          </ResultsCount>
        )}

        <Button
          disabled={!hasCreatePerm}
          className="add-product"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={createProduct}
        >
          Add Product
        </Button>
      </TableActions>
      <TableHeader>
        <TableHeaderCell
          style={{ flexBasis: "25%" }}
          onClick={() => dispatch(setSort("name"))}
        >
          Name{" "}
          {sortField === "name" && (
            <SwapVertIcon
              style={{ color: sortField === "name" ? "#90d18c" : "inherit" }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{ flexBasis: "30%" }}
          onClick={() => dispatch(setSort("description"))}
        >
          Description{" "}
          {sortField === "description" && (
            <SwapVertIcon
              style={{
                color: sortField === "description" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>
        <TableHeaderCell
          style={{ flexBasis: "20%" }}
          onClick={() => dispatch(setSort("investorName"))}
        >
          Investor Name{" "}
          {sortField === "investorName" && (
            <SwapVertIcon
              style={{
                color: sortField === "investorName" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>
        <TableHeaderCell
          style={{ flexBasis: "15%" }}
          onClick={() => dispatch(setSort("code"))}
        >
          Code{" "}
          {sortField === "code" && (
            <SwapVertIcon
              style={{ color: sortField === "code" ? "#90d18c" : "inherit" }}
            />
          )}
        </TableHeaderCell>
        <TableHeaderCell
          style={{ flexBasis: "10%", textAlign: "center" }}
          onClick={() => dispatch(setSort("isActive"))}
        >
          Is Active{" "}
          {sortField === "isActive" && (
            <SwapVertIcon
              style={{
                color: sortField === "isActive" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>
      </TableHeader>

      {products && (
        <TableBodyWrapper ref={parentRef}>
          <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
            {rowVirtualizer.virtualItems.map((virtualRow) => (
              <TableRow
                key={virtualRow.index}
                onClick={(e) => onRowClick(e, products[virtualRow.index])}
                style={{
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <TableCell style={{ flexBasis: "25%" }}>
                  {products[virtualRow.index].name}
                </TableCell>
                <TableCell style={{ flexBasis: "30%" }}>
                  {products[virtualRow.index].description}
                </TableCell>
                <TableCell style={{ flexBasis: "20%" }}>
                  {products[virtualRow.index].investorName}
                </TableCell>
                <TableCell style={{ flexBasis: "15%" }}>
                  {products[virtualRow.index].code}
                </TableCell>
                <TableCell style={{ flexBasis: "10%", textAlign: "center" }}>
                  {products[virtualRow.index].isActive ? (
                    <CheckIcon
                      className="is-active"
                      style={{ fill: "#90d18c" }}
                    />
                  ) : (
                    <CloseIcon
                      className="is-inactive"
                      style={{ fill: "red" }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableBodyWrapper>
      )}
    </Table>
  );
}
