import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import * as Api from "api";
import * as T from "types/engine-types";
import _ from "lodash";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import { isPresent, parseFloatOr } from "features/utils";

const params = new URLSearchParams(document.location.search);

export type PipelineFilters = {
  searchTerm: string;
  sortField: string;
  sortDir: string;
  createdAt: string;
  ownerId: string;
  dynamicFilters: DynamicPipelineFilter[];
};

export type DynamicPipelineFilter = {
  id: T.FieldId;
  disposition: FilterDisposition;
};

type FilterDisposition =
  | PipelineEnumFilter
  | PipelineStringFilter
  | PipelineNumberFilter
  | PipelineDurationFilter
  | PipelineDateFilter;

type PipelineStringFilter = {
  type: "string";
  value: string;
};

type PipelineNumberFilter = {
  type: "number";
  values: {
    min: string;
    max: string;
  };
};

type PipelineEnumFilter = {
  type: "enum";
  values: {
    enumTypeId: T.EnumTypeId;
    includedValues: T.EnumVariant[];
  };
};

type PipelineDurationFilter = {
  type: "duration";
  values: {
    includedValues: T.DurationValue[];
  };
};

type PipelineDateFilter = {
  type: "date";
  value: string;
};

function emptyFilterState(): PipelineFilters {
  let base: PipelineFilters = {
    searchTerm: "",
    sortField: "createdAt",
    sortDir: "asc",
    createdAt: "",
    ownerId: "",
    dynamicFilters: [],
  };

  params.forEach((value, key) => {
    if (key === "pipelineFilters" && value) {
      base = JSON.parse(value) as PipelineFilters;
    }
  });

  return base;
}

function resetFiltersToNone(): PipelineFilters {
  return {
    searchTerm: "",
    sortField: "createdAt",
    sortDir: "asc",
    createdAt: "",
    ownerId: "",
    dynamicFilters: [],
  };
}

export type PipelineState = {
  records: T.PipelineRecordHeader[] | undefined;
  loading: boolean;
  errors: string;
  filters: PipelineFilters;
};

const initialState: PipelineState = {
  records: undefined,
  loading: false,
  errors: "",
  filters: emptyFilterState(),
};

export const getPipeline = createAsyncThunk(
  "pipeline/getPipeline",
  async ({ ownerId }: { ownerId: T.UserId | null }) => {
    await Api.syncPipelineOutputs();

    return await Api.getPipelineRecords({
      ownerId,
    });
  },
);

const pipelineSlice = createSlice({
  name: "Pipeline",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPipeline.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPipeline.fulfilled, (state, { payload }) => {
      state.records = payload;
      state.loading = false;
    });
  },
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },
    setPipeline: (
      state,
      { payload }: PayloadAction<T.PipelineRecordHeader[]>,
    ) => {
      state.records = payload;
    },
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.filters.searchTerm = payload;
    },
    setSort: (state, { payload }: PayloadAction<string>) => {
      if (payload === "asc" || payload === "desc") {
        state.filters.sortDir = payload;
      } else {
        state.filters.sortField = payload;
        state.filters.sortDir = "asc";
      }
    },
    setCreatedAt: (state, { payload }: PayloadAction<string>) => {
      state.filters.createdAt = payload;
    },
    setOwnerId: (state, { payload }: PayloadAction<string>) => {
      state.filters.ownerId = payload;
    },
    setDynamicFilters: (
      state,
      { payload }: PayloadAction<DynamicPipelineFilter[]>,
    ) => {
      state.filters.dynamicFilters = payload;
    },
    resetFilters: (state) => {
      return {
        ...state,
        filters: resetFiltersToNone(),
      };
    },
  },
});

export const {
  setLoading,
  setErrors,
  setPipeline,
  setSearchTerm,
  setSort,
  setCreatedAt,
  setOwnerId,
  setDynamicFilters,
  resetFilters,
} = pipelineSlice.actions;

export default pipelineSlice;

export const pipelineSelector = (state: { pipeline: PipelineState }) =>
  state.pipeline;

export const pipelineFiltersSelector = createSelector(
  [pipelineSelector],
  (pipeline) => pipeline.filters,
);

export const pipelineGroupsSelector = createSelector(
  [(state: { pipeline: PipelineState }) => state.pipeline.records],

  (pipeline) => {
    const active: T.PipelineRecordHeader[] = [];
    const archived: T.PipelineRecordHeader[] = [];

    pipeline?.forEach((record) => {
      switch (record.status) {
        case "open":
          active.push(record);
          break;
        case "archived":
          archived.push(record);
          break;
      }
    });

    return {
      active,
      archived,
    };
  },
);

type DurationValues = { [index: string]: string[] };
type DurationValueIdObj = {
  id: T.FieldId;
  values: DurationValues;
};
export type DurationValueMap = { [index: T.FieldId]: DurationValueIdObj };

export const uniqueDurationValueSelector = createSelector(
  [
    (state: { pipeline: PipelineState }) => state.pipeline.records,
    nonNullApplicationInitializationSelector,
  ],
  (pipeline, appState) => {
    let durationMap: DurationValueMap = {};

    pipeline?.map((record) => {
      // get an array of all record values to iterate over
      const values = getRecordValues(
        record,
        appState.config.settings.pipelineFields,
      );

      values.map((value) => {
        if (value.value.type === "duration") {
          const { fieldId } = value;
          const objectKey = value.value.unit;
          // check the durationMap for this fieldId and grab the values that already exist if they do
          const currentValues: DurationValues = durationMap[fieldId]
            ? durationMap[fieldId].values
            : {};
          // check to see if we have this duration unit on the currently selected field
          const existingDurationCounts = currentValues[objectKey];

          // if the duration unit exists, add the current value count to it
          const newValues: DurationValues = currentValues[objectKey]
            ? {
                ...currentValues,
                [objectKey]: [...existingDurationCounts, value.value.count],
              }
            : // otherwise create a new object with just the current value count
              {
                ...currentValues,
                [objectKey]: [value.value.count],
              };

          // new obj using new values
          const newObj: DurationValueIdObj = {
            id: fieldId,
            values: newValues,
          };

          // update the map with the new obj
          durationMap = {
            ...durationMap,
            [fieldId]: newObj,
          };
        }
      });
    });
    return durationMap;
  },
);

export function getRecordValues(
  record: T.PipelineRecordHeader,
  filters: T.FieldId[],
): T.FieldValueMapping[] {
  const recordValues: T.FieldValueMapping[] = [];
  let value: T.FieldValueMapping | undefined = undefined;

  filters.map((id) => {
    value = record.pipelineOnlyFieldValues.find(
      (field) => field.fieldId === id,
    );
    if (value) {
      recordValues.push(value);
    }
  });

  filters.map((id) => {
    value = record.preferredScenario.creditApplicationFieldValues.find(
      (field) => field.fieldId === id,
    );

    if (value) {
      recordValues.push(value);
    }
  });

  filters.map((id) => {
    value = record.outputFieldValues.find((field) => field.fieldId === id);
    if (value) {
      recordValues.push(value);
    }
  });

  return recordValues;
}

function getAllEnumVariants(
  enumTypes: T.EnumType[],
  pipelineFilter: DynamicPipelineFilter,
): T.EnumVariant[] {
  const enumTypeId =
    pipelineFilter.disposition.type === "enum" &&
    pipelineFilter.disposition.values.enumTypeId;
  const enumFromConfig = enumTypes.find((enumType) => {
    return enumType.id === enumTypeId;
  });
  const allVariants = enumFromConfig?.variants;

  return allVariants ? allVariants : [];
}

function filterRecord(
  record: T.PipelineRecordHeader,
  pipelineFields: T.FieldId[],
  pipelineFilters: PipelineFilters,
  enumsFromSystem: T.EnumType[],
  uniqueDurationValues: DurationValueMap,
  hasViewAllRecords?: T.PermissionKind | undefined,
): T.PipelineRecordHeader | undefined {
  const values = getRecordValues(record, pipelineFields);
  let included: boolean = true;

  // hardcoded filters

  // filter by text
  // first we need to properly aggregate everything that the search filter can look through, this may need to be re-examined once real live pricing is hooked up if data changes
  let filterByTextString: string = "";
  values.forEach((value) => {
    switch (value.value.type) {
      case "date":
        const dateValue = new Date(value.value.value);
        filterByTextString = filterByTextString.concat(
          dateValue.toDateString(),
        );
        break;
      case "duration":
        filterByTextString = filterByTextString.concat(
          value.value.count.concat(" ", value.value.unit),
        );
        break;
      case "enum":
        filterByTextString = filterByTextString.concat(
          value.value.variantId.replaceAll("-", " "),
        );
        break;
      case "number":
        filterByTextString = filterByTextString.concat(value.value.value);
        break;
      case "object-ref":
        break;
      case "string":
        filterByTextString = filterByTextString.concat(value.value.value);
        break;
    }
  });

  filterByTextString = filterByTextString.concat(record.preferredScenario.name);

  if (hasViewAllRecords) {
    filterByTextString = filterByTextString.concat(record.ownerId);
  }

  filterByTextString = filterByTextString.toLowerCase();

  if (!filterByTextString.includes(pipelineFilters.searchTerm)) {
    included = false;
  }

  // after anytime that included could be set to false we need to check for it and return undefined if it was set to false to prevent filters overwriting oneanother
  if (!included) {
    return undefined;
  }

  // createdAt filter works like the date filter, excluding everything before the selected date
  if (!!pipelineFilters.createdAt) {
    const dateRecordValue = new Date(record.createdAt);
    const dateFilterValue = new Date(pipelineFilters.createdAt);

    dateFilterValue <= dateRecordValue ? (included = true) : (included = false);
  }

  // only filter by ownerId if the permission exists
  if (hasViewAllRecords) {
    if (!!pipelineFilters.ownerId) {
      pipelineFilters.ownerId === record.ownerId
        ? (included = true)
        : (included = false);
    }
  }

  // dynamic filters
  pipelineFilters.dynamicFilters.map((filter) => {
    switch (filter.disposition.type) {
      case "string":
        values.map((value) => {
          if (!included) {
            return;
          }
          if (
            value.value.type === "string" &&
            filter.disposition.type === "string"
          ) {
            const filterMatches = filter.id === value.fieldId;

            value.value.value.includes(filter.disposition.value) ||
            !filterMatches
              ? (included = true)
              : (included = false);
          }
        });
        break;
      case "number":
        values.map((value) => {
          if (!included) {
            return;
          }
          if (
            value.value.type === "number" &&
            filter.disposition.type === "number"
          ) {
            const min = parseFloatOr(
              filter.disposition.values.min,
              Number.NEGATIVE_INFINITY,
            );
            const max = parseFloatOr(
              filter.disposition.values.max,
              Number.POSITIVE_INFINITY,
            );
            const fieldValue = parseFloatOr(value.value.value, 0);
            const isWithinRange: boolean =
              min <= fieldValue && fieldValue <= max;

            const filterMatches = filter.id === value.fieldId;

            isWithinRange || !filterMatches
              ? (included = true)
              : (included = false);
          }
        });
        break;
      case "enum":
        values.map((value) => {
          if (!included) {
            return;
          }
          if (
            filter.disposition.type === "enum" &&
            value.value.type === "enum"
          ) {
            const allVariants = getAllEnumVariants(enumsFromSystem, filter);

            const isValueIncluded = filter.disposition.values.includedValues
              .map((value) => value.id)
              .includes(value.value.variantId);

            const filterMatches = filter.id === value.fieldId;

            allVariants.length ===
              filter.disposition.values.includedValues.length ||
            isValueIncluded ||
            !filterMatches
              ? (included = true)
              : (included = false);
          }
        });
        break;
      case "duration":
        values.map((value) => {
          if (!included) {
            return;
          }
          if (
            filter.disposition.type === "duration" &&
            value.value.type === "duration"
          ) {
            const allRelevantDurationValues =
              uniqueDurationValues[filter.id].values;

            const durationList = Object.entries(
              allRelevantDurationValues,
            ).flatMap((durationValue) => {
              return {
                unit: durationValue[0],
                count: durationValue[1],
              };
            });

            const allPossibleValues: T.DurationValue[] = durationList.flatMap(
              (value) =>
                value.count.map((count) => {
                  return {
                    unit: value.unit,
                    count,
                  } as T.DurationValue;
                }),
            );
            const filterMatches = filter.id === value.fieldId;

            const isValueIncluded =
              !!filter.disposition.values.includedValues.find(
                (stateValue) =>
                  value.value.type === "duration" &&
                  stateValue.unit === value.value.unit &&
                  stateValue.count === value.value.count,
              );

            isValueIncluded ||
            !filterMatches ||
            allPossibleValues.length ===
              filter.disposition.values.includedValues.length
              ? (included = true)
              : (included = false);
          }
        });
        break;
      case "date":
        values.map((value) => {
          if (!included) {
            return;
          }
          if (
            filter.disposition.type === "date" &&
            value.value.type === "date"
          ) {
            const dateRecordValue = new Date(value.value.value);
            const dateFilterValue = new Date(filter.disposition.value);
            const filterMatches = filter.id === value.fieldId;

            dateFilterValue <= dateRecordValue ||
            !!!filter.disposition.value ||
            !filterMatches
              ? (included = true)
              : (included = false);
          }
        });
        break;
    }
  });

  return included ? record : undefined;
}

function sortLogic(
  aRecord: T.PipelineRecordHeader,
  bRecord: T.PipelineRecordHeader,
  pipelineFilters: PipelineFilters,
  pipelineFields: T.FieldId[],
) {
  // hard coded sort fields
  if (pipelineFilters.sortField === "createdAt") {
    const aDate = new Date(aRecord.createdAt);
    const bDate = new Date(bRecord.createdAt);

    if (aDate <= bDate) {
      return -1;
    }
    if (aDate >= bDate) {
      return 1;
    }
    return 0;
  } else if (pipelineFilters.sortField === "ownerId") {
    return aRecord.ownerId.localeCompare(bRecord.ownerId);
  } else {
    // dynamic sort fields
    const aValues = getRecordValues(aRecord, pipelineFields);
    const bValues = getRecordValues(bRecord, pipelineFields);
    const aField = aValues.find(
      (value) => value.fieldId === pipelineFilters.sortField,
    );
    const bField = bValues.find(
      (value) => value.fieldId === pipelineFilters.sortField,
    );
    if (aField && bField) {
      switch (aField.value.type) {
        case "date":
          const aDate = new Date(aField.value.value);
          const bDate =
            bField.value.type === "date"
              ? new Date(bField.value.value)
              : new Date();
          if (aDate <= bDate) {
            return -1;
          }
          if (aDate >= bDate) {
            return 1;
          }
          return 0;
        case "duration":
          const bDuration =
            bField.value.type === "duration" ? bField.value.count : "";
          return aField.value.count.localeCompare(bDuration);
        case "enum":
          const bEnum =
            bField.value.type === "enum" ? bField.value.variantId : null;
          if (bEnum) {
            return aField.value.variantId.localeCompare(bEnum);
          }
          break;
        case "number":
          const bNumber =
            bField.value.type === "number" ? bField.value.value : "";
          return aField.value.value.localeCompare(bNumber);
        case "object-ref":
          return 0;
        case "string":
          const bString =
            bField.value.type === "string" ? bField.value.value : "";
          return aField.value.value.localeCompare(bString);
      }
    }
  }
}

function sortRecords(
  records: (T.PipelineRecordHeader | undefined)[],
  pipelineFilters: PipelineFilters,
  pipelineFields: T.FieldId[],
): T.PipelineRecordHeader[] {
  const { sortField, sortDir } = pipelineFilters;

  // default sorting behavior is to sort by record.preferredScenario.name, if the permission to view all records exists we first sort by ownerId and then record.preferredScenario.name alphabetically
  let sortedRecords = records.filter(isPresent).sort((a, b) => {
    if (sortField === "") {
      return (
        parseFloat(a.ownerId) - parseFloat(b.ownerId) ||
        a.preferredScenario.name.localeCompare(b.preferredScenario.name)
      );
    } else {
      const sortReturn = sortLogic(a, b, pipelineFilters, pipelineFields);
      if (sortReturn) {
        return sortReturn;
      }
    }
    return 0;
  });

  if (sortDir === "desc") {
    sortedRecords = _.reverse(sortedRecords);
  }

  return sortedRecords.filter(isPresent);
}

export const FilteredPipelineRecords = createSelector(
  [
    pipelineGroupsSelector,
    pipelineFiltersSelector,
    nonNullApplicationInitializationSelector,
    uniqueDurationValueSelector,
  ],
  (pipelineState, pipelineFilters, appState, uniqueDurationValues) => {
    const {
      config: {
        settings: { pipelineFields },
        enumTypes,
      },
      myRole: { permissions },
    } = appState;
    const hasViewAllRecords = permissions.find(
      (perm) => perm === "pipeline-records-view-all",
    );

    const activeRecords: T.PipelineRecordHeader[] = sortRecords(
      pipelineState.active.map((record) =>
        filterRecord(
          record,
          pipelineFields,
          pipelineFilters,
          enumTypes.slice(),
          uniqueDurationValues,
          hasViewAllRecords,
        ),
      ),
      pipelineFilters,
      pipelineFields,
    );

    const archivedRecords: T.PipelineRecordHeader[] = sortRecords(
      pipelineState.archived.map((record) =>
        filterRecord(
          record,
          pipelineFields,
          pipelineFilters,
          enumTypes.slice(),
          uniqueDurationValues,
        ),
      ),
      pipelineFilters,
      pipelineFields,
    );

    return {
      active: activeRecords,
      archived: archivedRecords,
    };
  },
);

export const activePipelineFiltersSelector = createSelector(
  [pipelineFiltersSelector],
  (pipelineFilters) => {
    let count = 0;
    const initialState = emptyFilterState();

    if (pipelineFilters.createdAt !== initialState.createdAt) {
      count++;
    }
    if (pipelineFilters.ownerId !== initialState.ownerId) {
      count++;
    }
    if (pipelineFilters.searchTerm !== initialState.searchTerm) {
      count++;
    }
    if (pipelineFilters.sortField !== initialState.sortField) {
      count++;
    }
    pipelineFilters.dynamicFilters.forEach((filter) => {
      count++;
    });

    return count;
  },
);
