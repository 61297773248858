import React from "react";
import { useDispatch } from "react-redux";
import * as T from "types/engine-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { PipelineFilters, setSort } from "features/pipeline";
import { Dispatch } from "react";
import { Wrapper } from "./styles";

export function handleSetSort(
  pipelineFilters: PipelineFilters,
  // eslint-disable-next-line
  dispatch: Dispatch<any>,
  field?: string,
  hardCodedSort?: string,
) {
  if (hardCodedSort) {
    if (pipelineFilters.sortField !== hardCodedSort) {
      dispatch(setSort(hardCodedSort));
    } else {
      if (pipelineFilters.sortDir === "asc") {
        dispatch(setSort("desc"));
      } else {
        dispatch(setSort("asc"));
      }
    }
  } else if (field && pipelineFilters.sortField !== field) {
    dispatch(setSort(field));
  } else {
    if (pipelineFilters.sortDir === "asc") {
      dispatch(setSort("desc"));
    } else {
      dispatch(setSort("asc"));
    }
  }
}

export function renderSortIcon(
  pipelineFilters: PipelineFilters,
  // eslint-disable-next-line
  dispatch: Dispatch<any>,
  field?: string,
  hardCodedSort?: string,
) {
  if (hardCodedSort) {
    if (pipelineFilters.sortField === hardCodedSort) {
      if (pipelineFilters.sortDir === "asc") {
        return faSortUp;
      } else {
        return faSortDown;
      }
    } else {
      return faSort;
    }
  } else if (field && pipelineFilters.sortField === field) {
    if (pipelineFilters.sortDir === "asc") {
      return faSortUp;
    } else {
      return faSortDown;
    }
  } else {
    return faSort;
  }
}

export default React.memo(function SortButtons({
  pipelineFilters,
  fieldId,
  hardCodedSort,
}: {
  pipelineFilters: PipelineFilters;
  fieldId?: T.FieldId;
  hardCodedSort?: string;
}) {
  const dispatch = useDispatch();

  return (
    <Wrapper
      onClick={() =>
        handleSetSort(
          pipelineFilters,
          dispatch,
          fieldId ?? undefined,
          hardCodedSort ?? undefined,
        )
      }
    >
      <FontAwesomeIcon
        icon={renderSortIcon(
          pipelineFilters,
          dispatch,
          fieldId ?? undefined,
          hardCodedSort ?? undefined,
        )}
      />
    </Wrapper>
  );
});
