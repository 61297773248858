import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import Snackbar from "@material-ui/core/Snackbar";

const ErrorBar = React.memo(
  ({
    alertOpen,
    setAlertOpen,
    children,
    className,
  }: {
    alertOpen: boolean;
    setAlertOpen: (value: React.SetStateAction<boolean>) => void;
    children: React.ReactNode;
    className: string;
  }) => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertOpen}
        autoHideDuration={60000}
        onClose={() => setAlertOpen(false)}
      >
        <div className={className}>
          {children}
          <ClearIcon
            style={{ marginLeft: "32px" }}
            onClick={() => setAlertOpen(false)}
          />
        </div>
      </Snackbar>
    );
  },
);

export default ErrorBar;
