import { List as IList, Map as IMap, Set as ISet } from "immutable";

import { Configuration } from "config";
import * as T from "types/generated-types";
import { unreachable } from "features/utils";

export function getProductStatus(
  productResult: T.ProductExecutionResult,
): T.ProductSummaryStatus["status"] {
  switch (productResult.status) {
    case "filtered-out":
      throw new Error("filtered-out status is discontinued");
    case "error":
      if (productResult.errors.every((err) => err.type === "blank-field")) {
        return "available";
      }

      return "error";
    case "rejected":
      return "rejected";
    case "ok":
      if (productResult.priceScenarios.some((s) => s.status === "approved")) {
        return "approved";
      }

      if (
        productResult.priceScenarios.some(
          (s) =>
            s.status === "error" &&
            s.errors.some((e) => e.type !== "blank-field"),
        )
      ) {
        return "error";
      }

      if (
        productResult.priceScenarios.some(
          (s) =>
            s.status === "error" &&
            s.errors.every((e) => e.type === "blank-field"),
        )
      ) {
        return "available";
      }

      return "rejected";
    case "no-pricing":
      return "no-pricing";
    default:
      unreachable(productResult);
  }
}

export function getMissingFieldsOnProduct(
  productResult: T.ProductExecutionResult,
): IMap<T.FieldId, ISet<T.ExecutionErrorSource>> {
  switch (productResult.status) {
    case "filtered-out":
    case "rejected":
    case "no-pricing":
      throw new Error(
        "cannot get missing fields from product result with status " +
          JSON.stringify(productResult.status),
      );
    case "error":
      return blankErrorsToFieldMap(productResult.errors);
    case "ok":
      return blankErrorsToFieldMap(
        productResult.priceScenarios.flatMap((scenario) => {
          if (scenario.status !== "error") {
            return [];
          }

          return scenario.errors;
        }),
      );
  }
}

export function getMissingFieldsOnPriceScenario(
  scenario: T.PriceScenarioResult,
): IMap<T.FieldId, ISet<T.ExecutionErrorSource>> {
  switch (scenario.status) {
    case "approved":
    case "review-required":
    case "rejected":
    case "missing-configuration":
      throw new Error(
        "cannot get missing fields from product result with status " +
          JSON.stringify(scenario.status),
      );
    case "error":
      return blankErrorsToFieldMap(scenario.errors);
  }
}

function blankErrorsToFieldMap(
  blankErrors: T.ExecutionError[],
): IMap<T.FieldId, ISet<T.ExecutionErrorSource>> {
  return IList(
    blankErrors.map((err) => {
      if (err.type !== "blank-field") {
        throw new Error(
          "cannot get missing fields when there are other errors",
        );
      }

      return { fieldId: err.fieldId, source: err.source };
    }),
  )
    .groupBy((err) => err.fieldId)
    .map((errList) => errList.map((err) => err.source).toSet())
    .toMap();
}

export function predictExecutionEnvironment(
  config: Configuration,
  when: "before" | "after",
  stageId: T.ExecutionStageId,
): ISet<T.FieldId> {
  let stageIndex = config.stages.findIndex((s) => s.id === stageId);

  if (stageIndex === -1) {
    throw new Error(`unknown stage ID ${JSON.stringify(stageId)}`);
  }

  if (when === "after") {
    stageIndex += 1;
  }

  const includedStages = config.stages.slice(0, stageIndex);

  let env = predictInitialExecutionEnvironment(config);

  for (const stage of includedStages) {
    switch (stage.kind) {
      case "run-rules":
        // No additions to the environment.
        break;
      case "calculations":
        for (const calc of stage.calculations) {
          env = env.union(getEnvironmentAdditionsForCalculation(calc));
        }

        break;
      case "split-on-product":
        env = env.concat([
          stage.investorCodeField.id,
          stage.investorNameField.id,
          stage.productCodeField.id,
          stage.productNameField.id,
        ]);
        for (const productField of config.productFields) {
          env = env.add(productField.id);
        }
        break;
      case "split-on-rate-sheet":
        env = env.union([
          stage.baseRateField.id,
          stage.lockPeriodField.id,
          stage.basePriceField.id,
        ]);
        break;
      case "insert-pricing-profile":
        env = env.union([
          stage.pricingProfileNameField.id,
          stage.pricingProfileRefField.id,
        ]);
        break;
      case "load-current-date":
      case "calculate-total-rate-adjustment":
      case "calculate-total-price-adjustment":
      case "calculate-total-margin-adjustment":
      case "calculate-final-total-rate-adjustment":
      case "calculate-final-total-price-adjustment":
      case "calculate-final-total-margin-adjustment":
      case "calculate-grand-total-rate-adjustment":
      case "calculate-grand-total-price-adjustment":
      case "calculate-grand-total-margin-adjustment":
        env = env.add(stage.fieldDef.id);
        break;
      default:
        unreachable(stage);
    }
  }

  return env;
}

function predictInitialExecutionEnvironment(
  config: Configuration,
): ISet<T.FieldId> {
  return ISet(config.creditApplicationFields.map((f) => f.id));
}

export function getEnvironmentAdditionsForCalculation(
  calc: T.Calculation,
): ISet<T.FieldId> {
  switch (calc.type) {
    case "field":
      return ISet([calc.field.id]);
    case "data-table-lookup":
      return ISet(calc.lookup.fields.map((f) => f.id));
  }
}
