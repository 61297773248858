import styled from "styled-components";

const DetailHeader = styled.div.attrs(() => ({ className: "detail-header" }))`
  z-index: 2;
  flex: 0 0 auto;
  background-color: #f5f5f5;
  padding: 8px;
  display: flex;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;

  h5 {
    opacity: 0.35;
  }
`;

export default DetailHeader;
