import { Set as ISet } from "immutable";
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import Loader from "react-loader";
import _ from "lodash";
import { getRules } from "features/rules";
import * as Api from "api";
import { LoadingOverlay } from "design/atoms/loading-overlay";
import MessageBar from "design/atoms/message-bar";
import {
  RuleEditor,
  RuleState,
  convertRuleToState,
  convertStateToRule,
  validateRuleState,
} from "../_components/rule-editor";
import UnloadPrompt from "design/atoms/unload-prompt";
import * as T from "types/engine-types";
import { useDispatch } from "react-redux";
import { expandedConfigSelector } from "features/application-initialization";
import { useSelector } from "react-redux";
import { localAccessId } from "features/access-id";

type Params = { id: T.RuleId };

export default function EditRulePage() {
  const { id } = useParams<Params>();

  const [productsLoadState] = Api.useProducts();
  const [ruleLoadState] = Api.useRule(id);
  const [rulesLoadState] = Api.useRules();
  const [investorsLoadState] = Api.useInvestors();
  const combinedLoadState = Api.combineLoadStates(
    productsLoadState,
    ruleLoadState,
    rulesLoadState,
    investorsLoadState,
  );

  if (combinedLoadState.status === "loading") {
    return <Loader loaded={false} />;
  }

  if (combinedLoadState.status === "error") {
    return (
      <MessageBar
        text="Something went wrong, please check your internet connection and try again later. If you see this message again, please contact support."
        type="error"
      />
    );
  }

  const [products, rule, rules, investors] = combinedLoadState.value;

  return (
    <EditRulePageLoaded
      products={products}
      rule={rule}
      rules={rules}
      investors={investors}
    />
  );
}

const EditRulePageLoaded = React.memo(function EditRulePageLoaded({
  investors,
  products,
  rules,
  rule,
}: {
  investors: T.DecoratedInvestorHeader[];
  products: T.DecoratedProductHeader[];
  rules: T.DecoratedRuleHeader[];
  rule: T.Rule;
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const config = useSelector(expandedConfigSelector);

  const [ruleState, setRuleState] = useState<RuleState>(
    convertRuleToState(rule, config),
  );
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isSave, setIsSave] = useState(false);

  const copyOfOriginalRuleState: RuleState | null = _.cloneDeep(
    convertRuleToState(rule, config),
  );

  const otherRules = rules.filter((r) => r.id !== rule.id);
  const existingNames = ISet(otherRules.map((r) => r.name));

  const nextError = validateRuleState(config, existingNames, ruleState);
  const accessId = localAccessId();
  const saveRule = async () => {
    setIsSave(true);

    setShowValidationErrors(true);

    if (nextError) {
      setIsSave(false);
      return;
    }

    const updatedRule = convertStateToRule(
      config,
      existingNames,
      ruleState,
    ) as T.Rule;
    updatedRule.id = rule.id;

    setLoading(true);
    const newRule = await Api.saveRule(updatedRule);
    setLoading(false);
    dispatch(getRules());
    history.push(`/c/${accessId}/rules/${newRule.id}`);
  };

  // The route change will be blocked if the state has changed AND user did not hit the save button
  const shouldBlock = !_.isEqual(copyOfOriginalRuleState, ruleState) && !isSave;

  return (
    <>
      <UnloadPrompt when={shouldBlock} />
      <LoadingOverlay when={loading} />

      <Box m={2} display="flex">
        <Button
          variant="outlined"
          startIcon={<CloseIcon />}
          onClick={() => history.push(`/c/${accessId}/rules/${rule.id}`)}
        >
          Cancel
        </Button>
        <Box flex="1" />
        {showValidationErrors && nextError !== null && (
          <Box display="flex" alignItems="center" px={2}>
            <Typography color="secondary">
              Error:{" "}
              {nextError.message ||
                "Some fields are missing or have invalid values."}
            </Typography>
          </Box>
        )}
        <Button
          variant="outlined"
          startIcon={<DoneIcon />}
          onClick={saveRule}
          disabled={loading || (showValidationErrors && nextError !== null)}
        >
          Save
        </Button>
      </Box>

      <RuleEditor
        title="Edit rule"
        products={products}
        existingNames={existingNames}
        state={ruleState}
        showValidationErrors={showValidationErrors}
        setState={setRuleState}
        investors={investors}
      />
    </>
  );
});
