import { Set as ISet } from "immutable";
import React from "react";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { expandedConfigSelector } from "features/application-initialization";
import { useSelector } from "react-redux";

import { actionTypeToString } from "types/action-types";
import { SearchableDropdown } from "design/molecules/dropdown";
import {
  NumberFieldExpressionEditor,
  NumberFieldExpressionState,
  StringFieldExpressionEditor,
  StringFieldExpressionState,
  newNumberFieldExpressionState,
  newStringFieldExpressionState,
  convertNumberFieldExpressionToState,
  convertStringFieldExpressionToState,
  convertStateToNumberFieldExpression,
  convertStateToStringFieldExpression,
} from "../field-expression-editor";
import * as T from "types/engine-types";
import { Configuration } from "config";
import { Setter, UiValidationError, usePropertySetter } from "features/utils";

export type RuleActionState = {
  type: T.RuleActionType | null;
  rejectionReason: StringFieldExpressionState;
  adjustmentValue: NumberFieldExpressionState;
  adjustmentName: StringFieldExpressionState;
  stipulationText: StringFieldExpressionState;
};

const rejectionReasonValueType: T.FieldValueType.String = {
  type: "string",
  format: "plain",
};
const adjustmentValueValueType: T.FieldValueType.Number = {
  type: "number",
  minimum: null,
  maximum: null,
  precision: 3,
  style: "plain",
};
const adjustmentNameValueType: T.FieldValueType.String = {
  type: "string",
  format: "plain",
};
const stipulationTextValueType: T.FieldValueType.String = {
  type: "string",
  format: "plain",
};

export function newRuleActionState(): RuleActionState {
  return {
    type: null,
    rejectionReason: newStringFieldExpressionState(rejectionReasonValueType),
    adjustmentValue: newNumberFieldExpressionState(adjustmentValueValueType),
    adjustmentName: newStringFieldExpressionState(adjustmentNameValueType),
    stipulationText: newStringFieldExpressionState(stipulationTextValueType),
  };
}

export function convertRuleActionToState(
  action: T.RuleAction,
  config: Configuration,
  environment: ISet<T.FieldId>,
  localFields: readonly T.RuleDataTableLookupFieldDefinition[],
): RuleActionState {
  const state = newRuleActionState();
  state.type = action.type;

  switch (action.type) {
    case "reject":
    case "require-review":
      state.rejectionReason = convertStringFieldExpressionToState(
        rejectionReasonValueType,
        action.reason,
        config,
        environment,
        localFields,
      );
      return state;
    case "add-rate-adjustment":
    case "add-price-adjustment":
    case "add-margin-adjustment":
    case "final-rate-adjustment":
    case "final-price-adjustment":
    case "final-margin-adjustment":
      state.adjustmentValue = convertNumberFieldExpressionToState(
        adjustmentValueValueType,
        action.adjustmentValue,
      );
      state.adjustmentName = convertStringFieldExpressionToState(
        adjustmentNameValueType,
        action.adjustmentName,
        config,
        environment,
        localFields,
      );
      return state;
    case "add-stipulation":
      state.stipulationText = convertStringFieldExpressionToState(
        stipulationTextValueType,
        action.stipulationText,
        config,
        environment,
        localFields,
      );
      return state;
  }
}

export function convertStateToRuleAction(
  allowedActionTypes: T.RuleActionType[],
  state: RuleActionState,
  config: Configuration,
  environment: ISet<T.FieldId>,
  localFields: T.RuleDataTableLookupFieldDefinition[],
): T.RuleAction {
  if (state.type === null) {
    throw new UiValidationError("Select an action type");
  }

  if (!allowedActionTypes.includes(state.type)) {
    throw new UiValidationError("Select a valid action type");
  }

  switch (state.type) {
    case "reject":
    case "require-review":
      return {
        type: state.type,
        reason: convertStateToStringFieldExpression(
          true,
          rejectionReasonValueType,
          state.rejectionReason,
          config,
          environment,
          localFields,
        ),
      };
    case "add-rate-adjustment":
    case "add-price-adjustment":
    case "add-margin-adjustment":
    case "final-rate-adjustment":
    case "final-price-adjustment":
    case "final-margin-adjustment":
      return {
        type: state.type,
        adjustmentValue: convertStateToNumberFieldExpression(
          adjustmentValueValueType,
          state.adjustmentValue,
        ),
        adjustmentName: convertStateToStringFieldExpression(
          true,
          adjustmentNameValueType,
          state.adjustmentName,
          config,
          environment,
          localFields,
        ),
      };
    case "add-stipulation":
      return {
        type: "add-stipulation",
        stipulationText: convertStateToStringFieldExpression(
          true,
          stipulationTextValueType,
          state.stipulationText,
          config,
          environment,
          localFields,
        ),
      };
  }
}

const useStyles = makeStyles((t) =>
  createStyles({
    actionType: {
      margin: t.spacing(0, 1),
      flex: "0 0 270px",
    },
    rejectionReason: {
      flex: "1",
      margin: t.spacing(0, 1),
      width: 270,
    },
    adjustmentValue: {
      margin: t.spacing(0, 1),
      width: 270,
    },
    adjustmentName: {
      flexGrow: 1,
      margin: t.spacing(0, 1),
      width: 270,
    },
    stipulationText: {
      flex: "1",
      margin: t.spacing(0, 1),
      width: 270,
    },
  }),
);

export const RuleActionEditor = React.memo(
  ({
    allowedActionTypes,
    state,
    environment,
    localFields,
    setState,
    showErrors,
  }: {
    allowedActionTypes: readonly T.RuleActionType[];
    state: RuleActionState;
    environment: ISet<T.FieldId>;
    localFields: readonly T.RuleDataTableLookupFieldDefinition[];
    setState: Setter<RuleActionState>;
    showErrors: boolean;
  }) => {
    const C = useStyles();
    const config = useSelector(expandedConfigSelector);
    const setType = usePropertySetter(setState, "type");
    const setRejectionReason = usePropertySetter(setState, "rejectionReason");
    const setAdjustmentValue = usePropertySetter(setState, "adjustmentValue");
    const setAdjustmentName = usePropertySetter(setState, "adjustmentName");
    const setStipulationText = usePropertySetter(setState, "stipulationText");

    return (
      <Box mx={2} flexGrow={1} flexBasis="100%">
        <Box display="flex" flexGrow={1} flexBasis="100%">
          <SearchableDropdown<T.RuleActionType>
            className={C.actionType}
            label="Action Type"
            options={allowedActionTypes}
            getOptionLabel={actionTypeToString}
            value={state.type}
            error={showErrors && state.type === null}
            setValue={setType}
          />
          {(state.type === "reject" || state.type === "require-review") && (
            <StringFieldExpressionEditor
              allowInterpolation
              className={C.rejectionReason}
              state={state.rejectionReason}
              required
              showErrors={true}
              valueType={rejectionReasonValueType}
              label={
                state.type === "reject" ? "Rejection Reason" : "Review Reason"
              }
              environment={environment}
              localFields={localFields}
              config={config}
              setState={setRejectionReason}
            />
          )}
          {(state.type === "add-rate-adjustment" ||
            state.type === "add-price-adjustment" ||
            state.type === "add-margin-adjustment" ||
            state.type === "final-rate-adjustment" ||
            state.type === "final-price-adjustment" ||
            state.type === "final-margin-adjustment") && (
            <>
              <NumberFieldExpressionEditor
                className={C.adjustmentValue}
                state={state.adjustmentValue}
                required
                showErrors={true}
                valueType={adjustmentValueValueType}
                label="Amount"
                environment={environment}
                localFields={localFields}
                config={config}
                setState={setAdjustmentValue}
              />
              <StringFieldExpressionEditor
                allowInterpolation
                className={C.adjustmentName}
                state={state.adjustmentName}
                required
                showErrors={true}
                valueType={adjustmentNameValueType}
                label="Description"
                environment={environment}
                localFields={localFields}
                config={config}
                setState={setAdjustmentName}
              />
            </>
          )}
          {state.type === "add-stipulation" && (
            <StringFieldExpressionEditor
              allowInterpolation
              className={C.stipulationText}
              state={state.stipulationText}
              multiline
              required
              showErrors={true}
              valueType={stipulationTextValueType}
              label="Stipulation Text"
              environment={environment}
              localFields={localFields}
              config={config}
              setState={setStipulationText}
            />
          )}
        </Box>
      </Box>
    );
  },
);
