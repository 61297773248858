import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import _ from "lodash";
import * as Api from "api";
import { LoadingOverlay } from "design/atoms/loading-overlay";
import {
  RateSheetFormatEditor,
  convertRateSheetFormatToState,
  convertStateToRateSheetFormat,
} from "../_components/rate-sheet-format-editor";
import UnloadPrompt from "design/atoms/unload-prompt";
import * as T from "types/engine-types";
import { UiValidationError } from "features/utils";
import { localAccessId } from "features/access-id";

type Params = {
  id: T.RateSheetFormatId;
};

const { newrelic } = window;

export const EditRateSheetFormatPage = React.memo(() => {
  const { id } = useParams<Params>();

  const [rateSheetFormatLoadState] = Api.Admin.useRateSheetFormat(id);

  if (rateSheetFormatLoadState.status === "error") {
    return (
      <Box px={3} py={3}>
        Error loading rate sheet format.
      </Box>
    );
  }

  if (rateSheetFormatLoadState.status === "loading") {
    return (
      <Box px={3} py={3}>
        Loading...
      </Box>
    );
  }

  return (
    <LoadedEditRateSheetFormatPage
      rateSheetFormat={rateSheetFormatLoadState.value}
    />
  );
});

export const LoadedEditRateSheetFormatPage = React.memo(
  ({ rateSheetFormat }: { rateSheetFormat: T.RateSheetFormat }) => {
    const history = useHistory();

    const [state, setState] = useState(() =>
      convertRateSheetFormatToState(rateSheetFormat),
    );
    const [loadingText, setLoadingText] = useState<string | null>(null);

    const [savedRateSheetFormat, setSavedRateSheetFormat] =
      useState<T.RateSheetFormatChangeset>(() => _.omit(rateSheetFormat, "id"));

    const [validationError, unsavedFormat] = useMemo(() => {
      try {
        const unsavedFormat = convertStateToRateSheetFormat(
          state,
          savedRateSheetFormat.fileStructure,
        );
        return [null, unsavedFormat];
      } catch (err) {
        if (err instanceof UiValidationError) {
          newrelic.noticeError(err);
          return [err, null];
        } else {
          throw err;
        }
      }
    }, [state, savedRateSheetFormat.fileStructure]);

    const isUnsaved = useMemo(() => {
      return (
        unsavedFormat === null ||
        !_.isEqual(unsavedFormat, savedRateSheetFormat)
      );
    }, [savedRateSheetFormat, unsavedFormat]);

    const handleSave = useCallback(async () => {
      if (validationError) {
        alert(validationError.message);
        return;
      }

      const changeset = convertStateToRateSheetFormat(
        state,
        savedRateSheetFormat.fileStructure,
      );
      setLoadingText("Saving rate sheet format...");

      const format = await Api.Admin.updateRateSheetFormat(
        rateSheetFormat.id,
        changeset,
      );

      setSavedRateSheetFormat(_.omit(format, "id"));
      setLoadingText(null);
    }, [
      state,
      validationError,
      rateSheetFormat.id,
      savedRateSheetFormat.fileStructure,
    ]);
    const accessId = localAccessId();
    const handleDelete = useCallback(async () => {
      if (window.confirm("Delete rate sheet format?")) {
        setLoadingText("Deleting rate sheet format...");

        await Api.Admin.deleteRateSheetFormat(rateSheetFormat.id);
        setLoadingText(null);
        history.push(`/c/${accessId}/__admin/rate-sheet-formats`);
      }
    }, [rateSheetFormat.id, history, accessId]);

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <UnloadPrompt when={isUnsaved} />
        <LoadingOverlay when={!!loadingText} text={loadingText} />

        <Box>
          <Box mt={2} mx={2} display="flex">
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() =>
                history.push(`/c/${accessId}/__admin/rate-sheet-formats`)
              }
            >
              Back to Rate Sheet Formats
            </Button>
            <Box flex="1" />
            {validationError && (
              <Box display="flex" alignItems="center" px={2}>
                <Typography color="secondary">
                  Error: {validationError.message}
                </Typography>
              </Box>
            )}
            <Box mr={2}>
              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
              >
                Delete
              </Button>
            </Box>
            <Button
              variant="outlined"
              startIcon={<DoneIcon />}
              onClick={handleSave}
              disabled={!isUnsaved}
            >
              Save
            </Button>
          </Box>
        </Box>

        <Box flex="1" overflow="hidden">
          <RateSheetFormatEditor state={state} setState={setState} />
        </Box>
      </Box>
    );
  },
);
