import React, { useState, useCallback } from "react";

// Libraries
import { Box, Button, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// api
import { Credentials } from "types/generated-types";
import Link from "design/atoms/link";

interface LoginFormProps {
  initialClientAccessId?: string;
  brandLogo?: string;
  helperText: string;
  error: boolean;
  onLogin: (credentials: Credentials) => Promise<void>;
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { initialClientAccessId, brandLogo, error, helperText, onLogin } =
    props;

  const classes = useStyles();
  const [clientAccessId, setClientAccessId] = useState(
    initialClientAccessId ?? "",
  );
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const isLoginEnabled =
    username.trim() !== "" && password.trim() !== "" && !isLoggingIn;

  const handleLogin = useCallback(async () => {
    setIsLoggingIn(true);

    await onLogin({
      clientAccessId,
      emailAddress: username,
      password,
    });

    setIsLoggingIn(false);
  }, [clientAccessId, username, password, onLogin]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      handleLogin();
    },
    [handleLogin],
  );

  return (
    <Box
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {brandLogo && <img className={classes.logo} alt="Logo" src={brandLogo} />}

      <div className={classes.container}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          {!initialClientAccessId ? (
            <TextField
              error={error}
              autoFocus
              fullWidth
              type="email"
              label="Client ID"
              data-selector="client-id-input"
              value={clientAccessId}
              onChange={(e) => setClientAccessId(e.target.value.toLowerCase())}
            />
          ) : null}
          <TextField
            error={error}
            autoFocus={!!initialClientAccessId}
            fullWidth
            type="email"
            label="Email address"
            data-selector="email-input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            error={error}
            fullWidth
            type="password"
            label="Password"
            data-selector="password-input"
            helperText={helperText}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Box className={classes.loginBtnSection}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={!isLoginEnabled}
            >
              Sign In
            </Button>

            <Link
              to={
                initialClientAccessId
                  ? `/forgot-password/${initialClientAccessId}`
                  : "/forgot-password"
              }
              data-selector="forgot-password-button"
            >
              Forgot Password?
            </Link>
          </Box>
        </form>
      </div>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      maxWidth: 400,
      margin: "0 auto",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      "& .MuiTextField-root": {
        marginBottom: theme.spacing(2),
      },
    },
    logo: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: theme.spacing(3),
      width: "100%",
      maxWidth: 350,
    },
    loginBtnSection: {
      marginTop: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      justifyContent: "space-between",
    },
  }),
);

export default LoginForm;
