import React from "react";
import { Box } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

const StartMessage = React.memo(() => {
  return (
    <Box
      px={5}
      py={3}
      color="success.main"
      bgcolor="white"
      borderBottom="1px solid rgba(0,0,0,0.12)"
      display="flex"
      alignItems="center"
    >
      <CheckIcon />
      <Box pl={1}>
        <strong>Engine ready.</strong> &nbsp;Fill out the loan information form
        on the left to proceed.
      </Box>
    </Box>
  );
});

export default StartMessage;
