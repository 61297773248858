import _ from "lodash";
import React, {
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import { useVirtual } from "react-virtual";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import * as T from "types/engine-types";
import { Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import * as Api from "api";
import { localAccessId } from "features/access-id";

export default function PageAccessList({
  users,
  editMode,
  createMode,
  pricingProfileId,
  setUsersToAdd,
  usersToAdd,
}: {
  users: T.DetailedUser[];
  setUsersToAdd: Dispatch<SetStateAction<T.DetailedUser[]>>;
  usersToAdd: T.DetailedUser[];
  editMode: Boolean;
  createMode: Boolean;
  pricingProfileId?: T.PricingProfileId;
}) {
  const parentRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [profileUsers, setProfileUsers] = useState<T.DetailedUser[]>([]);
  const usersToList = editMode || createMode ? users : profileUsers;
  const [filteredUsers, setFilteredUsers] =
    useState<T.DetailedUser[]>(usersToList);
  const [usersFetched, setUsersFetched] = useState<
    T.PricingProfileId | undefined
  >();
  const [profilesLoading, setProfilesLoading] = useState(false);
  const [userIds, setUserIds] = useState<T.UserId[]>([]);

  useEffect(() => {
    if (
      pricingProfileId &&
      (!usersFetched || usersFetched !== pricingProfileId) &&
      !profilesLoading
    ) {
      const fetchData = async () => {
        setProfilesLoading(true);
        const userIds = await Api.listPricingProfileUsers(pricingProfileId);
        setUserIds(userIds);
        setUsersFetched(pricingProfileId);
        setProfilesLoading(false);
      };
      fetchData();
    }
  }, [usersFetched, users, pricingProfileId, profilesLoading]);

  useEffect(() => {
    if (!!userIds.length) {
      const fetchedUsers = users.filter((u: T.DetailedUser) =>
        userIds.includes(u.id),
      );
      setProfileUsers(fetchedUsers);
      setUsersToAdd(fetchedUsers);
    }
  }, [setUsersToAdd, userIds, users]);

  useEffect(() => {
    setFilteredUsers(
      usersToList.filter((p: T.DetailedUser) => {
        if (editMode || createMode) {
          return p.displayName.toLowerCase().includes(searchTerm.toLowerCase());
        } else {
          return p.displayName.toLowerCase().includes(searchTerm.toLowerCase());
        }
      }),
    );
  }, [searchTerm, usersToList, pricingProfileId, editMode, createMode]);

  const rowVirtualizer = useVirtual({
    size: filteredUsers?.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, []),
  });
  const accessId = localAccessId();
  return (
    <Table
      style={{ flex: "1 0 24%", borderRight: "1px solid rgba(0,0,0,.15)" }}
    >
      <TableActions style={{ padding: "8px" }}>
        <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
          <SearchInput
            label="users"
            requireDispatch={false}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <ResultsCount>
          {filteredUsers.length} <VisibilityIcon />
          <span className="separator">|</span>
          {usersToAdd.length} <CheckIcon />
          <span className="separator">|</span>
          {users.length} Total
        </ResultsCount>
      </TableActions>

      {editMode || createMode ? (
        <>
          <TableHeader>
            <TableHeaderCell style={{ flexBasis: "50px" }}>
              <Checkbox
                checked={filteredUsers.every((p) => {
                  return usersToAdd.map((p) => p.id).includes(p.id);
                })}
                onChange={(e) => {
                  const pruned = _.without(usersToAdd, ...filteredUsers);
                  if (e.target.checked) {
                    setUsersToAdd([...pruned, ...filteredUsers]);
                  } else {
                    setUsersToAdd(pruned);
                  }
                }}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              />
            </TableHeaderCell>

            <TableHeaderCell style={{ flexBasis: "calc(100% - 50px)" }}>
              Users
            </TableHeaderCell>
          </TableHeader>

          {filteredUsers.length ? (
            <TableBodyWrapper ref={parentRef}>
              <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
                {rowVirtualizer.virtualItems.map((virtualRow) => (
                  <TableRow
                    key={virtualRow.index}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <TableCell style={{ flexBasis: "54px" }}>
                      <Checkbox
                        checked={usersToAdd.includes(
                          filteredUsers[virtualRow.index],
                        )}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        onChange={(e, checked) => {
                          if (checked) {
                            setUsersToAdd([
                              ...usersToAdd,
                              filteredUsers[virtualRow.index],
                            ]);
                          } else {
                            setUsersToAdd(
                              _.without(
                                usersToAdd,
                                filteredUsers[virtualRow.index],
                              ),
                            );
                          }
                        }}
                      />
                    </TableCell>

                    <TableCell style={{ flexBasis: "calc(100% - 54px)" }}>
                      {filteredUsers[virtualRow.index].displayName}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableBodyWrapper>
          ) : (
            <p
              style={{ marginTop: "14px", textAlign: "center", opacity: 0.65 }}
            >
              No users have been assigned to this role.
            </p>
          )}
        </>
      ) : (
        <>
          <TableHeader>
            <TableHeaderCell style={{ flexBasis: "100%" }}>
              Users
            </TableHeaderCell>
          </TableHeader>

          {filteredUsers.length ? (
            <TableBodyWrapper ref={parentRef}>
              <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
                {rowVirtualizer.virtualItems.map((virtualRow) => (
                  <TableRow
                    key={virtualRow.index}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <TableCell style={{ flexBasis: "100%" }}>
                      <Link
                        to={`/c/${accessId}/users/${
                          filteredUsers[virtualRow.index].id
                        }`}
                      >
                        {filteredUsers[virtualRow.index].displayName}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableBodyWrapper>
          ) : (
            <p
              style={{ marginTop: "14px", textAlign: "center", opacity: 0.65 }}
            >
              No users have been assigned to this role.
            </p>
          )}
        </>
      )}
    </Table>
  );
}
