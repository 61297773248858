import * as Api from "api";
import Table from "design/atoms/admin-table";
import { Map as IMap } from "immutable";
import { Column } from "material-table";
import { Box, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import * as T from "types/engine-types";
import { localAccessId } from "features/access-id";

export const SubscriptionsPage = React.memo(() => {
  const [subscriptionInfoLoad, reloadSubscriptionInfo] =
    Api.Admin.useSubscriptions();
  if (subscriptionInfoLoad.status === "error") {
    return <>"Can't load Subscriptions"</>;
  } else if (subscriptionInfoLoad.status === "loading") {
    return <Loader loaded={false} />;
  } else {
    return (
      <LoadedSubscriptionsPage
        subscriptionInfo={subscriptionInfoLoad.value}
        reloadSubscriptions={reloadSubscriptionInfo}
      />
    );
  }
});

export const LoadedSubscriptionsPage = React.memo(
  ({
    subscriptionInfo,
    reloadSubscriptions,
  }: {
    subscriptionInfo: T.SubscriptionInfo;
    reloadSubscriptions: () => void;
  }) => {
    const accessId = localAccessId();
    function generateDeleteFunction(s: T.Subscription): () => void {
      return async () => {
        await Api.Admin.subscriptionDelete(s);
        reloadSubscriptions();
      };
    }
    return (
      <Box>
        <Box px={2} my={2} display="flex">
          <Box flex="1" />
          <Button
            component={Link}
            to={`/c/${accessId}/__admin/create-subscription`}
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Create New Subscription
          </Button>
        </Box>
        <Box px={2} my={2}>
          <Table<DisplayableSubscription>
            columns={COLUMNS}
            data={getDisplayableSubscriptions(
              subscriptionInfo,
              generateDeleteFunction,
            )}
          />
        </Box>
      </Box>
    );
  },
);

function getDisplayableSubscriptions(
  info: T.SubscriptionInfo,
  generateDeleteFunction: (s: T.Subscription) => () => void,
): DisplayableSubscription[] {
  const { subscriptions, roles, clients, pricingProfiles } = info;
  const clientsMap = IMap(clients.map((c) => [c.id, c]));
  const rolesMap = IMap(roles.map((r) => [r.id, r]));
  const profilesMap = IMap(pricingProfiles.map((p) => [p.id, p]));
  const displayables = subscriptions.map((s) => {
    return {
      subscribingClientAccessId:
        clientsMap.get(s.subscribingClientId)?.accessId ||
        s.subscribingClientId,
      investingClientAccessId:
        clientsMap.get(s.investingClientId)?.accessId || s.investingClientId,
      investingRole: rolesMap.get(s.investingRoleId)?.name || s.investingRoleId,
      investingPricingProfile:
        profilesMap.get(s.investingProfileId)?.name || s.investingProfileId,
      deleteButton: (
        <Button
          style={{ marginRight: 16 }}
          variant="outlined"
          startIcon={<DeleteIcon />}
          disabled={false}
          onClick={generateDeleteFunction(s)}
        >
          Delete
        </Button>
      ),
    };
  });
  displayables.sort((a, b) =>
    (
      a.subscribingClientAccessId +
      " " +
      a.investingClientAccessId
    ).localeCompare(
      b.subscribingClientAccessId + " " + b.investingClientAccessId,
    ),
  );
  return displayables;
}

const COLUMNS: Column<DisplayableSubscription>[] = [
  {
    title: "Subscribing Client Login ID",
    field: "subscribingClientAccessId",
  },
  {
    title: "Investing Client Login ID",
    field: "investingClientAccessId",
  },
  {
    title: "Role in investing client",
    field: "investingRole",
  },
  {
    title: "Pricing profile in investing client",
    field: "investingPricingProfile",
  },
  {
    title: "",
    field: "deleteButton",
  },
];

type DisplayableSubscription = {
  investingClientAccessId: string;
  investingRole: string;
  investingPricingProfile: string;
  subscribingClientAccessId: string;
  deleteButton: JSX.Element;
};
