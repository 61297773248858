import React from "react";
import { Backdrop, Box, CircularProgress } from "@material-ui/core";

export const LoadingOverlay = React.memo(
  ({ when, text }: { when: boolean; text?: string | null }) => {
    return (
      <Backdrop
        open={when}
        style={{ flexDirection: "column", color: "#fff", zIndex: 10 }}
      >
        <Box py={1}>
          <CircularProgress color="inherit" />
        </Box>
        <Box py={1} fontSize="18px">
          {text || "Processing..."}
        </Box>
      </Backdrop>
    );
  },
);
