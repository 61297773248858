import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import WarningIcon from "@material-ui/icons/Warning";
import { Configuration } from "config";
import * as T from "types/engine-types";
import * as Fields from "features/fields";
import { ObjectDetails } from "features/objects";
import { usePriceScenarioTableCellStyles } from "pages/loans/styles";
import MiddotIcon from "../middot-icon";
import color from "design/subatomics/colors";

const SummaryPriceScenarioTableCell = React.memo(
  ({
    isBestEx,
    scenarioSummary,
    adjustedPriceField,
    config,
    objectDetails,
  }: {
    isBestEx?: Boolean;
    scenarioSummary: T.PriceScenarioSummary | null;
    adjustedPriceField: T.BaseFieldDefinition;
    config: Configuration;
    objectDetails: ObjectDetails;
  }): JSX.Element => {
    const C = usePriceScenarioTableCellStyles();
    const horizontalBarChar = String.fromCharCode(8213);

    if (!scenarioSummary) {
      return (
        <td className={C.cell + " " + C.noScenario}>{horizontalBarChar}</td>
      );
    }

    switch (scenarioSummary.status) {
      case "missing-configuration":
        throw new Error(
          "we shouldn't be displaying a table cell if missing configuration",
        );
      case "approved":
        return (
          <td
            className={C.cell + " " + C.approved}
            style={{
              border: `1px solid ${
                isBestEx
                  ? color({ color: "green", shade: 5 })
                  : color({ color: "lightBorder" })
              }`,
              color: color({ color: "green", shade: 5 }),
            }}
          >
            <div>
              {Fields.fieldValueToString(
                config,
                objectDetails,
                adjustedPriceField.valueType,
                {
                  type: "number",
                  value: scenarioSummary.adjustedPrice!,
                },
              )}
            </div>
          </td>
        );
      case "review-required":
        return (
          <td
            className={C.cell + " " + C.reviewRequired}
            style={{
              border: `1px solid ${
                isBestEx
                  ? color({ color: "green", shade: 5 })
                  : color({ color: "lightBorder" })
              }`,
              color: color({ color: "orange", shade: 5 }),
            }}
          >
            <div>
              {Fields.fieldValueToString(
                config,
                objectDetails,
                adjustedPriceField.valueType,
                {
                  type: "number",
                  value: scenarioSummary.adjustedPrice!,
                },
              )}
            </div>
          </td>
        );
      case "rejected":
        return (
          <td className={C.cell + " " + C.rejected}>
            <div>
              <ClearIcon />
            </div>
          </td>
        );
      case "available":
        return (
          <td className={C.cell + " " + C.missingData}>
            <div>
              <MiddotIcon />
            </div>
          </td>
        );
      case "error": {
        return (
          <td className={C.cell + " " + C.error}>
            <div>
              <WarningIcon />
            </div>
          </td>
        );
      }
    }
  },
);

export default SummaryPriceScenarioTableCell;
