import React, { forwardRef } from "react";
import MaterialTable, {
  Icons,
  Options,
  MaterialTableProps,
} from "material-table";

// icons
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";

export type TableProps<RowData extends object> = Exclude<
  MaterialTableProps<RowData>,
  "icons" | "options"
>;

export function Table<RowData extends object>(
  props: TableProps<RowData>,
): JSX.Element {
  const TABLE_OPTIONS: Options<RowData> = {
    searchFieldAlignment: "left",
    showTitle: false,
    draggable: false,
    pageSize: 10,
    pageSizeOptions: [5, 10, 25, 50, 100],
  };

  const TABLE_ICONS: Icons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
  };

  return (
    <MaterialTable<RowData>
      icons={TABLE_ICONS}
      options={TABLE_OPTIONS}
      {...props}
    />
  );
}

export default Table;
