import styled from "styled-components";
import colors from "design/subatomics/colors";
import shadows from "design/subatomics/shadows";
import radius from "design/subatomics/corners";
import whitespace from "design/subatomics/whitespace";
import layers from "design/subatomics/layers";
import { styles } from "design/atoms/typography";
import { StylesConfig } from "react-select";

const customStyles = <T>(): StylesConfig<T> => {
  return {
    option: (provided, state) => {
      return {
        ...provided,
        ...styles.inputSmall,

        background: state.isFocused
          ? colors({ color: "green", shade: 8 })
          : colors({ color: "white" }),

        color: state.isFocused
          ? colors({ color: "gray", shade: 4 })
          : colors({ color: "black" }),
        margin: 0,
        padding: whitespace("less"),
        "&:hover, &:active": {
          cursor: "pointer",
          background: colors({ color: "green", shade: 8 }),
          color: colors({ color: "gray", shade: 4 }),
        },
      };
    },
    container: (provided, state) => {
      return {
        ...provided,
      };
    },
    control: (provided, state) => {
      return {
        ...provided,
        boxShadow: shadows.controls,
        borderRadius: radius.small,

        borderColor: state.isFocused
          ? colors({ color: "green", shade: 5 })
          : colors({ color: "lightBorder" }),
        background: colors({ color: "white" }),
        "&:hover": {
          borderColor: colors({ color: "green", shade: 6 }),
        },
      };
    },
    placeholder: (provided, state) => {
      return {
        ...provided,

        display: state.selectProps.menuIsOpen ? "none" : "block",
        ...styles.input,
        color: colors({ color: "border" }),
        paddingLeft: whitespace("less"),
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        ...styles.input,
        borderRadius: radius.small,
        boxShadow: shadows.modals,
        zIndex: layers({ layer: "modal" }),
        color: colors({ color: "gray", shade: 6 }),
        backgroundColor: colors({ color: "white" }),
        border: `1px solid ${colors({ color: "lightBorder" })}`,
        margin: 0,
        top: `calc(100% + ${whitespace("least")})`,
        padding: 0,
        overflow: "hidden",
      };
    },
    menuList: (provided, state) => {
      return {
        ...provided,
        ...styles.input,
        margin: 0,
        padding: 0,
        color: colors({ color: "lightBorder" }),
        zIndex: layers({ layer: "modal" }),
      };
    },
    menuPortal: (provided, state) => {
      return {
        ...provided,
        ...styles.input,
        color: colors({ color: "lightBorder" }),
        zIndex: layers({ layer: "modal" }),
      };
    },
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        color: colors({ color: "gray", shade: 6 }),
      };
    },
    clearIndicator: (provided, state) => {
      return {
        ...provided,
        color: colors({ color: "gray", shade: 7 }),
        "&:hover": {
          color: colors({ color: "green", shade: 6 }),
        },
      };
    },
    indicatorSeparator: (provided, state) => {
      return {
        ...provided,
        display: "none",
      };
    },
    input: (provided, state) => {
      return {
        ...provided,
        position: "relative",
        top: "-2px",
        ...styles.inputSmall,
        color: colors({ color: "gray", shade: 7 }),
        padding: `0 0 0 ${whitespace("less")}`,
        margin: 0,
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        ...styles.input,
        color: colors({ color: "gray", shade: 1 }),
        margin: `${whitespace("least")} ${whitespace("least")} ${whitespace(
          "least",
        )} ${whitespace("less")}`,
      };
    },
    valueContainer: (provided, state) => {
      return {
        ...provided,
        padding: `${whitespace("least")} 0 0`,
        overflow: "visible",
      };
    },
    noOptionsMessage: (provided, state) => {
      return {
        ...provided,
        ...styles.smallText,
        padding: whitespace("less"),
        color: colors({ color: "gray", shade: 5 }),
      };
    },
  };
};

export const Label = styled.span`
  ${styles.microTitle};
  text-transform: uppercase;
  position: relative;
  top: ${whitespace("less")};
  left: ${whitespace("least")};
  background: ${colors({ color: "white" })};
  padding: 0 ${whitespace("least")};
  z-index: ${layers({ layer: "controls" })};
`;

export default customStyles;
