import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Pipeline } from "./styles";
import PipelineResults from "./_components/pipeline-results";
import { useAppDispatch } from "features/store";
import {
  nonNullApplicationInitializationSelector,
  isAdminSelector,
  usePermissions,
} from "features/application-initialization";
import { getPipeline, pipelineSelector } from "features/pipeline";
import * as Api from "../../api";

export default React.memo(() => {
  const dispatch = useAppDispatch();
  const { loading, records } = useSelector(pipelineSelector);
  const { user } = useSelector(nonNullApplicationInitializationSelector);
  const isAdmin = useSelector(isAdminSelector);
  const hasPermission = usePermissions();
  const hasViewAllRecords = hasPermission("pipeline-records-view-all");

  useEffect(() => {
    const syncOutputs = async () => await Api.syncPipelineOutputs();
    syncOutputs();
  }, []);

  useEffect(() => {
    if (!loading && records === undefined) {
      dispatch(
        getPipeline(
          hasViewAllRecords ? { ownerId: null } : { ownerId: user.id },
        ),
      );
    }
  }, [records, loading, dispatch, user, isAdmin, hasViewAllRecords]);

  return (
    <Pipeline className="page-pipeline">
      <PipelineResults loading={loading} />
    </Pipeline>
  );
});
