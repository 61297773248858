import React, { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    newrelic.noticeError(error);
  }

  public render() {
    if (this.state.hasError && this.state.error) {
      if (this.state.error.message) {
        return <h1>Error: {this.state.error.message}</h1>;
      } else {
        return <h1>Error: {JSON.stringify(this.state.error)}</h1>;
      }
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
