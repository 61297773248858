import React, { ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import FilterListIcon from "@material-ui/icons/FilterList";

type SearchInputComponentArguments = {
  disabled?: boolean;
  label: string;
  searchTerm: String;
  setSearchTerm: Function;

  // setSearchTerm could be interacting with redux state or local parent component state
  // when interacting with redux state, setSearchTerm requires a dispatch and (requireDispatch: true) - default
  // when interacting with component state, dispatch should not be called (requireDispatch: false)
  requireDispatch?: boolean;
};

const SearchInput = ({
  disabled = false,
  searchTerm,
  setSearchTerm,
  label,
  requireDispatch = true,
}: SearchInputComponentArguments) => {
  const dispatch = useDispatch();
  const handleChange = (e: ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    requireDispatch ? dispatch(setSearchTerm(value)) : setSearchTerm(value);
  };

  return (
    <TextField
      className="search-input"
      data-selector="search-input"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FilterListIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <ClearIcon
              data-selector="clear-search-input"
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={() =>
                // This comment was generated when upgrading react-scripts and eslint
                // TODO: fix the lint rule and remove this eslint-disable comment
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                requireDispatch
                  ? dispatch(setSearchTerm(""))
                  : setSearchTerm("")
              }
            />
          </InputAdornment>
        ),
      }}
      disabled={disabled}
      value={searchTerm}
      placeholder={`Filter ${label} by text`}
      onChange={handleChange}
    />
  );
};

export default SearchInput;
