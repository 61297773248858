import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { DecoratedRuleHeader } from "types/engine-types";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { useVirtual } from "react-virtual";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import {
  rulesSelector,
  filteredRulesSelector,
  getRules,
  setSearchTerm,
  setSort,
} from "features/rules";
import { useSelector, useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import { usePermissions } from "features/roles";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

export default function RulesPage() {
  const parentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const rulesState = useSelector(rulesSelector);
  const { searchTerm, sortField } = rulesState;
  const rules = useSelector(filteredRulesSelector);
  const hasPermission = usePermissions();
  const hasCreatePerm = hasPermission("rules-create");
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);

  useEffect(() => {
    if (rules.length === 0) {
      dispatch(getRules());
    }
  }, [dispatch, rules.length]);

  const rowVirtualizer = useVirtual({
    size: rules?.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, []),
  });

  const onRowClick = React.useCallback(
    (event: unknown, rule: DecoratedRuleHeader | undefined) => {
      if (rule) {
        history.push(`/c/${accessId}/rules/${rule.id}`);
      }
    },
    [history, accessId],
  );

  return (
    <Table>
      <TableActions>
        <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
          <SearchInput
            label="rules"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <ResultsCount>
          <span className="visible">
            {rules.length} <VisibilityIcon />
          </span>
          <span className="separator">|</span>
          <span className="total">{rulesState.rules.length} Total</span>
        </ResultsCount>
        <Button
          disabled={!hasCreatePerm}
          className="add-rule"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => history.push(`/c/${accessId}/create-rule`)}
        >
          Add Rule
        </Button>
      </TableActions>

      <TableHeader>
        <TableHeaderCell
          style={{ flexBasis: "40%" }}
          onClick={() => dispatch(setSort("name"))}
        >
          Name{" "}
          {sortField === "name" && (
            <SwapVertIcon
              style={{ color: sortField === "name" ? "#90d18c" : "inherit" }}
            />
          )}
        </TableHeaderCell>
        <TableHeaderCell
          style={{ flexBasis: "40%" }}
          onClick={() => dispatch(setSort("stageId"))}
        >
          Category{" "}
          {sortField === "stageId" && (
            <SwapVertIcon
              style={{ color: sortField === "stageId" ? "#90d18c" : "inherit" }}
            />
          )}
        </TableHeaderCell>
        <TableHeaderCell
          style={{ flexBasis: "20%", textAlign: "center" }}
          onClick={() => dispatch(setSort("isActive"))}
        >
          Is Active{" "}
          {sortField === "isActive" && (
            <SwapVertIcon
              style={{
                color: sortField === "isActive" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>
      </TableHeader>

      {rules && (
        <TableBodyWrapper ref={parentRef}>
          <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
            {rowVirtualizer.virtualItems.map((virtualRow) => (
              <TableRow
                key={virtualRow.index}
                onClick={(e) => onRowClick(e, rules[virtualRow.index])}
                style={{
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <TableCell style={{ flexBasis: "40%" }}>
                  {rules[virtualRow.index].name}
                </TableCell>
                <TableCell
                  style={{ flexBasis: "40%", textTransform: "capitalize" }}
                >
                  {rules[virtualRow.index].stageId}
                </TableCell>
                <TableCell style={{ flexBasis: "20%", textAlign: "center" }}>
                  {rules[virtualRow.index].isActive ? (
                    <CheckIcon
                      className="is-active"
                      style={{ fill: "#90d18c" }}
                    />
                  ) : (
                    <CloseIcon
                      className="is-inactive"
                      style={{ fill: "red" }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableBodyWrapper>
      )}
    </Table>
  );
}
