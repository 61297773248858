import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { PipelineResult, InlineGroup, ResultActions } from "./styles";
import KeyValue, { KeyValueGroup } from "design/atoms/key-value";
import {
  AsLink,
  SecondaryTitle,
  SmallText,
  PanelTitle,
} from "design/atoms/typography";
import { useHistory } from "react-router-dom";
import * as T from "types/engine-types";
import {
  expandedConfigSelector,
  nonNullApplicationInitializationSelector,
  objectDetailsMapSelector,
  usePermissions,
} from "features/application-initialization";
import { useDispatch, useSelector } from "react-redux";
import Icon from "design/atoms/icon";
import colors from "design/subatomics/colors";
import {
  faEye,
  faArchive,
  faTrash,
  faPencil,
  faCancel,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import Button from "design/atoms/button";
import { setPipelineRecordId, setPipelineStage } from "features/loan-pricing";
import { getPipeline, pipelineSelector } from "features/pipeline";
import * as Fields from "features/fields";
import * as Api from "api";
import moment from "moment";
import Field from "design/organisms/field";
import { useIMapSetter } from "features/utils";
import { Map as IMap } from "immutable";
import {
  FieldValueState,
  newFieldValueState,
} from "design/organisms/field-value-editor";

import {
  InputWrapper,
  InputSection,
} from "pages/loans-v2/loan-pricing/_components/pipeline-header/styles";
import whitespace from "design/subatomics/whitespace";

export default React.memo(
  ({
    record,
    icon,
    color,
    interactable = true,
  }: {
    // this type used to be `T.PipelineRecordHeader | null`, but when
    // preferredScenario was added it caused an error when a
    // `T.PipelineRecord` is passed as the component prop. Removing the
    // preferredScenario field from the type fixes the error.
    record: T.PipelineRecord | T.PipelineRecordHeader;
    icon: ReactNode;
    color?: string;
    interactable?: boolean;
  }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const config = useSelector(expandedConfigSelector);
    const objectDetails = useSelector(objectDetailsMapSelector);
    const { user } = useSelector(nonNullApplicationInitializationSelector);
    const hasPermission = usePermissions();
    const userOwnsRecord: boolean = user.id === record?.ownerId;
    const hasViewAllRecords = hasPermission("pipeline-records-view-all");
    const hasDeleteAll = hasPermission("pipeline-records-delete-all");
    const hasEditAll = hasPermission("pipeline-records-edit-all");
    const hasDeleteOwn = hasPermission("pipeline-records-delete-own");
    const pipelineFields = config.pipelineOnlyFields;
    const [isEditMode, setIsEditMode] = useState(false);
    const pipelineState = useSelector(pipelineSelector);

    const [pipelineValues, setPipelineValues] = useState<
      T.FieldValueMapping[] | null
    >(null);
    const [outputValues, setOutputValues] = useState<
      T.FieldValueMapping[] | null
    >(null);
    const [applicationValues, setApplicationValues] = useState<
      T.FieldValueMapping[] | null
    >(null);
    const [allValues, setAllValues] = useState<(string | null)[][] | null>(
      null,
    );

    useEffect(
      () => {
        if (!record) {
          return;
        }

        const foundRecord = pipelineState.records?.find(
          (r) => r.id === record.id,
        );

        if (foundRecord) {
          setPipelineValues(foundRecord.pipelineOnlyFieldValues);
        }

        // TODO: the ideal behaviour here is probably to always display the output values,
        //       but also display the output status

        if (
          foundRecord &&
          ["approved", "review-required"].includes(foundRecord.outputStatus)
        ) {
          setOutputValues(foundRecord.outputFieldValues);
        }

        if (
          foundRecord &&
          ["approved", "review-required"].includes(foundRecord.outputStatus)
        ) {
          setApplicationValues(
            foundRecord.preferredScenario.creditApplicationFieldValues.filter(
              (v) => config.settings.pipelineFields.includes(v.fieldId),
            ),
          );
        }
        setAllValues(
          config.settings.pipelineFields.map((field) => {
            let newField: T.BaseFieldDefinition | undefined =
              config.pipelineOnlyFields.find((f) => f.id === field);

            if (!newField) {
              newField = config.allFieldsById.get(field);
            }

            const valueObj = [
              ...(applicationValues || []),
              ...(outputValues || []),
              ...(pipelineValues || []),
            ].find((v) => v.fieldId === field);

            if (!newField?.name) {
              return [null, null];
            }

            if (!valueObj?.value || !newField?.valueType) {
              return [newField.name, null];
            }

            const value = Fields.fieldValueToString(
              config,
              objectDetails,
              newField.valueType,
              valueObj.value,
            );

            if (newField.name && value) {
              return [newField.name, value];
            } else {
              return [newField.name, null];
            }
          }),
        );
      },
      // eslint is saying to include applicationValues in the dependancies but that causes an infinite loop
      // eslint-disable-next-line
      [
        objectDetails,
        config,
        outputValues,
        pipelineState.records,
        record,
        pipelineValues,
        config.settings.pipelineFields,
        config.allFieldsById,
      ],
    );

    const initialPipelineFieldValueStates: IMap<T.FieldId, FieldValueState> =
      useMemo(
        () =>
          IMap<T.FieldId, FieldValueState>(
            pipelineFields.map((f) => [f.id, newFieldValueState(f.valueType)]),
          ),
        [pipelineFields],
      );

    const [pipelineFieldValueStates, setPipelineFieldValueStates] = useState(
      initialPipelineFieldValueStates,
    );

    const pipelineFieldValueSetter = useIMapSetter(setPipelineFieldValueStates);

    const handleClick = () => {
      if (record && interactable) {
        dispatch(setPipelineRecordId(record?.id));
        dispatch(setPipelineStage("view"));
        history.push(
          (record as T.PipelineRecordHeader).preferredScenario?.priceALoanUrl,
        );
      }
    };

    return (
      <PipelineResult
        className={
          color
            ? `page-pipeline-component-pipeline-result ${color} ${
                interactable ? "interactable" : "non-interactable"
              }`
            : `page-pipeline-component-pipeline-result`
        }
      >
        <SecondaryTitle onClick={handleClick}>
          <AsLink style={{ color: colors({ color: "gray", shade: 4 }) }}>
            {icon}{" "}
            <strong>
              {pipelineValues
                ?.slice(0, 1)
                .map((field: T.FieldValueMapping, i) => {
                  const newField: T.BaseFieldDefinition | undefined =
                    config.pipelineOnlyFields.find(
                      (f) => f.id === field.fieldId,
                    );

                  if (!newField) return <span key={i} />;

                  const value = Fields.fieldValueToString(
                    config,
                    objectDetails,
                    newField.valueType,
                    field.value,
                  );

                  if (!value) return <span key={i} />;

                  return value;
                })}
            </strong>
          </AsLink>
        </SecondaryTitle>

        <ResultActions>
          {!isEditMode &&
            (userOwnsRecord || hasEditAll) &&
            record?.status === "open" &&
            !!pipelineFields.filter((field) => {
              return !pipelineValues?.map((f) => f.fieldId).includes(field.id);
            }).length && (
              <Button
                title="Update this record"
                className="update-product hide-for-mobile"
                onClick={() => {
                  setIsEditMode(!isEditMode);
                }}
              >
                <Icon icon={faPencil} />
              </Button>
            )}
          {!isEditMode &&
            record?.status === "archived" &&
            ((userOwnsRecord && hasDeleteOwn) || hasDeleteAll) && (
              <Button
                title="Delete this record"
                className="select-product hide-for-mobile"
                onClick={() => {
                  if (user && record) {
                    Api.deletePipelineRecord(record.id).then(() => {
                      dispatch(
                        getPipeline(
                          hasViewAllRecords
                            ? { ownerId: null }
                            : { ownerId: user.id },
                        ),
                      );
                    });
                  }
                }}
              >
                <Icon icon={faTrash} />
              </Button>
            )}

          {!isEditMode &&
            record?.status === "archived" &&
            pipelineValues &&
            ((userOwnsRecord && hasDeleteOwn) || hasEditAll) && (
              <Button
                title="Reopen this record"
                className="select-product hide-for-mobile"
                onClick={() => {
                  if (user && record) {
                    Api.updatePipelineRecord(record.id, {
                      ownerId: user.id,
                      status: "open",
                      pricingProfileId: record.pricingProfileId,
                      pipelineOnlyFieldValues: pipelineValues,
                    }).then(() => {
                      dispatch(
                        getPipeline(
                          hasViewAllRecords
                            ? { ownerId: null }
                            : { ownerId: user.id },
                        ),
                      );
                    });
                  }
                }}
              >
                <Icon icon={faFolderOpen} />
              </Button>
            )}

          {!isEditMode &&
            record?.status === "open" &&
            pipelineValues &&
            ((userOwnsRecord && hasDeleteOwn) || hasEditAll) && (
              <Button
                title="Archive this record"
                className="select-product hide-for-mobile"
                onClick={() => {
                  if (user && record) {
                    Api.updatePipelineRecord(record.id, {
                      ownerId: user.id,
                      status: "archived",
                      pricingProfileId: record.pricingProfileId,
                      pipelineOnlyFieldValues: pipelineValues,
                    }).then(() => {
                      dispatch(
                        getPipeline(
                          hasViewAllRecords
                            ? { ownerId: null }
                            : { ownerId: user.id },
                        ),
                      );
                    });
                  }
                }}
              >
                <Icon icon={faArchive} />
              </Button>
            )}

          {!isEditMode && (
            <Button
              className="select-product hide-for-mobile"
              onClick={handleClick}
            >
              <Icon icon={faEye} />
            </Button>
          )}

          {isEditMode && (
            <Button
              className="update-product hide-for-mobile"
              onClick={() => {
                setIsEditMode(false);
              }}
            >
              <Icon icon={faCancel} />
            </Button>
          )}

          {isEditMode && record && (
            <Button
              isPrimary={true}
              className="save-product hide-for-mobile"
              onClick={() => {
                Api.updatePipelineRecord(record.id, {
                  ownerId: record.ownerId,
                  status: record.status,
                  pricingProfileId: record.pricingProfileId,
                  pipelineOnlyFieldValues:
                    record.pipelineOnlyFieldValues.concat(
                      Array.from(pipelineFieldValueStates).map((v) => {
                        return {
                          fieldId: v[0],
                          value: v[1],
                        } as T.FieldValueMapping;
                      }),
                    ),
                }).then(() => {
                  setIsEditMode(false);

                  dispatch(
                    getPipeline(
                      hasViewAllRecords
                        ? { ownerId: null }
                        : { ownerId: user.id },
                    ),
                  );
                });
              }}
            >
              Save
            </Button>
          )}
        </ResultActions>

        <InlineGroup>
          <SmallText className="created-at">
            Created by: {record?.ownerId}
          </SmallText>
        </InlineGroup>

        <KeyValueGroup>
          <KeyValue
            label="Created At"
            value={moment(record?.createdAt)?.format("MM-DD-YYYY HH:mmA") || ""}
          />

          {allValues?.map((pair: (string | null)[], i) => {
            if (!pair[0] || i === 0) return <span key={i} />;

            return (
              <KeyValue key={i} label={pair[0]} value={pair[1] || "&mdash;"} />
            );
          })}

          {isEditMode &&
            pipelineFields.filter((field) => {
              return !record?.pipelineOnlyFieldValues
                .map((f) => f.fieldId)
                .includes(field.id);
            }).length && (
              <>
                <PanelTitle>Update Missing Information</PanelTitle>
                <InputSection
                  style={{
                    justifyContent: "flex-start",
                    marginLeft: whitespace("more", true),
                  }}
                >
                  {pipelineFields.map((field) => {
                    if (
                      !record?.pipelineOnlyFieldValues
                        .map((f) => f.fieldId)
                        .includes(field.id)
                    ) {
                      return (
                        <InputWrapper>
                          <Field
                            showDescription={false}
                            key={field.id}
                            required={false}
                            showErrors={true}
                            margin="dense"
                            field={field}
                            defaultValue={undefined}
                            fieldState={
                              field
                                ? pipelineFieldValueStates.get(field.id) ||
                                  newFieldValueState(field.valueType)
                                : null
                            }
                            parentState={null}
                            setState={(e) => {
                              return pipelineFieldValueSetter.withKey(field.id)(
                                e,
                              );
                            }}
                          />
                        </InputWrapper>
                      );
                    }
                  })}
                </InputSection>
              </>
            )}
        </KeyValueGroup>
      </PipelineResult>
    );
  },
);
