import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DoneIcon from "@material-ui/icons/Done";
import Loader from "react-loader";
import { Set } from "immutable";
import _ from "lodash";
import {
  expandedConfigSelector,
  nonNullApplicationInitializationSelector,
} from "features/application-initialization";
import { useSelector } from "react-redux";

import * as Api from "api";
import MessageBar from "design/atoms/message-bar";
import {
  RuleEditor,
  RuleState,
  newRuleState,
  convertRuleToState,
  convertStateToRule,
  validateRuleState,
} from "../_components/rule-editor";
import UnloadPrompt from "design/atoms/unload-prompt";
import * as T from "types/engine-types";
import { useDispatch } from "react-redux";
import { getRules } from "features/rules";

export default function CreateRulePage() {
  const basedOnRuleId = new URLSearchParams(useLocation().search).get(
    "basedOnRuleId",
  ) as T.RuleId | null;

  const basedOnRulePromise = React.useMemo(
    () =>
      basedOnRuleId ? Api.getRule(basedOnRuleId) : Promise.resolve(undefined),
    [basedOnRuleId],
  );

  const [investorsLoadState] = Api.useInvestors();
  const combinedLoadState = Api.combineLoadStates(
    Api.useProducts()[0],
    Api.useRules()[0],
    Api.useLoadState(basedOnRulePromise),
    investorsLoadState,
  );

  if (combinedLoadState.status === "loading") {
    return <Loader loaded={false}></Loader>;
  }

  if (combinedLoadState.status === "error") {
    return (
      <MessageBar
        text="Something went wrong, please check your internet connection and try again later. If you see this message again, please contact support."
        type="error"
      />
    );
  }

  const [products, rules, basedOnRule, investors] = combinedLoadState.value;

  return (
    <CreateRulePageLoaded {...{ products, rules, basedOnRule, investors }} />
  );
}

type LoadedProps = {
  products: T.DecoratedProductHeader[];
  rules: T.DecoratedRuleHeader[];
  basedOnRule: T.Rule | undefined;
  investors: T.DecoratedInvestorHeader[];
};

function CreateRulePageLoaded({
  products,
  rules,
  basedOnRule,
  investors,
}: LoadedProps) {
  const history = useHistory();
  const config = useSelector(expandedConfigSelector);
  const dispatch = useDispatch();
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);

  const existingNames = React.useMemo(
    () => Set(rules.map((r) => r.name)),
    [rules],
  );

  const initialRuleState = basedOnRule
    ? convertRuleToState(basedOnRule, config)
    : newRuleState(config);

  const copyOfOriginalRuleState: RuleState | null =
    _.cloneDeep(initialRuleState);

  const [ruleState, setRuleState] = useState(initialRuleState);

  const [isCreate, setIsCreate] = useState(false);

  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const nextError = validateRuleState(config, existingNames, ruleState);

  const handleCreateRule = async () => {
    setIsCreate(true);

    setShowValidationErrors(true);

    if (nextError) {
      setIsCreate(false);
      return;
    }

    const rule = convertStateToRule(config, existingNames, ruleState);

    setLoading(true);
    const newRule = await Api.createRule(rule);
    setLoading(false);
    dispatch(getRules());
    history.push(`/c/${accessId}/rules/${newRule.id}`);
  };

  // The route change will be blocked if the state has changed AND user did not hit the create button
  const shouldBlock =
    !_.isEqual(copyOfOriginalRuleState, ruleState) && !isCreate;

  return (
    <>
      <UnloadPrompt when={shouldBlock} />
      <Box m={2} display="flex">
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => history.push(`/c/${accessId}/rules`)}
        >
          Back to Rules
        </Button>
        <Box flex="1" />
        {showValidationErrors && nextError !== null && (
          <Box display="flex" alignItems="center" px={2}>
            <Typography color="secondary">
              Error:{" "}
              {nextError.message ||
                "Some fields are missing or have invalid values."}
            </Typography>
          </Box>
        )}
        <Button
          variant="outlined"
          startIcon={<DoneIcon />}
          onClick={handleCreateRule}
          disabled={loading || (showValidationErrors && nextError !== null)}
        >
          Create
        </Button>
      </Box>

      <RuleEditor
        title={basedOnRule ? `Copy of ${basedOnRule.name}` : "Create rule"}
        products={products}
        existingNames={existingNames}
        state={ruleState}
        showValidationErrors={showValidationErrors}
        setState={setRuleState}
        investors={investors}
      />
    </>
  );
}
