import { unreachable, getErrorMessage } from "features/utils";
import { Map as IMap, OrderedMap as IOrderedMap } from "immutable";
import * as T from "types/engine-types";
import { importEnumTypeId, importEnumVariantId } from "../enums/import";
import { importObjectRef, ObjectDetailsByKey } from "../rules/import";
import toposort from "toposort";

export function importApplicationField(
  enumTypesByKey: IMap<string, T.EnumType>,
  applicationFieldsByKey: IMap<string, T.RawCreditApplicationFieldDefinition>,
  objectDetails: ObjectDetailsByKey,
  oldField: T.RawCreditApplicationFieldDefinition | null,
  field: T.RawCreditApplicationFieldDefinition,
): T.RawCreditApplicationFieldDefinition {
  const applicationFieldIdsByKey = applicationFieldsByKey.map((f) => f.id);

  if (field.disposition.kind === "native") {
    return {
      oldId: oldField?.id || null,
      id: field.id,
      disposition: {
        kind: "native",
        name: field.disposition.name,
        description: field.disposition.description,
        valueType: importFieldValueType(
          enumTypesByKey,
          field.disposition.valueType,
        ),
        conditions:
          field.disposition.conditions &&
          importFieldCondition(
            enumTypesByKey,
            applicationFieldIdsByKey,
            objectDetails,
            field.disposition.conditions,
          ),
      },
    };
  } else {
    return {
      oldId: oldField?.id || null,
      id: field.id,
      disposition: {
        kind: "inherited",
        nameAlias: field.disposition.nameAlias,
        descriptionAlias: field.disposition.descriptionAlias,
        includeConditions: field.disposition.includeConditions.map((id) => id),
        additionalConditions:
          field.disposition.additionalConditions &&
          importFieldCondition(
            enumTypesByKey,
            applicationFieldIdsByKey,
            objectDetails,
            field.disposition.additionalConditions,
          ),
      },
    };
  }
}

export function importProductField(
  enumTypesByKey: IMap<string, T.EnumType>,
  productFieldsByKey: IMap<string, T.RawProductFieldDefinition>,
  objectDetails: ObjectDetailsByKey,
  oldField: T.RawProductFieldDefinition | null,
  field: T.RawProductFieldDefinition,
): T.RawProductFieldDefinition {
  const productFieldIdsByKey = productFieldsByKey.map((f) => f.id);

  if (field.disposition.kind === "native") {
    return {
      oldId: oldField?.id || null,
      id: field.id,
      disposition: {
        kind: "native",
        name: field.disposition.name,
        description: field.disposition.description,
        valueType: importFieldValueType(
          enumTypesByKey,
          field.disposition.valueType,
        ),
        conditions:
          field.disposition.conditions &&
          importFieldCondition(
            enumTypesByKey,
            productFieldIdsByKey,
            objectDetails,
            field.disposition.conditions,
          ),
      },
    };
  } else {
    return {
      oldId: oldField?.id || null,
      id: field.id,
      disposition: {
        kind: "inherited",
        nameAlias: field.disposition.nameAlias,
        descriptionAlias: field.disposition.descriptionAlias,
        includeConditions: field.disposition.includeConditions.map((id) => id),
        additionalConditions:
          field.disposition.additionalConditions &&
          importFieldCondition(
            enumTypesByKey,
            productFieldIdsByKey,
            objectDetails,
            field.disposition.additionalConditions,
          ),
      },
    };
  }
}

export function importPipelineField(
  enumTypesByKey: IMap<string, T.EnumType>,
  pipelineFieldsByKey: IMap<string, T.RawPipelineFieldDefinition>,
  objectDetails: ObjectDetailsByKey,
  oldField: T.RawPipelineFieldDefinition | null,
  field: T.RawPipelineFieldDefinition,
): T.RawPipelineFieldDefinition {
  const pipelineFieldIdsByKey = pipelineFieldsByKey.map((f) => f.id);

  if (field.disposition.kind === "native") {
    return {
      oldId: oldField?.id || null,
      id: field.id,
      disposition: {
        kind: "native",
        name: field.disposition.name,
        description: field.disposition.description,
        valueType: importFieldValueType(
          enumTypesByKey,
          field.disposition.valueType,
        ),
        conditions:
          field.disposition.conditions &&
          importFieldCondition(
            enumTypesByKey,
            pipelineFieldIdsByKey,
            objectDetails,
            field.disposition.conditions,
          ),
      },
    };
  } else {
    return {
      oldId: oldField?.id || null,
      id: field.id,
      disposition: {
        kind: "inherited",
        nameAlias: field.disposition.nameAlias,
        descriptionAlias: field.disposition.descriptionAlias,
        includeConditions: field.disposition.includeConditions.map((id) => id),
        additionalConditions:
          field.disposition.additionalConditions &&
          importFieldCondition(
            enumTypesByKey,
            pipelineFieldIdsByKey,
            objectDetails,
            field.disposition.additionalConditions,
          ),
      },
    };
  }
}

// Application fields are expected to be sorted such that, if a field has a
// condition, the parent field shows up before the conditional field. This
// function tries to sort the fields in such a way where the end result both
// preserves any currently configured field order, but still preserves the
// correct sort order for any newly-imported fields. This is done using
// topological sorting. If sorting fails for some reason, it just falls back
// to using the fields in the original order from `mergedApplicationFields`.
export function reorderImportedApplicationFields(opts: {
  mergedApplicationFields: IOrderedMap<
    string,
    T.RawCreditApplicationFieldDefinition
  >;
  snapshotApplicationFields: readonly T.RawCreditApplicationFieldDefinition[];
  fileApplicationFields: T.RawCreditApplicationFieldDefinition[];
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>;
}): T.RawCreditApplicationFieldDefinition[] {
  const {
    mergedApplicationFields,
    snapshotApplicationFields,
    fileApplicationFields,
  } = opts;

  // Contains all the edges used for the topological sort
  const fieldKeyEdges: [string, string][] = [];

  // Add an edge for each existing application field. Given the fields appear
  // in the order A -> B -> C, this adds the edges [A, B] and [B, C], which
  // ensures the reordered result will preserve this field order
  for (let i = 0; i < snapshotApplicationFields.length - 1; ++i) {
    fieldKeyEdges.push([
      snapshotApplicationFields[i].id,
      snapshotApplicationFields[i + 1].id,
    ]);
  }

  // For each field that has a condition from the file, add an edge between
  // the field and the parent field. This ensures that newly-added fields
  // are inserted in the correct order, so their dependencies show up first
  for (const field of fileApplicationFields) {
    if (field.disposition.kind === "native") {
      for (const condition of field.disposition.conditions) {
        fieldKeyEdges.push([condition.parentFieldId, field.id]);
      }
    } else {
      // Add edges for inherited conditions
      for (const included of field.disposition.includeConditions) {
        // Find the included condition on the inherited field
        const includedCondition = opts.inheritableFields
          .get(field.id)
          ?.conditions?.find((c) => c.id === included.id);
        if (!includedCondition) continue;

        fieldKeyEdges.push([includedCondition.parentFieldId, field.id]);
      }
      // Add edges for any additional conditions
      for (const additionalCondition of field.disposition
        .additionalConditions) {
        fieldKeyEdges.push([additionalCondition.parentFieldId, field.id]);
      }
    }
  }

  // Try to topologically sort the fields. This may fail if there are conflicts
  // between the current field order and conditions for newly-imported fields
  let sortedFieldKeys: string[] | undefined;
  try {
    sortedFieldKeys = toposort(fieldKeyEdges);
  } catch (error: unknown) {
    newrelic.noticeError(getErrorMessage(error));
    console.warn("Error while trying to topologically sort edges", {
      fieldKeyEdges,
      error,
    });
  }

  if (sortedFieldKeys) {
    // If topological sorting was successful, return all fields based on their
    // topologically-sorted order
    return sortedFieldKeys.map((fieldKey) => {
      const field = mergedApplicationFields.get(fieldKey);
      if (!field) {
        throw new Error(`Could not find field with key '${fieldKey}'`);
      }

      return field;
    });
  } else {
    // If topological sorting fails, just return the fields in whatever order
    // they came from when importing the application fields
    return mergedApplicationFields.valueSeq().toArray();
  }
}

export function importFieldValueType(
  enumTypesByKey: IMap<string, T.EnumType>,
  valueType: T.FieldValueType,
): T.FieldValueType {
  switch (valueType.type) {
    case "enum":
      return {
        type: "enum" as const,
        enumTypeId: importEnumTypeId(enumTypesByKey, valueType.enumTypeId),
      };
    case "object-ref":
    case "string":
    case "header":
    case "number":
    case "duration":
    case "date":
      return valueType;
    default:
      return unreachable(valueType);
  }
}

function importFieldCondition(
  enumTypesByKey: IMap<string, T.EnumType>,
  previousFieldIdsByKey: IMap<string, T.FieldId>,
  objectDetails: ObjectDetailsByKey,
  conditions: T.FieldCondition[],
): T.FieldCondition[] {
  return conditions.map((condition) => {
    let toReturn;
    switch (condition.type) {
      case "parent-field-is-blank":
        toReturn = {
          type: "parent-field-is-blank" as const,
          id: condition.id,
          parentFieldId: importFieldId(
            previousFieldIdsByKey,
            condition.parentFieldId,
          ),
        };
        break;
      case "parent-field-is-not-blank":
        toReturn = {
          type: "parent-field-is-not-blank" as const,
          id: condition.id,
          parentFieldId: importFieldId(
            previousFieldIdsByKey,
            condition.parentFieldId,
          ),
        };
        break;
      case "parent-field-has-not-value":
        toReturn = {
          type: "parent-field-has-not-value" as const,
          id: condition.id,
          parentFieldId: importFieldId(
            previousFieldIdsByKey,
            condition.parentFieldId,
          ),
          value: importFieldValue(
            enumTypesByKey,
            objectDetails,
            condition.value,
          ),
        };
        break;
      case "parent-field-has-value":
        toReturn = {
          type: "parent-field-has-value" as const,
          id: condition.id,
          parentFieldId: importFieldId(
            previousFieldIdsByKey,
            condition.parentFieldId,
          ),
          value: importFieldValue(
            enumTypesByKey,
            objectDetails,
            condition.value,
          ),
        };
        break;
      case "parent-field-is-not-one-of":
        toReturn = {
          type: "parent-field-is-not-one-of" as const,
          id: condition.id,
          parentFieldId: importFieldId(
            previousFieldIdsByKey,
            condition.parentFieldId,
          ),
          values: condition.values.map((v) =>
            importFieldValue(enumTypesByKey, objectDetails, v),
          ),
        };
        break;
      case "parent-field-is-one-of":
        toReturn = {
          type: "parent-field-is-one-of" as const,
          id: condition.id,
          parentFieldId: importFieldId(
            previousFieldIdsByKey,
            condition.parentFieldId,
          ),
          values: condition.values.map((v) =>
            importFieldValue(enumTypesByKey, objectDetails, v),
          ),
        };
        break;
    }

    return toReturn;
  });
}

export function importFieldId(
  fieldIdsByKey: IMap<string, T.FieldId>,
  fieldId: T.FieldId,
): T.FieldId {
  const id = fieldIdsByKey.get(fieldId);

  if (!id) {
    throw new Error(
      "Could not resolve reference to field " + JSON.stringify(fieldId),
    );
  }

  return id;
}

export function importFieldValue(
  enumTypesByKey: IMap<string, T.EnumType>,
  objectDetails: ObjectDetailsByKey,
  value: T.FieldValue,
): T.FieldValue {
  switch (value.type) {
    case "enum":
      return {
        type: "enum" as const,
        enumTypeId: importEnumTypeId(enumTypesByKey, value.enumTypeId),
        variantId: importEnumVariantId(
          enumTypesByKey,
          value.enumTypeId,
          value.variantId,
        ),
      };
    case "object-ref":
      return {
        type: "object-ref",
        objectRef: importObjectRef(objectDetails, value.objectRef),
      };
    case "string":
    case "number":
    case "duration":
    case "date":
      return value;
    default:
      return unreachable(value);
  }
}

export function importFieldValueMapping(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  objectDetails: ObjectDetailsByKey,
  mapping: T.FieldValueMapping,
): T.FieldValueMapping {
  return {
    fieldId: importFieldId(allFieldIdsByKey, mapping.fieldId),
    value: importFieldValue(enumTypesByKey, objectDetails, mapping.value),
  };
}
