import { Map as IMap, OrderedMap as IOrderedMap } from "immutable";
import { useVirtual } from "react-virtual";
import React, { useCallback, useRef } from "react";
import { Box } from "@material-ui/core";
import * as Ta from "design/organisms/table";
import * as Api from "api";
import { Configuration } from "config";
import * as T from "types/engine-types";
import { useAsyncLoader } from "features/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  filteredSummaryProductsSelector,
  summarySelector,
} from "features/pricing-summaries";
import { loansSelector, setOpenProduct } from "features/loans";
import { ObjectDetails } from "features/objects";
import ProductResultDetailView from "../product-result-detail-view";
import ProductResultsListItem from "../product-results-list-item";
import StartMessage from "../start-message";
import NoProductsMessage from "../no-products-message";
import ProductResultsStatusBar from "../product-results-status-bar";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import {
  performanceEvents,
  postStartupMetric,
} from "features/utils/post-metric";

const ProductResults = React.memo(
  ({
    summary,
    requestTime,
    fieldValueMappings,
    rulesById,
    productsById,
    investors,
    config,
    objectDetails,
    stale,
  }: {
    summary: T.ExecutionSummary;
    requestTime: string;
    fieldValueMappings: T.FieldValueMapping[] | null;
    productsById: IMap<T.ProductId, T.DecoratedProductHeader>;
    rulesById: IMap<T.RuleId, T.DecoratedRuleHeader>;
    investors: T.DecoratedInvestorHeader[];
    investorsById: IMap<T.InvestorId, T.DecoratedInvestorHeader>;
    config: Configuration;
    objectDetails: ObjectDetails;
    stale: boolean;
  }) => {
    const dispatch = useDispatch();
    const parentRef = useRef<HTMLDivElement>(null);
    const summaryProducts = useSelector(filteredSummaryProductsSelector);
    const { openProduct } = useSelector(loansSelector);
    const resultsSummary = useSelector(summarySelector);
    const { searchTerm } = resultsSummary;
    const { myPricingProfile, myPricingProfiles } = useSelector(
      nonNullApplicationInitializationSelector,
    );
    const rowVirtualizer = useVirtual({
      size: summaryProducts?.length,
      parentRef,
      estimateSize: React.useCallback(() => 48, []),
    });
    const closeProduct = useCallback(() => {
      dispatch(setOpenProduct(null));
    }, [dispatch]);

    const [productResult, productResultLoading] = useAsyncLoader(async () => {
      if (
        !openProduct ||
        !fieldValueMappings ||
        !myPricingProfile?.id ||
        !myPricingProfiles?.length
      ) {
        return null;
      }

      const request: T.ProductExecutionRequest = {
        currentTime: requestTime,
        productId: openProduct,
        pricingProfileId:
          myPricingProfiles.length > 0 ? myPricingProfile.id : null,
        creditApplicationFields: fieldValueMappings,
        outputFieldsFilter: { type: "all" },
        publishedVersionId: null,
      };
      const response = await Api.executeProduct(request);

      return { request, response };
    }, [
      openProduct,
      fieldValueMappings,
      myPricingProfile,
      myPricingProfiles,
      requestTime,
    ]);
    postStartupMetric(performanceEvents.firstProductPaint);
    return (
      <Box
        className="product-results"
        flex="1"
        style={{
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
          overflowY: "auto",
          opacity: stale || (openProduct && productResultLoading) ? 0.3 : 1,
        }}
      >
        {openProduct && !productResult && <Box flex="1" />}
        {openProduct && productResult && (
          <>
            <ProductResultsStatusBar
              totals={summary.totals}
              onBack={closeProduct}
            />
            <Box flex="1" overflow="auto" bgcolor="white">
              <ProductResultDetailView
                investors={investors}
                product={productsById.get(openProduct)!}
                rulesById={rulesById}
                request={productResult.request}
                result={productResult.response}
                globalCalculatedFieldValuesById={
                  IOrderedMap<
                    never,
                    never
                  >() /* TODO remove this prop, they don't exist */
                }
                config={config}
                objectDetails={objectDetails}
              />
            </Box>
          </>
        )}

        {!openProduct && (
          <>
            <ProductResultsStatusBar totals={summary.totals} />

            {/* if no field values have been entered yet and no search input, we show this message (unless there's no products, then we show the No Products message) */}
            {summary.products.length > 0 &&
              !!searchTerm === false &&
              (!fieldValueMappings || fieldValueMappings.length === 0) && (
                <StartMessage />
              )}
            {summaryProducts.length !== 0 && !openProduct ? (
              <Ta.TableBodyWrapper ref={parentRef}>
                <Ta.TableBody
                  style={{ height: `${rowVirtualizer.totalSize}px` }}
                >
                  {rowVirtualizer.virtualItems.map((virtualRow) => (
                    <ProductResultsListItem
                      investors={investors}
                      requestTime={requestTime}
                      key={summaryProducts[virtualRow.index].productId}
                      result={summaryProducts[virtualRow.index]}
                      config={config}
                      objectDetails={objectDetails}
                      index={virtualRow.index}
                      size={virtualRow.size}
                      start={virtualRow.start}
                    />
                  ))}
                </Ta.TableBody>
              </Ta.TableBodyWrapper>
            ) : (
              <NoProductsMessage />
            )}
          </>
        )}
      </Box>
    );
  },
);

export default ProductResults;
