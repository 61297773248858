import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import WarningIcon from "@material-ui/icons/Warning";
import { Configuration } from "config";
import * as T from "types/engine-types";
import * as Fields from "features/fields";
import { ObjectDetails } from "features/objects";
import { usePriceScenarioTableCellStyles } from "pages/loans/styles";
import MiddotIcon from "../middot-icon";
import color from "design/subatomics/colors";
import { Text } from "design/atoms/typography";
import { useHistory, useLocation } from "react-router-dom";
import { localAccessId } from "features/access-id";

const SummaryPriceScenarioTableCell = React.memo(
  ({
    productId,
    isBestEx,
    scenarioSummary,
    adjustedPriceField,
    config,
    objectDetails,
  }: {
    productId: T.ProductId;
    isBestEx?: Boolean;
    scenarioSummary: T.PriceScenarioSummary | null;
    adjustedPriceField: T.BaseFieldDefinition;
    config: Configuration;
    objectDetails: ObjectDetails;
  }): JSX.Element => {
    const C = usePriceScenarioTableCellStyles();
    const horizontalBarChar = String.fromCharCode(8213);
    const history = useHistory();
    const location = useLocation();
    const accessId = localAccessId();

    if (!scenarioSummary) {
      return (
        <td className={C.cell + " " + C.noScenario}>{horizontalBarChar}</td>
      );
    }

    switch (scenarioSummary.status) {
      case "missing-configuration":
        throw new Error(
          "we shouldn't be displaying a table cell if missing configuration",
        );
      case "approved":
        return (
          <td
            className={C.cell + " " + C.approved}
            style={{
              border: `1px solid ${
                isBestEx
                  ? color({ color: "green", shade: 5 })
                  : color({ color: "lightBorder" })
              }`,
              color: color({ color: "green", shade: 5 }),
            }}
          >
            <Text
              onClick={() => {
                if (
                  scenarioSummary.adjustedRate &&
                  scenarioSummary.adjustedRateLockPeriod?.count
                ) {
                  history.push(
                    `/c/${accessId}/v2/loan-pricing/products/${productId}/${scenarioSummary.adjustedRate}/${scenarioSummary.adjustedRateLockPeriod?.count}${location.search}${location.hash}`,
                  );
                }
              }}
            >
              {Fields.fieldValueToString(
                config,
                objectDetails,
                adjustedPriceField.valueType,
                {
                  type: "number",
                  value: scenarioSummary.adjustedPrice!,
                },
              )}
            </Text>
          </td>
        );
      case "review-required":
        return (
          <td
            className={C.cell + " " + C.reviewRequired}
            style={{
              border: `1px solid ${
                isBestEx
                  ? color({ color: "green", shade: 5 })
                  : color({ color: "lightBorder" })
              }`,
              color: color({ color: "orange", shade: 5 }),
            }}
          >
            <Text
              onClick={() => {
                history.push(
                  `/c/${accessId}/v2/loan-pricing/products/${productId}${location.search}${location.hash}`,
                );
              }}
            >
              {Fields.fieldValueToString(
                config,
                objectDetails,
                adjustedPriceField.valueType,
                {
                  type: "number",
                  value: scenarioSummary.adjustedPrice!,
                },
              )}
            </Text>
          </td>
        );
      case "rejected":
        return (
          <td className={C.cell + " " + C.rejected}>
            <div
              onClick={() => {
                history.push(
                  `/c/${accessId}/v2/loan-pricing/products/${productId}${location.search}${location.hash}`,
                );
              }}
            >
              <ClearIcon />
            </div>
          </td>
        );
      case "available":
        return (
          <td className={C.cell + " " + C.missingData}>
            <div
              onClick={() => {
                history.push(
                  `/c/${accessId}/v2/loan-pricing/products/${productId}${location.search}${location.hash}`,
                );
              }}
            >
              <MiddotIcon />
            </div>
          </td>
        );
      case "error": {
        return (
          <td className={C.cell + " " + C.error}>
            <div
              onClick={() => {
                history.push(
                  `/c/${accessId}/v2/loan-pricing/products/${productId}${location.search}${location.hash}`,
                );
              }}
            >
              <WarningIcon />
            </div>
          </td>
        );
      }
    }
  },
);

export default SummaryPriceScenarioTableCell;
