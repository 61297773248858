import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import * as Api from "api";
import * as T from "types/engine-types";

const initialState: TargetTypesState = {
  status: { kind: "not-loaded" },
  targetTypes: null,
};

type TargetTypesStatus =
  | { kind: "not-loaded" }
  | { kind: "loading" }
  | { kind: "loaded" }
  | { kind: "error"; error: string };

type TargetTypesState = {
  status: TargetTypesStatus;
  targetTypes: { [key: string]: T.LsType } | null;
};

type TargetTypesByPurpose = { [key: string]: TargetTypesState };

export const createListTargetTypesThunk = (purpose: string) =>
  createAsyncThunk(`targetTypes/${purpose}`, async () => {
    return await Api.listTargetTypes(purpose);
  });

const TARGET_TYPE_PURPOSES: string[] = [
  "encompass-from-transaction-origin",
  "encompass-to-transaction-response",
];

const targetTypesSlice = createSlice({
  name: "TargetTypes",
  initialState: {} as TargetTypesByPurpose,
  extraReducers: (builder) => {
    for (const purpose of TARGET_TYPE_PURPOSES) {
      const getterThunk = createListTargetTypesThunk(purpose);

      builder.addCase(getterThunk.pending, (state) => {
        if (!state.hasOwnProperty(purpose))
          state[purpose] = { ...initialState };

        state[purpose].status = { kind: "loading" };
      });

      builder.addCase(getterThunk.rejected, (state) => {
        if (!state.hasOwnProperty(purpose))
          state[purpose] = { ...initialState };

        state[purpose].status = {
          kind: "error",
          error: "An error occurred while loading target types",
        };
      });

      builder.addCase(getterThunk.fulfilled, (state, { payload }) => {
        if (!state.hasOwnProperty(purpose))
          state[purpose] = { ...initialState };

        state[purpose] = {
          status: { kind: "loaded" },
          targetTypes: payload,
        };
      });
    }
  },
  reducers: {},
});

export default targetTypesSlice.reducer;

const createStateSelector = function (purpose: string) {
  return (state: { targetTypes: TargetTypesByPurpose }) => {
    if (!state.targetTypes.hasOwnProperty(purpose)) {
      return { ...initialState };
    } else {
      return state.targetTypes[purpose];
    }
  };
};

export const createTargetTypesStateSelector = (purpose: string) => {
  return createSelector(createStateSelector(purpose), (state) => state);
};
