import React, {
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  useCallback,
} from "react";
import { useVirtual, VirtualItem } from "react-virtual";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import * as T from "types/engine-types";
import {
  allUserVisiblePermissions,
  permissionsThatRequireX,
  permissionsThatXRequires,
  Permission,
} from "features/permissions";
import { Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Set as ISet } from "immutable";
import { unreachable } from "features/utils";

/**
 * the configuration-view permission is currently always present, and
 * needs to be included anytime we update the set of permissions for a role
 * price-a-loan-v1-view should also always be present until we're ready to start
 * forcing switch over and until GuardedRoute redirects somewhere else.
 */
export const alwaysPresentPermissions: T.PermissionKind[] = [
  "configuration-view",
  "price-a-loan-v1-view",
];

const SelectAllCheckbox = React.memo(function SelectAllCheckbox({
  chosenPerms,
  setChosenPerms,
  currentlyDisplayedPerms,
}: {
  chosenPerms: T.PermissionKind[];
  setChosenPerms: Dispatch<SetStateAction<T.PermissionKind[]>>;
  currentlyDisplayedPerms: T.PermissionKind[];
}) {
  const checked = allUserVisiblePermissions.every((p) =>
    chosenPerms.includes(p.kind),
  );

  const onChange = useCallback(
    (e: unknown, checked: boolean) => {
      handlePermissionChanges(currentlyDisplayedPerms, checked, setChosenPerms);
    },
    [setChosenPerms, currentlyDisplayedPerms],
  );

  return (
    <Checkbox
      checked={checked}
      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
      checkedIcon={<CheckBoxIcon fontSize="small" />}
      onChange={onChange}
    />
  );
});

const PermissionTableRow = React.memo(function EditablePermissionTableRow({
  permission,
  virtualRow,
  checked,
  mode,
  onChange,
}: {
  permission: Permission;
  virtualRow: VirtualItem;
  checked: boolean;
  mode: "view" | "edit";
  onChange?: (permissionKind: T.PermissionKind, checked: boolean) => void;
}) {
  const paddingLeft = 8 + 21 * (permission.indentation || 0);

  const onCheckboxChange = useCallback(
    (e: unknown, checked: boolean) => {
      onChange && onChange(permission.kind, checked);
    },
    [onChange, permission.kind],
  );

  return (
    <TableRow
      style={{
        height: `${virtualRow.size}px`,
        transform: `translateY(${virtualRow.start}px)`,
      }}
    >
      {mode === "edit" && (
        <TableCell style={{ flexBasis: "54px" }}>
          <Checkbox
            checked={checked}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            onChange={onCheckboxChange}
          />
        </TableCell>
      )}

      <TableCell
        style={{
          flexBasis: `calc(100% - ${mode === "edit" ? 54 : 0}px)`,
          paddingLeft,
        }}
      >
        {permission.name}
      </TableCell>
    </TableRow>
  );
});

export default React.memo(function PermissionsList({
  chosenPerms,
  setChosenPerms,
  mode,
}: {
  chosenPerms: T.PermissionKind[];
  setChosenPerms: Dispatch<SetStateAction<T.PermissionKind[]>>;
  mode: "view" | "edit";
}) {
  const parentRef = useRef<HTMLDivElement>(null);

  const [searchTerm, setSearchTerm] = useState<string>("");

  /**
   * list of all the fields that should be shown when the search box is empty.
   * when editing, this is all permissions, but when viewing it only shows the permissions
   * that the role has.
   * */
  const permsToShowUnfiltered = useMemo(() => {
    switch (mode) {
      case "view":
        return allUserVisiblePermissions.filter((p) =>
          chosenPerms.includes(p.kind),
        );
      case "edit":
        return allUserVisiblePermissions;
      default:
        return unreachable(mode);
    }
  }, [chosenPerms, mode]);

  /**
   * the actual permissions that should be displayed, filtered
   * by the search query if present. (note that this will be filtered further using
   * react-virtual below, but these are the ones that are "logically" being displayed,
   * they just won't be rendered if they're out of the scroll view)
   * */
  const filteredPermsToShow = useMemo(() => {
    if (!searchTerm) {
      return permsToShowUnfiltered;
    } else {
      return permsToShowUnfiltered.filter((p) =>
        p.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }
  }, [permsToShowUnfiltered, searchTerm]);

  const filteredPermKindsToShow = useMemo(
    () => filteredPermsToShow.map((p) => p.kind),
    [filteredPermsToShow],
  );

  /**
   * number of permissions that are checked for the current role
   * (whether visible under the current search term or not)
   * */
  const numCheckedPerms = useMemo(() => {
    // the count we show is limited to permissions that are user-visible
    // i.e. we don't count anything that hasn't been made visible in the UI yet
    return ISet(chosenPerms).intersect(
      allUserVisiblePermissions.map((p) => p.kind),
    ).size;
  }, [chosenPerms]);

  const onPermissionChange = useCallback(
    (permissionKind: T.PermissionKind, checked: boolean) => {
      handlePermissionChanges([permissionKind], checked, setChosenPerms);
    },
    [setChosenPerms],
  );

  const rowVirtualizer = useVirtual({
    size: filteredPermsToShow.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, []),
  });

  /** permissions that are actually displayed with react-virtual */
  const virtualPermissions = rowVirtualizer.virtualItems.map((virtualRow) => ({
    virtualRow,
    permission: filteredPermsToShow[virtualRow.index],
  }));

  const virtualTableRows = virtualPermissions.map(
    ({ permission, virtualRow }) => (
      <PermissionTableRow
        permission={permission}
        virtualRow={virtualRow}
        checked={chosenPerms.includes(permission.kind)}
        mode={mode}
        onChange={onPermissionChange}
        key={permission.kind}
      />
    ),
  );

  return (
    <Table
      style={{ flex: "1 0 24%", borderRight: "1px solid rgba(0,0,0,.15)" }}
    >
      <TableActions style={{ padding: "8px" }}>
        <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
          <SearchInput
            label="permissions"
            requireDispatch={false}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <ResultsCount>
          {filteredPermsToShow.length} <VisibilityIcon />
          <span className="separator">|</span>
          {numCheckedPerms} <CheckIcon />
          <span className="separator">|</span>
          {allUserVisiblePermissions.length} Total
        </ResultsCount>
      </TableActions>

      <TableHeader>
        {mode === "edit" && (
          <TableHeaderCell style={{ flexBasis: "50px" }}>
            <SelectAllCheckbox
              chosenPerms={chosenPerms}
              setChosenPerms={setChosenPerms}
              currentlyDisplayedPerms={filteredPermKindsToShow}
            />
          </TableHeaderCell>
        )}

        <TableHeaderCell
          style={{ flexBasis: `calc(100% - ${mode === "edit" ? 50 : 0}px)` }}
        >
          Page Level Permissions
        </TableHeaderCell>
      </TableHeader>

      {virtualTableRows.length ? (
        <TableBodyWrapper ref={parentRef}>
          <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
            {virtualTableRows}
          </TableBody>
        </TableBodyWrapper>
      ) : (
        <p style={{ marginTop: "14px", textAlign: "center", opacity: 0.65 }}>
          No additional permissions have been granted for this role.
        </p>
      )}
    </Table>
  );
});

/**
 * updates the current state of checked permission when a permission (or in the
 * case of the select-all checkbox, a set of permissions) is checked or unchecked.
 * This takes into account the dependencies between different permissions,
 * so e.g. if you uncheck a permission it will make sure that anything that depends on it
 * is unchecked to.
 */
function handlePermissionChanges(
  permissionKinds: T.PermissionKind[],
  checked: boolean,
  setPerms: Dispatch<SetStateAction<T.PermissionKind[]>>,
): void {
  setPerms((oldPerms) => {
    if (checked) {
      const dependenciesToAdd = permissionKinds.flatMap((p) =>
        permissionsThatXRequires(p),
      );
      return ISet(oldPerms)
        .union(permissionKinds)
        .union(dependenciesToAdd)
        .union(alwaysPresentPermissions)
        .toArray();
    } else {
      const dependentsToRemove = permissionKinds.flatMap((p) =>
        permissionsThatRequireX(p),
      );
      return ISet(oldPerms)
        .subtract(permissionKinds)
        .subtract(dependentsToRemove)
        .union(alwaysPresentPermissions)
        .toArray();
    }
  });
}
