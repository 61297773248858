import React from "react";
import { useSelector } from "react-redux";
import ResultsCount from "../results-count";
import {
  summarySelector,
  filteredSummaryProductsSelector,
} from "features/pricing-summaries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import whitespace from "design/subatomics/whitespace";

export default React.memo(
  ({ className, showCounts }: { className?: string; showCounts: boolean }) => {
    const summaryProducts = useSelector(filteredSummaryProductsSelector);
    const summaryState = useSelector(summarySelector);

    if (!summaryState.summary) {
      return <></>;
    }

    const {
      approved: approves,
      available: missingData,
      rejected: rejects,
      error: errors,
    } = summaryState.summary.totals;

    return (
      <ResultsCount className={className ? className : ""}>
        {approves > 0 && showCounts && (
          <span
            data-selector="approved"
            style={{ marginRight: whitespace(), marginLeft: whitespace() }}
          >
            <strong>{approves} Approved</strong>
          </span>
        )}

        {missingData > 0 && showCounts && (
          <span
            data-selector="available-products"
            style={{
              marginRight: whitespace("less"),
              marginLeft: whitespace("less"),
            }}
          >
            {missingData} Data Required
          </span>
        )}

        {rejects > 0 && showCounts && (
          <span
            style={{
              marginRight: whitespace("less"),
              marginLeft: whitespace("less"),
            }}
          >
            {rejects} Rejected
          </span>
        )}

        {errors > 0 && showCounts && (
          <span
            style={{
              marginRight: whitespace("less"),
              marginLeft: whitespace("less"),
            }}
          >
            {errors} Error
          </span>
        )}

        {showCounts && (
          <span
            style={{
              marginLeft: whitespace("most"),
            }}
          />
        )}

        <span style={{ whiteSpace: "nowrap" }}>
          <span>
            {summaryProducts?.length} <FontAwesomeIcon icon={faEye} />{" "}
          </span>

          <span
            style={{
              marginLeft: whitespace(),
            }}
          />

          <span>
            {summaryState.summary?.products.length - summaryProducts?.length}{" "}
            <FontAwesomeIcon icon={faEyeSlash} />{" "}
          </span>
        </span>
      </ResultsCount>
    );
  },
);
