import { Map as IMap } from "immutable";
import * as T from "types/engine-types";

export function importEnumType(
  oldEnumType: T.RawEnumType | null,
  enumType: T.RawEnumType,
): T.RawEnumType {
  if (
    !!oldEnumType &&
    oldEnumType?.disposition.kind !== enumType.disposition.kind
  ) {
    throw new Error("Cannot merge native and inherited enums together.");
  }

  if (enumType.disposition.kind === "native") {
    const oldDisposition = oldEnumType?.disposition || null;
    const disposition = importNativeEnumDisposition(
      oldDisposition && (oldDisposition as T.NativeEnumDisposition),
      enumType.disposition,
    );

    return {
      oldId: oldEnumType?.id || null,
      id: enumType.id,
      disposition: {
        ...disposition,
        kind: "native",
      },
    };
  } else {
    const disposition = importInheritedEnumDisposition(enumType.disposition);

    return {
      oldId: oldEnumType?.id || null,
      id: enumType.id,
      disposition: {
        ...disposition,
        kind: "inherited",
      },
    };
  }
}

function importNativeEnumDisposition(
  oldEnumDisposition: T.NativeEnumDisposition | null,
  enumDisposition: T.NativeEnumDisposition,
): T.NativeEnumDisposition {
  const oldVariantsByKey = IMap(
    oldEnumDisposition ? oldEnumDisposition.variants.map((v) => [v.id, v]) : [],
  );

  return {
    name: enumDisposition.name,
    variants: enumDisposition.variants.map((variant) =>
      importEnumVariant(oldVariantsByKey.get(variant.id) || null, variant),
    ),
  };
}

function importInheritedEnumDisposition(
  enumDisposition: T.InheritedEnumDisposition,
): T.InheritedEnumDisposition {
  return {
    nameAlias: enumDisposition.nameAlias,
    excludeVariants: enumDisposition.excludeVariants.map((id) => id),
    includeVariants: enumDisposition.includeVariants.map((v) => ({
      id: v.id,
      nameAlias: v.nameAlias,
    })),
  };
}

function importEnumVariant(
  oldVariant: T.EnumVariant | null,
  variant: T.EnumVariant,
): T.EnumVariant {
  return {
    oldId: oldVariant?.id || null,
    id: variant.id,
    name: variant.name,
  };
}

export function getRawEnumTypeKey(enumType: T.RawEnumType): string {
  return enumType.id;
}

export function importEnumVariantValue(
  enumTypesByKey: IMap<string, T.EnumType>,
  value: T.EnumVariantValue,
): T.EnumVariantValue {
  return {
    enumTypeId: importEnumTypeId(enumTypesByKey, value.enumTypeId),
    variantId: importEnumVariantId(
      enumTypesByKey,
      value.enumTypeId,
      value.variantId,
    ),
  };
}

export function importEnumTypeId(
  enumTypesByKey: IMap<string, T.EnumType>,
  enumTypeId: T.EnumTypeId,
): T.EnumTypeId {
  const enumType = enumTypesByKey.get(enumTypeId);

  if (!enumType) {
    throw new Error(
      "Could not resolve reference to enumeration " +
        JSON.stringify(enumTypeId),
    );
  }

  return enumType.id;
}

export function importEnumVariantId(
  enumTypesByKey: IMap<string, T.EnumType>,
  enumTypeId: T.EnumTypeId,
  enumVariantId: T.EnumVariantId,
): T.EnumVariantId {
  const enumType = enumTypesByKey.get(enumTypeId);

  if (!enumType) {
    throw new Error(
      "Could not resolve reference to enumeration " +
        JSON.stringify(enumTypeId),
    );
  }

  const variant = enumType.variants.find((v) => v.id === enumVariantId);

  if (!variant) {
    throw new Error(
      "Could not resolve reference to enum variant " +
        JSON.stringify(enumVariantId),
    );
  }

  return variant.id;
}
