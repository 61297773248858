import React, { useEffect, useRef } from "react";
import { useVirtual } from "react-virtual";
import { useHistory } from "react-router-dom";
import * as T from "types/engine-types";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import { filteredProductsSelector, getProducts } from "features/products";
import {
  filteredRateSheetsSelector,
  getRateSheets,
} from "features/rate-sheets";
import {
  investorsSelector,
  filteredInvestorsSelector,
  getInvestors,
  setSearchTerm,
  setSort,
} from "features/investors";
import { usePermissions } from "features/roles";
import { useSelector, useDispatch } from "react-redux";
import { localAccessId } from "features/access-id";

export default function InvestorsPage() {
  const parentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { searchTerm, sortField } = useSelector(investorsSelector);
  const investorsState = useSelector(investorsSelector);
  const investors = useSelector(filteredInvestorsSelector);
  const products = useSelector(filteredProductsSelector);
  const rateSheets = useSelector(filteredRateSheetsSelector);
  const hasPermission = usePermissions();
  const accessId = localAccessId();
  useEffect(() => {
    if (products.length === 0) dispatch(getProducts());
  }, [dispatch, products]);

  useEffect(() => {
    if (investors.length === 0) dispatch(getInvestors());
  }, [dispatch, investors.length]);

  useEffect(() => {
    if (rateSheets.length === 0) dispatch(getRateSheets());
  }, [dispatch, rateSheets.length]);

  const rowVirtualizer = useVirtual({
    size: investors?.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, []),
  });

  const onRowClick = React.useCallback(
    (event: unknown, investor: T.DecoratedInvestorHeader | undefined) => {
      if (investor) {
        history.push(`/c/${accessId}/investors/${investor.id}`);
      }
    },
    [history, accessId],
  );

  return (
    <Table>
      <TableActions>
        <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
          <SearchInput
            label="investors"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>

        <ResultsCount>
          <span className="visible">
            {investors.length} <VisibilityIcon />
          </span>
          <span className="separator">|</span>
          <span className="total">{investorsState.investors.length} Total</span>
        </ResultsCount>
        <Button
          disabled={!hasPermission("investors-create")}
          className="add-investor"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => history.push(`/c/${accessId}/create-investor`)}
        >
          Add Investor
        </Button>
      </TableActions>

      <TableHeader>
        <TableHeaderCell
          style={{ flexBasis: "40%" }}
          onClick={() => dispatch(setSort("name"))}
        >
          Name{" "}
          {sortField === "name" && (
            <SwapVertIcon
              style={{ color: sortField === "name" ? "#90d18c" : "inherit" }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{ flexBasis: "15%" }}
          onClick={() => dispatch(setSort("code"))}
        >
          Code{" "}
          {sortField === "code" && (
            <SwapVertIcon
              style={{ color: sortField === "code" ? "#90d18c" : "inherit" }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{ flexBasis: "15%", textAlign: "right" }}
          onClick={() => dispatch(setSort("products"))}
        >
          Products{" "}
          {sortField === "products" && (
            <SwapVertIcon
              style={{
                color: sortField === "products" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{ flexBasis: "15%", textAlign: "right" }}
          onClick={() => dispatch(setSort("rateSheets"))}
        >
          Rate Sheets{" "}
          {sortField === "rateSheets" && (
            <SwapVertIcon
              style={{
                color: sortField === "rateSheets" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{ flexBasis: "15%", textAlign: "center" }}
          onClick={() => dispatch(setSort("isPricingEnabled"))}
        >
          Pricing Enabled{" "}
          {sortField === "isPricingEnabled" && (
            <SwapVertIcon
              style={{
                color: sortField === "isPricingEnabled" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>
      </TableHeader>

      {investors && (
        <TableBodyWrapper ref={parentRef}>
          <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
            {rowVirtualizer.virtualItems.map((virtualRow) => (
              <TableRow
                key={virtualRow.index}
                onClick={(e) => onRowClick(e, investors[virtualRow.index])}
                style={{
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <TableCell style={{ flexBasis: "40%" }}>
                  {investors[virtualRow.index].name}
                </TableCell>
                <TableCell style={{ flexBasis: "15%" }}>
                  {investors[virtualRow.index].code}
                </TableCell>
                <TableCell style={{ flexBasis: "15%", textAlign: "right" }}>
                  {investors[virtualRow.index].products?.length}
                </TableCell>
                <TableCell style={{ flexBasis: "15%", textAlign: "right" }}>
                  {investors[virtualRow.index].rateSheets?.length}
                </TableCell>
                <TableCell style={{ flexBasis: "15%", textAlign: "center" }}>
                  {investors[virtualRow.index].isPricingEnabled ? (
                    <CheckIcon
                      className="pricing-enabled"
                      style={{ fill: "#90d18c" }}
                    />
                  ) : (
                    <CloseIcon
                      className="pricing-disabled"
                      style={{ fill: "red" }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableBodyWrapper>
      )}
    </Table>
  );
}
