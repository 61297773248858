import React from "react";
import { Box } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const NoProductsMessage = React.memo(() => {
  return (
    <Box
      px={8}
      py={6}
      color="info.main"
      bgcolor="white"
      display="flex"
      alignItems="center"
    >
      <InfoIcon />
      <Box pl={1}>
        <strong>No Products available.</strong> &nbsp;Please check your filters
        or reach out to your administrator for assistance.
      </Box>
    </Box>
  );
});

export default NoProductsMessage;
