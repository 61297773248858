import { Configuration } from "config";
import * as T from "types/generated-types";

export function isStageAfterAllEligibilityStages(
  stageId: T.ExecutionStageId,
  config: Configuration,
): boolean {
  const index = config.stages.findIndex((s) => s.id === stageId);

  if (index === -1) {
    throw new Error(`unknown stage ID ${JSON.stringify(stageId)}`);
  }

  return !config.stages
    .slice(index)
    .some((s) => s.kind === "run-rules" && s.actionTypes.includes("reject"));
}

export function isStageBefore(
  config: Configuration,
  a: T.ExecutionStageId,
  b: T.ExecutionStageId,
): boolean {
  const indexA = config.stages.findIndex((s) => s.id === a);
  const indexB = config.stages.findIndex((s) => s.id === b);

  if (indexA === -1) {
    throw new Error("unknown stage ID " + JSON.stringify(a));
  }

  if (indexB === -1) {
    throw new Error("unknown stage ID " + JSON.stringify(b));
  }

  return indexA < indexB;
}

type CalcStagesByScope = Readonly<{
  global: readonly T.ExecutionStage.Calculations[];
  product: readonly T.ExecutionStage.Calculations[];
  priceScenario: readonly T.ExecutionStage.Calculations[];
}>;

export function getCalcStagesByScope(config: Configuration): CalcStagesByScope {
  const productSplitStageIndex = config.stages.findIndex(
    (stage) => stage.kind === "split-on-product",
  );
  const priceScenarioSplitStageIndex = config.stages.findIndex(
    (stage) => stage.kind === "split-on-rate-sheet",
  );

  return {
    global: config.stages.filter(
      (stage, stageIndex): stage is T.ExecutionStage.Calculations =>
        stage.kind === "calculations" && stageIndex < productSplitStageIndex,
    ),
    product: config.stages.filter(
      (stage, stageIndex): stage is T.ExecutionStage.Calculations =>
        stage.kind === "calculations" &&
        stageIndex > productSplitStageIndex &&
        stageIndex < priceScenarioSplitStageIndex,
    ),
    priceScenario: config.stages.filter(
      (stage, stageIndex): stage is T.ExecutionStage.Calculations =>
        stage.kind === "calculations" &&
        stageIndex > priceScenarioSplitStageIndex,
    ),
  };
}
