import { Set as ISet } from "immutable";
import React, { useCallback, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  InputAdornment,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((t) =>
  createStyles({
    container: {
      width: "100%",
      maxWidth: 300,
    },
    componentContainer: {},
    label: {
      margin: t.spacing(1, 0, 0),

      borderBottom: "none",
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderRadius: "4px 4px 0 0",
      borderStyle: "solid",
      borderWidth: 1,

      padding: "12px 16px",

      color: "#888",
    },
    labelSecondary: {
      float: "right",
      paddingLeft: 8,
    },
    searchField: {
      width: "100%",

      borderColor: "rgba(0, 0, 0, 0.23)",
      borderStyle: "solid",
      borderWidth: 1,

      padding: "6px 12px",

      "&:hover": {
        borderColor: t.palette.text.primary,
      },

      "&:focus-within": {
        borderColor: t.palette.primary.main,
        borderWidth: 2,

        padding: "5px 11px",
      },
    },
    optionList: {
      overflow: "auto",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderTop: "none",
      height: 250,
      padding: 0,
    },
    footer: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderTop: "none",
      borderRadius: "0 0 4px 4px",
      height: 38,
      padding: "0 4px",
    },
    footerButton: {
      color: "#888",
    },
    footerMessage: {
      padding: t.spacing(0, 1),
      color: "#888",
    },
    emptyMessage: {
      padding: t.spacing(4, 0),
      color: "#888",
      textAlign: "center",
    },
  }),
);

type Props<T extends string | number> = {
  label: string;
  className?: string;
  noItemsMessage: string;
  noResultsMessage: string;
  width?: string;
  options: T[];
  selected: ISet<T>;
  getOptionLabel: (option: T) => string;
  onChange: (selectedOptions: ISet<T>) => void;
};

function SearchableMultiSelectList<T extends string | number>({
  label,
  className,
  noItemsMessage,
  noResultsMessage,
  width,
  options,
  selected,
  getOptionLabel,
  onChange,
}: Props<T>) {
  const classes = useStyles();

  const [searchText, setSearchText] = useState("");

  const [searchFilter, isSearchFiltering] = createSearchFilter(searchText);

  const visibleOptions = options.filter((o) =>
    matchesFilter(getOptionLabel(o), searchFilter),
  );

  const selectAll = useCallback(() => {
    onChange(ISet(selected.concat(visibleOptions)));
  }, [onChange, visibleOptions, selected]);

  const clearAll = useCallback(() => {
    onChange(selected.filter((s) => !visibleOptions.includes(s)));
  }, [onChange, visibleOptions, selected]);

  return (
    <Box
      className={`${classes.container} ${className || ""}`}
      style={{ maxWidth: width ? width : 300 }}
    >
      <Typography className={classes.label}>
        {label}
        <span className={classes.labelSecondary}>
          {selected.size}/{options.length}
        </span>
      </Typography>
      <Box className={classes.componentContainer}>
        <InputBase
          className={classes.searchField}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
            </InputAdornment>
          }
          placeholder="Search..."
          onChange={(e) => setSearchText(e.target.value)}
        />
        <List className={classes.optionList}>
          {options.length === 0 && (
            <Typography className={classes.emptyMessage}>
              {noItemsMessage}
            </Typography>
          )}
          {options.length > 0 && visibleOptions.length === 0 && (
            <Typography className={classes.emptyMessage}>
              {noResultsMessage}
            </Typography>
          )}
          {visibleOptions.map((option) => {
            const isSelected = selected.has(option);
            const optionLabel = getOptionLabel(option);

            return (
              <ListItem
                key={option}
                dense
                button
                onClick={() => {
                  if (isSelected) {
                    onChange(selected.delete(option));
                  } else {
                    onChange(selected.add(option));
                  }
                }}
              >
                <ListItemIcon>
                  {isSelected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </ListItemIcon>
                <ListItemText primary={optionLabel}></ListItemText>
              </ListItem>
            );
          })}
        </List>

        <Box className={classes.footer} display="flex" alignItems="center">
          <>
            <Button className={classes.footerButton} onClick={clearAll}>
              {options.length === visibleOptions.length ? (
                <>Clear All</>
              ) : (
                <>Clear Visible</>
              )}
            </Button>
            {isSearchFiltering ? (
              <Box flex="1" style={{ textAlign: "center" }}>
                <Typography className={classes.footerMessage}>
                  {visibleOptions.length === 1
                    ? `1 search result`
                    : `${visibleOptions.length} search results`}
                </Typography>
              </Box>
            ) : (
              <Box flex="1" />
            )}
            <Button className={classes.footerButton} onClick={selectAll}>
              {options.length === visibleOptions.length ? (
                <>Select All</>
              ) : (
                <>Select Visible</>
              )}
            </Button>
          </>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(
  SearchableMultiSelectList,
) as typeof SearchableMultiSelectList;

type SearchFilter = string[];

function createSearchFilter(searchText: string): [SearchFilter, boolean] {
  const filter = searchText.trim().toLowerCase().split(" ");
  const isFiltering = !!searchText.trim();
  return [filter, isFiltering];
}

function matchesFilter(optionLabel: string, filter: SearchFilter): boolean {
  const optionLabelLowerCase = optionLabel.toLowerCase();
  return filter.every((part) => optionLabelLowerCase.includes(part));
}
