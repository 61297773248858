import React, { useState, useEffect } from "react";
import { Button, TextField, Menu, MenuItem, Box } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import * as T from "types/engine-types";
import { useDispatch, useSelector } from "react-redux";
import {
  loansSelector,
  saveScenario,
  loadScenario,
  deleteScenario,
  loadScenarios,
  promoteScenarioToClientScope,
  demoteScenarioToUserScope,
} from "features/loans";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import moment from "moment";
import { usePermissions } from "features/roles";
import { useFieldsColumnStyles } from "../../styles";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

const ApplicationScenariosMenu = React.memo(
  ({
    fieldValueMappings,
    clearAllFields,
  }: {
    fieldValueMappings: T.FieldValueMapping[] | null;
    clearAllFields: () => void;
  }) => {
    const C = useFieldsColumnStyles();
    const hasPermission = usePermissions();
    const dispatch = useDispatch();
    const { user } = useSelector(nonNullApplicationInitializationSelector);
    const { scenarios } = useSelector(loansSelector);

    useEffect(() => {
      if (scenarios.clientScoped === null || scenarios.userScoped === null) {
        dispatch(loadScenarios());
      }
    }, [dispatch, scenarios]);

    const [anchorEl, setAnchorEl] = useState<
      (EventTarget & HTMLButtonElement) | null
    >(null);
    const [scenarioName, setScenarioName] = useState<string>("");
    const [saveScenarioExpanded, setSaveScenarioExpanded] = useState(false);

    if (!hasPermission("application-scenarios-view")) {
      // completely hide this menu
      return null;
    }

    const canSaveScenarios =
      hasPermission("application-scenarios-modify-user-scoped") ||
      hasPermission("application-scenarios-modify-client-scoped");

    return (
      <div
        className="scenarios-wrapper"
        style={{
          width: "100%",
          zIndex: 99,
          padding: "8px 16px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        {saveScenarioExpanded && (
          <>
            <TextField
              value={scenarioName}
              autoFocus={true}
              autoComplete="never"
              name="scenarioName"
              label="Scenario Name"
              InputLabelProps={{ shrink: true }}
              fullWidth={true}
              placeholder="Name this scenario."
              onChange={(e) => {
                setScenarioName(e?.currentTarget?.value || "");
              }}
              onKeyPress={(e) => {
                if (e.key !== "Enter") return;
                if (!scenarioName) return;
                dispatch(
                  saveScenario({
                    scope: "user",
                    displayName: scenarioName,
                    fields: fieldValueMappings || [],
                  }),
                );
                setSaveScenarioExpanded(false);
                setScenarioName("");
              }}
            />
            <div style={{ display: "flex", marginTop: "8px" }}>
              <Button
                style={{
                  fontSize: "12px",
                  marginRight: "4px",
                  flex: "1 1 auto",
                  padding: 0,
                }}
                onClick={() => {
                  setSaveScenarioExpanded(false);
                  setScenarioName("");
                }}
                variant="outlined"
              >
                Cancel
              </Button>

              <Button
                data-selector="final-save-scenario"
                style={{
                  fontSize: "12px",
                  marginLeft: "4px",
                  flex: "1 1 auto",
                  padding: 0,
                }}
                onClick={() => {
                  dispatch(
                    saveScenario({
                      scope: "user",
                      displayName: scenarioName,
                      fields: fieldValueMappings || [],
                    }),
                  );
                  setSaveScenarioExpanded(false);
                  setScenarioName("");
                }}
                variant="outlined"
                disabled={!fieldValueMappings?.length}
              >
                Save Scenario
              </Button>
            </div>
          </>
        )}

        {!saveScenarioExpanded && (
          <>
            <div style={{ display: "flex" }}>
              <Button
                data-selector="load-scenario-button"
                style={{
                  fontSize: "12px",
                  marginRight: "4px",
                  flex: "1 1 auto",
                  padding: 0,
                }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                variant="outlined"
              >
                Load Scenarios
              </Button>
              {canSaveScenarios && (
                <Button
                  data-selector="save-scenario-button"
                  style={{
                    fontSize: "12px",
                    marginLeft: "4px",
                    flex: "1 1 auto",
                    padding: 0,
                  }}
                  onClick={() => setSaveScenarioExpanded(true)}
                  variant="outlined"
                  disabled={!fieldValueMappings?.length}
                >
                  Save Scenario
                </Button>
              )}
            </div>
            {scenarios.error && (
              <Box color="error.main" marginTop="10px">
                <Box pl={1}>{scenarios.error}</Box>
              </Box>
            )}
          </>
        )}

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
        >
          {scenarios?.userScoped?.length === 0 &&
            scenarios?.clientScoped?.length === 0 && (
              <MenuItem>No scenarios have been saved yet.</MenuItem>
            )}
          {scenarios?.clientScoped && scenarios?.clientScoped?.length > 0 && (
            <div className={C.scenariosMenuDivider}>Popular scenarios</div>
          )}
          {scenarios.clientScoped?.map((scenario, i) => (
            <MenuItem style={{ position: "relative" }} key={i}>
              <div
                data-selector={
                  "scenario-popup-client-" +
                  String(scenario.displayName.match(/[^\s\\]/g)?.join(""))
                }
                style={{ minWidth: "500px" }}
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(loadScenario(scenario.fields));
                }}
              >
                {scenario.displayName}
              </div>
              {hasPermission("application-scenarios-modify-client-scoped") ? (
                <>
                  <DeleteIcon
                    data-selector={
                      "scenario-delete-" +
                      String(scenario.displayName.match(/[^\s\\]/g)?.join(""))
                    }
                    titleAccess="Delete this scenario"
                    style={{ position: "absolute", right: "8px" }}
                    onClick={() => dispatch(deleteScenario(scenario))}
                  />
                  <ArrowDownward
                    data-selector={
                      "scenario-unpublish-" +
                      String(scenario.displayName.match(/[^\s\\]/g)?.join(""))
                    }
                    titleAccess="Unpublish this scenario so that only its creator can see it"
                    style={{ position: "absolute", right: "32px" }}
                    onClick={() =>
                      dispatch(demoteScenarioToUserScope(scenario))
                    }
                  />
                </>
              ) : null}
            </MenuItem>
          ))}
          {scenarios?.userScoped && scenarios?.userScoped?.length > 0 && (
            <div className={C.scenariosMenuDivider}>Custom Scenarios</div>
          )}
          {scenarios?.userScoped?.map((scenario, i) => (
            <MenuItem style={{ position: "relative" }} key={i}>
              <div
                data-selector={
                  "scenario-popup-user-" +
                  String(scenario.displayName.match(/[^\s\\]/g)?.join(""))
                }
                style={{ minWidth: "500px" }}
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(loadScenario(scenario.fields));
                }}
              >
                {scenario.displayName}{" "}
                <span className={C.scenarioDate}>
                  {moment(scenario.createdAt).format("MMM D YYYY, h:mm A")}
                </span>
              </div>
              {scenario.ownedBy === user.id ? (
                <>
                  <DeleteIcon
                    data-selector={
                      "scenario-delete-" +
                      String(scenario.displayName.match(/[^\s\\]/g)?.join(""))
                    }
                    titleAccess="Delete this scenario"
                    style={{ position: "absolute", right: "8px" }}
                    onClick={() => dispatch(deleteScenario(scenario))}
                  />
                  {hasPermission(
                    "application-scenarios-modify-client-scoped",
                  ) ? (
                    <ArrowUpward
                      data-selector={
                        "scenario-publish-" +
                        String(scenario.displayName.match(/[^\s\\]/g)?.join(""))
                      }
                      titleAccess="Publish this scenario so that all users can see it"
                      style={{ position: "absolute", right: "32px" }}
                      onClick={() =>
                        dispatch(promoteScenarioToClientScope(scenario))
                      }
                    />
                  ) : null}
                </>
              ) : null}
            </MenuItem>
          ))}
          <div className={C.scenariosMenuDivider}>Actions</div>
          <MenuItem style={{ position: "relative" }} key={"clear"}>
            <div
              data-selector="clear-fields-button"
              style={{
                minWidth: "500px",
              }}
              onClick={() => {
                setAnchorEl(null);
                dispatch(loadScenario(null));
                clearAllFields();
              }}
            >
              Clear All Fields
            </div>
          </MenuItem>
        </Menu>
      </div>
    );
  },
);

export default ApplicationScenariosMenu;
