import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "features/store";
import * as Api from "api";
import * as T from "types/engine-types";
import { createSelector } from "reselect";
import _ from "lodash";
import { parseISO, isPast, isFuture } from "date-fns";
import { InvestorsState } from "features/investors";
import filterResults from "features/filter-list";
import { getErrorMessage } from "features/utils";

export type ProductsState = {
  products: T.DecoratedProductHeader[] | null;
  searchTerm: string;
  sortField: string;
  sortDir: string;
  loading: boolean;
  errors: string;
};

const initialState: ProductsState = {
  products: null,
  loading: false,
  searchTerm: "",
  errors: "",
  sortField: "name",
  sortDir: "asc",
};

const productSlice = createSlice({
  name: "Products",
  initialState,
  reducers: {
    setSort: (state, { payload }: PayloadAction<string>) => {
      let sortDir: string;
      if (payload === state.sortField) {
        if (state.sortDir === "asc") {
          sortDir = "desc";
        } else {
          sortDir = "asc";
        }
      } else {
        sortDir = "asc";
      }
      return {
        ...state,
        sortDir,
        sortField: payload,
      };
    },
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },
    setProducts: (
      state,
      { payload }: PayloadAction<T.DecoratedProductHeader[]>,
    ) => {
      state.products = payload;
    },
  },
});

export const { setLoading, setErrors, setProducts, setSearchTerm, setSort } =
  productSlice.actions;

export default productSlice.reducer;

export const productsSelector = (state: { products: ProductsState }) =>
  state.products;

export function productIsActive(product: T.DecoratedProductHeader): boolean {
  const activationDate = product.activationTimestamp
    ? parseISO(product.activationTimestamp)
    : null;

  const deactivationDate = product.deactivationTimestamp
    ? parseISO(product.deactivationTimestamp)
    : null;

  return !!(
    activationDate &&
    isPast(activationDate) &&
    (!deactivationDate || (deactivationDate && isFuture(deactivationDate)))
  );
}

export const filteredProductsSelector = createSelector(
  [
    (state: { products: ProductsState }) => state.products.products,
    (state: { investors: InvestorsState }) => state.investors.investors,
    (state: { products: ProductsState }) => state.products.searchTerm,
    (state: { products: ProductsState }) => state.products.sortField,
    (state: { products: ProductsState }) => state.products.sortDir,
  ],
  (products, investors, searchTerm, sortField, sortDir) => {
    const decoratedProducts: T.DecoratedProductHeader[] = products
      ? products.map((product) => {
          const investorName = investors?.find(
            (i) => i.id === product.investorId,
          )?.name;
          const isActive = productIsActive(product);

          return {
            ...product,
            investorName: investorName || "",
            isActive,
          };
        })
      : [];

    const filtered: T.DecoratedProductHeader[] = [];
    decoratedProducts.forEach((product: T.DecoratedProductHeader) => {
      const { noNotQuoteMatches, quoteMatch, restMatch } = filterResults(
        searchTerm,
        [
          product.isActive ? "active" : "inactive",
          product.name || "",
          product.investorName || "",
          product.description || "",
          product.code || "",
        ],
      );

      if (noNotQuoteMatches && quoteMatch && restMatch) filtered.push(product);
    });

    let sorted = _.sortBy(filtered, [
      (o) =>
        o[sortField as keyof T.DecoratedProductHeader]
          ?.toString()
          .toLowerCase(),
      (o) => o.name.toLowerCase(),
    ]);
    if (sortDir === "desc") {
      sorted = _.reverse(sorted);
    }
    return sorted;
  },
);

export const productsLoadingSelector = createSelector(
  (state: { products: ProductsState }) => state.products,
  (products) => {
    return products.products !== null && !products.loading;
  },
);

// AppThunk sets the type definitions for the dispatch method
export const getProducts = (): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const products = await Api.getProducts();
      dispatch(setLoading(false));
      dispatch(setProducts(products));
    } catch (error) {
      newrelic.noticeError(getErrorMessage(error));
      dispatch(setErrors(error as string));
      dispatch(setLoading(false));
    }
  };
};
