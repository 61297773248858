import styled from "styled-components";
import whitespace from "design/subatomics/whitespace";
import fontSizes from "design/subatomics/font-sizes";
import color from "design/subatomics/colors";
import { styles } from "design/atoms/typography";
import breakpoints from "design/subatomics/breakpoints";
import radiusOptions from "design/subatomics/corners";
import shadowOptions from "design/subatomics/shadows";
import transitions from "design/subatomics/transitions";
import opacities from "design/subatomics/opacities";

export const Button = styled.button`
  ${styles.text};
  box-sizing: border-box;
  border: 1px solid ${color({ color: "lightBorder" })};
  color: ${color({ color: "gray", shade: 4 })};
  border-radius: ${radiusOptions.small};
  box-shadow: ${shadowOptions.controls};
  background-color: ${color({ color: "white" })};
  transition: background-color ${transitions.default},
    border-color ${transitions.default}, color ${transitions.default};
  display: flex;
  align-items: center;
  justify-items: center;
  min-width: 26px;
  min-height: 26px;
  white-space: nowrap;
  text-transform: capitalize;

  .atom-icon {
    font-size: ${fontSizes.s};
  }

  .prefix {
    margin-right: ${whitespace("less")};

    .atom-icon {
      color: ${color({ color: "border" })};
    }
  }

  .content {
    display: inline-block;
    text-align: center;
    width: 100%;
  }

  .suffix {
    margin-left: ${whitespace("less")};

    .atom-icon {
      position: relative;
      top: -1px;
      color: ${color({ color: "border" })};
    }
  }

  &:not(:disabled) {
    &:not(:focus) {
      &.primary {
        border: 1px solid ${color({ color: "green", shade: 5 })};
        color: ${color({ color: "white" })};
        background-color: ${color({ color: "green", shade: 5 })};

        .atom-icon {
          color: ${color({ color: "white" })};
        }
      }

      &:hover,
      &:active {
        border: 1px solid ${color({ color: "green", shade: 6 })};
        background-color: ${color({ color: "green", shade: 6 })};
        color: ${color({ color: "white" })};
        cursor: pointer;
        .atom-icon {
          transition: color ${transitions.default};
          color: ${color({ color: "white" })};
        }
      }
    }

    &:focus {
      border: 1px solid ${color({ color: "green", shade: 6 })};
      background-color: ${color({ color: "green", shade: 6 })};
      outline: ${color({ color: "white" })};
      color: ${color({ color: "white" })};
      .atom-icon {
        color: ${color({ color: "white" })};
      }
    }
  }

  &.grow {
    flex: 1 0 auto;
  }

  &:disabled {
    opacity: ${opacities.translucent};
  }

  ${breakpoints.mobile} {
    padding: 0 ${whitespace("less")};
  }

  ${breakpoints.desktop} {
    padding: 0 ${whitespace("less")};
  }
`;
