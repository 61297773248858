import { Set as ISet } from "immutable";
import _ from "lodash";
import React, { useState } from "react";
import Loader from "react-loader";
import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DoneIcon from "@material-ui/icons/Done";
import { getInvestors } from "features/investors";

import * as Api from "api";
import * as T from "types/engine-types";
import {
  State,
  newInvestorState,
  investorStateHasValidationErrors,
  convertStateToInvestor,
  InvestorEditor,
} from "../_components/investor-editor";
import MessageBar from "design/atoms/message-bar";
import UnloadPrompt from "design/atoms/unload-prompt";
import { useDispatch } from "react-redux";
import { localAccessId } from "features/access-id";

export default function CreateInvestorPage() {
  const investorsLoadState = Api.useInvestors()[0];

  if (investorsLoadState.status === "loading") {
    return <Loader loaded={false}></Loader>;
  }

  if (investorsLoadState.status === "error") {
    return (
      <MessageBar
        text="Something went wrong, please check your internet connection and try again later. If you see this message again, please contact support."
        type="error"
      />
    );
  }

  const investors = investorsLoadState.value;

  return <CreateInvestorPageLoaded investors={investors} />;
}

type LoadedProps = {
  investors: T.DecoratedInvestorHeader[];
};

function CreateInvestorPageLoaded({ investors }: LoadedProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const accessId = localAccessId();

  const initialInvestorState = () => {
    return newInvestorState();
  };

  const copyOfOriginalInvestorState: State | null = _.cloneDeep(
    initialInvestorState(),
  );

  const [investorState, setInvestorState] = useState(
    copyOfOriginalInvestorState,
  );

  const [isCreate, setIsCreate] = useState(false);

  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const { existingNames, existingCodes } = React.useMemo(() => {
    return {
      existingNames: ISet(investors.map((p) => p.name)),
      existingCodes: ISet(investors.map((p) => p.code)),
    };
  }, [investors]);

  const hasErrors = investorStateHasValidationErrors(
    investorState,
    existingNames,
    existingCodes,
  );

  const handleCreateInvestor = async () => {
    setIsCreate(true);

    setShowValidationErrors(true);

    if (hasErrors) {
      setIsCreate(false);
      return;
    }

    const investor = convertStateToInvestor(investorState);

    setLoading(true);
    const newInvestor = await Api.createInvestor(investor);
    setLoading(false);
    dispatch(getInvestors());
    history.push(`/c/${accessId}/investors/${newInvestor.id}`);
  };

  // The route change will be blocked if the state has changed AND user did not hit the create button
  const shouldBlock =
    !_.isEqual(copyOfOriginalInvestorState, investorState) && !isCreate;

  return (
    <>
      <UnloadPrompt when={shouldBlock} />
      <Loader loaded={!loading} />
      <Box m={2} display="flex">
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => history.push(`/c/${accessId}/investors`)}
        >
          Back to Investors
        </Button>
        <Box flex="1" />
        {showValidationErrors && hasErrors && (
          <Box display="flex" alignItems="center" px={2}>
            <Typography color="secondary">
              Error: Some fields are missing or have invalid values.
            </Typography>
          </Box>
        )}
        <Button
          variant="outlined"
          startIcon={<DoneIcon />}
          onClick={handleCreateInvestor}
          disabled={loading || (showValidationErrors && hasErrors)}
        >
          Create
        </Button>
      </Box>

      <InvestorEditor
        title={"Create investor"}
        // investors={investors}
        existingNames={existingNames}
        existingCodes={existingCodes}
        state={investorState}
        showValidationErrors={showValidationErrors}
        onChange={setInvestorState}
      />
    </>
  );
}
