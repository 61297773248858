import { Map as IMap } from "immutable";
import React, { useCallback, useMemo } from "react";
import { Box, TextField } from "@material-ui/core";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@material-ui/lab/Autocomplete";
import { SearchableDropdown, Dropdown } from "design/molecules/dropdown";
import {
  FieldValueEditor,
  FieldValueState,
  EnumFieldValueState,
  newFieldValueState,
  convertFieldValueToState,
  convertStateToFieldValue,
} from "design/organisms/field-value-editor";
import { convertStateToFieldValueType } from "design/organisms/field-value-type-editor";
import { Configuration, expandConfig } from "config";
import * as T from "types/engine-types";
import * as Fields from "features/fields";
import {
  Setter,
  UiValidationError,
  useSetter,
  unreachable,
  isPresent,
  getErrorMessage,
} from "features/utils";
import * as uuid from "uuid";
import { getRawFieldStateName } from "features/formulas-util";
import {
  FieldDefinitionState,
  IncludedFieldConditionState,
} from "pages/fields";
import {
  expandedConfigSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import ErrorBoundary from "features/utils/error-boundary";
import { useSelector } from "react-redux";

const { newrelic } = window;

export type FieldConditionState = ParentConditionState;

type ParentConditionStateBase = {
  id: T.FieldConditionId;
  parentFieldId: T.FieldId | null;
  domainValidationErrors: {
    hasErrors: boolean;
    id: T.ServerValidationError[];
    parentFieldId: T.ServerValidationError[];
  };
};

export type ParentConditionState = ParentConditionStateBase &
  (
    | ParentFieldIsNotBlankConditionState
    | ParentFieldIsBlankConditionState
    | ParentFieldHasValueConditionState
    | ParentFieldHasNotValueConditionState
    | ParentFieldIsOneOfConditionState
    | ParentFieldIsNotOneOfConditionState
  );

type ParentFieldIsBlankConditionState = {
  type: "parent-field-is-blank";
};
type ParentFieldIsNotBlankConditionState = {
  type: "parent-field-is-not-blank";
};
type ParentFieldHasNotValueConditionState = {
  type: "parent-field-has-not-value";
  value: FieldValueState | null;
};
type ParentFieldHasValueConditionState = {
  type: "parent-field-has-value";
  value: FieldValueState | null;
};
type ParentFieldIsOneOfConditionState = {
  type: "parent-field-is-one-of";
  values: FieldValueState[];
};
type ParentFieldIsNotOneOfConditionState = {
  type: "parent-field-is-not-one-of";
  values: FieldValueState[];
};

export const FieldConditionEditor = React.memo(function FieldConditionEditor({
  state,
  showErrors,
  setState,
  previousFields,
  enumTypes,
}: {
  state: FieldConditionState;
  showErrors: boolean;
  setState: Setter<FieldConditionState>;
  previousFields: FieldDefinitionState[];
  enumTypes: T.RawEnumType[];
}) {
  const config = useSelector(expandedConfigSelector);
  const parentFieldId = state.parentFieldId;
  const { ...parentConditionState } = state;

  const setParentFieldId: Setter<T.FieldId | null> = useSetter(
    setState,
    (oldState, transformParentFieldId) => ({
      id: oldState.id,
      parentFieldId: transformParentFieldId(oldState.parentFieldId),
      // reset to parent-field-is-blank in case the current condition type isn't supported
      // for the new field, or has invalid values because it's a different value type
      type: "parent-field-is-blank" as const,
      domainValidationErrors: { hasErrors: false, id: [], parentFieldId: [] },
    }),
    [setState],
  );

  const setParentConditionState: Setter<FieldConditionState> = useSetter(
    setState,
    (oldState, transformParentCondition) => {
      const { ...parentConditionState } = oldState;
      return {
        ...transformParentCondition(parentConditionState),
      };
    },
    [setState],
  );

  const parentValueType = useMemo(
    () =>
      parentFieldId &&
      getParentValueType(
        parentFieldId,
        previousFields,
        config.allSystemFieldsById,
      ),
    [parentFieldId, previousFields, config.allSystemFieldsById],
  );

  const previousFieldIds = useMemo(
    () => previousFields.map((f) => f.id),
    [previousFields],
  );

  const getPreviousFieldName = useCallback(
    (fieldId: T.FieldId) => {
      const fieldState = previousFields.find((f) => f.id === fieldId);
      if (fieldState)
        return getRawFieldStateName(fieldState, config.allSystemFieldsById);
      else return "<Unknown>";
    },
    [previousFields, config.allSystemFieldsById],
  );

  return (
    <>
      <Box my={2}>
        <SearchableDropdown<T.FieldId>
          label="Parent Field"
          options={previousFieldIds}
          getOptionLabel={getPreviousFieldName}
          value={parentFieldId}
          error={showErrors && state.parentFieldId === null}
          setValue={setParentFieldId}
        />
      </Box>
      {parentValueType && (
        <ParentConditionEditor
          state={parentConditionState}
          setState={setParentConditionState}
          showErrors={showErrors}
          parentValueType={parentValueType}
          enumTypes={enumTypes}
        />
      )}
    </>
  );
});

export function newFieldConditionState(): FieldConditionState {
  return {
    id: uuid.v4() as T.FieldConditionId,
    type: "parent-field-is-not-blank" as const,
    parentFieldId: null,
    domainValidationErrors: { hasErrors: false, id: [], parentFieldId: [] },
  };
}

export function convertIncludedFieldConditionToState(
  conditions: T.IncludedFieldCondition[],
): IncludedFieldConditionState[] {
  return conditions.map((condition) => {
    return {
      id: condition.id,
      domainValidationErrors: { hasErrors: false, id: [] },
    };
  });
}

export function convertFieldConditionToState(
  conditions: T.FieldCondition[] | undefined | null,
): FieldConditionState[] {
  if (!conditions || !conditions.length) {
    return [];
  }

  return conditions.map((condition) => {
    const id = condition.id;
    const parentFieldId = condition.parentFieldId;

    let parentConditionState: FieldConditionState;

    switch (condition.type) {
      case "parent-field-is-blank":
        parentConditionState = {
          id,
          parentFieldId,
          type: "parent-field-is-blank",
          domainValidationErrors: {
            hasErrors: false,
            id: [],
            parentFieldId: [],
          },
        };
        break;
      case "parent-field-is-not-blank":
        parentConditionState = {
          id,
          parentFieldId,
          type: "parent-field-is-not-blank",
          domainValidationErrors: {
            hasErrors: false,
            id: [],
            parentFieldId: [],
          },
        };
        break;
      case "parent-field-has-not-value":
        parentConditionState = {
          id,
          type: "parent-field-has-not-value",
          parentFieldId,
          value: convertFieldValueToState(condition.value),
          domainValidationErrors: {
            hasErrors: false,
            id: [],
            parentFieldId: [],
          },
        };
        break;
      case "parent-field-has-value":
        parentConditionState = {
          id,
          type: "parent-field-has-value",
          parentFieldId,
          value: convertFieldValueToState(condition.value),
          domainValidationErrors: {
            hasErrors: false,
            id: [],
            parentFieldId: [],
          },
        };
        break;
      case "parent-field-is-one-of":
        parentConditionState = {
          id,
          type: "parent-field-is-one-of" as const,
          parentFieldId,
          values: condition.values.map(convertFieldValueToState),
          domainValidationErrors: {
            hasErrors: false,
            id: [],
            parentFieldId: [],
          },
        };
        break;
      case "parent-field-is-not-one-of":
        parentConditionState = {
          id,
          type: "parent-field-is-not-one-of" as const,
          parentFieldId,
          values: condition.values.map(convertFieldValueToState),
          domainValidationErrors: {
            hasErrors: false,
            id: [],
            parentFieldId: [],
          },
        };
        break;
    }

    return parentConditionState;
  });
}

export function convertStateToIncludedFieldCondition(
  states: readonly IncludedFieldConditionState[],
): T.IncludedFieldCondition[] {
  return states.map((state) => ({
    id: state.id,
  }));
}

function enumTypeIdFromFieldValueTypeState(
  valueType: T.FieldValueType,
): T.EnumTypeId | null {
  // valueType.enumState.enumTypeId
  if (valueType.type === "enum") {
    return valueType.enumTypeId;
  }
  return null;
}

export function convertStateToFieldCondition(
  validParentFields: readonly FieldDefinitionState[],
  states: readonly FieldConditionState[],
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): T.FieldCondition[] {
  return states.map((state) => {
    const { parentFieldId } = state;

    if (!parentFieldId) {
      throw new UiValidationError(
        "Select a parent field to use in the field condition",
      );
    }

    const parentFieldDefinitionState = validParentFields.find(
      (f) => f.id === parentFieldId,
    );

    if (!parentFieldDefinitionState) {
      throw new UiValidationError(
        "Field condition must be based on a field that comes before that field",
      );
    }

    let parentFieldValueType;

    try {
      if (parentFieldDefinitionState.disposition.kind === "native") {
        parentFieldValueType = convertStateToFieldValueType(
          parentFieldDefinitionState.disposition.valueType,
        );
      } else {
        const inherited = inheritableFields.get(parentFieldDefinitionState.id);
        if (inherited != null) {
          parentFieldValueType = inherited.valueType;
        } else {
          throw new UiValidationError(
            "Parent field inherits from a nonexistent system field",
          );
        }
      }
    } catch (err) {
      if (err instanceof UiValidationError) {
        newrelic.noticeError(getErrorMessage("Parent field contains errors"));
        newrelic.noticeError(err);
        throw new UiValidationError("Parent field contains errors");
      }
      throw err;
    }
    const parentEnumTypeId =
      enumTypeIdFromFieldValueTypeState(parentFieldValueType);
    let parentCondition: T.FieldCondition;

    switch (state.type) {
      case "parent-field-is-blank":
      case "parent-field-is-not-blank":
        parentCondition = {
          id: state.id,
          type: state.type,
          parentFieldId: state.parentFieldId!,
        };
        break;
      case "parent-field-has-value":
      case "parent-field-has-not-value":
        const fieldValue =
          state.value &&
          convertStateToFieldValue(parentFieldValueType, state.value);

        if (!fieldValue) {
          throw new UiValidationError("Field value required");
        }

        parentCondition = {
          id: state.id,
          type: state.type,
          parentFieldId: state.parentFieldId!,
          value: fieldValue,
        };
        break;
      case "parent-field-is-one-of":
      case "parent-field-is-not-one-of":
        const fieldValues = (state.values || []).map((val) => {
          if (val === null) {
            throw new UiValidationError("Field value required");
          }
          if (val.type !== "enum") {
            throw new UiValidationError("Field value should only be enum");
          }
          if (parentEnumTypeId === null) {
            throw new UiValidationError(
              `Enum type mismatch: the parent field does not have an enum type`,
            );
          }
          if (val.enumTypeId !== parentEnumTypeId) {
            throw new UiValidationError(
              `Enum type mismatch: parent field has enum type ${parentEnumTypeId} but child field has enum type ${val.enumTypeId}`,
            );
          }
          if (!val.variantId) {
            throw new UiValidationError("Field value must include variantId");
          }

          return {
            ...val,
            variantId: val.variantId,
          };
        });

        if (fieldValues.length === 0) {
          throw new UiValidationError("Field values required");
        }

        parentCondition = {
          id: state.id,
          type: state.type,
          parentFieldId: state.parentFieldId!,
          values: fieldValues,
        };
        break;
    }

    return { ...parentCondition };
  });
}

function getParentValueType(
  fieldId: T.FieldId,
  previousFields: FieldDefinitionState[],
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): T.FieldValueType | null {
  const fieldDefState = previousFields.find((f) => f.id === fieldId);

  if (!fieldDefState) {
    console.warn(`could not find parent field with id ${fieldId}`);
    return null;
  }

  try {
    if (fieldDefState.disposition.kind === "native") {
      return convertStateToFieldValueType(fieldDefState.disposition.valueType);
    } else {
      const inherited = inheritableFields.get(fieldDefState.id);
      if (inherited) {
        return inherited.valueType;
      } else {
        console.warn(
          `could not find inherited field with id ${fieldDefState.id}`,
        );
        return null;
      }
    }
  } catch (err) {
    if (err instanceof UiValidationError) {
      console.log(`parent field ${fieldId} has errors`);
      newrelic.noticeError(err);
      return null;
    }
    throw err;
  }
}

const ParentConditionEditor = React.memo(
  ({
    state,
    setState,
    parentValueType,
    showErrors,
    enumTypes,
  }: {
    state: FieldConditionState;
    setState: Setter<FieldConditionState>;
    parentValueType: T.FieldValueType;
    showErrors: boolean;
    enumTypes: T.RawEnumType[];
  }) => {
    const config = useSelector(expandedConfigSelector);
    const objectDetails = useSelector(objectDetailsMapSelector);
    const configWithEnums = useMemo(
      () => expandConfig({ ...config.original, rawEnumerations: enumTypes }),
      [config, enumTypes],
    );

    const setType: Setter<FieldConditionState["type"]> = useSetter(
      setState,
      (oldState, t) => {
        const newType = t(oldState.type);

        const oldValues = conditionHasMultiValue(oldState)
          ? oldState.values
          : conditionHasSingleValue(oldState) && oldState.value
          ? [oldState.value]
          : [];

        switch (newType) {
          case "parent-field-is-blank":
          case "parent-field-is-not-blank":
            return {
              id: oldState.id,
              parentFieldId: oldState.parentFieldId,
              type: newType,
              domainValidationErrors: {
                hasErrors: false,
                id: [],
                parentFieldId: [],
              },
            };
          case "parent-field-has-value":
          case "parent-field-has-not-value":
            return {
              id: oldState.id,
              parentFieldId: oldState.parentFieldId,
              type: newType,
              value: oldValues[0] || null,
              domainValidationErrors: {
                hasErrors: false,
                id: [],
                parentFieldId: [],
              },
            };
          case "parent-field-is-one-of":
          case "parent-field-is-not-one-of":
            return {
              id: oldState.id,
              parentFieldId: oldState.parentFieldId,
              type: newType,
              values: oldValues,
              domainValidationErrors: {
                hasErrors: false,
                id: [],
                parentFieldId: [],
              },
            };
          default:
            return unreachable(newType);
        }
      },
      [],
    );

    const setValues: Setter<FieldValueState[]> = useSetter(
      setState,
      (oldState, transformValues) => {
        if (!conditionHasMultiValue(oldState)) {
          throw new Error(`cannot set values on a ${oldState.type} condition`);
        }

        return {
          id: oldState.id,
          values: transformValues(oldState.values),
          parentFieldId: oldState.parentFieldId,
          type: oldState.type,
          domainValidationErrors: {
            hasErrors: false,
            id: [],
            parentFieldId: [],
          },
        };
      },
      [setState],
    );

    const setValue: Setter<FieldValueState> = useSetter(
      setState,
      (oldState, transformValue) => {
        if (!conditionHasSingleValue(oldState)) {
          throw new Error(`cannot set value on a ${oldState.type} condition`);
        }

        return {
          id: oldState.id,
          value: transformValue(
            oldState.value || newFieldValueState(parentValueType),
          ),
          parentFieldId: oldState.parentFieldId,
          type: oldState.type,
          domainValidationErrors: {
            hasErrors: false,
            id: [],
            parentFieldId: [],
          },
        };
      },
      [setState, parentValueType],
    );

    const fieldConditionTypes =
      fieldConditionTypesForParentValueType(parentValueType);

    const conditionTypeSelector = (
      <Dropdown<T.FieldCondition["type"]>
        label="Condition Type"
        options={fieldConditionTypes}
        getOptionLabel={Fields.getFieldConditionTypeName}
        value={state.type}
        setValue={setType}
      />
    );

    const singleValueSelector = conditionHasSingleValue(state) && (
      <FieldValueEditor
        label="Value"
        margin="normal"
        state={state.value || newFieldValueState(parentValueType)}
        showErrors={showErrors}
        setState={setValue}
        parentState={null}
        valueType={parentValueType}
        config={configWithEnums}
        objectDetails={objectDetails}
      />
    );

    const multiValueSelector = conditionHasMultiValue(state) && (
      <ErrorBoundary>
        <MultiValueSelect
          selectedValues={state.values}
          setSelectedValues={setValues}
          valueType={parentValueType}
          config={configWithEnums}
          required
          showErrors={showErrors}
        />
      </ErrorBoundary>
    );

    return (
      <>
        {conditionTypeSelector}
        {singleValueSelector}
        {multiValueSelector}
      </>
    );
  },
);

function conditionHasSingleValue(
  conditionState: FieldConditionState,
): conditionState is ParentConditionStateBase &
  (ParentFieldHasValueConditionState | ParentFieldHasNotValueConditionState) {
  return (
    conditionState.type === "parent-field-has-value" ||
    conditionState.type === "parent-field-has-not-value"
  );
}

function conditionHasMultiValue(
  conditionState: FieldConditionState,
): conditionState is ParentConditionStateBase &
  (ParentFieldIsOneOfConditionState | ParentFieldIsNotOneOfConditionState) {
  return (
    conditionState.type === "parent-field-is-one-of" ||
    conditionState.type === "parent-field-is-not-one-of"
  );
}

function fieldConditionTypesForParentValueType(
  valueType: T.FieldValueType,
): FieldConditionState["type"][] {
  switch (valueType.type) {
    case "duration":
    case "date":
    case "number":
    case "string":
      return [
        "parent-field-is-blank",
        "parent-field-is-not-blank",
        "parent-field-has-value",
        "parent-field-has-not-value",
      ];
    case "header":
    case "object-ref":
      return [];
    case "enum":
      return [
        "parent-field-is-blank",
        "parent-field-is-not-blank",
        "parent-field-is-one-of",
        "parent-field-is-not-one-of",
      ];
    default:
      unreachable(valueType);
  }
}

const MultiValueSelect = React.memo(
  ({
    selectedValues,
    setSelectedValues,
    valueType,
    config,
    required,
    showErrors,
  }: {
    selectedValues: FieldValueState[];
    setSelectedValues: Setter<FieldValueState[]>;
    valueType: T.FieldValueType;
    config: Configuration;
    required?: boolean;
    showErrors: boolean;
  }) => {
    if (valueType.type !== "enum") {
      throw new Error(
        `Expected valueType to be an enum, but found ${valueType.type}`,
      );
    }

    if (
      !selectedValues.every(
        (val): val is EnumFieldValueState => val.type === "enum",
      )
    ) {
      throw new Error(
        `Selected values includes non-enum values, ${JSON.stringify(
          selectedValues,
        )}`,
      );
    }

    if (selectedValues.some((val) => val.enumTypeId !== valueType.enumTypeId)) {
      throw new Error(
        `Selected values references the wrong enum type. (Expected ${
          valueType.enumTypeId
        }, but selected values are ${JSON.stringify(selectedValues)}.)`,
      );
    }

    const enumType = config.enumTypesById.get(valueType.enumTypeId);

    const enumVariantIds = useMemo(
      () => (enumType?.variants || []).map((v) => v.id),
      [enumType],
    );

    const enumVariantsById = useMemo(
      () =>
        IMap<T.EnumVariantId, T.EnumVariant>(
          (enumType?.variants || []).map((v) => [v.id, v]),
        ),
      [enumType],
    );

    const selectedVariants = useMemo(
      () => selectedValues.map((val) => val.variantId).filter(isPresent),
      [selectedValues],
    );

    const getOptionLabel = useCallback(
      (id: T.EnumVariantId) => enumVariantsById.get(id)?.name || "<Unknown>",
      [enumVariantsById],
    );

    const onSelectionsChange = useCallback(
      (e: unknown, selections: T.EnumVariantId[]) =>
        enumType &&
        setSelectedValues(
          selections.map((variantId) => ({
            type: "enum",
            enumTypeId: enumType.id,
            variantId,
          })),
        ),
      [enumType, setSelectedValues],
    );

    const error = showErrors && !!required && !selectedVariants.length;

    const renderInput = useCallback(
      (params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          variant="outlined"
          label={`${enumType?.name || "<Unknown Enum>"} variations`}
          error={error}
        />
      ),
      [enumType, error],
    );

    return (
      <Autocomplete<T.EnumVariantId, true>
        style={{ marginTop: "16px" }}
        multiple
        disableCloseOnSelect={true}
        options={enumVariantIds}
        getOptionLabel={getOptionLabel}
        onChange={onSelectionsChange}
        value={selectedVariants}
        renderInput={renderInput}
      />
    );
  },
);
