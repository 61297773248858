import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import * as Api from "../../api";
import * as T from "../../types/engine-types";
import { createSelector } from "reselect";
import _ from "lodash";
import filterResults from "../filter-list";
import { getErrorMessage } from "../utils";

export type DataTablesState = {
  dataTables: T.DataTableHeader[];
  dataTableColumns: T.DataTableColumnNameAndValueType[] | null;
  searchTerm: string;
  sortField: DataTableSortField;
  sortDir: string;
  loading: boolean;
  errors: string;
};

type DataTableSortField = "name";

const initialState: DataTablesState = {
  dataTables: [],
  dataTableColumns: null,
  loading: false,
  searchTerm: "",
  errors: "",
  sortField: "name",
  sortDir: "asc",
};

export const getDataTableColumns = createAsyncThunk(
  "dataTables/getDataTableColumns",
  async () => {
    return await Api.getDataTableColumnNamesAndValueTypes();
  },
);

const dataTableSlice = createSlice({
  name: "DataTables",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDataTableColumns.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDataTableColumns.fulfilled, (state, { payload }) => {
      state.dataTableColumns = payload;
      state.loading = false;
    });
  },
  reducers: {
    setSort: (state, { payload }: PayloadAction<DataTableSortField>) => {
      let sortDir: string;
      if (payload === state.sortField) {
        if (state.sortDir === "asc") {
          sortDir = "desc";
        } else {
          sortDir = "asc";
        }
      } else {
        sortDir = "asc";
      }
      return {
        ...state,
        sortDir,
        sortField: payload,
      };
    },
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },
    setDataTables: (state, { payload }: PayloadAction<T.DataTableHeader[]>) => {
      state.dataTables = payload;
    },
  },
});

export const { setLoading, setErrors, setDataTables, setSearchTerm, setSort } =
  dataTableSlice.actions;

export default dataTableSlice.reducer;

export const dataTablesSelector = (state: { dataTables: DataTablesState }) =>
  state.dataTables;

export const filteredDataTablesSelector = createSelector(
  [
    (state: { dataTables: DataTablesState }) => state.dataTables.dataTables,
    (state: { dataTables: DataTablesState }) => state.dataTables.searchTerm,
    (state: { dataTables: DataTablesState }) => state.dataTables.sortField,
    (state: { dataTables: DataTablesState }) => state.dataTables.sortDir,
  ],
  (dataTables, searchTerm, sortField, sortDir) => {
    const filtered: T.DataTableHeader[] = [];
    dataTables.forEach((dataTable: T.DataTableHeader) => {
      const { noNotQuoteMatches, quoteMatch, restMatch } = filterResults(
        searchTerm,
        [dataTable.name || ""],
      );

      if (noNotQuoteMatches && quoteMatch && restMatch)
        filtered.push(dataTable);
    });

    let sorted = _.sortBy(filtered, [
      (o) => o[sortField]?.toLowerCase(),
      (o) => o.name.toLowerCase(),
    ]);

    if (sortDir === "desc") {
      sorted = _.reverse(sorted);
    }
    return sorted;
  },
);

export const dedupedColumns = createSelector(
  [
    (state: { dataTables: DataTablesState }) =>
      state.dataTables.dataTableColumns,
  ],
  (columns) => {
    return columns === null ? null : _.uniqBy(columns, (c) => c.name);
  },
);

// AppThunk sets the type definitions for the dispatch method
export const getDataTables = (): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const dataTables = await Api.getDataTables();

      dispatch(setLoading(false));
      dispatch(setDataTables(dataTables));
    } catch (error: unknown) {
      newrelic.noticeError(getErrorMessage(error));
      dispatch(setErrors(error as string));
      dispatch(setLoading(false));
    }
  };
};
