import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DoneIcon from "@material-ui/icons/Done";
import { getDataTables } from "features/data-tables";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import * as Api from "api";
import UnloadPrompt from "design/atoms/unload-prompt";
import { useDispatch, useSelector } from "react-redux";

import {
  DataTableEditor,
  validateDataTableState,
  convertStateToDataTable,
  newDataTableState,
} from "../_components/DataTableEditor";

export default React.memo(function CreateDataTablePage() {
  const [editorState, setEditorState] = useState(newDataTableState());
  const [showErrors, setShowErrors] = useState(false);
  const [nextUrl, navigateTo] = useState<string>();
  const dispatch = useDispatch();
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);

  const createDataTable = useCallback(() => {
    (async () => {
      const err = validateDataTableState(editorState);

      if (err !== null) {
        setTimeout(
          () => alert(err || "Some fields did not pass validation"),
          10,
        );
        setShowErrors(true);
        return;
      }

      const dataTable = await Api.createDataTable(
        convertStateToDataTable(editorState),
      );

      dispatch(getDataTables());
      navigateTo(`/c/${accessId}/data-tables/${dataTable.id}`);
    })();
  }, [editorState, dispatch, accessId]);

  return (
    <>
      <UnloadPrompt when={true} nextUrl={nextUrl} />

      <Box display="flex" px={2} my={2}>
        <Button
          component={Link}
          to={`/c/${accessId}/data-tables`}
          variant="outlined"
          startIcon={<ArrowBackIcon />}
        >
          Back to Data Tables
        </Button>
        <Box flex="1" />
        <Button
          variant="outlined"
          startIcon={<DoneIcon />}
          onClick={createDataTable}
        >
          Create
        </Button>
      </Box>
      <DataTableEditor
        state={editorState}
        setState={setEditorState}
        showErrors={showErrors}
        title="Set up a new data table"
      />
    </>
  );
});
