import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import * as T from "types/generated-types";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  newObjectListState,
  ObjectListEditor,
  ObjectListEditorCallbacks,
} from "design/organisms/object-list-editor";
import {
  encompassCustomFieldsSelector,
  listCustomFields,
  removeCustomField,
  setCustomField,
} from "features/encompass-custom-fields";
import { Setter, usePropertySetter } from "features/utils";
import { useObjectEditorStyles } from "pages/fields";
import { Dropdown } from "design/molecules/dropdown";
import UnloadPrompt from "design/atoms/unload-prompt";
import * as Api from "api";
import { getMessageNode } from "features/server-validation";

type EncompassCustomFieldState = {
  id: T.EncompassCustomFieldId;
  path: string;
  type: T.EncompassCustomFieldType;
  isSaving: boolean;
  validationErrors: null | T.EncompassCustomFieldValidation;
};

const ALL_ENCOMPASS_CUSTOM_FIELD_TYPES: T.EncompassCustomFieldType[] = [
  "string",
  "number",
  "boolean",
];

function getCustomFieldTypeName(type: T.EncompassCustomFieldType): string {
  switch (type) {
    case "string":
      return "Text";
    case "number":
      return "Number";
    case "boolean":
      return "Boolean";
  }
}

const CustomFieldEditor = React.memo(
  ({
    value,
    showErrors,
    index,
    setValue,
  }: {
    value: EncompassCustomFieldState;
    showErrors: boolean;
    index: number;
    setValue: Setter<EncompassCustomFieldState>;
  }) => {
    const C = useObjectEditorStyles();
    const setPath = usePropertySetter(setValue, "path");
    const setType = usePropertySetter(setValue, "type");

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <Box>
          <TextField
            className={C.standardField}
            label="Path"
            variant="outlined"
            value={value.path}
            onChange={(ev) => setPath(ev.target.value)}
            InputLabelProps={{ shrink: true }}
            error={!!value.validationErrors?.fieldPath.length}
            helperText={
              value.validationErrors?.fieldPath &&
              getMessageNode(value.validationErrors?.fieldPath)
            }
          />
        </Box>
        <Box>
          <Dropdown<T.EncompassCustomFieldType>
            className={C.standardField}
            label="Type"
            options={ALL_ENCOMPASS_CUSTOM_FIELD_TYPES}
            getOptionLabel={getCustomFieldTypeName}
            value={value.type}
            setValue={setType}
          />
        </Box>
      </Box>
    );
  },
);

function getObjectListLabel(state: EncompassCustomFieldState): JSX.Element {
  const style = {
    position: "relative",
    float: "left",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  } as {};

  return <div style={style}>{state.path ? state.path : "<Unknown>"}</div>;
}

function fieldStateIsSaved(
  storedFieldStates: readonly EncompassCustomFieldState[],
  currentFieldState: EncompassCustomFieldState,
): boolean {
  if (!currentFieldState.id) return false;
  const stored = storedFieldStates.find(
    (stored) => stored.id === currentFieldState.id,
  );
  if (!stored) return false;
  if (stored.path !== currentFieldState.path) return false;
  if (stored.type !== currentFieldState.type) return false;

  return true;
}

function fieldStatesAreSaved(
  storedFieldStates: readonly EncompassCustomFieldState[],
  currentFieldStates: readonly EncompassCustomFieldState[],
): boolean {
  if (storedFieldStates.length !== currentFieldStates.length) {
    return false;
  }

  for (const stored of storedFieldStates) {
    const current = currentFieldStates.find(
      (current) => current.id === stored.id,
    );

    // Make sure nothing has been deleted
    if (!current) return false;

    // Make sure nothing has been altered
    if (current.path !== stored.path) return false;
    if (current.type !== stored.type) return false;
  }

  for (const current of currentFieldStates) {
    const stored = storedFieldStates.find((stored) => stored.id === current.id);

    // Make sure nothing has been added
    if (!stored) return false;
  }

  return true;
}

function fieldToState(
  field: T.EncompassCustomField,
): EncompassCustomFieldState {
  return {
    id: field.id,
    path: field.fieldPath,
    type: field.fieldType,
    isSaving: false,
    validationErrors: null,
  };
}

function stateToField(
  state: EncompassCustomFieldState,
): T.EncompassCustomField {
  return {
    id: state.id,
    fieldPath: state.path,
    fieldType: state.type,
  };
}

function storedFieldsToState(
  fields: T.EncompassCustomField[],
): EncompassCustomFieldState[] {
  return fields.map(fieldToState);
}

const EncompassCustomFieldsPage = React.memo(() => {
  const dispatch = useDispatch();
  const customFields = useSelector(encompassCustomFieldsSelector);
  const [fieldToDelete, setFieldToDelete] = useState<{
    fieldId: T.EncompassCustomFieldId;
    callbacks: ObjectListEditorCallbacks<
      T.EncompassCustomFieldId,
      EncompassCustomFieldState
    >;
  } | null>(null);

  const [customFieldsState, setCustomFieldsState] = useState(() => {
    return newObjectListState([] as EncompassCustomFieldState[]);
  });

  const [storedCustomFieldsState, setStoredCustomFieldsState] = useState<
    EncompassCustomFieldState[] | null
  >(null);

  const anyUnsaved = storedCustomFieldsState
    ? !fieldStatesAreSaved(storedCustomFieldsState, customFieldsState.objects)
    : false;

  useEffect(() => {
    dispatch(listCustomFields());
  }, [dispatch]);

  useEffect(() => {
    if (customFields.status.kind === "loaded") {
      const storedFields = storedFieldsToState(customFields.customFields);

      setStoredCustomFieldsState(storedFields);

      setCustomFieldsState((state) => ({
        ...state,
        objects: storedFields,
      }));
    }
  }, [customFields.customFields, customFields.status.kind]);

  return (
    <>
      <UnloadPrompt when={anyUnsaved} />
      <Box m={3} flex="1">
        <ObjectListEditor
          disableControls={false}
          height="100%"
          state={customFieldsState}
          makeObjectEditor={(props) => (
            <CustomFieldEditor
              value={props.value}
              showErrors={props.showErrors}
              index={props.index}
              setValue={props.setValue}
            />
          )}
          itemHasAdditionalErrors={(obj) =>
            obj.validationErrors
              ? obj.validationErrors.fieldPath.length > 0
              : false
          }
          additionalButtons={[
            {
              name: "New",
              icon: <AddIcon />,
              onClick: (callbacks) =>
                callbacks.addObject({
                  id: "" as T.EncompassCustomFieldId,
                  path: "",
                  type: "string" as T.EncompassCustomFieldType,
                  isSaving: false,
                  validationErrors: null,
                }),
            },
            {
              name: "Delete",
              icon: <DeleteIcon />,
              disabled: (callbacks) => !callbacks.getSelectedObject(),
              onClick: async (callbacks) => {
                const selectedIndex = callbacks.getSelectedIndex();
                if (selectedIndex === null) return;

                const selected = callbacks.getObject(selectedIndex);
                if (selected === null) return;

                if (!selected.id) {
                  // If this is a new unsaved field, just remove it from the list
                  callbacks.removeObject(selectedIndex);
                } else {
                  // Otherwise delete it on the server
                  setFieldToDelete({
                    fieldId: selected.id,
                    callbacks,
                  });
                }
              },
            },
            {
              name: (callbacks) => {
                const selected = callbacks.getSelectedObject();

                if (selected) {
                  if (selected.isSaving) {
                    return "Saving";
                  } else if (
                    storedCustomFieldsState &&
                    fieldStateIsSaved(storedCustomFieldsState, selected)
                  ) {
                    return "Saved";
                  }
                }

                return "Save";
              },
              icon: (callbacks) => {
                const selected = callbacks.getSelectedObject();

                if (selected) {
                  if (selected.isSaving) return <CircularProgress size="1em" />;
                }

                return null;
              },
              color: "primary",
              variant: "contained",
              disabled: (callbacks) => {
                const selected = callbacks.getSelectedObject();
                if (!selected) return true;
                if (selected.isSaving) return true;

                if (
                  storedCustomFieldsState &&
                  fieldStateIsSaved(storedCustomFieldsState, selected)
                ) {
                  return true;
                }

                return false;
              },
              onClick: async (callbacks) => {
                const selectedIndex = callbacks.getSelectedIndex();
                const selected = callbacks.getSelectedObject();
                if (selectedIndex !== null && selected) {
                  callbacks.updateSelectedObject({
                    ...selected,
                    isSaving: true,
                  });
                  const field = stateToField(selected);
                  try {
                    const savedField = field.id
                      ? await Api.updateEncompassCustomField(field.id, field)
                      : await Api.createEncompassCustomField(field);

                    dispatch(setCustomField(savedField));

                    callbacks.updateObject(selectedIndex, {
                      ...fieldToState(savedField),
                    });
                  } catch (e) {
                    if (
                      e instanceof Api.DetailedInvalidRequest &&
                      e.errors.type === "encompass-custom-field"
                    ) {
                      const selected = callbacks.getObject(selectedIndex);
                      if (selected) {
                        callbacks.updateObject(selectedIndex, {
                          ...selected,
                          isSaving: false,
                          validationErrors: e.errors.value,
                        });
                      }
                    }
                  }
                }
              },
              alignRight: true,
            },
          ]}
          allowDelete={false}
          allowDuplicate={false}
          emptyText={"You don't have any custom fields"}
          getObjectLabel={getObjectListLabel}
          setState={setCustomFieldsState}
          sortBy={() => 0}
        />
        <Dialog open={!!fieldToDelete} onClose={() => setFieldToDelete(null)}>
          <DialogTitle>Delete custom field?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this custom field? This action
              cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setFieldToDelete(null)}>Cancel</Button>
            <Button
              color="secondary"
              onClick={async () => {
                if (fieldToDelete !== null) {
                  await Api.deleteEncompassCustomField(fieldToDelete.fieldId);
                  fieldToDelete.callbacks.selectObject(null);
                  dispatch(removeCustomField(fieldToDelete.fieldId));
                }
                setFieldToDelete(null);
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
});

export default EncompassCustomFieldsPage;
