export const palette = {
  black: "0,0,0",
  white: "255,255,255",

  brandPrimary: "0,148,68",
  brandLightGray: "188,190,192",

  gray1: "20,20,20",
  gray2: "31,31,31",
  gray3: "38,38,38",
  gray4: "67,67,67",
  gray5: "89,89,89",
  gray6: "140,140,140",
  gray7: "217,217,217",
  gray8: "240,240,240",
  gray9: "245,245,245",
  gray10: "250,250,250",

  blue1: "0,39,102",
  blue2: "0,58,140",
  blue3: "0,80,179",
  blue4: "9,109,217",
  blue5: "24,144,255",
  blue6: "64,169,255",
  blue7: "105,192,255",
  blue8: "145,213,255",
  blue9: "186,231,255",
  blue10: "230,247,255",

  green1: "0,71,33",
  green2: "0,89,41",
  green3: "0,105,49",
  green4: "0,123,57",
  green5: "0,148,68",
  green6: "11,195,96",
  green7: "26,220,115",
  green8: "58,240,142",
  green9: "138,255,192",
  green10: "228,255,241",

  red1: "92,0,17",
  red2: "130,0,20",
  red3: "168,7,26",
  red4: "207,19,34",
  red5: "245,34,45",
  red6: "255,77,79",
  red7: "255,120,117",
  red8: "255,163,158",
  red9: "255,204,199",
  red10: "255,241,240",

  orange1: "97,37,0",
  orange2: "135,56,0",
  orange3: "173,78,0",
  orange4: "212,107,0",
  orange5: "250,140,22",
  orange6: "255,169,64",
  orange7: "255,192,105",
  orange8: "255,213,145",
  orange9: "255,231,186",
  orange10: "255,247,230",

  yellow1: "97,71,0",
  yellow2: "135,104,0",
  yellow3: "173,139,0",
  yellow4: "212,177,6",
  yellow5: "250,219,20",
  yellow6: "255,236,61",
  yellow7: "255,245,102",
  yellow8: "255,251,143",
  yellow9: "255,255,184",
  yellow10: "255,255,230",
};

export const colorDefaults = {
  black: palette["black"],
  white: palette["white"],

  brandPrimary: palette["brandPrimary"],
  brandLightGray: palette["brandLightGray"],

  gray: palette["gray6"],
  blue: palette["blue6"],
  green: palette["green6"],
  red: palette["red6"],
  yellow: palette["yellow6"],
  orange: palette["orange6"],
  border: palette["gray6"],
  lightBorder: palette["gray7"],
};

function color({
  color,
  shade,
  opacity = 1,
}: {
  color: string;
  shade?: number;
  opacity?: number;
}) {
  console.assert(
    Object.keys(colorDefaults).includes(color),
    `${color} not included in palette.`,
  );

  if (
    shade &&
    (color === "gray" ||
      color === "blue" ||
      color === "green" ||
      color === "red" ||
      color === "orange" ||
      color === "yellow")
  ) {
    console.assert(
      shade >= 1 && shade <= 10,
      "Shade must be between 1 and 10.",
    );
  }

  if (opacity) {
    console.assert(
      opacity >= 0 && opacity <= 1,
      "Opacity must be between 0 and 1.",
    );
  }

  let combinedColor, paletteMap;

  if (shade) {
    paletteMap = new Map(Object.entries(palette));
    combinedColor = paletteMap.get(color + String(shade));
  } else {
    paletteMap = new Map(Object.entries(colorDefaults));
    combinedColor = paletteMap.get(color);
  }

  return `rgba(${combinedColor || ""}, ${opacity})`;
}

export default color;
