import { Set as ISet } from "immutable";
import React from "react";
import { Box, Checkbox, TextField, Button } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { SearchableDropdown } from "design/molecules/dropdown";
import { Configuration } from "config";
import * as T from "types/engine-types";
import {
  ALL_ENUM_PREDICATE_OPS,
  EnumFieldPredicateOp,
  getEnumFieldPredicateOpName,
} from "features/field-predicates";
import { Setter, UiValidationError, usePropertySetter } from "features/utils";

export type EnumFieldPredicateState = {
  op: EnumFieldPredicateOp | null;
  variantIds: T.EnumVariantId[];
};

export function newEnumFieldPredicateState(
  valueType: T.FieldValueType.Enum,
): EnumFieldPredicateState {
  return {
    op: null,
    variantIds: [],
  };
}

export function convertEnumFieldPredicateToState(
  pred: T.EnumFieldPredicateKind,
): EnumFieldPredicateState {
  switch (pred.op) {
    case "is-blank":
    case "is-not-blank":
      return {
        op: pred.op,
        variantIds: [],
      };
    case "matches":
    case "does-not-match":
      return {
        op: pred.op,
        variantIds: pred.variantIds,
      };
  }
}

export function convertStateToEnumFieldPredicate(
  config: Configuration,
  valueType: T.FieldValueType.Enum,
  state: EnumFieldPredicateState,
): T.EnumFieldPredicateKind {
  if (!state.op) {
    throw new UiValidationError("Select an operator");
  }

  switch (state.op) {
    case "is-blank":
    case "is-not-blank":
      return {
        type: "generic",
        op: state.op,
      };
    case "matches":
    case "does-not-match":
      const enumType = config.enumTypesById.get(valueType.enumTypeId);

      if (!enumType) {
        throw new UiValidationError("Unknown enumeration type");
      }

      if (state.variantIds.length === 0) {
        throw new UiValidationError("Please select at least one option.");
      }

      if (
        state.variantIds.some((id) =>
          enumType.variants.every((v) => v.id !== id),
        )
      ) {
        throw new UiValidationError("Unknown enumeration variant selected");
      }

      return {
        type: "enum",
        op: state.op,
        enumTypeId: valueType.enumTypeId,
        variantIds: state.variantIds,
      };
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fields: {
      "& .MuiAutocomplete-root": {
        width: 270,
      },
      "& .MuiTextField-root": {
        width: 270,
        margin: theme.spacing(1, 0),
      },
    },
    hideClearIcon: {
      "& .MuiAutocomplete-clearIndicator": {
        display: "none",
      },
    },
  }),
);

export const EnumFieldPredicateEditor = React.memo(
  ({
    state,
    fieldId,
    setState,
    showValidationErrors,
    isExpandedView,
    environment,
    localFields,
    config,
  }: {
    state: EnumFieldPredicateState;
    fieldId: T.FieldId;
    setState: Setter<EnumFieldPredicateState>;
    showValidationErrors: boolean;
    isExpandedView: boolean;
    environment: ISet<T.FieldId>;
    localFields: T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
  }) => {
    const C = useStyles();

    const setOp = usePropertySetter(setState, "op");
    const setVariantIds = usePropertySetter(setState, "variantIds");

    const field = config.allFieldsById.get(fieldId);

    if (!field) {
      return <Box>Error: field not found</Box>;
    }

    const fieldValueType = field.valueType;

    if (fieldValueType.type !== "enum") {
      throw new Error("enum field predicate must point to an enum field");
    }

    const enumType = config.enumTypesById.get(fieldValueType.enumTypeId);

    if (!enumType) {
      return (
        <Box>
          Error: field {field.name} uses an unknown enumeration data type
        </Box>
      );
    }

    const allVariantIds = enumType.variants.map((v) => v.id);

    return (
      <div className={C.fields}>
        <SearchableDropdown
          label="Operation"
          options={ALL_ENUM_PREDICATE_OPS}
          getOptionLabel={getEnumFieldPredicateOpName}
          value={state.op}
          error={showValidationErrors && !state.op}
          setValue={setOp}
        />
        {state.op && opHasOperand(state.op) && (
          <Autocomplete<T.EnumVariantId, true>
            className={isExpandedView ? C.hideClearIcon : undefined}
            style={{
              width: "auto",
            }}
            multiple
            onChange={(event, values) => {
              setVariantIds(values);
            }}
            options={allVariantIds}
            disableCloseOnSelect
            value={state.variantIds}
            getOptionLabel={(variantId) =>
              enumType.variants.find((v) => v.id === variantId)?.name ||
              "<Unknown Enum Value>"
            }
            renderOption={(variantId, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {enumType.variants.find((v) => v.id === variantId)?.name ||
                  "<Unknown Enum Value>"}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  style={{ width: isExpandedView ? 900 : 270 }}
                  variant="outlined"
                  label={field.name}
                  placeholder={`Filter ${field.name}...`}
                  error={showValidationErrors && state.variantIds.length === 0}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: "8px",
                  }}
                >
                  {isExpandedView && (
                    <Button onClick={() => setVariantIds([])}>Clear All</Button>
                  )}

                  <Button onClick={() => setVariantIds(allVariantIds)}>
                    Select All
                  </Button>
                </div>
              </>
            )}
          />
        )}
      </div>
    );
  },
);

function opHasOperand(op: EnumFieldPredicateOp): boolean {
  switch (op) {
    case "is-blank":
    case "is-not-blank":
      return false;
    case "matches":
    case "does-not-match":
      return true;
  }
}
