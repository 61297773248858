import React from "react";
import { Box, Button } from "@material-ui/core";
import * as Api from "api";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import * as T from "types/engine-types";
import { SecondaryTitle } from "design/atoms/typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTableStyles } from "pages/object-patterns/create";
import { filterJoins } from "features/object-patterns";
import { localAccessId } from "features/access-id";
import { SearchableDropdown } from "design/molecules/dropdown";

interface State {
  clientId: string;
  investorId: string;
  patternId: string;
}

interface Props {
  joins: T.ObjectPatternInvestorJoin[];
  joinsSetter: (patterns: T.ObjectPatternInvestorJoin[]) => void;
  clients: T.Client[];
  investors: T.InvestorHeaderAugmented[];
  patterns: T.ObjectPattern[];
}

export const PatternInvestorsPage = React.memo(() => {
  const [joinsLoad, setJoinsLoad] = Api.Admin.useObjectPatternInvestorJoins();
  const [clientsLoad] = Api.Admin.useClients();
  const [investorsLoad] = Api.Admin.useInvestors();
  const [patternsLoad] = Api.Admin.useObjectPatterns();

  if (
    joinsLoad.status === "error" ||
    clientsLoad.status === "error" ||
    investorsLoad.status === "error" ||
    patternsLoad.status === "error"
  ) {
    return <>"Error"</>;
  }

  if (
    joinsLoad.status === "loading" ||
    clientsLoad.status === "loading" ||
    investorsLoad.status === "loading" ||
    patternsLoad.status === "loading"
  ) {
    return <Loader loaded={false} />;
  }

  return (
    <LoadedPatternInvestorsPage
      joins={joinsLoad.value}
      joinsSetter={setJoinsLoad}
      clients={clientsLoad.value}
      investors={investorsLoad.value}
      patterns={patternsLoad.value}
    />
  );
});

export class LoadedPatternInvestorsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // Read in ids from query paramaters
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get("clientId") || "";
    const investorId = urlParams.get("investorId") || "";
    const patternId = urlParams.get("patternId") || "";

    const state: Readonly<State> = {
      clientId,
      investorId,
      patternId,
    };
    this.state = state;

    this.handleChangeClientId = this.handleChangeClientId.bind(this);
    this.handleChangeInvestorId = this.handleChangeInvestorId.bind(this);
    this.handleChangePatternId = this.handleChangePatternId.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeClientId(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ clientId: event.target.value });
  }

  handleChangeInvestorId(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ investorId: event.target.value });
  }

  handleChangePatternId(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ patternId: event.target.value });
  }

  async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const { clientId, investorId, patternId } = this.state;
    const join: T.ObjectPatternInvestorJoin = {
      clientId: clientId as T.ClientId,
      investorId: investorId as T.InvestorId,
      objectPatternId: patternId as T.ObjectPatternId,
    };
    const returned = await Api.Admin.createObjectPatternInvestorJoin(join);
    this.props.joinsSetter([...this.props.joins, returned]);
  }
  render() {
    const joinCount = this.props.joins.length;
    const accessId: string = localAccessId() || "super";
    const filteredJoins = filterJoins(this.props.joins, this.state);
    const hydratedJoins = hydrateJoins(
      filteredJoins,
      this.props.clients,
      this.props.investors,
      this.props.patterns,
    );
    const selectedClient: T.Client | null =
      this.props.clients.find((c: T.Client) => c.id === this.state.clientId) ||
      null;
    const selectedInvestor: T.InvestorHeaderAugmented | null =
      this.props.investors.find(
        (p: T.InvestorHeaderAugmented) => p.id === this.state.investorId,
      ) || null;
    const selectedObjectPattern: T.ObjectPattern | null =
      this.props.patterns.find(
        (p: T.ObjectPattern) => p.id === this.state.patternId,
      ) || null;
    const generateDeleteFunction = (j: T.ObjectPatternInvestorJoin) => {
      return async () => {
        await Api.Admin.deleteObjectPatternInvestorJoin(j);
        this.props.joinsSetter(
          this.props.joins.filter(
            (join) =>
              join.objectPatternId !== j.objectPatternId ||
              join.investorId !== j.investorId ||
              join.clientId !== j.clientId,
          ),
        );
      };
    };
    return (
      <div>
        <Button
          component={Link}
          to={`/c/${accessId}/__admin/object-patterns`}
          variant="outlined"
          startIcon={<ArrowBackIcon />}
        >
          Back to Object Patterns
        </Button>
        <Box px={2} my={2}>
          <SecondaryTitle>Add a New Automation</SecondaryTitle>
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <Box my={2} style={{ width: "400px" }}>
              <SearchableDropdown<T.Client>
                label="Client"
                options={this.props.clients}
                getOptionLabel={(o: T.Client) => o.accessId}
                value={selectedClient}
                setValue={(o: T.Client | null) => {
                  this.setState({ clientId: o?.id || "" });
                }}
              />
            </Box>
            <Box my={2} style={{ width: "400px" }}>
              <SearchableDropdown<T.InvestorHeaderAugmented>
                label="Investor"
                options={this.props.investors.filter(
                  (o) => o.clientId === this.state.clientId,
                )}
                getOptionLabel={(o: T.InvestorHeaderAugmented) =>
                  `${o.code} [id=${o.id}]`
                }
                value={selectedInvestor}
                setValue={(o: T.InvestorHeaderAugmented | null) => {
                  this.setState({ investorId: o?.id || "" });
                }}
              />
            </Box>
            <Box my={2} style={{ width: "400px" }}>
              <SearchableDropdown<T.ObjectPattern>
                label="Object Pattern"
                options={this.props.patterns}
                getOptionLabel={(o: T.ObjectPattern) =>
                  `${o.name} [id=${o.id}]`
                }
                value={selectedObjectPattern}
                setValue={(o: T.ObjectPattern | null) => {
                  this.setState({ patternId: o?.id || "" });
                }}
              />
            </Box>
            <input type="submit" value="Submit" />
          </form>
        </Box>
        <Box px={2} my={2}>
          <SecondaryTitle>
            {joinCount} Matching Existing Automations
          </SecondaryTitle>
          <Table
            joins={hydratedJoins}
            generateDeleteFunction={generateDeleteFunction}
          />
        </Box>
      </div>
    );
  }
}

function hydrateJoins(
  joins: T.ObjectPatternInvestorJoin[],
  clients: T.Client[],
  investors: T.InvestorHeaderAugmented[],
  patterns: T.ObjectPattern[],
): HydratedJoin[] {
  return joins.map((j) => {
    const client = clients.filter((c) => c.id === j.clientId)[0];
    const investor = investors.filter((i) => i.id === j.investorId)[0];
    const pattern = patterns.filter((p) => p.id === j.objectPatternId)[0];
    return { investor, client, pattern };
  });
}

interface HydratedJoin {
  pattern: T.ObjectPattern;
  investor: T.InvestorHeader;
  client: T.Client;
}

interface TableProps {
  joins: HydratedJoin[];
  generateDeleteFunction: (j: T.ObjectPatternInvestorJoin) => () => void;
}

const Table = (props: TableProps) => {
  const C = useTableStyles();
  return (
    <table className={C.table}>
      <thead>
        <tr>
          <th className={C.columnLabel}>Client Name</th>
          <th className={C.columnLabel}>Client AccessId</th>
          <th className={C.columnLabel}>Client Id</th>
          <th className={C.columnLabel}>Investor Name</th>
          <th className={C.columnLabel}>Investor Code</th>
          <th className={C.columnLabel}>Investor Id</th>
          <th className={C.columnLabel}>Pattern Name</th>
          <th className={C.columnLabel}>Pattern Key Contains</th>
          <th className={C.columnLabel}>Pattern Key Contains[Optional]</th>
          <th className={C.columnLabel}>Pattern Id</th>
          <th className={C.columnLabel}></th>
        </tr>
      </thead>
      {props.joins.map(({ client, investor, pattern }) => (
        <tr>
          <td className={C.rowLabel}>{client.name}</td>
          <td className={C.rowLabel}>{client.accessId}</td>
          <td className={C.rowLabel}>{client.id}</td>
          <td className={C.field}>{investor.name} </td>
          <td className={C.field}>{investor.code} </td>
          <td className={C.field}>{investor.id} </td>
          <td className={C.field}>{pattern.name}</td>
          <td className={C.field}>{pattern.keyContains}</td>
          <td className={C.field}>{pattern.keyContainsOptional}</td>
          <td className={C.field}>{pattern.id}</td>
          <td className={C.field}>
            {" "}
            <Button
              style={{ marginRight: 16 }}
              // variant="outlined"
              startIcon={<DeleteIcon />}
              disabled={false}
              onClick={props.generateDeleteFunction({
                clientId: client.id,
                investorId: investor.id,
                objectPatternId: pattern.id,
              })}
            >
              Delete
            </Button>
          </td>
        </tr>
      ))}
    </table>
  );
};
