import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Map as IMap } from "immutable";
import { Box, TextField, IconButton } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import * as T from "types/engine-types";
import { getMessageNode } from "features/server-validation";
import {
  nonNullApplicationInitializationSelector,
  expandedConfigSelector,
  objectDetailsMapSelector,
  LoggedInInfo,
} from "features/application-initialization";
import {
  getRawFieldStateName,
  getEnumVariantIdentifier,
  getEnumTypeIdentifier,
} from "features/formulas-util";
import { Setter, resolveEnum, usePropertySetter2 } from "features/utils";
import {
  convertFieldValueTypeToState,
  FieldValueTypeDisplay,
} from "design/organisms/field-value-type-editor";
import {
  ObjectListEditor,
  newObjectListState,
} from "design/organisms/object-list-editor";
import { RefSourcesViewer } from "design/atoms/ref-sources-viewer";
import { SearchableDropdown } from "design/molecules/dropdown";
import {
  FieldDefinitionState,
  InheritedFieldDefinitionState,
  useObjectEditorStyles,
} from "pages/fields";
import { OptionalFieldConditionsEditor } from "../optional-field-conditions-editor";

function getFieldValueSummary(
  value: T.FieldValue,
  loggedInInfo: LoggedInInfo,
  enumTypes: T.RawEnumType[],
): string {
  switch (value.type) {
    case "enum":
      const clientEnum = enumTypes.find((e) => e.id === value.enumTypeId);
      const inheritableEnum = loggedInInfo.config.systemEnumTypesById.get(
        value.enumTypeId,
      );

      if (clientEnum) {
        const resolvedEnum = resolveEnum(
          clientEnum,
          loggedInInfo.config.systemEnumTypesById,
          loggedInInfo.client.displayNewInheritedEnumVariants,
        );
        const typeIdentifier = getEnumTypeIdentifier(resolvedEnum);
        const variant = resolvedEnum.variants.find(
          (v) => v.id === value.variantId,
        );
        const variantIdentifier = variant
          ? getEnumVariantIdentifier(variant)
          : "<unknown variant>";
        return `${typeIdentifier}.${variantIdentifier}`;
      } else if (inheritableEnum) {
        const typeIdentifier = getEnumTypeIdentifier(inheritableEnum);
        const variant = inheritableEnum.variants.find(
          (v) => v.id === value.variantId,
        );
        const variantIdentifier = variant
          ? getEnumVariantIdentifier(variant)
          : "<unknown variant>";
        return `(uninherited enum) ${typeIdentifier}.${variantIdentifier}`;
      }

      return "<unknown enumeration>";
    case "object-ref":
      let objectTypeName: string = value.objectRef.type;
      switch (value.objectRef.type) {
        case "pricing-profile":
          objectTypeName = "Pricing Profile";
          break;
      }
      return `${objectTypeName} with ID ${value.objectRef.id}`;
    case "string":
      return `"${value.value}"`;
    case "number":
      return `${value.value}`;
    case "duration":
      return `${value.count} ${value.unit}`;
    default:
      return "<unknown value>";
  }
}

function getFieldValueSummaries(
  values: T.FieldValue[],
  loggedInInfo: LoggedInInfo,
  enumTypes: T.RawEnumType[],
): string {
  const valueSummaries = values.map((v) =>
    getFieldValueSummary(v, loggedInInfo, enumTypes),
  );
  return `[${valueSummaries.join(", ")}]`;
}

function getInheritedFieldConditionSummary(
  loggedInInfo: LoggedInInfo,
  previousFields: FieldDefinitionState[],
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
  enumTypes: T.RawEnumType[],
  condition?: T.FieldCondition,
): JSX.Element {
  const parentFieldId = condition?.parentFieldId;
  const inheritableField = parentFieldId
    ? inheritableFields.get(parentFieldId)
    : null;
  const previousField = previousFields.find((pf) => pf.id === parentFieldId);

  let parentFieldName = "Parent field";
  if (previousField) {
    parentFieldName = getRawFieldStateName(
      previousField,
      loggedInInfo.config.allSystemFieldsById,
    );
  } else if (inheritableField) {
    parentFieldName = `(uninherited or incorrectly positioned) ${inheritableField.name}`;
  }

  switch (condition?.type) {
    case "parent-field-is-blank":
      return <>{parentFieldName} is blank</>;
    case "parent-field-is-not-blank":
      return <>{parentFieldName} is not blank</>;
    case "parent-field-has-value":
      return (
        <>
          {parentFieldName} has value &nbsp;
          {getFieldValueSummary(condition.value, loggedInInfo, enumTypes)}
        </>
      );
    case "parent-field-has-not-value":
      return (
        <>
          {parentFieldName} does not have value &nbsp;
          {getFieldValueSummary(condition.value, loggedInInfo, enumTypes)}
        </>
      );
    case "parent-field-is-one-of":
      return (
        <>
          {parentFieldName} has one of the values in &nbsp;
          {getFieldValueSummaries(condition.values, loggedInInfo, enumTypes)}
        </>
      );
    case "parent-field-is-not-one-of":
      return (
        <>
          {parentFieldName} has none of the values in &nbsp;
          {getFieldValueSummaries(condition.values, loggedInInfo, enumTypes)}
        </>
      );
    default:
      return <>{"<unknown condition type>"}</>;
  }
}

export const InheritedFieldEditor = React.memo(
  ({
    state,
    showErrors,
    setState,
    disableControls,
    previousFields,
    inheritableFields,
    enumTypes,
    fieldType,
  }: {
    state: InheritedFieldDefinitionState;
    showErrors: boolean;
    setState: Setter<InheritedFieldDefinitionState>;
    disableControls: boolean;
    previousFields: FieldDefinitionState[];
    inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>;
    enumTypes: T.RawEnumType[];
    fieldType: "credit-application" | "product" | "pipeline";
  }) => {
    const C = useObjectEditorStyles();
    const config = useSelector(expandedConfigSelector);
    const nonNullState = useSelector(nonNullApplicationInitializationSelector);
    const objectDetailsMap = useSelector(objectDetailsMapSelector);
    const loggedInInfo = useMemo(
      () => ({
        user: nonNullState.user,
        client: nonNullState.client,
        config: nonNullState.config,
        objectDetails: objectDetailsMap,
        notifications: nonNullState.notifications,
      }),
      [nonNullState, objectDetailsMap],
    );

    const setNameAlias = usePropertySetter2(
      setState,
      "disposition",
      "nameAlias",
    );
    const setDescriptionAlias = usePropertySetter2(
      setState,
      "disposition",
      "descriptionAlias",
    );
    const setIncludeConditions = usePropertySetter2(
      setState,
      "disposition",
      "includeConditions",
    );
    const setAdditionalConditions = usePropertySetter2(
      setState,
      "disposition",
      "additionalConditions",
    );

    const inheritedField = config.allSystemFieldsById.find(
      (e) => e.id === state.id,
    );

    const availableConditions =
      inheritedField?.conditions?.filter((inheritedCondition) => {
        if (
          !!state.disposition.includeConditions.objects.find(
            (includedCondition) =>
              includedCondition.id === inheritedCondition.id,
          )
        )
          return false;

        return true;
      }) || [];

    const makeIncludedFieldConditionEditor = useCallback(
      (
        { value, showErrors, index, setValue },
        inheritedField?: T.BaseFieldDefinition,
      ) => {
        return (
          <Box display="flex" flexDirection="column" height="100%">
            <Box>
              <TextField
                className={C.standardField}
                label="Condition ID"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                disabled={true}
                // This comment was generated when upgrading react-scripts and eslint
                // TODO: fix the lint rule and remove this eslint-disable comment
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                value={value.id}
                // This comment was generated when upgrading react-scripts and eslint
                // TODO: fix the lint rule and remove this eslint-disable comment
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                error={!!value.domainValidationErrors.id.length}
                // This comment was generated when upgrading react-scripts and eslint
                // TODO: fix the lint rule and remove this eslint-disable comment
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                helperText={getMessageNode(value.domainValidationErrors.id)}
              />
            </Box>
            {getInheritedFieldConditionSummary(
              loggedInInfo,
              previousFields,
              inheritableFields,
              enumTypes,
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              inheritedField?.conditions?.find((c) => c.id === value.id),
            )}
          </Box>
        );
      },
      [
        C.standardField,
        loggedInInfo,
        previousFields,
        enumTypes,
        inheritableFields,
      ],
    );

    const getInheritableOptions = (): T.ProductFieldDefinition[] => {
      switch (fieldType) {
        case "product":
          return [...loggedInInfo.config.systemProductFields];
          break;
        case "credit-application":
          return [...loggedInInfo.config.systemCreditApplicationFields];
          break;
        case "pipeline":
          return [...loggedInInfo.config.systemPipelineOnlyFields];
          break;
      }
      return [];
    };
    const inheritableOptions = getInheritableOptions();

    inheritableOptions.sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();

      if (aName > bName) return 1;
      else if (aName < bName) return -1;
      else return 0;
    });

    return (
      <Box>
        <Box display="flex" flexDirection="row">
          <SearchableDropdown<T.BaseFieldDefinition>
            className={C.standardField}
            label="Inherit from"
            value={inheritedField || null}
            options={inheritableOptions}
            getOptionLabel={(systemField) => systemField.name}
            setValue={(systemField) => {
              if (systemField) {
                setState({
                  ...state,
                  oldId: null,
                  id: systemField.id,
                  disposition: {
                    kind: "inherited",
                    nameAlias: null,
                    descriptionAlias: "",
                    includeConditions: newObjectListState([]),
                    additionalConditions: [],
                    domainValidationErrors: {
                      hasErrors: false,
                      nameAlias: [],
                      descriptionAlias: [],
                    },
                  },
                  domainValidationErrors: {
                    hasErrors: false,
                    id: [],
                  },
                });
              }
            }}
          />
          <TextField
            className={C.standardField}
            style={{ marginLeft: "24px" }}
            label="Field Name Alias"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder={inheritedField?.name}
            value={state.disposition.nameAlias || ""}
            error={!!state.disposition.domainValidationErrors.nameAlias.length}
            onChange={(e) =>
              setNameAlias(
                e.target.value?.trim() === "" ? null : e.target.value,
              )
            }
            helperText={getMessageNode(
              state.disposition.domainValidationErrors.nameAlias,
            )}
          />
        </Box>
        <Box>
          <TextField
            className={C.standardField}
            label="Field ID"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            disabled={true}
            value={state.id}
            error={!!state.domainValidationErrors.id.length}
            helperText={getMessageNode(state.domainValidationErrors.id)}
          />
        </Box>
        <Box>
          <TextField
            className={C.standardField}
            label="Field Description Alias"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder={inheritedField?.description || ""}
            value={state.disposition.descriptionAlias || ""}
            error={
              !!state.disposition.domainValidationErrors.descriptionAlias.length
            }
            onChange={(e) => setDescriptionAlias(e.target.value)}
            helperText={getMessageNode(
              state.disposition.domainValidationErrors.descriptionAlias,
            )}
          />
        </Box>

        {inheritedField && (
          <Box maxWidth="400px">
            <FieldValueTypeDisplay
              state={convertFieldValueTypeToState(inheritedField.valueType)}
              showErrors={showErrors}
              enumTypes={enumTypes}
              allowPricingProfiles={false}
            />
          </Box>
        )}
        {inheritedField?.valueType.type !== "header" &&
          fieldType !== "pipeline" && (
            <>
              <Box mb={2} fontSize="24px">
                Conditions
              </Box>
              <OptionalFieldConditionsEditor
                state={state.disposition.additionalConditions}
                showErrors={showErrors}
                setState={setAdditionalConditions}
                previousFields={previousFields}
                enumTypes={enumTypes}
              />
              <RefSourcesViewer
                objectId={{ type: "field", fieldId: state.id }}
                mt={2}
                style={{ marginBottom: "16px" }}
              />
              <Box flex="1" overflow="hidden">
                <h4>Included Conditions</h4>
                <ObjectListEditor
                  disableControls={disableControls}
                  height="250px"
                  state={state.disposition.includeConditions}
                  additionalButtons={[]}
                  getObjectLabel={(included) =>
                    getInheritedFieldConditionSummary(
                      loggedInInfo,
                      previousFields,
                      inheritableFields,
                      enumTypes,
                      inheritedField?.conditions?.find(
                        (c) => c.id === included.id,
                      ),
                    )
                  }
                  showErrors={showErrors}
                  makeObjectEditor={(args) =>
                    makeIncludedFieldConditionEditor(args, inheritedField)
                  }
                  setState={setIncludeConditions}
                  emptyText="Select a condition from &ldquo;Inheritable Conditions&rdquo; below to add it to this list"
                  // Do not sort, just keep the order in which they were added to the list
                  sortBy={(a, b) => 0}
                />

                <h4>Available Conditions</h4>
                <Box className={C.availableItemsList}>
                  {availableConditions.map((available, i) => {
                    return (
                      <div key={i} className={C.availableItemsListItem}>
                        {getInheritedFieldConditionSummary(
                          loggedInInfo,
                          previousFields,
                          inheritableFields,
                          enumTypes,
                          inheritedField?.conditions?.find(
                            (c) => c.id === available.id,
                          ),
                        )}
                        <Box className="buttonIcons">
                          <IconButton
                            title="Include this condition"
                            onClick={() =>
                              setIncludeConditions({
                                ...state.disposition.includeConditions,
                                objects: [
                                  ...state.disposition.includeConditions
                                    .objects,
                                  {
                                    id: available.id,
                                    domainValidationErrors: {
                                      hasErrors: false,
                                      id: [],
                                    },
                                  },
                                ],
                              })
                            }
                          >
                            <CheckCircleIcon />
                          </IconButton>
                        </Box>
                      </div>
                    );
                  })}
                </Box>
              </Box>
            </>
          )}
      </Box>
    );
  },
);
