import React, { useEffect, useState } from "react";
import * as T from "types/generated-types";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";
import {
  clientVendorIntegrationsStateSelector,
  listClientVendorIntegrations,
  saveClientVendorIntegration,
} from "features/client-vendor-integrations";

const EncompassSettingsPage = React.memo(() => {
  const dispatch = useDispatch();
  const clientVendorIntegrationsState = useSelector(
    clientVendorIntegrationsStateSelector,
  );

  const clientVendorIntegration =
    clientVendorIntegrationsState.clientVendorIntegrations.find(
      (cvi) => cvi.vendorIntegrationId === "encompass",
    );

  const [isActive, setIsActive] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);

  const isSaved = clientVendorIntegration?.isActive === isActive;

  useEffect(() => {
    if (clientVendorIntegrationsState.status.kind === "not-loaded") {
      dispatch(listClientVendorIntegrations());
    }
  }, [dispatch, clientVendorIntegrationsState.status.kind]);

  useEffect(() => {
    if (clientVendorIntegration) {
      setIsActive(clientVendorIntegration.isActive);
    }
  }, [clientVendorIntegration]);

  const saveIcon = isSaving ? (
    <CircularProgress size="1em" />
  ) : isSaved ? (
    <CheckIcon />
  ) : null;

  return (
    <Box
      style={{
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FormControlLabel
        style={{ width: 500 }}
        control={
          <Switch
            checked={isActive}
            onChange={(_e, checked) => setIsActive(checked)}
            color="primary"
          />
        }
        label={isActive ? "Integration is active" : "Integration is inactive"}
      />

      <Box style={{ marginTop: "4em" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={saveIcon}
          disabled={isSaved || isSaving}
          onClick={async () => {
            setIsSaving(true);
            await Promise.all([
              dispatch(
                saveClientVendorIntegration({
                  vendorIntegrationId: "encompass" as T.VendorIntegrationId,
                  update: {
                    isActive,
                    settings: { type: "encompass" },
                  } as T.ClientVendorIntegrationUpdate,
                }),
              ),
            ]);
            setIsSaving(false);
          }}
        >
          {isSaved ? "Saved" : isSaving ? "Saving" : "Save"}
        </Button>
      </Box>
    </Box>
  );
});

export default EncompassSettingsPage;
