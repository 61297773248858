import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import transitions from "design/subatomics/transitions";
import opacities from "design/subatomics/opacities";
import color from "design/subatomics/colors";

export const ProductResults = styled.section`
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  transition: flex-basis ${transitions.default};
  overflow: hidden;

  &.loading {
    opacity: ${opacities.translucent};
  }

  ${breakpoints.mobile} {
    &.closed {
      flex-basis: 0%;
    }

    &.open {
      flex-basis: 100%;
    }

    &.force-closed {
      flex-basis: 0%;
    }
  }

  ${breakpoints.desktop} {
    &.closed {
      flex-basis: 0%;
    }

    &.open {
      flex-basis: calc(100vw - 20%);
    }

    &.force-closed {
      flex-basis: 0%;
    }
  }

  ${breakpoints.largeDesktop} {
    &.closed {
      flex-basis: 60%;
      border-right: 1px solid ${color({ color: "border" })};
    }

    &.force-closed {
      flex-basis: 0%;
    }
  }
`;

export const AccordionWrapper = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;

  .molecule-accordion {
    .page-loan-pricing-component-product-result {
      &:last-child {
        border-bottom: none;
      }
    }
  }

  ${breakpoints.mobile} {
  }

  ${breakpoints.desktop} {
  }
`;
