const opacities = {
  opaque: 1,
  nearlyOpaque: 0.85,
  lessTranslucent: 0.65,
  translucent: 0.5,
  moreTranslucent: 0.375,
  nearlyTransparent: 0.15,
  transparent: 0,
};

export default opacities;
