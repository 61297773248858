import React, { useState } from "react";
import { styles } from "design/atoms/typography";
import whitespace from "design/subatomics/whitespace";
import colors from "design/subatomics/colors";
import { Wrapper, Label, InputWrapper, Prefix, Suffix, Input } from "./styles";

function TextInput({
  label,
  hint,
  error,
  inputProps,
  isRequired = false,
  adornment,
  adornmentText,
  className,
  placeholderText,
  width,
}: {
  label?: string;
  hint?: string;
  error?: string;
  inputProps?: unknown;
  isRequired?: boolean;
  adornment?: "prefix" | "suffix" | null;
  adornmentText?: string | null;
  className?: string;
  placeholderText?: string;
  width?: string;
}) {
  const [adornmentState, setAdornment] = useState(false);

  return (
    <Wrapper
      style={{ width: width ?? undefined }}
      className={`${className ? className : ""} `}
    >
      {label && (
        <Label className="label">
          {label}
          {isRequired && (
            <strong
              title="This field is required"
              style={{
                display: "inline-block",
                marginLeft: whitespace("least"),
                color: colors({ color: "red" }),
              }}
            >
              *
            </strong>
          )}
        </Label>
      )}
      <InputWrapper>
        {adornment === "prefix" && (
          <Prefix
            className={adornmentState ? "prefix-span focused" : "prefix-span"}
          >
            {adornmentText}
          </Prefix>
        )}
        <Wrapper>
          <Input
            autoComplete="off"
            className={`${error ? "with-error" : "without-error"} ${
              adornment ? adornment : ""
            }  `}
            type="search"
            placeholder={placeholderText ? placeholderText : ""}
            onFocus={() => setAdornment(true)}
            onBlur={() => setAdornment(false)}
            {...inputProps}
          />
        </Wrapper>
        {adornment === "suffix" && (
          <Suffix
            className={adornmentState ? "suffix-span focused" : "suffix-span"}
          >
            {adornmentText}
          </Suffix>
        )}
      </InputWrapper>
      {hint && !error && (
        <div
          className="hint"
          style={{
            ...styles.smallText,
            color: colors({ color: "gray", shade: 6 }),
            marginTop: whitespace("least"),
            marginLeft: whitespace("less"),
          }}
        >
          {hint}
        </div>
      )}
      {error && (
        <div
          className="error"
          style={{
            ...styles.smallText,
            color: colors({ color: "red" }),
            marginTop: whitespace("least"),
            marginLeft: whitespace("less"),
          }}
        >
          {error}
        </div>
      )}
    </Wrapper>
  );
}

export default TextInput;
