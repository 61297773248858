import styled from "styled-components";
export const ExportWrapper = styled.div`
  @media print {
    img {
      display: flex;
    }
    @page {
      size: auto;
      margin: 8mm 10mm 9mm 10mm;
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-top: 5px;

  img {
    display: none;
  }

  @media print {
    img {
      display: flex;
    }
  }
`;

export const PriceScenarioTableWrapper = styled.div`
  margin: 12px;
  @media print {
    overflow-x: hidden;
  }
`;
