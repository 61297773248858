import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  useTheme,
  withStyles,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import * as Api from "api";
import {
  newObjectListState,
  ObjectListEditor,
} from "design/organisms/object-list-editor";
import { Setter, usePropertySetter } from "features/utils";
import SplitPane from "react-split-pane";
import {
  createCodeContextStateSelector,
  createGetCodeContextThunk,
} from "features/code-contexts";
import {
  createMappingsByTargetStateSelector,
  createGetFieldMappingsThunk,
  MappingPurpose,
} from "features/field-mappings";
import {
  createTargetTypesStateSelector,
  createListTargetTypesThunk,
} from "features/target-types";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CheckIcon from "@material-ui/icons/Check";
import CircleIcon from "@material-ui/icons/Lens";
import { useDispatch, useSelector } from "react-redux";
import * as T from "types/engine-types";
import UnloadPrompt from "design/atoms/unload-prompt";
import { ApiError } from "api";
import Alert from "@material-ui/lab/Alert";
import { CodeContext } from "types/engine-types";

const LoanScriptEditor = React.lazy(
  () => import("design/organisms/loanscript-editor"),
);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabledMapping: {
      color: theme.palette.text.disabled,
    },
    loadingIndicator: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      flexDirection: "column",
      backgroundColor: "#f8f8f8",
      textAlign: "center",
      height: "100%",
      color: theme.palette.success.main,
      ...theme.typography.button,
    },
    objectEditor: {
      overflow: "auto",
      scrollbarWidth: "thin",
      height: "100%",
    },
    codeEditor: {
      border: "none",
      height: "100%",
      width: "100%",
      overflow: "hidden",
    },
    helpIcon: {
      fontSize: "1rem",
      marginLeft: "0.5em",
      color: theme.palette.info.main,
      display: "inline-block",
      marginBottom: "-2px",
    },
    errorAlert: {
      marginRight: "2em",
    },
    resizer: {
      width: "5px",
      height: "100%",
      cursor: "col-resize",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      },
    },
    contextExplorer: {
      width: "100%",
      height: "100%",
      overflow: "scroll",
      padding: "5px",
    },
    typeInfo: {
      color: "#999",
    },
    valueInfo: {
      color: "#999",
    },
    propertyInfo: {
      marginLeft: "3em",
    },
  }),
);

type MappingState = {
  id: T.FieldId;
  name: string;
  isActive: boolean;
  code: string;
  expectedOutputType: T.LsType;
  isSaving: boolean;
  useSystemMapping: boolean;
  systemMappingExists: boolean;
  systemMappingCode: string;
  systemMappingIsActive: boolean;
};

function getObjectListLabel(
  state: MappingState,
  isSaved: boolean,
  disabledColor: string,
): JSX.Element {
  return (
    <div style={{ color: state.isActive ? undefined : disabledColor }}>
      {!isSaved ? (
        <CircleIcon
          titleAccess="This mapping has unsaved changes"
          style={{ fontSize: "0.8em", color: "orange", marginRight: "0.7em" }}
        />
      ) : (
        ""
      )}
      {state.name}
    </div>
  );
}

const CodeEditor = React.memo(
  ({
    value,
    setValue,
    isSaved,
    setSaved,
    mappingPurpose,
  }: {
    value: MappingState;
    setValue: Setter<MappingState>;
    isSaved: boolean;
    setSaved: (mappingState: MappingState) => void;
    mappingPurpose: MappingPurpose;
  }) => {
    const [codeContextSelector, getCodeContext] = useMemo(() => {
      return [
        createCodeContextStateSelector(mappingPurpose),
        createGetCodeContextThunk(mappingPurpose),
      ];
    }, [mappingPurpose]);

    const classes = useStyles();
    const dispatch = useDispatch();
    const codeContextState = useSelector(codeContextSelector);

    const setIsActive = usePropertySetter(setValue, "isActive");
    const setCode = usePropertySetter(setValue, "code");
    const setUseSystemMapping = usePropertySetter(setValue, "useSystemMapping");

    const [codeTab, setCodeTab] = useState("custom");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
      setCodeTab(value.useSystemMapping ? "system" : "custom");
    }, [setCodeTab, value.useSystemMapping]);

    useEffect(() => {
      if (codeContextState.status.kind === "not-loaded") {
        dispatch(getCodeContext());
      }
    }, [dispatch, codeContextState.status.kind, getCodeContext]);

    const codeContext = useMemo(() => {
      if (codeContextState.codeContext !== null) {
        return {
          ...codeContextState.codeContext,
          expectedOutputType: { ...value.expectedOutputType },
        };
      } else {
        return null;
      }
    }, [codeContextState.codeContext, value.expectedOutputType]);

    const saveMapping = async () => {
      setErrorMessage(null);
      setValue({ ...value, isSaving: true });
      try {
        await Api.saveFieldMapping(mappingPurpose, {
          target: value.id,
          isActive: value.isActive,
          useSystemMapping: value.useSystemMapping,
          code: value.code,
        });
        setSaved(value);
      } catch (err) {
        if (err instanceof ApiError) {
          setErrorMessage(err.message);
        } else {
          setErrorMessage("An unexpected error occurred.");
        }
      } finally {
        setValue({ ...value, isSaving: false });
      }
    };

    const saveIcon = value.isSaving ? (
      <CircularProgress size="1em" />
    ) : isSaved ? (
      <CheckIcon />
    ) : null;

    const errorAlert = (
      <Alert severity="error" className={classes.errorAlert}>
        {errorMessage}
      </Alert>
    );

    return (
      <Box
        className={classes.objectEditor}
        display="flex"
        flexDirection="column"
      >
        <Box mt={2}>
          <Box
            marginLeft={1.5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={value.isActive}
                      onChange={(_, checked) => setIsActive(checked)}
                      color="primary"
                    />
                  }
                  label={
                    value.isActive ? "Mapping Enabled" : "Mapping Disabled"
                  }
                />
              </Grid>
              <Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid>{!!errorMessage && errorAlert}</Grid>
                  <Grid>
                    <Button
                      variant="contained"
                      startIcon={saveIcon}
                      color="primary"
                      onClick={saveMapping}
                      disabled={isSaved || value.isSaving}
                    >
                      {isSaved ? "Saved" : value.isSaving ? "Saving" : "Save"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Tabs
          value={codeTab}
          onChange={(_, tabId: string) => setCodeTab(tabId)}
        >
          <Tab
            label={
              <Box>
                <Checkbox
                  checked={!value.useSystemMapping}
                  onChange={(_event, checked) => setUseSystemMapping(!checked)}
                />
                Custom mapping
                <HtmlTooltip
                  title={
                    <>
                      <Typography color="inherit">
                        Custom field mapping
                      </Typography>
                      This is a mapping that you write yourself. If there is a
                      system mapping available for this field and you wish to
                      use a modified version of it, you can copy its code from
                      the System Mapping tab and paste it here, then make your
                      modifications.
                    </>
                  }
                >
                  <HelpIcon className={classes.helpIcon} />
                </HtmlTooltip>
              </Box>
            }
            value={"custom"}
          />
          <Tab
            disabled={
              !(value.systemMappingExists && value.systemMappingIsActive)
            }
            label={
              <Box>
                <Checkbox
                  checked={value.useSystemMapping}
                  onChange={(_event, checked) => setUseSystemMapping(checked)}
                />
                System mapping
                <HtmlTooltip
                  title={
                    <>
                      <Typography color="inherit">
                        System field mapping
                      </Typography>
                      This is a field mapping defined by LoanPASS. It cannot be
                      modified.
                    </>
                  }
                >
                  <HelpIcon className={classes.helpIcon} />
                </HtmlTooltip>
              </Box>
            }
            value={"system"}
          />
        </Tabs>
        <Box
          className={classes.codeEditor}
          mt={2}
          style={{ position: "relative" }}
        >
          <Suspense
            fallback={<LoadingIndicator message={"Loading Loanscript..."} />}
          >
            {codeContext ? (
              <SplitPane
                split="vertical"
                defaultSize="75%" // The default width of the first/left pane (the code editor)
                minSize={500} // The minimum size in pixels of the editor pane
                maxSize="90%"
                resizerClassName={classes.resizer}
                style={{ height: "100%", width: "100%" }}
                pane1Style={{
                  border: "1px solid rgba(0, 0, 0, 0.87)",
                  height: "100%",
                }}
                pane2Style={{
                  border: "1px solid rgba(0, 0, 0, 0.87)",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <LoanScriptEditor
                  value={
                    codeTab === "custom" ? value.code : value.systemMappingCode
                  }
                  isReadOnly={codeTab === "system"}
                  onChange={(code) => {
                    if (codeTab === "custom") {
                      setCode(code);
                    }
                  }}
                  editorContext={codeContext}
                />
                <ContextExplorer codeContext={codeContext} />
              </SplitPane>
            ) : (
              <LoadingIndicator message={"Loading environment..."} />
            )}
          </Suspense>
        </Box>
      </Box>
    );
  },
);

export const TypeInfo = React.memo(({ typ }: { typ: T.LsType }) => {
  const classes = useStyles();
  return isDefinedType(typ) ? (
    <DefinedTypeInfo typ={typ.defined} />
  ) : (
    <span className={classes.typeInfo}>deferred</span>
  );
});

export const DefinedTypeInfo = React.memo(
  ({ typ }: { typ: T.LsDefinedType }) => {
    const classes = useStyles();

    return (
      <span className={classes.typeInfo}>
        {(() => {
          switch (typ.kind) {
            case "type-ref":
              return "type reference";
            case "none":
              return "none";
            case "blank":
              return "blank";
            case "num":
              return "number";
            case "str":
              return "text";
            case "bool":
              return "boolean";
            case "day-duration":
              return "day duration";
            case "month-duration":
              return "month duration";
            case "date":
              return "date";
            case "function":
              return (
                <>
                  (
                  {typ.args.map((arg) => (
                    <>
                      <TypeInfo typ={arg[1]} /> {arg[0]}
                    </>
                  ))}
                  ) -&gt;&nbsp;
                  <TypeInfo typ={typ.output} />
                </>
              );
            case "object-instance":
              return typ.typeName;
            case "enum-variant":
              return typ.typeName;
            case "list":
              if (typ.itemType.kind === "any-of") {
                return (
                  <>
                    list of (<DefinedTypeInfo typ={typ.itemType} />)
                  </>
                );
              } else {
                return (
                  <>
                    list of <DefinedTypeInfo typ={typ.itemType} />
                  </>
                );
              }
            case "any":
              return "anything";
            case "any-of":
              if (typ.types.length === 0) {
                return <DefinedTypeInfo typ={{ kind: "none" }} />;
              } else if (typ.types.length === 1) {
                return <DefinedTypeInfo typ={typ.types[0]} />;
              } else if (typ.types.length === 2) {
                return (
                  <>
                    <DefinedTypeInfo typ={typ.types[0]} /> or{" "}
                    <DefinedTypeInfo typ={typ.types[1]} />
                  </>
                );
              } else {
                return (
                  <>
                    {typ.types.slice(0, -1).map((typ) => (
                      <>
                        <DefinedTypeInfo typ={typ} />
                        ,&nbsp;
                      </>
                    ))}{" "}
                    or <DefinedTypeInfo typ={typ.types[typ.types.length - 1]} />
                  </>
                );
              }
          }
        })()}
      </span>
    );
  },
);

export const ObjectInfo = React.memo(
  ({ name, obj }: { name: string; obj: T.LsObjectType }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    const props = useMemo(
      () => Object.entries(obj.map).sort((a, b) => a[0].localeCompare(b[0])),
      [obj.map],
    );

    return (
      <div>
        <span style={{ whiteSpace: "nowrap" }}>
          {props.length > 0 ? (
            <span onClick={() => setExpanded(!expanded)}>
              {expanded ? (
                <ArrowDropDownIcon
                  style={{ marginBottom: "-0.4rem", cursor: "pointer" }}
                />
              ) : (
                <ArrowRightIcon
                  style={{ marginBottom: "-0.4rem", cursor: "pointer" }}
                />
              )}
            </span>
          ) : (
            <ArrowRightIcon
              style={{ marginBottom: "-0.4rem", color: "#999" }}
            />
          )}
          {name}
        </span>
        <span
          style={{ marginLeft: "0.5rem", color: "#999", fontSize: "0.7rem" }}
        >
          {props.length}
          &nbsp;
          {props.length === 1 ? "property" : "properties"}
        </span>
        {expanded
          ? props.map((entry) => {
              return (
                <div className={classes.propertyInfo}>
                  {entry[0]}
                  &nbsp;
                  <TypeInfo typ={entry[1].typ} />
                </div>
              );
            })
          : null}
      </div>
    );
  },
);

export const EnumInfo = React.memo(
  ({ name, enumeration }: { name: string; enumeration: T.LsEnumType }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    const variants = useMemo(
      () =>
        Object.entries(enumeration.map).sort((a, b) =>
          a[0].localeCompare(b[0]),
        ),
      [enumeration.map],
    );

    return (
      <div>
        <span style={{ whiteSpace: "nowrap" }}>
          {variants.length > 0 ? (
            <span onClick={() => setExpanded(!expanded)}>
              {expanded ? (
                <ArrowDropDownIcon
                  style={{ marginBottom: "-0.4rem", cursor: "pointer" }}
                />
              ) : (
                <ArrowRightIcon
                  style={{ marginBottom: "-0.4rem", cursor: "pointer" }}
                />
              )}
            </span>
          ) : (
            <ArrowRightIcon
              style={{ marginBottom: "-0.4rem", color: "#999" }}
            />
          )}
          {name}
        </span>
        <span
          style={{ marginLeft: "0.5rem", color: "#999", fontSize: "0.7rem" }}
        >
          {variants.length}
          &nbsp;
          {variants.length === 1 ? "variant" : "variants"}
        </span>
        {expanded
          ? variants.map((entry) => {
              return (
                <div className={classes.propertyInfo}>
                  {entry[0]}
                  &nbsp;
                  <span style={{ color: "#999" }}>"{entry[1]}"</span>
                </div>
              );
            })
          : null}
      </div>
    );
  },
);

export const VariableInfo = React.memo(
  ({ name, typ }: { name: string; typ: T.LsDefinedType }) => {
    return <div>{name}</div>;
  },
);

function isDefinedType(
  typ: T.LsType,
): typ is { kind: "defined"; defined: T.LsDefinedType } {
  return typ.kind === "defined";
}

export const ContextExplorer = React.memo(
  ({ codeContext }: { codeContext: CodeContext }) => {
    const classes = useStyles();

    const definedScopeVars: { [name: string]: T.LsDefinedType } = {};

    for (const entry of Object.entries(codeContext.scopeVariables.map).sort(
      (a, b) => a[0].localeCompare(b[0]),
    )) {
      if (isDefinedType(entry[1].typ)) {
        definedScopeVars[entry[0]] = entry[1].typ.defined;
      }
    }

    return (
      <div className={classes.contextExplorer}>
        <h4>Variables</h4>
        <div>
          {Object.entries(definedScopeVars)
            .filter((entry) => entry[1].kind !== "function")
            .map((entry) => {
              return (
                <div>
                  {entry[0]} <DefinedTypeInfo typ={entry[1]} />
                </div>
              );
            })}
        </div>
        <h4>Functions</h4>
        <div>
          {Object.entries(definedScopeVars)
            .filter((entry) => entry[1].kind === "function")
            .map((entry) => {
              return (
                <div>
                  {entry[0]} <DefinedTypeInfo typ={entry[1]} />
                </div>
              );
            })}
        </div>
        <h4>Object Types</h4>
        <div>
          {Object.entries(codeContext.objectTypes)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map((entry) => {
              return <ObjectInfo name={entry[0]} obj={entry[1]} />;
            })}
        </div>
        <h4>Enum Types</h4>
        <div>
          {Object.entries(codeContext.enumTypes)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map((entry) => {
              return <EnumInfo name={entry[0]} enumeration={entry[1]} />;
            })}
        </div>
      </div>
    );
  },
);

const makeCodeEditor = ({
  value,
  setValue,
  isSaved,
  setSaved,
  mappingPurpose,
}: {
  value: MappingState;
  setValue: Setter<MappingState>;
  isSaved: boolean;
  setSaved: (mappingState: MappingState) => void;
  mappingPurpose: MappingPurpose;
}) => {
  return (
    <CodeEditor
      value={value}
      isSaved={isSaved}
      setSaved={(mapping) => setSaved(mapping)}
      setValue={setValue}
      mappingPurpose={mappingPurpose}
    />
  );
};

function storedMappingsToState(
  mappings: T.CodeOnlyFieldMappings,
  targetTypes: { [key: string]: T.LsType },
  targets: MappingTarget[],
): MappingState[] {
  return targets.map((target) => {
    const customMapping: T.CodeOnlyFieldMapping | undefined =
      mappings.custom[target.id];

    const systemMapping: T.CodeOnlyFieldMapping | undefined =
      mappings.system[target.id];

    const systemMappingExists = systemMapping !== undefined;
    const systemMappingCode = systemMappingExists ? systemMapping.code : "";
    const systemMappingIsActive = systemMappingExists
      ? systemMapping.isActive
      : false;

    if (customMapping) {
      return {
        id: customMapping.target as T.FieldId,
        isActive: customMapping.isActive,
        name: target.name,
        code: customMapping.code,
        expectedOutputType: targetTypes[target.id],
        isSaving: false,
        useSystemMapping: customMapping.useSystemMapping,
        systemMappingExists,
        systemMappingCode,
        systemMappingIsActive,
      } as MappingState;
    } else {
      return {
        id: target.id as T.FieldId,
        isActive: false,
        name: target.name,
        code: `// Map data to ${target.name}\n\nblank`,
        expectedOutputType: targetTypes[target.id],
        isSaving: false,
        useSystemMapping: false,
        systemMappingExists,
        systemMappingCode,
        systemMappingIsActive,
      } as MappingState;
    }
  });
}

function mappingStateIsSaved(
  storedMappingState: MappingState,
  currentMappingState: MappingState,
): boolean {
  return (
    storedMappingState.code === currentMappingState.code &&
    storedMappingState.isActive === currentMappingState.isActive &&
    storedMappingState.useSystemMapping === currentMappingState.useSystemMapping
  );
}

function mappingStatesAreSaved(
  storedMappingStates: readonly MappingState[],
  currentMappingStates: readonly MappingState[],
): boolean {
  if (storedMappingStates.length !== currentMappingStates.length) {
    return false;
  }

  for (let i = 0; i < storedMappingStates.length; ++i) {
    if (!mappingStateIsSaved(storedMappingStates[i], currentMappingStates[i])) {
      return false;
    }
  }

  return true;
}

export type MappingTarget = {
  id: string;
  name: string;
};

export const MappingEditor = React.memo(
  ({
    mappingPurpose,
    targets,
  }: {
    mappingPurpose: MappingPurpose;
    targets: MappingTarget[] | null;
  }) => {
    const [
      fieldMappingsSelector,
      getFieldMappings,
      targetTypesSelector,
      listTargetTypes,
    ] = useMemo(() => {
      return [
        createMappingsByTargetStateSelector(mappingPurpose),
        createGetFieldMappingsThunk(mappingPurpose),
        createTargetTypesStateSelector(mappingPurpose),
        createListTargetTypesThunk(mappingPurpose),
      ];
    }, [mappingPurpose]);

    const theme = useTheme();
    const dispatch = useDispatch();
    const mappingsByTargetState = useSelector(fieldMappingsSelector);
    const targetTypesState = useSelector(targetTypesSelector);
    const [mappingsState, setMappingState] = useState(() => {
      return newObjectListState([] as MappingState[]);
    });

    const [storedMappingsState, setStoredMappingsState] = useState<
      MappingState[] | null
    >(null);

    const anyUnsaved = storedMappingsState
      ? !mappingStatesAreSaved(storedMappingsState, mappingsState.objects)
      : false;

    useEffect(() => {
      dispatch(getFieldMappings());
    }, [dispatch, getFieldMappings]);

    useEffect(() => {
      if (targetTypesState.status.kind === "not-loaded")
        dispatch(listTargetTypes());
    }, [dispatch, targetTypesState.status.kind, listTargetTypes]);

    useEffect(() => {
      if (targetTypesState.status.kind === "loaded") {
        // When the mappings are loaded, create the initial state objects for them
        const mappings = mappingsByTargetState.mappingsByTarget;
        const targetTypes = targetTypesState.targetTypes;

        if (mappings !== null && targetTypes !== null) {
          const storedMappings = storedMappingsToState(
            mappings,
            targetTypes,
            targets || [],
          );

          setStoredMappingsState(storedMappings);

          setMappingState({
            objects: storedMappings,
            selectedIndex: null,
          });
        }
      }
    }, [
      dispatch,
      targets,
      targetTypesState.status.kind,
      mappingsByTargetState.mappingsByTarget,
      targetTypesState.targetTypes,
    ]);

    const isSaved = useCallback(
      (mapping: MappingState) => {
        const storedMapping = storedMappingsState?.find(
          (storedMapping) => storedMapping.id === mapping.id,
        );
        return !!storedMapping && mappingStateIsSaved(storedMapping, mapping);
      },
      [storedMappingsState],
    );

    const storedMappingsStateRef = useRef<MappingState[] | null>();
    storedMappingsStateRef.current = storedMappingsState;

    const setSaved = useCallback((mapping: MappingState) => {
      if (storedMappingsStateRef.current !== null) {
        const newStoredMappings = storedMappingsStateRef.current!.map(
          (storedMapping) => {
            if (storedMapping.id === mapping.id) {
              return mapping;
            } else {
              return storedMapping;
            }
          },
        );

        setStoredMappingsState(newStoredMappings);
      }
    }, []);

    return (
      <>
        <UnloadPrompt when={anyUnsaved} />
        <React.StrictMode>
          <Box m={3} flex="1">
            {mappingsByTargetState.mappingsByTarget !== null ? (
              <ObjectListEditor
                disableControls={false}
                height="100%"
                state={mappingsState}
                makeObjectEditor={(props) =>
                  makeCodeEditor({
                    isSaved: isSaved(props.value),
                    setSaved: (mapping) => setSaved(mapping),
                    mappingPurpose,
                    value: props.value,
                    setValue: props.setValue,
                  })
                }
                additionalButtons={[]}
                allowDelete={false}
                allowDuplicate={false}
                emptyText={
                  targets !== null
                    ? "You don't have anything to create mappings for."
                    : "Loading mapping targets..."
                }
                getObjectLabel={(obj) =>
                  getObjectListLabel(
                    obj,
                    isSaved(obj),
                    theme.palette.text.disabled,
                  )
                }
                setState={setMappingState}
                // This sort function only exists to disable drag-and-drop reordering
                sortBy={() => 0}
              />
            ) : (
              "Loading mapping targets..."
            )}
          </Box>
        </React.StrictMode>
      </>
    );
  },
);

const LoadingIndicator = React.memo(({ message }: { message: string }) => {
  const classes = useStyles();

  return <div className={classes.loadingIndicator}>{message}</div>;
});

export default MappingEditor;
