import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Box,
  Button,
  TextField as MaterialTextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DetailActions from "design/molecules/detail-actions";
import DetailHeader from "design/molecules/detail-header";
import NextButton from "design/atoms/next-button";
import PrevButton from "design/atoms/prev-button";
import CopyIcon from "@material-ui/icons/FileCopy";
import PermissionsList from "../_components/permissions-list";
import { alwaysPresentPermissions } from "../_components/permissions-list";
import UsersList from "../_components/users-list";
import FieldsList from "../_components/fields-list";
import DefaultValuesList from "../_components/default-values-list";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import * as T from "types/engine-types";
import { fieldBuckets } from "config";
import { usersSelector, usersLoadingSelector, getUsers } from "features/users";
import {
  rolesSelector,
  filteredRolesSelector,
  getRoles,
  deleteRoleById,
  createRole,
  updateRole,
  allPerms,
  configurationView,
  usePermissions,
} from "features/roles";
import { useAppDispatch } from "features/store";
import * as Api from "api";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

type Params = {
  id: T.RoleId;
};

export default function ViewRolePage() {
  const { id } = useParams<Params>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    config,
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);
  const roles = useSelector(filteredRolesSelector);
  const role = roles.find((r) => r.id === id);
  const [editMode, setEditMode] = useState(false);
  const [copyMode, setCopyMode] = useState(false);
  const usersState = useSelector(usersSelector);
  const adminId = roles.find((r) => r?.name === "Admin")?.id;
  const [name, setName] = useState<string>(
    (copyMode ? role?.name : `${role?.name || ""} Copy`) || "",
  );
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [cancelConfirmOpen, setCancelConfirmOpen] = useState<boolean>(false);
  const [perms, setPerms] = useState<T.PermissionKind[]>(
    _.without(role?.permissions, configurationView) || [],
  );

  const [fieldPermIds, setFieldPermIds] = useState<T.FieldId[]>(
    role?.pricingVisibleFieldIds || [],
  );
  const { myRole } = useSelector(nonNullApplicationInitializationSelector);

  const allFieldPermissions = config && fieldBuckets(config);
  const hasPermission = usePermissions();
  const hasCreatePerm = hasPermission("roles-create");
  const hasEditPerm = hasPermission("roles-edit");
  const hasDeletePerm = hasPermission("roles-delete");
  const rolesState = useSelector(rolesSelector);
  const { errors, loading } = rolesState;
  const flatFieldPermissionIds: T.FieldId[] = [];
  const [usersToAdd, setUsersToAdd] = useState<T.DetailedUser[]>([]);
  const positionInList = role && roles.indexOf(role) + 1;
  const [defaultValues, setDefaultValues] = useState<
    T.DefaultFieldValue[] | null
  >(null);
  const usersLoading = useSelector(usersLoadingSelector);

  useEffect(() => {
    Api.getRoleDefaultValues(id).then(
      (defaultRoleValues: T.DefaultFieldValue[]) => {
        setDefaultValues(defaultRoleValues);
      },
    );
  }, [id]);

  allFieldPermissions?.forEach((g) => {
    g[1].forEach((p) => flatFieldPermissionIds.push(p.id));
  });

  useEffect(() => {
    if (!roles || roles.length === 0) dispatch(getRoles());
  }, [dispatch, roles, roles.length]);

  useEffect(() => {
    role?.name && setName(role.name);
    role?.permissions && setPerms(role.permissions);
    role?.pricingVisibleFieldIds &&
      setFieldPermIds(role?.pricingVisibleFieldIds);
    copyMode && setName(`${role?.name || ""} Copy`);
    copyMode && role?.permissions && setPerms(role.permissions);
  }, [role, copyMode, editMode]);

  useEffect(() => {
    if (!usersLoading) dispatch(getUsers());
  }, [dispatch, usersLoading]);

  if (loading) {
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert>Loading</MuiAlert>
      </Snackbar>
    );
  } else if (!role) {
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert severity="error">This role was not found</MuiAlert>
      </Snackbar>
    );
  } else {
    return (
      <>
        {errors && (
          <Snackbar
            open={true}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert severity="error">{errors}</MuiAlert>
          </Snackbar>
        )}
        <DetailActions>
          {editMode || copyMode ? (
            <>
              <Button
                className="cancel-role"
                variant="outlined"
                startIcon={<CloseIcon />}
                onClick={() => {
                  setCancelConfirmOpen(true);
                }}
              >
                Cancel
              </Button>
              <Box flex="1" />

              <Dialog
                open={cancelConfirmOpen}
                onClose={() => setCancelConfirmOpen(false)}
              >
                <DialogTitle>Cancel Editing {role?.name} Role</DialogTitle>

                <DialogContent>
                  <DialogContentText>
                    <strong>Warning!</strong> All unsaved progress will be lost.
                    Click "Cancel" to finish editing the role, or "Go to Role
                    View" to abandon edits.
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button onClick={() => setCancelConfirmOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setEditMode(false);
                      setCopyMode(false);
                      setCancelConfirmOpen(false);
                    }}
                  >
                    Go to {role?.name}
                  </Button>
                </DialogActions>
              </Dialog>

              <Button
                className="save-role"
                variant="outlined"
                startIcon={<DoneIcon />}
                onClick={async () => {
                  if (copyMode) {
                    dispatch(
                      createRole({
                        name,
                        permissions: allPerms
                          .filter((p) => perms.includes(p))
                          .concat(configurationView),
                        pricingVisibleFieldIds: fieldPermIds,
                      }),
                    )
                      .then(unwrapResult)
                      .then((status: "error" | "success") => {
                        if (status === "success") {
                          dispatch(getUsers());
                          dispatch(getRoles());
                          setCopyMode(false);
                          history.push(`/c/${accessId}/roles`);
                        }
                      });
                  } else if (editMode) {
                    dispatch(
                      updateRole({
                        defaultValues: defaultValues || [],
                        myRoleId: myRole.id,
                        role: {
                          id: role && role.id,
                          changeset: {
                            name,
                            permissions: allPerms
                              .filter((p) => perms.includes(p))
                              .concat(alwaysPresentPermissions),
                            pricingVisibleFieldIds: fieldPermIds,
                          },
                        },
                        userIds: usersToAdd.map((u) => u.id),
                      }),
                    )
                      .then(unwrapResult)
                      .then((status: "error" | "success") => {
                        if (status === "success") {
                          dispatch(getUsers());
                          dispatch(getRoles());
                          setEditMode(false);
                        }
                      });
                  }
                }}
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => history.push(`/c/${accessId}/roles`)}
              >
                Back to Roles ({positionInList}/{roles.length})
                <span
                  style={{
                    fontWeight: 100,
                    marginLeft: "8px",
                    textTransform: "none",
                  }}
                >
                  sorted: {rolesState.sortField} {rolesState.sortDir}
                </span>
                {rolesState.searchTerm && (
                  <span style={{ fontWeight: 100, textTransform: "none" }}>
                    , filtered: {rolesState.searchTerm}
                  </span>
                )}
              </Button>

              <PrevButton
                list={roles}
                path={`/c/${accessId}/roles`}
                id={id}
                label="Previous Role"
              />

              <Button
                className="current-role"
                style={{ cursor: "default", background: "transparent" }}
                variant="text"
              >
                {role?.name}
              </Button>

              <NextButton
                list={roles}
                path={`/c/${accessId}/roles`}
                id={id}
                label="Next Role"
              />

              <Box flex="1" />

              <Button
                disabled={!hasEditPerm}
                className="edit-role"
                variant="outlined"
                startIcon={<EditIcon />}
                onClick={() => setEditMode(true)}
              >
                Edit
              </Button>

              <Button
                disabled={!hasCreatePerm}
                className="copy-role"
                variant="outlined"
                startIcon={<CopyIcon />}
                onClick={() => setCopyMode(true)}
              >
                Copy
              </Button>

              <Button
                className="delete-role"
                variant="outlined"
                startIcon={<DeleteIcon />}
                disabled={
                  role?.isAdmin || (role?.users || 0) > 0 || !hasDeletePerm
                }
                onClick={() => setDeleteConfirmOpen(true)}
              >
                Delete
              </Button>

              <Dialog
                open={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
              >
                <DialogTitle>Deleting {role?.name} Role</DialogTitle>

                <DialogContent>
                  <DialogContentText>
                    <strong>Warning!</strong> Deletion is permanent. If you've
                    clicked Delete by mistake you can click "Cancel" below.
                    Otherwise click "Continue with Deletion" to proceed.
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button onClick={() => setDeleteConfirmOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      if (role?.id) {
                        dispatch(deleteRoleById(role.id));
                        setDeleteConfirmOpen(false);
                        history.push(`/c/${accessId}/roles`);
                      }
                    }}
                  >
                    Continue with Deletion
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </DetailActions>

        <DetailHeader style={{ padding: "0 8px" }}>
          {(!role?.isAdmin && editMode) || copyMode ? (
            <MaterialTextField
              autoFocus={true}
              style={{ margin: "16px 0", width: "500px" }}
              label="Role Name"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          ) : (
            <></> // <Typography variant="h4">{ role?.name }</Typography>
          )}
        </DetailHeader>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: "1 1 auto",
            overflowX: "scroll",
          }}
        >
          <PermissionsList
            // for some reason we don't allow editing a copy of the admin role
            // until it is saved
            mode={(copyMode || editMode) && !role.isAdmin ? "edit" : "view"}
            chosenPerms={perms}
            setChosenPerms={setPerms}
          />

          {defaultValues && (
            <DefaultValuesList
              label="Application Form Defaults"
              editMode={editMode}
              defaultValues={defaultValues}
              setDefaultValues={setDefaultValues}
            />
          )}

          {allFieldPermissions?.map((b) => (
            <FieldsList
              fieldPermIds={fieldPermIds}
              key={b[0]}
              fields={b[1].filter((f) => fieldPermIds.includes(f.id))}
              allFields={b[1]}
              setFields={setFieldPermIds}
              groupLabel={b[0]}
              createMode={false}
              copyMode={role.isAdmin ? false : copyMode}
              editMode={role.isAdmin ? false : editMode}
            />
          ))}

          {!copyMode && (
            <UsersList
              adminId={adminId}
              users={usersState.users}
              createMode={false}
              copyMode={copyMode}
              editMode={editMode}
              roleId={role.id}
              usersToAdd={usersToAdd}
              setUsersToAdd={setUsersToAdd}
            />
          )}
        </div>
      </>
    );
  }
}
