import { Set as ISet } from "immutable";
import React from "react";

import { RenderedField } from "design/molecules/field-viewer";
import { stringFieldExpressionToString } from "../string-field-expression-editor";
import { Configuration } from "config";
import * as T from "types/engine-types";
import {
  dateFieldValueToString,
  durationValueToString,
  numberFieldValueToString,
} from "features/fields";
import {
  fieldNameToIdentifier,
  getFieldIdentifier,
} from "features/formulas-util";

export const DateFieldExpressionViewer = React.memo(
  ({
    expression,
    valueType,
    label,
    environment,
    localFields,
    config,
  }: {
    expression: T.Expression;
    valueType: T.FieldValueType.Date;
    label: string;
    environment: ISet<T.FieldId>;
    localFields: readonly T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
  }) => {
    return (
      <RenderedField
        name={label}
        value={dateFieldExpressionToString(
          environment,
          localFields,
          config,
          valueType,
          expression,
        )}
        align="left"
        width="auto"
      />
    );
  },
);

export function dateFieldExpressionToString(
  environment: ISet<T.FieldId>,
  localFields: readonly T.RuleDataTableLookupFieldDefinition[],
  config: Configuration,
  valueType: T.FieldValueType.Date,
  expression: T.Expression,
): string {
  if (expression.kind === "literal" && expression.value.type === "date") {
    return dateFieldValueToString(valueType, expression.value);
  }

  if (expression.kind === "field-value") {
    const field =
      environment.has(expression.fieldId) &&
      config.allFieldsById.get(expression.fieldId);

    return field ? `{${getFieldIdentifier(field)}}` : "<Unknown Field>";
  }

  if (expression.kind === "local-field-value") {
    const field = localFields.find((f) => f.id === expression.fieldId);

    return field ? `{${fieldNameToIdentifier(field.name)}}` : "<Unknown Field>";
  }

  throw new Error(
    "expression kind not yet supported: " + JSON.stringify(expression.kind),
  );
}

export const NumberFieldExpressionViewer = React.memo(
  ({
    expression,
    valueType,
    label,
    environment,
    localFields,
    config,
  }: {
    expression: T.Expression;
    valueType: T.FieldValueType.Number;
    label: string;
    environment: ISet<T.FieldId>;
    localFields: readonly T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
  }) => {
    return (
      <RenderedField
        name={label}
        value={numberFieldExpressionToString(
          environment,
          localFields,
          config,
          valueType,
          expression,
        )}
        align="left"
        width="auto"
      />
    );
  },
);

export function numberFieldExpressionToString(
  environment: ISet<T.FieldId>,
  localFields: readonly T.RuleDataTableLookupFieldDefinition[],
  config: Configuration,
  valueType: T.FieldValueType.Number,
  expression: T.Expression,
): string {
  if (expression.kind === "literal" && expression.value.type === "number") {
    return numberFieldValueToString(valueType, expression.value);
  }

  if (expression.kind === "field-value") {
    const field =
      environment.has(expression.fieldId) &&
      config.allFieldsById.get(expression.fieldId);

    return field ? `{${getFieldIdentifier(field)}}` : "<Unknown Field>";
  }

  if (expression.kind === "local-field-value") {
    const field = localFields.find((f) => f.id === expression.fieldId);

    return field ? `{${fieldNameToIdentifier(field.name)}}` : "<Unknown Field>";
  }

  throw new Error(
    "expression kind not yet supported: " + JSON.stringify(expression.kind),
  );
}

export { stringFieldExpressionToString };

export const StringFieldExpressionViewer = React.memo(
  ({
    expression,
    valueType,
    label,
    environment,
    localFields,
    config,
  }: {
    expression: T.Expression;
    valueType: T.FieldValueType.String;
    label: string;
    environment: ISet<T.FieldId>;
    localFields: readonly T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
  }) => {
    return (
      <RenderedField
        name={label}
        value={stringFieldExpressionToString(
          valueType,
          expression,
          config,
          environment,
          localFields,
        )}
        align="left"
        width="auto"
      />
    );
  },
);

export function durationFieldExpressionToString(
  environment: ISet<T.FieldId>,
  localFields: readonly T.RuleDataTableLookupFieldDefinition[],
  config: Configuration,
  valueType: T.FieldValueType.Duration,
  expression: T.Expression,
): string {
  if (
    expression.kind === "duration" &&
    expression.count.kind === "literal" &&
    expression.count.value.type === "number"
  ) {
    return durationValueToString({
      count: expression.count.value.value,
      unit: expression.unit,
    });
  }

  throw new Error("not yet implemented");
}
