import _ from "lodash";
import React from "react";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import * as T from "types/engine-types";
import * as Fields from "features/fields";

const useStyles = makeStyles((t) =>
  createStyles({
    table: {
      borderCollapse: "collapse",

      "& th": {
        border: "1px solid #e0e0e0",
        padding: "2px 4px",

        "& > div": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minWidth: 72,
          minHeight: 26,
        },
      },

      "& td": {
        border: "1px solid #e0e0e0",
        padding: "2px 4px",

        "& > div": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minWidth: 84,
          minHeight: 28,
        },
      },
    },
    columnLabel: {
      padding: "6px 0",
    },
    rowLabel: {
      padding: "0 12px 0 18px",
      textAlign: "right",
    },
  }),
);

export const PricingTableViewer = React.memo(
  ({ prices }: { prices: T.PriceScenario[] }) => {
    const C = useStyles();

    if (prices.length === 0) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="500px"
          height="200px"
          border="1px solid #e0e0e0"
        >
          <Box color="text.secondary" fontSize="24px">
            No pricing available.
          </Box>
        </Box>
      );
    }

    const lockPeriods = _.sortBy(
      _.uniqBy(
        prices.map((price) => price.lockPeriod),
        (p) => `${p.count} ${p.unit}`,
      ),
    );
    const rates = _.sortBy(_.uniq(prices.map((price) => price.rate)));

    return (
      <table className={C.table}>
        <thead>
          <tr>
            <th></th>
            {lockPeriods.map((lockPeriod, periodIndex) => (
              <th key={periodIndex} className={C.columnLabel}>
                <div>{Fields.durationValueToString(lockPeriod)}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rates.map((rate, rateIndex) => (
            <tr key={rateIndex}>
              <th className={C.rowLabel}>
                <div>{(+rate).toFixed(3)}%</div>
              </th>
              {lockPeriods.map((lockPeriod, periodIndex) => {
                const price = prices.find(
                  (p) =>
                    p.rate === rate &&
                    p.lockPeriod.count === lockPeriod.count &&
                    p.lockPeriod.unit === lockPeriod.unit,
                );

                return (
                  <td key={periodIndex}>
                    <div>
                      {price ? (+price.price).toFixed(3) : <>&mdash;</>}
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  },
);
