import React, { useCallback } from "react";
import { Box, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import * as T from "types/engine-types";
import { Setter, useArraySetter } from "features/utils";
import { usePermissions } from "features/roles";
import { FieldDefinitionState } from "pages/fields";
import {
  FieldConditionEditor,
  FieldConditionState,
  newFieldConditionState,
} from "../field-condition-editor";

export const OptionalFieldConditionsEditor = React.memo(
  ({
    state,
    showErrors,
    setState,
    previousFields,
    enumTypes,
  }: {
    state: readonly FieldConditionState[];
    showErrors: boolean;
    setState: Setter<readonly FieldConditionState[]>;
    previousFields: FieldDefinitionState[];
    enumTypes: T.RawEnumType[];
  }) => {
    const addCondition = useCallback(
      () =>
        setState((oldState) => [...(oldState || []), newFieldConditionState()]),
      [setState],
    );
    const hasPermission = usePermissions();
    const hasFieldsModifyPerm = hasPermission("field-enum-library-modify");
    const conditionsArraySetter = useArraySetter(setState);

    const deleteCondition = useCallback(
      (index: number) =>
        setState((oldState) => {
          const newState = [...(oldState || [])];
          newState.splice(index, 1);
          return newState;
        }),
      [setState],
    );

    return (
      <Box maxWidth={400}>
        {!!state?.length && <Box my={2}>Field is conditional on:</Box>}
        {state &&
          !!state.length &&
          state.map((s, i) => {
            return (
              <div
                key={i}
                style={{
                  borderBottom: "1px solid #ccc",
                  paddingBottom: "16px",
                }}
              >
                <FieldConditionEditor
                  state={s}
                  showErrors={showErrors}
                  setState={conditionsArraySetter.withIndex(i)}
                  previousFields={previousFields}
                  enumTypes={enumTypes}
                />
                <Box my={2}>
                  <Button
                    disabled={!hasFieldsModifyPerm}
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    onClick={() => deleteCondition(i)}
                  >
                    Remove this field condition
                  </Button>
                </Box>
              </div>
            );
          })}
        {state === null ||
          (state.length < 3 && (
            <div style={{ marginTop: "16px" }}>
              <Button
                disabled={!hasFieldsModifyPerm}
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={addCondition}
              >
                {!!state?.length
                  ? "Add another field condition"
                  : "Add a field condition"}
              </Button>
            </div>
          ))}
      </Box>
    );
  },
);
