import React from "react";
import { Box, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  // TODO make this dynamic based on prop
  customColor: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "white",
    },
    "& .MuiLinearProgress-colorPrimary": {
      // backgroundColor: "lightGray" TODO this change seems to be bugged on material UI's end
    },
    "& .MuiLinearProgress-dashedColorPrimary": {
      backgroundImage:
        "radial-gradient(rgb(255, 255, 255) 0%, rgb(255, 255, 255) 16%, transparent 42%)",
    },
  },
});

export default function LinearProgressBar({
  width,
  useCustomColor,
  progress,
  valueBuffer,
  message,
}: {
  width?: string | number;
  useCustomColor?: boolean;
  progress: number;
  valueBuffer?: number;
  message: string;
}) {
  const classes = useStyles();

  return (
    <div
      className={useCustomColor ? classes.customColor : undefined}
      style={{
        display: "flex",
        flexDirection: "column",
        width: width || "100%",
      }}
    >
      <LinearProgress
        variant="buffer"
        value={progress}
        valueBuffer={valueBuffer || progress}
      />
      <Box
        py={1}
        fontSize="18px"
        style={{
          alignSelf: "center",
        }}
      >
        {message}
      </Box>
    </div>
  );
}
