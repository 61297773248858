import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import Button from "design/atoms/button";
import Icon from "design/atoms/icon";
import { ProductDetails, DetailsWrapper, AccordionWrapper } from "./styles";
import ProductDetailActionPanel from "../product-detail-action-panel";
import KeyValue, { KeyValueGroup } from "design/atoms/key-value";
import Accordion from "design/molecules/accordion";
import {
  faFileExport,
  faBan,
  IconDefinition,
  faExclamationCircle,
  faCircleXmark,
  faCircleQuestion,
  faCircleExclamation,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as T from "types/engine-types";
import { useSelector } from "react-redux";
import { getMissingFieldInfo, LoansState } from "features/loans";
import {
  expandedConfigSelector,
  nonNullApplicationInitializationSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import { getMissingFieldsOnProduct } from "features/execution";
import { useAsyncLoader } from "features/utils";
import * as Api from "api";
import * as DateFns from "date-fns";
import PriceScenarioTable from "pages/loans-v2/loan-pricing/_components/price-scenario-table";
import { productsSelector } from "features/products";
import { useById } from "features/utils";
import { rulesSelector } from "features/rules";
import * as Fields from "features/fields";
import { Map as IMap, Set as ISet } from "immutable";
import { useLazyMemo } from "features/utils";
import PriceScenarioFields from "pages/loans-v2/loan-pricing/_components/price-scenario-fields";
import PriceScenarioSummary from "pages/loans-v2/loan-pricing/_components/price-scenario-summary";
import ProductHeader from "../product-header";
import { localAccessId } from "features/access-id";
import * as Stages from "features/stages";
import { BaseFieldDefinition } from "types/engine-types";
import { useVisibleFields } from "features/roles";
import { SmallText } from "design/atoms/typography";
import { loansSelector } from "features/loans";
import { getLowest } from "features/pricing-summaries";
import CauseLink from "pages/loans/_components/cause-link";
import EnglishList from "design/atoms/english-list";
import DescriptionIcon from "@material-ui/icons/Description";
import MessageSection from "pages/loans-v2/loan-pricing/_components/message-section";
import whitespace from "design/subatomics/whitespace";

type Params = {
  productId: T.ProductId;
  pricingScenarioRate: string;
  pricingScenarioLock: string;
};

export default React.memo(
  ({
    className,
    fieldValueMappings,
    showActionPanel = true,
    showControlButtons = true,
    setForceCollapseResults,
    forceCollapseResults,
    highlightNextField,
  }: {
    className?: string;
    fieldValueMappings: T.FieldValueMapping[] | null;
    showActionPanel?: boolean;
    showControlButtons?: boolean;
    setForceCollapseResults?: React.Dispatch<React.SetStateAction<boolean>>;
    forceCollapseResults?: boolean;
    highlightNextField: (field: T.FieldId[] | undefined) => void;
  }) => {
    const accessId = localAccessId();
    const history = useHistory();
    const location = useLocation();
    const { products } = useSelector(productsSelector);
    const params = useParams<Params>();
    const decoratedProducts = useSelector(
      (state: { loans: LoansState }) => state.loans?.summary?.products,
    );
    const objectDetails = useSelector(objectDetailsMapSelector);
    const { rules } = useSelector(rulesSelector);
    const rulesById = useById(rules);
    const { currentFormValues } = useSelector(loansSelector);
    const { myPricingProfile, myPricingProfiles, myDefaultFieldValues } =
      useSelector(nonNullApplicationInitializationSelector);
    const [product, setProduct] = useState<T.ExecutionProductSummary | null>(
      null,
    );
    const config = useSelector(expandedConfigSelector);
    const calcStagesByScope = Stages.getCalcStagesByScope(config);
    const isFieldAllowed = useVisibleFields();
    const [pricingSelection, setPricingSelection] =
      useState<T.PriceScenarioResultId | null>(null);
    const queryParams = new URLSearchParams(document.location.search);

    useEffect(() => {
      if (products !== null) {
        const product = decoratedProducts?.find(
          (p) => p.productId === params.productId,
        );

        if (product) {
          setProduct(product);
        }
      }
    }, [decoratedProducts, products, params.productId]);

    const productRequest: T.ProductExecutionRequest | null = useMemo(() => {
      if (
        !fieldValueMappings ||
        !myPricingProfile?.id ||
        !myPricingProfiles?.length
      ) {
        return null;
      }

      return {
        currentTime: DateFns.formatISO(new Date()),
        productId: params.productId,
        pricingProfileId:
          myPricingProfiles.length > 0 ? myPricingProfile.id : null,
        creditApplicationFields: fieldValueMappings,
        outputFieldsFilter: { type: "all" },
        publishedVersionId: null,
      };
    }, [
      fieldValueMappings,
      myPricingProfile.id,
      myPricingProfiles.length,
      params.productId,
    ]);

    const [productResult, productResultLoading] = useAsyncLoader(async () => {
      if (
        !fieldValueMappings ||
        !myPricingProfile?.id ||
        !myPricingProfiles?.length
      ) {
        return null;
      }

      if (params.productId) {
        const resp = await Api.executeProduct({
          currentTime: DateFns.formatISO(new Date()),
          productId: params.productId,
          pricingProfileId:
            myPricingProfiles.length > 1 ? myPricingProfile.id : null,
          creditApplicationFields: fieldValueMappings,
          outputFieldsFilter: { type: "all" },
          publishedVersionId: null,
        });

        if (
          (!params.pricingScenarioLock && !params.pricingScenarioRate) ||
          (resp.status === "ok" &&
            (!resp.priceScenarios.find(
              (s) =>
                parseFloat(s.adjustedRate || "") ===
                parseFloat(params.pricingScenarioRate),
            ) ||
              !resp.priceScenarios.find(
                (s) =>
                  parseInt(s.adjustedRateLockPeriod?.count || "") ===
                  parseInt(params.pricingScenarioLock),
              )))
        ) {
          if (product) {
            const lowest = getLowest(product);

            if (!lowest?.adjustedRate && !lowest?.adjustedRateLockPeriod) {
              if (
                resp.status === "ok" &&
                resp.priceScenarios[0] &&
                resp.priceScenarios[0].adjustedRate &&
                resp.priceScenarios[0].adjustedRateLockPeriod?.count
              ) {
                history.push(
                  `/c/${accessId}/v2/loan-pricing/products/${params.productId}/${resp.priceScenarios[0].adjustedRate}/${resp.priceScenarios[0].adjustedRateLockPeriod?.count}${location.search}${location.hash}`,
                );
              } else if (
                resp.status === "ok" &&
                resp.priceScenarios[0] &&
                resp.priceScenarios[0].adjustedRate &&
                resp.priceScenarios[0].adjustedRateLockPeriod === null
              ) {
                history.push(
                  `/c/${accessId}/v2/loan-pricing/products/${params.productId}/${resp.priceScenarios[0].adjustedRate}${location.search}${location.hash}`,
                );
              } else {
                history.push(
                  `/c/${accessId}/v2/loan-pricing/products/${params.productId}${location.search}${location.hash}`,
                );
              }
            }
          }
        }
        return resp;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, productRequest]);

    const priceScenarios = useMemo(() => {
      return productResult?.status === "ok"
        ? productResult?.priceScenarios
        : [];
    }, [productResult]);

    const scenariosMap = ISet(priceScenarios);

    const openPriceScenario = useMemo(() => {
      return pricingSelection === null
        ? null
        : scenariosMap.find((s) => s.id === pricingSelection) || null;
    }, [scenariosMap, pricingSelection]);

    const openPriceScenarioById = useLazyMemo(
      (id: T.PriceScenarioResultId) => () => {
        const foundScenario =
          priceScenarios.find((s) => s.id === id) || priceScenarios[0];

        if (
          foundScenario?.adjustedRateLockPeriod?.count &&
          foundScenario?.adjustedRate
        ) {
          history.push(
            `/c/${accessId}/v2/loan-pricing/products/${params.productId}/${foundScenario.adjustedRate}/${foundScenario.adjustedRateLockPeriod?.count}${location.search}${location.hash}`,
          );

          setPricingSelection(id);
        } else if (foundScenario?.adjustedRate) {
          history.push(
            `/c/${accessId}/v2/loan-pricing/products/${params.productId}/${foundScenario.adjustedRate}${location.search}${location.hash}`,
          );

          setPricingSelection(id);
        } else {
          history.push(
            `/c/${accessId}/v2/loan-pricing/products/${params.productId}${location.search}${location.hash}`,
          );

          setPricingSelection(id);
        }
      },
      [
        params,
        history,
        location.hash,
        location.search,
        priceScenarios,
        accessId,
      ],
    );

    if (!product || !productResult || !priceScenarios) {
      return <></>;
    }

    let color: string = "";
    let icon: IconDefinition;
    switch (product.status) {
      case "approved":
        color = "green";
        icon = faCheckCircle;
        break;
      case "available":
        color = "blue";
        icon = faExclamationCircle;
        break;
      case "error":
        color = "red";
        icon = faCircleExclamation;
        break;
      case "missing-configuration":
        color = "red";
        icon = faCircleExclamation;
        break;
      case "no-pricing":
        color = "red";
        icon = faCircleExclamation;
        break;
      case "rejected":
        color = "gray";
        icon = faCircleXmark;
        break;
      case "review-required":
        color = "yellow";
        icon = faCircleQuestion;
        break;
      default:
        icon = faCircleExclamation;
        break;
    }

    if (openPriceScenario?.status === "rejected") {
      color = "gray";
    }

    const filteredInitialCalcs = calcStagesByScope.product[0].calculations
      .flatMap((calc): BaseFieldDefinition[] =>
        calc.type === "field"
          ? [{ ...calc.field, description: null }]
          : calc.lookup.fields.map((f) => ({
              ...f,
              description: null,
            })),
      )
      .filter((pair) => {
        return isFieldAllowed(pair.id);
      });

    const missingFieldInfo = getMissingFieldInfo(
      config,
      rulesById,
      getMissingFieldsOnProduct(productResult),
      accessId,
    );

    return (
      <ProductDetails
        className={
          className
            ? `page-component-loan-pricing-product-details ${className}`
            : "page-component-loan-pricing-product-details"
        }
      >
        {showActionPanel && (
          <ProductDetailActionPanel
            scenario={openPriceScenario || undefined}
            product={products?.find((p) => p.id === productResult.productId)}
            productResultLoading={productResultLoading}
            setForceCollapseResults={setForceCollapseResults}
            forceCollapseResults={forceCollapseResults}
            setPricingSelection={setPricingSelection}
            productId={product.productId}
            result={productResult}
            request={productRequest ? productRequest : undefined}
          />
        )}

        {showControlButtons && (
          <div className="control-buttons">
            <Button className="remove">
              <Icon icon={faBan} />
            </Button>
            <Button className="export">
              <Icon icon={faFileExport} />
            </Button>
          </div>
        )}
        <DetailsWrapper>
          <ProductHeader
            product={product}
            color={color}
            highlightNextField={highlightNextField}
            icon={<Icon icon={icon} />}
          />

          {!!missingFieldInfo.length && (
            <div style={{ padding: whitespace("less") }}>
              <SmallText>
                More information is needed to produce an Approved or Rejected
                response for this product.
                <br />
                Fill out the following{" "}
                {missingFieldInfo.length === 1 ? "field" : "fields"} to
                continue:
              </SmallText>

              {missingFieldInfo.map((missingField) => (
                <MessageSection
                  key={missingField.fieldId}
                  icon={<DescriptionIcon />}
                >
                  <strong>{missingField.fieldName}</strong> is needed in{" "}
                  <EnglishList
                    items={_.uniqBy(missingField.causes, "link").map(
                      (cause) => {
                        if (cause.link) {
                          return (
                            <CauseLink title={cause.title} link={cause.link} />
                          );
                        } else {
                          return cause.title;
                        }
                      },
                    )}
                  />
                  .
                </MessageSection>
              ))}
            </div>
          )}

          <PriceScenarioTable
            productResult={productResult}
            openPriceScenarioById={openPriceScenarioById}
            isInvestorPricingEnabled={productResult.isPricingEnabled}
            scenarios={scenariosMap}
            config={config}
            objectDetails={objectDetails}
          />

          {!!openPriceScenario && (
            <PriceScenarioSummary
              scenario={openPriceScenario}
              config={config}
              rulesById={rulesById}
              color={color}
            />
          )}
          <AccordionWrapper>
            {!!config.creditApplicationFields.length && (
              <Accordion
                className={
                  queryParams.get("inCompareView") === "true"
                    ? ""
                    : "only-for-print"
                }
                color={color}
                open={true}
                label="Application Form Inputs"
              >
                <KeyValueGroup>
                  {config.creditApplicationFields.map((pair) => {
                    const field = config.allFieldsById.get(pair.id);

                    if (field?.valueType.type === "header") {
                      return <span key={field.id}></span>;
                    }

                    let fieldValue: T.FieldValue | null =
                      currentFormValues?.find((f) => {
                        return field!.id === f.fieldId;
                      })?.value || null;

                    let defaultField;
                    if (!fieldValue) {
                      defaultField = myDefaultFieldValues.find((d) => {
                        return field!.id === d.fieldId;
                      });

                      fieldValue = defaultField?.value || null;
                    }

                    const label = field?.name || "";

                    if (!field || defaultField?.hidden) return <></>;

                    const value = Fields.fieldValueToString(
                      config,
                      objectDetails,
                      field.valueType,
                      fieldValue,
                    );
                    return (
                      <KeyValue
                        key={label}
                        label={label}
                        value={value || "&mdash;"}
                      />
                    );
                  })}
                </KeyValueGroup>
              </Accordion>
            )}

            {!!openPriceScenario ? (
              <PriceScenarioFields
                scenario={openPriceScenario}
                config={config}
                rulesById={rulesById}
                color={color}
                top={true}
              />
            ) : null}

            {!!productResult.productFields.length && (
              <Accordion
                color={color}
                open={true}
                label="Product Specifications"
              >
                <KeyValueGroup>
                  {productResult.productFields.map((pair) => {
                    const field = config.allFieldsById.get(pair.fieldId);
                    const label = field?.name || "";
                    const fieldValuesById = IMap(
                      [...productResult.productFields].map((mapping) => [
                        mapping.fieldId,
                        mapping.value,
                      ]),
                    );
                    const fieldValue: T.FieldValue | null =
                      fieldValuesById.get(pair.fieldId) || null;

                    if (!field) return <></>;

                    const value = Fields.fieldValueToString(
                      config,
                      objectDetails,
                      field.valueType,
                      fieldValue,
                    );
                    return (
                      <KeyValue
                        key={label}
                        label={label}
                        value={value || "&mdash;"}
                      />
                    );
                  })}
                </KeyValueGroup>
              </Accordion>
            )}

            <Accordion color={color} open={true} label="Initial Calculations">
              <KeyValueGroup>
                {filteredInitialCalcs.map((field) => {
                  const newField: T.BaseFieldDefinition | undefined =
                    config.allFieldsById.get(field.id);
                  const label = newField?.name || "";
                  const fieldValuesById = IMap(
                    [...productResult.calculatedFields].map((mapping) => [
                      mapping.fieldId,
                      mapping.value,
                    ]),
                  );
                  const fieldValue: T.FieldValue | null =
                    fieldValuesById.get(newField!.id) || null;

                  if (!newField) return <></>;

                  const value = Fields.fieldValueToString(
                    config,
                    objectDetails,
                    newField.valueType,
                    fieldValue,
                  );
                  return (
                    <KeyValue
                      key={newField.name}
                      label={label}
                      value={value || "&mdash;"}
                    />
                  );
                })}
              </KeyValueGroup>
            </Accordion>

            {!!openPriceScenario ? (
              <PriceScenarioFields
                scenario={openPriceScenario}
                config={config}
                rulesById={rulesById}
                color={color}
                top={false}
              />
            ) : null}
          </AccordionWrapper>
        </DetailsWrapper>
        <div className="only-for-print">
          <SmallText>
            URL: <strong>{window.location.href}</strong>
          </SmallText>
        </div>
      </ProductDetails>
    );
  },
);
