import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Box,
  Button,
  Typography,
  TextField as MaterialTextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import DetailActions from "design/molecules/detail-actions";
import DetailHeader from "design/molecules/detail-header";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useAppDispatch } from "features/store";
import {
  productsSelector,
  productsLoadingSelector,
  getProducts,
} from "features/products";
import { usersSelector, usersLoadingSelector, getUsers } from "features/users";
import UsersList from "../_components/users-list";
import ProductsList from "../_components/products-list";
import * as T from "types/engine-types";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

import {
  pricingProfilesSelector,
  filteredPricingProfilesSelector,
  getPricingProfiles,
  createPricingProfile,
} from "features/pricing-profiles";

type Params = {
  id: string;
};

export default function ViewPricingProfilePage() {
  const history = useHistory<Params>();
  const dispatch = useAppDispatch();
  const pricingProfiles = useSelector(filteredPricingProfilesSelector);
  const [name, setName] = useState("");
  const [cancelConfirmOpen, setCancelConfirmOpen] = useState<boolean>(false);
  const pricingProfilesState = useSelector(pricingProfilesSelector);
  const { errors, loading } = pricingProfilesState;
  const usersState = useSelector(usersSelector);
  const productsState = useSelector(productsSelector);
  const productsLoading = useSelector(productsLoadingSelector);
  const [usersToAdd, setUsersToAdd] = useState<T.DetailedUser[]>([]);
  const [productsToAdd, setProductsToAdd] = useState<T.ProductHeader[]>([]);
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);
  const usersLoading = useSelector(usersLoadingSelector);

  useEffect(() => {
    if (!usersLoading) dispatch(getUsers());
  }, [dispatch, usersLoading]);

  useEffect(() => {
    if (!productsLoading) dispatch(getProducts());
  }, [dispatch, productsLoading]);

  useEffect(() => {
    if (!pricingProfiles || pricingProfiles.length === 0)
      dispatch(getPricingProfiles());
  }, [dispatch, pricingProfiles, pricingProfiles.length]);

  const handleSave = async () => {
    dispatch(
      createPricingProfile({
        pricingProfile: {
          name,
        },
        usersToAdd,
        productsToAdd,
      }),
    )
      .then(unwrapResult)
      .then((result: "success" | "error") => {
        if (result === "success") {
          history.push(`/c/${accessId}/pricing-profiles`);
        }
      });
  };

  if (loading) {
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert>Loading</MuiAlert>
      </Snackbar>
    );
  } else {
    return (
      <>
        {errors && (
          <Snackbar
            open={true}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert severity="error">{errors}</MuiAlert>
          </Snackbar>
        )}
        <DetailActions>
          <>
            <Button
              className="cancel-pricing-profile"
              variant="outlined"
              startIcon={<CloseIcon />}
              onClick={() => setCancelConfirmOpen(true)}
            >
              Cancel
            </Button>

            <Dialog
              open={cancelConfirmOpen}
              onClose={() => setCancelConfirmOpen(false)}
            >
              <DialogTitle>Leaving the Screen</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  <strong>Warning!</strong> All unsaved progress will be lost.
                  Click "Cancel" to finish creating the new pricing profile, or
                  "Go to Pricing Profiles List" to abandon pricing profile
                  creation.
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setCancelConfirmOpen(false)}>
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    setCancelConfirmOpen(false);
                    history.push(`/c/${accessId}/pricing-profiles`);
                  }}
                >
                  Go to Pricing Profiles List
                </Button>
              </DialogActions>
            </Dialog>

            <Box flex="1" />

            <Button
              className="save-pricing-profile"
              variant="outlined"
              startIcon={<DoneIcon />}
              onClick={handleSave}
            >
              Save
            </Button>
          </>
        </DetailActions>

        <DetailHeader>
          <div>
            <Typography style={{ margin: "32px 0 16px" }} variant="h4">
              Creating Pricing Profile
            </Typography>

            <MaterialTextField
              style={{ margin: "16px 0" }}
              label="Profile Name"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setName(e.currentTarget.value)}
              data-selector="pricing-profile-name-input"
            />
          </div>
        </DetailHeader>

        <div
          style={{
            overflowX: "scroll",
            display: "flex",
            flexDirection: "row",
            flex: "1 1 auto",
          }}
        >
          <>
            <UsersList
              users={usersState.users}
              createMode={true}
              editMode={false}
              usersToAdd={usersToAdd}
              setUsersToAdd={setUsersToAdd}
            />
            <ProductsList
              products={productsState.products ? productsState.products : []}
              createMode={true}
              editMode={false}
              productsToAdd={productsToAdd}
              setProductsToAdd={setProductsToAdd}
            />
          </>
        </div>
      </>
    );
  }
}
