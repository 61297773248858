export default function filterResults(
  searchTerm: string,
  requirements: string[],
) {
  // lazy check for matches wrapped in -""
  const notQuoteRegex = /-".*?"/g;

  // create array from formatted regex matches
  const notQuotedSearchTerm: string[] = Array.from(
    searchTerm.matchAll(notQuoteRegex),
    (m) => {
      // remove syntax chars and lowercase
      return m[0].toLowerCase().replaceAll('-"', "").replaceAll('"', "");
    },
  );

  // prune wip string
  const updatedAfterNotQuotes: string = searchTerm.replaceAll(
    notQuoteRegex,
    "",
  );

  // capture if no -"" found
  const noNotQuoteMatches: boolean = notQuotedSearchTerm.every((t) => {
    const requirementsMet = requirements.every(
      (r) => !r.toLowerCase().includes(t),
    );
    return t.length === 0 || requirementsMet;
  });

  // lazy check for matches wrapped in ""
  const quoteRegex = /".*?"/g;

  // create array from formatted regex matches
  const quotedSearchTerm: string[] = Array.from(
    updatedAfterNotQuotes.matchAll(quoteRegex),
    (m) => {
      // remove syntax chars and lowercase
      return m[0].toLowerCase().replaceAll('"', "");
    },
  );

  // prune wip string
  const updatedAfterQuotes: string = updatedAfterNotQuotes.replaceAll(
    quoteRegex,
    "",
  );

  // capture if "" matches found
  const quoteMatch: boolean = quotedSearchTerm.every((t) => {
    const requirementsMet = !!requirements.find((r) => {
      return r.toLowerCase().includes(t);
    });
    return t.length === 0 || requirementsMet;
  });

  // check for matches in remaining string split by spaces
  const splitSearchTerms: string[] = updatedAfterQuotes.split(" ");

  // capture if any other matches found
  const restMatch: boolean = splitSearchTerms.every((term) => {
    const transformedTerm = term.replaceAll('"', "").toLowerCase();

    const requirementsMet = !!requirements.find((r) => {
      // only match "active" or "inactive" if they typed it exactly
      // (for searching for active and inactive products / investors)
      if (transformedTerm === "active" || transformedTerm === "inactive") {
        return r.toLowerCase() === transformedTerm;
      } else {
        return r.toLowerCase().includes(transformedTerm);
      }
    });
    return transformedTerm.length === 0 || requirementsMet;
  });

  return {
    noNotQuoteMatches,
    quoteMatch,
    restMatch,
  };
}
