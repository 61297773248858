import styled from "styled-components";
import whitespace from "design/subatomics/whitespace";
import colors from "design/subatomics/colors";
import radiusOptions from "design/subatomics/corners";
import fontSizes from "design/subatomics/font-sizes";
import breakpoints from "design/subatomics/breakpoints";
import color from "design/subatomics/colors";

export const Header = styled.div`
  display: block;
  background-color: ${colors({ color: "gray", shade: 9 })};
  border-bottom: 1px solid ${colors({ color: "border" })};
  flex: 0 0 auto;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${whitespace("less")} 0;
  width: 100%;
  gap: ${whitespace("less")};

  label {
    margin: 0 ${whitespace("more")} 0;

    width: 280px;
  }

  ${breakpoints.mobile} {
    label {
      width: calc(100% - ${whitespace("more")});
    }
  }

  ${breakpoints.mobile} {
    flex-direction: column;
  }
`;

export const InputSection = styled.div`
  padding: ${whitespace("less")};
  display: flex;

  h1 {
    flex-grow: 1;
  }

  input {
    background-color: ${color({ color: "white" })} !important;
  }

  ${breakpoints.desktop} {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    gap: ${whitespace("less")};

    > h1 {
      width: calc(100% - 12px);
      margin-bottom: ${whitespace("less")};
    }

    > button {
      position: relative;
      top: -4px;
      width: 240px;
    }

    > div {
      margin-right: 0;
      width: calc(25% - 6px) !important;
      max-width: 240px;

      & > div {
        width: 100%;
      }
    }
  }

  ${breakpoints.mobile} {
    flex-direction: column;
    padding: ${whitespace("less")} 0;

    gap: ${whitespace("less")};

    > h1,
    > button {
      width: calc(100% - 12px);
      margin-bottom: ${whitespace("less")};
    }

    > div {
      width: 100% !important;

      & > div {
        width: 100%;
      }
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${whitespace("less")};
`;

export const PipelineScenarios = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: ${whitespace("less")} ${whitespace()};
  flex: 1 0 100%;
  gap: ${whitespace("less")};

  label {
    margin-right: ${whitespace()};
    width: 240px;
  }

  ${breakpoints.mobile} {
    flex-wrap: wrap;

    > button.add-scenario,
    > button.cancel-add-scenario {
      flex: 1 1 calc(50% - ${whitespace("less")});
    }
  }
`;

export const RecordSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;

  ${breakpoints.mobile} {
    padding: ${whitespace("less")} ${whitespace("less")} 0;
  }
`;

export const CheckboxWrapper = styled.div`
  height: auto;
  display: flex;
  white-space: nowrap;
  justify-content: space-around;
  align-items: center;
  margin-right: ${whitespace()};
  font-size: ${fontSizes.m};
`;

export const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: ${radiusOptions.medium};
  &:checked {
    accent-color: ${colors({ color: "green", shade: 6 })};
  }
`;
