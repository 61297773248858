import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Api from "api";
import { VendorIntegration } from "../../types/generated-types";

type VendorIntegrationsStatus =
  | { kind: "not-loaded" }
  | { kind: "loading" }
  | { kind: "loaded" }
  | { kind: "error"; error: string };

export type VendorIntegrationsState = {
  vendorIntegrations: VendorIntegration[];
  status: VendorIntegrationsStatus;
};

const initialState: VendorIntegrationsState = {
  vendorIntegrations: [],
  status: { kind: "not-loaded" },
};

export const listVendorIntegrations = createAsyncThunk(
  "vendorIntegrations/list",
  async () => {
    return await Api.getVendorIntegrations();
  },
);

const vendorIntegrationsSlice = createSlice({
  name: "VendorIntegrations",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(listVendorIntegrations.pending, (state) => {
      state.status = { kind: "loading" };
    });
    builder.addCase(listVendorIntegrations.rejected, (state) => {
      state.status = {
        kind: "error",
        error: "An error occurred while loading target types",
      };
    });
    builder.addCase(listVendorIntegrations.fulfilled, (state, { payload }) => {
      state.vendorIntegrations = payload;
      state.status = { kind: "loaded" };
    });
  },
  reducers: {},
});

export const vendorIntegrationsStateSelector = (state: {
  vendorIntegrations: VendorIntegrationsState;
}) => state.vendorIntegrations;

export default vendorIntegrationsSlice.reducer;
