import React, { ReactNode } from "react";
import { ProductResult, InlineGroup } from "./styles";
import KeyValue, { KeyValueGroup } from "design/atoms/key-value";
import { AsLink, SecondaryTitle, Text } from "design/atoms/typography";
import { useParams } from "react-router-dom";
import * as T from "types/engine-types";
import { useSelector } from "react-redux";
import { getLowest } from "features/pricing-summaries";
import { loansSelector } from "features/loans";
import Icon from "design/atoms/icon";
import { faScaleUnbalanced } from "@fortawesome/free-solid-svg-icons";
import {
  expandedConfigSelector,
  nonNullApplicationInitializationSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import * as Fields from "features/fields";

type Params = {
  productId: T.ProductId;
  pricingScenarioRate: string;
  pricingScenarioLock: string;
};

export default React.memo(
  ({
    icon,
    color,
    product,
    highlightNextField,
  }: {
    icon: ReactNode;
    color?: string;
    product?: T.ExecutionProductSummary;
    highlightNextField: (field: T.FieldId[] | undefined) => void;
  }) => {
    const params = useParams<Params>();
    const objectDetails = useSelector(objectDetailsMapSelector);
    const config = useSelector(expandedConfigSelector);
    const applicationState = useSelector(
      nonNullApplicationInitializationSelector,
    );
    const settings = applicationState.config.settings;
    const settingsType = settings.priceScenarioTable?.type;

    const nextField = applicationState.config.creditApplicationFields.find(
      (f) =>
        product?.status === "available" && f.id === product.requiredFieldIds[0],
    );
    const { loansToCompare } = useSelector(loansSelector);

    if (!product) return <></>;

    const allFields = applicationState.config.allFieldsById;
    const lowest = getLowest(product);
    let match: T.PriceScenarioSummary | undefined;

    if (product.status === "approved" || product.status === "review-required") {
      match = product.priceScenarios.find((scenario) => {
        return (
          scenario.adjustedRate === params.pricingScenarioRate &&
          scenario.adjustedRateLockPeriod?.count === params.pricingScenarioLock
        );
      });
    }

    const extraFields =
      product.status === "approved" || product.status === "review-required"
        ? [
            ...(match?.calculatedFields || []),
            ...(match?.priceScenarioFields || []),
          ]
        : [];
    const extraColumns =
      applicationState.config.priceScenarioTableFieldInfo &&
      (applicationState.config.priceScenarioTableFieldInfo.type ===
      "rate-with-lock-period"
        ? applicationState.config.priceScenarioTableFieldInfo.extraColumnFields
        : applicationState.config.priceScenarioTableFieldInfo.columnFields);
    const extraColumnsMap: Map<T.FieldId, T.BaseFieldDefinition> = new Map();
    extraColumns?.map((item) => {
      extraColumnsMap.set(item.id, item);
    });

    const sortedColumns = extraFields?.sort((a, b) => {
      const aItem: T.BaseFieldDefinition = extraColumnsMap.get(a.fieldId)!;
      const bItem: T.BaseFieldDefinition = extraColumnsMap.get(b.fieldId)!;
      return extraColumns!.indexOf(aItem) - extraColumns!.indexOf(bItem);
    });

    let price = lowest?.adjustedPrice;
    if (product.status === "approved" || product.status === "review-required") {
      const scenario = product.priceScenarios.find((s) => {
        return (
          params.pricingScenarioRate === s.adjustedRate &&
          params.pricingScenarioLock === s.adjustedRateLockPeriod?.count
        );
      });

      price = scenario?.adjustedPrice || "";
    }

    let lock = lowest?.adjustedRateLockPeriod;
    if (product.status === "approved" || product.status === "review-required") {
      const scenario = product.priceScenarios.find((s) => {
        return (
          params.pricingScenarioRate === s.adjustedRate &&
          params.pricingScenarioLock === s.adjustedRateLockPeriod?.count
        );
      });

      lock = scenario?.adjustedRateLockPeriod;
    }

    let rate = lowest?.adjustedRate;
    if (product.status === "approved" || product.status === "review-required") {
      if (!!params.pricingScenarioLock && !!params.pricingScenarioRate) {
        const scenario = product.priceScenarios.find(
          (s) =>
            params.pricingScenarioRate === s.adjustedRate &&
            params.pricingScenarioLock === s.adjustedRateLockPeriod?.count,
        );

        rate = scenario?.adjustedRate || "";
      } else if (!!params.pricingScenarioRate) {
        const scenario = product.priceScenarios.find(
          (s) => params.pricingScenarioRate === s.adjustedRate,
        );

        rate = scenario?.adjustedRate || "";
      }
    }

    const adjustedRateObj =
      settings.priceScenarioTable?.adjustedRateFieldId &&
      applicationState.config.allFieldsById.get(
        settings.priceScenarioTable?.adjustedRateFieldId,
      );
    const adjustedRateValue =
      adjustedRateObj && adjustedRateObj.valueType.type === "number" && !!rate
        ? parseFloat(rate).toFixed(adjustedRateObj.valueType.precision) + "%"
        : "";
    const adjustedPriceObj =
      settingsType === "rate-with-lock-period" &&
      settings.priceScenarioTable?.adjustedPriceFieldId &&
      applicationState.config.allFieldsById.get(
        settings.priceScenarioTable?.adjustedPriceFieldId,
      );
    const adjustedPriceValue =
      settingsType === "rate-with-lock-period" &&
      adjustedPriceObj &&
      adjustedPriceObj.valueType.type === "number" &&
      !!price
        ? parseFloat(price).toFixed(adjustedPriceObj.valueType.precision)
        : "";

    return (
      <ProductResult
        className={
          color
            ? `page-loan-pricing-component-product-result ${color}`
            : `page-loan-pricing-component-product-result`
        }
      >
        <SecondaryTitle>
          {icon} <strong>{product.productName}</strong>{" "}
          {loansToCompare.find((l) => l === window.location.href) && (
            <Icon icon={faScaleUnbalanced} />
          )}
        </SecondaryTitle>

        <InlineGroup>
          {product.status === "available" && nextField && (
            <Text className="next-required">
              Next Field:{" "}
              <AsLink onClick={() => highlightNextField([nextField.id])}>
                {nextField?.name}
              </AsLink>
            </Text>
          )}
        </InlineGroup>

        <KeyValueGroup>
          <KeyValue label="Investor Name" value={product.investorName} />

          {(product.status === "approved" ||
            product.status === "review-required") &&
            settingsType === "rate-with-columns" &&
            settings.priceScenarioTable && (
              <KeyValue
                label={
                  allFields.get(settings.priceScenarioTable.adjustedRateFieldId)
                    ?.name || ""
                }
                value={adjustedRateValue ? adjustedRateValue : "Loading..."}
              />
            )}

          {(product.status === "approved" ||
            product.status === "review-required") &&
            settingsType === "rate-with-lock-period" &&
            settings.priceScenarioTable && (
              <>
                <KeyValue
                  label={
                    allFields.get(
                      settings.priceScenarioTable.adjustedPriceFieldId,
                    )?.name || ""
                  }
                  value={adjustedPriceValue ? adjustedPriceValue : "Loading..."}
                />

                <KeyValue
                  label={
                    allFields.get(
                      settings.priceScenarioTable.adjustedRateFieldId,
                    )?.name || ""
                  }
                  value={adjustedRateValue ? adjustedRateValue : "Loading..."}
                />

                <KeyValue
                  label={
                    allFields.get(
                      settings.priceScenarioTable.adjustedRateLockPeriodFieldId,
                    )?.name || ""
                  }
                  value={`${lock?.count || ""} ${lock?.unit || ""}`}
                />
              </>
            )}

          {sortedColumns &&
            sortedColumns.map((field, i) => {
              const newField: T.BaseFieldDefinition | undefined =
                config.allFieldsById.get(field.fieldId);

              const value = Fields.fieldValueToString(
                config,
                objectDetails,
                newField!.valueType,
                field.value,
              );

              return (
                <KeyValue
                  key={i}
                  label={allFields.get(field.fieldId)?.name || ""}
                  value={value || ""}
                />
              );
            })}
        </KeyValueGroup>
      </ProductResult>
    );
  },
);
