import { Map as IMap } from "immutable";
import _ from "lodash";
import * as T from "types/engine-types";
import { Configuration } from "config";
import { findUsStateByTwoLetterCode } from "features/us-states";
import { findUsCountyByFiveDigitCode } from "features/us-counties";
import { getObjectRefLabel, ObjectDetails } from "features/objects";
import { unreachable, YMDtoMDY } from "features/utils";

export const ALL_FIELD_CONDITION_TYPES: readonly T.FieldCondition["type"][] = [
  "parent-field-is-blank",
  "parent-field-is-not-blank",
  "parent-field-has-value",
  "parent-field-has-not-value",
  "parent-field-is-one-of",
  "parent-field-is-not-one-of",
];

export function getFieldConditionTypeName(t: T.FieldCondition["type"]): string {
  switch (t) {
    case "parent-field-is-blank":
      return "Is Blank";
    case "parent-field-is-not-blank":
      return "Is Not Blank";
    case "parent-field-has-value":
      return "Equals";
    case "parent-field-has-not-value":
      return "Does Not Equal";
    case "parent-field-is-one-of":
      return "Is One Of";
    case "parent-field-is-not-one-of":
      return "Is Not One Of";
  }
}

export function evaluateFieldCondition(
  fieldId: T.FieldId,
  fieldsById: IMap<T.FieldId, T.BaseFieldDefinition>,
  fieldValuesById: IMap<T.FieldId, T.FieldValue | null>,
): boolean {
  const field = fieldsById.get(fieldId)!;
  const conditions = field.conditions;

  if (!conditions || !conditions?.length) {
    return true;
  }

  return conditions?.every((condition) => {
    // Check if parent field is disabled
    // Note: this may recurse infinitely if config is set up incorrectly
    if (
      !evaluateFieldCondition(
        condition.parentFieldId,
        fieldsById,
        fieldValuesById,
      )
    ) {
      // Disable field if parent is disabled
      return false;
    }

    const parentValue = fieldValuesById.get(condition.parentFieldId);

    switch (condition.type) {
      case "parent-field-is-blank": {
        return !parentValue;
      }

      case "parent-field-is-not-blank": {
        return !!parentValue;
      }
      case "parent-field-has-not-value": {
        if (!parentValue) {
          return false;
        }

        return !fieldValuesEqual(parentValue, condition.value);
      }
      case "parent-field-has-value": {
        if (!parentValue) {
          return false;
        }

        return fieldValuesEqual(parentValue, condition.value);
      }
      case "parent-field-is-one-of": {
        if (!parentValue) {
          return false;
        }

        for (const value of condition.values) {
          if (fieldValuesEqual(parentValue, value)) return true;
        }

        return false;
      }
      case "parent-field-is-not-one-of": {
        if (!parentValue) {
          return false;
        }

        for (const value of condition.values) {
          if (fieldValuesEqual(parentValue, value)) return false;
        }

        return true;
      }
    }
  });
}

// Checks if two field values are equivalent when used as inputs to engine
// execution. Note that, in some cases, two field values may be distinct in the
// UI but "equal" under this test (e.g. 12-month durations and 1-year durations).
export function fieldValuesEqual(
  a: T.FieldValue | null,
  b: T.FieldValue | null,
): boolean {
  if (a === null || b === null) {
    return a === b;
  }

  switch (a.type) {
    case "enum":
      if (b.type !== "enum") {
        return false;
      }

      return a.variantId === b.variantId;

    case "object-ref":
      if (b.type !== "object-ref") {
        return false;
      }

      return (
        a.objectRef.type === b.objectRef.type &&
        a.objectRef.id === b.objectRef.id
      );

    case "string":
      if (b.type !== "string") {
        return false;
      }

      return a.value === b.value;

    case "number":
      if (b.type !== "number") {
        return false;
      }

      // TODO This may incorrectly return false if we serialize non-integral
      // values with trailing zeros.
      // We may need a BigDecimal JS library.
      return a.value === b.value;

    case "duration":
      if (b.type !== "duration") {
        return false;
      }

      const normalizedA = normalizeDurationFieldValue(a);
      const normalizedB = normalizeDurationFieldValue(b);

      return (
        normalizedA.unit === normalizedB.unit &&
        normalizedA.count === normalizedB.count
      );

    case "date":
      if (b.type !== "date") {
        return false;
      }

      return a.value === b.value;
  }
}

// Sort field values for displaying to user
export function sortedFieldValues(
  config: Configuration,
  valueType: T.FieldValueType,
  values: T.FieldValue[],
): T.FieldValue[] {
  return _.sortBy(values, (v) => fieldValueSortKey(config, valueType, v));
}

// Remove duplicate field values
export function uniqueFieldValues(
  config: Configuration,
  valueType: T.FieldValueType,
  values: T.FieldValue[],
): T.FieldValue[] {
  return _.uniqBy(values, (v) => fieldValueSortKey(config, valueType, v));
}

export function fieldValueSortKey(
  config: Configuration,
  valueType: T.FieldValueType,
  value: T.FieldValue,
): unknown {
  switch (valueType.type) {
    case "enum":
      if (value.type !== "enum") {
        throw new Error("expected enum field value");
      }

      const enumType = config.enumTypesById.get(valueType.enumTypeId)!;
      return enumType.variants.findIndex((v) => v.id === value.variantId);
    case "object-ref":
      if (value.type !== "object-ref") {
        throw new Error("expected object ref field value");
      }

      return `${value.objectRef.type}---${value.objectRef.id}`;
    case "string":
      if (value.type !== "string") {
        throw new Error("expected string field value");
      }

      return value.value;
    case "number":
      if (value.type !== "number") {
        throw new Error("expected number field value");
      }

      return +value.value;
    case "duration":
      if (value.type !== "duration") {
        throw new Error("expected duration field value");
      }

      const normalized = normalizeDurationFieldValue(value);
      return `${normalized.unit}----${normalized.count}`;
    case "date":
      if (value.type !== "date") {
        throw new Error("expected date field value");
      }

      return value.value;
    case "header":
      return null;
  }
}

export function getEquivalentMinimumFromDays(
  minDays: number,
  unit: T.DurationUnit,
): number {
  switch (unit) {
    case "days":
      return minDays;
    case "weeks":
      return Math.ceil(minDays / 7);
    case "fortnights":
      return Math.ceil(minDays / 14);
    case "half-months":
    case "months":
    case "quarters":
    case "years":
      throw new Error(`Cannot infer a minimum ${unit} from min days`);
  }
}

export function getEquivalentMaximumFromDays(
  maxDays: number,
  unit: T.DurationUnit,
): number {
  switch (unit) {
    case "days":
      return maxDays;
    case "weeks":
      return Math.floor(maxDays / 7);
    case "fortnights":
      return Math.floor(maxDays / 14);
    case "half-months":
    case "months":
    case "quarters":
    case "years":
      throw new Error(`Cannot infer a maximum ${unit} from max days`);
  }
}

export function getEquivalentMinimumFromMonths(
  minMonths: number,
  unit: T.DurationUnit,
): number {
  switch (unit) {
    case "days":
    case "weeks":
    case "fortnights":
      throw new Error(`Cannot infer a minimum ${unit} from min months`);
    case "half-months":
      // Round to handle possible floating point error
      return Math.round(minMonths * 2);
    case "months":
      return minMonths;
    case "quarters":
      // return Math.ceil(minMonths / 3);
      return minMonths / 3;
    case "years":
      // return Math.ceil(minMonths / 12);
      return minMonths / 12;
  }
}

export function getEquivalentMaximumFromMonths(
  maxMonths: number,
  unit: T.DurationUnit,
): number {
  switch (unit) {
    case "days":
    case "weeks":
    case "fortnights":
      throw new Error(`Cannot infer a maximum ${unit} from max months`);
    case "half-months":
      // Round to handle possible floating point error
      return Math.round(maxMonths * 2);
    case "months":
      return maxMonths;
    case "quarters":
      // return Math.floor(maxMonths / 3);
      return maxMonths / 3;
    case "years":
      // return Math.floor(maxMonths / 12);
      return maxMonths / 12;
  }
}

function assertFieldValueType<Type extends T.FieldValue["type"]>(
  fieldValue: T.FieldValue,
  type: Type,
): asserts fieldValue is T.FieldValue & { type: Type } {
  if (fieldValue.type !== type) {
    throw new Error(
      `expected fieldValue.type to be ${type}, found ${fieldValue.type}`,
    );
  }
}

export type CombinedFieldValueWithValueType = {
  [K in T.FieldValue["type"]]: {
    type: K;
    fieldValue: T.FieldValue & { type: K };
    valueType: T.FieldValueType & { type: K };
  };
}[T.FieldValue["type"]];

export function combineFieldValueWithValueType(
  valueType: T.FieldValueType,
  fieldValue: T.FieldValue,
): CombinedFieldValueWithValueType {
  switch (valueType.type) {
    case "string": {
      assertFieldValueType(fieldValue, valueType.type);
      return { type: valueType.type, fieldValue, valueType };
    }
    case "number": {
      assertFieldValueType(fieldValue, valueType.type);
      return { type: valueType.type, fieldValue, valueType };
    }
    case "enum": {
      assertFieldValueType(fieldValue, valueType.type);
      if (fieldValue.enumTypeId !== valueType.enumTypeId) {
        throw new Error(
          `enum value has enum type id ${fieldValue.enumTypeId}, but value type has enum type id ${valueType.enumTypeId}`,
        );
      }
      return { type: valueType.type, fieldValue, valueType };
    }
    case "object-ref": {
      assertFieldValueType(fieldValue, valueType.type);
      if (fieldValue.objectRef.type !== valueType.objectType) {
        // using type declarations below to get around errors because `fieldValue.objectRef.type` has type `never`.
        const valueObjectType: T.ObjectRef["type"] = fieldValue.objectRef.type;
        const valueTypeObjectType: T.ObjectType = valueType.objectType;
        throw new Error(
          `object ref value has type ${valueObjectType}, but value type has object type ${valueTypeObjectType}`,
        );
      }
      return { type: valueType.type, fieldValue, valueType };
    }
    case "duration": {
      assertFieldValueType(fieldValue, valueType.type);
      return { type: valueType.type, fieldValue, valueType };
    }
    case "date": {
      assertFieldValueType(fieldValue, valueType.type);
      return { type: valueType.type, fieldValue, valueType };
    }
    case "header": {
      throw new Error(
        `cannot combine header value type ${JSON.stringify(
          valueType,
        )} with a field value`,
      );
    }
    default: {
      unreachable(valueType);
    }
  }
}

export function fieldValueToString(
  config: Configuration,
  objectDetails: ObjectDetails,
  valueType: T.FieldValueType,
  fieldValue: T.FieldValue | null,
): string | null {
  if (fieldValue === null) {
    return null;
  }

  switch (valueType.type) {
    case "string":
      if (fieldValue.type !== "string") {
        return null;
      }

      return stringFieldValueToString(valueType, fieldValue);
    case "number":
      if (fieldValue.type !== "number") {
        return null;
      }
      return numberFieldValueToString(valueType, fieldValue);
    case "enum":
      if (fieldValue.type !== "enum") {
        return null;
      }

      if (valueType.enumTypeId !== fieldValue.enumTypeId) {
        return null;
      }

      const enumType = config.enumTypesById.get(valueType.enumTypeId);

      if (!enumType) {
        return null;
      }

      const variant = enumType.variants.find(
        (v) => v.id === fieldValue.variantId,
      );
      return variant ? variant.name : null;
    case "object-ref":
      if (fieldValue.type !== "object-ref") {
        return null;
      }

      if (valueType.objectType !== fieldValue.objectRef.type) {
        return null;
      }

      return getObjectRefLabel(objectDetails, fieldValue.objectRef);
    case "duration":
      if (fieldValue.type !== "duration") {
        return null;
      }

      return durationValueToString(fieldValue);
    case "date":
      if (fieldValue.type !== "date") {
        return null;
      }

      return dateFieldValueToString(valueType, fieldValue);
    case "header":
      return null;
  }
}

export function dateFieldValueToString(
  valueType: T.FieldValueType.Date,
  fieldValue: T.FieldValue.Date,
): string {
  return YMDtoMDY(fieldValue.value);
}

export function numberFieldValueToString(
  valueType: T.FieldValueType.Number,
  fieldValue: T.FieldValue.Number,
): string {
  let prefix = "";
  let suffix = "";

  switch (valueType.style) {
    case "plain":
      break;
    case "percent":
      suffix = "%";
      break;
    case "dollar":
      prefix = "$";
      break;
  }

  const numberFormat = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: valueType.precision,
  });
  return (prefix + numberFormat.format(+fieldValue.value) + suffix).replaceAll(
    "$-",
    "-$",
  );
}

function stringFieldValueToString(
  valueType: T.FieldValueType.String,
  fieldValue: T.FieldValue.String,
): string {
  switch (valueType.format) {
    case "plain":
      return fieldValue.value;
    case "us-state-code":
      if (!fieldValue.value) {
        return "";
      }

      return (
        findUsStateByTwoLetterCode(fieldValue.value)?.name ||
        "<Unknown US State>"
      );
    case "us-county-code": {
      if (!fieldValue.value) {
        return "";
      }

      const county = findUsCountyByFiveDigitCode(fieldValue.value);

      if (!county) {
        return "<Unknown US County>";
      }

      return `${county.usState.twoLetterCode} - ${county.name}`;
    }
  }
}

export function durationValueToString(duration: T.DurationValue): string {
  if (+duration.count === 1) {
    return `1 ${durationUnitToString(duration.unit, false)}`;
  }

  return `${duration.count} ${durationUnitToString(duration.unit, true)}`;
}

type NormalizedDurationFieldValue =
  | (T.FieldValue.Duration & { unit: "days" })
  | (T.FieldValue.Duration & { unit: "months" });

export function normalizeDurationFieldValue(
  x: T.FieldValue.Duration,
): NormalizedDurationFieldValue {
  switch (x.unit) {
    case "days":
      return {
        type: "duration",
        unit: "days",
        count: x.count,
      };
    case "weeks":
      return {
        type: "duration",
        unit: "days",
        count: String(+x.count * 7) + "",
      };
    case "fortnights":
      return {
        type: "duration",
        unit: "days",
        count: String(+x.count * 14) + "",
      };
    case "half-months":
      return {
        type: "duration",
        unit: "months",
        count: String(+x.count * 0.5) + "",
      };
    case "months":
      return {
        type: "duration",
        unit: "months",
        count: x.count,
      };
    case "quarters":
      return {
        type: "duration",
        unit: "months",
        count: String(+x.count * 3) + "",
      };
    case "years":
      return {
        type: "duration",
        unit: "months",
        count: String(+x.count * 12) + "",
      };
  }
}

export function durationUnitToString(
  unit: T.DurationUnit,
  plural?: boolean,
): string {
  if (plural === undefined) {
    switch (unit) {
      case "days":
        return "day(s)";
      case "weeks":
        return "week(s)";
      case "fortnights":
        return "fortnight(s)";
      case "half-months":
        return "half month(s)";
      case "months":
        return "month(s)";
      case "quarters":
        return "quarter(s)";
      case "years":
        return "year(s)";
    }
  }

  if (plural) {
    switch (unit) {
      case "days":
        return "days";
      case "weeks":
        return "weeks";
      case "fortnights":
        return "fortnights";
      case "half-months":
        return "half months";
      case "months":
        return "months";
      case "quarters":
        return "quarters";
      case "years":
        return "years";
    }
  }

  switch (unit) {
    case "days":
      return "day";
    case "weeks":
      return "week";
    case "fortnights":
      return "fortnight";
    case "half-months":
      return "half month";
    case "months":
      return "month";
    case "quarters":
      return "quarter";
    case "years":
      return "year";
  }
}

export function stringToDurationUnit(s: string): T.DurationUnit | null {
  switch (s) {
    case "day":
    case "days":
    case "day(s)":
      return "days";
    case "week":
    case "weeks":
    case "week(s)":
      return "weeks";
    case "fortnight":
    case "fortnights":
    case "fortnight(s)":
      return "fortnights";
    case "half month":
    case "half months":
    case "half month(s)":
      return "half-months";
    case "month":
    case "months":
    case "month(s)":
      return "months";
    case "quarter":
    case "quarters":
    case "quarter(s)":
      return "quarters";
    case "year":
    case "years":
    case "year(s)":
      return "years";
  }

  return null;
}

type expandedFields = {
  header: string;
  fields: T.BaseFieldDefinition[];
};

export type expandedFieldsArray = expandedFields[];
