import _ from "lodash";
import React, {
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import { useVirtual } from "react-virtual";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import * as T from "types/engine-types";
import { Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import * as Api from "api";
import { localAccessId } from "features/access-id";
import SwapVertIcon from "@material-ui/icons/SwapVert";

export default function ProductsList({
  products,
  editMode,
  createMode,
  pricingProfileId,
  setProductsToAdd,
  productsToAdd,
}: {
  products: T.ProductHeader[];
  setProductsToAdd: Dispatch<SetStateAction<T.ProductHeader[]>>;
  productsToAdd: T.ProductHeader[];
  editMode: Boolean;
  createMode: Boolean;
  pricingProfileId?: T.PricingProfileId;
}) {
  const parentRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [profileProducts, setProfileProducts] = useState<T.ProductHeader[]>([]);
  const productsToList = createMode || editMode ? products : profileProducts;
  const [filteredProducts, setFilteredProducts] =
    useState<T.ProductHeader[]>(productsToList);
  const [productsFetched, setProductsFetched] = useState<
    T.PricingProfileId | undefined
  >();
  const [investorsLoadState] = Api.useInvestors();
  const [sortDirection, setSortDirection] = useState<string>("");
  const [sortColumn, setSortColumn] = useState<string>("");
  const [productsLoading, setProductsLoading] = useState(false);
  const [productIds, setProductIds] = useState<T.ProductId[]>([]);

  function investorsCode(id: string): string {
    if (investorsLoadState.status === "loaded") {
      return investorsLoadState.value.find((investor) => investor.id === id)!
        .code;
    }
    return "";
  }

  useEffect(() => {
    if (
      pricingProfileId &&
      (!productsFetched || productsFetched !== pricingProfileId) &&
      !productsLoading
    ) {
      const fetchData = async () => {
        setProductsLoading(true);
        const productsIds = await Api.listPricingProfileProducts(
          pricingProfileId,
        );
        setProductIds(productsIds);
        setProductsFetched(pricingProfileId);
        setProductsLoading(false);
      };
      fetchData();
    }
  }, [
    productsFetched,
    products,
    pricingProfileId,
    setProductsToAdd,
    productsLoading,
    productIds,
  ]);

  useEffect(() => {
    if (!!productIds.length) {
      const newProfileProducts = products.filter((p: T.ProductHeader) =>
        productIds.includes(p.id),
      );
      setProfileProducts(newProfileProducts);
      setProductsToAdd(newProfileProducts);
    }
  }, [products, productIds, setProductsToAdd]);

  useEffect(() => {
    function investorIdsFilteredByInvestorCode(prefix: string): string[] {
      const codes: string[] = [];
      const ids: string[] = [];
      if (investorsLoadState.status === "loaded") {
        for (const investor of investorsLoadState.value) {
          codes.push(investor.code);
          ids.push(investor.id as string);
        }
      }
      const res = [];
      for (let i = 0; i < codes.length; i++) {
        if (codes[i].startsWith(prefix.toUpperCase())) {
          res.push(ids[i]);
        }
      }
      return res;
    }

    setFilteredProducts(
      productsToList.filter((p: T.ProductHeader) => {
        const ids = investorIdsFilteredByInvestorCode(searchTerm);
        if (ids.length === 0) {
          return p.name.toLowerCase().includes(searchTerm.toLowerCase());
        } else {
          return (
            p.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            ids.includes(p.investorId as string)
          );
        }
      }),
    );
  }, [productsToList, searchTerm, investorsLoadState]);

  const sortBy = (column: keyof T.ProductHeader) => {
    const sortedProducts = filteredProducts.sort((a, b) => {
      switch (column) {
        case "investorId":
          if (sortColumn === "Investors") {
            if (sortDirection === "Asc") {
              setSortDirection("Desc");
              return investorsCode(b[column] as string).localeCompare(
                investorsCode(a[column] as string),
              );
            }
            if (sortDirection === "Desc") {
              setSortDirection("Asc");
              return investorsCode(a[column] as string).localeCompare(
                investorsCode(b[column] as string),
              );
            }
          }
          if (sortColumn !== "Investors") {
            setSortColumn("Investors");
            setSortDirection("Asc");
            return investorsCode(a[column] as string).localeCompare(
              investorsCode(b[column] as string),
            );
          }
          return 0;
          break;
        case "name":
          if (sortColumn === "Products") {
            if (sortDirection === "Asc") {
              setSortDirection("Desc");
              return b[column].localeCompare(a[column]);
            }
            if (sortDirection === "Desc") {
              setSortDirection("Asc");
              return a[column].localeCompare(b[column]);
            }
          }
          if (sortColumn !== "Products") {
            setSortColumn("Products");
            setSortDirection("Asc");
            return a[column].localeCompare(b[column]);
          }
          return 0;
          break;
        case "activationTimestamp":
          return 0;
          break;
        case "deactivationTimestamp":
          return 0;
          break;
        case "code":
          return 0;
          break;
        case "id":
          return 0;
          break;
        case "description":
          return 0;
          break;
      }
    });

    setFilteredProducts(sortedProducts);
  };

  const rowVirtualizer = useVirtual({
    size: filteredProducts?.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, []),
  });
  const accessId = localAccessId();
  return (
    <Table
      style={{ flex: "2 0 24%", borderRight: "1px solid rgba(0,0,0,.15)" }}
    >
      <TableActions
        style={{
          padding: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
          }}
        >
          <SearchInput
            label="products"
            requireDispatch={false}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <ResultsCount>
          {filteredProducts.length} <VisibilityIcon />
          <span className="separator">|</span>
          {productsToAdd.length} <CheckIcon />
          <span className="separator">|</span>
          {products.length} Total
        </ResultsCount>
      </TableActions>

      {editMode || createMode ? (
        <>
          <TableHeader>
            <TableHeaderCell style={{ flexBasis: "50px" }}>
              <Checkbox
                checked={filteredProducts.every((p) => {
                  return productsToAdd.map((p) => p.id).includes(p.id);
                })}
                onChange={(e) => {
                  const pruned = _.without(productsToAdd, ...filteredProducts);
                  if (e.target.checked) {
                    setProductsToAdd([...pruned, ...filteredProducts]);
                  } else {
                    setProductsToAdd(pruned);
                  }
                }}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              />
            </TableHeaderCell>

            <TableHeaderCell style={{ flexBasis: "calc(50% - 50px)" }}>
              Products{" "}
              <SwapVertIcon
                style={{
                  marginLeft: "2px",
                  color: sortColumn === "Products" ? "#90d18c" : "inherit",
                }}
                onClick={() => sortBy("name")}
              />
            </TableHeaderCell>
            <TableHeaderCell style={{ flexBasis: "50%" }}>
              Investors{" "}
              <SwapVertIcon
                style={{
                  marginLeft: "2px",
                  color: sortColumn === "Investors" ? "#90d18c" : "inherit",
                }}
                onClick={() => sortBy("investorId")}
              />
            </TableHeaderCell>
          </TableHeader>

          {filteredProducts.length ? (
            <TableBodyWrapper ref={parentRef}>
              <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
                {rowVirtualizer.virtualItems.map((virtualRow) => (
                  <TableRow
                    key={virtualRow.index}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <TableCell style={{ flexBasis: "54px" }}>
                      <Checkbox
                        checked={productsToAdd.includes(
                          filteredProducts[virtualRow.index],
                        )}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        onChange={(e, checked) => {
                          if (checked) {
                            setProductsToAdd([
                              ...productsToAdd,
                              filteredProducts[virtualRow.index],
                            ]);
                          } else {
                            setProductsToAdd(
                              _.without(
                                productsToAdd,
                                filteredProducts[virtualRow.index],
                              ),
                            );
                          }
                        }}
                      />
                    </TableCell>

                    <TableCell style={{ flexBasis: "calc(50% - 54px)" }}>
                      {filteredProducts[virtualRow.index].name}
                    </TableCell>
                    <TableCell style={{ flexBasis: "50%" }}>
                      {investorsCode(
                        filteredProducts[virtualRow.index].investorId,
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableBodyWrapper>
          ) : (
            <p
              style={{ marginTop: "14px", textAlign: "center", opacity: 0.65 }}
            >
              No products have been assigned to this pricing profile.
            </p>
          )}
        </>
      ) : (
        <>
          <TableHeader>
            <TableHeaderCell style={{ flexBasis: "calc(50% - 10px)" }}>
              Products
              <SwapVertIcon
                style={{
                  marginLeft: "2px",
                  color: sortColumn === "Products" ? "#90d18c" : "inherit",
                }}
                onClick={() => sortBy("name")}
              />
            </TableHeaderCell>
            <TableHeaderCell style={{ flexBasis: "50%" }}>
              Investors
              <SwapVertIcon
                style={{
                  marginLeft: "2px",
                  color: sortColumn === "Investors" ? "#90d18c" : "inherit",
                }}
                onClick={() => sortBy("investorId")}
              />
            </TableHeaderCell>
          </TableHeader>

          {filteredProducts.length ? (
            <TableBodyWrapper ref={parentRef}>
              <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
                {rowVirtualizer.virtualItems.map((virtualRow) => (
                  <TableRow
                    key={virtualRow.index}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <TableCell style={{ flexBasis: "50%" }}>
                      <Link
                        to={`/c/${accessId}/products/${
                          filteredProducts[virtualRow.index].id
                        }`}
                      >
                        {filteredProducts[virtualRow.index].name}
                      </Link>
                    </TableCell>
                    <TableCell style={{ flexBasis: "50%" }}>
                      {investorsCode(
                        filteredProducts[virtualRow.index].investorId,
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableBodyWrapper>
          ) : (
            <p
              style={{ marginTop: "14px", textAlign: "center", opacity: 0.65 }}
            >
              No products have been assigned to this pricing profile.
            </p>
          )}
        </>
      )}
    </Table>
  );
}
