import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { genericReactMemo } from "features/utils";

export const Dropdown = genericReactMemo(
  <T extends string | number>({
    className,
    margin,
    label,
    backgroundColor,
    options,
    getOptionLabel,
    value,
    error,
    setValue,
    disabled = false,
  }: {
    className?: string;
    margin?: "normal" | "dense";
    backgroundColor?: string;
    label: string;
    options: readonly T[];
    getOptionLabel: (option: T) => string;
    value: T;
    error?: boolean;
    setValue: (v: T) => void;
    disabled?: boolean;
  }) => {
    return (
      <FormControl className={className} margin={margin} variant="outlined">
        <InputLabel>{label}</InputLabel>
        <Select
          style={backgroundColor ? { backgroundColor } : {}}
          label={label}
          value={value}
          error={error === true}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setValue(event.target.value as T);
          }}
          disabled={disabled}
        >
          {options.map((option) => (
            <MenuItem
              className={`${getOptionLabel(option).toLowerCase()}-option`}
              key={option}
              value={option}
            >
              {getOptionLabel(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  },
);

export const SearchableMultiDropdown = genericReactMemo(
  <T,>({
    className,
    label,
    margin,
    options,
    getOptionLabel,
    value,
    error,
    setValue,
    disabled = false,
  }: {
    className?: string;
    label?: string;
    margin?: "normal" | "dense";
    options: readonly T[];
    getOptionLabel: (option: T) => string;
    value: T[] | undefined;
    error?: boolean;
    setValue: (v: T[] | null) => void;
    disabled?: boolean;
  }) => {
    return (
      <Autocomplete
        disabled={disabled}
        multiple
        disableCloseOnSelect={true}
        className={className}
        options={[...options]}
        getOptionLabel={getOptionLabel}
        value={value}
        onChange={(e: unknown, v: T[] | null) => {
          setValue(v);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            margin={margin}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            error={error === true}
          />
        )}
      />
    );
  },
);

export const SearchableDropdown = genericReactMemo(
  <T,>({
    className,
    label,
    placeholder,
    margin,
    options,
    getOptionLabel,
    value,
    error,
    required,
    setValue,
    helperText,
    disabled = false,
  }: {
    className?: string;
    label?: string;
    placeholder?: string;
    margin?: "normal" | "dense";
    options: readonly T[];
    getOptionLabel: (option: T) => string;
    value: T | null;
    error?: boolean;
    required?: boolean;
    setValue: (v: T | null) => void;
    helperText?: React.ReactNode;
    disabled?: boolean;
  }) => {
    return (
      <Autocomplete
        disabled={disabled}
        className={className}
        options={[...options]}
        getOptionLabel={getOptionLabel}
        value={value}
        onChange={(e: unknown, v: T | null) => {
          setValue(v);
        }}
        placeholder={placeholder}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            margin={margin}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            error={error === true}
            required={required}
            helperText={helperText}
          />
        )}
      />
    );
  },
);
