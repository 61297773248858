import React, { useCallback, useState, useMemo } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import * as Api from "api";
import {
  SearchableDropdown,
  SearchableMultiDropdown,
} from "design/molecules/dropdown";
import { useSelector, useDispatch } from "react-redux";
import { LoadingOverlay } from "design/atoms/loading-overlay";
import * as T from "types/engine-types";
import { UiValidationError } from "features/utils";
import RateWithColumnsExampleTable from "../rate-with-columns-example-table";
import RateWithLockExampleTable from "../rate-with-lock-example-table";
import { useEffect } from "react";
import { getFieldsFromStage } from "config";
import _ from "lodash";
import { NumberFieldDefaultValueEditor } from "../default-pricing-filters";
import { assertNumberFieldValueType } from "types/field-value-types";
import {
  expandedConfigSelector,
  loadAppInit,
} from "features/application-initialization";
import { setDynamicFilters } from "features/pricing-summaries";

const SettingsButton = React.memo(({ disabled }: { disabled: boolean }) => {
  const dispatch = useDispatch();
  const config = useSelector(expandedConfigSelector);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [interestRateFieldId, setInterestRateFieldId] =
    useState<T.FieldId | null>(
      config.settings.priceScenarioTable
        ? config.settings.priceScenarioTable.adjustedRateFieldId
        : null,
    );

  const [rateLockPeriodFieldId, setRateLockPeriodFieldId] =
    useState<T.FieldId | null>(
      config.settings.priceScenarioTable?.type === "rate-with-lock-period"
        ? config.settings.priceScenarioTable.adjustedRateLockPeriodFieldId
        : null,
    );

  const [priceFieldId, setPriceFieldId] = useState<T.FieldId | null>(
    config.settings.priceScenarioTable?.type === "rate-with-lock-period"
      ? config.settings.priceScenarioTable.adjustedPriceFieldId
      : null,
  );

  type PriceScenarioTableOptionId =
    | "rate-with-lock-period"
    | "rate-with-columns";

  type PriceScenarioTableOption = {
    label: string;
    id: PriceScenarioTableOptionId;
  };

  const priceScenarioTableOptions = [
    {
      id: "rate-with-lock-period" as PriceScenarioTableOptionId,
      label: "Rate with Lock Period",
    },
    {
      id: "rate-with-columns" as PriceScenarioTableOptionId,
      label: "Rate with Columns",
    },
  ];

  const [configType, setConfigType] = useState<PriceScenarioTableOption | null>(
    priceScenarioTableOptions.find(
      (o) => o.id === config.settings.priceScenarioTable?.type,
    ) || priceScenarioTableOptions[0],
  );

  const getConfigTypeLabel = useCallback(
    (type: PriceScenarioTableOption) => type.label,
    [],
  );

  let baseColumns: T.FieldId[];
  switch (config.settings.priceScenarioTable?.type) {
    case "rate-with-lock-period":
      baseColumns = config.settings.priceScenarioTable.extraColumns;
      break;
    case "rate-with-columns":
      baseColumns = config.settings.priceScenarioTable.columns;
      break;
    default:
      baseColumns = [];
      break;
  }

  const [columnIds, setColumnIds] = useState<T.FieldId[] | null>(baseColumns);

  const tooManyColumns = !!(columnIds && columnIds.length > 5);

  const [minRate, setMinRate] = useState(
    config?.settings.defaultPricingFilters.minRate || null,
  );
  const [maxRate, setMaxRate] = useState(
    config?.settings.defaultPricingFilters.maxRate || null,
  );
  const [minPrice, setMinPrice] = useState(
    config?.settings.defaultPricingFilters.minPrice || null,
  );
  const [maxPrice, setMaxPrice] = useState(
    config?.settings.defaultPricingFilters.maxPrice || null,
  );

  const interestRateField =
    interestRateFieldId && config?.allFieldsById.get(interestRateFieldId);
  const priceField = priceFieldId && config?.allFieldsById.get(priceFieldId);

  const [isValid, setIsValid] = useState<boolean>();
  useEffect(() => {
    if (!configType) {
      setIsValid(false);
      return;
    }

    switch (configType.id) {
      case "rate-with-lock-period":
        setIsValid(
          !!interestRateFieldId &&
            !!rateLockPeriodFieldId &&
            !!priceFieldId &&
            !tooManyColumns,
        );
        break;
      case "rate-with-columns":
        setIsValid(!!interestRateFieldId && !tooManyColumns);
        break;
      default:
        setIsValid(false);
        break;
    }
  }, [
    isValid,
    configType,
    interestRateFieldId,
    rateLockPeriodFieldId,
    priceFieldId,
    tooManyColumns,
  ]);

  const getFieldName = useCallback(
    (id: T.FieldId) => config.allFieldsById.get(id)?.name || "<Unknown Field>",
    [config],
  );

  // fields that can be used in the price scenario table
  const availableFields = useMemo(() => {
    // we don't let you pick fields that are calculated or inserted
    // before the rate sheet split, since they would have the same
    // value for every price scenario
    const stagesBeginningWithRateSheetSplit = _.dropWhile(
      config.stages,
      (stage) => stage.kind !== "split-on-rate-sheet",
    );

    const fields = stagesBeginningWithRateSheetSplit.flatMap((stage) =>
      getFieldsFromStage(config.original, stage),
    );

    return _.sortBy(fields, (f) => f.name);
  }, [config]);

  const numberFieldIds = useMemo(
    () =>
      availableFields
        .filter((f) => f.valueType.type === "number")
        .map((f) => f.id),
    [availableFields],
  );

  const durationFieldIds = useMemo(
    () =>
      availableFields
        .filter((f) => f.valueType.type === "duration")
        .map((f) => f.id),
    [availableFields],
  );

  const availableFieldIds = useMemo(
    () => availableFields.map((f) => f.id),
    [availableFields],
  );

  async function save() {
    setLoading(true);
    const defaultPricingFilters: T.DefaultPricingFilters = {
      minRate: minRate || null,
      maxRate: maxRate || null,
      minPrice:
        (configType?.id === "rate-with-lock-period" && minPrice) || null,
      maxPrice:
        (configType?.id === "rate-with-lock-period" && maxPrice) || null,
    };
    if (configType?.id === "rate-with-lock-period") {
      if (!interestRateFieldId || !rateLockPeriodFieldId || !priceFieldId) {
        throw new UiValidationError(
          "cannot save when not all fields are filled in",
        );
      }
      dispatch(
        setDynamicFilters({
          type: "rate-with-lock-period",
          adjustedRateFieldId: {
            id: interestRateFieldId,
            values: {
              min: minRate,
              max: maxRate,
            },
          },
          adjustedRateLockPeriodFieldId: {
            id: rateLockPeriodFieldId,
            rateLockPeriods: null,
          },
          adjustedPriceFieldId: {
            id: priceFieldId,
            values: {
              min: minPrice,
              max: maxPrice,
            },
          },
          extraColumns: columnIds || [],
          columns: null,
          columnsValues: null,
        }),
      );

      await Api.updateClientSettings({
        priceScenarioTable: {
          type: "rate-with-lock-period",
          adjustedRateFieldId: interestRateFieldId,
          adjustedRateLockPeriodFieldId: rateLockPeriodFieldId,
          adjustedPriceFieldId: priceFieldId,
          extraColumns: columnIds || [],
        },
        defaultPricingFilters,
        pipelineFields: config.settings.pipelineFields,
      });
    } else if (configType?.id === "rate-with-columns") {
      if (!interestRateFieldId) {
        throw new UiValidationError(
          "cannot save when not all fields are filled in",
        );
      }

      dispatch(
        setDynamicFilters({
          type: "rate-with-columns",
          adjustedRateFieldId: {
            id: interestRateFieldId,
            values: {
              min: minRate,
              max: maxRate,
            },
          },
          adjustedRateLockPeriodFieldId: null,
          adjustedPriceFieldId: null,
          extraColumns: columnIds || [],
          columns: null,
          columnsValues: null,
        }),
      );

      await Api.updateClientSettings({
        priceScenarioTable: {
          type: "rate-with-columns",
          adjustedRateFieldId: interestRateFieldId,
          columns: columnIds || [],
        },
        defaultPricingFilters,
        pipelineFields: config.settings.pipelineFields,
      });
    }
    setLoading(false);
    setOpen(false);
    dispatch(loadAppInit());
  }

  return (
    <>
      <Button
        disabled={disabled}
        variant="outlined"
        startIcon={<SettingsIcon />}
        onClick={() => setOpen(!open)}
        style={{ marginRight: "16px" }}
      >
        Settings
      </Button>

      <Dialog
        maxWidth="xl"
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
      >
        <LoadingOverlay when={loading} />

        <DialogTitle>Rate Sheet Settings</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex" }}>
            <div style={{ flex: "1 1 auto" }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                {configType?.id === "rate-with-lock-period" && (
                  <RateWithLockExampleTable
                    columnIds={columnIds}
                    getFieldName={getFieldName}
                  />
                )}

                {configType?.id === "rate-with-columns" && (
                  <RateWithColumnsExampleTable
                    adjustedRateFieldId={interestRateFieldId}
                    columnIds={columnIds}
                    getFieldName={getFieldName}
                  />
                )}
              </Box>
            </div>

            <div style={{ marginLeft: "16px", flex: "0 1 600px" }}>
              <Box width="400px">
                <SearchableDropdown<PriceScenarioTableOption>
                  label="Settings Configuration"
                  options={priceScenarioTableOptions}
                  getOptionLabel={getConfigTypeLabel}
                  value={configType}
                  setValue={setConfigType}
                  required
                />
              </Box>

              {configType?.id === "rate-with-lock-period" && (
                <>
                  <Box my={2} width="400px">
                    <SearchableDropdown<T.FieldId>
                      label="Adjusted Rate Lock Period Field"
                      options={durationFieldIds}
                      getOptionLabel={getFieldName}
                      value={rateLockPeriodFieldId}
                      setValue={setRateLockPeriodFieldId}
                      required
                    />
                  </Box>

                  <Box my={2} style={{ flex: "1 1 auto", display: "flex" }}>
                    <span style={{ width: "400px", marginRight: "16px" }}>
                      <SearchableDropdown<T.FieldId>
                        label="Adjusted Interest Rate Field"
                        options={numberFieldIds}
                        getOptionLabel={getFieldName}
                        value={interestRateFieldId}
                        setValue={setInterestRateFieldId}
                        required
                      />
                    </span>
                    {interestRateField && (
                      <>
                        <span style={{ width: "150px", marginRight: "16px" }}>
                          <NumberFieldDefaultValueEditor
                            label="Default Min"
                            state={minRate}
                            setState={setMinRate}
                            valueType={assertNumberFieldValueType(
                              interestRateField.valueType,
                              "Rate",
                            )}
                            showErrors={true}
                          />
                        </span>
                        <span style={{ width: "150px", marginRight: "16px" }}>
                          <NumberFieldDefaultValueEditor
                            label="Default Max"
                            state={maxRate}
                            setState={setMaxRate}
                            valueType={assertNumberFieldValueType(
                              interestRateField.valueType,
                              "Rate",
                            )}
                            showErrors={true}
                          />
                        </span>
                      </>
                    )}
                  </Box>
                  <Box my={2} style={{ flex: "1 1 auto", display: "flex" }}>
                    <span style={{ width: "400px", marginRight: "16px" }}>
                      <SearchableDropdown<T.FieldId>
                        label="Adjusted Price Field"
                        options={numberFieldIds}
                        getOptionLabel={getFieldName}
                        value={priceFieldId}
                        setValue={setPriceFieldId}
                        required
                      />
                    </span>

                    {priceField && (
                      <>
                        <span style={{ width: "150px", marginRight: "16px" }}>
                          <NumberFieldDefaultValueEditor
                            label="Default Min"
                            state={minPrice}
                            setState={setMinPrice}
                            valueType={assertNumberFieldValueType(
                              priceField.valueType,
                              "Price",
                            )}
                            showErrors={true}
                          />
                        </span>

                        <span style={{ width: "150px", marginRight: "16px" }}>
                          <NumberFieldDefaultValueEditor
                            label="Default Max"
                            state={maxPrice}
                            setState={setMaxPrice}
                            valueType={assertNumberFieldValueType(
                              priceField.valueType,
                              "Price",
                            )}
                            showErrors={true}
                          />
                        </span>
                      </>
                    )}
                  </Box>

                  <Box width="400px">
                    <SearchableMultiDropdown<T.FieldId>
                      error={tooManyColumns}
                      label="Extra Columns"
                      options={availableFieldIds}
                      getOptionLabel={getFieldName}
                      value={columnIds || []}
                      setValue={setColumnIds}
                    />
                  </Box>
                </>
              )}

              {configType?.id === "rate-with-columns" && (
                <>
                  <Box my={2} style={{ flex: "1 1 auto", display: "flex" }}>
                    <span style={{ width: "400px", marginRight: "16px" }}>
                      <SearchableDropdown<T.FieldId>
                        label="Adjusted Interest Rate Field"
                        options={numberFieldIds}
                        getOptionLabel={getFieldName}
                        value={interestRateFieldId}
                        setValue={setInterestRateFieldId}
                        required
                      />
                    </span>
                    {interestRateField && (
                      <>
                        <span style={{ width: "150px", marginRight: "16px" }}>
                          <NumberFieldDefaultValueEditor
                            label="Default Min"
                            state={minRate}
                            setState={setMinRate}
                            valueType={assertNumberFieldValueType(
                              interestRateField.valueType,
                              "Rate",
                            )}
                            showErrors={true}
                          />
                        </span>

                        <span style={{ width: "150px", marginRight: "16px" }}>
                          <NumberFieldDefaultValueEditor
                            label="Default Max"
                            state={maxRate}
                            setState={setMaxRate}
                            valueType={assertNumberFieldValueType(
                              interestRateField.valueType,
                              "Rate",
                            )}
                            showErrors={true}
                          />
                        </span>
                      </>
                    )}
                  </Box>
                  <Box width="400px">
                    <SearchableMultiDropdown<T.FieldId>
                      error={tooManyColumns}
                      label="Extra Columns"
                      options={availableFieldIds}
                      getOptionLabel={getFieldName}
                      value={columnIds || []}
                      setValue={setColumnIds}
                    />
                  </Box>
                </>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button color="primary" onClick={save} disabled={!isValid}>
            {tooManyColumns ? "Too many columns selected" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default SettingsButton;
