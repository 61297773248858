import { Map as IMap, Set as ISet } from "immutable";
import _ from "lodash";
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import * as T from "types/generated-types";
import { DecoratedRuleHeader } from "types/engine-types";
import CauseLink from "pages/loans/_components/cause-link";
import Accordion from "design/molecules/accordion";
import { Text } from "design/atoms/typography";
import { localAccessId } from "features/access-id";

type AdjustmentValue =
  | T.RateAdjustmentValue
  | T.PriceAdjustmentValue
  | T.MarginAdjustmentValue;

const AdjustmentsSection = React.memo(
  ({
    adjustments,
    finalAdjustments,
    adjustmentKindName,
    rulesById,
    color,
  }: {
    adjustments: ISet<AdjustmentValue>;
    finalAdjustments: ISet<AdjustmentValue>;
    adjustmentKindName: string;
    rulesById: IMap<T.RuleId, DecoratedRuleHeader>;
    color: string;
  }) => {
    const accessId = localAccessId();
    const sortedAdjustments = _.sortBy(adjustments.toArray(), "description");

    let total = 0;

    for (const adj of sortedAdjustments) {
      total += +adj.amount;
    }
    const sortedFinalAdjustments = _.sortBy(
      finalAdjustments.toArray(),
      "description",
    );

    let finalTotal = 0;

    for (const adj of sortedFinalAdjustments) {
      finalTotal += +adj.amount;
    }

    const grandTotal = total + finalTotal;
    return (
      <Accordion
        color={color}
        open={true}
        label={`${adjustmentKindName} Adjustments`}
      >
        {adjustments.size + finalAdjustments.size === 0 && (
          <Text>No {adjustmentKindName.toLowerCase()} adjustments.</Text>
        )}

        {adjustments.size + finalAdjustments.size > 0 && (
          <Table size="small" style={{ width: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell>{adjustmentKindName} Adjustment</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedAdjustments.map((adj) => {
                const rule = rulesById.get(adj.ruleId);
                const ruleName = rule?.name || "<unknown rule>";
                return (
                  <TableRow key={adj.ruleId}>
                    <TableCell>
                      {adj.description}
                      <> - </>
                      <CauseLink
                        title={ruleName}
                        link={`/c/${accessId}/rules/${adj.ruleId}`}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {parseFloat(adj.amount).toFixed(3)}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell style={{ fontWeight: 700 }}>
                  Total {adjustmentKindName} Adjustment
                </TableCell>
                <TableCell align="right" style={{ fontWeight: 700 }}>
                  {total.toFixed(3)}
                </TableCell>
              </TableRow>
              {sortedFinalAdjustments.map((adj) => {
                const rule = rulesById.get(adj.ruleId);
                const ruleName = rule?.name || "<unknown rule>";
                return (
                  <TableRow key={adj.ruleId}>
                    <TableCell>
                      {adj.description}
                      <> - </>
                      <CauseLink
                        title={ruleName}
                        link={`/c/${accessId}/rules/${adj.ruleId}`}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {parseFloat(adj.amount).toFixed(3)}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell style={{ fontWeight: 700 }}>
                  Final Total {adjustmentKindName} Adjustment
                </TableCell>
                <TableCell align="right" style={{ fontWeight: 700 }}>
                  {finalTotal.toFixed(3)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: 700 }}>
                  Grand Total {adjustmentKindName} Adjustment
                </TableCell>
                <TableCell align="right" style={{ fontWeight: 700 }}>
                  {grandTotal.toFixed(3)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Accordion>
    );
  },
);

export default AdjustmentsSection;
