import _ from "lodash";
import React from "react";
import { ObjectListState } from "design/organisms/object-list-editor";

export class ServerValidationError {
  public code: string | null = null;
  public message: string;

  constructor(error: ServerValidationError) {
    this.message = error.message || "This field is invalid.";
    this.code = error.code;

    Object.setPrototypeOf(this, ServerValidationError.prototype);
  }
}

export type ServerValidationErrorFieldValue =
  | null
  | boolean
  | number
  | string
  | ServerValidationErrorFieldValue[]
  | { [key: string]: ServerValidationErrorFieldValue };

export function isStateWithValErrors<T>(
  obj: unknown,
): obj is StateWithValErrors<T> {
  return (obj as StateWithValErrors<T>).domainValidationErrors !== undefined;
}

export interface StateWithValErrors<T extends Object> {
  domainValidationErrors: T;
}

export function stateHasErrors<TObj extends StateWithValErrors<TErr>, TErr>(
  obj: TObj,
): boolean {
  for (const key of Object.keys(obj)) {
    if (Object.keys(obj.domainValidationErrors).includes(key)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return hasDomainValidationErrors(obj.domainValidationErrors);
    }
  }
  return false;
}

// Domain validation errors can take pretty much any shape, so there's no way to
// restrict the types here. We treat them as `any` and examine their keys and
// values recursively to determine if there are any actual errors in the
// validation error object.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasDomainValidationErrors(domainValidationErrors: any): boolean {
  if (!domainValidationErrors) return false;

  for (const key in domainValidationErrors) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const inner = domainValidationErrors[key];

    if (!inner) continue;

    if (_.isArray(inner) && inner.length > 0) return true;

    if (_.isMap(inner) || _.isPlainObject(inner))
      return hasDomainValidationErrors(inner);
  }
  return false;
}

export function anyStateHasErrors<TObj extends StateWithValErrors<TErr>, TErr>(
  list: readonly TObj[] | ObjectListState<TObj>,
): boolean {
  let objects: readonly TObj[] = [];

  if ("objects" in list) {
    objects = list.objects;
  } else {
    objects = list;
  }

  for (const obj of objects) {
    if (stateHasErrors(obj)) return true;
  }
  return false;
}

export function mapErrorListToState<
  TObj extends StateWithValErrors<TErr>,
  TErr extends Object,
>(objects: readonly TObj[], errors: { [key: number]: TErr }): TObj[] {
  const newObjects: TObj[] = [];
  for (let i = 0; i < objects.length; ++i) {
    const newObject = { ...objects[i] };
    if (i in errors) {
      newObject.domainValidationErrors = errors[i];
    } else {
      for (const key in newObject.domainValidationErrors) {
        // This comment was generated when upgrading react-scripts and eslint
        // TODO: fix the lint rule and remove this eslint-disable comment
        // TODO look at this with Ross and see if we can get rid of `any`
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (newObject.domainValidationErrors as any)[key] = [];
      }
    }
    newObjects.push(newObject);
  }
  return newObjects;
}

export function getMessageNode(
  errors: ServerValidationError[],
): React.ReactNode {
  return (
    <>
      {errors.map((v, i) => (
        <span key={i}>
          {v.message}
          <br />
        </span>
      ))}
    </>
  );
}
