import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import ArrowNextIcon from "@material-ui/icons/ArrowForwardIos";

function NextButton({
  id,
  label,
  path,
  list,
}: {
  id: string;
  label: string;
  path: string;
  list: Array<{ id: string }>;
}) {
  const history = useHistory();
  const currentIndex = list && list.findIndex((i) => i.id === id);
  const nextId = list && list[currentIndex + 1]?.id;

  return (
    <Button
      data-selector="next-button"
      style={{ marginLeft: "16px" }}
      variant="outlined"
      endIcon={<ArrowNextIcon />}
      disabled={!nextId}
      onClick={() => history.push(`${path}/${nextId}`)}
    >
      {label}
    </Button>
  );
}

export default NextButton;
