import { ObjectDetails } from "features/objects";
import { Map as IMap } from "immutable";
import * as T from "types/engine-types";
import {
  importEnumTypeId,
  importEnumVariantId,
  importEnumVariantValue,
} from "../enums/import";
import { unreachable, generateSafeId } from "features/utils";
import { getDataTableColumnKey, getPricingProfileKey } from ".";
import { importFieldId, importFieldValueType } from "../fields/import";

export function importRule(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  dataTablesByKey: IMap<string, T.DataTable>,
  objectDetails: ObjectDetailsByKey,
  oldRule: T.Rule | null,
  rule: T.NewRule,
): T.Rule {
  return {
    id: oldRule?.id || ("" as T.RuleId),
    name: rule.name,
    activationTimestamp: rule.activationTimestamp,
    deactivationTimestamp: rule.deactivationTimestamp,
    body: rule.body && {
      precondition:
        rule.body.precondition &&
        importRuleCondition(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          rule.body.precondition,
        ),
      lookup:
        rule.body.lookup &&
        importRuleDataTableLookup(
          enumTypesByKey,
          allFieldIdsByKey,
          dataTablesByKey,
          objectDetails,
          rule.body.lookup,
        ),
      condition: importRuleCondition(
        enumTypesByKey,
        allFieldIdsByKey,
        objectDetails,
        rule.body.condition,
      ),
      action: importRuleAction(
        enumTypesByKey,
        allFieldIdsByKey,
        objectDetails,
        rule.body.action,
      ),
    },
    stageId: rule.stageId,
    productIds: [], // Linked to products in a separate step
  };
}

function importRuleDataTableLookup(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  dataTablesByKey: IMap<string, T.DataTable>,
  objectDetails: ObjectDetailsByKey,
  lookup: T.RuleDataTableLookup,
): T.RuleDataTableLookup {
  return {
    id: generateSafeId() as T.DataTableLookupId, // I don't think there's any benefit to preserving the original ID in this case
    tableId: importDataTableId(dataTablesByKey, lookup.tableId),
    predicates: lookup.predicates.map((pred) =>
      importDataTableColumnPredicate(
        enumTypesByKey,
        allFieldIdsByKey,
        dataTablesByKey,
        objectDetails,
        lookup.tableId,
        pred,
      ),
    ),
    fields: lookup.fields.map((field) =>
      importRuleDataTableLookupFieldDefinition(
        enumTypesByKey,
        dataTablesByKey,
        lookup.tableId,
        field,
      ),
    ),
  };
}

function importDataTableColumnPredicate(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  dataTablesByKey: IMap<string, T.DataTable>,
  objectDetails: ObjectDetailsByKey,
  dataTableKey: string,
  pred: T.DataTableColumnPredicate,
): T.DataTableColumnPredicate {
  switch (pred.kind) {
    case "equals":
      return {
        kind: pred.kind,
        expression: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          pred.expression,
        ),
        other: importDataTableColumnId(
          dataTablesByKey,
          dataTableKey,
          pred.other,
        ),
      };
    case "in-range":
      return {
        kind: pred.kind,
        expression: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          pred.expression,
        ),
        bottom: importRangeBound(
          pred.bottom,
          importDataTableColumnId.bind(null, dataTablesByKey, dataTableKey),
        ),
        top: importRangeBound(
          pred.top,
          importDataTableColumnId.bind(null, dataTablesByKey, dataTableKey),
        ),
      };
  }
}

export function importRuleDataTableLookupFieldDefinition(
  enumTypesByKey: IMap<string, T.EnumType>,
  dataTablesByKey: IMap<string, T.DataTable>,
  tableKey: string,
  field: T.RuleDataTableLookupFieldDefinition,
): T.RuleDataTableLookupFieldDefinition {
  return {
    id: field.id, // Preserve LocalFieldId as is since it's local to the object being imported
    name: field.name,
    valueType: importFieldValueType(enumTypesByKey, field.valueType),
    columnId: importDataTableColumnId(
      dataTablesByKey,
      tableKey,
      field.columnId,
    ),
  };
}

function importRuleCondition(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  objectDetails: ObjectDetailsByKey,
  condition: T.RuleCondition,
): T.RuleCondition {
  switch (condition.type) {
    case "and":
    case "or":
      return {
        type: condition.type,
        children: condition.children.map((child) =>
          importRuleCondition(
            enumTypesByKey,
            allFieldIdsByKey,
            objectDetails,
            child,
          ),
        ),
      };
    case "field-predicate":
      return {
        type: condition.type,
        predicate: importFieldPredicate(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          condition.predicate,
        ),
      };
  }
}

function importFieldPredicate(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  objectDetails: ObjectDetailsByKey,
  predicate: T.FieldPredicate,
): T.FieldPredicate {
  switch (predicate.type) {
    case "generic":
      return importGenericFieldPredicate(allFieldIdsByKey, predicate);
    case "enum":
      return importEnumFieldPredicate(
        enumTypesByKey,
        allFieldIdsByKey,
        predicate,
      );
    case "object-ref":
      return importObjectRefFieldPredicate(
        allFieldIdsByKey,
        objectDetails,
        predicate,
      );
    case "string":
      return importStringFieldPredicate(
        enumTypesByKey,
        allFieldIdsByKey,
        objectDetails,
        predicate,
      );
    case "number":
      return importNumberFieldPredicate(
        enumTypesByKey,
        allFieldIdsByKey,
        objectDetails,
        predicate,
      );
    case "duration":
      return importDurationFieldPredicate(
        enumTypesByKey,
        allFieldIdsByKey,
        objectDetails,
        predicate,
      );
    case "date":
      return importDateFieldPredicate(
        enumTypesByKey,
        allFieldIdsByKey,
        objectDetails,
        predicate,
      );
    default:
      return unreachable(predicate);
  }
}

function importGenericFieldPredicate(
  allFieldIdsByKey: IMap<string, T.FieldId>,
  predicate: T.FieldPredicate.Generic,
): T.FieldPredicate.Generic {
  switch (predicate.op) {
    case "is-blank":
    case "is-not-blank":
      return {
        type: "generic" as const,
        op: predicate.op,
        fieldId: importFieldId(allFieldIdsByKey, predicate.fieldId),
      };
  }
}

function importEnumFieldPredicate(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  predicate: T.FieldPredicate.Enum,
): T.FieldPredicate.Enum {
  switch (predicate.op) {
    case "matches":
    case "does-not-match":
      return {
        type: "enum" as const,
        op: predicate.op,
        fieldId: importFieldId(allFieldIdsByKey, predicate.fieldId),
        enumTypeId: importEnumTypeId(enumTypesByKey, predicate.enumTypeId),
        variantIds: predicate.variantIds.map((id) =>
          importEnumVariantId(enumTypesByKey, predicate.enumTypeId, id),
        ),
      };
  }
}

function importObjectRefFieldPredicate(
  allFieldIdsByKey: IMap<string, T.FieldId>,
  objectDetails: ObjectDetailsByKey,
  predicate: T.FieldPredicate.ObjectRef,
): T.FieldPredicate.ObjectRef {
  switch (predicate.op) {
    case "matches":
    case "does-not-match":
      return {
        type: "object-ref",
        op: predicate.op,
        fieldId: importFieldId(allFieldIdsByKey, predicate.fieldId),
        objectRefs: predicate.objectRefs.map((ref) =>
          importObjectRef(objectDetails, ref),
        ),
      };
  }
}

function importStringFieldPredicate(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  objectDetails: ObjectDetailsByKey,
  predicate: T.FieldPredicate.String,
): T.FieldPredicate.String {
  switch (predicate.op) {
    case "equals":
    case "does-not-equal":
      return {
        type: "string" as const,
        op: predicate.op,
        fieldId: importFieldId(allFieldIdsByKey, predicate.fieldId),
        other: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          predicate.other,
        ),
      };
    case "starts-with":
    case "does-not-start-with":
      return {
        type: "string" as const,
        op: predicate.op,
        fieldId: importFieldId(allFieldIdsByKey, predicate.fieldId),
        prefix: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          predicate.prefix,
        ),
      };
    case "ends-with":
    case "does-not-end-with":
      return {
        type: "string" as const,
        op: predicate.op,
        fieldId: importFieldId(allFieldIdsByKey, predicate.fieldId),
        suffix: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          predicate.suffix,
        ),
      };
    case "contains":
    case "does-not-contain":
      return {
        type: "string" as const,
        op: predicate.op,
        fieldId: importFieldId(allFieldIdsByKey, predicate.fieldId),
        substring: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          predicate.substring,
        ),
      };
  }
}

function importExpression(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  objectDetails: ObjectDetailsByKey,
  expr: T.Expression,
): T.Expression {
  function recurse(expr: T.Expression): T.Expression {
    return importExpression(
      enumTypesByKey,
      allFieldIdsByKey,
      objectDetails,
      expr,
    );
  }

  switch (expr.kind) {
    case "field-value":
    case "field-value-to-string":
      return {
        kind: expr.kind,
        fieldId: importFieldId(allFieldIdsByKey, expr.fieldId),
      };
    case "local-field-value":
    case "local-field-value-to-string":
      // We assume that local fields are local to the object being imported and
      // preserve their LocalFieldIds as is
      return expr;
    case "list":
      return {
        kind: "list",
        elements: expr.elements.map(recurse),
      };
    case "duration":
      return {
        kind: "duration",
        count: recurse(expr.count),
        unit: expr.unit,
      };
    case "literal":
      return {
        kind: "literal",
        value: importValue(enumTypesByKey, objectDetails, expr.value),
      };
    case "condition":
      return {
        kind: "condition",
        cond: importCondition(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          expr.cond,
        ),
      };
    case "if-else":
      return {
        kind: "if-else",
        cond: recurse(expr.cond),
        ifTrue: recurse(expr.ifTrue),
        ifFalse: recurse(expr.ifFalse),
      };
    case "function-call":
      return {
        kind: "function-call",
        func: recurse(expr.func),
        args: expr.args.map(recurse),
      };
  }
}

function importCondition(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  objectDetails: ObjectDetailsByKey,
  condition: T.Condition,
): T.Condition {
  switch (condition.kind) {
    case "and":
    case "or":
      return {
        kind: condition.kind,
        children: condition.children.map((child) =>
          importExpression(
            enumTypesByKey,
            allFieldIdsByKey,
            objectDetails,
            child,
          ),
        ),
      };
    case "not":
      return {
        kind: "not",
        child: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          condition.child,
        ),
      };
    case "predicate":
      return {
        kind: "predicate",
        predicate: importPredicate(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          condition.predicate,
        ),
      };
  }
}

function importPredicate(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  objectDetails: ObjectDetailsByKey,
  pred: T.Predicate,
): T.Predicate {
  switch (pred.kind) {
    case "unary":
      return {
        kind: "unary",
        left: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          pred.left,
        ),
        op: pred.op,
      };
    case "binary":
      return {
        kind: "binary",
        left: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          pred.left,
        ),
        op: pred.op,
        right: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          pred.right,
        ),
      };
  }
}

function importNumberFieldPredicate(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  objectDetails: ObjectDetailsByKey,
  predicate: T.FieldPredicate.Number,
): T.FieldPredicate.Number {
  switch (predicate.op) {
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return {
        type: "number" as const,
        op: predicate.op,
        fieldId: importFieldId(allFieldIdsByKey, predicate.fieldId),
        other: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          predicate.other,
        ),
      };
    case "between":
    case "not-between":
      return {
        type: "number" as const,
        op: predicate.op,
        fieldId: importFieldId(allFieldIdsByKey, predicate.fieldId),
        bottom: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          predicate.bottom,
        ),
        top: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          predicate.top,
        ),
      };
  }
}

function importDurationFieldPredicate(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  objectDetails: ObjectDetailsByKey,
  predicate: T.FieldPredicate.Duration,
): T.FieldPredicate.Duration {
  switch (predicate.op) {
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return {
        type: "duration" as const,
        op: predicate.op,
        fieldId: importFieldId(allFieldIdsByKey, predicate.fieldId),
        other: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          predicate.other,
        ),
      };
    case "between":
    case "not-between":
      return {
        type: "duration" as const,
        op: predicate.op,
        fieldId: importFieldId(allFieldIdsByKey, predicate.fieldId),
        bottom: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          predicate.bottom,
        ),
        top: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          predicate.top,
        ),
      };
  }
}

function importDateFieldPredicate(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  objectDetails: ObjectDetailsByKey,
  predicate: T.FieldPredicate.Date,
): T.FieldPredicate.Date {
  switch (predicate.op) {
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return {
        type: "date" as const,
        op: predicate.op,
        fieldId: importFieldId(allFieldIdsByKey, predicate.fieldId),
        other: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          predicate.other,
        ),
      };
    case "between":
    case "not-between":
      return {
        type: "date" as const,
        op: predicate.op,
        fieldId: importFieldId(allFieldIdsByKey, predicate.fieldId),
        bottom: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          predicate.bottom,
        ),
        top: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          predicate.top,
        ),
      };
  }
}

function importRuleAction(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  objectDetails: ObjectDetailsByKey,
  action: T.RuleAction,
): T.RuleAction {
  switch (action.type) {
    case "reject":
    case "require-review":
      return {
        type: action.type,
        reason: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          action.reason,
        ),
      };
    case "add-price-adjustment":
    case "add-rate-adjustment":
    case "add-margin-adjustment":
    case "final-rate-adjustment":
    case "final-price-adjustment":
    case "final-margin-adjustment":
      return {
        type: action.type,
        adjustmentValue: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          action.adjustmentValue,
        ),
        adjustmentName: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          action.adjustmentName,
        ),
      };
    case "add-stipulation":
      return {
        type: action.type,
        stipulationText: importExpression(
          enumTypesByKey,
          allFieldIdsByKey,
          objectDetails,
          action.stipulationText,
        ),
      };
  }
}

function importValue(
  enumTypesByKey: IMap<string, T.EnumType>,
  objectDetails: ObjectDetailsByKey,
  value: T.Value,
): T.Value {
  function recurse(value: T.Value): T.Value {
    return importValue(enumTypesByKey, objectDetails, value);
  }

  switch (value.type) {
    case "enum":
      return {
        type: "enum",
        value: importEnumVariantValue(enumTypesByKey, value.value),
      };
    case "object-ref":
      return {
        type: "object-ref",
        value: importObjectRef(objectDetails, value.value),
      };
    case "list":
      return {
        type: "list",
        value: value.value.map(recurse),
      };
    case "number":
    case "string":
    case "duration":
    case "date":
    case "boolean":
    case "builtin-func":
      return value;
  }
}

export function importObjectRef(
  objectDetails: ObjectDetailsByKey,
  value: T.ObjectRef,
): T.ObjectRef {
  switch (value.type) {
    case "pricing-profile":
      return {
        type: "pricing-profile",
        id: importPricingProfileId(objectDetails, value.id),
      };
  }
}

function importPricingProfileId(
  objectDetails: ObjectDetailsByKey,
  key: string,
): T.PricingProfileId {
  const pricingProfile = objectDetails.pricingProfiles.get(key);
  if (!pricingProfile) {
    throw new Error(
      `Pricing profile with key ${JSON.stringify(key)} not found`,
    );
  }

  return pricingProfile.id;
}

function importDataTableColumnId(
  dataTablesByKey: IMap<string, T.DataTable>,
  dataTableKey: string,
  dataTableColumnId: T.DataTableColumnId,
): T.DataTableColumnId {
  const table = dataTablesByKey.get(dataTableKey);

  if (!table) {
    throw new Error(
      "Could not resolve reference to data table " +
        JSON.stringify(dataTableKey),
    );
  }

  const column = table.columnDefs.find(
    (c) => getDataTableColumnKey(c) === dataTableColumnId,
  );

  if (!column) {
    throw new Error(
      "Could not resolve reference to data table " +
        JSON.stringify(dataTableColumnId),
    );
  }

  return column.id;
}

export type ObjectDetailsByKey = {
  pricingProfiles: Map<string, T.PricingProfileHeader>;
};

export function buildObjectDetailsByKey(
  objectDetails: ObjectDetails,
): ObjectDetailsByKey {
  const pricingProfileValues = Array.from(
    objectDetails.pricingProfiles.values(),
  );
  const pricingProfiles = new Map(
    pricingProfileValues.map((pricingProfile) => [
      getPricingProfileKey(pricingProfile),
      pricingProfile,
    ]),
  );

  return {
    pricingProfiles,
  };
}

function importDataTableId(
  dataTablesByKey: IMap<string, T.DataTable>,
  dataTableKey: string,
): T.DataTableId {
  const table = dataTablesByKey.get(dataTableKey);

  if (!table) {
    throw new Error(
      "Could not resolve reference to data table " +
        JSON.stringify(dataTableKey),
    );
  }

  return table.id;
}

function importRangeBound<T>(
  bound: T.RangeBound<T>,
  importT: (v: T) => T,
): T.RangeBound<T> {
  switch (bound.type) {
    case "unbounded":
      return {
        type: bound.type,
      };
    case "included":
    case "excluded":
      return {
        type: bound.type,
        value: importT(bound.value),
      };
  }
}

export function importRuleId(
  rulesByKey: IMap<string, T.Rule>,
  ruleId: T.RuleId,
): T.RuleId {
  const rule = rulesByKey.get(ruleId);

  if (!rule) {
    throw new Error(
      "Could not resolve reference to rule " + JSON.stringify(ruleId),
    );
  }

  return rule.id;
}
