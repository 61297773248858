import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import whitespace from "design/subatomics/whitespace";

export const Messages = styled.section`
  .typography-text {
    > svg {
      position: relative;
      top: -2px;
    }
  }

  a {
    svg {
      position: relative;
      top: 2px;
    }
  }

  ${breakpoints.mobile} {
    padding: ${whitespace("less")} 0;
  }

  ${breakpoints.desktop} {
    padding: ${whitespace()} 0;
  }
`;
