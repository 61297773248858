import * as T from "types/engine-types";
import { Map as IMap } from "immutable";
import { FieldDefinitionState, PipelineFieldState } from "pages/fields";
import { Configuration } from "config";

function resolveFieldName(
  name: string | null,
  id: T.FieldId | null,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): string {
  if (name !== null) return name;

  if (id !== null) {
    const inherited = inheritableFields.get(id);
    if (inherited) return inherited.name;
  }

  return "<UNKNOWN>";
}

export function getRawFieldStateName(
  state: FieldDefinitionState,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): string {
  if (state.disposition.kind === "native") {
    return resolveFieldName(state.disposition.name, null, inheritableFields);
  } else {
    return resolveFieldName(
      state.disposition.nameAlias,
      state.id,
      inheritableFields,
    );
  }
}

export function getNameFromId(field: T.FieldId, config: Configuration) {
  const currentField = config.allFieldsById.find(
    (existingField) => existingField.id === field,
  );

  return currentField ? currentField?.name : "";
}

export function getRawPipelineFieldStateName(
  state: PipelineFieldState,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
  config: Configuration,
): string {
  if (state.type === "pipeline-only") {
    if (state.disposition.kind === "native") {
      return resolveFieldName(state.disposition.name, null, inheritableFields);
    } else if (state.disposition.kind === "inherited") {
      return resolveFieldName(
        state.disposition.nameAlias,
        state.id,
        inheritableFields,
      );
    }
  } else {
    return getNameFromId(state.id, config);
  }
  return "";
}

export function getRawFieldName(
  field: T.BaseRawFieldDefinition,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): string {
  if (field.disposition.kind === "native") {
    return resolveFieldName(field.disposition.name, null, inheritableFields);
  } else {
    return resolveFieldName(
      field.disposition.nameAlias,
      field.id,
      inheritableFields,
    );
  }
}

export function getFieldIdentifier(field: T.BaseFieldDefinition): string {
  return fieldNameToIdentifier(field.name);
}

export function getRawFieldStateIdentifier(
  state: FieldDefinitionState,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): string {
  return fieldNameToIdentifier(getRawFieldStateName(state, inheritableFields));
}

export function getRawFieldIdentifier(
  field: T.BaseRawFieldDefinition,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): string {
  return fieldNameToIdentifier(getRawFieldName(field, inheritableFields));
}

export function fieldNameToIdentifier(fieldName: string): string {
  return nameToIdentifier(fieldName);
}

export function getEnumTypeIdentifier(enumType: T.EnumType): string {
  return enumTypeNameToIdentifier(enumType.name);
}

export function getRawEnumTypeIdentifier(
  enumType: T.RawEnumType,
  inheritableEnums: IMap<T.EnumTypeId, T.EnumType>,
): string {
  let name = "<INHERITED>";
  if (enumType.disposition.kind === "inherited") {
    if (enumType.disposition.nameAlias !== null) {
      name = enumType.disposition.nameAlias;
    } else {
      const inherited = inheritableEnums.get(enumType.id);
      if (inherited) {
        name = inherited.name;
      }
    }
  } else {
    name = enumType.disposition.name;
  }

  return enumTypeNameToIdentifier(name);
}

export function enumTypeNameToIdentifier(enumTypeName: string): string {
  return nameToIdentifier(enumTypeName);
}

export function findEnumVariantIdentifier(
  enumType: T.EnumType,
  enumVariantId: T.EnumVariantId,
): string | null {
  const enumVariant = enumType.variants.find((v) => v.id === enumVariantId);
  return enumVariant ? nameToIdentifier(enumVariant.name) : null;
}

export function getEnumVariantIdentifier(enumVariant: T.EnumVariant): string {
  return nameToIdentifier(enumVariant.name);
}

export function enumVariantNameToIdentifier(variantName: string): string {
  return nameToIdentifier(variantName);
}

export function findEnumVariantByIdentifier(
  enumType: T.EnumType,
  enumVariantIdentifier: string,
): T.EnumVariant | null {
  const lowerIdent = enumVariantIdentifier.toLowerCase();

  return (
    enumType.variants.find(
      (v) => nameToIdentifier(v.name).toLowerCase() === lowerIdent,
    ) || null
  );
}

export function getPricingProfileIdentifier(
  profile: T.PricingProfileHeader,
): string {
  return nameToIdentifier(profile.name);
}

function nameToIdentifier(name: string): string {
  return name
    .replace(/ /g, "_") // spaces become underscores
    .replace(/[^a-zA-Z0-9_]/g, "") // remove non-whitelisted chars
    .replace(/^(?=[0-9])/, "_"); // add underscore prefix if start with digit
}
