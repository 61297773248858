import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import * as Api from "api";
import * as T from "types/engine-types";

const initialState: CodeContextState = {
  status: { kind: "not-loaded" },
  codeContext: null,
};

type CodeContextStatus =
  | { kind: "not-loaded" }
  | { kind: "loading" }
  | { kind: "loaded" }
  | { kind: "error"; error: string };

type CodeContextState = {
  status: CodeContextStatus;
  codeContext: T.CodeContext | null;
};

type CodeContextsByPurpose = { [key: string]: CodeContextState };

export const createGetCodeContextThunk = (purpose: string) =>
  createAsyncThunk(`codeContexts/${purpose}`, async () => {
    return await Api.getCodeContext(purpose);
  });

const CONTEXT_PURPOSES: string[] = [
  "encompass-from-transaction-origin",
  "encompass-to-transaction-response",
];

const codeContextsSlice = createSlice({
  name: "CodeContexts",
  initialState: {} as CodeContextsByPurpose,
  extraReducers: (builder) => {
    for (const purpose of CONTEXT_PURPOSES) {
      const getterThunk = createGetCodeContextThunk(purpose);

      builder.addCase(getterThunk.pending, (state) => {
        if (!state.hasOwnProperty(purpose))
          state[purpose] = { ...initialState };

        state[purpose].status = { kind: "loading" };
      });

      builder.addCase(getterThunk.rejected, (state) => {
        if (!state.hasOwnProperty(purpose))
          state[purpose] = { ...initialState };

        state[purpose].status = {
          kind: "error",
          error: "An error occurred while loading code context",
        };
      });

      builder.addCase(getterThunk.fulfilled, (state, { payload }) => {
        if (!state.hasOwnProperty(purpose))
          state[purpose] = { ...initialState };

        state[purpose] = {
          status: { kind: "loaded" },
          codeContext: payload,
        };
      });
    }
  },
  reducers: {},
});

export default codeContextsSlice.reducer;

const createStateSelector = function (purpose: string) {
  return (state: { codeContexts: CodeContextsByPurpose }) => {
    if (!state.codeContexts.hasOwnProperty(purpose)) {
      return { ...initialState };
    } else {
      return state.codeContexts[purpose];
    }
  };
};

export const createCodeContextStateSelector = (purpose: string) => {
  return createSelector(createStateSelector(purpose), (state) => state);
};
