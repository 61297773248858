import React, { ReactElement } from "react";

type EnglishListProps = {
  conjunction?: string | ReactElement;
  comma?: string | ReactElement;
  items: (string | ReactElement)[];
};

export default function EnglishList({
  conjunction: conjunctionArg,
  comma: commaArg,
  items,
}: EnglishListProps) {
  const conjunction = conjunctionArg || " and ";
  const comma = commaArg || ", ";

  return (
    <>
      {items.slice(0, -2).map((item) => [item, comma])}
      {items.length > 1 && [items[items.length - 2], conjunction]}
      {items.length > 0 && items[items.length - 1]}
    </>
  );
}
