export const layerDefaults = {
  below: 0,
  base: 1000,
  controls: 2000,
  overlay: 2000,
  modal: 3000,
  above: 4000,
};

function layers({ layer, modifier = 0 }: { layer: string; modifier?: number }) {
  console.assert(
    Object.keys(layerDefaults).includes(layer),
    "first argument, layer, not included in layers.",
  );

  console.assert(
    typeof modifier === "number",
    "second argument, modifer, must be a number",
  );

  const layersMap = new Map(Object.entries(layerDefaults));

  const pickedLayer = layersMap.get(layer) || layerDefaults.base;

  return pickedLayer + (modifier || 0);
}

export default layers;
