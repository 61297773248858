import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import XLSX from "xlsx";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import InfoIcon from "@material-ui/icons/Info";
import PublishIcon from "@material-ui/icons/Publish";
import { useSelector } from "react-redux";
import { Configuration } from "config";
import * as T from "types/engine-types";
import * as Fields from "features/fields";
import {
  enumVariantNameToIdentifier,
  findEnumVariantByIdentifier,
} from "features/formulas-util";
import {
  XLS_MIME_TYPE,
  XLSM_MIME_TYPE,
  XLSX_MIME_TYPE,
  unreachable,
  parseNumberAsExcelDate,
  MDYtoYMD,
  isPresent,
  getErrorMessage,
} from "features/utils";
import { ObjectDetails } from "features/objects";
import {
  expandedConfigSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import { DataTableBody } from "./DataTableEditor";

type ImportTableButtonProps =
  | {
      disabled?: false;
      columnDefs: T.DataTableColumn[];
      onImport: (body: DataTableBody, columnDefs: T.DataTableColumn[]) => void;
    }
  | {
      disabled: true;
      columnDefs?: T.DataTableColumn[];
      onImport?: (body: DataTableBody, columnDefs: T.DataTableColumn[]) => void;
    };

const { newrelic } = window;

export const ImportTableButton = React.memo(function ImportTableButton({
  disabled,
  columnDefs,
  onImport,
}: ImportTableButtonProps) {
  const [file, setFile] = useState<File | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const open = useCallback(() => {
    if (disabled) {
      return;
    }

    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef, disabled]);

  const onFileChange = useCallback(() => {
    if (inputRef.current?.files && inputRef.current.files[0]) {
      setFile(inputRef.current.files[0]);
    }
  }, [inputRef, setFile]);

  const cancelImport = useCallback(() => {
    setFile(null);
  }, [setFile]);

  const onDialogImport = useCallback(
    (body: DataTableBody) => {
      setFile(null);
      !disabled && onImport && columnDefs && onImport(body, columnDefs);
    },
    [setFile, onImport, columnDefs, disabled],
  );

  return (
    <>
      <form>
        <Button
          disabled={disabled}
          variant="outlined"
          startIcon={<PublishIcon />}
          onClick={open}
        >
          Upload Spreadsheet
        </Button>
        <input
          type="file"
          ref={inputRef}
          accept={[XLS_MIME_TYPE, XLSX_MIME_TYPE, XLSM_MIME_TYPE].join(", ")}
          style={{ display: "none" }}
          onChange={onFileChange}
        />
      </form>
      {file && columnDefs && (
        <ImportBodyDialog
          columnDefs={columnDefs}
          file={file}
          cancelImport={cancelImport}
          onImport={onDialogImport}
        />
      )}
    </>
  );
});

export const ImportBodyDialog = React.memo(
  ({
    columnDefs,
    file,
    cancelImport,
    onImport,
  }: {
    columnDefs: T.DataTableColumn[];
    file: File;
    cancelImport: () => void;
    onImport: (body: DataTableBody) => void;
  }) => {
    const [workbook, setWorkbook] = useState<XLSX.WorkBook | null>(null);
    useEffect(() => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        if (e?.target) {
          const data = new Uint8Array(e.target.result as ArrayBuffer);
          const parsed = XLSX.read(data, { type: "array" });
          setWorkbook(parsed);
        }
      };
      fileReader.readAsArrayBuffer(file);
    }, [file]);

    const [skipFirstRow, setSkipFirstRow] = useState(true);

    const [selectedSheetName, setSelectedSheetName] = useState<string | null>(
      null,
    );
    const [selectedSheetData, setSelectedSheetData] = useState<
      T.FieldValue[][] | null
    >(null);

    const setSelectedSheet = useCallback(
      (sheetName: string, rows: T.FieldValue[][]) => {
        setSelectedSheetName(sheetName);
        setSelectedSheetData(rows);
      },
      [],
    );

    const handleSkipFirstRowChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        // Clear selection because toggling this setting may change which sheets pass validation
        setSelectedSheetName(null);
        setSkipFirstRow(e.target.checked);
      },
      [],
    );

    const importSheet = useCallback(() => {
      if (selectedSheetData === null) {
        return;
      }

      onImport(selectedSheetData);
    }, [selectedSheetData, onImport]);

    return (
      <Dialog open={true} onClose={cancelImport}>
        <DialogTitle>Import {file.name}</DialogTitle>
        <DialogContent>
          {workbook === null && (
            <Box pt={8} px={20} pb={7} fontSize="24px" color="#666">
              Processing...
            </Box>
          )}
          {workbook !== null && (
            <>
              <DialogContentText>
                Select a sheet below to import the data on that worksheet:
              </DialogContentText>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={skipFirstRow}
                    onChange={handleSkipFirstRowChange}
                  />
                }
                label="Skip first row"
              />

              {workbook.SheetNames.map((sheetName) => (
                <SheetOption
                  key={sheetName}
                  name={sheetName}
                  cells={workbook.Sheets[sheetName]}
                  workbookEpoch={
                    workbook.Workbook?.WBProps?.date1904 ? 1904 : 1900
                  }
                  minRowCount={skipFirstRow ? 2 : 1}
                  isSelected={selectedSheetName === sheetName}
                  columnDefs={columnDefs}
                  skipFirstRow={skipFirstRow}
                  select={setSelectedSheet}
                />
              ))}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelImport}>Cancel</Button>
          <Button
            disabled={selectedSheetName === null}
            color="primary"
            onClick={importSheet}
          >
            Import
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

const SheetOption = React.memo(
  ({
    name,
    cells,
    workbookEpoch,
    minRowCount,
    isSelected,
    columnDefs,
    skipFirstRow,
    select,
  }: {
    name: string;
    cells: XLSX.Sheet;
    workbookEpoch: 1900 | 1904;
    minRowCount: number;
    isSelected: boolean;
    columnDefs: T.DataTableColumn[];
    skipFirstRow: boolean;
    select: (sheetName: string, sheetData: T.FieldValue[][]) => void;
  }) => {
    const config = useSelector(expandedConfigSelector);

    const objectDetails = useSelector(objectDetailsMapSelector);

    const requiredColumnCount = columnDefs.length;

    const sheetRange = cells["!ref"] && XLSX.utils.decode_range(cells["!ref"]);
    const numRows = sheetRange ? sheetRange.e.r - sheetRange.s.r + 1 : 0;
    const numColumns = sheetRange ? sheetRange.e.c - sheetRange.s.c + 1 : 0;

    const tooFewRows = numRows < minRowCount;
    const wrongColumnCount = numColumns !== requiredColumnCount;
    const anySizingError = tooFewRows || wrongColumnCount;

    const [isImporting, setImporting] = useState(false);
    const [[tableData, importError], setImportedData] = useState<
      [T.FieldValue[][] | null, TableImportError | null]
    >([null, null]);

    useEffect(() => {
      const sheetRange =
        cells["!ref"] && XLSX.utils.decode_range(cells["!ref"]);

      if (!sheetRange || anySizingError) {
        setImportedData([null, null]);
        return;
      }

      setImporting(true);

      const startRow = sheetRange.s.r + (skipFirstRow ? 1 : 0);
      const endRow = sheetRange.e.r;

      const startColumn = sheetRange.s.c;
      const endColumn = sheetRange.e.c;

      try {
        const rows = _.range(startRow, endRow + 1)
          .map((rowNumber) => {
            const hasColumnWithValue = _.range(startColumn, endColumn + 1).some(
              (colNumber) => {
                const cellAddress = XLSX.utils.encode_cell({
                  r: rowNumber,
                  c: colNumber,
                });

                return !!cells[cellAddress];
              },
            );

            if (!hasColumnWithValue) {
              // ignore empty rows
              return null;
            }

            return _.range(startColumn, endColumn + 1).map(
              (columnNumber, columnIndex) => {
                const columnDefinition = columnDefs[columnIndex];
                const cellAddress = XLSX.utils.encode_cell({
                  r: rowNumber,
                  c: columnNumber,
                });

                const cellValue = cells[cellAddress] as XLSX.CellObject;

                if (!cellValue) {
                  throw new TableImportError(`Cell ${cellAddress} is empty`);
                }

                try {
                  return cellValueToFieldValue(
                    config,
                    objectDetails,
                    columnDefinition.valueType,
                    cellValue.v as string | number,
                    workbookEpoch,
                  );
                } catch (err) {
                  if (err instanceof TableImportError) {
                    newrelic.noticeError(err);
                    throw new TableImportError(
                      `Cell ${cellAddress}: ${err.message}`,
                    );
                  }

                  throw err;
                }
              },
            );
          })
          .filter(isPresent);

        setImporting(false);
        setImportedData([rows, null]);
        return;
      } catch (err) {
        if (err instanceof TableImportError) {
          newrelic.noticeError(err);
          setImporting(false);
          setImportedData([null, err]);
          return;
        }

        throw err;
      }
    }, [
      config,
      objectDetails,
      anySizingError,
      columnDefs,
      cells,
      skipFirstRow,
      workbookEpoch,
    ]);

    const handleRadioChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked && tableData) {
          select(name, tableData);
        }
      },
      [select, name, tableData],
    );

    return (
      <Box py={1}>
        <FormControlLabel
          control={
            <Radio
              checked={isSelected}
              disabled={isImporting || anySizingError || !!importError}
              color="primary"
              onChange={handleRadioChange}
            />
          }
          label={
            <Box display="flex">
              <Box fontSize="20px">{name}</Box>
              <Box
                color={
                  isImporting || anySizingError || importError
                    ? "text.disabled"
                    : "text.secondary"
                }
                fontSize="20px"
                pl="8px"
              >
                ({numRows}
                {numRows === 1 ? " row, " : " rows, "}
                {numColumns}
                {numColumns === 1 ? " column" : " columns"})
              </Box>
            </Box>
          }
        />
        {tooFewRows && (
          <SheetOptionError message="Sheet must contain at least one row of data" />
        )}
        {wrongColumnCount && (
          <SheetOptionError
            message={`Sheet must have exactly ${requiredColumnCount}${
              requiredColumnCount === 1 ? " column" : " columns"
            }`}
          />
        )}
        {importError && <SheetOptionError message={importError.message} />}
        {isImporting && (
          <Box display="flex" alignItems="center" color="text.secondary">
            <InfoIcon />
            <Box pl={1}>Processing sheet data. Please wait...</Box>
          </Box>
        )}
        {!anySizingError && !isImporting && !!tableData && (
          <Box display="flex" alignItems="center" color="success.main">
            <DoneIcon />
            <Box pl={1}>Ready for import</Box>
          </Box>
        )}
      </Box>
    );
  },
);

const SheetOptionError = React.memo(({ message }: { message: string }) => {
  return (
    <Box display="flex" alignItems="center" color="error.main">
      <ClearIcon />
      <Box pl={1}>{message}</Box>
    </Box>
  );
});

class TableImportError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "TableImportError";
  }
}

function normalizeCellValue(cellValue: string): string {
  return cellValue.trim().toLowerCase();
}

function cellValueToFieldValue(
  config: Configuration,
  objectDetails: ObjectDetails,
  valueType: T.FieldValueType,
  cellValue: number | string,
  workbookEpoch: 1900 | 1904,
): T.FieldValue {
  switch (valueType.type) {
    case "enum":
      if (typeof cellValue !== "string") {
        throw new TableImportError("cell value is not text");
      }

      const enumType = config.enumTypesById.get(valueType.enumTypeId);

      if (!enumType) {
        throw new TableImportError(
          "table is set up incorrectly: column data type uses an enumeration that no longer exists",
        );
      }

      const lowerCaseTrimmed = cellValue.toLowerCase().trim();
      const variant =
        enumType.variants.find(
          (v) => v.name.toLowerCase().trim() === lowerCaseTrimmed,
        ) ||
        findEnumVariantByIdentifier(
          enumType,
          enumVariantNameToIdentifier(cellValue),
        );

      if (!variant) {
        throw new TableImportError(
          "cell value does not match any of the enumeration's variants",
        );
      }

      return {
        type: "enum",
        enumTypeId: valueType.enumTypeId,
        variantId: variant.id,
      };
    case "object-ref":
      if (typeof cellValue !== "string") {
        throw new TableImportError("cell value is not text");
      }

      const objectRef = getObjectRefByCellValue(
        objectDetails,
        valueType.objectType,
        cellValue,
      );

      if (!objectRef) {
        throw new TableImportError(
          `cell value does not match any object of type ${valueType.objectType}`,
        );
      }

      return {
        type: "object-ref",
        objectRef,
      };
    case "number":
      if (typeof cellValue !== "number") {
        throw new TableImportError("cell value is not a number");
      }
      return {
        type: "number",
        value: String(cellValue.toFixed(valueType.precision)) + "",
      };
    case "header":
    case "string":
      if (typeof cellValue === "number") {
        return {
          type: "string",
          value: String(cellValue) + "",
        };
      }

      if (typeof cellValue === "string") {
        return {
          type: "string",
          value: cellValue,
        };
      }

      throw new TableImportError("cell value is not number or text");
    case "duration": {
      if (typeof cellValue !== "string") {
        throw new TableImportError(
          `cell value should be text (example: "15 years")`,
        );
      }

      try {
        const durationParts = cellValue.split(" ");
        const count = parseInt(durationParts[0], 10);
        const unit = Fields.stringToDurationUnit(durationParts[1]);

        if (!unit) {
          throw new TableImportError(
            "unknown duration unit: " + JSON.stringify(durationParts[1]),
          );
        }

        return {
          type: "duration",
          count: String(count) + "",
          unit,
        };
      } catch (err) {
        newrelic.noticeError(getErrorMessage("could not parse duration value"));
        newrelic.noticeError(getErrorMessage(err));
        throw new TableImportError("could not parse duration value");
      }
    }
    case "date":
      if (typeof cellValue === "string") {
        return {
          type: "date",
          // Excel files should use the MM/DD/YYYY format for text dates.
          // We need to parse this and transform it into YYYY-MM-DD format.
          value: MDYtoYMD(cellValue + ""),
        };
      } else if (typeof cellValue === "number") {
        return {
          type: "date",
          value: parseNumberAsExcelDate(cellValue, workbookEpoch).format(
            "YYYY-MM-DD",
          ),
        };
      } else {
        throw new TableImportError(
          "cell value is not number or text (required for Date)",
        );
      }
    default: {
      return unreachable(valueType);
    }
  }
}

function getObjectRefByCellValue(
  objectDetails: ObjectDetails,
  objectType: T.ObjectType,
  cellValue: string,
): T.ObjectRef | null {
  const normalizedCellValue = normalizeCellValue(cellValue);

  switch (objectType) {
    case "pricing-profile":
      const pricingProfiles = Array.from(
        objectDetails.pricingProfiles.values(),
      );
      const pricingProfile = pricingProfiles.find(
        (p) => normalizeCellValue(p.name) === normalizedCellValue,
      );

      if (!pricingProfile) {
        return null;
      }

      return {
        type: "pricing-profile",
        id: pricingProfile.id,
      };
    default:
      return unreachable(objectType);
  }
}
