import { Set as ISet } from "immutable";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { Box } from "@material-ui/core";

import * as T from "types/engine-types";
import {
  EnumFieldPredicateEditor,
  EnumFieldPredicateState,
  newEnumFieldPredicateState,
  convertEnumFieldPredicateToState,
  convertStateToEnumFieldPredicate,
} from "../enum-field-predicate-editor";
import {
  ObjectRefFieldPredicateEditor,
  ObjectRefFieldPredicateState,
  newObjectRefFieldPredicateState,
  convertObjectRefFieldPredicateToState,
  convertStateToObjectRefFieldPredicate,
} from "../object-ref-field-predicate-editor";
import {
  NumberFieldPredicateEditor,
  NumberFieldPredicateState,
  newNumberFieldPredicateState,
  convertNumberFieldPredicateToState,
  convertStateToNumberFieldPredicate,
} from "../number-field-predicate-editor";
import {
  StringFieldPredicateEditor,
  StringFieldPredicateState,
  newStringFieldPredicateState,
  convertStringFieldPredicateToState,
  convertStateToStringFieldPredicate,
} from "../string-field-predicate-editor";
import {
  DurationFieldPredicateEditor,
  DurationFieldPredicateState,
  newDurationFieldPredicateState,
  convertDurationFieldPredicateToState,
  convertStateToDurationFieldPredicate,
} from "../duration-field-predicate-editor";
import { Configuration } from "config";
import { SearchableDropdown } from "design/molecules/dropdown";
import {
  UiValidationError,
  Setter,
  getValidationError,
  useNonNullSetter,
  usePropertySetter,
  unreachable,
} from "features/utils";
import {
  isEnumFieldPredicate,
  isObjectFieldPredicate,
  isStringFieldPredicate,
  isNumberFieldPredicate,
  isDurationFieldPredicate,
  isDateFieldPredicate,
} from "features/field-predicates";
import {
  convertDateFieldPredicateToState,
  convertStateToDateFieldPredicate,
  DateFieldPredicateEditor,
  DateFieldPredicateState,
  newDateFieldPredicateState,
} from "../date-field-predicate-editor";

export type FieldPredicateState = {
  fieldId: T.FieldId | null;
  enumPredicate: EnumFieldPredicateState | null;
  objectRefPredicate: ObjectRefFieldPredicateState | null;
  stringPredicate: StringFieldPredicateState | null;
  numberPredicate: NumberFieldPredicateState | null;
  durationPredicate: DurationFieldPredicateState | null;
  datePredicate: DateFieldPredicateState | null;
};

export function newFieldPredicateState(): FieldPredicateState {
  return {
    fieldId: null,
    enumPredicate: null,
    objectRefPredicate: null,
    stringPredicate: null,
    numberPredicate: null,
    durationPredicate: null,
    datePredicate: null,
  };
}

export function convertFieldPredicateToState(
  config: Configuration,
  pred: T.FieldPredicate,
): FieldPredicateState {
  const field = config.allFieldsById.get(pred.fieldId);

  if (!field) {
    return {
      fieldId: pred.fieldId,
      enumPredicate: null,
      objectRefPredicate: null,
      stringPredicate: null,
      numberPredicate: null,
      durationPredicate: null,
      datePredicate: null,
    };
  }

  const valueType = field.valueType;

  return {
    fieldId: pred.fieldId,
    enumPredicate:
      valueType.type === "enum" && isEnumFieldPredicate(pred)
        ? convertEnumFieldPredicateToState(pred)
        : null,
    objectRefPredicate:
      valueType.type === "object-ref" && isObjectFieldPredicate(pred)
        ? convertObjectRefFieldPredicateToState(pred)
        : null,
    stringPredicate:
      valueType.type === "string" && isStringFieldPredicate(pred)
        ? convertStringFieldPredicateToState(valueType, pred, config)
        : null,
    numberPredicate:
      valueType.type === "number" && isNumberFieldPredicate(pred)
        ? convertNumberFieldPredicateToState(valueType, pred)
        : null,
    durationPredicate:
      valueType.type === "duration" && isDurationFieldPredicate(pred)
        ? convertDurationFieldPredicateToState(valueType, pred)
        : null,
    datePredicate:
      valueType.type === "date" && isDateFieldPredicate(pred)
        ? convertDateFieldPredicateToState(valueType, pred)
        : null,
  };
}

export function convertStateToFieldPredicate(
  environment: ISet<T.FieldId>,
  config: Configuration,
  state: FieldPredicateState,
): T.FieldPredicate {
  if (!state.fieldId) {
    throw new UiValidationError("Select a field");
  }

  const field = config.allFieldsById.get(state.fieldId);

  if (!field) {
    throw new UiValidationError("Unknown field");
  }

  if (!environment.has(state.fieldId)) {
    throw new UiValidationError(
      "The selected field is not available at this point in the engine execution",
    );
  }

  switch (field.valueType.type) {
    case "enum": {
      if (!state.enumPredicate) {
        throw new UiValidationError("Select a field");
      }

      const predicate = convertStateToEnumFieldPredicate(
        config,
        field.valueType,
        state.enumPredicate,
      );
      return { ...predicate, fieldId: field.id };
    }
    case "object-ref": {
      if (!state.objectRefPredicate) {
        throw new UiValidationError("Select a field");
      }

      const predicate = convertStateToObjectRefFieldPredicate(
        config,
        field.valueType,
        state.objectRefPredicate,
      );
      return { ...predicate, fieldId: field.id };
    }
    case "string": {
      if (!state.stringPredicate) {
        throw new UiValidationError("Select a field");
      }

      const predicate = convertStateToStringFieldPredicate(
        config,
        field.valueType,
        state.stringPredicate,
      );
      return { ...predicate, fieldId: field.id };
    }
    case "number": {
      if (!state.numberPredicate) {
        throw new UiValidationError("Select a field");
      }

      const predicate = convertStateToNumberFieldPredicate(
        config,
        field.valueType,
        state.numberPredicate,
      );
      return { ...predicate, fieldId: field.id };
    }
    case "duration": {
      if (!state.durationPredicate) {
        throw new UiValidationError("Select a field");
      }

      const predicate = convertStateToDurationFieldPredicate(
        config,
        field.valueType,
        state.durationPredicate,
      );
      return { ...predicate, fieldId: field.id };
    }
    case "date": {
      if (!state.datePredicate) {
        throw new UiValidationError("Select a field");
      }

      const predicate = convertStateToDateFieldPredicate(
        config,
        field.valueType,
        state.datePredicate,
      );
      return { ...predicate, fieldId: field.id };
    }
    case "header": {
      throw new Error("Headers cannot have predicates");
    }
  }
}

export function validateFieldPredicateState(
  environment: ISet<T.FieldId>,
  config: Configuration,
  state: FieldPredicateState,
): UiValidationError | null {
  return getValidationError(() => {
    convertStateToFieldPredicate(environment, config, state);
  });
}

export const FieldPredicateEditor = React.memo(
  ({
    state,
    setState,
    showValidationErrors,
    isExpandedView,
    environment,
    localFields,
    config,
  }: {
    state: FieldPredicateState;
    setState: Setter<FieldPredicateState>;
    showValidationErrors: boolean;
    isExpandedView: boolean;
    environment: ISet<T.FieldId>;
    localFields: T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
  }) => {
    const sortedFieldIds = useMemo(() => {
      return _.sortBy(
        environment.toArray().map((fieldId: T.FieldId) => {
          const field = config.allFieldsById.get(fieldId);

          if (!field) {
            throw new Error(
              "Unknown field in environment: id=" + JSON.stringify(fieldId),
            );
          }

          return field;
        }),
        (f) => f.name.toLowerCase(),
      )
        .filter((f) => f.valueType.type !== "header")
        .map((f) => f.id);
    }, [config, environment]);
    const getFieldName = useCallback(
      (id: T.FieldId) => {
        if (environment.has(id)) {
          const field = config.allFieldsById.get(id);

          if (field) {
            return field.name;
          }
        }

        return "<Unknown Field>";
      },
      [environment, config],
    );

    const updateFieldId = useCallback(
      (fieldId: T.FieldId | null) => {
        if (!fieldId) {
          setState({
            fieldId: null,
            enumPredicate: null,
            objectRefPredicate: null,
            stringPredicate: null,
            numberPredicate: null,
            durationPredicate: null,
            datePredicate: null,
          });
          return;
        }

        const field = config.allFieldsById.get(fieldId);

        if (!field) {
          setState({
            fieldId,
            enumPredicate: null,
            objectRefPredicate: null,
            stringPredicate: null,
            numberPredicate: null,
            durationPredicate: null,
            datePredicate: null,
          });
          return;
        }

        setState({
          fieldId,
          enumPredicate:
            field.valueType.type === "enum"
              ? newEnumFieldPredicateState(field.valueType)
              : null,
          objectRefPredicate:
            field.valueType.type === "object-ref"
              ? newObjectRefFieldPredicateState(field.valueType)
              : null,
          stringPredicate:
            field.valueType.type === "string"
              ? newStringFieldPredicateState(field.valueType)
              : null,
          numberPredicate:
            field.valueType.type === "number"
              ? newNumberFieldPredicateState(field.valueType)
              : null,
          durationPredicate:
            field.valueType.type === "duration"
              ? newDurationFieldPredicateState(field.valueType)
              : null,
          datePredicate:
            field.valueType.type === "date"
              ? newDateFieldPredicateState(field.valueType)
              : null,
        });
      },
      [setState, config],
    );

    const field =
      (state.fieldId && config.allFieldsById.get(state.fieldId)) || null;

    return (
      <>
        <Box width="270px" mb={1}>
          <SearchableDropdown
            label="Field Name"
            options={sortedFieldIds}
            getOptionLabel={getFieldName}
            value={state.fieldId}
            error={showValidationErrors && !state.fieldId}
            setValue={updateFieldId}
          />
        </Box>
        {field && (
          <FieldPredicateValueEditor
            state={state}
            setState={setState}
            field={field}
            showValidationErrors={showValidationErrors}
            isExpandedView={isExpandedView}
            environment={environment}
            localFields={localFields}
            config={config}
          />
        )}
      </>
    );
  },
);

const FieldPredicateValueEditor = React.memo(
  ({
    state,
    setState,
    field,
    showValidationErrors,
    isExpandedView,
    environment,
    localFields,
    config,
  }: {
    state: FieldPredicateState;
    setState: Setter<FieldPredicateState>;
    field: T.BaseFieldDefinition;
    showValidationErrors: boolean;
    isExpandedView: boolean;
    environment: ISet<T.FieldId>;
    localFields: T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
  }): React.ReactElement | null => {
    const setEnumPredicate = usePropertySetter(setState, "enumPredicate");
    const setObjectRefPredicate = usePropertySetter(
      setState,
      "objectRefPredicate",
    );
    const setStringPredicate = usePropertySetter(setState, "stringPredicate");
    const setNumberPredicate = usePropertySetter(setState, "numberPredicate");
    const setDurationPredicate = usePropertySetter(
      setState,
      "durationPredicate",
    );
    const setDatePredicate = usePropertySetter(setState, "datePredicate");
    const setEnumPredicateNonNull = useNonNullSetter(setEnumPredicate);
    const setObjectRefPredicateNonNull = useNonNullSetter(
      setObjectRefPredicate,
    );
    const setStringPredicateNonNull = useNonNullSetter(setStringPredicate);
    const setNumberPredicateNonNull = useNonNullSetter(setNumberPredicate);
    const setDurationPredicateNonNull = useNonNullSetter(setDurationPredicate);
    const setDatePredicateNonNull = useNonNullSetter(setDatePredicate);

    switch (field.valueType.type) {
      case "enum":
        if (!state.enumPredicate) {
          console.warn("Enum field has no predicate");
          return null;
        }

        return (
          <EnumFieldPredicateEditor
            state={state.enumPredicate}
            fieldId={field.id}
            environment={environment}
            localFields={localFields}
            config={config}
            setState={setEnumPredicateNonNull}
            showValidationErrors={showValidationErrors}
            isExpandedView={isExpandedView}
          />
        );
      case "object-ref":
        if (!state.objectRefPredicate) {
          console.warn("Object field has no predicate");
          return null;
        }

        return (
          <ObjectRefFieldPredicateEditor
            state={state.objectRefPredicate}
            fieldId={field.id}
            environment={environment}
            localFields={localFields}
            config={config}
            setState={setObjectRefPredicateNonNull}
            showValidationErrors={showValidationErrors}
            isExpandedView={isExpandedView}
          />
        );

      case "string":
        if (!state.stringPredicate) {
          console.warn("String field has no predicate");
          return null;
        }

        return (
          <StringFieldPredicateEditor
            state={state.stringPredicate}
            valueType={field.valueType}
            environment={environment}
            localFields={localFields}
            config={config}
            setState={setStringPredicateNonNull}
            showValidationErrors={showValidationErrors}
          />
        );
      case "number":
        if (!state.numberPredicate) {
          console.warn("Number field has no predicate");
          return null;
        }

        return (
          <NumberFieldPredicateEditor
            state={state.numberPredicate}
            valueType={field.valueType}
            environment={environment}
            localFields={localFields}
            config={config}
            setState={setNumberPredicateNonNull}
            showValidationErrors={showValidationErrors}
          />
        );
      case "duration":
        if (!state.durationPredicate) {
          console.warn("Duration field has no predicate");
          return null;
        }

        return (
          <DurationFieldPredicateEditor
            state={state.durationPredicate}
            valueType={field.valueType}
            environment={environment}
            localFields={localFields}
            config={config}
            setState={setDurationPredicateNonNull}
            showValidationErrors={showValidationErrors}
          />
        );
      case "date":
        if (!state.datePredicate) {
          console.warn("Date field has no predicate");
          return null;
        }

        return (
          <DateFieldPredicateEditor
            state={state.datePredicate}
            valueType={field.valueType}
            environment={environment}
            localFields={localFields}
            config={config}
            setState={setDatePredicateNonNull}
            showValidationErrors={showValidationErrors}
          />
        );
      case "header":
        console.warn("Tried to edit a field predicate with a `header` field");
        return null;
      default:
        return unreachable(field.valueType);
    }
  },
);
