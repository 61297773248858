import React from "react";
import whitespace from "design/subatomics/whitespace";
import { styles } from "design/atoms/typography";
import colors from "design/subatomics/colors";
import selectStyles, { Label } from "./styles";
import Select, {
  ActionMeta,
  SingleValue,
  GetOptionLabel,
  GetOptionValue,
} from "react-select";

function CustomSelect<T>({
  placeholder,
  className,
  label,
  hint,
  error,
  onChange,
  value,
  options,
  getOptionLabel,
  getOptionValue,
}: {
  placeholder?: string;
  className?: string;
  label?: string;
  hint?: string;
  error?: string;
  getOptionLabel: GetOptionLabel<T>;
  getOptionValue: GetOptionValue<T>;
  onChange: (newValue: SingleValue<T>, actionMeta?: ActionMeta<T>) => void;
  value: T;
  options: T[];
}) {
  return (
    <label className={className}>
      {label && <Label className="label">{label}</Label>}

      <Select<T>
        // eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment
        styles={selectStyles()}
        value={value}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        options={options}
        backspaceRemovesValue={true}
        onChange={onChange}
        placeholder={placeholder}
      />

      {hint && !error && (
        <div
          className="hint"
          style={{
            ...styles.smallText,
            color: colors({ color: "gray", shade: 6 }),
            marginTop: whitespace("least"),
            marginLeft: whitespace("less"),
          }}
        >
          {hint}
        </div>
      )}

      {error && (
        <div
          className="error"
          style={{
            ...styles.smallText,
            color: colors({ color: "red", shade: 5 }),
            marginTop: whitespace("least"),
            marginLeft: whitespace("less"),
          }}
        >
          {error}
        </div>
      )}
    </label>
  );
}

export default CustomSelect;
