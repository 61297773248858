import * as T from "types/generated-types";

export type Permission = {
  kind: T.PermissionKind;
  /** the name of the permission, as displayed in the UI */
  name: string;
  /** permissions that you must also have in order to have this permission*/
  requires?: T.PermissionKind[];
  /**
   * level to which this permission should be indented when appearing in
   * the permissions list in the UI. If not present, 0 is implied.
   */
  indentation?: number;
};

/** a list of all the permissions that are displayed in the UI,
 *  in the order that they should be displayed. This excludes
 * "configuration-view" because that is an internal permission that
 * is currently hidden in the UI
 */
export const allUserVisiblePermissions: Permission[] = [
  {
    kind: "pricing-profit-margin-adjustments-view",
    name: "Pricing Profit Margin Adjustments View",
  },
  {
    kind: "application-scenarios-view",
    name: "View Scenarios",
  },
  {
    kind: "application-scenarios-modify-user-scoped",
    name: "Manage User-Scoped Scenarios",
    requires: ["application-scenarios-view"],
    indentation: 1,
  },
  {
    kind: "application-scenarios-modify-client-scoped",
    name: "Manage System Scenarios",
    requires: [
      "application-scenarios-view",
      "application-scenarios-modify-user-scoped",
    ],
    indentation: 1,
  },
  {
    kind: "investors-menu-access",
    name: "Investors Menu Access",
  },
  {
    kind: "investors-create",
    name: "Investors Create",
    requires: ["investors-menu-access"],
    indentation: 1,
  },
  {
    kind: "investors-edit",
    name: "Investors Edit",
    requires: ["investors-menu-access"],
    indentation: 1,
  },
  {
    kind: "investors-delete",
    name: "Investors Delete",
    requires: ["investors-menu-access"],
    indentation: 1,
  },
  {
    kind: "products-menu-access",
    name: "Products Menu Access",
  },
  {
    kind: "products-create",
    name: "Products Create",
    requires: ["products-menu-access"],
    indentation: 1,
  },
  {
    kind: "products-edit",
    name: "Products Edit",
    requires: ["products-menu-access"],
    indentation: 1,
  },
  {
    kind: "products-delete",
    name: "Products Delete",
    requires: ["products-menu-access"],
    indentation: 1,
  },
  {
    kind: "field-enum-library-menu-access",
    name: "Field / Enum Library Menu Access",
  },
  {
    kind: "field-enum-library-modify",
    name: "Field / Enum Library Modify ",
    requires: ["field-enum-library-menu-access"],
    indentation: 1,
  },
  {
    kind: "calculations-menu-access",
    name: "Calculations Menu Access",
  },
  {
    kind: "calculations-modify",
    name: "Calculations Modify",
    requires: ["calculations-menu-access"],
    indentation: 1,
  },
  {
    kind: "data-tables-menu-access",
    name: "Data Tables Menu Access",
  },
  {
    kind: "data-tables-create",
    name: "Data Tables Create",
    requires: ["data-tables-menu-access"],
    indentation: 1,
  },
  {
    kind: "data-tables-edit",
    name: "Data Tables Edit",
    requires: ["data-tables-menu-access"],
    indentation: 1,
  },
  {
    kind: "data-tables-delete",
    name: "Data Tables Delete",
    requires: ["data-tables-menu-access"],
    indentation: 1,
  },
  {
    kind: "rate-sheets-menu-access",
    name: "Rate Sheets Menu Access",
  },
  {
    kind: "rate-sheets-create",
    name: "Rate Sheets Create",
    requires: ["rate-sheets-menu-access"],
    indentation: 1,
  },
  {
    kind: "rate-sheets-delete",
    name: "Rate Sheets Delete",
    requires: ["rate-sheets-menu-access"],
    indentation: 1,
  },
  {
    kind: "rules-menu-access",
    name: "Rules Menu Access",
  },
  {
    kind: "rules-create",
    name: "Rules Create",
    requires: ["rules-menu-access"],
    indentation: 1,
  },
  {
    kind: "rules-edit",
    name: "Rules Edit",
    requires: ["rules-menu-access"],
    indentation: 1,
  },
  {
    kind: "rules-delete",
    name: "Rules Delete",
    requires: ["rules-menu-access"],
    indentation: 1,
  },
  {
    kind: "roles-menu-access",
    name: "Roles Menu Access",
  },
  {
    kind: "roles-create",
    name: "Roles Create",
    requires: ["roles-menu-access"],
    indentation: 1,
  },
  {
    kind: "roles-edit",
    name: "Roles Edit",
    requires: ["roles-menu-access"],
    indentation: 1,
  },
  {
    kind: "roles-delete",
    name: "Roles Delete",
    requires: ["roles-menu-access"],
    indentation: 1,
  },
  {
    kind: "pipeline-menu-access",
    name: "Pipeline Menu Access",
  },
  {
    kind: "pipeline-records-delete-own",
    name: "Pipeline Records Delete Own",
    requires: ["pipeline-menu-access"],
    indentation: 1,
  },
  {
    kind: "pipeline-records-view-all",
    name: "Pipeline Records View All",
    requires: ["pipeline-menu-access"],
    indentation: 1,
  },
  {
    kind: "pipeline-records-delete-all",
    name: "Pipeline Records Delete All",
    requires: ["pipeline-menu-access"],
    indentation: 1,
  },
  {
    kind: "pipeline-records-edit-all",
    name: "Pipeline Records Edit All",
    requires: ["pipeline-menu-access"],
    indentation: 1,
  },
  {
    kind: "pricing-profiles-menu-access",
    name: "Pricing Profiles Menu Access",
  },
  {
    kind: "pricing-profiles-create",
    name: "Pricing Profiles Create",
    requires: ["pricing-profiles-menu-access"],
    indentation: 1,
  },
  {
    kind: "pricing-profiles-edit",
    name: "Pricing Profiles Edit",
    requires: ["pricing-profiles-menu-access"],
    indentation: 1,
  },
  {
    kind: "pricing-profiles-delete",
    name: "Pricing Profiles Delete",
    requires: ["pricing-profiles-menu-access"],
    indentation: 1,
  },
  {
    kind: "pricing-profiles-access-all",
    name: "Pricing Profiles Access All",
    indentation: 0,
  },
  {
    kind: "price-a-loan-v1-view",
    name: "Price a Loan v1 Menu Access",
  },

  {
    kind: "users-menu-access",
    name: "Users Menu Access",
  },
  {
    kind: "users-create",
    name: "Users Create",
    requires: ["users-menu-access"],
    indentation: 1,
  },
  {
    kind: "users-edit",
    name: "Users Edit",
    requires: ["users-menu-access"],
    indentation: 1,
  },
  {
    kind: "users-edit-email",
    name: "Users Edit Email",
    requires: ["users-menu-access", "users-edit"],
    indentation: 2,
  },
  {
    kind: "users-delete",
    name: "Users Delete",
    requires: ["users-menu-access"],
    indentation: 1,
  },
  {
    kind: "release-notes-menu-access",
    name: "Release Notes Menu Access",
  },
  {
    kind: "support-menu-access",
    name: "Support Menu Access",
  },
  {
    kind: "change-password",
    name: "Change Password",
  },
  {
    kind: "vendor-integrations-menu-access",
    name: "Vendor Integrations Menu Access",
  },
];

export function permissionsThatRequireX(
  permissionX: T.PermissionKind,
): T.PermissionKind[] {
  return allUserVisiblePermissions
    .filter((p) => p.requires?.includes(permissionX))
    .map((p) => p.kind);
}

export function permissionsThatXRequires(
  permissionX: T.PermissionKind,
): T.PermissionKind[] {
  return (
    allUserVisiblePermissions.find((p) => p.kind === permissionX)?.requires ||
    []
  );
}
