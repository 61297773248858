import React from "react";
import * as T from "types/generated-types";

const cellStyle = {
  borderLeft: "1px solid #e0e0e0",
  borderTop: "1px solid #e0e0e0",
  borderRight: "0px",
  borderBottom: "0px",
  background: "white",
  padding: "8px",
  cursor: "pointer",
  fontWeight: 700,
  color: "#4caf50",
};

const labelStyle = {
  ...cellStyle,
  color: "#151515",
};

const extraColumnCellStyle = {
  ...cellStyle,
  color: "#151515",
};

const RateWithLockExampleTable = ({
  columnIds,
  getFieldName,
}: {
  columnIds: T.FieldId[] | null;
  getFieldName: (id: T.FieldId) => string;
}) => {
  return (
    <table
      style={{
        borderCollapse: "collapse",
        borderRight: "1px solid #e0e0e0",
        borderBottom: "1px solid #e0e0e0",
        margin: "0 auto",
      }}
    >
      <thead>
        <tr>
          <th style={labelStyle}></th>
          <th style={labelStyle}>15 days</th>
          <th style={labelStyle}>30 days</th>
          <th style={labelStyle}>45 days</th>
          <th style={labelStyle}>60 days</th>
          <th style={labelStyle}>75 days</th>
          <th style={labelStyle}>90 days</th>
          {columnIds?.map((column, i) => (
            <th key={i} style={extraColumnCellStyle}>
              {getFieldName(column)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={labelStyle}>2.500%</td>
          <td style={cellStyle}>91.000</td>
          <td style={cellStyle}>91.250</td>
          <td style={cellStyle}>91.500</td>
          <td style={cellStyle}>91.750</td>
          <td style={cellStyle}>92.000</td>
          <td style={cellStyle}>92.250</td>
          {columnIds?.map((column, i) => (
            <th key={i} style={extraColumnCellStyle}>
              Value
            </th>
          ))}
        </tr>
        <tr>
          <td style={labelStyle}>2.625%</td>
          <td style={cellStyle}>92.500</td>
          <td style={cellStyle}>92.750</td>
          <td style={cellStyle}>93.000</td>
          <td style={cellStyle}>93.250</td>
          <td style={cellStyle}>93.500</td>
          <td style={cellStyle}>93.750</td>
          {columnIds?.map((column, i) => (
            <th key={i} style={extraColumnCellStyle}>
              Value
            </th>
          ))}
        </tr>
        <tr>
          <td style={labelStyle}>2.750%</td>
          <td style={cellStyle}>94.000</td>
          <td style={cellStyle}>94.250</td>
          <td style={cellStyle}>94.500</td>
          <td style={cellStyle}>94.750</td>
          <td style={cellStyle}>95.000</td>
          <td style={cellStyle}>95.250</td>
          {columnIds?.map((column, i) => (
            <th key={i} style={extraColumnCellStyle}>
              Value
            </th>
          ))}
        </tr>
        <tr>
          <td style={labelStyle}>2.875%</td>
          <td style={cellStyle}>95.500</td>
          <td style={cellStyle}>95.750</td>
          <td style={cellStyle}>96.000</td>
          <td style={cellStyle}>96.250</td>
          <td style={cellStyle}>96.500</td>
          <td style={cellStyle}>96.750</td>
          {columnIds?.map((column, i) => (
            <th key={i} style={extraColumnCellStyle}>
              Value
            </th>
          ))}
        </tr>
        <tr>
          <td style={labelStyle}>3.000%</td>
          <td style={cellStyle}>97.000</td>
          <td style={cellStyle}>97.250</td>
          <td style={cellStyle}>97.500</td>
          <td style={cellStyle}>97.750</td>
          <td style={cellStyle}>98.000</td>
          <td style={cellStyle}>98.250</td>
          {columnIds?.map((column, i) => (
            <th key={i} style={extraColumnCellStyle}>
              Value
            </th>
          ))}
        </tr>
        <tr>
          <td style={labelStyle}>3.125%</td>
          <td style={cellStyle}>98.500</td>
          <td style={cellStyle}>98.750</td>
          <td style={cellStyle}>99.000</td>
          <td style={cellStyle}>99.250</td>
          <td style={cellStyle}>99.500</td>
          <td style={cellStyle}>99.750</td>
          {columnIds?.map((column, i) => (
            <th key={i} style={extraColumnCellStyle}>
              Value
            </th>
          ))}
        </tr>
        <tr>
          <td style={labelStyle}>3.250%</td>
          <td style={cellStyle}>100.000</td>
          <td style={cellStyle}>100.250</td>
          <td style={cellStyle}>100.500</td>
          <td style={cellStyle}>100.750</td>
          <td style={cellStyle}>101.000</td>
          <td style={cellStyle}>101.250</td>
          {columnIds?.map((column, i) => (
            <th key={i} style={extraColumnCellStyle}>
              Value
            </th>
          ))}
        </tr>
        <tr>
          <td style={labelStyle}>3.375%</td>
          <td style={cellStyle}>101.500</td>
          <td style={cellStyle}>101.750</td>
          <td style={cellStyle}>102.000</td>
          <td style={cellStyle}>102.250</td>
          <td style={cellStyle}>102.500</td>
          <td style={cellStyle}>102.750</td>
          {columnIds?.map((column, i) => (
            <th key={i} style={extraColumnCellStyle}>
              Value
            </th>
          ))}
        </tr>
        <tr>
          <td style={labelStyle}>3.500%</td>
          <td style={cellStyle}>103.000</td>
          <td style={cellStyle}>103.250</td>
          <td style={cellStyle}>103.500</td>
          <td style={cellStyle}>103.750</td>
          <td style={cellStyle}>104.000</td>
          <td style={cellStyle}>104.250</td>
          {columnIds?.map((column, i) => (
            <th key={i} style={extraColumnCellStyle}>
              Value
            </th>
          ))}
        </tr>
        <tr>
          <td style={labelStyle}>3.625%</td>
          <td style={cellStyle}>104.500</td>
          <td style={cellStyle}>104.750</td>
          <td style={cellStyle}>105.000</td>
          <td style={cellStyle}>105.250</td>
          <td style={cellStyle}>105.500</td>
          <td style={cellStyle}>105.750</td>
          {columnIds?.map((column, i) => (
            <th key={i} style={extraColumnCellStyle}>
              Value
            </th>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default RateWithLockExampleTable;
