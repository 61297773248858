import React, { useEffect } from "react";
import { useDrop } from "react-dnd";
import { ArrowRight } from "@material-ui/icons";

import { ItemTypes, DragItem } from "types/dnd-item-types";

type RuleConditionStateId = string & { ruleConditionStateIdBrand: unknown };
type ConditionDroppableZoneProps = {
  droppable: boolean;
  zoneIndex: number;
  onDrop: (item: DragItem, zoneIndex: number) => void;
  containerId: RuleConditionStateId;
  // setHoveringContainerId: React.Dispatch<
  //   React.SetStateAction<RuleConditionStateId | null>
  // >;
  setIsOver: (boo: boolean) => void;
};

export default function ConditionDroppableZone({
  droppable,
  zoneIndex,
  onDrop,
  containerId,
  setIsOver,
}: ConditionDroppableZoneProps) {
  function handleDrop() {
    onDrop(getItem as DragItem, zoneIndex);
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [{ isOver, getItem }, drop] = useDrop({
    accept: [ItemTypes.PREDICATE, ItemTypes.AND, ItemTypes.OR],
    drop: () => handleDrop(),
    collect: (mon) => ({
      isOver: !!mon.isOver(),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      getItem: mon.getItem(),
    }),
  });

  useEffect(() => {
    setIsOver(isOver);
    //eslint-disable-next-line
  }, [isOver]);

  return (
    <>
      {droppable && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: isOver ? "45px" : "10px",
            transition: "height 0.1s",
          }}
          ref={drop}
        >
          {isOver && <ArrowRight color={"primary"} />}
        </div>
      )}
      {!droppable && (
        <div
          style={{
            height: "10px",
          }}
        />
      )}
    </>
  );
}
