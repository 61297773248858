import React from "react";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { usePermissions } from "features/roles";
import { useCauseLinkStyles } from "../../styles";

const CauseLink = React.memo(
  ({ title, link }: { title: string; link: string }) => {
    const C = useCauseLinkStyles();
    const hasPermission = usePermissions();

    if (hasPermission("rules-menu-access")) {
      return (
        <Link className={C.causeLink} to={link} target="_blank">
          <strong>{title}</strong> <OpenInNewIcon />
        </Link>
      );
    } else {
      return <></>;
    }
  },
);

export default CauseLink;
