import React, { ReactNode, MouseEventHandler } from "react";
import { Button } from "./styles";

export default React.memo(
  ({
    children,
    className,
    onClick,
    onDragEnd,
    prefix,
    suffix,
    isPrimary,
    disabled,
    draggable,
    grow = true,
    style,
    title,
  }: {
    children: ReactNode;
    prefix?: ReactNode;
    suffix?: ReactNode;
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    onDragEnd?: MouseEventHandler<HTMLButtonElement>;
    isPrimary?: boolean;
    disabled?: boolean;
    draggable?: boolean;
    grow?: boolean;
    style?: React.CSSProperties | undefined;
    title?: string | undefined;
  }) => {
    return (
      <Button
        draggable={draggable}
        title={title}
        style={style}
        disabled={disabled}
        onDragEnd={onDragEnd}
        type="button"
        onClick={onClick}
        className={`${className || ""} ${
          isPrimary ? "primary" : "non-primary"
        } ${grow ? "grow" : "no-grow"} atom-button`}
      >
        {!!prefix && <span className="prefix">{prefix} </span>}

        <span className="content">{children}</span>

        {!!suffix && <span className="suffix">{suffix}</span>}
      </Button>
    );
  },
);
