import styled from "styled-components";

export const TableActions = styled.div.attrs(() => ({
  className: "table-actions",
}))`
  flex: 0 0 auto;
  background-color: #f5f5f5;
  padding: 8px 16px 8px 8px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  flex-wrap: wrap;

  .search-input {
    left: 8px;
    width: 50%;
    min-width: 180px;
    max-width: 400px;
  }

  button,
  a {
    margin-left: 16px;
  }
`;

export const Table = styled.div.attrs(() => ({ className: "table" }))`
  display: flex;
  flex-direction: column;
  height: 100%;

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #0b9444;
  }

  .Mui-disabled {
    svg {
      color: rgba(0, 0, 0, 0.15);
    }
  }
`;

export const TableHeader = styled.div.attrs(() => ({
  className: "table-header",
}))`
  flex: 0 0 auto;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  line-height: 32px;
  background-color: #f5f5f5;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.15);
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const TableHeaderCell = styled.div.attrs(() => ({
  className: "table-header-cell",
}))`
  flex: 0 0 25%;
  padding: 0 16px;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  display: flex;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > svg {
    font-size: 18px;
    position: relative;
    top: 5px;
  }
`;

export const TableBodyWrapper = styled.div.attrs(() => ({
  className: "table-body-wrapper",
}))`
  flex: 1 0 auto;
  width: 100%;
  overflow: auto;
  position: relative;
`;

export const TableBody = styled.div.attrs(() => ({ className: "table-body" }))`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const TableRow = styled.div.attrs(() => ({ className: "table-row" }))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  line-height: 48px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
  z-index: 1;

  &:hover {
    background-color: #eee;
  }

  &.react-draggable-dragging {
    z-index: 2;
    background-color: #eee;
    position: relative !important;
  }
`;

export const TableCell = styled.div.attrs(() => ({ className: "table-cell" }))`
  flex: 0 0 25%;
  padding: 0 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  > svg {
    position: relative;
    top: 8px;
  }

  > .field {
    display: inline-block;

    > div {
      width: 300px;
    }
  }
`;
