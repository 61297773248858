import React, { ReactNode } from "react";
import { SlidingPanel } from "./styles";

export default React.memo(
  ({ children, open }: { children: ReactNode; open: boolean }) => {
    return (
      <SlidingPanel
        className={
          open ? "layout-sliding-panel open" : "layout-sliding-panel closed"
        }
      >
        {children}
      </SlidingPanel>
    );
  },
);
