import * as T from "types/engine-types";
import { Set as ISet } from "immutable";
import { Snapshot } from "..";
import { getProductKey } from ".";
import { exportFieldValueMapping, exportRuleId } from "../rules/export";
import { exportInvestorId } from "../investors/export";

export function exportProducts(
  snapshot: Snapshot,
  productIds: ISet<T.ProductId>,
): T.NewProduct[] {
  return snapshot.products
    .filter((p) => productIds.has(p.id))
    .map((p) => ({
      code: p.code,
      name: p.name,
      description: p.description,
      activationTimestamp: p.activationTimestamp,
      deactivationTimestamp: p.deactivationTimestamp,
      fieldValues: p.fieldValues.map((m) =>
        exportFieldValueMapping(snapshot, m),
      ),
      investorId: exportInvestorId(snapshot, p.investorId),
      ruleIds: p.ruleIds.map((ruleId) => exportRuleId(snapshot, ruleId)),
    }));
}

export function exportProductId(
  snapshot: Snapshot,
  productId: T.ProductId,
): T.ProductId {
  const product = snapshot.products.find((p) => p.id === productId);

  if (!product) {
    throw new Error("Product not found: ID = " + JSON.stringify(productId));
  }

  return getProductKey(product) as T.ProductId;
}
