import _ from "lodash";
import React, { useCallback } from "react";
import XLSX from "xlsx";
import { Configuration } from "config";
import * as T from "types/engine-types";
import * as Fields from "features/fields";
import { unreachable } from "features/utils";
import { ObjectDetails } from "features/objects";
import { Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { DataTableBody } from "./DataTableEditor";
import {
  expandedConfigSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import { useSelector } from "react-redux";

type Props =
  | {
      disabled?: false;
      tableName: string;
      columnDefs: T.DataTableColumn[];
      body: DataTableBody;
      children?: React.ReactNode;
    }
  | {
      disabled: true;
      tableName?: string;
      columnDefs?: T.DataTableColumn[] | null;
      body?: DataTableBody | null;
      children?: React.ReactNode;
    };

export const ExportTableButton = React.memo(function ExportTableButton({
  tableName,
  columnDefs,
  body,
  disabled,
  children,
}: Props): JSX.Element {
  const config = useSelector(expandedConfigSelector);
  const objectDetails = useSelector(objectDetailsMapSelector);

  const exportTable = useCallback(() => {
    if (disabled) {
      return;
    }

    const workbook = XLSX.utils.book_new();

    const sheet = XLSX.utils.aoa_to_sheet(
      [
        columnDefs.map((column) => column.name as string | number | null),
      ].concat(
        body.map((row) =>
          row.map((value, columnIndex) =>
            fieldValueToCellValue(
              config,
              objectDetails,
              columnDefs[columnIndex].valueType,
              value,
            ),
          ),
        ),
      ),
    );

    // XLSX only allows sheet names up to 31 characters, so truncate it here
    const sheetName = _.truncate(tableName, { length: 31 });

    XLSX.utils.book_append_sheet(workbook, sheet, sheetName);

    XLSX.writeFile(workbook, tableNameToFileName(tableName));
  }, [config, objectDetails, tableName, columnDefs, body, disabled]);

  return (
    <Button
      disabled={disabled}
      variant="outlined"
      startIcon={<GetAppIcon />}
      onClick={exportTable}
      children={children || "Export Table"}
    />
  );
});

function tableNameToFileName(tableName: string): string {
  return tableName.replace(/[^a-zA-Z0-9_ -]/g, "") + ".xlsx";
}

function fieldValueToCellValue(
  config: Configuration,
  objectDetails: ObjectDetails,
  valueType: T.FieldValueType,
  fieldValue: T.FieldValue,
): number | string | null {
  const combined = Fields.combineFieldValueWithValueType(valueType, fieldValue);

  switch (combined.type) {
    case "number":
      return +combined.fieldValue.value;
    case "string":
      return combined.fieldValue.value;
    case "enum":
    case "object-ref":
    case "duration":
    case "date":
      return Fields.fieldValueToString(
        config,
        objectDetails,
        valueType,
        fieldValue,
      );
    default:
      return unreachable(combined);
  }
}
