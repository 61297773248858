import _ from "lodash";
import React, {
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { useVirtual } from "react-virtual";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import * as T from "types/engine-types";
import { Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import { useSelector } from "react-redux";

export default function PageAccessList({
  users,
  editMode,
  copyMode,
  createMode,
  roleId,
  adminId,
  setUsersToAdd,
  usersToAdd,
}: {
  users: T.DetailedUser[];
  setUsersToAdd: Dispatch<SetStateAction<T.DetailedUser[]>>;
  usersToAdd: T.DetailedUser[];
  editMode: Boolean;
  copyMode: Boolean;
  createMode: Boolean;
  roleId: T.RoleId;
  adminId: T.RoleId | undefined;
}) {
  const parentRef = useRef<HTMLDivElement>(null);
  const usersToList = users;
  const adminUserCount = usersToList.filter((u) => u.roleId === adminId).length;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredUsers, setFilteredUsers] =
    useState<T.DetailedUser[]>(usersToList);
  const assignedUsers = usersToList.filter(
    (p: T.DetailedUser) => p.roleId === roleId,
  );
  const { user } = useSelector(nonNullApplicationInitializationSelector);

  useEffect(() => {
    setFilteredUsers(
      usersToList.filter((p: T.DetailedUser) => {
        if (editMode || copyMode || createMode) {
          return p.displayName.toLowerCase().includes(searchTerm.toLowerCase());
        } else {
          return (
            p.displayName.toLowerCase().includes(searchTerm.toLowerCase()) &&
            p.roleId === roleId
          );
        }
      }),
    );
  }, [searchTerm, usersToList, roleId, editMode, copyMode, createMode]);

  const rowVirtualizer = useVirtual({
    size: filteredUsers?.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, []),
  });

  return (
    <Table
      style={{ flex: "1 0 24%", borderRight: "1px solid rgba(0,0,0,.15)" }}
    >
      <TableActions style={{ padding: "8px" }}>
        <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
          <SearchInput
            label="users"
            requireDispatch={false}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <ResultsCount>
          {filteredUsers.length} <VisibilityIcon />
          <span className="separator">|</span>
          {assignedUsers.length} <CheckIcon />
          <span className="separator">|</span>
          {users.length} Total
        </ResultsCount>
      </TableActions>

      {editMode || copyMode || createMode ? (
        <>
          <TableHeader>
            <TableHeaderCell style={{ flexBasis: "50px" }}>
              <Checkbox
                disabled={true}
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              />
            </TableHeaderCell>

            <TableHeaderCell style={{ flexBasis: "calc(100% - 50px)" }}>
              Users
            </TableHeaderCell>
          </TableHeader>

          {filteredUsers.length ? (
            <TableBodyWrapper ref={parentRef}>
              <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
                {rowVirtualizer.virtualItems.map((virtualRow) => (
                  <TableRow
                    key={virtualRow.index}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <TableCell style={{ flexBasis: "54px" }}>
                      <Checkbox
                        disabled={
                          filteredUsers[virtualRow.index].roleId === roleId ||
                          (filteredUsers[virtualRow.index].roleId === adminId &&
                            adminUserCount === 1) ||
                          user.id === filteredUsers[virtualRow.index].id
                        }
                        checked={
                          usersToAdd.includes(
                            filteredUsers[virtualRow.index],
                          ) || filteredUsers[virtualRow.index].roleId === roleId
                        }
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        onChange={(e, checked) => {
                          if (checked) {
                            setUsersToAdd([
                              ...usersToAdd,
                              filteredUsers[virtualRow.index],
                            ]);
                          } else {
                            setUsersToAdd(
                              _.without(
                                usersToAdd,
                                filteredUsers[virtualRow.index],
                              ),
                            );
                          }
                        }}
                      />
                    </TableCell>

                    <TableCell style={{ flexBasis: "calc(100% - 54px)" }}>
                      {filteredUsers[virtualRow.index].displayName}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableBodyWrapper>
          ) : (
            <p
              style={{ marginTop: "14px", textAlign: "center", opacity: 0.65 }}
            >
              No users have been assigned to this role.
            </p>
          )}
        </>
      ) : (
        <>
          <TableHeader>
            <TableHeaderCell style={{ flexBasis: "100%" }}>
              Users
            </TableHeaderCell>
          </TableHeader>

          {filteredUsers.length ? (
            <TableBodyWrapper ref={parentRef}>
              <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
                {rowVirtualizer.virtualItems.map((virtualRow) => (
                  <TableRow
                    key={virtualRow.index}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <TableCell style={{ flexBasis: "100%" }}>
                      {filteredUsers[virtualRow.index].displayName}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableBodyWrapper>
          ) : (
            <p
              style={{ marginTop: "14px", textAlign: "center", opacity: 0.65 }}
            >
              No users have been assigned to this role.
            </p>
          )}
        </>
      )}
    </Table>
  );
}
