import React from "react";
import Icon from "design/atoms/icon";
import IconLink from "design/atoms/icon-link";
import {
  faDollarSign,
  faSuitcase,
  faBookOpen,
  faCalculator,
  faTable,
  faChartLine,
  faWrench,
  faUsers,
  faIdCard,
  faShield,
  faRefresh,
  faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";
import {
  nonNullApplicationInitializationSelector,
  usePermissions,
} from "features/application-initialization";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPipelineRecordId, setPipelineStage } from "features/loan-pricing";
import { loadScenario } from "features/loans";

export default React.memo(
  ({
    inline,
    showLoanPricing,
  }: {
    inline: boolean;
    showLoanPricing: boolean;
  }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const hasPermission = usePermissions();
    // Get the current client
    const {
      client: { accessId },
      config: { featureFlags },
    } = useSelector(nonNullApplicationInitializationSelector);
    return (
      <>
        {hasPermission("investors-menu-access") && (
          <IconLink
            inline={inline}
            to={`/c/${accessId}/investors`}
            icon={<Icon icon={faDollarSign} />}
          >
            Investors
          </IconLink>
        )}

        {hasPermission("products-menu-access") && (
          <IconLink
            inline={inline}
            to={`/c/${accessId}/products`}
            icon={<Icon icon={faSuitcase} />}
          >
            Products
          </IconLink>
        )}

        {hasPermission("field-enum-library-menu-access") && (
          <IconLink
            inline={inline}
            to={`/c/${accessId}/fields`}
            icon={<Icon icon={faBookOpen} />}
          >
            Field Library
          </IconLink>
        )}

        {hasPermission("calculations-menu-access") && (
          <IconLink
            inline={inline}
            to={`/c/${accessId}/calculations`}
            icon={<Icon icon={faCalculator} />}
          >
            Calculations
          </IconLink>
        )}

        {hasPermission("data-tables-menu-access") && (
          <IconLink
            inline={inline}
            to={`/c/${accessId}/data-tables`}
            icon={<Icon icon={faTable} />}
          >
            Data Tables
          </IconLink>
        )}

        {hasPermission("rate-sheets-menu-access") && (
          <IconLink
            inline={inline}
            to={`/c/${accessId}/rate-sheets`}
            icon={<Icon icon={faChartLine} />}
          >
            Rate Sheets
          </IconLink>
        )}

        {hasPermission("rules-menu-access") && (
          <IconLink
            inline={inline}
            to={`/c/${accessId}/rules`}
            icon={<Icon icon={faWrench} />}
          >
            Rules
          </IconLink>
        )}

        {featureFlags.pipeline &&
          showLoanPricing &&
          hasPermission("pipeline-menu-access") && (
            <IconLink
              inline={inline}
              to={`/c/${accessId}/v2/pipeline`}
              icon={<Icon icon={faRefresh} />}
              onClick={() => {
                dispatch(setPipelineRecordId(null));
                dispatch(setPipelineStage("initial"));
                dispatch(loadScenario(null));
              }}
            >
              Pipeline
            </IconLink>
          )}

        {showLoanPricing && hasPermission("price-a-loan-v1-view") && (
          <IconLink
            inline={inline}
            to={`/c/${accessId}/loan-pricing${location.hash}`}
            icon={<Icon icon={faRefresh} />}
          >
            Price a Loan Classic
          </IconLink>
        )}

        {showLoanPricing && hasPermission("price-a-loan-v1-view") && (
          <IconLink
            inline={inline}
            to={`/c/${accessId}/v2/loan-pricing${location.hash}`}
            icon={<Icon icon={faRefresh} />}
            onClick={() => {
              dispatch(setPipelineRecordId(null));
              dispatch(setPipelineStage("initial"));
              dispatch(loadScenario([]));
            }}
          >
            Price a Loan v2.0
          </IconLink>
        )}

        {hasPermission("roles-menu-access") && (
          <IconLink
            inline={inline}
            to={`/c/${accessId}/roles`}
            icon={<Icon icon={faShield} />}
          >
            Roles
          </IconLink>
        )}

        {hasPermission("vendor-integrations-menu-access") && (
          <IconLink
            inline={inline}
            to={`/c/${accessId}/vendor-integrations`}
            icon={<Icon icon={faPuzzlePiece} />}
          >
            Vendor Integrations
          </IconLink>
        )}

        {hasPermission("pricing-profiles-menu-access") && (
          <IconLink
            inline={inline}
            to={`/c/${accessId}/pricing-profiles`}
            icon={<Icon icon={faIdCard} />}
          >
            Pricing Profiles
          </IconLink>
        )}

        {hasPermission("users-menu-access") && (
          <IconLink
            inline={inline}
            to={`/c/${accessId}/users`}
            icon={<Icon icon={faUsers} />}
          >
            Users
          </IconLink>
        )}
      </>
    );
  },
);
