import { RoleId } from "types/generated-types";

const ACTIVE_ROLE_ID_KEY = "activeRoleId";

export function activeRoleId(): string {
  return sessionStorage.getItem(ACTIVE_ROLE_ID_KEY) || "";
}

export function setActiveRoleId(id: RoleId) {
  sessionStorage.setItem(ACTIVE_ROLE_ID_KEY, `${id}`);
}

export function unsetActiveRoleId() {
  sessionStorage.setItem(ACTIVE_ROLE_ID_KEY, "");
}
