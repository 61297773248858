import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import whitespace from "design/subatomics/whitespace";
import color from "design/subatomics/colors";
import { styles } from "design/atoms/typography";

export const KeyValueGroup = styled.div.attrs(() => ({
  className: "key-value-group",
}))`
  ${breakpoints.mobile} {
  }
  ${breakpoints.desktop} {
  }
`;

export const KeyValue = styled.span.attrs(() => ({
  className: "key-value",
}))`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;

  .key {
    ${styles.smallText};
    display: block;
    white-space: nowrap;
    color: ${color({ color: "gray", shade: 4 })};
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .value {
    white-space: nowrap;
    color: ${color({ color: "gray", shade: 2 })};
    text-overflow: ellipsis;
    overflow: hidden;
  }

  ${breakpoints.mobile} {
    margin: ${whitespace("least")} 0;
    width: 50%;

    .value {
      ${styles.panelTitle};
    }
  }

  ${breakpoints.desktop} {
    margin: ${whitespace("less")} ${whitespace("less")} ${whitespace("less")} 0;
    width: 240px;
    flex-wrap: wrap;

    .value {
      ${styles.panelTitle};
    }
  }
`;
