import { Set as ISet } from "immutable";
import React from "react";
import { Configuration } from "config";
import * as T from "types/engine-types";
import { LazyMemoCache } from "features/utils";
import SuspendedPricingWarning from "pages/loans/_components/suspended-price-warning";
import { ObjectDetails } from "features/objects";
import whitespace from "design/subatomics/whitespace";
import { SmallText, Text } from "design/atoms/typography";
import { RateWithLockPeriodPriceScenarioTable } from "./rate-with-lock-period";
import { RateWithColumnsPriceScenarioTable } from "./rate-with-columns";
import color from "design/subatomics/colors";
import { formatDateTime } from "features/utils";

const PriceScenarioTable = React.memo(
  ({
    openPriceScenarioById,
    scenarios,
    config,
    objectDetails,
    isInvestorPricingEnabled,
    productResult,
  }: {
    productResult: T.ProductExecutionResult;
    openPriceScenarioById: LazyMemoCache<string, () => void>;
    isInvestorPricingEnabled?: Boolean;
    scenarios: ISet<T.PriceScenarioResult>;
    config: Configuration;
    objectDetails: ObjectDetails;
  }) => {
    const { priceScenarioTableFieldInfo } = config;

    if (
      !priceScenarioTableFieldInfo ||
      scenarios.some((s) => s.status === "missing-configuration")
    ) {
      return (
        <Text>
          Pricing is unavailable due to a configuration problem. Check Settings
          on the Rate Sheets page.
        </Text>
      );
    }

    if (scenarios.size === 0) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: whitespace("most"),
          }}
        >
          <SmallText>No pricing available.</SmallText>
        </div>
      );
    }

    const effectiveTimestamp =
      productResult.status === "ok"
        ? productResult.rateSheetEffectiveTimestamp
        : null;

    return (
      <>
        {!isInvestorPricingEnabled && <SuspendedPricingWarning />}

        <div style={{ width: "100%", overflow: "auto" }}>
          {priceScenarioTableFieldInfo.type === "rate-with-lock-period" && (
            <RateWithLockPeriodPriceScenarioTable
              fieldInfo={priceScenarioTableFieldInfo}
              config={config}
              scenarios={scenarios}
              objectDetails={objectDetails}
              openPriceScenarioById={openPriceScenarioById}
            />
          )}
          {priceScenarioTableFieldInfo.type === "rate-with-columns" && (
            <RateWithColumnsPriceScenarioTable
              fieldInfo={priceScenarioTableFieldInfo}
              config={config}
              scenarios={scenarios}
              objectDetails={objectDetails}
              openPriceScenarioById={openPriceScenarioById}
            />
          )}
        </div>

        {effectiveTimestamp && (
          <Text
            style={{
              padding: whitespace("less"),
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              color: color({ color: "gray", shade: 4 }),
            }}
          >
            Effective as of: {formatDateTime(effectiveTimestamp)}
          </Text>
        )}

        <Text
          className="hide-from-print"
          style={{
            padding: whitespace("less"),
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            color: color({ color: "gray", shade: 4 }),
          }}
        >
          Click{" "}
          <strong style={{ margin: `0 ${whitespace("least")}` }}>
            any pricing cell
          </strong>{" "}
          above for additional details.
        </Text>
      </>
    );
  },
);

export default PriceScenarioTable;
