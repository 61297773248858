import _ from "lodash";
import React, { useCallback, useState, useEffect } from "react";
import Loader from "react-loader";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import * as Api from "api";
import { LoadingOverlay } from "design/atoms/loading-overlay";
import MessageBar from "design/atoms/message-bar";
import { PricingTableViewer } from "design/molecules/pricing-table-viewer";
import * as T from "types/engine-types";
import { useById, formatDateTime } from "features/utils";
import DetailActions from "design/molecules/detail-actions";
import DetailHeader from "design/molecules/detail-header";
import NextButton from "design/atoms/next-button";
import PrevButton from "design/atoms/prev-button";
import {
  filteredRateSheetsSelector,
  getRateSheets,
  rateSheetsSelector,
} from "features/rate-sheets";
import { getInvestors } from "features/investors";
import { usePermissions } from "features/roles";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

type Params = { id: T.RateSheetId };

export const ViewRateSheetPage = React.memo(() => {
  const { id } = useParams<Params>();
  const [rateSheet] = Api.useRateSheet(id);
  const [investors] = Api.useInvestors();
  const [products] = Api.useProducts();

  if (
    rateSheet.status === "error" ||
    investors.status === "error" ||
    products.status === "error"
  ) {
    return (
      <MessageBar
        text="Something went wrong, please check your internet connection and try again later. If you see this message again, please contact support."
        type="error"
      />
    );
  }

  if (
    rateSheet.status === "loading" ||
    investors.status === "loading" ||
    products.status === "loading"
  ) {
    return <Loader loaded={false} />;
  }

  return (
    <LoadedViewRateSheetPage
      rateSheet={rateSheet.value}
      investors={investors.value}
      products={products.value}
    />
  );
});

export const LoadedViewRateSheetPage = React.memo(
  ({
    rateSheet,
    investors,
    products,
  }: {
    rateSheet: T.RateSheet;
    investors: T.DecoratedInvestorHeader[];
    products: T.DecoratedProductHeader[];
  }) => {
    const { id } = rateSheet;
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const rateSheets = useSelector(filteredRateSheetsSelector);
    const dispatch = useDispatch();
    const investor = investors.find((i) => i.id === rateSheet.investorId);
    const hasPermission = usePermissions();
    const hasDeletePerm = hasPermission("rate-sheets-delete");
    const rateSheetsState = useSelector(rateSheetsSelector);
    const rateSheetMatch = rateSheets.find((i) => i.id === id);
    const positionInList =
      rateSheetMatch && rateSheets.indexOf(rateSheetMatch) + 1;
    const {
      client: { accessId },
    } = useSelector(nonNullApplicationInitializationSelector);

    useEffect(() => {
      if (investors.length === 0) dispatch(getInvestors());
    }, [dispatch, investors.length]);

    useEffect(() => {
      if (rateSheets.length === 0) dispatch(getRateSheets());
    }, [dispatch, rateSheets.length]);

    const downloadRateSheet = useCallback(() => {
      async function run() {
        setLoading(true);
        const url = await Api.getRateSheetDownloadUrl(rateSheet.id);
        setLoading(false);
        window.location.href = url;
      }

      run();
    }, [rateSheet]);

    const deleteRateSheet = useCallback(() => {
      async function run() {
        setLoading(true);
        await Api.deleteRateSheet(rateSheet.id);
        setLoading(false);
        dispatch(getRateSheets());
        history.push(`/c/${accessId}/rate-sheets`);
      }

      run();
    }, [accessId, rateSheet.id, history, dispatch]);

    return (
      <>
        <LoadingOverlay when={loading} />

        <DetailActions>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => history.push(`/c/${accessId}/rate-sheets`)}
          >
            Back to Rate Sheets ({positionInList}/{rateSheets.length})
            <span
              style={{
                fontWeight: 100,
                marginLeft: "8px",
                textTransform: "none",
              }}
            >
              sorted: {rateSheetsState.sortField} {rateSheetsState.sortDir}
            </span>
            {rateSheetsState.searchTerm && (
              <span style={{ fontWeight: 100, textTransform: "none" }}>
                , filtered: {rateSheetsState.searchTerm}
              </span>
            )}
          </Button>
          <PrevButton
            list={rateSheets}
            path={`/c/${accessId}/rate-sheets`}
            id={id}
            label="Previous Rate Sheet"
          />
          <NextButton
            list={rateSheets}
            path={`/c/${accessId}/rate-sheets`}
            id={id}
            label="Next Rate Sheet"
          />
          <Box flex="1" />
          <Box>
            <Button
              variant="outlined"
              startIcon={<GetAppIcon />}
              onClick={downloadRateSheet}
            >
              Download
            </Button>
          </Box>
          <Box ml={2}>
            <Button
              disabled={!hasDeletePerm}
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={() => setDeleteConfirmOpen(true)}
            >
              Delete
            </Button>
          </Box>
        </DetailActions>

        {!!investor && (
          <RateSheetViewer
            rateSheet={rateSheet}
            products={products}
            investor={investor}
          />
        )}

        <Dialog
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
        >
          <DialogTitle>Delete rate sheet?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this rate sheet? If this is the
              most recent rate sheet for any products on the rate sheet, the
              pricing for those products will be affected.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
            <Button
              color="secondary"
              onClick={() => {
                setDeleteConfirmOpen(false);
                deleteRateSheet();
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  },
);

const RateSheetViewer = React.memo(
  ({
    rateSheet,
    products,
    investor,
  }: {
    rateSheet: T.RateSheet;
    products: T.DecoratedProductHeader[];
    investor: T.InvestorHeader;
  }) => {
    const productsById = useById(products);
    const sortedPricingTables = _.sortBy(
      rateSheet.pricingTables,
      (table) => productsById.get(table.productId)?.name || "000",
    );

    return (
      <>
        <DetailHeader>
          <Typography variant="h4">{investor.name} Rate Sheet</Typography>
          <Box
            px={3}
            style={{
              alignSelf: "flex-end",
              position: "relative",
              top: "-4px",
              left: "-8px",
            }}
            color="text.secondary"
          >
            Uploaded {formatDateTime(rateSheet.creationTimestamp)}. Effective{" "}
            {formatDateTime(rateSheet.effectiveTimestamp)}.
          </Box>
        </DetailHeader>

        <div style={{ flex: "1 1 auto", overflow: "auto" }}>
          {sortedPricingTables.map((table) => {
            const product = productsById.get(table.productId);

            return (
              <>
                <Box px={3} mt={3} pb={1} fontSize="24px">
                  {product?.name || "Unknown Product"}
                </Box>
                {!!table.rateSheetFormatPricingTableName && (
                  <Box px={3} color="text.secondary">
                    Imported from pricing table{" "}
                    <strong>{table.rateSheetFormatPricingTableName}</strong>.
                  </Box>
                )}
                <Box px={3} pt={2} mb={3}>
                  <PricingTableViewer prices={table.priceScenarios} />
                </Box>
              </>
            );
          })}
        </div>
      </>
    );
  },
);
