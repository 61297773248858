import React from "react";
import { useSelector } from "react-redux";
import { PipelineResults, AccordionWrapper, PageMessage } from "./styles";
import PipelineResult from "../pipeline-result";
import PipelineActionPanel from "../pipeline-action-panel";
import Accordion from "design/molecules/accordion";
import Icon from "design/atoms/icon";
import { FilteredPipelineRecords } from "features/pipeline";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircle as faCircleFilled } from "@fortawesome/free-solid-svg-icons";

export default React.memo(({ loading }: { loading: boolean }) => {
  const pipelineGroups = useSelector(FilteredPipelineRecords);

  return (
    <PipelineResults className={"page-pipeline-component-pipeline-results"}>
      <PipelineActionPanel loading={loading} />

      {!pipelineGroups["active"].length &&
        !pipelineGroups["archived"].length && (
          <PageMessage>No pipeline records found.</PageMessage>
        )}

      <AccordionWrapper>
        {!!pipelineGroups["active"].length && (
          <Accordion
            open={true}
            label={`Active (${pipelineGroups["active"].length})`}
            color="blue"
            icon={<Icon icon={faCircle} />}
          >
            {pipelineGroups["active"].map((pipeline) => {
              return (
                <PipelineResult
                  record={pipeline}
                  key={pipeline.id}
                  color="blue"
                  icon={<Icon icon={faCircle} />}
                />
              );
            })}
          </Accordion>
        )}

        {!!pipelineGroups["archived"].length && (
          <Accordion
            open={false}
            label={`Archived (${pipelineGroups["archived"].length})`}
            color="gray"
            icon={<Icon icon={faCircleFilled} />}
          >
            {pipelineGroups["archived"].map((pipeline) => {
              return (
                <PipelineResult
                  record={pipeline}
                  key={pipeline.id}
                  color="gray"
                  icon={<Icon icon={faCircleFilled} />}
                />
              );
            })}
          </Accordion>
        )}
      </AccordionWrapper>
    </PipelineResults>
  );
});
