import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { getDataTables } from "features/data-tables";
import * as Api from "api";
import * as T from "types/engine-types";
import UnloadPrompt from "design/atoms/unload-prompt";
import MessageBar from "design/atoms/message-bar";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "react-loader";

import {
  DataTableEditor,
  validateDataTableState,
  convertStateToDataTable,
  convertDataTableToState,
} from "../_components/DataTableEditor";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import _ from "lodash";

type Params = {
  id: T.DataTableId;
};

export default React.memo(function EditDataTablePage() {
  const { id } = useParams<Params>();

  const [loadState, reloadDataTable] = Api.useDataTable(id);

  if (loadState.status === "loading") {
    return <Loader loaded={false} />;
  }

  if (loadState.status === "error") {
    return (
      <MessageBar
        text="Something went wrong, please check your internet connection and try again later. If you see this message again, please contact support."
        type="error"
      />
    );
  }

  return (
    <EditDataTablePageLoaded
      dataTable={loadState.value}
      reloadDataTable={reloadDataTable}
    />
  );
});

const EditDataTablePageLoaded = React.memo(function EditDataTablePageLoaded({
  dataTable,
}: {
  dataTable: T.DataTable;
  reloadDataTable: () => void;
}) {
  const savedState = useMemo(
    () => convertDataTableToState(dataTable),
    [dataTable],
  );
  const [editorState, setEditorState] = useState(savedState);
  const stateIsUnchanged = useMemo(
    () => _.isEqual(savedState, editorState),
    [savedState, editorState],
  );

  const [showErrors, setShowErrors] = useState(false);
  const currentError = useMemo(
    () => validateDataTableState(editorState),
    [editorState],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [nextUrl, navigateTo] = useState<string>();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);

  const saveDataTable = useCallback(() => {
    (async () => {
      setShowErrors(true);

      if (currentError !== null) {
        return;
      }

      const dataTableChangeset: T.DataTableChangeset =
        convertStateToDataTable(editorState);

      setIsLoading(true);

      await Api.updateDataTable(dataTable.id, dataTableChangeset);

      setIsLoading(false);

      dispatch(getDataTables());

      navigateTo(`/c/${accessId}/data-tables/${dataTable.id}`);
    })();
  }, [
    setShowErrors,
    currentError,
    editorState,
    dataTable.id,
    navigateTo,
    dispatch,
    accessId,
  ]);

  return (
    <>
      <UnloadPrompt when={true} nextUrl={nextUrl} />

      <Box m={2} display="flex">
        <Button
          variant="outlined"
          startIcon={<CloseIcon />}
          onClick={() =>
            history.push(`/c/${accessId}/data-tables/${dataTable.id}`)
          }
        >
          Cancel
        </Button>
        <Box flex="1" />
        {showErrors && currentError && (
          <Box display="flex" alignItems="center" px={2}>
            <Typography color="secondary">
              Error: {currentError.message}
            </Typography>
          </Box>
        )}
        <Button
          variant="outlined"
          startIcon={<DoneIcon />}
          onClick={saveDataTable}
          disabled={
            isLoading || (showErrors && !!currentError) || stateIsUnchanged
          }
        >
          Save
        </Button>
      </Box>

      <DataTableEditor
        state={editorState}
        setState={setEditorState}
        showErrors={showErrors}
        title="Edit Data Table"
      />
    </>
  );
});
