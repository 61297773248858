import React, { useEffect } from "react";
import HelpIcon from "@material-ui/icons/Help";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Box, Paper, Tab, Tabs, Tooltip } from "@material-ui/core";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import EncompassSettingsPage from "./settings";
import EncompassIncomingMappingsPage from "./incoming-mappings";
import EncompassOutgoingMappingsPage from "./outgoing-mappings";
import EncompassCustomFieldsPage from "./custom-fields";
import { useSelector } from "react-redux";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

const usePageStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  paper: {
    flex: "1",
    margin: theme.spacing(2),
  },
  helpIcon: {
    fontSize: "1rem",
    marginLeft: "0.5em",
    color: theme.palette.info.main,
    display: "inline-block",
    marginBottom: "-2px",
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export type TabId =
  | "settings"
  | "incoming-mappings"
  | "outgoing-mappings"
  | "custom-fields";

type RouteParams = {
  tabId: TabId | undefined;
};

export default function EncompassPage() {
  const styles = usePageStyles();

  const match = useRouteMatch();
  const history = useHistory();

  const params = useParams<RouteParams>();

  const { client } = useSelector(nonNullApplicationInitializationSelector);

  useEffect(() => {
    // If no tab is selected, redirect.
    if (!params.tabId) {
      if (client.accessId !== "super") {
        // For regular clients, redirect to the Settings tab.
        history.replace({
          pathname: generatePath(match.path, {
            ...match.params,
            tabId: "settings",
          }),
        });
      } else {
        // For super clients, redirect to the Incoming Mappings
        // tab since there is no settings tab.
        history.replace({
          pathname: generatePath(match.path, {
            ...match.params,
            tabId: "incoming-mappings",
          }),
        });
      }
    }
  });

  const selectTab = (tabId: TabId) => {
    history.push({
      pathname: generatePath(match.path, { ...match.params, tabId }),
    });
  };

  return (
    <Box className={styles.container}>
      <Paper className={styles.paper}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box display="flex">
            <Tabs
              value={params.tabId}
              onChange={(_, tabId) => selectTab(tabId as TabId)}
            >
              {client.accessId !== "super" && (
                <Tab label="Settings" value="settings" />
              )}
              {client.accessId !== "super" && (
                <Tab label="Custom Fields" value="custom-fields" />
              )}
              <Tab
                label={
                  <Box>
                    Incoming Field Mappings
                    <HtmlTooltip
                      title={
                        <>
                          <Typography color="inherit">
                            Map fields from Encompass to LoanPASS
                          </Typography>
                          When you make a pricing request in Encompass,
                          Encompass will send its raw field data to LoanPASS,
                          which will use it as inputs to the pricing engine. But
                          first, the Encompass field values need to be
                          translated into values that LoanPASS understands. This
                          is where you can define those translations.
                        </>
                      }
                    >
                      <HelpIcon className={styles.helpIcon} />
                    </HtmlTooltip>
                  </Box>
                }
                value="incoming-mappings"
              />
              <Tab
                label={
                  <Box>
                    Outgoing Field Mappings
                    <HtmlTooltip
                      title={
                        <>
                          <Typography color="inherit">
                            Map fields from LoanPASS to Encompass
                          </Typography>
                          After you run pricing against the data translated from
                          Encompass and get an approved product, you can request
                          that Encompass create a lock for that product.
                          LoanPASS will send the product's data to Encompass to
                          create the lock request, but first, the LoanPASS field
                          values need to be translated into values that
                          Encompass understands. This is where you can define
                          those translations.
                        </>
                      }
                    >
                      <HelpIcon className={styles.helpIcon} />
                    </HtmlTooltip>
                  </Box>
                }
                value="outgoing-mappings"
              />
            </Tabs>
          </Box>
          <Box
            flex="1"
            display="flex"
            overflow="hidden"
            justifyContent="stretch"
          >
            {client.accessId !== "super" && params.tabId === "settings" && (
              <EncompassSettingsPage />
            )}
            {client.accessId !== "super" &&
              params.tabId === "custom-fields" && <EncompassCustomFieldsPage />}
            {params.tabId === "incoming-mappings" && (
              <EncompassIncomingMappingsPage />
            )}
            {params.tabId === "outgoing-mappings" && (
              <EncompassOutgoingMappingsPage />
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
