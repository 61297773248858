import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as T from "types/engine-types";

export type QueryParamsState = {
  activeView: string | undefined;
  activeRole: string | undefined;
  activePricingProfile: string | undefined;
  activeScenario: string | undefined;
  inputsHash: string | undefined;
  sort: string | undefined;
  sortDir: string | undefined;
  filters: string | undefined;
};

type PipelineStage = "initial" | "preCheck" | "create" | "view";

export type LoanPricingState = {
  pipelineStage: PipelineStage;
  pipelineRecordId: T.PipelineRecordId | null;
  queryParams: QueryParamsState;
};

const initialState: LoanPricingState = {
  pipelineStage: "initial",
  pipelineRecordId: null,
  queryParams: {
    activeView: undefined,
    activeRole: undefined,
    activePricingProfile: undefined,
    activeScenario: undefined,
    inputsHash: undefined,
    sort: undefined,
    sortDir: undefined,
    filters: undefined,
  },
};

const loanPricingSlice = createSlice({
  name: "LoanPricing",
  initialState,
  reducers: {
    setPipelineStage: (state, { payload }: PayloadAction<PipelineStage>) => {
      state.pipelineStage = payload;
    },
    setPipelineRecordId: (
      state,
      { payload }: PayloadAction<T.PipelineRecordId | null>,
    ) => {
      state.pipelineRecordId = payload;
    },
    updateQueryParams: (
      state,
      { payload }: PayloadAction<QueryParamsState>,
    ) => {
      state.queryParams = payload;
    },
  },
});

export const { updateQueryParams, setPipelineStage, setPipelineRecordId } =
  loanPricingSlice.actions;

export default loanPricingSlice;

export const loanPricingStateSelector = (state: {
  loanPricing: LoanPricingState;
}) => state.loanPricing;
