import React, { ReactElement } from "react";
import { Box, Typography } from "@material-ui/core";

import * as T from "types/engine-types";

const ProductResultHeader = React.memo(
  ({
    product,
    fgColor,
    bgColor,
    statusIcon,
    statusMessage,
    investorName,
  }: {
    product: T.DecoratedProductHeader;
    fgColor: string;
    bgColor: string;
    statusIcon?: ReactElement;
    statusMessage?: string;
    investorName: string;
  }) => {
    return (
      <Box
        className="product-results-header"
        p={3}
        color={fgColor}
        bgcolor={bgColor}
      >
        <Typography>{investorName}</Typography>
        <Box className="flatten-for-print" display="flex" alignItems="center">
          <Box className="product-name" paddingTop="16px" fontSize="2.125rem">
            {product.name}
          </Box>
          <Box flex="1" />
          <Box
            className="product-code"
            fontSize="1.5rem"
            style={{ opacity: "0.5" }}
          >
            {product.code}
          </Box>
        </Box>
        {statusIcon && statusMessage && (
          <Box display="flex" alignItems="center">
            {statusIcon}
            <Box ml={1}>{statusMessage}</Box>
          </Box>
        )}
      </Box>
    );
  },
);

export default ProductResultHeader;
