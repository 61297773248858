import _ from "lodash";
import { Column } from "material-table";
import React, { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import * as Api from "api";
import MessageBar from "design/atoms/message-bar";
import Table from "design/atoms/admin-table";
import * as T from "types/engine-types";
import { localAccessId } from "features/access-id";

const COLUMNS: Column<T.RateSheetFormatHeader>[] = [
  {
    title: "Internal Name",
    field: "internalName",
    defaultSort: "asc",
    cellStyle: { paddingLeft: 24 },
    headerStyle: { paddingLeft: 24 },
  },
  {
    title: "Display Name",
    field: "displayName",
  },
  {
    render: () => <ChevronRightIcon />,
    cellStyle: { textAlign: "right", paddingRight: 30 },
  },
];

export const RateSheetFormatsPage = React.memo(() => {
  const history = useHistory();

  const [formatsLoadState] = Api.Admin.useRateSheetFormats();
  const accessId = localAccessId();

  const createFormat = useCallback(() => {
    history.push(`/c/${accessId}/__admin/create-rate-sheet-format`);
  }, [history, accessId]);

  if (formatsLoadState.status === "error") {
    return (
      <MessageBar
        text="Something went wrong, please check your internet connection and try again later. If you see this message again, please contact support."
        type="error"
      />
    );
  }
  // TODO: this is a potential bug, update this component to not return before calling useCallback
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onRowClick = useCallback(
    (event: unknown, format: T.RateSheetFormatHeader | undefined) => {
      if (format) {
        history.push(`/c/${accessId}/__admin/rate-sheet-formats/${format.id}`);
      }
    },
    [history, accessId],
  );

  const formats =
    formatsLoadState.status === "loaded"
      ? _.sortBy(formatsLoadState.value, "internalName")
      : [];

  return (
    <Box>
      <Box m={2} display="flex">
        <Button
          component={Link}
          to="/c/super/__admin/clients"
          variant="outlined"
          startIcon={<ArrowBackIcon />}
        >
          Back to Clients
        </Button>
        <Box flex="1" />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={createFormat}
        >
          Create Rate Sheet Format
        </Button>
      </Box>
      <Box m={2}>
        <Table<T.RateSheetFormatHeader>
          columns={COLUMNS}
          data={formats}
          isLoading={formatsLoadState.status === "loading"}
          onRowClick={onRowClick}
        />
      </Box>
    </Box>
  );
});
