import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Box, TextField } from "@material-ui/core";
import * as T from "types/engine-types";
import { getMessageNode } from "features/server-validation";
import {
  Setter,
  usePropertySetter,
  createSlugId,
  unPrefixId,
  resolveEnum,
  usePropertySetter2,
} from "features/utils";
import {
  nonNullApplicationInitializationSelector,
  expandedConfigSelector,
} from "features/application-initialization";
import { FieldValueTypeEditor } from "design/organisms/field-value-type-editor";
import { RefSourcesViewer } from "design/atoms/ref-sources-viewer";
import {
  FieldDefinitionState,
  NativeFieldDefinitionState,
  useObjectEditorStyles,
} from "pages/fields";
import { OptionalFieldConditionsEditor } from "../optional-field-conditions-editor";

export const NativeFieldEditor = React.memo(
  ({
    state,
    showErrors,
    setState,
    previousFields,
    enumTypes,
    allowHeaders,
    fieldType,
  }: {
    state: NativeFieldDefinitionState;
    showErrors: boolean;
    setState: Setter<NativeFieldDefinitionState>;
    previousFields: FieldDefinitionState[];
    enumTypes: T.RawEnumType[];
    allowHeaders: boolean;
    fieldType: "credit-application" | "product" | "pipeline";
  }) => {
    const C = useObjectEditorStyles();
    const config = useSelector(expandedConfigSelector);
    const { client } = useSelector(nonNullApplicationInitializationSelector);

    const setId = usePropertySetter(setState, "id");
    const setName = usePropertySetter2(setState, "disposition", "name");
    const setDescription = usePropertySetter2(
      setState,
      "disposition",
      "description",
    );
    const setValueType = usePropertySetter2(
      setState,
      "disposition",
      "valueType",
    );
    const setConditions = usePropertySetter2(
      setState,
      "disposition",
      "conditions",
    );

    const handleIdChange = useCallback(
      (event: { target: { value: string } }) =>
        setId(("field@" + event.target.value) as T.FieldId),
      [setId],
    );

    const handleNameChange = useCallback(
      (event: { target: { value: string } }) => {
        setName(event.target.value);
        if (!state.oldId) {
          setId(createSlugId(event.target.value, "field@") as T.FieldId);
        }
      },
      [setId, setName, state.oldId],
    );

    const sortEnumTypeToTop = useCallback(
      (enumType: T.RawEnumType) =>
        resolveEnum(
          enumType,
          config.systemEnumTypesById,
          client.displayNewInheritedEnumVariants,
        ).name.toLowerCase() === state.disposition.name.toLowerCase(),
      [state.disposition.name, config, client],
    );

    return (
      <Box display="flex" flexDirection="column">
        <TextField
          className={C.standardField}
          label="Field Name"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={state.disposition.name}
          error={
            (showErrors && state.disposition.name.trim() === "") ||
            !!state.disposition.domainValidationErrors.name.length
          }
          onChange={handleNameChange}
          helperText={getMessageNode(
            state.disposition.domainValidationErrors.name,
          )}
        />
        <TextField
          className={C.standardField}
          label="Field ID"
          InputProps={{ startAdornment: "field@" }}
          fullWidth
          disabled={!!state.oldId}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={unPrefixId(state.id, "field@")}
          error={
            (showErrors && state.id.trim() === "") ||
            !!state.domainValidationErrors.id.length
          }
          onChange={handleIdChange}
          helperText={getMessageNode(state.domainValidationErrors.id)}
        />
        <TextField
          className={C.standardField}
          label="Field Description"
          multiline
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={state.disposition.description}
          error={!!state.disposition.domainValidationErrors.description.length}
          onChange={(e) => setDescription(e.target.value)}
          helperText={getMessageNode(
            state.disposition.domainValidationErrors.description,
          )}
        />
        <Box maxWidth="400px">
          <FieldValueTypeEditor
            state={state.disposition.valueType}
            showErrors={showErrors}
            setState={setValueType}
            enumTypes={enumTypes}
            sortEnumTypeToTop={sortEnumTypeToTop}
            allowHeaders={allowHeaders}
            allowPricingProfiles={false}
          />
        </Box>
        {state.disposition.valueType.fieldType !== "header" &&
          fieldType !== "pipeline" && (
            <>
              <OptionalFieldConditionsEditor
                state={state.disposition.conditions}
                showErrors={showErrors}
                setState={setConditions}
                previousFields={previousFields}
                enumTypes={enumTypes}
              />
              <RefSourcesViewer
                objectId={{ type: "field", fieldId: state.id }}
                mt={2}
                style={{ marginBottom: "16px" }}
              />
            </>
          )}
      </Box>
    );
  },
);
