import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as T from "types/engine-types";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { Setter, unreachable, useDebounce } from "features/utils";
import {
  DynamicPipelineFilter,
  DurationValueMap,
  pipelineFiltersSelector,
  setDynamicFilters,
  uniqueDurationValueSelector,
} from "features/pipeline";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import DatePicker from "react-date-picker";
import TextInput from "design/atoms/text-input";
import Icon from "design/atoms/icon";
import { Wrapper, CheckboxWrapper, CheckboxText, MultiSelect } from "./styles";
import RangeInput from "design/atoms/range-input";

type MultiSelectOption = EnumMultiselect | DurationMultiselect;

type EnumMultiselect = { type: "enum" } & T.EnumVariant;

type DurationMultiselect = { type: "duration" } & T.DurationValue;

export default React.memo(function FilterEditor({
  filterField,
}: {
  filterField: T.BaseFieldDefinition;
}) {
  const dispatch = useDispatch();
  const { config } = useSelector(nonNullApplicationInitializationSelector);
  const pipelineFilters = useSelector(pipelineFiltersSelector);
  const uniqueDurationValues = useSelector(uniqueDurationValueSelector);
  const [dynamicFiltersState, setDynamicFiltersState] = useState<
    DynamicPipelineFilter[]
  >(pipelineFilters.dynamicFilters);
  const [debouncedState] = useDebounce(dynamicFiltersState, 500);

  // function for creating a new empty filter object
  const handleNewFilter = (
    field: T.BaseFieldDefinition,
  ): DynamicPipelineFilter => {
    switch (field.valueType.type) {
      case "number":
        return {
          id: field.id,
          disposition: {
            type: "number",
            values: {
              min: "",
              max: "",
            },
          },
        };
      case "string":
        return {
          id: field.id,
          disposition: {
            type: "string",
            value: "",
          },
        };
      case "enum":
        const enumTypeId =
          field.valueType.type === "enum" ? field.valueType.enumTypeId : null;
        const enumType = config.enumTypes.find(
          (variant) => variant.id === enumTypeId,
        );

        // for multiselect filters we want all options enabled by default so all options need to be included in the initial state when first created, here we pass all relevant enumVariants for the enumFilter
        return {
          id: field.id,
          disposition: {
            type: "enum",
            values: {
              enumTypeId: enumTypeId!,
              includedValues: enumType?.variants ? enumType?.variants : [],
            },
          },
        };
      // the same is true for including all duration value options by default
      case "duration":
        const allRelevantDurationValues = uniqueDurationValues[field.id]
          ? uniqueDurationValues[field.id].values
          : [];
        const durationValuesArray = Object.entries(
          allRelevantDurationValues,
        ).flatMap((value) => {
          return {
            unit: value[0],
            count: value[1],
          };
        });
        const initialValues: T.DurationValue[] = [];
        durationValuesArray.map((obj) => {
          obj.count.map((count) => {
            const durationValue: T.DurationValue = {
              unit: obj.unit as T.DurationUnit,
              count,
            };

            initialValues.push(durationValue);
          });
        });

        return {
          id: field.id,
          disposition: {
            type: "duration",
            values: {
              includedValues: initialValues,
            },
          },
        };
      case "date":
        return {
          id: field.id,
          disposition: {
            type: "date",
            value: "",
          },
        };
      case "object-ref":
      case "header":
        // currently i'm unable to find a workaround to this switch statement not playing nice with eslint, we will never pass a header field so this code shouldn't run and its easier than using isPresent to verify that this function isnt returning undefined
        return {
          id: field.id,
          disposition: {
            type: "string",
            value: "",
          },
        };
      default:
        return unreachable(field.valueType);
    }
  };

  // finds the current filter in state if it exists otherwise creates a new blank filter object
  const currentFilter: DynamicPipelineFilter =
    [...dynamicFiltersState].find((filter) => filter.id === filterField.id) ??
    handleNewFilter(filterField);

  useEffect(() => {
    // call setDynamicFilters from redux slice whenever state changes
    dispatch(setDynamicFilters(debouncedState));
  }, [debouncedState, dispatch]);

  const handleFilterType = (
    type:
      | "string"
      | "number"
      | "enum"
      | "duration"
      | "date"
      | "header"
      | "object-ref",
  ) => {
    switch (filterField.valueType.type) {
      case "string":
        return (
          <StringFilter
            field={filterField}
            currentFilter={currentFilter}
            state={dynamicFiltersState}
            setState={setDynamicFiltersState}
          />
        );
        break;
      case "number":
        return (
          <NumberFilter
            field={filterField}
            currentFilter={currentFilter}
            state={dynamicFiltersState}
            setState={setDynamicFiltersState}
          />
        );
        break;
      case "enum":
        return (
          <EnumFilter
            field={filterField}
            currentFilter={currentFilter}
            state={dynamicFiltersState}
            setState={setDynamicFiltersState}
          />
        );
        break;
      case "duration":
        return (
          <DurationFilter
            field={filterField}
            currentFilter={currentFilter}
            state={dynamicFiltersState}
            setState={setDynamicFiltersState}
            uniqueDurationValues={uniqueDurationValues}
          />
        );
        break;
      case "date":
        return (
          <DateFilter
            field={filterField}
            currentFilter={currentFilter}
            state={dynamicFiltersState}
            setState={setDynamicFiltersState}
          />
        );
        break;
      case "header":
      case "object-ref":
        return <></>;
      default:
        return unreachable(filterField.valueType);
    }
  };

  return handleFilterType(filterField.valueType.type);
});

const StringFilter = React.memo(function StringFilter({
  field,
  currentFilter,
  state,
  setState,
}: {
  field: T.BaseFieldDefinition;
  currentFilter: DynamicPipelineFilter;
  state: DynamicPipelineFilter[];
  setState: Setter<DynamicPipelineFilter[]>;
}) {
  const [filterValue, setFilterValue] = useState<string>(
    currentFilter.disposition.type === "string"
      ? currentFilter.disposition.value
      : "",
  );

  useEffect(() => {
    const updatedFilter: DynamicPipelineFilter = {
      id: field.id,
      disposition: {
        type: "string",
        value: filterValue,
      },
    };
    const newFilters: DynamicPipelineFilter[] = [...state].filter(
      (f) => f.id !== field.id,
    );
    if (!!filterValue) {
      newFilters.push(updatedFilter);
    }

    setState(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.id, filterValue, setState]);

  return (
    <Wrapper>
      <TextInput
        placeholderText={field.name}
        inputProps={{
          value: filterValue ? filterValue : "",
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setFilterValue(e.target.value);
          },
        }}
      />
    </Wrapper>
  );
});

const NumberFilter = React.memo(function NumberFilter({
  field,
  currentFilter,
  state,
  setState,
}: {
  field: T.BaseFieldDefinition;
  currentFilter: DynamicPipelineFilter;
  state: DynamicPipelineFilter[];
  setState: Setter<DynamicPipelineFilter[]>;
}) {
  let prefix = null;
  let suffix = null;
  const adornmentType =
    field.valueType.type === "number" && field.valueType.style;
  switch (adornmentType) {
    case "percent":
      suffix = "%";
      break;
    case "dollar":
      prefix = "$";
      break;
    case "plain":
      break;
    case false:
      break;
  }
  let adornment: "suffix" | "prefix" | null = null;
  let adornmentText: string | undefined = undefined;
  if (suffix) {
    adornment = "suffix";
    adornmentText = suffix;
  }
  if (prefix) {
    adornment = "prefix";
    adornmentText = prefix;
  }

  const [minValue, setMinValue] = useState<string>(
    currentFilter.disposition.type === "number"
      ? currentFilter.disposition.values.min
      : "",
  );
  const [maxValue, setMaxValue] = useState<string>(
    currentFilter.disposition.type === "number"
      ? currentFilter.disposition.values.max
      : "",
  );

  useEffect(() => {
    const updatedFilter: DynamicPipelineFilter = {
      id: field.id,
      disposition: {
        type: "number",
        values: {
          min: minValue,
          max: maxValue,
        },
      },
    };

    const newFilters: DynamicPipelineFilter[] = [...state].filter(
      (f) => f.id !== field.id,
    );

    if (!!minValue || !!maxValue) {
      newFilters.push(updatedFilter);
    }

    setState(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.id, minValue, maxValue, setState]);

  return (
    <RangeInput
      minValue={minValue}
      minSetter={setMinValue}
      maxValue={maxValue}
      maxSetter={setMaxValue}
      adornment={adornment}
      adornmentText={adornmentText}
    />
  );
});

/**
 * This multiselect can currently only handle enum and duration field values, but can be plumbed in the future to support other field types if necessary by expanding upon the union types available to MultiSelectOption and adding their corresponding logic to the switch statement on o.type
 */
const FilterMultiselect = React.memo(function FilterMultiselect<
  T extends MultiSelectOption,
>({
  field,
  currentFilter,
  state,
  setState,
  options,
}: {
  field: T.BaseFieldDefinition;
  currentFilter: DynamicPipelineFilter;
  state: DynamicPipelineFilter[];
  setState: Setter<DynamicPipelineFilter[]>;
  options: T[];
}) {
  return (
    <MultiSelect>
      {options.map((o, i) => {
        let checked: boolean = false;

        switch (o.type) {
          case "enum":
            const enumTypeId =
              currentFilter?.disposition.type === "enum"
                ? currentFilter.disposition.values.enumTypeId
                : null;
            const currentEnums =
              currentFilter?.disposition.type === "enum"
                ? currentFilter?.disposition.values.includedValues
                : [];
            const currentVariant: T.EnumVariant = {
              oldId: o.oldId,
              id: o.id,
              name: o.name,
            };

            // .includes() was not working here for some reason, but casting the result of .find() to a boolean works instead
            checked = !!currentEnums.find(
              (variant) => variant.id === currentVariant.id,
            );

            return (
              <CheckboxWrapper
                key={i}
                onClick={() => {
                  if (checked) {
                    // remove the current variant from currentEnums
                    const newIncludedValues: T.EnumVariant[] = [
                      ...currentEnums,
                    ].filter((variant) => variant.id !== o.id);

                    // create new filter object using newIncludedValues
                    const newFilter: DynamicPipelineFilter = {
                      id: field.id,
                      disposition: {
                        type: "enum",
                        values: {
                          enumTypeId: enumTypeId as T.EnumTypeId,
                          includedValues: newIncludedValues,
                        },
                      },
                    };

                    // iterate over the dynamicFiltersState and replace the old values of the current filter with the new ones
                    const newFilterArray = [...state].find(
                      (filter) => filter.id === field.id,
                    )
                      ? [...state].map((filter) => {
                          if (filter.id !== field.id) {
                            return filter;
                          } else {
                            return newFilter;
                          }
                        })
                      : [...state, newFilter];

                    setState(newFilterArray);
                  } else {
                    // add the current variant to currentEnums
                    const newIncludedValues: T.EnumVariant[] = [
                      ...currentEnums,
                      currentVariant,
                    ];

                    // create new filter object using newIncludedValues
                    const newFilter: DynamicPipelineFilter = {
                      id: field.id,
                      disposition: {
                        type: "enum",
                        values: {
                          enumTypeId: enumTypeId as T.EnumTypeId,
                          includedValues: newIncludedValues,
                        },
                      },
                    };

                    // if the current filter already exists in dynamicFilterState we iterate over the values and replace it like above, otherwise we add the current filter to dynamicFilterState. If the length of all options is equal to the amount of includedValues we can remove the filter from state instead as to not clutter state with filter values that dont actually filter out any values
                    const newFilterArray = [...state].find(
                      (filter) => filter.id === field.id,
                    )
                      ? newIncludedValues.length === options.length
                        ? [...state].filter((f) => f.id !== field.id)
                        : [...state].map((filter) => {
                            if (filter.id !== field.id) {
                              return filter;
                            } else {
                              return newFilter;
                            }
                          })
                      : [...state, newFilter];

                    setState(newFilterArray);
                  }
                }}
              >
                {checked ? (
                  <Icon icon={faSquareCheck} />
                ) : (
                  <Icon icon={faSquare} />
                )}
                <CheckboxText>{o.name}</CheckboxText>
              </CheckboxWrapper>
            );
            break;
          case "duration":
            const currentDurations =
              currentFilter?.disposition.type === "duration"
                ? currentFilter.disposition.values.includedValues
                : [];

            const currentDurationValue: T.DurationValue = {
              count: o.count,
              unit: o.unit,
            };

            // .includes() was not working here for some reason, but casting the result of .find() to a boolean works instead
            checked = !!currentDurations.find(
              (duration) =>
                duration.count === currentDurationValue.count &&
                duration.unit === currentDurationValue.unit,
            );

            return (
              <CheckboxWrapper
                key={i}
                onClick={() => {
                  if (checked) {
                    // remove the duration value from the current values in state
                    const newIncludedValues: T.DurationValue[] = [
                      ...currentDurations,
                    ].filter((value) => {
                      return value.count !== o.count || value.unit !== o.unit;
                    });

                    // create new filter object using newIncludedValues
                    const newFilter: DynamicPipelineFilter = {
                      id: field.id,
                      disposition: {
                        type: "duration",
                        values: {
                          includedValues: newIncludedValues,
                        },
                      },
                    };

                    // iterate over the dynamicFiltersState and replace the old values of the current filter with the new ones
                    const newFilterArray = [...state].find(
                      (filter) => filter.id === field.id,
                    )
                      ? [...state].map((filter) => {
                          if (filter.id !== field.id) {
                            return filter;
                          } else {
                            return newFilter;
                          }
                        })
                      : [...state, newFilter];

                    setState(newFilterArray);
                  } else {
                    // add the new duration value to the current values in state
                    const newIncludedValues: T.DurationValue[] = [
                      ...currentDurations,
                      currentDurationValue,
                    ];

                    // create new filter object using newIncludedValues
                    const newFilter: DynamicPipelineFilter = {
                      id: field.id,
                      disposition: {
                        type: "duration",
                        values: {
                          includedValues: newIncludedValues,
                        },
                      },
                    };

                    // if the current filter already exists in dynamicFilterState we iterate over the values and replace it like above, otherwise we add the current filter to dynamicFilterState. If the length of all options is equal to the amount of includedValues we can remove the filter from state instead as to not clutter state with filter values that dont actually filter out any values
                    const newFilterArray = [...state].find(
                      (filter) => filter.id === field.id,
                    )
                      ? newIncludedValues.length === options.length
                        ? [...state].filter((f) => f.id !== field.id)
                        : [...state].map((filter) => {
                            if (filter.id !== field.id) {
                              return filter;
                            } else {
                              return newFilter;
                            }
                          })
                      : [...state, newFilter];

                    setState(newFilterArray);
                  }
                }}
              >
                {checked ? (
                  <Icon icon={faSquareCheck} />
                ) : (
                  <Icon icon={faSquare} />
                )}
                <CheckboxText>
                  {o.count} {o.unit}
                </CheckboxText>
              </CheckboxWrapper>
            );
        }
      })}
    </MultiSelect>
  );
});

const EnumFilter = React.memo(function EnumFilter({
  field,
  currentFilter,
  state,
  setState,
}: {
  field: T.BaseFieldDefinition;
  currentFilter: DynamicPipelineFilter;
  state: DynamicPipelineFilter[];
  setState: Setter<DynamicPipelineFilter[]>;
}) {
  const { config } = useSelector(nonNullApplicationInitializationSelector);

  const enumTypeId =
    field.valueType.type === "enum" ? field.valueType.enumTypeId : null;

  const enumType = config.enumTypes.find(
    (enumTypes) => enumTypes.id === enumTypeId,
  )!;

  const enumOptions: EnumMultiselect[] = enumType?.variants.map((variant) => {
    return { type: "enum", ...variant } as EnumMultiselect;
  });

  return (
    <FilterMultiselect
      field={field}
      currentFilter={currentFilter}
      state={state}
      setState={setState}
      options={enumOptions}
    />
  );
});

const DurationFilter = React.memo(function DurationFilter({
  field,
  currentFilter,
  state,
  setState,
  uniqueDurationValues,
}: {
  field: T.BaseFieldDefinition;
  currentFilter: DynamicPipelineFilter;
  state: DynamicPipelineFilter[];
  setState: Setter<DynamicPipelineFilter[]>;
  uniqueDurationValues: DurationValueMap;
}) {
  const allRelevantDurationValues = uniqueDurationValues[field.id]
    ? uniqueDurationValues[field.id].values
    : [];

  const durationValuesArray = Object.entries(allRelevantDurationValues).flatMap(
    (value) => {
      return {
        unit: value[0],
        count: value[1],
      };
    },
  );

  const durationOptions: DurationMultiselect[] = durationValuesArray.flatMap(
    (value) => {
      return value.count.map((count) => {
        return {
          type: "duration",
          unit: value.unit,
          count,
        } as DurationMultiselect;
      });
    },
  );

  return (
    <Wrapper>
      <FilterMultiselect
        field={field}
        currentFilter={currentFilter}
        state={state}
        setState={setState}
        options={durationOptions}
      />
    </Wrapper>
  );
});

const DateFilter = React.memo(function DateFilter({
  field,
  currentFilter,
  state,
  setState,
}: {
  field: T.BaseFieldDefinition;
  currentFilter: DynamicPipelineFilter;
  state: DynamicPipelineFilter[];
  setState: Setter<DynamicPipelineFilter[]>;
}) {
  const [dateValue, setDateValue] = useState<string>(
    currentFilter.disposition.type === "date"
      ? currentFilter.disposition.value
      : "",
  );

  useEffect(() => {
    const updatedFilter: DynamicPipelineFilter = {
      id: field.id,
      disposition: {
        type: "date",
        value: dateValue,
      },
    };
    const newFilters: DynamicPipelineFilter[] = [...state].filter(
      (f) => f.id !== field.id,
    );

    if (!!dateValue) {
      newFilters.push(updatedFilter);
    }

    setState(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.id, dateValue, setState]);

  return (
    <Wrapper>
      <DatePicker
        minDate={new Date("1-1-1900")}
        maxDate={new Date("12-31-9999")}
        disabled={false}
        onChange={(value: Date) => {
          !!value ? setDateValue(value.toString()) : setDateValue("");
        }}
        value={dateValue ? new Date(dateValue) : undefined}
      />
    </Wrapper>
  );
});
