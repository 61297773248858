import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DetailActions from "design/molecules/detail-actions";
import DetailHeader from "design/molecules/detail-header";
import NextButton from "design/atoms/next-button";
import PrevButton from "design/atoms/prev-button";
import {
  usersSelector,
  filteredUsersSelector,
  usersLoadingSelector,
  getUsers,
  deleteUserById,
} from "features/users";
import { useAppDispatch } from "features/store";
import PricingProfilesList from "../_components/pricing-profiles-list";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

type Params = {
  id: string;
};

export default function ViewUserPage() {
  const { id } = useParams<Params>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const users = useSelector(filteredUsersSelector);
  const user = users.find((r) => r.id === id);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const usersState = useSelector(usersSelector);
  const { errors, loading } = usersState;
  const positionInList = user && users.indexOf(user) + 1;
  const loggedInInfo = useSelector(nonNullApplicationInitializationSelector);
  const {
    client: { accessId },
  } = loggedInInfo;
  const usersLoading = useSelector(usersLoadingSelector);

  useEffect(() => {
    if (!users || !usersLoading) dispatch(getUsers());
  }, [dispatch, users, usersLoading]);

  if (loading) {
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert>Loading</MuiAlert>
      </Snackbar>
    );
  } else if (!user) {
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert severity="error">This user was not found</MuiAlert>
      </Snackbar>
    );
  } else {
    return (
      <>
        {errors && (
          <Snackbar
            open={true}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert severity="error">{errors}</MuiAlert>
          </Snackbar>
        )}
        <DetailActions>
          <>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => history.push(`/c/${accessId}/users`)}
            >
              Back to Users ({positionInList}/{users.length})
              <span
                style={{
                  fontWeight: 100,
                  marginLeft: "8px",
                  textTransform: "none",
                }}
              >
                sorted: {usersState.sortField} {usersState.sortDir}
              </span>
              {usersState.searchTerm && (
                <span style={{ fontWeight: 100, textTransform: "none" }}>
                  , filtered: {usersState.searchTerm}
                </span>
              )}
            </Button>

            <PrevButton
              list={users}
              path={`/c/${accessId}/users`}
              id={id}
              label="Previous User"
            />

            <Button
              className="current-user"
              style={{ cursor: "default", background: "transparent" }}
              variant="text"
            >
              {user?.displayName}
            </Button>

            <NextButton
              list={users}
              path={`/c/${accessId}/users`}
              id={id}
              label="Next User"
            />

            <Box flex="1" />

            <Button
              className="edit-user"
              variant="outlined"
              startIcon={<EditIcon />}
              onClick={() =>
                history.push(`/c/${accessId}/users/${user.id}/edit`)
              }
            >
              Edit
            </Button>

            <Button
              disabled={user.id === loggedInInfo.user.id}
              className="delete-user"
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={() => setDeleteConfirmOpen(true)}
            >
              Delete
            </Button>

            <Dialog
              open={deleteConfirmOpen}
              onClose={() => setDeleteConfirmOpen(false)}
            >
              <DialogTitle>Deleting {user?.displayName} User</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  <strong>Warning!</strong> Deletion is permanent. If you've
                  clicked Delete by mistake you can click "Cancel" below.
                  Otherwise click "Continue with Deletion" to proceed.
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setDeleteConfirmOpen(false)}>
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    if (user?.id) {
                      dispatch(deleteUserById(user.id));
                      setDeleteConfirmOpen(false);
                      history.push(`/c/${accessId}/users`);
                    }
                  }}
                >
                  Continue with Deletion
                </Button>
              </DialogActions>
            </Dialog>
          </>
        </DetailActions>

        <DetailHeader style={{ flexDirection: "row" }}>
          <Box p={1} style={{ flex: "0 0 auto", marginRight: "32px" }}>
            <Typography style={{ fontSize: ".75rem", color: "#666" }}>
              Email
            </Typography>
            <Typography align="left">{user.emailAddress}</Typography>
          </Box>

          <Box p={1} style={{ flex: "0 0 auto", marginRight: "32px" }}>
            <Typography style={{ fontSize: ".75rem", color: "#666" }}>
              Role
            </Typography>
            <Typography align="left">
              <Link to={`/c/${accessId}/roles/${user.roleId}`}>
                {user.roleName}
              </Link>
            </Typography>
          </Box>

          {user.lastLogin && (
            <Box p={1} style={{ flex: "0 0 auto", marginRight: "32px" }}>
              <Typography style={{ fontSize: ".75rem", color: "#666" }}>
                Last Login
              </Typography>
              <Typography align="left">{user.lastLogin}</Typography>
            </Box>
          )}
        </DetailHeader>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: "1 1 auto",
            overflowX: "scroll",
          }}
        >
          <PricingProfilesList
            createMode={false}
            editMode={false}
            userId={user.id}
          />
        </div>
      </>
    );
  }
}
