import React, { ReactNode, useEffect, useState } from "react";
import Icon from "design/atoms/icon";
import { PanelTitle } from "design/atoms/typography";
import { Accordion } from "./styles";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

export const createManualOpenEvent = (detail: string) => {
  const event = new CustomEvent("Accordion::ManualOpen", { detail });
  window.dispatchEvent(event);
};

export default React.memo(
  ({
    className,
    children,
    label,
    open,
    icon,
    manualTargets,
    color,
  }: {
    className?: string;
    open?: boolean;
    children: ReactNode;
    label: string;
    icon?: ReactNode;
    manualTargets?: string[];
    color?: string;
  }) => {
    const [forcedOpen, setOpen] = useState(!!open);

    useEffect(() => {
      const execOpen = (e: CustomEventInit) => {
        if (manualTargets?.includes(e.detail as string)) {
          setOpen(true);
        }
      };

      window.addEventListener("Accordion::ManualOpen", execOpen, false);

      return () => {
        window.removeEventListener("Accordion::ManualOpen", execOpen, false);
      };
    }, [manualTargets]);

    return (
      <Accordion
        className={
          forcedOpen
            ? `molecule-accordion ${className || ""} ${color || ""} open`
            : `molecule-accordion ${className || ""} ${color || ""} closed`
        }
      >
        <PanelTitle
          onClick={() => {
            setOpen(!forcedOpen);
          }}
        >
          {icon} {label}
          <Icon
            className="close hide-from-print"
            icon={forcedOpen ? faMinus : faPlus}
          />
        </PanelTitle>

        <div>{children}</div>
      </Accordion>
    );
  },
);
