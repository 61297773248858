import { Set as ISet } from "immutable";
import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

import { Configuration } from "config";
import * as T from "types/engine-types";
import {
  fieldNameToIdentifier,
  getFieldIdentifier,
} from "features/formulas-util";
import * as StringFieldExpressionEditor from "../string-field-expression-editor";
import { LocalOrGlobalFieldId, FieldPicker } from "../field-picker";
import {
  NumberFieldValueState,
  NumberFieldValueEditor,
  newNumberFieldValueState,
  convertNumberFieldValueToState,
  convertStateToNumberFieldValue,
  DateFieldValueState,
  convertDateFieldValueToState,
  newDateFieldValueState,
  convertStateToDateFieldValue,
  DateFieldValueEditor,
} from "design/organisms/field-value-editor";
import {
  Setter,
  UiValidationError,
  useGuardedSetter,
  usePropertySetter,
} from "features/utils";

export type StringFieldExpressionState =
  StringFieldExpressionEditor.StringFieldExpressionState;
export {
  convertStateToStringFieldExpression,
  convertStringFieldExpressionToState,
  StringFieldExpressionEditor,
} from "../string-field-expression-editor";

export type DateFieldExpressionState =
  | DateFieldExpressionStateNumberMode
  | DateFieldExpressionStateFieldMode
  | DateFieldExpressionStateLocalFieldMode;
export type DateFieldExpressionStateNumberMode = {
  mode: "date";
  dateState: DateFieldValueState;
};
export type DateFieldExpressionStateFieldMode = {
  mode: "field";
  fieldId: T.FieldId;
};
export type DateFieldExpressionStateLocalFieldMode = {
  mode: "local-field";
  fieldId: T.LocalFieldId;
};

export type NumberFieldExpressionState =
  | NumberFieldExpressionStateNumberMode
  | NumberFieldExpressionStateFieldMode
  | NumberFieldExpressionStateLocalFieldMode;
export type NumberFieldExpressionStateNumberMode = {
  mode: "number";
  numberState: NumberFieldValueState;
};
export type NumberFieldExpressionStateFieldMode = {
  mode: "field";
  fieldId: T.FieldId;
};
export type NumberFieldExpressionStateLocalFieldMode = {
  mode: "local-field";
  fieldId: T.LocalFieldId;
};

export const DateFieldExpressionEditor = React.memo(
  ({
    className,
    state,
    required,
    showErrors,
    error,
    valueType,
    label,
    margin,
    localFields,
    environment,
    config,
    setState,
  }: {
    className?: string;
    state: DateFieldExpressionState;
    required: boolean;
    showErrors: boolean;
    error?: boolean;
    valueType: T.FieldValueType.Date;
    label?: string;
    margin?: "normal" | "dense";
    localFields: readonly T.RuleDataTableLookupFieldDefinition[];
    environment: ISet<T.FieldId>;
    config: Configuration;
    setState: Setter<DateFieldExpressionState>;
  }) => {
    const setStateDateMode = useGuardedSetter(
      setState,
      (s): s is DateFieldExpressionStateNumberMode => s.mode === "date",
      [],
    );
    const setDateState = usePropertySetter(setStateDateMode, "dateState");

    const reset = useCallback(() => {
      setState(newDateFieldExpressionState(valueType));
    }, [setState, valueType]);

    switch (state.mode) {
      case "date": {
        return (
          <Box display="flex" alignItems="center">
            <DateFieldValueEditor
              className={className}
              state={state.dateState}
              label={label}
              margin={margin}
              required={required}
              showErrors={showErrors}
              error={error}
              setState={setDateState}
            />
            <EditDateFormulaButton
              valueType={valueType}
              state={state}
              localFields={localFields}
              environment={environment}
              config={config}
              setState={setState}
            />
          </Box>
        );
      }
      case "field": {
        return (
          <Box display="flex" alignItems="center">
            <FieldSelectedTextField
              className={className}
              state={state}
              label={label}
              environment={environment}
              config={config}
              reset={reset}
            />
            <EditDateFormulaButton
              valueType={valueType}
              state={state}
              localFields={localFields}
              environment={environment}
              config={config}
              setState={setState}
            />
          </Box>
        );
      }
      case "local-field": {
        return (
          <Box display="flex" alignItems="center">
            <LocalFieldSelectedTextField
              className={className}
              state={state}
              label={label}
              localFields={localFields}
              reset={reset}
            />
            <EditDateFormulaButton
              valueType={valueType}
              state={state}
              localFields={localFields}
              environment={environment}
              config={config}
              setState={setState}
            />
          </Box>
        );
      }
    }
  },
);

export const NumberFieldExpressionEditor = React.memo(
  ({
    className,
    state,
    required,
    showErrors,
    error,
    valueType,
    label,
    margin,
    localFields,
    environment,
    config,
    setState,
  }: {
    className?: string;
    state: NumberFieldExpressionState;
    required: boolean;
    showErrors: boolean;
    error?: boolean;
    valueType: T.FieldValueType.Number;
    label?: string;
    margin?: "normal" | "dense";
    localFields: readonly T.RuleDataTableLookupFieldDefinition[];
    environment: ISet<T.FieldId>;
    config: Configuration;
    setState: Setter<NumberFieldExpressionState>;
  }) => {
    const setStateNumberMode = useGuardedSetter(
      setState,
      (s): s is NumberFieldExpressionStateNumberMode => s.mode === "number",
      [],
    );
    const setNumberState = usePropertySetter(setStateNumberMode, "numberState");

    const reset = useCallback(() => {
      setState(newNumberFieldExpressionState(valueType));
    }, [setState, valueType]);

    switch (state.mode) {
      case "number": {
        return (
          <Box display="flex" alignItems="center">
            <NumberFieldValueEditor
              className={className}
              state={state.numberState}
              label={label}
              margin={margin}
              required={required}
              showErrors={showErrors}
              error={error}
              precision={valueType.precision}
              style={valueType.style}
              setState={setNumberState}
            />
            <EditNumberFormulaButton
              valueType={valueType}
              state={state}
              localFields={localFields}
              environment={environment}
              config={config}
              setState={setState}
            />
          </Box>
        );
      }
      case "field": {
        return (
          <Box display="flex" alignItems="center">
            <FieldSelectedTextField
              className={className}
              state={state}
              label={label}
              environment={environment}
              config={config}
              reset={reset}
            />
            <EditNumberFormulaButton
              valueType={valueType}
              state={state}
              localFields={localFields}
              environment={environment}
              config={config}
              setState={setState}
            />
          </Box>
        );
      }
      case "local-field": {
        return (
          <Box display="flex" alignItems="center">
            <LocalFieldSelectedTextField
              className={className}
              state={state}
              label={label}
              localFields={localFields}
              reset={reset}
            />
            <EditNumberFormulaButton
              valueType={valueType}
              state={state}
              localFields={localFields}
              environment={environment}
              config={config}
              setState={setState}
            />
          </Box>
        );
      }
    }
  },
);

const FieldSelectedTextField = React.memo(
  ({
    className,
    state,
    label,
    environment,
    config,
    reset,
  }: {
    className?: string;
    state: NumberFieldExpressionStateFieldMode;
    label: string | undefined;
    environment: ISet<T.FieldId>;
    config: Configuration;
    reset: () => void;
  }) => {
    const field = environment.has(state.fieldId)
      ? config.allFieldsById.get(state.fieldId)
      : null;

    return (
      <TextField
        className={className}
        label={label}
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={reset}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={field ? `{${getFieldIdentifier(field)}}` : "<Unknown Field>"}
      />
    );
  },
);

const LocalFieldSelectedTextField = React.memo(
  ({
    className,
    state,
    label,
    localFields,
    reset,
  }: {
    className?: string;
    state: NumberFieldExpressionStateLocalFieldMode;
    label: string | undefined;
    localFields: readonly T.RuleDataTableLookupFieldDefinition[];
    reset: () => void;
  }) => {
    const field = localFields.find((f) => f.id === state.fieldId);

    return (
      <TextField
        className={className}
        label={label}
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={reset}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={
          field ? `{${fieldNameToIdentifier(field.name)}}` : "<Unknown Field>"
        }
      />
    );
  },
);

// Currently only allows selecting a field or local field.
// Eventually this could be a full formula editor.
const EditDateFormulaButton = React.memo(
  ({
    valueType,
    state,
    localFields,
    environment,
    config,
    setState,
  }: {
    valueType: T.FieldValueType.Date;
    state: DateFieldExpressionState;
    localFields: readonly T.RuleDataTableLookupFieldDefinition[];
    environment: ISet<T.FieldId>;
    config: Configuration;
    setState: Setter<DateFieldExpressionState>;
  }) => {
    const [isOpen, setIsOpen] = useState(false);

    const showDialog = useCallback(() => {
      setIsOpen(true);
    }, []);

    const initialSelectedFieldId: LocalOrGlobalFieldId | null = useMemo(() => {
      switch (state.mode) {
        case "field":
          return { type: "global", fieldId: state.fieldId };
        case "local-field":
          return { type: "local", fieldId: state.fieldId };
        default:
          return null;
      }
    }, [state]);

    const [selectedFieldId, setSelectedFieldId] =
      useState<LocalOrGlobalFieldId | null>(initialSelectedFieldId);

    const onFieldPickerChange = useCallback(
      (selected) => {
        // This comment was generated when upgrading react-scripts and eslint
        // TODO: fix the lint rule and remove this eslint-disable comment
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setSelectedFieldId(selected);
      },
      [setSelectedFieldId],
    );

    const saveChanges = useCallback(() => {
      if (!selectedFieldId) {
        return;
      }

      if (selectedFieldId.type === "local") {
        setState({ mode: "local-field", fieldId: selectedFieldId.fieldId });
      }

      if (selectedFieldId.type === "global") {
        setState({ mode: "field", fieldId: selectedFieldId.fieldId });
      }

      setIsOpen(false);
    }, [setState, selectedFieldId, setIsOpen]);

    const cancel = useCallback(() => {
      setIsOpen(false);
    }, [setIsOpen]);

    return (
      <>
        <IconButton onClick={showDialog}>
          <LibraryBooksIcon />
        </IconButton>

        <Dialog open={isOpen}>
          <DialogTitle>Select field</DialogTitle>
          <DialogContent>
            <Box width="300px">
              <FieldPicker
                localFields={localFields}
                environment={environment}
                config={config}
                fieldType="date"
                selected={selectedFieldId}
                onChange={onFieldPickerChange}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancel}>Cancel</Button>
            <Button
              color="primary"
              disabled={selectedFieldId === null}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  },
);

// Currently only allows selecting a field or local field.
// Eventually this could be a full formula editor.
const EditNumberFormulaButton = React.memo(
  ({
    valueType,
    state,
    localFields,
    environment,
    config,
    setState,
  }: {
    valueType: T.FieldValueType.Number;
    state: NumberFieldExpressionState;
    localFields: readonly T.RuleDataTableLookupFieldDefinition[];
    environment: ISet<T.FieldId>;
    config: Configuration;
    setState: Setter<NumberFieldExpressionState>;
  }) => {
    const [isOpen, setIsOpen] = useState(false);

    const showDialog = useCallback(() => {
      setIsOpen(true);
    }, []);

    const initialSelectedFieldId: LocalOrGlobalFieldId | null = useMemo(() => {
      switch (state.mode) {
        case "field":
          return { type: "global", fieldId: state.fieldId };
        case "local-field":
          return { type: "local", fieldId: state.fieldId };
        default:
          return null;
      }
    }, [state]);

    const [selectedFieldId, setSelectedFieldId] =
      useState<LocalOrGlobalFieldId | null>(initialSelectedFieldId);

    const onFieldPickerChange = useCallback(
      (selected: React.SetStateAction<LocalOrGlobalFieldId | null>) => {
        setSelectedFieldId(selected);
      },
      [setSelectedFieldId],
    );

    const saveChanges = useCallback(() => {
      if (!selectedFieldId) {
        return;
      }

      if (selectedFieldId.type === "local") {
        setState({ mode: "local-field", fieldId: selectedFieldId.fieldId });
      }

      if (selectedFieldId.type === "global") {
        setState({ mode: "field", fieldId: selectedFieldId.fieldId });
      }

      setIsOpen(false);
    }, [setState, selectedFieldId, setIsOpen]);

    const cancel = useCallback(() => {
      setIsOpen(false);
    }, [setIsOpen]);

    return (
      <>
        <IconButton onClick={showDialog}>
          <LibraryBooksIcon />
        </IconButton>

        <Dialog open={isOpen}>
          <DialogTitle>Select field</DialogTitle>
          <DialogContent>
            <Box width="300px">
              <FieldPicker
                localFields={localFields}
                environment={environment}
                config={config}
                fieldType="number"
                selected={selectedFieldId}
                onChange={onFieldPickerChange}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancel}>Cancel</Button>
            <Button
              color="primary"
              disabled={selectedFieldId === null}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  },
);

export function newDateFieldExpressionState(
  valueType: T.FieldValueType.Date,
): DateFieldExpressionState {
  return {
    mode: "date",
    dateState: newDateFieldValueState(valueType),
  };
}

export function newNumberFieldExpressionState(
  valueType: T.FieldValueType.Number,
): NumberFieldExpressionState {
  return {
    mode: "number",
    numberState: newNumberFieldValueState(valueType),
  };
}

export function newStringFieldExpressionState(
  valueType: T.FieldValueType.String,
): StringFieldExpressionState {
  return {
    text: "",
  };
}

export function convertDateFieldExpressionToState(
  valueType: T.FieldValueType.Date,
  expression: T.Expression,
): DateFieldExpressionState {
  if (expression.kind === "literal" && expression.value.type === "date") {
    return {
      mode: "date",
      dateState: convertDateFieldValueToState(expression.value),
    };
  }

  if (expression.kind === "field-value") {
    return {
      mode: "field",
      fieldId: expression.fieldId,
    };
  }

  if (expression.kind === "local-field-value") {
    return {
      mode: "local-field",
      fieldId: expression.fieldId,
    };
  }

  throw new Error("not yet supported");
}

export function convertStateToDateFieldExpression(
  valueType: T.FieldValueType.Date,
  state: DateFieldExpressionState,
): T.Expression {
  switch (state.mode) {
    case "date": {
      const dateValue = convertStateToDateFieldValue(
        valueType,
        state.dateState,
      );

      if (dateValue === null) {
        throw new UiValidationError("Date field is required");
      }

      return {
        kind: "literal",
        value: dateValue,
      };
    }
    case "field": {
      return {
        kind: "field-value",
        fieldId: state.fieldId,
      };
    }
    case "local-field": {
      return {
        kind: "local-field-value",
        fieldId: state.fieldId,
      };
    }
  }
}

export function convertNumberFieldExpressionToState(
  valueType: T.FieldValueType.Number,
  expression: T.Expression,
): NumberFieldExpressionState {
  if (expression.kind === "literal" && expression.value.type === "number") {
    return {
      mode: "number",
      numberState: convertNumberFieldValueToState(expression.value),
    };
  }

  if (expression.kind === "field-value") {
    return {
      mode: "field",
      fieldId: expression.fieldId,
    };
  }

  if (expression.kind === "local-field-value") {
    return {
      mode: "local-field",
      fieldId: expression.fieldId,
    };
  }

  throw new Error("not yet supported");
}

export function convertStateToNumberFieldExpression(
  valueType: T.FieldValueType.Number,
  state: NumberFieldExpressionState,
): T.Expression {
  switch (state.mode) {
    case "number": {
      const numberValue = convertStateToNumberFieldValue(
        valueType,
        state.numberState,
      );

      if (numberValue === null) {
        throw new UiValidationError("Number field is required");
      }

      return {
        kind: "literal",
        value: numberValue,
      };
    }
    case "field": {
      return {
        kind: "field-value",
        fieldId: state.fieldId,
      };
    }
    case "local-field": {
      return {
        kind: "local-field-value",
        fieldId: state.fieldId,
      };
    }
  }
}
