import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import whitespace from "design/subatomics/whitespace";
import transitions from "design/subatomics/transitions";
import { styles } from "design/atoms/typography";

export const AccordionWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const ProductDetails = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: flex-basis ${transitions.default};
  position: relative;

  .control-buttons {
    position: absolute;
    top: ${whitespace("least")};
    right: ${whitespace("least")};

    button {
      margin: ${whitespace("least")};
    }
  }

  .typography-secondary-title {
    ${styles.panelTitle};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 48px);
    padding-left: ${whitespace("least")};
  }

  ${breakpoints.mobile} {
    &.closed {
      flex-basis: 0%;
    }

    &.open {
      flex-basis: 100%;
    }

    .control-buttons {
      display: none;
    }
  }

  ${breakpoints.desktop} {
    &.closed {
      flex-basis: 0%;
    }

    &.open {
      flex-basis: calc(100vw - 20%);
    }
  }
`;

export const DetailsWrapper = styled.div`
  flex: 0 1 100%;
  overflow-y: auto;

  ${breakpoints.mobile} {
    table {
      // min-width: 100%;
      // overflow-x: auto;
    }
  }

  ${breakpoints.desktop} {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;
