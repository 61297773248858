import React, { useRef, useEffect, useState } from "react";
import { useVirtual } from "react-virtual";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import * as T from "types/engine-types";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import AddIcon from "@material-ui/icons/Add";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { usersLoadingSelector, getUsers } from "features/users";
import {
  pricingProfilesSelector,
  filteredPricingProfilesSelector,
  getPricingProfiles,
  setPricingProfiles,
  setSearchTerm,
  savePositioning,
} from "features/pricing-profiles";
import Draggable, { DraggableEvent, DraggableData } from "react-draggable";
import { usePermissions } from "features/roles";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

export default function PricingProfilesPage() {
  const parentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const pricingProfilesState = useSelector(pricingProfilesSelector);
  const { searchTerm } = pricingProfilesState;
  const pricingProfiles = useSelector(filteredPricingProfilesSelector);
  const hasPermission = usePermissions();
  const hasCreatePerm = hasPermission("pricing-profiles-create");
  const [reorderingEnabled, setReorderingEnabled] = useState<boolean>(false);
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);
  const usersLoading = useSelector(usersLoadingSelector);

  const rowVirtualizer = useVirtual({
    size: pricingProfiles?.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, []),
  });

  useEffect(() => {
    if (!usersLoading) dispatch(getUsers());
  }, [dispatch, usersLoading]);

  useEffect(() => {
    if (pricingProfiles.length === 0) dispatch(getPricingProfiles());
  }, [dispatch, pricingProfiles.length]);

  const dragStop = (
    e: DraggableEvent,
    data: DraggableData,
    pricingProfile: T.PricingProfileHeader,
    i: number,
  ) => {
    const profilesCopy: T.PricingProfileHeader[] = [];
    const profileCopy = { ...pricingProfile };
    const newIndex = i + 1 + Math.floor(data.y / data.node.offsetHeight);

    pricingProfiles.forEach((p, copyIndex) => {
      if (p.id !== profileCopy.id) {
        profilesCopy[newIndex] = profileCopy;

        if (!profilesCopy[copyIndex]) {
          profilesCopy[copyIndex] = p;
        } else {
          profilesCopy[copyIndex + 1] = p;
        }
      }
    });

    if (newIndex === 0) {
      dispatch(
        savePositioning({
          id: pricingProfile.id,
          repositionRequest: {
            type: "first",
          },
        }),
      );
    } else {
      dispatch(
        savePositioning({
          id: pricingProfile.id,
          repositionRequest: {
            type: "after",
            afterId: pricingProfiles[newIndex - 1].id,
          },
        }),
      );
    }

    dispatch(setPricingProfiles(profilesCopy));
  };

  return (
    <Table>
      <TableActions>
        <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
          <SearchInput
            disabled={reorderingEnabled}
            label="profiles"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <ResultsCount>
          <span className="visible">
            {pricingProfiles.length} <VisibilityIcon />
          </span>
          <span className="separator">|</span>
          <span className="total">
            {pricingProfilesState.pricingProfiles.length} Total
          </span>
        </ResultsCount>
        <Button
          data-selector="edit-order"
          disabled={!!searchTerm?.length}
          className="add-pricing-profile"
          variant="outlined"
          onClick={() => setReorderingEnabled(!reorderingEnabled)}
        >
          {reorderingEnabled ? "Finish Editing" : "Edit Order"}
        </Button>
        <Button
          data-selector="add-pricing-profile"
          disabled={!hasCreatePerm}
          className="add-pricing-profile"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => history.push(`/c/${accessId}/pricing-profiles/create`)}
        >
          Add Pricing Profile
        </Button>
      </TableActions>

      {reorderingEnabled ? (
        <TableHeader>
          <TableHeaderCell style={{ flexBasis: "150px", textAlign: "center" }}>
            Re-order{" "}
            <span
              style={{ position: "relative", top: "6px" }}
              title="The first profile in the list will be used as the default, and re-ordering allows customizing the default."
            >
              <InfoIcon />
            </span>
          </TableHeaderCell>
          <TableHeaderCell style={{ flexBasis: "auto" }}>Name</TableHeaderCell>
        </TableHeader>
      ) : (
        <TableHeader>
          <TableHeaderCell style={{ flexBasis: "95%" }}>Name</TableHeaderCell>
        </TableHeader>
      )}

      {pricingProfiles && (
        <TableBodyWrapper ref={parentRef}>
          <TableBody>
            {!reorderingEnabled &&
              rowVirtualizer.virtualItems.map((virtualRow) => (
                <TableRow
                  style={{ position: "static" }}
                  key={pricingProfiles[virtualRow.index].id}
                >
                  <TableCell
                    style={{ flexBasis: "95%" }}
                    onClick={() =>
                      history.push(
                        `/c/${accessId}/pricing-profiles/${
                          pricingProfiles[virtualRow.index].id
                        }`,
                      )
                    }
                  >
                    {pricingProfiles[virtualRow.index].name}
                  </TableCell>
                </TableRow>
              ))}

            {reorderingEnabled &&
              pricingProfiles.map((pricingProfile, i) => (
                <Draggable
                  position={{ x: 0, y: 0 }}
                  onStop={(e, data) => dragStop(e, data, pricingProfile, i)}
                  axis="y"
                  handle={`#draggable-${pricingProfile.id}`}
                >
                  <TableRow
                    style={{ position: "static" }}
                    key={pricingProfile.id}
                  >
                    <TableCell
                      style={{ flexBasis: "100px", textAlign: "center" }}
                      id={`draggable-${pricingProfile.id}`}
                    >
                      <DragIndicatorIcon />
                    </TableCell>
                    <TableCell style={{ flexBasis: "auto" }}>
                      {pricingProfile.name}
                    </TableCell>
                  </TableRow>
                </Draggable>
              ))}
          </TableBody>
        </TableBodyWrapper>
      )}
    </Table>
  );
}
