import React, { useEffect, useState, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "features/utils";
import {
  setSearchTerm,
  pipelineFiltersSelector,
  activePipelineFiltersSelector,
} from "features/pipeline";
import { setPipelineRecordId, setPipelineStage } from "features/loan-pricing";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import { PrimaryTitle } from "design/atoms/typography";
import TextInput from "design/atoms/text-input";
import Button from "design/atoms/button";
import ManageFilters from "../manage-filters";
import PipelineResultsSummary from "../pipeline-results-summary";
import { PipelineActionPanel, HeaderWrapper, FilterWrapper } from "./styles";
import whitespace from "design/subatomics/whitespace";

export default React.memo(({ loading }: { loading: boolean }) => {
  const history = useHistory();
  const pipelineFilters = useSelector(pipelineFiltersSelector);
  const [filterByText, setFilterByText] = useState<string>(
    pipelineFilters.searchTerm,
  );
  const activeFilters = useSelector(activePipelineFiltersSelector);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [preSaveMessage, setPreSaveMessage] = useState<boolean>(false);
  const { client } = useSelector(nonNullApplicationInitializationSelector);
  const storageKey = "loanPASS::hidePreSavePipelineMessage";

  const [debouncedFilterByText] = useDebounce(filterByText, 500);

  useEffect(() => {
    dispatch(setSearchTerm(debouncedFilterByText));
  }, [debouncedFilterByText, dispatch]);

  return (
    <PipelineActionPanel className={"page-pipeline-products-action-panel"}>
      <PrimaryTitle>Pipeline</PrimaryTitle>
      <HeaderWrapper>
        <FilterWrapper className="hide-for-mobile">
          <TextInput
            placeholderText="Filter by Text"
            inputProps={{
              type: "filterInput",
              value: filterByText ?? "",
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                setFilterByText(e.target.value.toLowerCase());
              },
            }}
          />
        </FilterWrapper>

        <PipelineResultsSummary
          preSaveMessage={preSaveMessage}
          setPreSaveMessage={setPreSaveMessage}
          activeFilters={activeFilters}
          open={open}
          setOpen={setOpen}
          loading={loading}
        />
      </HeaderWrapper>

      {open && <ManageFilters />}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: whitespace("less"),
        }}
      >
        {preSaveMessage && (
          <Button
            className="hide-for-desktop"
            grow={true}
            onClick={() => {
              setPreSaveMessage(false);
            }}
          >
            Cancel
          </Button>
        )}

        {preSaveMessage && (
          <Button
            className="hide-for-desktop"
            isPrimary={true}
            grow={true}
            onClick={() => {
              dispatch(setPipelineStage("create"));
              dispatch(setPipelineRecordId(null));
              history.push(`/c/${client.accessId}/v2/loan-pricing`);
            }}
          >
            Continue
          </Button>
        )}

        {!preSaveMessage && (
          <Button
            className="hide-for-desktop"
            isPrimary={true}
            grow={true}
            onClick={() => {
              if (preSaveMessage) {
                dispatch(setPipelineStage("create"));
                dispatch(setPipelineRecordId(null));
                history.push(`/c/${client.accessId}/v2/loan-pricing`);
                localStorage.setItem(storageKey, "true");
              } else {
                if (localStorage.getItem(storageKey) === "true") {
                  dispatch(setPipelineStage("create"));
                  dispatch(setPipelineRecordId(null));
                  history.push(`/c/${client.accessId}/v2/loan-pricing`);
                } else {
                  setPreSaveMessage(true);
                }
              }
            }}
          >
            {preSaveMessage
              ? "Continue and Hide This Message"
              : "Create Pipeline Record"}
          </Button>
        )}
      </div>
    </PipelineActionPanel>
  );
});
