import styled from "styled-components";
import whitespace from "design/subatomics/whitespace";
import radiusOptions from "design/subatomics/corners";
import colors from "design/subatomics/colors";
import { styles } from "design/atoms/typography";
import fontSizes from "design/subatomics/font-sizes";
import layers from "design/subatomics/layers";

export const Wrapper = styled.div`
  ${styles.text};
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
`;

export const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: ${radiusOptions.medium};
  margin-right: ${whitespace("less")};

  &:checked {
    accent-color: ${colors({ color: "green", shade: 6 })};
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${whitespace("less")} 0;
  width: 200px;

  svg {
    color: ${colors({ color: "green", shade: 5 })};

    font-size: ${fontSizes.xxl};
    margin-right: ${whitespace("less")};
  }
`;

export const CheckboxText = styled.span`
  flex-grow: 1;
  margin-right: ${whitespace("less")};
  ${styles.text};
`;

export const MultiSelect = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: min-content;
  padding: ${whitespace()};
`;

export const Label = styled.div`
  ${styles.text};
  position: relative;
  padding: 0 ${whitespace()};
  z-index: ${layers({ layer: "controls" })};
`;
