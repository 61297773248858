export const spacingOptions = {
  least: 3,
  less: 6,
  base: 8,
  more: 12,
  most: 16,
};

function whitespace(spacing?: string, negative?: boolean) {
  let pickedWhitespace;

  if (!spacing) {
    pickedWhitespace = spacingOptions["base"];
  } else {
    console.assert(
      Object.keys(spacingOptions).includes(spacing),
      "Spacing not included in spacingOptions.",
    );

    const spacingMap = new Map(Object.entries(spacingOptions));
    pickedWhitespace = spacingMap.get(spacing);
  }

  if (negative) {
    return `calc(${pickedWhitespace as number}px * -1)`;
  } else {
    return `${pickedWhitespace as number}px`;
  }
}

export default whitespace;
