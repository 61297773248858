import _ from "lodash";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Select,
  InputLabel,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import ExtensionIcon from "@material-ui/icons/Extension";
import NotificationIcon from "@material-ui/icons/NewReleases";
import MenuIcon from "@material-ui/icons/Menu";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import React, { useEffect, useState } from "react";
import Loader from "react-loader";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BuildIcon from "@material-ui/icons/Build";
import FunctionsIcon from "@material-ui/icons/Functions";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SecurityIcon from "@material-ui/icons/Security";
import CompareIcon from "@material-ui/icons/Compare";
import PeopleIcon from "@material-ui/icons/People";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import TableChartIcon from "@material-ui/icons/TableChart";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import { useHistory, useLocation } from "react-router";
import * as Api from "api";
import { usePermissions, hasAdminSelector } from "features/roles";
import loanpassLogoUrl from "images/light-theme-logo-250.png";
import { InvalidRequest } from "api";
import { getErrorMessage } from "features/utils";

import applicationInitializationSlice, {
  getBuildNumber,
  logout,
  nonNullApplicationInitializationSelector,
  overrideMyRole,
  getMyPricingProfiles,
  expandedConfigSelector,
} from "features/application-initialization";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
    notification: {
      color: "orange",
      fontSize: "1.2em",
      position: "relative",
      left: "-0.5em",
      top: "-0.4em",
    },
  }),
);

export default function Header() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    client,
    myRole,
    myPricingProfile,
    myPricingProfiles,
    assumbableRoles,
  } = useSelector(nonNullApplicationInitializationSelector);
  const userHasAdminRole = useSelector(hasAdminSelector);
  const sortedAssumableRoles = _.sortBy(assumbableRoles, [
    (o) => o.name.toLowerCase(),
  ]);
  const superTenant = client.accessId === "super";

  useEffect(() => {
    if (!superTenant && myPricingProfiles.length === 0)
      dispatch(getMyPricingProfiles());
  }, [dispatch, myPricingProfiles.length, superTenant]);

  const [brandLogo, setBrandLogo] = useState<string>();
  useEffect(() => {
    const getLogo = async () => {
      if (client.accessId) {
        try {
          const brand = await Api.getClientBrand(client.accessId);
          if (brand) {
            const logoUrlToSet = brand.logoUrl ?? loanpassLogoUrl;
            setBrandLogo(logoUrlToSet);
          }
        } catch (error) {
          console.error(error);
          newrelic.noticeError(getErrorMessage(error));
        }
      } else {
        // If no client access ID is set in the URL, then just show the LoanPASS logo
        setBrandLogo(loanpassLogoUrl);
      }
    };

    getLogo();
  }, [client.accessId]);

  return (
    <div className={classes.root}>
      <AppBar
        className="application-header"
        position="static"
        style={{
          background: "transparent",
          color: "rgba(0,0,0,.65)",
          borderBottom: "1px solid rgba(0,0,0, .15)",
          boxShadow: "none",
        }}
      >
        <Toolbar
          style={{
            paddingLeft: "16px",
            paddingRight: "6px",
            minHeight: "48px",
          }}
        >
          <div style={{ flexGrow: 1, alignItems: "center", display: "flex" }}>
            {brandLogo && (
              <img
                width="250px"
                className="application-logo"
                src={brandLogo}
                alt={brandLogo ? client.name : "LoanPASS"}
              />
            )}

            {!window.location.hostname.includes("app") &&
              !process.env.REACT_APP_PROXY?.includes("app") && (
                <span
                  style={{
                    marginLeft: "16px",
                    opacity: 0.35,
                    fontWeight: "bold",
                    letterSpacing: "2px",
                  }}
                >
                  {(window.location.hostname.includes("staging") ||
                    process.env.REACT_APP_PROXY?.includes("staging")) &&
                    "STAGING"}

                  {!window.location.hostname.includes("staging") &&
                    !process.env.REACT_APP_PROXY?.includes("staging") &&
                    "DEVELOPMENT"}
                </span>
              )}
          </div>

          {userHasAdminRole && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "32px",
              }}
            >
              <InputLabel style={{ marginRight: "8px" }}>
                Permissioned as:
              </InputLabel>
              <Select
                value={myRole?.id}
                onChange={(e) => {
                  const newRole = sortedAssumableRoles.find(
                    (r) => r.id === e.target.value,
                  );
                  if (newRole) {
                    dispatch(overrideMyRole(newRole.id));
                  }
                }}
                displayEmpty
                data-selector="permission-dropdown"
              >
                {sortedAssumableRoles.map((r) => (
                  <MenuItem
                    key={r.id}
                    value={r.id}
                    data-selector={
                      "permission-" + String(r.name.match(/[^\s\\]/g)?.join(""))
                    }
                  >
                    {r.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}

          {!superTenant && myPricingProfiles.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "16px",
              }}
            >
              <InputLabel style={{ marginRight: "8px" }}>
                Pricing as:
              </InputLabel>
              <Select
                className="pricing-profile-select"
                disabled={myPricingProfiles.length <= 1}
                value={myPricingProfile?.id}
                onChange={(e) => {
                  const newProfile = myPricingProfiles.find(
                    (p) => p.id === e.target.value,
                  );
                  if (newProfile) {
                    dispatch(
                      applicationInitializationSlice.actions.setMyPricingProfile(
                        newProfile,
                      ),
                    );
                  }
                }}
                displayEmpty
                data-selector="pricing-profile-dropdown"
              >
                {myPricingProfiles.map((p) => (
                  <MenuItem
                    data-selector={
                      "pricing-profile-" +
                      String(p.name.match(/[^\s\\]/g)?.join(""))
                    }
                    key={p.id}
                    value={p.id}
                  >
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          <AccountMenu />
        </Toolbar>
      </AppBar>
    </div>
  );
}

function AccountMenu() {
  const classes = useStyles();
  const { user, client, notifications } = useSelector(
    nonNullApplicationInitializationSelector,
  );
  const location = useLocation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const hasPermission = usePermissions();
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState(false);
  const superTenant = client.accessId === "super";

  const { featureFlags } = useSelector(expandedConfigSelector);

  return (
    <>
      <Button
        className="application-nav-menu-button"
        color="inherit"
        style={{ textTransform: "none", width: "42px", minWidth: "42px" }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MenuIcon />
        {hasPermission("release-notes-menu-access") &&
          notifications.length > 0 && (
            <span title="A new version of LoanPASS has been released">
              <NotificationIcon className={classes.notification} />
            </span>
          )}
      </Button>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        getContentAnchorEl={null}
        keepMounted
        onClick={() => setAnchorEl(null)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          disabled
          style={{
            marginTop: "-8px",
            minWidth: "250px",
            textAlign: "right",
            borderBottom: "1px solid rgba(0,0,0,.25)",
          }}
        >
          <ListItemText>
            <div className="application-user-display-name">
              {user.displayName}
            </div>
            <strong className="application-client-name">{client.name}</strong>
            <br />
            <span className="application-build-number">
              Build ID: {getBuildNumber()}
            </span>
          </ListItemText>
        </MenuItem>

        {!superTenant && hasPermission("investors-menu-access") && (
          <MenuItem
            className="nav-investors"
            onClick={() => history.push(`/c/${client.accessId}/investors`)}
            style={{ textAlign: "right" }}
          >
            <ListItemIcon>
              <MonetizationOnIcon />
            </ListItemIcon>
            <ListItemText>Investors</ListItemText>
          </MenuItem>
        )}

        {!superTenant && hasPermission("products-menu-access") && (
          <MenuItem
            className="nav-products"
            onClick={() => history.push(`/c/${client.accessId}/products`)}
            style={{ textAlign: "right" }}
          >
            <ListItemIcon>
              <WorkOutlineIcon />
            </ListItemIcon>
            <ListItemText>Products</ListItemText>
          </MenuItem>
        )}

        {hasPermission("field-enum-library-menu-access") && (
          <MenuItem
            className="nav-fields"
            onClick={() => history.push(`/c/${client.accessId}/fields`)}
            style={{ textAlign: "right" }}
          >
            <ListItemIcon>
              <LibraryBooksIcon />
            </ListItemIcon>
            <ListItemText>Field Library</ListItemText>
          </MenuItem>
        )}

        {!superTenant && hasPermission("calculations-menu-access") && (
          <MenuItem
            className="nav-calculations"
            onClick={() => history.push(`/c/${client.accessId}/calculations`)}
            style={{ textAlign: "right" }}
          >
            <ListItemIcon>
              <FunctionsIcon />
            </ListItemIcon>
            <ListItemText>Calculations</ListItemText>
          </MenuItem>
        )}

        {!superTenant && hasPermission("data-tables-menu-access") && (
          <MenuItem
            className="nav-data-tables"
            onClick={() => history.push(`/c/${client.accessId}/data-tables`)}
            style={{ textAlign: "right" }}
          >
            <ListItemIcon>
              <TableChartIcon />
            </ListItemIcon>
            <ListItemText>Data Tables</ListItemText>
          </MenuItem>
        )}

        {!superTenant && hasPermission("rate-sheets-menu-access") && (
          <MenuItem
            className="nav-rates"
            onClick={() => history.push(`/c/${client.accessId}/rate-sheets`)}
            style={{ textAlign: "right" }}
          >
            <ListItemIcon>
              <ShowChartIcon />
            </ListItemIcon>
            <ListItemText>Rate Sheets</ListItemText>
          </MenuItem>
        )}

        {!superTenant && hasPermission("rules-menu-access") && (
          <MenuItem
            className="nav-rules"
            onClick={() => history.push(`/c/${client.accessId}/rules`)}
            style={{ textAlign: "right" }}
          >
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText>Rules</ListItemText>
          </MenuItem>
        )}

        {!superTenant &&
          featureFlags.pipeline &&
          hasPermission("pipeline-menu-access") && (
            <MenuItem
              className="nav-pipeline-new"
              onClick={() => history.push(`/c/${client.accessId}/v2/pipeline`)}
              style={{ textAlign: "right" }}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText>Pipeline</ListItemText>
            </MenuItem>
          )}

        {!superTenant && hasPermission("price-a-loan-v1-view") && (
          <MenuItem
            className="nav-loans-old"
            onClick={() => history.push(`/c/${client.accessId}/loan-pricing`)}
            style={{ textAlign: "right" }}
          >
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText>Price A Loan Classic</ListItemText>
          </MenuItem>
        )}

        {!superTenant && hasPermission("price-a-loan-v1-view") && (
          <MenuItem
            className="nav-loans-new"
            onClick={() =>
              history.push(
                `/c/${client.accessId}/v2/loan-pricing${location.hash}`,
              )
            }
            style={{ textAlign: "right" }}
          >
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText>Price A Loan v2.0</ListItemText>
          </MenuItem>
        )}

        {hasPermission("roles-menu-access") && (
          <MenuItem
            className="nav-roles"
            onClick={() => history.push(`/c/${client.accessId}/roles`)}
            style={{ textAlign: "right" }}
          >
            <ListItemIcon>
              <SecurityIcon />
            </ListItemIcon>
            <ListItemText>Roles</ListItemText>
          </MenuItem>
        )}

        {!superTenant && hasPermission("pricing-profiles-menu-access") && (
          <MenuItem
            className="nav-pricing-profiles"
            onClick={() =>
              history.push(`/c/${client.accessId}/pricing-profiles`)
            }
            style={{ textAlign: "right" }}
          >
            <ListItemIcon>
              <CompareIcon />
            </ListItemIcon>
            <ListItemText>Pricing Profiles</ListItemText>
          </MenuItem>
        )}

        {hasPermission("vendor-integrations-menu-access") && (
          <MenuItem
            className="nav-vendor-integrations"
            onClick={() =>
              history.push(`/c/${client.accessId}/vendor-integrations`)
            }
            style={{ textAlign: "right" }}
          >
            <ListItemIcon>
              <ExtensionIcon />
            </ListItemIcon>
            <ListItemText>Vendor Integrations</ListItemText>
          </MenuItem>
        )}

        {hasPermission("users-menu-access") && (
          <MenuItem
            className="nav-users"
            onClick={() => history.push(`/c/${client.accessId}/users`)}
            style={{
              textAlign: "right",
              borderBottom: "1px solid rgba(0,0,0,.25)",
            }}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText>Users</ListItemText>
          </MenuItem>
        )}

        {superTenant && (
          <MenuItem
            className="nav-users"
            onClick={() =>
              history.push(`/c/${client.accessId}/__admin/clients`)
            }
            style={{
              textAlign: "right",
            }}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText>Clients</ListItemText>
          </MenuItem>
        )}

        {superTenant && (
          <MenuItem
            className="nav-rates"
            onClick={() =>
              history.push(`/c/${client.accessId}/__admin/object-patterns`)
            }
            style={{
              textAlign: "right",
            }}
          >
            <ListItemIcon>
              <CompareIcon />
            </ListItemIcon>
            <ListItemText>Object Patterns</ListItemText>
          </MenuItem>
        )}

        {superTenant && (
          <MenuItem
            className="nav-rates"
            onClick={() =>
              history.push(`/c/${client.accessId}/__admin/rate-sheet-formats`)
            }
            style={{
              textAlign: "right",
            }}
          >
            <ListItemIcon>
              <ShowChartIcon />
            </ListItemIcon>
            <ListItemText>Rate Sheet Formats</ListItemText>
          </MenuItem>
        )}

        {superTenant && (
          <MenuItem
            className="nav-rates"
            onClick={() =>
              history.push(`/c/${client.accessId}/__admin/subscriptions`)
            }
            style={{
              textAlign: "right",
              borderBottom: "1px solid rgba(0,0,0,.25)",
            }}
          >
            <ListItemIcon>
              <SubscriptionsIcon />
            </ListItemIcon>
            <ListItemText>Subscriptions</ListItemText>
          </MenuItem>
        )}

        {hasPermission("change-password") && (
          <MenuItem
            className="nav-change-password"
            style={{ textAlign: "right" }}
            onClick={() => {
              setAnchorEl(null);
              setChangePasswordDialogOpen(true);
            }}
          >
            <ListItemIcon>
              <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText>Change Password</ListItemText>
          </MenuItem>
        )}

        {hasPermission("release-notes-menu-access") && (
          <MenuItem
            className="nav-release"
            onClick={() =>
              window.open("https://blog.loanpass.io/tag/releases/")
            }
            style={{
              textAlign: "right",
            }}
          >
            <ListItemIcon>
              <RssFeedIcon />{" "}
              {notifications.length > 0 && (
                <span title="A new version of LoanPASS has been released">
                  <NotificationIcon className={classes.notification} />
                </span>
              )}
            </ListItemIcon>
            <ListItemText>Release Notes</ListItemText>
          </MenuItem>
        )}

        {hasPermission("support-menu-access") && (
          <MenuItem
            className="nav-support"
            onClick={() =>
              window.open(
                "https://loanpass.atlassian.net/servicedesk/customer/portal/2",
              )
            }
            style={{
              textAlign: "right",
            }}
          >
            <ListItemIcon>
              <ContactSupportIcon />{" "}
              <span
                style={{ fontSize: "10px", position: "relative", top: "4px" }}
              >
                Extra login required
              </span>
            </ListItemIcon>
            <ListItemText>Support</ListItemText>
          </MenuItem>
        )}

        <MenuItem
          className="nav-sign-out"
          style={{ textAlign: "right", marginBottom: "-8px" }}
          onClick={() => logout(client.accessId)}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Sign Out</ListItemText>
        </MenuItem>
      </Menu>

      {changePasswordDialogOpen && (
        <ChangePasswordDialog
          onClose={() => setChangePasswordDialogOpen(false)}
        />
      )}
    </>
  );
}

interface ChangePasswordDialogProps {
  onClose: () => void;
}

function ChangePasswordDialog({ onClose }: ChangePasswordDialogProps) {
  const [submitAttempted, setSubmitAttempted] = useState(false);

  let fieldError = false;

  const [currentPassword, setCurrentPassword] = useState("");
  const currentPasswordError = !currentPassword.trim();
  fieldError = fieldError || currentPasswordError;

  const [newPassword, setNewPassword] = useState("");
  const newPasswordError = !newPassword.trim();
  fieldError = fieldError || newPasswordError;

  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const confirmNewPasswordError =
    !confirmNewPassword.trim() || confirmNewPassword !== newPassword;
  fieldError = fieldError || confirmNewPasswordError;

  const [loading, setLoading] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState<string | null>(
    null,
  );

  async function submit() {
    setSubmitAttempted(true);

    if (fieldError) {
      return;
    }

    try {
      setLoading(true);
      await Api.changeCurrentUserPassword({
        currentPassword,
        newPassword,
      });
      setLoading(false);
      onClose();
    } catch (e) {
      setLoading(false);
      newrelic.noticeError(getErrorMessage(e));
      if (e instanceof InvalidRequest) {
        setChangePasswordError(e.message);
      }
    }
  }

  // TODO better styling on loader spinner
  return (
    <Loader loaded={!loading}>
      <Dialog open={true}>
        <DialogTitle>Change password</DialogTitle>
        <DialogContent>
          {changePasswordError && (
            <Box fontWeight="bold" color="red" mb={1}>
              {changePasswordError}
            </Box>
          )}

          <DialogContentText>
            Enter your current password and the new password you would like to
            use:
          </DialogContentText>

          <TextField
            required
            autoFocus
            type="password"
            label="Current password"
            error={currentPasswordError && submitAttempted}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            margin="dense"
            fullWidth
            variant="outlined"
            data-selector="password-change-current"
          />
          <TextField
            required
            type="password"
            label="New password"
            error={newPasswordError && submitAttempted}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            margin="dense"
            fullWidth
            variant="outlined"
            data-selector="password-change-new"
          />
          <TextField
            required
            type="password"
            label="Confirm new password"
            error={confirmNewPasswordError && submitAttempted}
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            margin="dense"
            fullWidth
            variant="outlined"
            data-selector="password-change-confirm"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button
            color="primary"
            data-selector="password-change-submit-button"
            onClick={submit}
          >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </Loader>
  );
}
