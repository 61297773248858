import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import whitespace from "design/subatomics/whitespace";
import color from "design/subatomics/colors";
import { styles } from "design/atoms/typography";
import transitions from "design/subatomics/transitions";
import radiusOptions from "design/subatomics/corners";
import fontSizes from "design/subatomics/font-sizes";

export const ProductResult = styled.div`
  position: relative;
  transition: background-color ${transitions.default},
    border-color ${transitions.default}, color ${transitions.default};

  * {
    transition: background-color ${transitions.default},
      color ${transitions.default};
  }

  .page-loan-pricing-component-product-result-result-actions {
    opacity: 0;
  }

  .next-required {
    padding-bottom: ${whitespace("least")};
  }

  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
      svg {
        height: 16px;
        width: 16px;
      }
      transition: color ${transitions.default}, opacity ${transitions.default};
    }

    .fa-eye {
      color: ${color({ color: "gray", shade: 5 })};
    }
  }

  &:hover {
    .page-loan-pricing-component-product-result-result-actions {
      opacity: 1;
    }
  }

  &.green {
    border-bottom: 1px solid ${color({ color: "lightBorder" })};

    h2 {
      svg:first-of-type {
        color: ${color({ color: "green", shade: 5 })};
      }
    }

    &:hover {
      background-color: ${color({ color: "gray", shade: 10 })};
    }

    &.selected {
      background-color: ${color({ color: "gray", shade: 9 })};

      h2 {
        svg:first-of-type {
          path {
            color: ${color({ color: "green", shade: 5 })} !important;
          }
        }
        svg:last-of-type {
          path {
            color: ${color({ color: "gray", shade: 5 })} !important;
          }
        }
      }
    }
  }

  &.blue {
    border-bottom: 1px solid ${color({ color: "lightBorder" })};

    h2 {
      svg:first-of-type {
        color: ${color({ color: "blue", shade: 5 })};
      }
    }

    &:hover {
      background-color: ${color({ color: "gray", shade: 10 })};
    }

    &.selected {
      background-color: ${color({ color: "gray", shade: 9 })};

      h2 {
        svg:first-of-type {
          path {
            color: ${color({ color: "blue", shade: 5 })} !important;
          }
        }
        svg:last-of-type {
          path {
            color: ${color({ color: "gray", shade: 5 })} !important;
          }
        }
      }
    }
  }

  &.gray {
    background-color: ${color({ color: "gray", shade: 9 })};
    border-bottom: 1px solid ${color({ color: "lightBorder" })};

    * {
      color: ${color({ color: "gray", shade: 6 })};
    }

    h2 {
      svg:first-of-type,
      path {
        color: ${color({ color: "gray", shade: 7 })};
      }
    }

    &:hover {
      background-color: ${color({ color: "white" })} !important;
    }

    &.selected {
      background-color: ${color({ color: "white" })} !important;
      h2 {
        svg:first-of-type {
          path {
            color: ${color({ color: "gray", shade: 5 })} !important;
          }
        }
        svg:last-of-type {
          path {
            color: ${color({ color: "gray", shade: 5 })} !important;
          }
        }
      }
    }
  }

  &.red {
    background-color: ${color({ color: "red", shade: 10 })};
    border-bottom: 1px solid ${color({ color: "lightBorder" })};

    h2 {
      svg:first-of-type {
        color: ${color({ color: "red", shade: 5 })};
      }
    }

    &:hover {
      background-color: ${color({ color: "white" })} !important;
    }

    &.selected {
      background-color: ${color({ color: "white" })} !important;
      h2 {
        svg:first-of-type {
          path {
            color: ${color({ color: "red", shade: 5 })} !important;
          }
        }
        svg:last-of-type {
          path {
            color: ${color({ color: "gray", shade: 5 })} !important;
          }
        }
      }
    }
  }

  &.orange {
    border-bottom: 1px solid ${color({ color: "lightBorder" })};

    h2 {
      svg:first-of-type {
        color: ${color({ color: "orange", shade: 5 })};
      }
    }
    &:hover {
      background-color: ${color({ color: "gray", shade: 10 })};
    }

    &.selected {
      background-color: ${color({ color: "gray", shade: 9 })};

      h2 {
        svg:first-of-type {
          path {
            color: ${color({ color: "orange", shade: 5 })} !important;
          }
        }
        svg:last-of-type {
          path {
            color: ${color({ color: "gray", shade: 5 })} !important;
          }
        }
      }
    }
  }

  &.yellow {
    border-bottom: 1px solid ${color({ color: "lightBorder" })};

    h2 {
      svg:first-of-type {
        background-color: ${color({ color: "gray", shade: 4 })};
        border-radius: ${radiusOptions.circle};
        color: ${color({ color: "yellow", shade: 5 })};
      }
    }

    &:hover {
      background-color: ${color({ color: "gray", shade: 10 })};
    }

    &.selected {
      background-color: ${color({ color: "gray", shade: 9 })};

      h2 {
        svg:first-of-type {
          path {
            color: ${color({ color: "yellow", shade: 5 })} !important;
          }
        }
        svg:last-of-type {
          path {
            color: ${color({ color: "gray", shade: 5 })} !important;
          }
        }
      }
    }
  }

  ${breakpoints.mobile} {
    padding: ${whitespace("least")} ${whitespace("least")} ${whitespace("less")};
    margin: 0 -2px;

    h2 {
      font-size: ${fontSizes.l};
    }

    .select-product,
    .pricing-popover {
      opacity: 1 !important;
    }

    &.closed {
      flex-basis: 0%;
    }

    &.open {
      flex-basis: 100vw;
    }

    .key-value-group {
      margin-left: calc(${whitespace("most")} + ${whitespace("least")});

      .key-value {
        margin: ${whitespace("least")} 0 ${whitespace("least", true)};
      }
    }
  }

  ${breakpoints.desktop} {
    padding: ${whitespace("least")} ${whitespace("least")} 0;
    margin: 0 ${whitespace("least", true)};

    &.closed {
      flex-basis: 0%;
    }

    &.open {
      flex-basis: calc(100vw - 20%);
    }

    .key-value-group {
      margin-left: calc(${whitespace("most")} + ${whitespace()});

      .key-value {
        width: 204px;
      }
    }
  }
`;

export const InlineGroup = styled.div.attrs(() => ({
  className: "page-loan-pricing-component-product-result-inline-group",
}))`
  ${styles.secondaryTitleSub};

  ${breakpoints.mobile} {
    display: block;

    > span {
      display: block;
      margin-left: calc(${whitespace("most")} + ${whitespace("least")});
    }
  }

  ${breakpoints.desktop} {
    display: block;

    > span {
      flex: 0 1 100%;
      display: block;
      margin-left: calc(${whitespace("most")} + ${whitespace()});

      &.investor-name {
        font-size: 15px;
        line-height: 15px;
        margin-bottom: ${whitespace()};
      }
    }
  }
`;

export const ResultActions = styled.div.attrs(() => ({
  className: "page-loan-pricing-component-product-result-result-actions",
}))`
  display: block;
  position: absolute;
  top: ${whitespace("least")};
  right: ${whitespace("least")};
  display: flex;
  flex-direction: row-reverse;
  gap: ${whitespace("less")};
`;
