import React, { useState, useEffect, ChangeEvent } from "react";
import * as Api from "api";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Box,
  Button,
  TextField as MaterialTextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import DetailActions from "design/molecules/detail-actions";
import DetailHeader from "design/molecules/detail-header";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import * as T from "types/engine-types";
import {
  usersSelector,
  filteredUsersSelector,
  usersLoadingSelector,
  getUsers,
  updateUser,
  useRoleValidation,
  useEmailAddressValidation,
} from "features/users";
import {
  pricingProfilesSelector,
  getPricingProfiles,
} from "features/pricing-profiles";
import { rolesSelector, getRoles, usePermissions } from "features/roles";
import { useAppDispatch } from "features/store";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import { localAccessId } from "features/access-id";

type Params = {
  id: string;
};

export default function ViewUserPage() {
  const { id } = useParams<Params>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const users = useSelector(filteredUsersSelector);
  const user = users.find((r) => r.id === id);
  const usersState = useSelector(usersSelector);
  const rolesState = useSelector(rolesSelector);
  const { roles } = rolesState;
  const [cancelConfirmOpen, setCancelConfirmOpen] = useState<boolean>(false);
  const { errors, loading, detailedErrors } = usersState;
  const loggedInInfo = useSelector(nonNullApplicationInitializationSelector);
  const [displayName, setDisplayName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const getEmailAddressErrors = useEmailAddressValidation();
  const emailAddressErrors = getEmailAddressErrors(
    emailAddress,
    user?.emailAddress,
  );
  const [role, setRole] = useState<unknown>("");
  const getRoleErrors = useRoleValidation();
  const roleErrors = getRoleErrors(role);
  const hasPermission = usePermissions();
  const hasUsersEditEmailPerm = hasPermission("users-edit-email");
  const pricingProfilesState = useSelector(pricingProfilesSelector);
  const { pricingProfiles } = pricingProfilesState;
  const [myPricingProfiles, setMyPricingProfiles] = useState<
    T.PricingProfileHeader[]
  >([]);
  const [profilesFetched, setProfilesFetched] = useState<
    T.UserId | undefined
  >();
  const [newPricingProfiles, setNewPricingProfiles] = useState<
    T.PricingProfileId[]
  >(myPricingProfiles.map((p) => p.id));
  const accessId = localAccessId();
  const usersLoading = useSelector(usersLoadingSelector);

  useEffect(() => {
    if (user && (!profilesFetched || profilesFetched !== user.id)) {
      Api.getUserPricingProfiles(user.id).then(
        (fetchedProfiles: T.PricingProfileHeader[]) => {
          setMyPricingProfiles(fetchedProfiles);
          setNewPricingProfiles(fetchedProfiles.map((p) => p.id));
          setProfilesFetched(user.id);
        },
      );
    }
  }, [profilesFetched, myPricingProfiles, myPricingProfiles.length, user]);

  useEffect(() => {
    if (user) {
      setDisplayName(user.displayName);
      setEmailAddress(user.emailAddress);
      setRole(user.roleId);
    }
  }, [user]);

  useEffect(() => {
    if (!roles || roles.length === 0) dispatch(getRoles());
  }, [dispatch, roles, roles.length]);

  useEffect(() => {
    if (!users || !usersLoading) dispatch(getUsers());
  }, [dispatch, users, usersLoading]);

  useEffect(() => {
    if (!pricingProfiles || pricingProfiles.length === 0)
      dispatch(getPricingProfiles());
  }, [dispatch, pricingProfiles, pricingProfiles.length]);

  if (loading) {
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert>Loading</MuiAlert>
      </Snackbar>
    );
  } else if (!user) {
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert severity="error">This user was not found</MuiAlert>
      </Snackbar>
    );
  } else {
    return (
      <>
        {errors && (
          <Snackbar
            open={true}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert severity="error">{errors}</MuiAlert>
          </Snackbar>
        )}
        <DetailActions>
          <>
            <Button
              className="cancel-user"
              variant="outlined"
              startIcon={<CloseIcon />}
              onClick={() => {
                setCancelConfirmOpen(true);
              }}
            >
              Cancel
            </Button>
            <Box flex="1" />

            <Dialog
              open={cancelConfirmOpen}
              onClose={() => setCancelConfirmOpen(false)}
            >
              <DialogTitle>Cancel Editing {user?.displayName} User</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  <strong>Warning!</strong> All unsaved progress will be lost.
                  Click "Cancel" to finish editing the user, or "Go to User
                  View" to abandon edits.
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setCancelConfirmOpen(false)}>
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    setCancelConfirmOpen(false);
                    history.push(`/c/${accessId}/users/${user.id}`);
                  }}
                >
                  Go to {user?.displayName}
                </Button>
              </DialogActions>
            </Dialog>

            <Button
              className="save-user"
              disabled={
                !newPricingProfiles?.length ||
                !role ||
                !!roleErrors ||
                !!detailedErrors?.roleId?.length ||
                !!emailAddressErrors ||
                !!detailedErrors?.emailAddress?.length
              }
              variant="outlined"
              startIcon={<DoneIcon />}
              onClick={async () => {
                dispatch(
                  updateUser({
                    id: user && user.id,
                    changeset: {
                      displayName,
                      emailAddress,
                      roleId: role as T.RoleId,
                    },
                    pricingProfiles: newPricingProfiles,
                  }),
                )
                  .then(unwrapResult)
                  .then((status: "error" | "success") => {
                    if (status === "success") {
                      history.push(`/c/${accessId}/users/${user.id}`);
                    }
                  });
              }}
            >
              Save
            </Button>
          </>
        </DetailActions>

        <DetailHeader style={{ flexDirection: "row" }}>
          <Typography variant="h4">Editing {user.displayName}</Typography>
        </DetailHeader>

        <div
          style={{
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControl style={{ width: 500 }}>
            <MaterialTextField
              autoFocus={true}
              style={{ margin: "16px 0" }}
              label="Display Name"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
          </FormControl>

          <FormControl style={{ width: 500 }}>
            <MaterialTextField
              style={{ margin: "16px 0" }}
              label="Email Address"
              disabled={!hasUsersEditEmailPerm}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              helperText={
                !!emailAddressErrors ? <>{emailAddressErrors}</> : <></>
              }
              error={
                !!emailAddressErrors || !!detailedErrors?.emailAddress?.length
              }
            />
            {detailedErrors?.emailAddress.map((e) => (
              <FormHelperText
                error={true}
                style={{ margin: "-16px 0px 14px 16px" }}
              >
                {e.message}
              </FormHelperText>
            ))}
          </FormControl>

          <FormControl style={{ width: 500 }}>
            <InputLabel>Select a Role</InputLabel>

            <Select
              style={{ margin: "16px 0" }}
              disabled={loggedInInfo.user.id === user.id}
              label="Role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              error={!!roleErrors || !!detailedErrors?.roleId?.length}
            >
              {roles &&
                roles.map((r: T.DecoratedRoleHeader) => (
                  <MenuItem key={r.id} value={r.id}>
                    {r.name}
                  </MenuItem>
                ))}
            </Select>
            {detailedErrors?.roleId.map((e) => (
              <FormHelperText
                error={true}
                style={{ margin: "2px 0px 14px 16px" }}
              >
                {e.message}
              </FormHelperText>
            ))}
          </FormControl>

          <FormControl style={{ width: 500 }}>
            <InputLabel>Select Pricing Profiles</InputLabel>
            <Select
              style={{ margin: "16px 0" }}
              label="Pricing Profiles"
              multiple
              value={newPricingProfiles}
              // material ui has imprecise types for multiple selects
              // HTMLInputElement has a type of string for event.target.value despite material returning the value wrapped in an arrau
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(event: ChangeEvent<any>) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                setNewPricingProfiles(event.target.value);
              }}
            >
              {pricingProfiles.map((profile: T.PricingProfileHeader) => (
                <MenuItem key={profile.id} value={profile.id}>
                  {profile.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </>
    );
  }
}
