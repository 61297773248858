import React, { useRef, useEffect } from "react";
import { useVirtual } from "react-virtual";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import AddIcon from "@material-ui/icons/Add";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  usersSelector,
  filteredUsersSelector,
  usersLoadingSelector,
  getUsers,
  setSearchTerm,
  setSort,
} from "features/users";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

export default function UsersPage() {
  const parentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { searchTerm, sortField } = useSelector(usersSelector);
  const usersState = useSelector(usersSelector);
  const users = useSelector(filteredUsersSelector);
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);
  const usersLoading = useSelector(usersLoadingSelector);

  const rowVirtualizer = useVirtual({
    size: users?.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, []),
  });

  useEffect(() => {
    if (!usersLoading) dispatch(getUsers());
  }, [dispatch, usersLoading]);
  return (
    <Table>
      <TableActions>
        <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
          <SearchInput
            label="users"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <ResultsCount>
          <span className="visible">
            {users.length} <VisibilityIcon />
          </span>
          <span className="separator">|</span>
          <span className="total">{usersState.users.length} Total</span>
        </ResultsCount>
        <Button
          className="add-user"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => history.push(`/c/${accessId}/users/create`)}
        >
          Add User
        </Button>
      </TableActions>

      <TableHeader>
        <TableHeaderCell
          style={{ flexBasis: "25%" }}
          onClick={() => dispatch(setSort("displayName"))}
        >
          Name{" "}
          {sortField === "displayName" && (
            <SwapVertIcon
              style={{
                color: sortField === "displayName" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{ flexBasis: "25%" }}
          onClick={() => dispatch(setSort("emailAddress"))}
        >
          Email{" "}
          {sortField === "emailAddress" && (
            <SwapVertIcon
              style={{
                color: sortField === "emailAddress" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{ flexBasis: "25%" }}
          onClick={() => dispatch(setSort("lastLogin"))}
        >
          Last Login{" "}
          {sortField === "lastLogin" && (
            <SwapVertIcon
              style={{
                color: sortField === "lastLogin" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{ flexBasis: "25%" }}
          onClick={() => dispatch(setSort("roleName"))}
        >
          Role{" "}
          {sortField === "roleName" && (
            <SwapVertIcon
              style={{
                color: sortField === "roleName" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>
      </TableHeader>

      {users && (
        <TableBodyWrapper ref={parentRef}>
          <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
            {rowVirtualizer.virtualItems.map((virtualRow) => (
              <TableRow
                key={virtualRow.index}
                onClick={() =>
                  history.push(
                    `/c/${accessId}/users/${users[virtualRow.index].id}`,
                  )
                }
                style={{
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <TableCell style={{ flexBasis: "25%" }}>
                  {users[virtualRow.index].displayName}
                </TableCell>

                <TableCell style={{ flexBasis: "25%" }}>
                  {users[virtualRow.index].emailAddress}
                </TableCell>

                <TableCell style={{ flexBasis: "25%" }}>
                  {users[virtualRow.index].lastLogin}
                </TableCell>

                <TableCell style={{ flexBasis: "25%" }}>
                  {users[virtualRow.index].roleName}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableBodyWrapper>
      )}
    </Table>
  );
}
