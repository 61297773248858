import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import whitespace from "design/subatomics/whitespace";

export const Fields = styled.div.attrs(() => ({
  className: "workflow-fields",
}))`
  overflow-y: scroll;

  ${breakpoints.mobile} {
  }

  ${breakpoints.desktop} {
  }
`;

export const NoGroupWrapper = styled.div`
   {
    position: relative;
    padding: ${whitespace("more")} 0 0;
  }

  ${breakpoints.mobile} {
  }

  ${breakpoints.desktop} {
  }
`;
