import React, { useState, useEffect, ChangeEvent } from "react";
import { Setter, useDebounce } from "features/utils";
import TextInput from "../text-input";
import { Wrapper } from "./styles";

function RangeInput({
  minSetter,
  maxSetter,
  minValue,
  maxValue,
  adornment,
  adornmentText,
}: {
  minSetter: Setter<string>;
  maxSetter: Setter<string>;
  minValue: string;
  maxValue: string;
  adornment?: "prefix" | "suffix" | null;
  adornmentText?: string;
}) {
  const [inputMin, setInputMin] = useState<string>(minValue ?? "");
  const [inputMax, setInputMax] = useState<string>(maxValue ?? "");

  const [debouncedMin] = useDebounce(inputMin, 500);
  const [debouncedMax] = useDebounce(inputMax, 500);

  useEffect(() => minSetter(debouncedMin), [debouncedMin, minSetter]);
  useEffect(() => maxSetter(debouncedMax), [debouncedMax, maxSetter]);

  return (
    <Wrapper>
      <TextInput
        adornment={adornment}
        adornmentText={adornmentText}
        placeholderText="Minimum"
        inputProps={{
          type: "text",
          value: inputMin ?? "",
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setInputMin(e.target.value);
          },
        }}
      />
      <TextInput
        adornment={adornment}
        adornmentText={adornmentText}
        placeholderText="Maximum"
        inputProps={{
          type: "text",
          value: inputMax ?? "",
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setInputMax(e.target.value);
          },
        }}
      />
    </Wrapper>
  );
}

export default RangeInput;
