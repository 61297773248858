import styled from "styled-components";
import transitions from "design/subatomics/transitions";
import opacities from "design/subatomics/opacities";

export const PipelineResults = styled.section`
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  transition: flex-basis ${transitions.default};
  overflow: hidden;

  &.loading {
    opacity: ${opacities.translucent};
  }
`;

export const PageMessage = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AccordionWrapper = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;

  .molecule-accordion {
    .page-pipeline-component-product-result {
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;
