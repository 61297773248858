import React, { useEffect } from "react";
import { Set as ISet } from "immutable";
import ClearIcon from "@material-ui/icons/Clear";
import WarningIcon from "@material-ui/icons/Warning";
import CheckIcon from "@material-ui/icons/Check";
import _ from "lodash";
import { Configuration, PriceScenarioTableFieldInfo } from "config";
import * as T from "types/engine-types";
import * as Fields from "features/fields";
import { LazyMemoCache } from "features/utils";
import MiddotIcon from "pages/loans-v2/loan-pricing/_components/middot-icon";
import { findScenarioFieldValue } from "features/pricing-summaries";
import { ObjectDetails } from "features/objects";
import { useTableStyles, useTableRowStyles } from "./styles";
import { useVisibleFields } from "features/roles";
import { useParams } from "react-router-dom";
import color from "design/subatomics/colors";

type Params = {
  productId: T.ProductId;
  pricingScenarioRate: string;
};

const StatusCell = React.memo(
  ({ scenario }: { scenario: T.PriceScenarioResult }): JSX.Element => {
    const C = useTableRowStyles();
    const params = useParams<Params>();

    switch (scenario.status) {
      case "missing-configuration":
        throw new Error(
          "we shouldn't be displaying a table cell if missing configuration",
        );
      case "approved":
        return (
          <td
            style={
              params.pricingScenarioRate === scenario.adjustedRate
                ? {
                    background: color({ color: "green", shade: 5 }),
                    color: color({ color: "gray", shade: 10 }),
                  }
                : {}
            }
            className={C.cell + " " + C.approved}
          >
            <div>
              <CheckIcon />
            </div>
          </td>
        );
      case "review-required":
        return (
          <td className={C.cell}>
            <div>RR</div>
          </td>
        );
      case "rejected":
        return (
          <td className={C.cell + " " + C.rejected}>
            <div>
              <ClearIcon />
            </div>
          </td>
        );
      case "error": {
        const isMissingData = scenario.errors.every(
          (err) => err.type === "blank-field",
        );

        if (isMissingData) {
          return (
            <td className={C.cell + " " + C.missingData}>
              <div>
                <MiddotIcon />
              </div>
            </td>
          );
        }

        return (
          <td className={C.cell + " " + C.error}>
            <div>
              <WarningIcon />
            </div>
          </td>
        );
      }
    }
  },
);

const ScenarioRow = React.memo(
  ({
    config,
    objectDetails,
    fieldInfo,
    scenario,
    openPriceScenario,
  }: {
    config: Configuration;
    objectDetails: ObjectDetails;
    fieldInfo: PriceScenarioTableFieldInfo.RateWithColumns;
    scenario: T.PriceScenarioResult;
    openPriceScenario?: () => void;
  }) => {
    const C = useTableRowStyles();
    const hasVisibleField = useVisibleFields();
    const params = useParams<Params>();

    const columnFieldDefs = [
      fieldInfo.adjustedRateField,
      ...fieldInfo.columnFields,
    ];

    const columnCells = columnFieldDefs.map((fieldDef) => {
      const value = findScenarioFieldValue(fieldDef.id, scenario);

      if (!value) {
        const horizontalBarChar = String.fromCharCode(8213);

        return (
          <td
            style={{
              background:
                params.pricingScenarioRate === scenario.id
                  ? "yellow"
                  : "inherit",
            }}
            align="center"
            className={`${C.cell} ${C.missingData}`}
          >
            {horizontalBarChar}
          </td>
        );
      }

      if (hasVisibleField(fieldDef.id)) {
        return (
          <td
            key={fieldDef.id}
            style={
              scenario.status === "approved" &&
              params.pricingScenarioRate === scenario.adjustedRate
                ? {
                    background: color({ color: "green", shade: 5 }),
                    color: color({ color: "gray", shade: 10 }),
                  }
                : {}
            }
            className={C.cell}
          >
            <div>
              {Fields.fieldValueToString(
                config,
                objectDetails,
                fieldDef.valueType,
                value,
              )}
            </div>
          </td>
        );
      } else {
        return <></>;
      }
    });

    const statusCell = <StatusCell scenario={scenario} />;

    useEffect(() => {
      if (
        openPriceScenario &&
        parseFloat(params.pricingScenarioRate) ===
          parseFloat(scenario?.adjustedRate || "")
      ) {
        openPriceScenario();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.pricingScenarioRate, scenario]);

    return (
      <tr
        className={C.row}
        key={scenario.id}
        onClick={openPriceScenario}
        data-selector="product-detail-cell"
      >
        {columnCells}
        {statusCell}
      </tr>
    );
  },
);

export const RateWithColumnsPriceScenarioTable = React.memo(
  ({
    fieldInfo,
    scenarios,
    openPriceScenarioById,
    config,
    objectDetails,
  }: {
    fieldInfo: PriceScenarioTableFieldInfo.RateWithColumns;
    scenarios: ISet<T.PriceScenarioResult>;
    openPriceScenarioById: LazyMemoCache<T.PriceScenarioResultId, () => void>;
    config: Configuration;
    objectDetails: ObjectDetails;
  }) => {
    const C = useTableStyles();
    const hasVisibleField = useVisibleFields();

    const sortedScenarios = _.sortBy(scenarios.toArray(), (scenario) => {
      const value = findScenarioFieldValue(
        fieldInfo.adjustedRateField.id,
        scenario,
      );

      return (
        value &&
        Fields.fieldValueSortKey(
          config,
          fieldInfo.adjustedRateField.valueType,
          value,
        )
      );
    });

    const fieldNameHeaders = [
      fieldInfo.adjustedRateField,
      ...fieldInfo.columnFields,
    ].map(
      (fieldDef) =>
        hasVisibleField(fieldDef.id) && (
          <th key={fieldDef.id} align="center" className={C.columnLabel}>
            {fieldDef.name}
          </th>
        ),
    );

    const statusHeader = (
      <th key="status" align="center" className={C.columnLabel}>
        Status
      </th>
    );

    const columnHeaders = [...fieldNameHeaders, statusHeader];
    const rows = sortedScenarios.map((scenario) => {
      return (
        <ScenarioRow
          key={scenario.id}
          scenario={scenario}
          openPriceScenario={openPriceScenarioById.get(scenario.id)}
          config={config}
          objectDetails={objectDetails}
          fieldInfo={fieldInfo}
        />
      );
    });

    return (
      <table data-selector="test" className={C.table}>
        <thead>
          <tr>{columnHeaders}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  },
);
