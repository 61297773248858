import * as api from "api";
import * as types from "types/engine-types";

export type DataDump = {
  investors: types.Investor[];
  products: types.Product[];
  rules: types.Rule[];
  dataTables: types.DataTable[];
  rawProductFields: types.RawProductFieldDefinition[];
  rawCreditApplicationFields: types.RawCreditApplicationFieldDefinition[];
  rawPipelineOnlyFields: types.RawPipelineFieldDefinition[];
  calculationStages: types.CalculationStage[];
  rawEnumerations: types.RawEnumType[];
};

export async function getDataDump(): Promise<DataDump> {
  const investors = await asyncMap(await api.getInvestors(), (i) =>
    api.getInvestor(i.id),
  );

  const products = await asyncMap(await api.getProducts(), (p) =>
    api.getProduct(p.id),
  );

  const rules = await asyncMap(await api.getRules(), (r) => api.getRule(r.id));

  const dataTables = await asyncMap(
    await api.getDataTables(),
    async (t) => await api.getDataTable(t.id),
  );

  const config = await api.getConfig();

  const {
    rawProductFields,
    rawCreditApplicationFields,
    rawPipelineOnlyFields,
    rawEnumerations,
  } = config;

  const calculationStages = config.stages
    .filter(
      (stage): stage is types.ExecutionStage.Calculations =>
        stage.kind === "calculations",
    )
    .map(({ id, calculations }) => {
      return { oldId: id, id, calculations };
    });

  return {
    investors,
    products,
    rules,
    dataTables,
    rawProductFields,
    rawCreditApplicationFields,
    rawPipelineOnlyFields,
    calculationStages,
    rawEnumerations,
  };
}

export async function createDataFromDump(dump: DataDump): Promise<void> {
  const investorsMap = new Map(
    await asyncMap(dump.investors, async (investor) => {
      const { id: newId } = await api.createInvestor(investor);

      return [investor.id, newId];
    }),
  );

  const productsMap = new Map(
    await asyncMap(dump.products, async (product) => {
      const { id: newId } = await api.createProduct({
        ...product,
        investorId: investorsMap.get(product.investorId)!,
        ruleIds: [],
      });

      return [product.id, newId];
    }),
  );

  const dataTablesMap = new Map(
    await asyncMap(dump.dataTables, async (tableExport) => {
      const { id: oldId, ...newTable } = tableExport;

      const { id: newId } = await api.createDataTable(newTable);

      return [oldId, newId];
    }),
  );

  for (const rule of dump.rules) {
    const body = rule.body && {
      ...rule.body,
      lookup: rule.body.lookup && {
        ...rule.body.lookup,
        tableId: dataTablesMap.get(rule.body.lookup.tableId)!,
      },
    };

    const productIds = rule.productIds.map((pid) => productsMap.get(pid)!);

    await api.createRule({ ...rule, body, productIds });
  }

  await api.updateFieldDefinitions({
    productFields: dump.rawProductFields,
    creditApplicationFields: dump.rawCreditApplicationFields,
    pipelineFields: dump.rawPipelineOnlyFields,
  });

  await api.updateEnumerations(dump.rawEnumerations);

  const updatedCalculations = dump.calculationStages.map((stage) => {
    return {
      ...stage,
      // this eslint rule apparently doesn't know about
      // exhaustive switch statements
      // eslint-disable-next-line array-callback-return
      calculations: stage.calculations.map((calculation) => {
        switch (calculation.type) {
          case "field":
            return calculation;
          case "data-table-lookup":
            return {
              ...calculation,
              lookup: {
                ...calculation.lookup,
                tableId: dataTablesMap.get(calculation.lookup.tableId)!,
              },
            };
        }
      }),
    };
  });

  await api.updateCalculations(updatedCalculations);
}

export async function deleteAllData(): Promise<void> {
  if (window.location.hostname !== "localhost") {
    const {
      client: { accessId, name },
    } = await api.getUserInfo();

    const expectedAnswer = `${window.location.hostname} ${accessId}`;

    const prompt = `this is on ${window.location.host}. Are you sure you want to delete all data for client "${name}" (${accessId})? If so, type "${expectedAnswer}" below.`;

    if (!accessId || window.prompt(prompt) !== expectedAnswer) {
      return;
    }
  }

  for (const rule of await api.getRules()) {
    await api.deleteRule(rule.id);
  }

  for (const dataTable of await api.getDataTables()) {
    await api.deleteDataTable(dataTable.id);
  }

  for (const product of await api.getProducts()) {
    await api.deleteProduct(product.id);
  }

  for (const investor of await api.getInvestors()) {
    await api.deleteInvestor(investor.id);
  }

  const newStages = (await api.getConfig()).stages
    .filter((stage) => stage.kind === "calculations")
    .map(({ id }) => ({
      oldId: id,
      id,
      calculations: [],
    }));

  await api.updateFieldDefinitions({
    productFields: [],
    creditApplicationFields: [],
    pipelineFields: [],
  });

  await api.updateEnumerations([]);

  await api.updateCalculations(newStages);
}

async function asyncMap<T, U>(it: T[], f: (t: T) => Promise<U>): Promise<U[]> {
  const arr = [];

  for (const t of it) {
    arr.push(await f(t));
  }

  return arr;
}
