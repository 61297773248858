import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Redirect, useParams, useLocation } from "react-router-dom";
import IFrameListener from "design/application/iframe-listener";
import LoginForm from "./_components/login-form";

// api
import * as Api from "api";
import { getErrorMessage } from "features/utils";
import { Credentials } from "types/generated-types";

// assets
import loanpassLogoUrl from "images/light-theme-logo-600.png";
import { setLoggedIn } from "features/login-logout/set-logged-in";

type Params = {
  clientAccessId?: string;
};

type LoginParameters = {
  redirectIfLoggedIn?: boolean;
};

function LoginPage({ redirectIfLoggedIn }: LoginParameters) {
  const [shouldRedirect, setShouldRedirect] = useState(
    typeof redirectIfLoggedIn === "undefined" ? true : redirectIfLoggedIn,
  );
  const params = useParams<Params>();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const initialClientAccessId =
    params.clientAccessId ?? localStorage.getItem("clientAccessId");

  const [helperText, setHelperText] = useState("");
  const [error, setError] = useState(false);
  const [brandLogo, setBrandLogo] = useState<string>();
  const [loggedInClient, setLoggedInClient] = useState<string | null>(() => {
    const paramsClient = params.clientAccessId;
    const localStorageClient = localStorage.getItem("clientAccessId");
    const localStorageSession = localStorage.getItem("sessionToken");
    if (localStorageClient != null && localStorageSession != null) {
      if (paramsClient === localStorageClient || paramsClient == null) {
        return localStorageClient;
      } else {
        // User accessed login page for a different client, so don't automatically sign in
        return null;
      }
    } else {
      return null;
    }
  });

  const handleLogin = useCallback(async (credentials: Credentials) => {
    setHelperText("Signing you in...");

    try {
      const res = await Api.login(credentials);

      setError(false);
      setHelperText("Login Successful");

      setLoggedIn({
        sessionToken: res.sessionToken,
        accessId: res.client.accessId,
      });
      setShouldRedirect(true);
      setLoggedInClient(res.client.accessId);
    } catch (e) {
      setError(true);
      const error = e as Error;
      if (error.message === "Forbidden") {
        setHelperText("Forbidden");
      } else {
        setHelperText("Incorrect Credentials");
      }
    }
  }, []);

  useEffect(() => {
    const qpUsername = queryParams.get("username");
    const qpPassword = queryParams.get("pw");
    const clientAccessId = params.clientAccessId;

    if (clientAccessId && qpUsername && qpPassword) {
      handleLogin({
        clientAccessId,
        emailAddress: qpUsername,
        password: qpPassword,
      });
    }
  }, [queryParams, params.clientAccessId, handleLogin]);

  useEffect(() => {
    const getLogo = async () => {
      if (initialClientAccessId != null) {
        try {
          const brand = await Api.getClientBrand(initialClientAccessId);
          if (brand) {
            const logoUrlToSet = brand.logoUrl ?? loanpassLogoUrl;
            setBrandLogo(logoUrlToSet);
          }
        } catch (error) {
          console.error(error);
          window.newrelic.noticeError(getErrorMessage(error));
        }
      } else {
        // If no client access ID is set in the URL, then just show the LoanPASS logo
        setBrandLogo(loanpassLogoUrl);
      }
    };

    getLogo();
  }, [initialClientAccessId]);

  if (loggedInClient != null && shouldRedirect) {
    let redirect = queryParams.get("redirect") || "";
    if (redirect) {
      redirect = decodeURIComponent(redirect);
    } else {
      redirect = `/c/${loggedInClient}/`;
    }
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <LoginForm
        initialClientAccessId={initialClientAccessId ?? undefined}
        brandLogo={brandLogo}
        error={error}
        helperText={helperText}
        onLogin={handleLogin}
      />
      <IFrameListener />
    </>
  );
}

export default LoginPage;
