import React, { useState, ChangeEvent, useEffect, useMemo } from "react";
import {
  Header,
  Row,
  InputSection,
  InputWrapper,
  PipelineScenarios,
  RecordSection,
  StyledCheckbox,
  CheckboxWrapper,
} from "./styles";
import TextInput from "design/atoms/text-input";
import Button from "design/atoms/button";
import Icon from "design/atoms/icon";
import { faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";
import { PrimaryTitle, SmallText } from "design/atoms/typography";
import PipelineResult from "pages/pipeline-v2/_components/pipeline-result";
import {
  loanPricingStateSelector,
  setPipelineRecordId,
  setPipelineStage,
} from "features/loan-pricing";
import { useDispatch, useSelector } from "react-redux";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import {
  expandedConfigSelector,
  nonNullApplicationInitializationSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import * as Api from "api";
import * as T from "types/engine-types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { loadScenario, loansSelector } from "features/loans";
import { getPipeline, pipelineSelector } from "features/pipeline";
import Select from "design/atoms/select";
import Field from "design/organisms/field";
import {
  FieldValueState,
  newFieldValueState,
} from "design/organisms/field-value-editor";
import { useIMapSetter } from "features/utils";
import { Map as IMap } from "immutable";
import { usePermissions } from "features/roles";
import color from "design/subatomics/colors";
import moment from "moment";
import * as Fields from "features/fields";
import whitespace from "design/subatomics/whitespace";

type Params = {
  clientAccessId: string;
  productId: T.ProductId | undefined;
  pricingScenarioRate: string | undefined;
  pricingScenarioLock: string | undefined;
};

export default React.memo(
  ({
    setHashRead,
  }: {
    setHashRead: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const [name, setName] = useState("");
    const [addInputs, setAddInputs] = useState(false);
    const [makeDefault, setMakeDefault] = useState(false);
    const queryParams = new URLSearchParams(document.location.search);
    const { pipelineStage, pipelineRecordId } = useSelector(
      loanPricingStateSelector,
    );
    const { currentFormValues } = useSelector(loansSelector);
    const dispatch = useDispatch();
    const config = useSelector(expandedConfigSelector);
    const { user, myPricingProfile } = useSelector(
      nonNullApplicationInitializationSelector,
    );
    const history = useHistory();
    const params = useParams<Params>();
    const location = useLocation();
    const settings = config.settings;
    const settingsType = settings.priceScenarioTable?.type;
    const [record, setRecord] = useState<T.PipelineRecord | null>(null);
    const hasPermission = usePermissions();
    const userOwnsRecord: boolean = user.id === record?.ownerId;
    const hasViewAllRecords = hasPermission("pipeline-records-view-all");
    const hasEditAll = hasPermission("pipeline-records-edit-all");
    const objectDetails = useSelector(objectDetailsMapSelector);
    const pipelineState = useSelector(pipelineSelector);
    const pipelineFields = config.pipelineOnlyFields;
    const initialPipelineFieldValueStates: IMap<T.FieldId, FieldValueState> =
      useMemo(
        () =>
          IMap<T.FieldId, FieldValueState>(
            pipelineFields.map((f) => [f.id, newFieldValueState(f.valueType)]),
          ),
        [pipelineFields],
      );

    const [pipelineFieldValueStates, setPipelineFieldValueStates] = useState(
      initialPipelineFieldValueStates,
    );

    const pipelineFieldValueSetter = useIMapSetter(setPipelineFieldValueStates);

    const pipelineRecordScenarios = hasViewAllRecords
      ? pipelineState.records?.filter((option) => option.status === "open") ||
        []
      : pipelineState.records?.filter(
          (option) => option.status === "open" && option.ownerId === user.id,
        ) || [];

    useEffect(() => {
      if (pipelineRecordId) {
        Api.getPipelineRecord(pipelineRecordId).then((r) => {
          setRecord(r);
        });
      }
    }, [pipelineRecordId]);

    useEffect(() => {
      if (record) {
        dispatch(
          getPipeline(
            hasViewAllRecords ? { ownerId: null } : { ownerId: user.id },
          ),
        );
      }
    }, [record, dispatch, hasViewAllRecords, user.id]);

    const savePipelineScenario = () => {
      let priceScenarioInfo: T.SelectedPriceScenarioInfo | null = null;

      if (params.pricingScenarioRate) {
        if (
          settingsType === "rate-with-lock-period" &&
          params.pricingScenarioLock
        ) {
          priceScenarioInfo = {
            type: "rate-with-lock-period",
            selectedInterestRate: params.pricingScenarioRate,
            selectedLockPeriod: {
              count: params.pricingScenarioLock,
              unit: {
                type: "days",
              },
            },
          };
        } else if (settingsType === "rate-with-columns") {
          priceScenarioInfo = {
            type: "rate",
            selectedInterestRate: params.pricingScenarioRate,
          };
        }
      }

      dispatch(
        getPipeline(
          hasViewAllRecords ? { ownerId: null } : { ownerId: user.id },
        ),
      );

      if (record) {
        Api.createPipelineRecordScenario(record.id, {
          name,
          preferred: makeDefault,
          priceALoanUrl: `${location.pathname}${location.search}${location.hash}`,
          creditApplicationFieldValues: currentFormValues || [],
          selectedProductId: params?.productId || null,
          selectedPriceScenarioInfo: priceScenarioInfo || null,
        }).then(() => {
          setName("");
          setMakeDefault(false);
          setAddInputs(false);
          Api.getPipelineRecord(record.id).then((r) => setRecord(r));
        });
      }
    };

    const handleDelete = (scenarioId: T.PipelineRecordScenarioId) => {
      if (record) {
        Api.deletePipelineRecordScenario(record.id, scenarioId).then(() => {
          Api.getPipelineRecord(record.id).then((r) => setRecord(r));
        });
      }
    };

    const create = () => {
      dispatch(setPipelineRecordId(null));

      let priceScenarioInfo: T.SelectedPriceScenarioInfo | null = null;

      if (params.pricingScenarioRate) {
        if (
          settingsType === "rate-with-lock-period" &&
          params.pricingScenarioLock
        ) {
          priceScenarioInfo = {
            type: "rate-with-lock-period",
            selectedInterestRate: params.pricingScenarioRate,
            selectedLockPeriod: {
              count: params.pricingScenarioLock,
              unit: {
                type: "days",
              },
            },
          };
        } else if (settingsType === "rate-with-columns") {
          priceScenarioInfo = {
            type: "rate",
            selectedInterestRate: params.pricingScenarioRate,
          };
        }
      }

      Api.createPipelineRecord({
        ownerId: user.id,
        status: "open",
        pricingProfileId: myPricingProfile.id,
        pipelineOnlyFieldValues: Array.from(pipelineFieldValueStates).map(
          (v) => {
            return {
              fieldId: v[0],
              value: v[1],
            } as T.FieldValueMapping;
          },
        ),
        scenarios: [
          {
            name: "Initial Inputs",
            preferred: true,
            priceALoanUrl: `${location.pathname}${location.search}${location.hash}`,
            creditApplicationFieldValues: currentFormValues || [],
            selectedProductId: params?.productId || null,
            selectedPriceScenarioInfo: priceScenarioInfo,
          },
        ],
      }).then((savedRecord: T.PipelineRecord) => {
        dispatch(setPipelineRecordId(savedRecord.id));
        dispatch(
          getPipeline(
            hasViewAllRecords ? { ownerId: null } : { ownerId: user.id },
          ),
        );
      });
    };

    return (
      <Header>
        {pipelineStage === "initial" &&
          queryParams.get("inCompareView") !== "true" && (
            <Row>
              <Button
                isPrimary={true}
                grow={false}
                onClick={() => {
                  setPipelineFieldValueStates(initialPipelineFieldValueStates);
                  dispatch(setPipelineStage("preCheck"));
                }}
              >
                Start or continue Pipeline record.
              </Button>
            </Row>
          )}

        {pipelineStage === "preCheck" && (
          <Row>
            <SmallText>Check for existing records first.</SmallText>

            <Select
              placeholder="Lookup Loan"
              getOptionLabel={(option) => {
                if (option?.pipelineOnlyFieldValues[0]) {
                  const field: T.BaseFieldDefinition | undefined =
                    config.pipelineOnlyFields.find(
                      (f) =>
                        f.id === option?.pipelineOnlyFieldValues[0].fieldId,
                    );
                  const valueObj = option?.pipelineOnlyFieldValues[0];

                  if (field && valueObj) {
                    const value = Fields.fieldValueToString(
                      config,
                      objectDetails,
                      field?.valueType,
                      valueObj.value,
                    );

                    return `
                    ${value || ""} from ${moment(option.createdAt).format(
                      "MM-DD-YYYY",
                    )}
                  `;
                  } else {
                    return "";
                  }
                } else {
                  return "";
                }
              }}
              getOptionValue={() => ""}
              value={null}
              options={pipelineRecordScenarios}
              onChange={(option) => {
                if (option) {
                  setHashRead(false);
                  dispatch(setPipelineRecordId(option.id));
                  dispatch(setPipelineStage("view"));
                  history.push(option.preferredScenario.priceALoanUrl);
                }
              }}
            />

            <SmallText style={{ marginRight: "4px" }}>
              If no matches were found
            </SmallText>

            <Button
              grow={false}
              isPrimary={true}
              onClick={() => {
                dispatch(setPipelineStage("create"));
              }}
              children="Create Pipeline record."
            />
          </Row>
        )}

        {pipelineStage === "create" && (
          <InputSection>
            <PrimaryTitle>Create Pipeline Record</PrimaryTitle>

            {pipelineFields.map((field) => {
              return (
                <InputWrapper>
                  <Field
                    showDescription={false}
                    key={field.id}
                    required={false}
                    showErrors={true}
                    margin="dense"
                    field={field}
                    defaultValue={undefined}
                    fieldState={
                      field
                        ? pipelineFieldValueStates.get(field.id) ||
                          newFieldValueState(field.valueType)
                        : null
                    }
                    parentState={null}
                    setState={(e) => {
                      return pipelineFieldValueSetter.withKey(field.id)(e);
                    }}
                  />
                </InputWrapper>
              );
            })}

            <Button
              grow={false}
              isPrimary={true}
              onClick={() => {
                dispatch(setPipelineStage("view"));
                create();
              }}
            >
              Create
            </Button>
          </InputSection>
        )}

        {pipelineStage === "view" && (
          <RecordSection>
            {record && (
              <PipelineResult
                record={record}
                interactable={false}
                color="blue"
                icon={<Icon icon={faCircle} />}
              />
            )}
          </RecordSection>
        )}

        {pipelineStage === "view" && (
          <PipelineScenarios>
            {!addInputs &&
              record?.scenarios
                .sort((a, b) => {
                  if (a.createdAt < b.createdAt) return -1;
                  if (a.createdAt > b.createdAt) return 1;
                  return 0;
                })
                .map((scenario) => {
                  const match =
                    `${location.pathname}${location.search}${location.hash}` ===
                    scenario.priceALoanUrl;

                  return (
                    <Button
                      isPrimary={match}
                      grow={false}
                      suffix={
                        scenario.preferred ? (
                          <>
                            <Icon icon={faStar} />
                          </>
                        ) : (
                          <>
                            {(userOwnsRecord || hasEditAll) && (
                              <Icon
                                title="Click to delete this scenario"
                                style={{
                                  marginRight: whitespace("more"),
                                  marginLeft: whitespace(),
                                }}
                                icon={faTrash}
                                onClick={() => {
                                  handleDelete(scenario.id);
                                }}
                              />
                            )}
                            {(userOwnsRecord || hasEditAll) && (
                              <Icon
                                title="Click to make this the preferred scenario"
                                icon={faStarEmpty}
                                onClick={() => {
                                  Api.updatePipelineRecordScenario(
                                    record.id,
                                    scenario.id,
                                    {
                                      preferred: true,
                                    },
                                  ).then(() => {
                                    Api.getPipelineRecord(record.id).then(
                                      (r) => {
                                        setRecord(r);
                                      },
                                    );

                                    dispatch(
                                      loadScenario(
                                        scenario.creditApplicationFieldValues,
                                      ),
                                    );
                                    history.push(scenario.priceALoanUrl);
                                  });
                                }}
                              />
                            )}
                          </>
                        )
                      }
                    >
                      <span
                        title="Click to load this scenario"
                        onClick={() => {
                          dispatch(
                            loadScenario(scenario.creditApplicationFieldValues),
                          );
                          history.push(scenario.priceALoanUrl);
                        }}
                      >
                        {scenario.name}
                      </span>
                    </Button>
                  );
                })}

            {addInputs && (
              <InputWrapper>
                <TextInput
                  placeholderText="Save Scenario Name"
                  inputProps={{
                    value: name,
                    onChange: (e: ChangeEvent<HTMLInputElement>) =>
                      setName(e.target.value),
                  }}
                />
                <CheckboxWrapper>
                  <StyledCheckbox
                    checked={makeDefault}
                    onClick={() => {
                      setMakeDefault(!makeDefault);
                    }}
                  />{" "}
                  Make Preferred
                </CheckboxWrapper>
              </InputWrapper>
            )}
            {addInputs && (
              <Button
                className="cancel-add-scenario"
                grow={false}
                onClick={() => {
                  setAddInputs(false);
                }}
              >
                Cancel
              </Button>
            )}

            {!addInputs && (
              <span
                style={{
                  borderRight: `1px solid ${color({ color: "border" })}`,
                  width: "1px",
                  height: "24px",
                }}
              />
            )}

            {(userOwnsRecord || hasEditAll) && (
              <Button
                disabled={addInputs && !name}
                grow={false}
                onClick={() => {
                  if (addInputs) {
                    savePipelineScenario();
                  } else {
                    setAddInputs(true);
                  }
                }}
              >
                {addInputs ? <>Save Scenario</> : <>Add Scenario</>}
              </Button>
            )}

            {!addInputs && (
              <Button
                className="clear-scenario"
                title="Clear Borrower Info"
                isPrimary={false}
                grow={false}
                onClick={() => {
                  setRecord(null);
                  dispatch(setPipelineStage("initial"));
                  dispatch(setPipelineRecordId(null));
                }}
              >
                Exit Pipeline Record
              </Button>
            )}
          </PipelineScenarios>
        )}
      </Header>
    );
  },
);
