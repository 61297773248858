import styled from "styled-components";
import color from "design/subatomics/colors";
import whitespace, { spacingOptions } from "design/subatomics/whitespace";
import breakpoints from "design/subatomics/breakpoints";
import radiusOptions from "design/subatomics/corners";
import transitions from "design/subatomics/transitions";
import fontSizes from "design/subatomics/font-sizes";
import shadowOptions from "design/subatomics/shadows";

export const ApplicationHeader = styled.header`
  border-bottom: 1px solid ${color({ color: "border" })};
  display: flex;
  flex-direction: column;

  .atom-logo {
    align-self: center;
    justify-self: center;
  }

  .sign-out {
    position: absolute;
    bottom: ${whitespace("less")};
    right: ${whitespace("less")};
  }

  ${breakpoints.mobile} {
    .hide-for-mobile {
      display: none !important;
    }

    .atom-logo {
      max-height: 28px;
    }
  }

  ${breakpoints.desktop} {
    .nav-trigger {
      margin-left: ${whitespace()};
    }

    .hide-for-desktop {
      display: none !important;
    }

    .atom-logo {
      max-height: 42px;
    }
  }
`;

export const AdminLinks = styled.div`
  a {
    display: flex;
    padding: ${whitespace("least")} ${whitespace("less")};
    margin: ${whitespace("least")};
    transition: background-color ${transitions.default};
    align-items: center;
    justify-content: center;

    .icon {
      text-align: left;
      width: 32px;
      transition: color ${transitions.default};
      color: ${color({ color: "green", shade: 5 })};
    }

    .label {
      text-align: right;
      width: calc(100% - ${spacingOptions.most}px - ${spacingOptions.less}px);
      transition: color ${transitions.default};
      color: ${color({ color: "gray", shade: 4 })};
    }

    &:hover {
      background-color: ${color({ color: "green", shade: 8 })};
      border-radius: ${radiusOptions.medium};

      .icon {
        color: ${color({ color: "white" })};
      }
    }
  }
`;

export const UserDetails = styled.div.attrs(() => ({
  className: "user-details",
}))`
  padding: ${whitespace("less")};
  text-align: right;
`;

export const UserConfig = styled.div.attrs(() => ({
  className: "user-config",
}))`
  box-sizing: border-box;

  label {
    display: block;
    margin: ${whitespace()} ${whitespace("less")};
    flex: 1 1 calc((100% / 3) - ${whitespace("less")} - ${whitespace("less")});

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .molecule-select {
    margin: 0 ${whitespace("less")} ${whitespace()};
  }
`;

export const BuildDetails = styled.div.attrs(() => ({
  className: "user-config",
}))`
  position: absolute;
  bottom: ${whitespace("less")};
  left: ${whitespace("less")};
  color: ${color({ color: "gray", shade: 5 })};

  span {
    display: block;
  }
`;

export const NavigationLinks = styled.div.attrs(() => ({
  className: "support-links",
}))`
  box-sizing: border-box;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: ${whitespace()} auto;
  display: block;
  margin-top: ${whitespace("most")};

  a {
    box-shadow: ${shadowOptions.controls};
    transition: background-color ${transitions.default},
      border-color ${transitions.default}, color ${transitions.default} !important;

    .icon,
    .label {
      transition: background-color ${transitions.default},
        border-color ${transitions.default}, color ${transitions.default} !important;
      font-size: ${fontSizes.s};
      display: inline-block;
    }

    .icon {
      margin-right: ${whitespace("least")};
      color: ${color({ color: "gray", shade: 6 })};
    }

    .label {
      color: ${color({ color: "gray", shade: 4 })};
    }

    &:hover {
      cursor: pointer;
      background-color: ${color({ color: "green", shade: 6 })};
      border-color: ${color({ color: "green", shade: 6 })};

      .icon,
      .label {
        color: ${color({ color: "white" })};
      }
    }
  }

  > span {
    display: none;
  }

  > a {
    border-radius: ${radiusOptions.medium};
    border: 1px solid ${color({ color: "lightBorder" })};
    text-align: center;
    display: inline-block;
    flex: 0 1 calc(50%);
    box-sizing: border-box;
    width: calc(50% - ${whitespace("less")} - ${whitespace("less")});
    min-width: auto;
    padding: ${whitespace("less")};
    margin: 0 ${whitespace("less")} ${whitespace("less")};
  }
`;

export const PrimaryNav = styled.nav`
  position: relative;
  display: flex;
  padding: ${whitespace("less")};
  height: 48px;
  box-sizing: border-box;
  justify-items: center;
  align-items: center;

  .atom-logo,
  .nav-trigger {
    position: absolute;
  }

  .nav-trigger {
    position: static;
    flex: 0 0;
  }

  .atom-logo {
    left: ${whitespace("less")};
  }

  .nav-trigger {
    right: ${whitespace("less")};
  }
`;
