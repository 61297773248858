import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Api from "api";
import { localAccessId } from "features/access-id";
import * as T from "types/engine-types";

export type ReferenceErrorsState = {
  referenceErrors: T.Ref[];
  loaded: boolean;
  loading: boolean;
  errors: string;
};

const initialState: ReferenceErrorsState = {
  referenceErrors: [],
  loaded: false,
  loading: false,
  errors: "",
};

export const getReferenceErrors = createAsyncThunk(
  "brokenReferences/getBrokenReferences",
  async () => {
    return await Api.getInvalidReferences(localAccessId());
  },
);

const brokenReferencesSlice = createSlice({
  name: "BrokenReferences",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getReferenceErrors.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getReferenceErrors.fulfilled, (state, { payload }) => {
      state.loaded = true;
      state.loading = false;
      state.referenceErrors = payload;
      console.log("fulfilled");
    });
  },
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },
    setInvestors: (state, { payload }: PayloadAction<T.Ref[]>) => {
      state.referenceErrors = payload;
    },
  },
});

export const { setLoading, setErrors, setInvestors } =
  brokenReferencesSlice.actions;

export default brokenReferencesSlice.reducer;

export const brokenReferencesStateSelector = (state: {
  brokenReferences: ReferenceErrorsState;
}) => state.brokenReferences;
