import { Set as ISet } from "immutable";
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { Configuration } from "config";
import * as T from "types/engine-types";
import { fieldPredicateToString } from "features/field-predicates";
import { ObjectDetails } from "features/objects";

const guideLineBg = "#ccc";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    conditionHeader: {
      marginBottom: "10px",
      display: "flex",
      alignItems: "center",
      background: "rgba(0, 0, 0, 0.05)",
      transition: "all 0.5s",
      color: "black",
      padding: "4px 8px",
      width: "fit-content",
    },
    conditionItem: {
      marginBottom: "10px",
      marginLeft: "8px",
    },
    ruleConditionRootContainer: {
      width: "100%",
    },
    ruleBox: {
      // width: "100%",
    },
    ruleContainer: {
      // marginLeft: "25px",
    },
    ruleContainerHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    ruleText: {
      margin: theme.spacing(0, 1),
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      padding: theme.spacing(2, 4, 3),
    },
    rulePredicateViewerConatiner: {
      display: "flex",
      alignItems: "center",
    },
    predicateContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
    },
    predicateEditor: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 270,
      },
    },
    verticalLine: {
      marginLeft: 16,
      marginBottom: 12,
      border: `solid 1px ${guideLineBg}`,
    },
    horizontalLine: {
      marginRight: 4,
      width: 24,
      alignSelf: "baseline",
      marginTop: "7px",
      border: `solid 1px ${guideLineBg}`,
    },
  }),
);

export const RuleConditionViewer = React.memo(
  ({
    condition,
    environment,
    localFields,
    config,
    objectDetails,
  }: {
    condition: T.RuleCondition;
    environment: ISet<T.FieldId>;
    localFields: readonly T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
    objectDetails: ObjectDetails;
  }) => {
    const C = useStyles();

    return (
      <Box className={C.ruleConditionRootContainer}>
        {condition && (condition.type === "and" || condition.type === "or") && (
          <RuleContainer
            condition={condition}
            parentCondition={null}
            environment={environment}
            localFields={localFields}
            config={config}
            objectDetails={objectDetails}
          />
        )}
      </Box>
    );
  },
);

/*
  RuleContainer contains an array of predicates or child RuleContainers
  Correspond to each "and" or "or" type of condition
*/
const RuleContainer = React.memo(
  ({
    condition,
    parentCondition,
    environment,
    localFields,
    config,
    objectDetails,
  }: {
    condition: T.RuleCondition.And | T.RuleCondition.Or;
    parentCondition: T.RuleCondition.And | T.RuleCondition.Or | null;
    environment: ISet<T.FieldId>;
    localFields: readonly T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
    objectDetails: ObjectDetails;
  }) => {
    const C = useStyles();

    // if only one child condition at root, and it is a field-predicate, then childRootCondition has value
    const childRootCondition =
      !parentCondition && condition.children.length === 1
        ? condition.children[0]
        : null;

    if (childRootCondition?.type === "field-predicate") {
      return (
        <div style={{ marginLeft: "-8px" }}>
          <SimpleRulePredicateViewer
            predicate={childRootCondition.predicate}
            environment={environment}
            localFields={localFields}
            config={config}
            objectDetails={objectDetails}
          />
        </div>
      );
    }

    return (
      <div className={C.ruleBox}>
        <div className={C.conditionHeader}>
          {condition.type === "and" ? "All " : "Any "}of the following are true:
        </div>
        <div style={{ display: "flex" }}>
          <div className={C.verticalLine} />
          <div>
            {condition.children.length === 0 && (
              <Typography
                style={{
                  marginLeft: "24px",
                  fontSize: 14,
                  color: "#666",
                  marginBottom: 10,
                }}
              >
                This condition is empty
              </Typography>
            )}
            {condition.children.map((childCondition, childIndex) => {
              // Wrap in immediately executed function to workaround eslint limitations
              return (function () {
                switch (childCondition.type) {
                  case "field-predicate":
                    return (
                      <div key={childIndex}>
                        <div className={C.rulePredicateViewerConatiner}>
                          <div className={C.horizontalLine} />
                          <SimpleRulePredicateViewer
                            predicate={childCondition.predicate}
                            environment={environment}
                            localFields={localFields}
                            config={config}
                            objectDetails={objectDetails}
                          />
                        </div>
                      </div>
                    );
                  case "and":
                  case "or":
                    return (
                      <div key={childIndex}>
                        <div className={C.rulePredicateViewerConatiner}>
                          <div
                            className={C.horizontalLine}
                            style={{ marginTop: 12 }}
                          />
                          <RuleContainer
                            parentCondition={condition}
                            condition={childCondition}
                            environment={environment}
                            localFields={localFields}
                            config={config}
                            objectDetails={objectDetails}
                          />
                        </div>
                      </div>
                    );
                }
              })();
            })}
          </div>
        </div>
      </div>
    );
  },
);

const SimpleRulePredicateViewer = React.memo(
  ({
    predicate,
    environment,
    localFields,
    config,
    objectDetails,
  }: {
    predicate: T.FieldPredicate;
    environment: ISet<T.FieldId>;
    localFields: readonly T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
    objectDetails: ObjectDetails;
  }) => {
    const C = useStyles();

    return (
      <>
        <div className={C.conditionItem}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              color: "black",
            }}
          >
            <div
              className={C.ruleText}
              style={{
                marginLeft: "0px",
              }}
            >
              {fieldPredicateToString(
                environment,
                localFields,
                config,
                objectDetails,
                predicate,
              )}
            </div>
          </div>
        </div>
      </>
    );
  },
);
