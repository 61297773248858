import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Libraries
import { Box, Button, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// api
import * as Api from "api";
import { UserPasswordResetTokenId } from "types/generated-types";

import Link from "design/atoms/link";

function RestPasswordPage() {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const classes = useStyles();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [allowReset, setAllowReset] = useState(true);

  const isFormEnabled =
    newPassword.length > 0 && confirmPassword.length > 0 && allowReset;
  const clientAccessId: string | null = queryParams.get("client");
  const tokenId = queryParams.get("tokenId") as UserPasswordResetTokenId | null;
  const tokenSecret = queryParams.get("tokenSecret");

  useEffect(() => {
    if (!tokenId || !tokenSecret) {
      setHelperText(
        "Your password reset token is invalid, try requesting another password reset and try again",
      );
      setError(true);
      setAllowReset(false);
    }
  }, [tokenId, tokenSecret]);

  const handlePasswordReset: React.FormEventHandler = useCallback(
    (e) => {
      e.preventDefault();

      if (newPassword !== confirmPassword) {
        setHelperText("Passwords do not match!");
        setError(true);
        return;
      }

      if (!tokenId || !tokenSecret) {
        console.warn(
          "Tried resetting password without a valid token ID/secret",
        );
        return;
      }

      setHelperText("Resetting password...");
      setAllowReset(false);

      Api.resetPassword({
        tokenId,
        tokenSecret,
        newPassword,
      })
        .then(() => {
          if (clientAccessId) {
            history.push(`/login/${clientAccessId}`);
          }
        })
        .catch((error) => {
          console.warn("Error resetting password", error);

          if (error instanceof Api.InvalidRequest) {
            setHelperText(error.message);
          } else {
            setHelperText(
              "Could not reset password! Did your password reset expire?",
            );
          }

          setError(true);
          setAllowReset(true);

          if (error instanceof Error && error.name === "NotUniqueError") {
            setHelperText(
              "Save failed because a user with this name already exists.",
            );
          } else if (error instanceof Error && error.message) {
            setHelperText(error.message);
          } else {
            setHelperText("Save failed because of an error.");
          }
        });
    },
    [
      tokenId,
      tokenSecret,
      newPassword,
      confirmPassword,
      history,
      clientAccessId,
    ],
  );

  return (
    <Box
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={classes.container}>
        <form noValidate autoComplete="off" onSubmit={handlePasswordReset}>
          <TextField
            autoFocus
            fullWidth
            type="password"
            label="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            fullWidth
            type="password"
            label="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={error}
            helperText={helperText}
          />

          <Box className={classes.resetBtnSection}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={!isFormEnabled}
            >
              Reset Password
            </Button>
          </Box>
          <Box className={classes.resetBtnSection}>
            <Link to={clientAccessId ? `/login/${clientAccessId}` : "/login"}>
              Back to Login
            </Link>
          </Box>
        </form>
      </div>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      maxWidth: 400,
      margin: "0 auto",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      "& .MuiTextField-root": {
        marginBottom: theme.spacing(2),
      },
    },
    logo: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: theme.spacing(3),
      width: "100%",
      maxWidth: 350,
    },
    resetBtnSection: {
      marginTop: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      justifyContent: "space-between",
    },
  }),
);

export default RestPasswordPage;
