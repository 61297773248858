import { setMetric } from "api";

export const performanceEvents = {
  // Should be kept in sync with performanceEvents in `index.html`
  t0: "t0",
  firstProductPaint: "first-product-paint",
};

export function postStartupMetric(key: string) {
  // A startup metric is special in 2 ways:
  // 1. You measure from t0.
  // 2. You don't want to measure it again when the event is triggered a second time after startup.
  // Goal 1 is achieved by measuring t0 in index.html.
  // Goal 2 is achieved using sessionStorage and relies on sessionStorage being
  // cleared at startup time in index.html and then being filled here.

  // Only set this metric if it hasn't yet been set.
  const duration = sessionStorage.getItem(key);
  if (!duration) {
    // Use the performance api to see how many ms it's been since startup.
    performance.mark(key);
    const duration = performance.measure(
      key,
      performanceEvents.t0,
      key,
    ).duration;

    // Trigger a request to the backend but don't await it -- don't want to block for a metric.
    setMetric(key, duration);

    // Set duration in sessionStorage so that this metric won't be collected a second time.
    sessionStorage.setItem(key, `${duration}`);
  }
}
