import * as T from "types/engine-types";
import { getInvestorKey } from ".";
import { Snapshot } from "..";

export function exportInvestor(
  snapshot: Snapshot,
  investorId: T.InvestorId,
): T.NewInvestor {
  const investor = snapshot.investors.find((i) => i.id === investorId);

  if (!investor) {
    throw new Error("Investor not found: ID=" + JSON.stringify(investorId));
  }

  return {
    name: investor.name,
    code: investor.code,
    isPricingEnabled: investor.isPricingEnabled,
  };
}

export function exportInvestorId(
  snapshot: Snapshot,
  investorId: T.InvestorId,
): T.InvestorId {
  const investor = snapshot.investors.find((i) => i.id === investorId);

  if (!investor) {
    throw new Error("Investor not found: ID = " + JSON.stringify(investorId));
  }

  return getInvestorKey(investor) as T.InvestorId;
}
