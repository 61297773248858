import React from "react";
import { PipelineResultsSummary } from "./styles";
import { SmallText, Text } from "design/atoms/typography";
import { useDispatch, useSelector } from "react-redux";
import Button from "design/atoms/button";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PipelineResultsCount from "design/atoms/pipeline-results-count";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import { setPipelineRecordId, setPipelineStage } from "features/loan-pricing";
import { resetFilters } from "features/pipeline";

type Params = {
  productId: string;
  pricingScenarioRate: string;
  pricingScenarioLock: string;
};

export default React.memo(
  ({
    open,
    setOpen,
    loading,
    activeFilters,
    preSaveMessage,
    setPreSaveMessage,
  }: {
    open?: boolean;
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    loading?: boolean;
    activeFilters?: number | undefined;
    preSaveMessage: boolean;
    setPreSaveMessage: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const queryParams = new URLSearchParams(document.location.search);
    const params = useParams<Params>();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const storageKey = "loanPASS::hidePreSavePipelineMessage";
    const { client } = useSelector(nonNullApplicationInitializationSelector);

    return (
      <>
        <PipelineResultsSummary className="pipeline-component-pipeline-results-summary">
          {loading && (
            <SmallText className="updating">
              Updating pipeline records.
            </SmallText>
          )}

          {preSaveMessage && (
            <Text className="updating">
              Always check for the existence of a Borrower Pipeline Record
              before creating a new one.
            </Text>
          )}

          {!loading && !preSaveMessage && (
            <PipelineResultsCount
              showCounts={!params.productId}
              className="v2Results"
            />
          )}

          {!!activeFilters && !preSaveMessage && (
            <Button
              grow={true}
              className="hide-for-mobile"
              style={{ marginLeft: 0 }}
              onClick={() => {
                history.push(
                  `${location.pathname}?${queryParams.toString()}#${
                    location.hash ? "#" + location.hash : ""
                  }`,
                );

                dispatch(resetFilters());
              }}
            >
              Reset Filters
            </Button>
          )}

          {setOpen && !preSaveMessage && (
            <Button
              grow={true}
              className="hide-for-mobile"
              onClick={() => setOpen(!open)}
            >
              {open ? "Close Filters" : `Filters`}
            </Button>
          )}

          {preSaveMessage && (
            <Button
              className="hide-for-mobile"
              grow={true}
              onClick={() => {
                setPreSaveMessage(false);
              }}
            >
              Cancel
            </Button>
          )}

          {preSaveMessage && (
            <Button
              className="hide-for-mobile"
              isPrimary={true}
              grow={true}
              onClick={() => {
                dispatch(setPipelineStage("create"));
                dispatch(setPipelineRecordId(null));
                history.push(`/c/${client.accessId}/v2/loan-pricing`);
              }}
            >
              Continue
            </Button>
          )}

          <Button
            className="hide-for-mobile"
            isPrimary={true}
            grow={true}
            onClick={() => {
              if (preSaveMessage) {
                dispatch(setPipelineStage("create"));
                dispatch(setPipelineRecordId(null));
                history.push(`/c/${client.accessId}/v2/loan-pricing`);
                localStorage.setItem(storageKey, "true");
              } else {
                if (localStorage.getItem(storageKey) === "true") {
                  dispatch(setPipelineStage("create"));
                  dispatch(setPipelineRecordId(null));
                  history.push(`/c/${client.accessId}/v2/loan-pricing`);
                } else {
                  setPreSaveMessage(true);
                }
              }
            }}
          >
            {preSaveMessage
              ? "Continue and Hide This Message"
              : "Create Pipeline Record"}
          </Button>
        </PipelineResultsSummary>
      </>
    );
  },
);
