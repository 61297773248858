import Button from "design/atoms/button";
import Icon from "design/atoms/icon";
import { Scenarios, ButtonGroup, Scenario } from "./styles";
import {
  faTrash,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { SmallText, PanelTitle, AsLink, Text } from "design/atoms/typography";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loansSelector,
  loadScenario,
  deleteScenario,
  loadScenarios,
  promoteScenarioToClientScope,
  demoteScenarioToUserScope,
} from "features/loans";
import moment from "moment";
import { usePermissions } from "features/roles";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

export default React.memo(
  ({ className, close }: { className?: string; close: () => void }) => {
    const hasPermission = usePermissions();
    const dispatch = useDispatch();
    const { user } = useSelector(nonNullApplicationInitializationSelector);
    const { scenarios } = useSelector(loansSelector);

    useEffect(() => {
      if (scenarios.clientScoped === null || scenarios.userScoped === null) {
        dispatch(loadScenarios());
      }
    }, [dispatch, scenarios]);

    if (!hasPermission("application-scenarios-view")) return null;

    return (
      <Scenarios
        className={
          className
            ? `page-component-loan-pricing-scenarios ${className}`
            : "page-component-loan-pricing-scenarios"
        }
      >
        {scenarios?.clientScoped && scenarios?.clientScoped?.length > 0 && (
          <PanelTitle>Global Scenarios</PanelTitle>
        )}

        {scenarios.clientScoped?.map((scenario, i) => (
          <Scenario key={i}>
            <Text>
              <AsLink
                onClick={() => {
                  close();
                  dispatch(loadScenario(scenario.fields));
                }}
              >
                {scenario.displayName}
              </AsLink>
            </Text>

            {hasPermission("application-scenarios-modify-client-scoped") && (
              <ButtonGroup className="global">
                <Button>
                  <Icon
                    icon={faTrash}
                    title="Delete this scenario"
                    onClick={() => dispatch(deleteScenario(scenario))}
                  />
                </Button>

                <Button>
                  <Icon
                    icon={faArrowDown}
                    title="Unpublish this scenario so that only its creator can see it"
                    onClick={() =>
                      dispatch(demoteScenarioToUserScope(scenario))
                    }
                  />
                </Button>
              </ButtonGroup>
            )}
          </Scenario>
        ))}

        {scenarios?.userScoped && scenarios?.userScoped?.length > 0 && (
          <PanelTitle>Custom Scenarios</PanelTitle>
        )}

        {scenarios?.userScoped?.map((scenario, i) => (
          <Scenario key={i}>
            <Text
              onClick={() => {
                dispatch(loadScenario(scenario.fields));
                close();
              }}
            >
              <AsLink>{scenario.displayName} </AsLink>
              <SmallText>
                {moment(scenario.createdAt).format("MMM D YYYY, h:mm A")}
              </SmallText>
            </Text>

            {scenario.ownedBy === user.id && (
              <ButtonGroup className="custom">
                <Button>
                  <Icon
                    icon={faTrash}
                    title="Delete this scenario"
                    onClick={() => dispatch(deleteScenario(scenario))}
                  />
                </Button>

                {hasPermission(
                  "application-scenarios-modify-client-scoped",
                ) && (
                  <Button>
                    <Icon
                      icon={faArrowUp}
                      title="Publish this scenario so that all users can see it"
                      onClick={() =>
                        dispatch(promoteScenarioToClientScope(scenario))
                      }
                    />
                  </Button>
                )}
              </ButtonGroup>
            )}
          </Scenario>
        ))}
      </Scenarios>
    );
  },
);
