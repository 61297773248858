import { Set as ISet } from "immutable";
import React from "react";
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: "100%",
      maxWidth: 300,
    },
    label: {
      margin: theme.spacing(1, 0, 0),

      borderColor: "rgba(0, 0, 0, 0.23)",
      borderRadius: "4px 4px 0 0",
      borderStyle: "solid",
      borderWidth: 1,

      padding: "12px 16px",

      color: "#888",
    },
    labelSecondary: {
      float: "right",
      paddingLeft: 8,
    },
    optionList: {
      overflow: "auto",
      height: 250,
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderTop: "none",
      borderRadius: "0 0 4px 4px",
      padding: 0,
    },
    emptyMessage: {
      padding: theme.spacing(4, 0),
      color: "#888",
      textAlign: "center",
    },
    link: {
      color: "inherit",
      textDecoration: "inherit",
    },
  }),
);

type Props<T extends string | number> = {
  label: string;
  className?: string;
  noneSelectedMessage: string;
  width?: string;
  options: T[];
  selected: ISet<T>;
  getOptionLabel: (option: T) => string;
  getOptionLink?: (option: T) => string;
};

export default function MultiSelectListViewer<T extends string | number>({
  label,
  className,
  noneSelectedMessage,
  width,
  options,
  selected,
  getOptionLabel,
  getOptionLink,
}: Props<T>) {
  const classes = useStyles();

  const visibleItems = options.filter((o) => selected.has(o));

  return (
    <Box
      data-selector={label.match(/[^\s\\]/g)?.join("")}
      className={`${classes.container} ${className || ""}`}
      style={{ maxWidth: width ? width : 300 }}
    >
      <Typography className={classes.label}>
        {label}
        <span className={classes.labelSecondary}>{selected.size}</span>
      </Typography>
      <List className={classes.optionList}>
        {visibleItems.length === 0 && (
          <Typography className={classes.emptyMessage}>
            {noneSelectedMessage}
          </Typography>
        )}
        {visibleItems.map((option) => {
          const optionLabel = getOptionLabel(option);

          if (getOptionLink) {
            return (
              <Link
                key={option}
                to={getOptionLink(option)}
                className={classes.link}
              >
                <ListItem key={option} dense button>
                  <ListItemText primary={optionLabel}></ListItemText>
                </ListItem>
              </Link>
            );
          } else {
            return (
              <ListItem key={option} dense>
                <ListItemText primary={optionLabel}></ListItemText>
              </ListItem>
            );
          }
        })}
      </List>
    </Box>
  );
}
