import * as T from "types/engine-types";

export type FieldValueTypeKind = "enum" | "string" | "number" | "duration";

export const ALL_FIELD_VALUE_TYPE_KINDS: FieldValueTypeKind[] = [
  "enum",
  "string",
  "number",
  "duration",
];

export const ALL_NUMBER_FIELD_STYLES: T.NumberFieldStyle[] = [
  "plain",
  "percent",
  "dollar",
];

export const ALL_OBJECT_TYPES: T.ObjectType[] = ["pricing-profile"];

export function getNumberFieldStyleName(style: T.NumberFieldStyle): string {
  switch (style) {
    case "plain":
      return "Plain";
    case "percent":
      return "Percentage";
    case "dollar":
      return "Dollar";
  }
}

export function getObjectTypeName(style: T.ObjectType): string {
  switch (style) {
    case "pricing-profile":
      return "Pricing Profile";
  }
}

export function assertNumberFieldValueType(
  valueType: T.FieldValueType,
  fieldName: string,
): T.FieldValueType.Number {
  if (valueType.type !== "number") {
    throw new Error(
      `expected ${fieldName} field to be a number, found ${valueType.type}`,
    );
  }

  return valueType;
}

export function assertDurationFieldValueType(
  valueType: T.FieldValueType,
  fieldName: string,
): T.FieldValueType.Duration {
  if (valueType.type !== "duration") {
    throw new Error(
      `expected ${fieldName} field to be a number, found ${valueType.type}`,
    );
  }

  return valueType;
}
