import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useVirtual } from "react-virtual";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import * as T from "types/engine-types";
import { localAccessId } from "features/access-id";

export default function PageAccessList({
  products,
}: {
  products: T.DecoratedProductHeader[];
}) {
  const parentRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredProducts, setFilteredProducts] =
    useState<T.DecoratedProductHeader[]>(products);
  const accessId = localAccessId();

  useEffect(() => {
    setFilteredProducts(
      products.filter((p: T.DecoratedProductHeader) => {
        return p.name.toLowerCase().includes(searchTerm.toLowerCase());
      }),
    );
  }, [searchTerm, products]);

  const rowVirtualizer = useVirtual({
    size: filteredProducts?.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, []),
  });

  return (
    <Table
      data-selector="investors-products-list"
      style={{ flex: "1 0 24%", borderRight: "1px solid rgba(0,0,0,.15)" }}
    >
      <TableActions style={{ padding: "8px" }}>
        <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
          <SearchInput
            label="products"
            requireDispatch={false}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <ResultsCount>
          {filteredProducts.length} <VisibilityIcon />
          <span className="separator">|</span>
          {products.length} <CheckIcon />
          <span className="separator">|</span>
          {products.length} Total
        </ResultsCount>
      </TableActions>

      <TableHeader>
        <TableHeaderCell style={{ flexBasis: "100%" }}>
          Products
        </TableHeaderCell>
      </TableHeader>

      {filteredProducts.length ? (
        <TableBodyWrapper ref={parentRef}>
          <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
            {rowVirtualizer.virtualItems.map((virtualRow) => (
              <TableRow
                key={virtualRow.index}
                style={{
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <TableCell style={{ flexBasis: "100%" }}>
                  <Link
                    data-selector="rendered-product"
                    to={`/c/${accessId}/products/${
                      filteredProducts[virtualRow.index].id
                    }`}
                  >
                    {filteredProducts[virtualRow.index].name}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableBodyWrapper>
      ) : (
        <p style={{ marginTop: "14px", textAlign: "center", opacity: 0.65 }}>
          No products have been assigned to this investor.
        </p>
      )}
    </Table>
  );
}
