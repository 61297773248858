import React, { ReactNode } from "react";
import { ApplicationContent } from "./styles";

export default React.memo(
  ({ children, onClick }: { onClick: () => void; children: ReactNode }) => {
    return (
      <ApplicationContent className="application-content" onClick={onClick}>
        {children}
      </ApplicationContent>
    );
  },
);
