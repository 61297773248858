import React, { useState } from "react";
import { usePropertySetter } from "features/utils";
import { Box, Button, TextField } from "@material-ui/core";
import * as Api from "api";
import Loader from "react-loader";
import * as T from "types/engine-types";
import { SecondaryTitle } from "design/atoms/typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import { patternMatchesKey, massagedPatterns } from "features/object-patterns";
import { localAccessId } from "features/access-id";
interface State {
  key: string;
  keyContains: string;
  keyContainsOptional: string;
  name: string;
}

function newState(): State {
  return {
    key: "",
    keyContains: "",
    keyContainsOptional: "",
    name: "",
  };
}

interface Props {
  patterns: T.ObjectPattern[];
  setter: (patterns: T.ObjectPattern[]) => void;
}

export const PatternPage = React.memo(() => {
  const [patternsLoad, setPatternsLoad] = Api.Admin.useObjectPatterns();

  if (patternsLoad.status === "error") {
    return <>"Error"</>;
  }

  if (patternsLoad.status === "loading") {
    return <Loader loaded={false} />;
  }

  return (
    <LoadedPatternPage patterns={patternsLoad.value} setter={setPatternsLoad} />
  );
});

export const LoadedPatternPage = React.memo((props: Props) => {
  const [state, setState] = useState(newState());
  const setKey = usePropertySetter(setState, "key");
  const setKeyContains = usePropertySetter(setState, "keyContains");
  const setKeyContainsOptional = usePropertySetter(
    setState,
    "keyContainsOptional",
  );
  const setName = usePropertySetter(setState, "name");
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const pattern = await Api.Admin.createObjectPattern({
      keyContains: state.keyContains,
      keyContainsOptional: state.keyContainsOptional,
      name: state.name,
    });
    props.setter([...props.patterns, pattern]);
  }

  const patternCount = props.patterns.length;
  const key = state.key;
  const matchingPatterns = props.patterns.filter((p) =>
    patternMatchesKey(p, key),
  );
  const accessId: string = localAccessId() || "super";
  const setter = props.setter;
  const patterns = props.patterns;
  function generateDeleteFunction(id: T.ObjectPatternId): () => void {
    return async () => {
      await Api.Admin.objectPatternDelete(id);
      setter(patterns.filter((p) => p.id !== id));
    };
  }

  return (
    <div>
      <Button
        component={Link}
        to={`/c/${accessId}/__admin/investor-automations`}
        variant="outlined"
        startIcon={<ArrowBackIcon />}
      >
        Back to Investor Automations
      </Button>
      <Box px={2} my={2}>
        <SecondaryTitle>
          Matches {matchingPatterns.length} out of {patternCount} Object
          Patterns
        </SecondaryTitle>
        <form onSubmit={(e) => handleSubmit(e)}>
          {InputField("Example Key", state.key, (e) => {
            setKey(e.target.value);
          })}
          {InputField("Key Contains", state.keyContains, (e) => {
            setKeyContains(e.target.value);
          })}
          {InputField(
            "Key Contains [Optional]",
            state.keyContainsOptional,
            (e) => {
              setKeyContainsOptional(e.target.value);
            },
          )}
          {InputField("Name", state.name, (e) => {
            setName(e.target.value);
          })}
          <input type="submit" value="Submit" />
        </form>
      </Box>
      <Box px={2} my={2}>
        <SecondaryTitle>Patterns</SecondaryTitle>
        <Table
          patterns={massagedPatterns(
            state.key ? matchingPatterns : props.patterns,
          )}
          generateDeleteFunction={generateDeleteFunction}
        />
      </Box>
    </div>
  );
});

function InputField(
  label: string,
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
) {
  return (
    <Box my={1}>
      <TextField
        style={{ width: "600px" }}
        label={label}
        variant="outlined"
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
        }}
      />
    </Box>
  );
}

interface TableProps {
  patterns: T.ObjectPattern[];
  generateDeleteFunction: (id: T.ObjectPatternId) => () => void;
}

export const useTableStyles = makeStyles((t) =>
  createStyles({
    table: {
      border: "1px solid #e0e0e0",
      borderCollapse: "collapse",
    },
    columnLabel: {
      borderLeft: "1px solid #e0e0e0",
      padding: "6px",
    },
    rowLabel: {
      borderTop: "1px solid #e0e0e0",
      padding: "0 12px 0 18px",
      textAlign: "right",
    },
    field: {
      borderTop: "1px solid #e0e0e0",
      padding: "0 12px 0 18px",
    },
  }),
);

const Table = React.memo((props: TableProps) => {
  const C = useTableStyles();
  const accessId = localAccessId();
  return (
    <table className={C.table}>
      <thead>
        <tr>
          <th className={C.columnLabel}>investors link</th>
          <th className={C.columnLabel}>name </th>
          <th className={C.columnLabel}>id</th>
          <th className={C.columnLabel}>keyContains</th>
          <th className={C.columnLabel}>keyContainsOptional</th>
          <th className={C.columnLabel}> </th>
        </tr>
      </thead>
      {props.patterns.map(({ name, id, keyContains, keyContainsOptional }) => (
        <tr>
          <td className={C.rowLabel}>
            <Button
              component={Link}
              to={`/c/${accessId}/__admin/investor-automations?patternId=${id}`}
            >
              {name} investors
            </Button>
          </td>
          <td className={C.field}>{name}</td>
          <td className={C.field}>{id}</td>
          <td className={C.field}>{keyContains}</td>
          <td className={C.field}>{keyContainsOptional}</td>
          <td className={C.field}>
            <Button
              style={{ marginRight: 16 }}
              // variant="outlined"
              startIcon={<DeleteIcon />}
              disabled={false}
              onClick={props.generateDeleteFunction(id)}
            >
              Delete
            </Button>
          </td>
        </tr>
      ))}
    </table>
  );
});
