import { expandedConfigSelector } from "features/application-initialization";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import MappingEditor from "design/organisms/mapping-editor";

const EncompassIncomingMappingsPage = React.memo(() => {
  const expandedConfigMap = useSelector(expandedConfigSelector);

  const targets = useMemo(() => {
    return expandedConfigMap.creditApplicationFields
      .filter((field) => field.valueType.type !== "header")
      .map((field) => ({
        id: field.id,
        name: field.name,
      }));
  }, [expandedConfigMap.creditApplicationFields]);

  return (
    <MappingEditor
      mappingPurpose={"encompass-from-transaction-origin"}
      targets={targets}
    />
  );
});

export default EncompassIncomingMappingsPage;
