import React, { ReactElement } from "react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Text } from "design/atoms/typography";
import { Messages } from "./styles";
import whitespace from "design/subatomics/whitespace";

const MessageSection = React.memo(
  ({ icon, children }: { icon?: ReactElement; children: React.ReactNode }) => {
    return (
      <Messages>
        <Text style={{ display: "flex" }}>
          {icon || <HelpOutlineIcon />}
          <div style={{ marginLeft: whitespace("less") }}>{children}</div>
        </Text>
      </Messages>
    );
  },
);

export default MessageSection;
