import React, { useEffect, useState } from "react";
import { ProductDetailActionPanel } from "./styles";
import Button from "design/atoms/button";
import ActionButtons from "../action-buttons";
import { useHistory, useLocation } from "react-router-dom";
import { SmallText } from "design/atoms/typography";
import {
  faCaretLeft,
  faCaretRight,
  faEllipsisV,
  faFileExport,
  faMaximize,
  faMinimize,
  faPlus,
  faMinus,
  faMagnifyingGlass,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "design/atoms/icon";
import Popover from "design/layout/popover";
import { LinkList } from "design/molecules/link-list/styles";
import IconLink from "design/atoms/icon-link";
import { filteredSummaryProductsSelector } from "features/pricing-summaries";
import { useDispatch, useSelector } from "react-redux";
import * as T from "types/engine-types";
import iFrameMessageTransport from "features/iframe-message-transport";
import {
  nonNullApplicationInitializationSelector,
  expandedConfigSelector,
} from "features/application-initialization";
import { investorsSelector } from "features/investors";
import { useVisibleFields } from "features/roles";
import { UiValidationError } from "features/utils";
import { getLowest } from "features/pricing-summaries";
import { createManualOpenEvent } from "design/molecules/accordion";
import {
  loansSelector,
  resolveEffectiveFieldValues,
  addLoanToCompare,
  removeLoanFromCompare,
  logCompareBackUrl,
  clearLoanToCompare,
} from "features/loans";

export default React.memo(
  ({
    productResultLoading,
    product,
    scenario,
    compareMode = false,
    productId,
    setPricingSelection,
    setForceCollapseResults,
    forceCollapseResults,
    loansToHide,
    request,
    result,
  }: {
    productResultLoading?: boolean;
    product?: T.ProductHeader;
    scenario?: T.PriceScenarioResult;
    compareMode?: boolean;
    productId?: T.ProductId;
    setPricingSelection?: React.Dispatch<React.SetStateAction<string | null>>;
    setForceCollapseResults?: React.Dispatch<React.SetStateAction<boolean>>;
    forceCollapseResults?: boolean;
    loansToHide?: string[];
    request?: T.ProductExecutionRequest;
    result?: T.ProductExecutionResult;
  }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [loadWarning, setLoadWarning] = useState<string>(
      "Pricing will appear below once it's loaded.",
    );
    const config = useSelector(expandedConfigSelector);
    const investorsState = useSelector(investorsSelector);
    const loggedInInfo = useSelector(nonNullApplicationInitializationSelector);
    const userInfo = { user: loggedInInfo.user, client: loggedInInfo.client };
    const history = useHistory();
    const summary = useSelector(filteredSummaryProductsSelector);
    const currentProduct = summary.find(
      (product) => product.productId === productId,
    );
    const currentIndex = summary.indexOf(currentProduct!);
    const nextProduct = summary[currentIndex + 1];
    const previousProduct = summary[currentIndex - 1];
    const {
      client: { accessId },
    } = useSelector(nonNullApplicationInitializationSelector);

    const backToProducts = () =>
      history.push(
        `/c/${accessId}/v2/loan-pricing?activeView=products/${location.hash}`,
      );

    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);

    const {
      priceLockingConfig,
      floatRequestConfig,
      currentFormValues,
      loansToCompare,
      compareBackUrl,
    } = useSelector(loansSelector);

    const { myRole, myDefaultFieldValues, myPricingProfile } = useSelector(
      nonNullApplicationInitializationSelector,
    );
    const investor = investorsState.investors.find(
      (i) => i.id === product?.investorId,
    );
    const hasVisibleField = useVisibleFields();

    useEffect(() => {
      setTimeout(() => {
        setLoadWarning("");
      }, loansToCompare.length * 1000);
    });

    const stopCompare = () => {
      if (compareBackUrl) {
        history.push(compareBackUrl.replace(window.location.origin, ""));
      } else {
        history.push(`/c/${accessId}/v2/loan-pricing?activeView=products`);
      }
    };

    if (queryParams.get("inCompareView") === "true") {
      return <></>;
    }

    return (
      <ProductDetailActionPanel className="page-loan-pricing-product-detail-action-panel">
        <ActionButtons>
          {compareMode && (
            <>
              <SmallText>
                {loadWarning
                  ? loadWarning
                  : `Comparing ${
                      loansToCompare.length - (loansToHide?.length || 0)
                    } loans. `}
              </SmallText>

              <Button grow={false} className="compare" onClick={stopCompare}>
                Back to Loan Pricing
              </Button>
            </>
          )}

          {forceCollapseResults && (
            <Button
              grow={false}
              disabled={productResultLoading}
              onClick={() => {
                backToProducts();
              }}
            >
              Back to Products
            </Button>
          )}

          {!compareMode && queryParams.get("inCompareView") !== "true" && (
            <>
              {!forceCollapseResults && (
                <Button
                  grow={true}
                  disabled={productResultLoading}
                  onClick={() => {
                    backToProducts();
                  }}
                >
                  Close Product
                </Button>
              )}

              <Button
                title="Previous Product"
                grow={false}
                onClick={() => {
                  createManualOpenEvent(previousProduct?.productId as string);

                  const lowest = getLowest(previousProduct);

                  if (
                    lowest?.adjustedRate &&
                    lowest.adjustedRateLockPeriod?.count
                  ) {
                    setPricingSelection && setPricingSelection(null);
                    history.push(
                      `/c/${accessId}/v2/loan-pricing/products/${previousProduct.productId}/${lowest.adjustedRate}/${lowest.adjustedRateLockPeriod.count}${location.hash}`,
                    );
                  } else {
                    history.push(
                      `/c/${accessId}/v2/loan-pricing/products/${previousProduct.productId}${location.hash}`,
                    );
                  }
                }}
                disabled={productResultLoading || !!!summary[currentIndex - 1]}
              >
                <Icon icon={faCaretLeft} />
              </Button>

              <SmallText
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {currentIndex + 1} of {summary.length}
              </SmallText>

              <Button
                title="Next Product"
                grow={false}
                onClick={() => {
                  createManualOpenEvent(nextProduct?.productId as string);

                  const lowest = getLowest(nextProduct);

                  if (
                    lowest?.adjustedRate &&
                    lowest.adjustedRateLockPeriod?.count
                  ) {
                    setPricingSelection && setPricingSelection(null);
                    history.push(
                      `/c/${accessId}/v2/loan-pricing/products/${nextProduct.productId}/${lowest.adjustedRate}/${lowest.adjustedRateLockPeriod.count}${location.hash}`,
                    );
                  } else {
                    history.push(
                      `/c/${accessId}/v2/loan-pricing/products/${nextProduct.productId}${location.hash}`,
                    );
                  }
                }}
                disabled={productResultLoading || !!!summary[currentIndex + 1]}
              >
                <Icon icon={faCaretRight} />
              </Button>

              {loansToCompare.includes(window.location.href) && (
                <Button
                  title="Remove from Compare"
                  grow={false}
                  onClick={() => {
                    dispatch(
                      removeLoanFromCompare({ loanUrl: window.location.href }),
                    );
                  }}
                >
                  <Icon icon={faMinus} />
                </Button>
              )}

              {!loansToCompare.includes(window.location.href) &&
                loansToCompare?.length < 8 && (
                  <Button
                    title="Add to Compare"
                    grow={false}
                    onClick={() => {
                      dispatch(
                        addLoanToCompare({ loanUrl: window.location.href }),
                      );
                    }}
                  >
                    <Icon icon={faPlus} />
                  </Button>
                )}

              {loansToCompare.length > 1 && (
                <Button
                  className="hide-for-mobile"
                  grow={true}
                  onClick={() => {
                    const encodedCompareLoans = loansToCompare.map((l) => {
                      const hashed = btoa(l);
                      return encodeURIComponent(hashed);
                    });

                    dispatch(logCompareBackUrl({ url: window.location.href }));

                    history.push(
                      `/c/${accessId}/v2/loan-pricing/compare#${encodedCompareLoans.join(
                        ",",
                      )}`,
                    );
                  }}
                >
                  <Icon className="hide-for-mobile" icon={faMagnifyingGlass} />{" "}
                  Compare {loansToCompare.length}
                </Button>
              )}

              <Button
                isPrimary={currentProduct?.status === "approved"}
                className="hide-for-mobile"
                onClick={() => {
                  window.print();
                }}
              >
                Export to PDF
              </Button>

              <Popover
                trigger={
                  <Button grow={false}>
                    <Icon icon={faEllipsisV} />
                  </Button>
                }
                content={
                  <>
                    {!compareMode && (
                      <LinkList>
                        {loansToCompare.length > 1 && (
                          <IconLink
                            inline={true}
                            icon={<Icon icon={faMagnifyingGlass} />}
                            onClick={() => {
                              const encodedCompareLoans = loansToCompare.map(
                                (l) => {
                                  const hashed = btoa(l);
                                  return encodeURIComponent(hashed);
                                },
                              );

                              dispatch(
                                logCompareBackUrl({
                                  url: window.location.href,
                                }),
                              );

                              history.push(
                                `/c/${accessId}/v2/loan-pricing/compare#${encodedCompareLoans.join(
                                  ",",
                                )}`,
                              );
                            }}
                          >
                            Compare ({loansToCompare.length})
                          </IconLink>
                        )}

                        {!!loansToCompare.length && (
                          <IconLink
                            inline={true}
                            icon={<Icon icon={faBan} />}
                            onClick={() => dispatch(clearLoanToCompare())}
                          >
                            Clear Compare List
                          </IconLink>
                        )}

                        {priceLockingConfig.lockingEnabled && (
                          <IconLink
                            className="hide-for-mobile"
                            inline={true}
                            icon={<Icon icon={faFileExport} />}
                            onClick={() => {
                              if (
                                currentFormValues != null &&
                                request !== undefined &&
                                result !== undefined
                              ) {
                              } else {
                                console.warn(
                                  "currentFormValues, request, or response was not set while sending price lock message",
                                );
                                return;
                              }

                              if (
                                investor &&
                                product &&
                                scenario &&
                                currentFormValues &&
                                myRole &&
                                myPricingProfile
                              ) {
                                // Resolve fields based on the input field values, field
                                // conditions, and default values. For more details,
                                // see DEV-1137: https://loanpass.atlassian.net/browse/DEV-1137
                                const effectiveFieldValues =
                                  resolveEffectiveFieldValues(
                                    config.creditApplicationFields,
                                    currentFormValues,
                                    myDefaultFieldValues ?? [],
                                  );
                                iFrameMessageTransport.postMessage({
                                  message: "price-lock",
                                  role: {
                                    id: myRole.id,
                                    name: myRole.name,
                                  },
                                  pricingProfile: myPricingProfile,
                                  investor,
                                  product,
                                  productSpecifications:
                                    config.productFields.filter((field) =>
                                      hasVisibleField(field.id),
                                    ),
                                  scenario,
                                  executionRequest: request,
                                  productExecutionResult: result,
                                  creditApplicationFields: effectiveFieldValues,
                                });
                              } else {
                                throw new UiValidationError(
                                  "Select at least one pricing table to import",
                                );
                              }
                            }}
                          >
                            {priceLockingConfig.lockRequestLabel}
                          </IconLink>
                        )}

                        {floatRequestConfig.floatRequestsEnabled && (
                          <IconLink
                            className="hide-for-mobile"
                            inline={true}
                            icon={<Icon icon={faFileExport} />}
                            onClick={() => {
                              if (
                                currentFormValues != null &&
                                request !== undefined &&
                                result !== undefined
                              ) {
                              } else {
                                console.warn(
                                  "currentFormValues, request, or response was not set while sending float request message",
                                );
                                return;
                              }

                              if (
                                investor &&
                                product &&
                                scenario &&
                                currentFormValues &&
                                myRole &&
                                myPricingProfile
                              ) {
                                // Resolve fields based on the input field values, field
                                // conditions, and default values. For more details,
                                // see DEV-1137: https://loanpass.atlassian.net/browse/DEV-1137
                                const effectiveFieldValues =
                                  resolveEffectiveFieldValues(
                                    config.creditApplicationFields,
                                    currentFormValues,
                                    myDefaultFieldValues ?? [],
                                  );
                                iFrameMessageTransport.postMessage({
                                  message: "float-request",
                                  role: {
                                    id: myRole.id,
                                    name: myRole.name,
                                  },
                                  pricingProfile: myPricingProfile,
                                  investor,
                                  product,
                                  productSpecifications:
                                    config.productFields.filter((field) =>
                                      hasVisibleField(field.id),
                                    ),
                                  scenario,
                                  executionRequest: request,
                                  productExecutionResult: result,
                                  creditApplicationFields: effectiveFieldValues,
                                });
                              } else {
                                throw new UiValidationError(
                                  "Select at least one pricing table to import",
                                );
                              }
                            }}
                          >
                            {floatRequestConfig.floatRequestLabel}
                          </IconLink>
                        )}

                        {forceCollapseResults && (
                          <IconLink
                            className="hide-for-mobile"
                            inline={true}
                            icon={<Icon icon={faMaximize} />}
                            onClick={() => {
                              if (setForceCollapseResults) {
                                localStorage.setItem(
                                  "loanPASS::setForceCollapseResults",
                                  "false",
                                );
                                setForceCollapseResults(false);
                              }
                            }}
                          >
                            Expand Product List
                          </IconLink>
                        )}

                        {!forceCollapseResults && (
                          <IconLink
                            className="hide-for-mobile"
                            inline={true}
                            icon={<Icon icon={faMinimize} />}
                            onClick={() => {
                              if (setForceCollapseResults) {
                                localStorage.setItem(
                                  "loanPASS::setForceCollapseResults",
                                  "true",
                                );
                                setForceCollapseResults(true);
                              }
                            }}
                          >
                            Collapse Product List
                          </IconLink>
                        )}

                        <IconLink
                          className="hide-for-desktop"
                          external={true}
                          to={`mailto:${userInfo.user.emailAddress}?subject=Pricing Reminder&body=${window.location.href}`}
                          inline={true}
                          icon={<Icon icon={faFileExport} />}
                        >
                          Share Link
                        </IconLink>
                      </LinkList>
                    )}
                  </>
                }
              />
            </>
          )}
        </ActionButtons>
      </ProductDetailActionPanel>
    );
  },
);
