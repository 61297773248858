/** generates a random 30-byte sequence and encodes it as a 40-character base64 string*/
// This used to come from an npm package that had no type declarations, so the return type was
// inferred as `any`. To keep existing code working, I'm declaring the return type as `any` for now
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function generateSafeId(): any {
  const buffer = new Uint8Array(30);
  crypto.getRandomValues(buffer);
  return (
    // replace + and / with - and _, to match previous behaviour with the base64url library
    base64EncArr(buffer)
      .replace(/\+/g, "-")
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .replace(/\//g, "_") as any
  );
}

// `uint6ToB64` and `base64EncArr` are taken from code examples in the MDN docs, source here:
// https://github.com/mdn/content/blob/37cac3ae9980584d66ebc001bf012a8fd7d8d642/files/en-us/glossary/base64/index.md?plain=1#L136-L171
// license for code examples appears to be MIT or CC0 depending on the date it was added:
// https://github.com/mdn/content/blob/37cac3ae9980584d66ebc001bf012a8fd7d8d642/LICENSE.md

function uint6ToB64(nUint6: number): number {
  return nUint6 < 26
    ? nUint6 + 65
    : nUint6 < 52
    ? nUint6 + 71
    : nUint6 < 62
    ? nUint6 - 4
    : nUint6 === 62
    ? 43
    : nUint6 === 63
    ? 47
    : 65;
}
function base64EncArr(aBytes: Uint8Array): string {
  let nMod3 = 2,
    sB64Enc = "";
  for (let nLen = aBytes.length, nUint24 = 0, nIdx = 0; nIdx < nLen; nIdx++) {
    nMod3 = nIdx % 3;
    if (nIdx > 0 && ((nIdx * 4) / 3) % 76 === 0) {
      sB64Enc += "\r\n";
    }
    nUint24 |= aBytes[nIdx] << ((16 >>> nMod3) & 24);
    if (nMod3 === 2 || aBytes.length - nIdx === 1) {
      sB64Enc += String.fromCodePoint(
        uint6ToB64((nUint24 >>> 18) & 63),
        uint6ToB64((nUint24 >>> 12) & 63),
        uint6ToB64((nUint24 >>> 6) & 63),
        uint6ToB64(nUint24 & 63),
      );
      nUint24 = 0;
    }
  }
  return (
    sB64Enc.substr(0, sB64Enc.length - 2 + nMod3) +
    (nMod3 === 2 ? "" : nMod3 === 1 ? "=" : "==")
  );
}
