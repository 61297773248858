import { createStyles, makeStyles } from "@material-ui/core/styles";
import color from "design/subatomics/colors";

export const colors = {
  approvedFg: "white",
  approvedBg: "hsl(122.4, 39.4%, 49.2%)", // palette: success.main
  approvedBgHover: "hsl(122.4, 39.4%, 45%)",

  missingDataFg: "hsl(0, 0%, 46%)", // palette: text.secondary
  missingDataBg: "white",
  missingDataBgHover: "hsl(0, 0%, 95%)",

  errorFg: "white",
  errorBg: "hsl(4.1, 89.6%, 58.4%)", // palette: error.main
  errorBgHover: "hsl(4.1, 89.6%, 53%)",

  rejectedFg: "white",
  rejectedBg: "hsl(0, 0%, 85%)", // palette: text.secondary
  rejectedBgHover: "hsl(0, 0%, 40%)",
};

export const useStyles = makeStyles((t) =>
  createStyles({
    pageContainer: {
      display: "flex",
      height: "100%",
      overflow: "hidden",
    },
    fieldsColumnContainer: {
      overflowX: "hidden",
      overflowY: "auto",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      minWidth: 250,
      height: "100%",
      background: "white",
    },
    fieldsColumn: {
      display: "flex",
      flexDirection: "column",
      padding: t.spacing(1),
    },
    scenariosMenuDivider: {
      color: "rgba(0, 0, 0, 0.4)",
      fontSize: "0.8em",
      fontWeight: "bold",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "1em 0 0.25em 1em",
    },
    scenarioDate: {
      color: "rgba(0, 0, 0, 0.4)",
      fontSize: "0.7em",
      marginLeft: "0.7em",
    },
    snackbar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "-12px",
      padding: "8px 16px",
      borderRadius: "4px",
      background: "#fff",
      border: "1px solid red",
    },
  }),
);

export const useTableStyles = makeStyles((t) =>
  createStyles({
    table: {
      border: "1px solid #e0e0e0",
      borderCollapse: "collapse",
    },
    columnLabel: {
      borderLeft: "1px solid #e0e0e0",
      padding: "6px 0",
    },
    fieldNameColumnLabel: {
      borderLeft: "1px solid #e0e0e0",
      padding: "6px",
    },
    rowLabel: {
      borderTop: "1px solid #e0e0e0",
      padding: "0 12px 0 18px",
      textAlign: "right",
    },
  }),
);

export const useTableCellStyles = makeStyles((t) =>
  createStyles({
    cell: {
      borderLeft: "1px solid #e0e0e0",
      borderTop: "1px solid #e0e0e0",
      background: "white",
      padding: "0 8px",
      fontWeight: 700,
      textAlign: "center",

      "& > div": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 84,
        minHeight: 32,
      },
    },

    scenario: {
      cursor: "pointer",
      "&:hover": {
        background: "hsl(0, 0%, 95%)",
      },
    },

    approved: {
      color: color({ color: "green", shade: 5 }),
    },
    reviewRequired: {
      color: color({ color: "orange", shade: 5 }),
    },
    rejected: {
      color: "hsl(0, 0%, 46%)",
    },
    missingData: {
      color: "hsl(0, 0%, 46%)",
    },
    error: {
      background: colors.errorBg,
      color: colors.errorFg,
      textAlign: "center",

      "&:hover": {
        background: colors.errorBgHover,
      },
    },
    noScenario: {
      cursor: "default",
      fontWeight: 400,
      textAlign: "center",

      "&:hover": {
        background: "white",
      },
    },
    invalidValue: {
      cursor: "default",
      fontWeight: 400,
      textAlign: "center",

      "&:hover": {
        background: "white",
      },
    },
  }),
);

export const useCauseLinkStyles = makeStyles((t) =>
  createStyles({
    causeLink: {
      color: "black",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
      "& > .MuiSvgIcon-root": {
        fontSize: "1rem",
      },
    },
  }),
);

export const useFieldsColumnStyles = makeStyles((t) =>
  createStyles({
    pageContainer: {
      display: "flex",
      height: "100%",
      overflow: "hidden",
    },
    fieldsColumnContainer: {
      overflowX: "hidden",
      overflowY: "auto",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      minWidth: 367,
      height: "100%",
      background: "white",
    },
    fieldsColumn: {
      display: "flex",
      flexDirection: "column",
      padding: t.spacing(1),
    },
    scenariosMenuDivider: {
      color: "rgba(0, 0, 0, 0.4)",
      fontSize: "0.8em",
      fontWeight: "bold",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "1em 0 0.25em 1em",
    },
    scenarioDate: {
      color: "rgba(0, 0, 0, 0.4)",
      fontSize: "0.7em",
      marginLeft: "0.7em",
    },
  }),
);

export const useMiddotIconStyles = makeStyles((t) =>
  createStyles({
    middotIcon: {
      display: "inline-block",
      width: 24,
      height: 24,

      "& > div": {
        marginTop: -5,

        width: 24,
        height: 24,

        fontSize: 24,
        fontWeight: 400,
        textAlign: "center",
      },
    },
  }),
);

export const useTableRowStyles = makeStyles((t) =>
  createStyles({
    cell: {
      borderLeft: "1px solid #e0e0e0",
      borderTop: "1px solid #e0e0e0",
      background: "inherit",
      padding: "0 8px",
      cursor: "inherit",
      fontWeight: 700,

      "& > div": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 84,
        minHeight: 32,
      },
    },
    row: {
      "&:hover": {
        cursor: "pointer",
        background: "hsl(0, 0%, 95%)",
      },
    },
    approved: {
      color: color({ color: "green", shade: 5 }),
    },
    reviewRequired: {
      color: color({ color: "orange", shade: 5 }),
    },
    rejected: {
      color: "hsl(0, 0%, 46%)",
    },
    missingData: {
      color: "hsl(0, 0%, 46%)",
    },
    error: {
      background: colors.errorBg,
      color: colors.errorFg,
      textAlign: "center",

      "&:hover": {
        background: colors.errorBgHover,
      },
    },
  }),
);

export const usePriceScenarioTableStyles = makeStyles((t) =>
  createStyles({
    table: {
      border: "1px solid #e0e0e0",
      borderCollapse: "collapse",
    },
    columnLabel: {
      borderLeft: "1px solid #e0e0e0",
      padding: "6px 0",
    },
    rowLabel: {
      borderTop: "1px solid #e0e0e0",
      padding: "0 12px 0 18px",
      textAlign: "right",
    },
  }),
);

export const usePriceScenarioTableCellStyles = makeStyles((t) =>
  createStyles({
    cell: {
      borderLeft: "1px solid #e0e0e0",
      borderTop: "1px solid #e0e0e0",
      background: "white",
      padding: "3px 6px",
      cursor: "pointer",
      fontWeight: 700,
      textAlign: "center",

      "&:hover": {
        background: "hsl(0, 0%, 95%)",
      },

      "& > div": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 84,
        minHeight: 32,
      },
    },
    approved: {
      color: color({ color: "green", shade: 5 }),
    },
    reviewRequired: {
      color: color({ color: "orange", shade: 5 }),
    },
    rejected: {
      color: "hsl(0, 0%, 46%)",
    },
    missingData: {
      color: "hsl(0, 0%, 46%)",
    },
    error: {
      background: colors.errorBg,
      color: colors.errorFg,
      textAlign: "center",

      "&:hover": {
        background: colors.errorBgHover,
      },
    },
    noScenario: {
      cursor: "default",
      fontWeight: 400,
      textAlign: "center",

      "&:hover": {
        background: "white",
      },
    },
  }),
);
