import { Set as ISet } from "immutable";
import React, { useCallback } from "react";
import { Box, CircularProgress } from "@material-ui/core";

import * as Api from "api";
import { Configuration } from "config";
import * as T from "types/engine-types";

export const DataTableLookupViewer = React.memo(
  ({
    lookup,
    environment,
    config,
  }: {
    lookup: T.RuleDataTableLookup;
    environment: ISet<T.FieldId>;
    config: Configuration;
  }) => {
    const [tablesLoad] = Api.useDataTables();
    const [tableLoad] = Api.useDataTable(lookup.tableId);

    if (tablesLoad.status === "error" || tableLoad.status === "error") {
      return <Box p={3}>Error loading data table information</Box>;
    }

    if (tablesLoad.status === "loading" || tableLoad.status === "loading") {
      return (
        <Box p={3}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <LoadedDataTableLookupViewer
        lookup={lookup}
        environment={environment}
        config={config}
        table={tableLoad.value}
        tables={tablesLoad.value}
      />
    );
  },
);

const LoadedDataTableLookupViewer = React.memo(
  ({
    lookup,
    environment,
    config,
    table,
    tables,
  }: {
    lookup: T.RuleDataTableLookup;
    environment: ISet<T.FieldId>;
    config: Configuration;
    table: T.DataTable;
    tables: readonly T.DataTableHeader[];
  }) => {
    return (
      <Box pt={1}>
        <Box fontSize="16px">
          In the data table <strong>{table.name}</strong>, find the first row
          where:
        </Box>

        {table && (
          <>
            <Box>
              <ul>
                {lookup.predicates.map((predicate, predicateIndex) => {
                  return (
                    <li key={predicateIndex}>
                      <DataTableColumnPredicateViewer
                        predicate={predicate}
                        table={table}
                        environment={environment}
                        config={config}
                      />
                    </li>
                  );
                })}
              </ul>
            </Box>

            <Box mt={2} mb={1} fontSize="16px">
              Save the values from that row into these new fields:
            </Box>
            {lookup.fields.length === 0 && (
              <Box px={3} py={2} color="text.secondary">
                No output fields.
              </Box>
            )}
            {lookup.fields.length > 0 && (
              <Box>
                <ul>
                  {lookup.fields.map((field, fieldIndex) => {
                    return (
                      <li key={field.id}>
                        <DataTableLookupFieldViewer
                          field={field}
                          table={table}
                        />
                      </li>
                    );
                  })}
                </ul>
              </Box>
            )}
          </>
        )}
      </Box>
    );
  },
);

function getTableColumnPredicateKindName(
  kind: T.DataTableColumnPredicate["kind"],
): string {
  switch (kind) {
    case "equals":
      return "equals";
    case "in-range":
      return "is between";
  }
}

const DataTableColumnPredicateViewer = React.memo(
  ({
    predicate,
    table,
    environment,
    config,
  }: {
    predicate: T.DataTableColumnPredicate;
    table: T.DataTable;
    environment: ISet<T.FieldId>;
    config: Configuration;
  }) => {
    if (predicate.expression.kind !== "field-value") {
      throw new Error(
        "Unexpected expression inside data table column predicate: " +
          predicate.expression.kind,
      );
    }

    const fieldId = predicate.expression.fieldId;
    const field =
      (environment.has(fieldId) && config.allFieldsById.get(fieldId)) || null;

    const getColumnName = useCallback(
      (id: T.DataTableColumnId) =>
        table.columnDefs.find((c) => c.id === id)?.name ||
        "<Unknown Table Column>",
      [table],
    );

    return (
      <Box>
        {field?.name || "<Unknown Field>"}{" "}
        {getTableColumnPredicateKindName(predicate.kind)}{" "}
        {predicate.kind === "equals" && <>{getColumnName(predicate.other)}</>}
        {predicate.kind === "in-range" && (
          <>
            <ColumnRangeBoundViewer
              bound={predicate.bottom}
              getColumnName={getColumnName}
            />
            {" and "}
            <ColumnRangeBoundViewer
              bound={predicate.top}
              getColumnName={getColumnName}
            />
          </>
        )}
      </Box>
    );
  },
);

const ColumnRangeBoundViewer = React.memo(
  ({
    bound,
    getColumnName,
  }: {
    bound: T.RangeBound<T.DataTableColumnId>;
    getColumnName: (id: T.DataTableColumnId) => string;
  }) => {
    if (bound.type !== "included") {
      return <>Unsupported range bound type</>;
    }

    return <strong>{getColumnName(bound.value)}</strong>;
  },
);

const DataTableLookupFieldViewer = React.memo(
  ({
    field,
    table,
  }: {
    field: T.RuleDataTableLookupFieldDefinition;
    table: T.DataTable;
  }) => {
    const columnName =
      table.columnDefs.find((c) => c.id === field.columnId)?.name ||
      "<Unknown Table Column>";

    return (
      <Box>
        Use column <strong>{columnName}</strong> as a field called{" "}
        <strong>{field.name}</strong>
      </Box>
    );
  },
);
