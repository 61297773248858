import { Map as IMap } from "immutable";
import { importFieldValueMapping } from "../fields/import";
import { importInvestorId } from "../investors/import";
import { ObjectDetailsByKey } from "../rules/import";
import * as T from "types/engine-types";

export function importProduct(
  enumTypesByKey: IMap<string, T.EnumType>,
  allFieldIdsByKey: IMap<string, T.FieldId>,
  investorsByKey: IMap<string, T.InvestorHeader>,
  objectDetails: ObjectDetailsByKey,
  oldProduct: T.Product | null,
  product: T.NewProduct,
): T.Product {
  return {
    id: oldProduct?.id || ("" as T.ProductId),
    name: product.name,
    code: product.code,
    description: product.description,
    activationTimestamp: product.activationTimestamp,
    deactivationTimestamp: product.deactivationTimestamp,
    fieldValues: product.fieldValues.map((m) =>
      importFieldValueMapping(
        enumTypesByKey,
        allFieldIdsByKey,
        objectDetails,
        m,
      ),
    ),
    investorId: importInvestorId(investorsByKey, product.investorId),
    ruleIds: [], // Linked to rules in a separate step
  };
}
