import React, { useEffect, ReactNode } from "react";
import { useAppDispatch } from "features/store";
import {
  applicationInitializationSelector,
  loadAppInit,
} from "features/application-initialization";
import { useSelector } from "react-redux";
import Loader from "react-loader";
import { wipeClientAndCreds } from "features/login-logout/set-logged-in";

export default React.memo(({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const { loading, errorName, errorMessage } = useSelector(
    applicationInitializationSelector,
  );

  useEffect(() => {
    dispatch(loadAppInit());
  }, [dispatch]);

  if (errorName !== null) {
    return (
      <AuthErrorDisplay
        errorName={errorName}
        errorMessage={errorMessage || ""}
      />
    );
  }

  if (loading) {
    return <Loader loaded={!loading} />;
  } else {
    return <>{children}</>;
  }
});

const AuthErrorDisplay = (props: {
  errorName: string;
  errorMessage: string;
}) => {
  if (props.errorName === "UnauthorizedError") {
    // UnauthorizedError will get redirected to login page.
    // Use loading screen while waiting rather than error page.
    return <Loader loaded={false} />;
  }
  if (props.errorName === "ForbiddenError") {
    wipeClientAndCreds();
    window.location.reload();
  }
  return (
    <div>
      <h1>{props.errorName}</h1>
      <h2>{props.errorMessage}</h2>
    </div>
  );
};
