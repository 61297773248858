import { Map as IMap, Set as ISet } from "immutable";
import React from "react";
import { Configuration } from "config";
import * as T from "types/engine-types";
import * as Stages from "features/stages";
import { usePermissions } from "features/roles";
import AdjustmentsSection from "pages/loans-v2/loan-pricing/_components/adjustments-section";
import CalculationsSection from "pages/loans-v2/loan-pricing/_components/calculations-section";
import StipulationsSection from "pages/loans-v2/loan-pricing/_components/stipulations-section";

const PriceScenarioFields = React.memo(
  ({
    scenario,
    config,
    rulesById,
    color,
    top,
  }: {
    scenario: T.PriceScenarioResult;
    config: Configuration;
    rulesById: IMap<T.RuleId, T.DecoratedRuleHeader>;
    color: string;
    top: boolean;
  }) => {
    const hasPermission = usePermissions();
    const hasProfitViewPerm = hasPermission(
      "pricing-profit-margin-adjustments-view",
    );
    const calcStagesByScope = Stages.getCalcStagesByScope(config);
    const priceScenarioCalculatedFieldValuesById = IMap(
      [...scenario.calculatedFields].map((mapping) => [
        mapping.fieldId,
        mapping.value,
      ]),
    );
    let content: JSX.Element | null;
    let _stipulation: JSX.Element | null = null;
    let _price: JSX.Element | null = null;
    let _margin: JSX.Element | null = null;
    let _rate: JSX.Element | null = null;

    switch (scenario.status) {
      case "missing-configuration":
        throw new Error(
          "we shouldn't be displaying a PriceScenarioFields if missing configuration",
        );
      case "approved":
        _stipulation = (
          <StipulationsSection
            color={color}
            stipulations={ISet(scenario.stipulations)}
            rulesById={rulesById}
          />
        );
        _price = (
          <AdjustmentsSection
            color={color}
            adjustments={ISet(scenario.priceAdjustments)}
            finalAdjustments={ISet(scenario.finalPriceAdjustments)}
            adjustmentKindName="Price"
            rulesById={rulesById}
          />
        );
        _margin =
          scenario.marginAdjustments && hasProfitViewPerm ? (
            <AdjustmentsSection
              color={color}
              adjustments={ISet(scenario.marginAdjustments)}
              finalAdjustments={ISet(scenario.finalMarginAdjustments)}
              adjustmentKindName="Profit Margin Price"
              rulesById={rulesById}
            />
          ) : null;
        _rate = (
          <AdjustmentsSection
            color={color}
            adjustments={ISet(scenario.rateAdjustments)}
            finalAdjustments={ISet(scenario.finalRateAdjustments)}
            adjustmentKindName="Rate"
            rulesById={rulesById}
          />
        );
        content = (
          <>
            {calcStagesByScope.priceScenario.map((stage) => (
              <CalculationsSection
                color={color}
                key={stage.id}
                config={config}
                stage={stage}
                fieldValuesById={priceScenarioCalculatedFieldValuesById}
              />
            ))}
          </>
        );
        break;
      case "review-required": {
        _stipulation = (
          <StipulationsSection
            color={color}
            stipulations={ISet(scenario.stipulations)}
            rulesById={rulesById}
          />
        );
        _price = (
          <AdjustmentsSection
            color={color}
            adjustments={ISet(scenario.priceAdjustments)}
            finalAdjustments={ISet(scenario.finalPriceAdjustments)}
            adjustmentKindName="Price"
            rulesById={rulesById}
          />
        );
        _margin =
          scenario.marginAdjustments && hasProfitViewPerm ? (
            <AdjustmentsSection
              color={color}
              adjustments={ISet(scenario.marginAdjustments)}
              finalAdjustments={ISet(scenario.finalMarginAdjustments)}
              adjustmentKindName="Profit Margin Price"
              rulesById={rulesById}
            />
          ) : null;
        _rate = (
          <AdjustmentsSection
            color={color}
            adjustments={ISet(scenario.rateAdjustments)}
            finalAdjustments={ISet(scenario.finalRateAdjustments)}
            adjustmentKindName="Rate"
            rulesById={rulesById}
          />
        );
        content = (
          <>
            {calcStagesByScope.priceScenario.map((stage) => (
              <CalculationsSection
                color={color}
                key={stage.id}
                config={config}
                stage={stage}
                fieldValuesById={priceScenarioCalculatedFieldValuesById}
              />
            ))}
          </>
        );
        break;
      }
      case "rejected": {
        _stipulation = (
          <StipulationsSection
            color={color}
            stipulations={ISet(scenario.stipulations)}
            rulesById={rulesById}
          />
        );
        _price = (
          <AdjustmentsSection
            color={color}
            adjustments={ISet(scenario.priceAdjustments)}
            finalAdjustments={ISet(scenario.finalPriceAdjustments)}
            adjustmentKindName="Price"
            rulesById={rulesById}
          />
        );
        _margin =
          scenario.marginAdjustments && hasProfitViewPerm ? (
            <AdjustmentsSection
              color={color}
              adjustments={ISet(scenario.marginAdjustments)}
              finalAdjustments={ISet(scenario.finalMarginAdjustments)}
              adjustmentKindName="Profit Margin Price"
              rulesById={rulesById}
            />
          ) : null;
        _rate = (
          <AdjustmentsSection
            color={color}
            adjustments={ISet(scenario.rateAdjustments)}
            finalAdjustments={ISet(scenario.finalRateAdjustments)}
            adjustmentKindName="Rate"
            rulesById={rulesById}
          />
        );

        content = (
          <>
            {calcStagesByScope.priceScenario.map((stage) => (
              <CalculationsSection
                color={color}
                key={stage.id}
                config={config}
                stage={stage}
                fieldValuesById={priceScenarioCalculatedFieldValuesById}
              />
            ))}
          </>
        );
        break;
      }
      case "error":
        const isMissingData = scenario.errors.every(
          (err) => err.type === "blank-field",
        );

        if (isMissingData) {
          content = (
            <>
              {calcStagesByScope.priceScenario.map((stage) => (
                <CalculationsSection
                  color={color}
                  key={stage.id}
                  config={config}
                  stage={stage}
                  fieldValuesById={priceScenarioCalculatedFieldValuesById}
                />
              ))}
            </>
          );
        } else {
          content = (
            <>
              {calcStagesByScope.priceScenario.map((stage) => (
                <CalculationsSection
                  color={color}
                  key={stage.id}
                  config={config}
                  stage={stage}
                  fieldValuesById={priceScenarioCalculatedFieldValuesById}
                />
              ))}
            </>
          );
        }
    }

    return (
      // specify container to make print positioning a bit easier
      <div>
        {top ? _stipulation : null}
        {top ? _price : null}
        {top ? _margin : null}
        {top ? _rate : null}
        {top ? null : content}
      </div>
    );
  },
);

export default PriceScenarioFields;
