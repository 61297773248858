import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { expandedConfigSelector } from "features/application-initialization";
import { useSelector } from "react-redux";
import * as Api from "api";
import * as Formulas from "features/formulas";
import * as FormulasParser from "features/formulas-parser";
import * as FormulasIr from "features/formulas-ir";
import * as FormulasPrinter from "features/formulas-printer";
import * as FormulasSyntax from "features/formulas-syntax";
import { getErrorMessage } from "features/utils";

export default function DebugPage(): React.ReactElement {
  const config = useSelector(expandedConfigSelector);

  const [formulaText, setFormulaText] = useState("");

  const [formulaAst, formulaErrorMessage] = React.useMemo(
    () => tryParseFormula(formulaText),
    [formulaText],
  );

  const [formulaIr, irErrorMessage] = React.useMemo(() => {
    try {
      const ir = formulaAst ? FormulasIr.astToIr(config, formulaAst) : null;
      return [ir, null];
    } catch (err) {
      newrelic.noticeError(getErrorMessage(err));
      if (err instanceof FormulasIr.InvalidFormulaError) {
        return [null, err.message];
      }
      throw err;
    }
  }, [config, formulaAst]);

  const renderedFormulaAst = formulaAst
    ? FormulasPrinter.printFormula(formulaAst)
    : "";
  const renderedFormulaIr: string = formulaIr
    ? Formulas.irToFormula(config, formulaIr)
    : "";

  const evalResultPromise = React.useMemo(() => {
    console.log("updating promise");
    return formulaIr
      ? Api.evalExpression(formulaIr)
      : Promise.resolve(undefined);
  }, [formulaIr]);

  const evalResultLoadState = Api.useLoadState(evalResultPromise);

  const evalResultMarkup = formulaIr && (
    <>
      <Box m={3}>
        <strong>Eval Result:</strong>
        {evalResultLoadState.status === "loading" ? (
          "Loading..."
        ) : evalResultLoadState.status === "error" ? (
          `Error: ${evalResultLoadState.error.message}`
        ) : (
          <pre>
            <code>{JSON.stringify(evalResultLoadState.value, null, 4)}</code>
          </pre>
        )}
      </Box>
    </>
  );

  return (
    <Box>
      <Box m={3}>
        <Typography variant="h4">Formula tester</Typography>
      </Box>
      <Box m={3}>
        Formula parser:{" "}
        <input
          autoFocus
          value={formulaText}
          onChange={(e) => setFormulaText(e.target.value)}
        />
      </Box>
      {formulaAst && (
        <>
          <Box m={3}>
            <strong>Rendered formula AST: </strong>
            <code>{renderedFormulaAst}</code>
          </Box>
          <Box m={3}>
            <strong>Formula AST:</strong>
            <pre>
              <code>{JSON.stringify(formulaAst, null, 4)}</code>
            </pre>
          </Box>
        </>
      )}
      {formulaErrorMessage && (
        <>
          <Box m={3}>
            <strong>Formula parsing error: </strong>
            <pre>
              <code>{formulaErrorMessage}</code>
            </pre>
          </Box>
        </>
      )}
      {formulaIr && (
        <>
          <Box m={3}>
            <strong>Rendered formula IR: </strong>
            <code>{renderedFormulaIr}</code>
          </Box>
          <Box m={3}>
            <strong>Formula IR:</strong>
            <pre>
              <code>{JSON.stringify(formulaIr, null, 4)}</code>
            </pre>
          </Box>
        </>
      )}
      {irErrorMessage && (
        <>
          <Box m={3}>
            <strong>IR generation error: </strong>
            <pre>
              <code>{irErrorMessage}</code>
            </pre>
          </Box>
        </>
      )}
      {evalResultMarkup}
    </Box>
  );
}

function tryParseFormula(
  formulaText: string,
): [FormulasSyntax.Expression | null, string] {
  try {
    const ast = FormulasParser.parseFormula(formulaText);
    return [ast, ""];
  } catch (err) {
    if (err instanceof Error) {
      newrelic.noticeError(err);
      return [null, err.toString()];
    }
    return [null, "error"];
  }
}
