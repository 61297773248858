import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";

// Libraries
import { Box, Button, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// api
import * as T from "types/engine-types";
import * as Api from "api";
import { getErrorMessage } from "features/utils";
import Link from "design/atoms/link";

// assets
import loanpassLogoUrl from "images/light-theme-logo-600.png";

type Params = {
  clientAccessId?: string;
  username?: string;
  pw?: string;
};

function ForgotPasswordPage() {
  const params = useParams<Params>();

  const classes = useStyles();
  const [clientAccessId, setClientAccessId] = useState("");
  const [email, setEmail] = useState("");
  const [allowForgotPassword, setAllowForgotPassword] = useState(true);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [brandLogo, setBrandLogo] = useState<string>();

  const accessId = params.clientAccessId ?? clientAccessId;
  const isFormEnabled =
    accessId.length > 0 && email.length > 0 && allowForgotPassword;

  useEffect(() => {
    const getLogo = async () => {
      if (params.clientAccessId) {
        try {
          const brand = await Api.getClientBrand(params.clientAccessId);
          if (brand) {
            const logoUrlToSet = brand.logoUrl ?? loanpassLogoUrl;
            setBrandLogo(logoUrlToSet);
          }
        } catch (error) {
          console.error(error);
          newrelic.noticeError(getErrorMessage(error));
        }
      } else {
        // If no client access ID is set in the URL, then just show the LoanPASS logo
        setBrandLogo(loanpassLogoUrl);
      }
    };

    getLogo();
  }, [params.clientAccessId]);

  const handleForgotPassword: React.FormEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      setHelperText("Sending password reset...");
      setAllowForgotPassword(false);

      const forgotPasswordRequest: T.ForgotPassword = {
        clientAccessId: accessId,
        emailAddress: email,
      };

      Api.forgotPassword(forgotPasswordRequest)
        .then(() => {
          setHelperText("Password reset sent!");
        })
        .catch((error) => {
          const err = new Error("Error sending 'forgot password' request");
          console.error(err, error);
          newrelic.noticeError(err);
          newrelic.noticeError(getErrorMessage(error));
          setError(true);
          setHelperText("Could not reset password");
          setAllowForgotPassword(true);
        });
    },
    [accessId, email],
  );

  return (
    <Box
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {brandLogo && <img className={classes.logo} alt="Logo" src={brandLogo} />}

      <div className={classes.container}>
        <form noValidate autoComplete="off" onSubmit={handleForgotPassword}>
          {!params.clientAccessId ? (
            <TextField
              error={error}
              autoFocus
              fullWidth
              type="text"
              label="Client ID"
              value={clientAccessId}
              onChange={(e) => setClientAccessId(e.target.value.toLowerCase())}
            />
          ) : null}
          <TextField
            data-selector="email-input"
            error={error}
            autoFocus={!!params.clientAccessId}
            fullWidth
            type="email"
            label="Email address"
            value={email}
            helperText={helperText}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Box className={classes.resetBtnSection}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={!isFormEnabled}
              data-selector="send-password-reset-button"
            >
              Send Password Reset
            </Button>
          </Box>
          <Box className={classes.resetBtnSection}>
            <Link
              to={
                params.clientAccessId
                  ? `/login/${params.clientAccessId}`
                  : "/login"
              }
              data-selector="back-to-login"
            >
              Back to Login
            </Link>
          </Box>
        </form>
      </div>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      maxWidth: 400,
      margin: "0 auto",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      "& .MuiTextField-root": {
        marginBottom: theme.spacing(2),
      },
    },
    logo: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: theme.spacing(3),
      width: "100%",
      maxWidth: 350,
    },
    resetBtnSection: {
      marginTop: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      justifyContent: "space-between",
    },
  }),
);

export default ForgotPasswordPage;
