/*
Define Drag Types
*/

export type DragItem = {
  dragId: string;
  dragParentId: string;
  dragType: "and" | "or" | "field-predicate";
};

export const ItemTypes = {
  PREDICATE: "field-predicate",
  AND: "and",
  OR: "or",
};
