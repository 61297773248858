import { Configuration } from "config";
import * as T from "types/engine-types";
import * as Api from "../../api";
import { unreachable } from "../utils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { PricingProfileHeader } from "types/engine-types";

export function getAllObjectRefsByObjectType(
  config: Configuration,
  type: T.ObjectType,
): T.ObjectRef[] {
  switch (type) {
    case "pricing-profile":
      return config.pricingProfiles.map((pp) => ({
        type: "pricing-profile",
        id: pp.id,
      }));
  }
}

export function getVisibleObjectRefsByObjectType(
  config: Configuration,
  details: ObjectDetails,
  type: T.ObjectType,
  currentValues: T.ObjectRef[],
): T.ObjectRef[] {
  switch (type) {
    case "pricing-profile":
      const currentIds = currentValues.map(({ id }) => id);
      const knownIds = details.pricingProfiles.keys();
      const visibleIds = new Set([...currentIds, ...Array.from(knownIds)]);
      return Array.from(visibleIds).map((id) => ({
        type: "pricing-profile",
        id,
      }));
  }
}

export type ObjectDetails = {
  pricingProfiles: Map<T.PricingProfileId, T.PricingProfileHeader>;
};

export function emptyObjectDetails(): ObjectDetails {
  return {
    pricingProfiles: new Map(),
  };
}

export async function fetchObjectDetails(): Promise<ObjectDetails> {
  const pricingProfiles = await Api.getCurrentUserPricingProfiles();

  return {
    pricingProfiles: new Map(pricingProfiles.map((p) => [p.id, p])),
  };
}

export type ObjectDetailsV2 = {
  pricingProfiles: T.PricingProfileHeader[];
};

export function objectDetailsV2FromData(
  pricingProfiles: PricingProfileHeader[],
): ObjectDetailsV2 {
  return {
    pricingProfiles,
  };
}

export async function fetchObjectDetailsV2(): Promise<ObjectDetailsV2> {
  const pricingProfiles = await Api.getCurrentUserPricingProfiles();
  return objectDetailsV2FromData(pricingProfiles);
}

export function isObjectRefValid(
  config: T.EngineConfiguration,
  details: ObjectDetails,
  ref: T.ObjectRef,
): boolean {
  switch (ref.type) {
    case "pricing-profile":
      return config.pricingProfiles.some((pp) => pp.id === ref.id);
    default:
      return unreachable(ref.type);
  }
}

export function getObjectRefLabel(
  details: ObjectDetails,
  objectRef: T.ObjectRef,
): string {
  switch (objectRef.type) {
    case "pricing-profile":
      return (
        details.pricingProfiles.get(objectRef.id)?.name ||
        `[Unknown pricing profile: ${objectRef.id}]`
      );
  }
}

export type ObjectDetailsState = {
  objectDetails: ObjectDetails | null;
};

const initialState: ObjectDetailsState = {
  objectDetails: null,
};

const objectDetailsSlice = createSlice({
  name: "ObjectDetails",
  initialState,
  reducers: {
    setObjectDetails: (state, { payload }: PayloadAction<ObjectDetails>) => {
      return {
        ...state,
        objectDetails: payload,
      };
    },
  },
});

export const { setObjectDetails } = objectDetailsSlice.actions;

export function useObjectDetails(): [Api.LoadState<ObjectDetails>, () => void] {
  const [loadState, setLoadState] = useState<Api.LoadState<ObjectDetails>>({
    status: "loading",
  });

  // will be set to true in the effect below
  const [shouldMakeRequest, setShouldMakeRequest] = useState<boolean>(false);

  const reload = useCallback(() => {
    setShouldMakeRequest(true);
  }, []);

  useEffect(() => {
    setShouldMakeRequest(true);
  }, []);

  // if shouldMakeRequest is set to true, we run the request, and
  // switch to status: "loading"
  useEffect(() => {
    (async () => {
      if (shouldMakeRequest) {
        try {
          // in the case of when `reload` is called, the load state will
          // probably be "loaded". So we need to set it back to "loading"
          // here.
          setLoadState({ status: "loading" });
          // we've made the request, so we don't need to make it again.
          // When `reload` is called, it will set this back to `true`
          // and the effect will run again
          setShouldMakeRequest(false);

          const loadedObjectDetails = await fetchObjectDetails();
          setLoadState({ status: "loaded", value: loadedObjectDetails });
        } catch (error: unknown) {
          if (error instanceof Error) {
            setLoadState({ status: "error", error });
            console.error(
              `Error while fetching object details: ${error.message}`,
            );
            console.error(error);
            newrelic.noticeError(error);
          }
        }
      }
    })();
  }, [shouldMakeRequest]);

  return [loadState, reload];
}
