// Converts to and from the intermediate representation (IR) used to store formulas
// (i.e. Expression from engine-types.ts).

import { Configuration } from "config";
import * as T from "types/generated-types";
import * as Util from "features/formulas-util";
import * as Syntax from "features/formulas-syntax";

const infixOpKindByBuiltinFunc = {
  add: "addition",
  subtract: "subtraction",
  multiply: "multiplication",
  divide: "division",
  power: "exponentiation",
} as const;

const prefixOpKindByBuiltinFunc = {
  negate: "numeric-negation",
} as const;

const builtinFuncByOpKind = {
  addition: "add",
  subtraction: "subtract",
  multiplication: "multiply",
  division: "divide",
  exponentiation: "power",
  "numeric-negation": "negate",
} as const;

const opKindByBinaryOp = {
  eq: "equality-test",
  ne: "inequality-test",
  lt: "less-than",
  gt: "greater-than",
  lte: "less-than-or-equal",
  gte: "greater-than-or-equal",
} as const;

const binaryOpByOpKind = {
  "equality-test": "eq",
  "inequality-test": "ne",
  "less-than": "lt",
  "greater-than": "gt",
  "less-than-or-equal": "lte",
  "greater-than-or-equal": "gte",
} as const;

export class InvalidFormulaError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "InvalidFormulaError";
  }
}

export class UnknownFieldError extends InvalidFormulaError {
  identifier: string;
  constructor(identifier: string) {
    super("unknown field " + JSON.stringify(identifier));
    this.name = "UnknownFieldError";
    this.identifier = identifier;
  }
}

export class UnknownEnumTypeError extends InvalidFormulaError {
  identifier: string;
  constructor(identifier: string) {
    super("unknown enum type " + JSON.stringify(identifier));
    this.name = "UnknownEnumTypeError";
    this.identifier = identifier;
  }
}

export class UnknownEnumVariantError extends InvalidFormulaError {
  identifier: string;
  constructor(identifier: string) {
    super("unknown enum variant " + JSON.stringify(identifier));
    this.name = "UnknownEnumVariantError";
    this.identifier = identifier;
  }
}

export class UnknownPricingProfileError extends InvalidFormulaError {
  identifier: string;
  constructor(identifier: string) {
    super("unknown pricing profile " + JSON.stringify(identifier));
    this.name = "UnknownPricingProfileError";
    this.identifier = identifier;
  }
}

export function irToAst(
  config: Configuration,
  expr: T.Expression,
): Syntax.Expression {
  function recurse(innerExpr: T.Expression): Syntax.Expression {
    return irToAst(config, innerExpr);
  }

  function conditionToAst(condition: T.Condition): Syntax.Expression {
    switch (condition.kind) {
      case "and":
      case "or":
        return {
          kind: "function-call",
          func: {
            kind: "identifier",
            text: condition.kind,
          },
          args: condition.children.map((child) => recurse(child)),
        };
      case "predicate":
        return predicateToAst(condition.predicate);
      default:
        throw new Error("IR contains an unsupported condition type");
    }
  }

  function predicateToAst(predicate: T.Predicate): Syntax.Expression {
    switch (predicate.kind) {
      case "unary":
        return unaryPredicateToAst(predicate);
      case "binary":
        return binaryPredicateToAst(predicate);
    }
  }

  function unaryPredicateToAst(predicate: T.UnaryPredicate): Syntax.Expression {
    switch (predicate.op) {
      case "is-blank":
        return {
          kind: "function-call",
          func: {
            kind: "identifier",
            text: "isblank",
          },
          args: [recurse(predicate.left)],
        };
    }
  }

  function binaryPredicateToAst(
    predicate: T.BinaryPredicate,
  ): Syntax.Expression {
    switch (predicate.op) {
      case "eq":
      case "ne":
      case "lt":
      case "gt":
      case "lte":
      case "gte":
        return {
          kind: opKindByBinaryOp[predicate.op],
          left: recurse(predicate.left),
          right: recurse(predicate.right),
        };
      default:
        throw new Error("IR contains an unsupported predicate op");
    }
  }

  switch (expr.kind) {
    case "if-else": {
      return {
        kind: "function-call",
        func: {
          kind: "identifier",
          text: "if",
        },
        args: [recurse(expr.cond), recurse(expr.ifTrue), recurse(expr.ifFalse)],
      };
    }
    case "condition":
      return conditionToAst(expr.cond);
    case "function-call": {
      if (expr.func.kind !== "literal") {
        throw new Error(
          "IR contains a function call to a function value that is not a literal",
        );
      }

      if (expr.func.value.type !== "builtin-func") {
        throw new Error(
          "IR contains a function call to a function value that is not a builtin-func literal",
        );
      }

      const funcName = expr.func.value.value;

      if (
        infixOpKindByBuiltinFunc.hasOwnProperty(funcName) &&
        expr.args.length === 2
      ) {
        const builtinFunc = funcName as keyof typeof infixOpKindByBuiltinFunc;

        return {
          kind: infixOpKindByBuiltinFunc[builtinFunc],
          left: recurse(expr.args[0]),
          right: recurse(expr.args[1]),
        };
      }

      if (
        prefixOpKindByBuiltinFunc.hasOwnProperty(funcName) &&
        expr.args.length === 1
      ) {
        const builtinFunc = funcName as keyof typeof prefixOpKindByBuiltinFunc;

        return {
          kind: prefixOpKindByBuiltinFunc[builtinFunc],
          term: recurse(expr.args[0]),
        };
      }

      return {
        kind: "function-call",
        func: {
          kind: "identifier",
          text: funcName,
        },
        args: expr.args.map((arg) => recurse(arg)),
      };
    }
    case "field-value":
      const field = config.allFieldsById.get(expr.fieldId);
      return {
        kind: "identifier",
        text: field ? Util.getFieldIdentifier(field) : "Deleted_Field",
      };
    case "duration":
      return {
        kind: "duration-literal",
        count: recurse(expr.count),
        unit: expr.unit,
      };
    case "literal":
      const value = expr.value;
      switch (value.type) {
        case "boolean":
          return {
            kind: "boolean-literal",
            value: value.value,
          };
        case "number":
          return {
            kind: "number-literal",
            value: value.value,
          };
        case "date":
          return {
            kind: "date-literal",
            value: value.value,
          };
        case "string":
          return {
            kind: "string-literal",
            value: value.value,
          };
        case "enum": {
          const enumType = config.enumTypesById.get(value.value.enumTypeId);
          const enumTypeIdent = enumType
            ? Util.getEnumTypeIdentifier(enumType)
            : "<Deleted_Enumeration>";
          const variantIdent = enumType
            ? Util.findEnumVariantIdentifier(enumType, value.value.variantId) ||
              "<Deleted_Variant>"
            : "<Unknown>";

          return {
            kind: "property-access",
            objectName: {
              kind: "identifier",
              text: enumTypeIdent,
            },
            propertyName: {
              kind: "identifier",
              text: variantIdent,
            },
          };
        }
        case "object-ref": {
          const profile = config.pricingProfiles.find(
            (pp) => pp.id === value.value.id,
          );
          const profileIdent = profile
            ? Util.getPricingProfileIdentifier(profile)
            : "<Deleted_Pricing_Profile>";

          return {
            kind: "property-access",
            objectName: {
              kind: "identifier",
              text: "PricingProfile",
            },
            propertyName: {
              kind: "identifier",
              text: profileIdent,
            },
          };
        }
        default:
          throw new Error(
            "IR contains unexpected literal type " + JSON.stringify(value.type),
          );
      }
    default:
      throw new Error(
        "IR contains unexpected expression type " + JSON.stringify(expr.kind),
      );
  }
}

export function astToIr(
  config: Configuration,
  expr: Syntax.Expression,
): T.Expression {
  function recurse(innerExpr: Syntax.Expression): T.Expression {
    return astToIr(config, innerExpr);
  }

  switch (expr.kind) {
    case "equality-test":
    case "inequality-test":
    case "less-than":
    case "greater-than":
    case "less-than-or-equal":
    case "greater-than-or-equal":
      return {
        kind: "condition",
        cond: {
          kind: "predicate",
          predicate: {
            kind: "binary",
            op: binaryOpByOpKind[expr.kind],
            left: recurse(expr.left),
            right: recurse(expr.right),
          },
        },
      };
    // Infix operators with equivalent built-in functions
    case "addition":
    case "subtraction":
    case "multiplication":
    case "division":
    case "exponentiation":
      return {
        kind: "function-call",
        func: {
          kind: "literal",
          value: {
            type: "builtin-func",
            value: builtinFuncByOpKind[expr.kind] as T.BuiltinFunc,
          },
        },
        args: [recurse(expr.left), recurse(expr.right)],
      };
    case "numeric-negation":
      return {
        kind: "function-call",
        func: {
          kind: "literal",
          value: {
            type: "builtin-func",
            value: builtinFuncByOpKind[expr.kind] as T.BuiltinFunc,
          },
        },
        args: [recurse(expr.term)],
      };
    case "function-call": {
      const funcName = expr.func.text.toLowerCase();

      if (funcName === "if") {
        if (expr.args.length !== 3) {
          throw new InvalidFormulaError(
            "IF must have 3 parameters: IF(Condition, IfTrue, IfFalse)",
          );
        }

        return {
          kind: "if-else",
          cond: recurse(expr.args[0]),
          ifTrue: recurse(expr.args[1]),
          ifFalse: recurse(expr.args[2]),
        };
      }

      if (funcName === "and") {
        if (expr.args.length < 1) {
          throw new InvalidFormulaError("AND must have at least one parameter");
        }

        return {
          kind: "condition",
          cond: {
            kind: "and",
            children: expr.args.map((arg) => recurse(arg)),
          },
        };
      }

      if (funcName === "or") {
        if (expr.args.length < 1) {
          throw new InvalidFormulaError("OR must have at least one parameter");
        }

        return {
          kind: "condition",
          cond: {
            kind: "or",
            children: expr.args.map((arg) => recurse(arg)),
          },
        };
      }

      if (funcName === "isblank") {
        if (expr.args.length !== 1) {
          throw new InvalidFormulaError("ISBLANK must have one parameter");
        }

        return {
          kind: "condition",
          cond: {
            kind: "predicate",
            predicate: {
              kind: "unary",
              op: "is-blank",
              left: recurse(expr.args[0]),
            },
          },
        };
      }

      return {
        kind: "function-call",
        func: {
          kind: "literal",
          value: {
            type: "builtin-func",
            value: funcName as T.BuiltinFunc,
          },
        },
        args: expr.args.map((arg) => recurse(arg)),
      };
    }
    case "property-access": {
      if (expr.objectName.text === "PricingProfile") {
        const profile = config.getPricingProfileByIdentifier(
          expr.propertyName.text,
        );

        if (profile === null) {
          throw new UnknownPricingProfileError(expr.propertyName.text);
        }

        return {
          kind: "literal",
          value: {
            type: "object-ref",
            value: {
              type: "pricing-profile",
              id: profile.id,
            },
          },
        };
      } else {
        const enumType = config.getEnumTypeByIdentifier(expr.objectName.text);

        if (enumType === null) {
          throw new UnknownEnumTypeError(expr.objectName.text);
        }

        const variant = Util.findEnumVariantByIdentifier(
          enumType,
          expr.propertyName.text,
        );

        if (variant === null) {
          throw new UnknownEnumVariantError(expr.propertyName.text);
        }

        return {
          kind: "literal",
          value: {
            type: "enum",
            value: {
              enumTypeId: enumType.id,
              variantId: variant.id,
            },
          },
        };
      }
    }
    case "identifier":
      const field = config.getFieldByIdentifier(expr.text);

      if (field === null) {
        throw new UnknownFieldError(expr.text);
      }

      return {
        kind: "field-value",
        fieldId: field.id,
      };
    case "boolean-literal":
      return {
        kind: "literal",
        value: {
          type: "boolean",
          value: expr.value,
        },
      };
    case "duration-literal":
      return {
        kind: "duration",
        count: recurse(expr.count),
        unit: expr.unit,
      };
    case "number-literal":
      return {
        kind: "literal",
        value: {
          type: "number",
          value: expr.value,
        },
      };
    case "string-literal":
      return {
        kind: "literal",
        value: {
          type: "string",
          value: expr.value,
        },
      };
    case "date-literal":
      return {
        kind: "literal",
        value: {
          type: "date",
          value: expr.value,
        },
      };
  }
}
