import styled from "styled-components";
import whitespace from "design/subatomics/whitespace";
import breakpoints from "design/subatomics/breakpoints";
import shadows from "design/subatomics/shadows";
import radiusOptions from "design/subatomics/corners";
import colors from "design/subatomics/colors";
import { styles } from "design/atoms/typography";

export const ManageFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .atom-button {
    display: block;
    margin: ${whitespace("base")} ${whitespace("less")};
  }

  .clear-filters {
    justify-self: flex-end;
    margin-right: ${whitespace("base")};
    margin-left: auto;
  }

  ${breakpoints.mobile} {
    h2 {
      margin-bottom: ${whitespace()};
    }

    .pal-v2-filter-editor {
      margin: ${whitespace()} 0;
    }
  }

  ${breakpoints.desktop} {
    padding: ${whitespace("less")};

    h2 {
      margin-bottom: ${whitespace("most")};
    }

    .pal-v2-filter-editor {
      margin: ${whitespace()} 0;
    }
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${breakpoints.mobile} {
    width: 100%;
  }

  ${breakpoints.desktop} {
    margin-right: ${whitespace("less")};
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  flex-wrap: wrap;
`;

export const InnerRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  flex-wrap: wrap;

  ${breakpoints.mobile} {
    width: 100%;
  }
`;

export const FilterCard = styled.div`
  display: block;
  width: 200px;
  background-color: ${colors({ color: "white" })};
  border-radius: ${radiusOptions.small};
  padding: ${whitespace("least")} ${whitespace("less")};
  margin: ${whitespace("least")} 0px;
  box-shadow: ${shadows.controls};
  border: 1px solid ${colors({ color: "lightBorder" })};
  ${styles.smallText};
  position: relative;
  color: ${colors({ color: "gray", shade: 2 })};
  min-height: 26px;

  > span {
    position: absolute;
    top: 0px;
    right: 16px;

    &.sort {
      right: 0px;
    }

    svg {
      padding: ${whitespace("more")} ${whitespace()};

      &:hover {
        cursor: pointer;
      }
    }
  }

  .typography-small-text {
    display: block;
    position: static;
  }

  &.active {
    color: ${colors({ color: "white" })};
    border-color: ${colors({ color: "brandPrimary" })};
    background-color: ${colors({ color: "brandPrimary" })};

    .typography-small-text {
      color: ${colors({ color: "white" })};
    }
  }

  &.inactive {
    color: ${colors({ color: "gray", shade: 2 })};
    border-color: ${colors({ color: "lightBorder" })};
    background-color: ${colors({ color: "white" })};

    .typography-small-text {
      color: ${colors({ color: "gray", shade: 5 })};
    }
  }

  ${breakpoints.mobile} {
    width: 100%;
  }
`;

export const FilterText = styled.div`
  position: relative;
  width: 170px;
  margin-right: ${whitespace("less")};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
