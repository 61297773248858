import styled from "styled-components";
import color from "design/subatomics/colors";
import whitespace, { spacingOptions } from "design/subatomics/whitespace";
import radiusOptions from "design/subatomics/corners";
import transitions from "design/subatomics/transitions";

export const LinkList = styled.div`
  a,
  span {
    display: flex;
    padding: ${whitespace("less")} ${whitespace("less")};
    margin: ${whitespace("least")};
    transition: background-color ${transitions.default};
    align-items: center;
    justify-content: center;

    .icon {
      text-align: left;
      width: 32px;
      transition: color ${transitions.default};
      color: ${color({ color: "gray", shade: 5 })};
    }

    .label {
      text-align: right;
      width: calc(100% - ${spacingOptions.most}px - ${spacingOptions.less}px);
      transition: color ${transitions.default};
      color: ${color({ color: "gray", shade: 4 })};
    }

    &:hover {
      background-color: ${color({ color: "green", shade: 8 })};
      border-radius: ${radiusOptions.small};
    }
  }
`;
