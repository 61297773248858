import styled from "styled-components";
import transitions from "design/subatomics/transitions";
import breakpoints from "design/subatomics/breakpoints";
import color from "design/subatomics/colors";
import shadowOptions from "design/subatomics/shadows";
import layers from "design/subatomics/layers";

export const SlidingPanel = styled.div`
  position: relative;

  &.closed {
    height: 0px;
    width: 0px;
    transition: height ${transitions.default}, width ${transitions.default};
    overflow: hidden;
  }

  &.open {
    transition: height ${transitions.default};
    box-shadow: ${shadowOptions.dropdowns};

    position: fixed;
    top: 48px;
    background: ${color({ color: "white" })};
    z-index: ${layers({ layer: "above", modifier: 1 })};
    padding-bottom: 48px;

    ${breakpoints.mobile} {
      width: 100vw;
      right: 0px;
      bottom: 0px;
      border-top: 1px solid ${color({ color: "border" })};
      border-bottom: 1px solid ${color({ color: "border" })};
    }

    ${breakpoints.desktop} {
      right: 3px;
      width: 500px;
      border: 1px solid ${color({ color: "lightBorder" })};
    }
  }
`;
