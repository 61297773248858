import {
  createCodeContextStateSelector,
  createGetCodeContextThunk,
} from "features/code-contexts";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import MappingEditor, { MappingTarget } from "design/organisms/mapping-editor";
import { MappingPurpose } from "features/field-mappings";
import * as T from "types/generated-types";
import _ from "lodash";

const PURPOSE: MappingPurpose = "encompass-to-transaction-response";

const codeContextSelector = createCodeContextStateSelector(PURPOSE);
const getCodeContext = createGetCodeContextThunk(PURPOSE);

const EncompassOutgoingMappingsPage = React.memo(() => {
  const dispatch = useDispatch();
  const codeContextState = useSelector(codeContextSelector);

  useEffect(() => {
    if (codeContextState.status.kind === "not-loaded") {
      dispatch(getCodeContext());
    }
  }, [dispatch, codeContextState]);

  const targets = useMemo(() => {
    if (
      codeContextState.status.kind === "loaded" &&
      codeContextState.codeContext !== null
    ) {
      return makeMappingTargets(codeContextState.codeContext, ["Encompass"]);
    } else {
      return null;
    }
  }, [codeContextState]);

  return (
    <MappingEditor
      mappingPurpose={"encompass-to-transaction-response"}
      targets={targets}
    />
  );
});

function makeMappingTargets(
  codeContext: T.CodeContext,
  includeScopeVariables: string[] | null,
): MappingTarget[] {
  const targets: string[] = [];

  for (const [name, binding] of Object.entries(
    codeContext.scopeVariables.map,
  )) {
    if (
      includeScopeVariables === null ||
      includeScopeVariables.includes(name)
    ) {
      targets.push(
        ...getTargetPaths(name, binding.typ, codeContext.objectTypes),
      );
    }
  }

  return _.uniq(targets).map((path) => ({
    id: path,
    name: path.substring("Encompass.loan.".length),
  }));
}

function getTargetPaths(
  basePath: string,
  type: T.LsType,
  objectTypes: { [key: string]: T.LsObjectType },
): string[] {
  const paths = [];
  switch (type.kind) {
    case "defined":
      switch (type.defined.kind) {
        // Maps to single type
        case "list":
        case "date":
        case "any":
        case "blank":
        case "num":
        case "str":
        case "bool":
        case "day-duration":
        case "month-duration":
        case "enum-variant":
          return [basePath];

        case "object-instance":
          if (type.defined.typeName in objectTypes) {
            const objectType = objectTypes[type.defined.typeName];
            for (const [propName, binding] of Object.entries(objectType.map)) {
              paths.push(
                ...getTargetPaths(
                  `${basePath}.${propName}`,
                  binding.typ,
                  objectTypes,
                ),
              );
            }
          }
          return paths;

        case "any-of":
          for (const typ of type.defined.types) {
            paths.push(
              ...getTargetPaths(
                basePath,
                { kind: "defined", defined: typ },
                objectTypes,
              ),
            );
          }
          return paths;

        // Not mappable
        case "type-ref":
        case "function":
        case "none":
          return [];
      }
      // This `break` is unreachable, but I added it to satisfy the `no-fallthrough` lint rule.
      // Note that it may become reachable if we add more variants to LsType::Defined and forget
      // to add them to the switch statement above.
      break;
    default:
      return [];
  }
}

export default EncompassOutgoingMappingsPage;
