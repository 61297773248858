import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import * as Api from "api";
import loanpassLogoUrl from "images/light-theme-logo-250.png";
import { getErrorMessage } from "features/utils";

export default React.memo(() => {
  const { client } = useSelector(nonNullApplicationInitializationSelector);
  const [brandLogo, setBrandLogo] = useState<string>();

  useEffect(() => {
    const getLogo = async () => {
      if (client.accessId) {
        try {
          const brand = await Api.getClientBrand(client.accessId);
          if (brand) {
            const logoUrlToSet = brand.logoUrl ?? loanpassLogoUrl;
            setBrandLogo(logoUrlToSet);
          }
        } catch (error) {
          console.error(error);
          newrelic.noticeError(getErrorMessage(error));
        }
      } else {
        // If no client access ID is set in the URL, then just show the LoanPASS logo
        setBrandLogo(loanpassLogoUrl);
      }
    };

    getLogo();
  }, [client, brandLogo]);

  if (!brandLogo) {
    return <></>;
  } else {
    return (
      <img
        className="atom-logo"
        src={brandLogo}
        alt={brandLogo ? client?.name : "LoanPASS"}
      />
    );
  }
});
