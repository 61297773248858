import * as T from "types/generated-types";

// *** AST node type definitions ***
export type Expression =
  | EqualityTest
  | InequalityTest
  | LessThan
  | GreaterThan
  | LessThanOrEqual
  | GreaterThanOrEqual
  | Addition
  | Subtraction
  | Multiplication
  | Division
  | Exponentiation
  | NumericNegation
  | FunctionCall
  | PropertyAccess
  | Identifier
  | BooleanLiteral
  | DurationLiteral
  | NumberLiteral
  | StringLiteral
  | DateLiteral;

type EqualityTest = Readonly<{
  kind: "equality-test";
  left: Expression;
  right: Expression;
}>;

type InequalityTest = Readonly<{
  kind: "inequality-test";
  left: Expression;
  right: Expression;
}>;

type LessThan = Readonly<{
  kind: "less-than";
  left: Expression;
  right: Expression;
}>;

type GreaterThan = Readonly<{
  kind: "greater-than";
  left: Expression;
  right: Expression;
}>;

type LessThanOrEqual = Readonly<{
  kind: "less-than-or-equal";
  left: Expression;
  right: Expression;
}>;

type GreaterThanOrEqual = Readonly<{
  kind: "greater-than-or-equal";
  left: Expression;
  right: Expression;
}>;

type Addition = Readonly<{
  kind: "addition";
  left: Expression;
  right: Expression;
}>;

type Subtraction = Readonly<{
  kind: "subtraction";
  left: Expression;
  right: Expression;
}>;

type Multiplication = Readonly<{
  kind: "multiplication";
  left: Expression;
  right: Expression;
}>;

type Division = Readonly<{
  kind: "division";
  left: Expression;
  right: Expression;
}>;

type Exponentiation = Readonly<{
  kind: "exponentiation";
  left: Expression;
  right: Expression;
}>;

type NumericNegation = Readonly<{
  kind: "numeric-negation";
  term: Expression;
}>;

type FunctionCall = Readonly<{
  kind: "function-call";
  func: Identifier;
  args: readonly Expression[];
}>;

type PropertyAccess = Readonly<{
  kind: "property-access";
  objectName: Identifier;
  propertyName: Identifier;
}>;

type Identifier = Readonly<{
  kind: "identifier";
  text: string;
}>;

type BooleanLiteral = Readonly<{
  kind: "boolean-literal";
  value: boolean;
}>;

type DurationLiteral = Readonly<{
  kind: "duration-literal";
  count: Expression;
  unit: T.DurationUnit;
}>;

type NumberLiteral = Readonly<{
  kind: "number-literal";
  value: string;
}>;

type StringLiteral = Readonly<{
  kind: "string-literal";
  value: string;
}>;

type DateLiteral = Readonly<{
  kind: "date-literal";
  value: string;
}>;

// Used for determining parenthesis insertion when rendering formula ASTs to
// strings.
export const syntaxNodePrecedenceByKind = {
  "equality-test": 0,
  "inequality-test": 0,
  "less-than": 0,
  "greater-than": 0,
  "less-than-or-equal": 0,
  "greater-than-or-equal": 0,
  addition: 1,
  subtraction: 1,
  multiplication: 2,
  division: 2,
  exponentiation: 3,
  "numeric-negation": 100,
  "function-call": 100,
  "boolean-literal": 100,
  "duration-literal": 100,
  "number-literal": 100,
  "string-literal": 100,
  "date-literal": 100,
  "property-access": 100,
  identifier: 100,
} as const;

// Used for rendering
export const infixOperatorsByKind = {
  "equality-test": " = ",
  "inequality-test": " <> ",
  "less-than": " < ",
  "greater-than": " > ",
  "less-than-or-equal": " <= ",
  "greater-than-or-equal": " >= ",
  addition: " + ",
  subtraction: " - ",
  multiplication: " * ",
  division: " / ",
  exponentiation: "^",
};

// Used for rendering
export const prefixOperatorsByKind = {
  "numeric-negation": "-",
};

// Used for rendering
export const infixOperatorAssociativitityByKind = {
  "equality-test": "left",
  "inequality-test": "left",
  "less-than": "left",
  "greater-than": "left",
  "less-than-or-equal": "left",
  "greater-than-or-equal": "left",
  addition: "left",
  subtraction: "left",
  multiplication: "left",
  division: "left",
  exponentiation: "right",
};
