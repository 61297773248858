import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import * as T from "types/engine-types";
import * as Fields from "features/fields";
import { unreachable, useDebounce } from "features/utils";
import {
  expandedConfigSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import { DataTableBody } from "./DataTableEditor";
import SearchInput from "design/atoms/search-input";

const useRevisionStyles = makeStyles((t) =>
  createStyles({
    container: {
      position: "relative",
      overflow: "auto",
    },
    table: {
      borderSpacing: 0,
      width: "100%",
    },
    th: {
      position: "sticky",
      top: 0,

      borderTop: "1px solid #ccc",
      borderRight: "1px solid #ccc",
      borderBottom: "1px solid #888",

      height: 40,

      background: "white",
      padding: "8px 12px",

      "&:first-child": {
        borderLeft: "1px solid #ccc",
      },
    },
    td: {
      borderRight: "1px solid #ccc",
      borderBottom: "1px solid #ccc",

      height: 40,

      padding: "0 10px",

      "&:first-child": {
        borderLeft: "1px solid #ccc",
      },

      "&.rightAlign": {
        textAlign: "right",
      },
    },
  }),
);

function rowHasText(row: string[], text: string): boolean {
  return row.some((fieldString) => fieldString.includes(text));
}

function filterRow(row: string[], texts: string[]): boolean {
  return texts.every((text) => rowHasText(row, text));
}

export default function DataTableBodyViewer({
  columnDefs,
  body,
}: {
  columnDefs: T.DataTableColumn[];
  body: DataTableBody;
}) {
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 250);
  return (
    <div>
      <SearchInput
        label="rows"
        searchTerm={searchText}
        setSearchTerm={setSearchText}
      />
      <Inner
        columnDefs={columnDefs}
        body={body}
        searchText={debouncedSearchText}
      />
    </div>
  );
}

const Inner = React.memo(function DataTableBodyViewerInner({
  columnDefs,
  body,
  searchText,
}: {
  columnDefs: T.DataTableColumn[];
  body: DataTableBody;
  searchText: string;
}) {
  const C = useRevisionStyles();
  const config = useSelector(expandedConfigSelector);

  const objectDetails = useSelector(objectDetailsMapSelector);

  const searchTexts = searchText
    .toLowerCase()
    .trim()
    .split(" ")
    .filter((s) => !!s);
  const valueTypes = columnDefs.map((cd) => cd.valueType);
  const maxLines = 1000;
  let passed = 0;
  return (
    <div className={C.container}>
      <table className={C.table}>
        <thead>
          <tr>
            {columnDefs.map((column) => (
              <th key={column.id} className={C.th}>
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {body
            .filter((row) => {
              if (passed > maxLines) {
                return false;
              } else {
                const textsFound = !!searchTexts.length
                  ? filterRow(
                      row
                        .map(
                          (fv, i) =>
                            Fields.fieldValueToString(
                              config,
                              objectDetails,
                              valueTypes[i],
                              fv,
                            ) || "",
                        )
                        .filter((fs) => !!fs)
                        .map((fs) => fs.toLowerCase()),
                      searchTexts,
                    )
                  : true;
                if (textsFound) {
                  passed += 1;
                }
                return textsFound;
              }
            })
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columnDefs.map((column, columnIndex) => (
                  <td
                    key={column.id}
                    className={
                      C.td +
                      (isRightAligned(column.valueType) ? " rightAlign" : "")
                    }
                  >
                    {Fields.fieldValueToString(
                      config,
                      objectDetails,
                      column.valueType,
                      row[columnIndex],
                    )}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
});

function isRightAligned(valueType: T.FieldValueType): boolean {
  switch (valueType.type) {
    case "enum":
    case "object-ref":
    case "string":
    case "header":
    case "duration":
    case "date":
      return false;
    case "number":
      return true;
    default:
      return unreachable(valueType);
  }
}
