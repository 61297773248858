import React, { useEffect } from "react";
import { Box, Button } from "@material-ui/core";
import MessageSection from "../_components/message-section";
import { useDispatch, useSelector } from "react-redux";
import { investorsSelector, getInvestors } from "features/investors";
import {
  loansSelector,
  resolveEffectiveFieldValues,
  loanStatusToString,
} from "features/loans";
import { rulesSelector, getRules } from "features/rules";
import { RenderedField } from "design/molecules/field-viewer";
import { useById, formatDateTime, unreachable } from "features/utils";
import PriceScenarioTable from "../_components/price-scenario-table";
import { Map as IMap, Set as ISet } from "immutable";
import AdjustmentsSection from "../_components/adjustments-section";
import StipulationsSection from "../_components/stipulations-section";
import { usePermissions } from "features/roles";
import * as Stages from "features/stages";
import CalculationsSection from "../_components/calculations-section";
import CauseLink from "../_components/cause-link";
import * as T from "types/engine-types";
import ProductFieldsSection from "../_components/product-fields-section";
import { useHistory } from "react-router";
import { getRejectionReasonInfo } from "features/loans";
import CommentIcon from "@material-ui/icons/Comment";
import ClearIcon from "@material-ui/icons/Clear";
import { findScenarioFieldValue } from "features/pricing-summaries";
import * as Fields from "features/fields";
import { getObjectRefLabel } from "features/objects";
import {
  ExportWrapper,
  LogoWrapper,
  PriceScenarioTableWrapper,
} from "./styles";
import {
  expandedConfigSelector,
  objectDetailsMapSelector,
  nonNullApplicationInitializationSelector,
} from "features/application-initialization";
import Logo from "design/atoms/logo";

export default React.memo(function ExportLoansToPdfPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const rulesState = useSelector(rulesSelector);
  const loansState = useSelector(loansSelector);
  const scenario = loansState.exportedLoan?.scenario;
  const investorsState = useSelector(investorsSelector);
  const investor = investorsState.investors.find(
    (i) => i.id === loansState.exportedLoan?.product?.investorId,
  );
  const config = useSelector(expandedConfigSelector);
  const objectDetails = useSelector(objectDetailsMapSelector);
  const rulesById = useById(rulesState.rules);
  const hasPermission = usePermissions();
  const hasProfitViewPerm = hasPermission(
    "pricing-profit-margin-adjustments-view",
  );
  const { myRole, myDefaultFieldValues, myPricingProfile } = useSelector(
    nonNullApplicationInitializationSelector,
  );
  const calcStagesByScope = Stages.getCalcStagesByScope(config);
  let priceScenarioCalculatedFieldValuesById: IMap<
    T.FieldId,
    T.FieldValue | null
  >;
  let productFieldValuesById: IMap<T.FieldId, T.FieldValue | null> = IMap([]);
  let productCalculatedFieldValuesById: IMap<T.FieldId, T.FieldValue | null> =
    IMap([]);
  const effectiveTimestamp =
    loansState?.exportedLoan?.result?.status === "ok"
      ? loansState.exportedLoan.result.rateSheetEffectiveTimestamp
      : null;
  const rejectionReasons =
    scenario?.status === "rejected"
      ? getRejectionReasonInfo(config, rulesById, scenario.rejections)
      : [];
  const reviewRequirementReasons =
    scenario?.status === "review-required" || scenario?.status === "rejected"
      ? getRejectionReasonInfo(config, rulesById, scenario.reviewRequirements)
      : [];

  if (scenario?.calculatedFields) {
    priceScenarioCalculatedFieldValuesById = IMap(
      [...scenario.calculatedFields].map((mapping) => [
        mapping.fieldId,
        mapping.value,
      ]),
    );
  }

  const priceScenarios =
    loansState?.exportedLoan?.result?.status === "ok"
      ? loansState.exportedLoan.result.priceScenarios
      : [];

  if (
    loansState?.exportedLoan?.result?.productFields &&
    loansState?.exportedLoan?.result.status === "ok"
  ) {
    productFieldValuesById = IMap(
      [...loansState.exportedLoan.result.productFields].map((mapping) => [
        mapping.fieldId,
        mapping.value,
      ]),
    );
  }

  if (loansState?.exportedLoan?.result?.calculatedFields) {
    productCalculatedFieldValuesById = IMap(
      [...loansState.exportedLoan.result.calculatedFields].map((mapping) => [
        mapping.fieldId,
        mapping.value,
      ]),
    );
  }

  useEffect(() => {
    if (investorsState.investors.length === 0) dispatch(getInvestors());
  }, [dispatch, investorsState.investors.length]);

  useEffect(() => {
    if (rulesState.rules.length === 0) dispatch(getRules());
  }, [dispatch, rulesState.rules.length]);

  const { client } = useSelector(nonNullApplicationInitializationSelector);

  if (
    !myPricingProfile ||
    !myRole ||
    !loansState.exportedLoan?.product ||
    !scenario ||
    !loansState.exportedLoan.result ||
    !loansState.currentFormValues ||
    !investor ||
    !config.priceScenarioTableFieldInfo
  ) {
    console.warn("something was invalid, redirecting back to /loan-pricing");
    history.push(`/c/${client.accessId}/loan-pricing`);
    return <></>;
  } else {
    let allColumnFields: T.BaseFieldDefinition[];
    switch (config.priceScenarioTableFieldInfo.type) {
      case "rate-with-columns": {
        const { adjustedRateField, columnFields } =
          config.priceScenarioTableFieldInfo;
        allColumnFields = [adjustedRateField, ...columnFields];
        break;
      }
      case "rate-with-lock-period":
        const {
          adjustedRateField,
          adjustedRateLockPeriodField,
          adjustedPriceField,
          extraColumnFields,
        } = config.priceScenarioTableFieldInfo;
        allColumnFields = [
          adjustedRateField,
          adjustedRateLockPeriodField,
          adjustedPriceField,
          ...extraColumnFields,
        ];
        break;
    }

    const columnFieldsMarkup = allColumnFields.map((fieldDef) => {
      const fieldValue = scenario
        ? findScenarioFieldValue(fieldDef.id, scenario)
        : null;

      return (
        <RenderedField
          key={fieldDef.id}
          name={fieldDef.name}
          value={Fields.fieldValueToString(
            config,
            objectDetails,
            fieldDef.valueType,
            fieldValue,
          )}
          align="left"
          width="200px"
        />
      );
    });

    const effectiveFieldValues = resolveEffectiveFieldValues(
      config.creditApplicationFields,
      loansState.currentFormValues,
      myDefaultFieldValues ?? [],
    );

    const status = scenario.status;

    return (
      <ExportWrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <div style={{ display: "flex" }}>
          <Button
            data-selector="export-pdf-back-button"
            className="hide-from-print"
            style={{ margin: "12px 8px 12px auto", display: "block" }}
            variant="outlined"
            color="primary"
            onClick={() => history.goBack()}
          >
            Back
          </Button>

          <Button
            className="hide-from-print"
            style={{ margin: "12px auto 12px 8px", display: "block" }}
            variant="outlined"
            color="primary"
            data-selector="export-page-pdf"
            onClick={() => window.print()}
          >
            Print to PDF
          </Button>
        </div>

        <div style={{ margin: "12px", display: "flex" }}>
          <RenderedField
            name="Role"
            value={myRole.name}
            align="left"
            width="200px"
          />
          <RenderedField
            name="Pricing Profile"
            value={myPricingProfile.name}
            align="left"
            width="200px"
          />
        </div>

        <div style={{ margin: "12px", display: "flex" }}>
          <RenderedField
            name="Product Name"
            value={loansState.exportedLoan.product.name}
            align="left"
            width="200px"
          />

          <RenderedField
            name="Product Code"
            value={loansState.exportedLoan.product.code}
            align="left"
            width="200px"
          />

          <RenderedField
            name="Investor Name"
            value={investor.name}
            align="left"
            width="200px"
          />

          <RenderedField
            name="Investor Code"
            value={investor.code}
            align="left"
            width="200px"
          />
        </div>

        <div style={{ margin: "12px", display: "flex", flexWrap: "wrap" }}>
          <RenderedField
            name="Status"
            value={loanStatusToString(status)}
            align="left"
            width="200px"
          />
          {columnFieldsMarkup}
        </div>

        {scenario.status === "rejected" && (
          <>
            <MessageSection>
              Given the information provided, this loan would not meet this
              product's eligibility guidelines.
              <br />
              {rejectionReasons.length === 1
                ? "The following reason was "
                : `The following ${rejectionReasons.length} reasons were `}
              given for the rejection:
            </MessageSection>
            {rejectionReasons.map((reason, reasonIndex) => (
              <MessageSection key={reasonIndex} icon={<ClearIcon />} isListItem>
                {reason.title}
                {reason.cause && (
                  <>
                    {" "}
                    (
                    <CauseLink
                      title={reason.cause.name}
                      link={reason.cause.link}
                    />
                    )
                  </>
                )}
              </MessageSection>
            ))}
            {reviewRequirementReasons.length > 0 && (
              <>
                <MessageSection>
                  Furthermore, if the above rejection reasons were not present,
                  this loan would still be subject to further review for the
                  following{" "}
                  {reviewRequirementReasons.length === 1
                    ? "reason"
                    : `${reviewRequirementReasons.length} reasons`}{" "}
                  reasons before it could be approved:
                </MessageSection>
                {reviewRequirementReasons.map((reason, reasonIndex) => (
                  <MessageSection
                    key={reasonIndex}
                    icon={<CommentIcon />}
                    isListItem
                  >
                    {reason.title}
                    {reason.cause && (
                      <>
                        {" "}
                        (
                        <CauseLink
                          title={reason.cause.name}
                          link={reason.cause.link}
                        />
                        )
                      </>
                    )}
                  </MessageSection>
                ))}
              </>
            )}
          </>
        )}

        {scenario.status === "review-required" && (
          <>
            <MessageSection>
              Given the information provided, this loan requires further review
              before it can be approved. Pricing may change upon review. The
              following{" "}
              {scenario.reviewRequirements.length === 1
                ? `reason was`
                : `${scenario.reviewRequirements.length} reasons were`}{" "}
              given for this requirement:
            </MessageSection>
            {reviewRequirementReasons.map((reason, reasonIndex) => (
              <MessageSection
                key={reasonIndex}
                icon={<CommentIcon />}
                isListItem
              >
                {reason.title}
                {reason.cause && (
                  <>
                    {" "}
                    (
                    <CauseLink
                      title={reason.cause.name}
                      link={reason.cause.link}
                    />
                    )
                  </>
                )}
              </MessageSection>
            ))}
          </>
        )}

        <div style={{ margin: "12px", display: "flex", flexWrap: "wrap" }}>
          <Box style={{ fontSize: "1.25rem", width: "100%" }}>Form Inputs</Box>
          {effectiveFieldValues.map((f) => {
            const name = config.allFieldsById.get(f.fieldId)?.name ?? "";
            switch (f.value.type) {
              case "string":
                return (
                  <RenderedField
                    name={name}
                    value={f.value.value}
                    align="left"
                    width="200px"
                  />
                );
              case "number":
                return (
                  <RenderedField
                    name={name}
                    value={f.value.value}
                    align="left"
                    width="200px"
                  />
                );
              case "duration":
                return (
                  <RenderedField
                    name={name}
                    value={`${f.value.count} ${f.value.unit}`}
                    align="left"
                    width="200px"
                  />
                );
              case "date":
                return (
                  <RenderedField
                    name={name}
                    value={f.value.value}
                    align="left"
                    width="200px"
                  />
                );
              case "enum":
                const obj = config?.enumTypes?.find((t) => {
                  return f.value.type === "enum" && t.id === f.value.enumTypeId;
                });
                const variant = obj?.variants.find(
                  (v) => f.value.type === "enum" && v.id === f.value.variantId,
                );
                return (
                  <RenderedField
                    name={name}
                    value={variant?.name || ""}
                    align="left"
                    width="200px"
                  />
                );
              case "object-ref":
                const objectLabel = getObjectRefLabel(
                  objectDetails,
                  f.value.objectRef,
                );
                return (
                  <RenderedField
                    name={name}
                    value={objectLabel}
                    align="left"
                    width="200px"
                  />
                );
              default:
                return unreachable(f.value);
            }
          })}
        </div>

        <div style={{ margin: "12px" }}>
          {(scenario.status === "approved" ||
            scenario.status === "review-required" ||
            scenario.status === "rejected") && (
            <>
              <AdjustmentsSection
                adjustments={ISet(scenario.rateAdjustments)}
                adjustmentKindName="Rate"
                rulesById={rulesById}
              />
              <AdjustmentsSection
                adjustments={ISet(scenario.priceAdjustments)}
                adjustmentKindName="Price"
                rulesById={rulesById}
              />
              {scenario.marginAdjustments && hasProfitViewPerm && (
                <AdjustmentsSection
                  adjustments={ISet(scenario.marginAdjustments)}
                  adjustmentKindName="Profit Margin Price"
                  rulesById={rulesById}
                />
              )}
              <StipulationsSection
                stipulations={ISet(scenario.stipulations)}
                rulesById={rulesById}
              />
            </>
          )}
        </div>

        <div style={{ margin: "12px" }}>
          {loansState.exportedLoan.result.status === "ok" && (
            <ProductFieldsSection
              config={config}
              fieldValuesById={productFieldValuesById}
            />
          )}
          {calcStagesByScope.product.map((stage) => (
            <CalculationsSection
              key={stage.id}
              config={config}
              stage={stage}
              fieldValuesById={productCalculatedFieldValuesById}
            />
          ))}

          {calcStagesByScope.priceScenario.map((stage) => (
            <CalculationsSection
              config={config}
              stage={stage}
              fieldValuesById={priceScenarioCalculatedFieldValuesById}
            />
          ))}
        </div>

        <PriceScenarioTableWrapper>
          <Box mb={3} fontSize={"1.25rem"}>
            Adjusted Pricing
          </Box>
          {effectiveTimestamp && (
            <Box mb={3} fontSize={16}>
              Effective as of: {formatDateTime(effectiveTimestamp)}
            </Box>
          )}

          <PriceScenarioTable
            result={loansState.exportedLoan.result}
            product={loansState.exportedLoan.product}
            isInvestorPricingEnabled={
              loansState.exportedLoan.result.isPricingEnabled
            }
            scenarios={ISet(priceScenarios)}
            config={config}
            objectDetails={objectDetails}
            rulesById={rulesById}
          />
        </PriceScenarioTableWrapper>

        <div style={{ display: "flex" }}>
          <Button
            className="hide-from-print"
            style={{ margin: "12px 8px 12px auto", display: "block" }}
            variant="outlined"
            color="primary"
            onClick={() => history.goBack()}
          >
            Back
          </Button>

          <Button
            className="hide-from-print"
            style={{ margin: "12px auto 12px 8px", display: "block" }}
            variant="outlined"
            color="primary"
            onClick={() => window.print()}
          >
            Print to PDF
          </Button>
        </div>
      </ExportWrapper>
    );
  }
});
