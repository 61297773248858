import { Map as IMap, Set as ISet } from "immutable";
import React, { useState } from "react";
import {
  Box,
  Dialog,
  Fade,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Loop,
  DragIndicator,
  HighlightOff,
  AspectRatio,
} from "@material-ui/icons";
import { useDrag } from "react-dnd";
// import { Animated } from "react-animated-css";

import * as T from "types/engine-types";
import ConditionDroppableZone from "../condition-droppable-zone";
import {
  FieldPredicateEditor,
  newFieldPredicateState,
  convertFieldPredicateToState,
  convertStateToFieldPredicate,
  validateFieldPredicateState,
} from "../field-predicate-value-editor";
import { Configuration } from "config";
import { ItemTypes, DragItem } from "types/dnd-item-types";
import { Setter, usePropertySetter, generateSafeId } from "features/utils";
import { fieldPredicateToString } from "features/field-predicates";
import { ObjectDetails } from "features/objects";

type RuleConditionNodeId = string & { ruleConditionStateIdBrand: unknown };

type RuleConditionNode = Readonly<
  | {
      type: "and";
      childIds: readonly RuleConditionNodeId[];
    }
  | {
      type: "or";
      childIds: readonly RuleConditionNodeId[];
    }
  | {
      type: "field-predicate";
      predicate: T.FieldPredicate;
    }
>;

export type RuleConditionState = {
  conditionsById: IMap<RuleConditionNodeId, RuleConditionNode | null>;
  rootId: RuleConditionNodeId;
};

export function newRuleConditionState(): RuleConditionState {
  const rootId = generateSafeId() as RuleConditionNodeId;

  const conditionsById = IMap<
    RuleConditionNodeId,
    RuleConditionNode | null
  >().set(rootId, { type: "and", childIds: [] });

  return { rootId, conditionsById };
}

const fgColor = "rgba(0, 0, 0, 0.87)";
const hoverFgColor = fgColor;
const bgColor = "#ccc";
const hoverBgColor = "rgba(0, 0, 0, 0.67)";
const switchButtonBorderColor = "rgba(0,0,0,0.23)";
const hoverSwitchButtonBorderColor = switchButtonBorderColor || "white";
const { newrelic } = window;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ruleConditionRootContainer: {
      width: "100%",
    },
    ruleBox: {
      width: "100%",
    },
    ruleContainer: {
      display: "flex",
    },
    ruleContainerButtons: {
      marginRight: theme.spacing(1),
    },
    ruleContainerHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: -2,
    },
    predicateEditor: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        backgroundColor: "red",
        width: 270,
      },
    },
    rulePredicateViewerConatiner: {
      display: "flex",
      borderLeft: "solid 2px",
      transition: "border-color 0.5s",
      position: "relative",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    rulePredicateViewerTreeBox: {
      marginRight: "5px",
      width: "30px",
      marginTop: "20px",
      position: "relative",
      transition: "border 0.5s",
    },
    treeBoxVerticalLine: {
      position: "absolute",
      width: "2px",
      height: "100%",
      left: "-2px",
      transition: "background 0.5s",
    },
    dialogTitleContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
  }),
);

export function convertRuleConditionToState(
  rootCondition: T.RuleCondition,
): RuleConditionState {
  let conditionsById = IMap<RuleConditionNodeId, RuleConditionNode | null>();
  let state: RuleConditionNode | null = null;

  switch (rootCondition.type) {
    case "and":
    case "or":
      const childIds: RuleConditionNodeId[] = [];
      rootCondition.children.forEach((child) => {
        const childStateTree = convertRuleConditionToState(child);
        childIds.push(childStateTree.rootId);
        conditionsById = IMap([
          ...conditionsById.toArray(),
          ...childStateTree.conditionsById.toArray(),
        ]);
      });
      state = { type: rootCondition.type, childIds };

      break;
    case "field-predicate":
      state = {
        type: "field-predicate",
        predicate: rootCondition?.predicate,
      };
      break;
    default:
      break;
  }

  const rootId = generateSafeId() as RuleConditionNodeId;

  conditionsById = conditionsById.set(rootId, state);

  return { rootId, conditionsById };
}

export function convertStateToRuleCondition(
  state: RuleConditionState,
): T.RuleCondition {
  const conditionsById = state.conditionsById;

  function recurse(childId: RuleConditionNodeId): T.RuleCondition {
    return convertStateToRuleCondition({ rootId: childId, conditionsById });
  }

  const rootNode = conditionsById.get(state.rootId);

  if (!rootNode) {
    console.log(new Map(conditionsById.toArray()));
    const error = new Error(
      "Error in rule condition editor: could not find node with ID " +
        JSON.stringify(state.rootId),
    );
    newrelic.noticeError(error);
    throw error;
  }

  let ruleCondition: T.RuleCondition = { type: "and", children: [] };
  const children: T.RuleCondition[] = [];

  switch (rootNode.type) {
    case "and":
    case "or":
      rootNode.childIds.forEach((childId) => {
        children.push(recurse(childId));
      });

      ruleCondition = {
        type: rootNode.type,
        children,
      };
      break;
    case "field-predicate":
      ruleCondition = {
        type: "field-predicate",
        predicate: rootNode.predicate,
      };
      break;
  }
  return ruleCondition;
}

/*
  if type is And create an AND container and loop through children to put them inside container;
  if type is Or, create an OR container and loop through children to put them inside container;
  if type is Predicate, render text version of predicate
*/

export const RuleConditionEditor = React.memo(
  ({
    state,
    setState,
    environment,
    localFields,
    config,
    objectDetails,
  }: {
    state: RuleConditionState;
    setState: Setter<RuleConditionState>;
    environment: ISet<T.FieldId>;
    localFields: T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
    objectDetails: ObjectDetails;
  }) => {
    const C = useStyles();

    const rootId = state.rootId;
    const conditionsById = state.conditionsById;

    const setConditionsById = usePropertySetter(setState, "conditionsById");

    const [hoveringContainerId, setHoveringContainerId] =
      useState<RuleConditionNodeId | null>(null);

    const rootConditionState = conditionsById.get(rootId);

    return (
      <Box className={C.ruleConditionRootContainer}>
        {rootConditionState &&
          (rootConditionState.type === "and" ||
            rootConditionState.type === "or") && (
            <RuleContainer
              rootId={rootId}
              conditionsById={conditionsById}
              type={rootConditionState.type}
              parentId={null}
              containerId={rootId}
              childIds={rootConditionState.childIds}
              isParentDragging={false}
              hoveringContainerId={hoveringContainerId}
              setHoveringContainerId={setHoveringContainerId}
              setDroppableZoneHoveringStateInParent={() => {}}
              setConditionsById={setConditionsById}
              environment={environment}
              localFields={localFields}
              config={config}
              objectDetails={objectDetails}
            />
          )}
      </Box>
    );
  },
);

/*
  RuleContainer contains an array of predicates or child RuleContainers
  Correspond to each "and" or "or" type of condition
*/

const RuleContainer = React.memo(
  ({
    type,
    rootId,
    parentId,
    containerId,
    childIds,
    isParentDragging,
    hoveringContainerId,
    setHoveringContainerId,
    setDroppableZoneHoveringStateInParent,
    conditionsById,
    setConditionsById,
    environment,
    localFields,
    config,
    objectDetails,
  }: {
    rootId: RuleConditionNodeId;
    type: "and" | "or" | null;
    parentId: RuleConditionNodeId | null;
    containerId: RuleConditionNodeId;
    childIds: readonly RuleConditionNodeId[] | null;
    isParentDragging: boolean;
    hoveringContainerId: RuleConditionNodeId | null;
    setHoveringContainerId: React.Dispatch<
      React.SetStateAction<RuleConditionNodeId | null>
    >;
    setDroppableZoneHoveringStateInParent: (boo: boolean) => void;
    conditionsById: IMap<RuleConditionNodeId, RuleConditionNode | null>;
    setConditionsById: Setter<
      IMap<RuleConditionNodeId, RuleConditionNode | null>
    >;
    environment: ISet<T.FieldId>;
    localFields: T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
    objectDetails: ObjectDetails;
  }) => {
    const C = useStyles();
    const [open, setOpen] = useState(false);
    const [newPredicateOpen, setNewPredicateOpen] = useState(false);
    const [dialogType, setDialogType] = useState<
      "chooseContainer" | "editPredicate" | "confirmRemoval"
    >("chooseContainer");
    const [isChildDroppableZoneHovering, setIsChildDroppableZoneHovering] =
      useState<boolean>(false);

    const isHover = hoveringContainerId === containerId;

    function handleAddContainer(type: "and" | "or") {
      const state: RuleConditionNode = {
        type,
        childIds: [],
      };

      let newConditionsById = conditionsById;

      const childConditionStateId = generateSafeId() as RuleConditionNodeId;

      newConditionsById = newConditionsById.set(childConditionStateId, state);

      const containerCondition = newConditionsById.get(containerId);

      if (
        containerCondition?.type === "and" ||
        containerCondition?.type === "or"
      ) {
        const containerState: RuleConditionNode = {
          type: containerCondition.type,
          childIds: [...containerCondition.childIds, childConditionStateId],
        };
        newConditionsById = newConditionsById.set(containerId, containerState);
      }

      setConditionsById(newConditionsById);

      // document.getElementById('pageContainer')?.scrollTo({
      //   top: y - 65,
      //   left: 0,
      //   behavior: 'smooth',
      // });
    }

    // handle condition removal when the remove button is clicked
    const handleRemove = () => {
      if (parentId === null) {
        // If root node, just clear children
        setConditionsById((conditionsById) => {
          const oldCondition = conditionsById.get(containerId);

          if (!oldCondition) {
            throw new Error(
              "Condition not found: " + JSON.stringify(containerId),
            );
          }

          if (oldCondition.type === "field-predicate") {
            throw new Error("Cannot clear non-container root node");
          }

          return conditionsById.set(containerId, {
            type: oldCondition.type,
            childIds: [],
          });
        });
      } else {
        setConditionsById((conditionsById) => {
          const parentCondition = conditionsById.get(parentId);

          if (!parentCondition) {
            throw new Error("Parent condition not found");
          }

          if (parentCondition.type === "field-predicate") {
            throw new Error(
              "Cannot remove rule container from non-container node",
            );
          }

          const parentChildIds = parentCondition.childIds;
          const newParentChildIds = parentChildIds.filter(
            (id) => id !== containerId,
          );
          const newParentCondition = {
            type: parentCondition.type,
            childIds: newParentChildIds,
          };

          return conditionsById
            .delete(containerId)
            .set(parentId, newParentCondition);
        });
      }
    };

    const handleConditionTypeSwitch = (e: React.MouseEvent) => {
      setConditionsById((conditionsById) => {
        const parentCondition = conditionsById.get(containerId);

        if (parentCondition) {
          if (parentCondition.type === "field-predicate") {
            return conditionsById;
          }

          const newConditionType =
            parentCondition.type === "and" ? "or" : "and";
          return conditionsById.set(containerId, {
            ...parentCondition,
            type: newConditionType,
          });
        }

        return conditionsById;
      });
    };

    /*
      When a predicate is dropped into a new container
      Get rid dragId in dragParentId.childIDs
      Add dragId to dropParentId.childIds
    */

    function handleDrop(item: DragItem, zoneIndex: number) {
      // TODO these casts shouldn't be needed
      const dragId = item.dragId as RuleConditionNodeId;
      const dragParentId = item.dragParentId as RuleConditionNodeId;

      const dragIndex = childIds?.findIndex((id) => id === dragId);

      let newConditionsById = conditionsById;

      // Remove predicate's ID from its previous parent
      const dragParentCondition = newConditionsById.get(dragParentId);
      if (
        dragParentCondition?.type === "and" ||
        dragParentCondition?.type === "or"
      ) {
        const childIds = dragParentCondition.childIds.filter(
          (id) => id !== dragId,
        );
        newConditionsById = newConditionsById.set(dragParentId, {
          type: dragParentCondition.type,
          childIds,
        });
      }

      // Add predicate's ID to its new parent, with the corresponding drop index
      const dropCondition = newConditionsById.get(containerId);

      if (
        dragIndex !== undefined &&
        dragIndex !== -1 &&
        dragIndex < zoneIndex &&
        zoneIndex > 0
      ) {
        zoneIndex--;
      }

      if (dropCondition?.type === "and" || dropCondition?.type === "or") {
        const childIds = [...dropCondition.childIds];

        childIds.splice(zoneIndex, 0, dragId);

        newConditionsById = newConditionsById.set(containerId, {
          type: dropCondition.type,
          childIds,
        });
      }

      setConditionsById(newConditionsById);
    }

    const [{ containerDragging }, dragContainer] = useDrag({
      item: {
        type: type === "and" ? ItemTypes.AND : ItemTypes.OR,
        dragId: containerId,
        dragParentId: parentId,
      },
      collect: (monitor) => ({
        containerDragging: !!monitor.isDragging(),
      }),
    });

    const handleHoveringDroppableZone = (isOver: boolean) => {
      setIsChildDroppableZoneHovering(isOver); // set current container
    };

    return (
      <div
        className={C.ruleBox}
        style={{
          marginBottom: parentId ? "0px" : "8px",
        }}
        onMouseEnter={() => {
          if (!containerDragging) {
            setHoveringContainerId(containerId);
          }
        }}
        onMouseLeave={() => {
          if (!containerDragging) {
            setHoveringContainerId(parentId);
          }
        }}
      >
        <div
          key={containerId}
          className={C.ruleContainer}
          ref={parentId ? dragContainer : null}
          style={{
            opacity: containerDragging ? 0.5 : 1,
            marginLeft: parentId ? "-6px" : 0,
          }}
        >
          <div>
            <Box
              className={C.ruleContainerHeaderContainer}
              // TODO Need mouseOver instead of hover since user may move cursor too fast before hoveringContainerId can work properly
              onMouseEnter={() => {
                if (!containerDragging) {
                  setHoveringContainerId(containerId);
                }
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "none",
                  transition: "all 0.5s",
                  color: fgColor,
                  padding: "0px 10px 0px 0px",
                }}
              >
                {parentId && (
                  <DragIndicator
                    fontSize="small"
                    style={{
                      marginLeft: "4px",
                      cursor: "grab",
                    }}
                  />
                )}
                <Button
                  endIcon={<Loop />}
                  onClick={handleConditionTypeSwitch}
                  style={{
                    padding: "2px 4px",
                    transition: "all 0.5s",
                    color: isHover ? hoverFgColor : fgColor,
                    background: "white",
                    margin: "6px 8px",
                    border: isHover
                      ? `1px solid ${hoverSwitchButtonBorderColor}`
                      : `1px solid ${switchButtonBorderColor}`,
                  }}
                >
                  {type === "and" ? "All" : "Any"}
                </Button>
                of the following are true
              </div>

              {/* <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={isHover}> */}
              <Box
                ml={3}
                style={{
                  opacity: isHover ? "1" : "0",
                  transition: "opacity 0.5s",
                }}
              >
                <Button
                  color="primary"
                  size="medium"
                  className={C.ruleContainerButtons}
                  onClick={() => {
                    setNewPredicateOpen(true);
                  }}
                >
                  New Condition
                </Button>
                <Button
                  color="primary"
                  size="medium"
                  className={C.ruleContainerButtons}
                  onClick={() => {
                    handleAddContainer("and");
                  }}
                >
                  New Group
                </Button>
                <Button
                  color="secondary"
                  size="medium"
                  className={C.ruleContainerButtons}
                  style={{ width: 76 }}
                  onClick={() => {
                    if (childIds && childIds.length > 0) {
                      setDialogType("confirmRemoval");
                      setOpen(true);
                    } else {
                      handleRemove();
                    }
                  }}
                >
                  {parentId ? "Remove" : "Clear"}
                </Button>
              </Box>
              {/* </Animated> */}
            </Box>
            <div style={{ display: "flex", marginLeft: "16px" }}>
              <div>
                <div
                  style={{
                    borderLeft: `solid 2px ${isHover ? hoverBgColor : bgColor}`,
                    transition: "border-color 0.5s",
                  }}
                >
                  {childIds?.length === 0 && (
                    <Typography
                      style={{
                        marginTop: "8px",
                        marginLeft: "24px",
                        fontSize: 14,
                        color: "#666",
                      }}
                    >
                      {parentId ? "Add or drag" : "Add"} a condition here
                    </Typography>
                  )}
                  <ConditionDroppableZone
                    droppable={!containerDragging && !isParentDragging}
                    zoneIndex={0}
                    onDrop={(item, index) => handleDrop(item, index)}
                    containerId={containerId}
                    setIsOver={handleHoveringDroppableZone}
                  />
                </div>
                <>
                  {childIds?.map((conditionId, index) => {
                    const condition = conditionsById.get(conditionId);

                    if (!condition) {
                      throw new Error(
                        "Child condition ID not recognized: " +
                          JSON.stringify(conditionId),
                      );
                    }

                    const isLastChild = childIds.length - 1 === index;
                    switch (condition.type) {
                      case "field-predicate":
                        return (
                          <div key={index}>
                            <div
                              className={C.rulePredicateViewerConatiner}
                              style={{
                                borderLeft: `solid 2px ${
                                  isHover ? hoverBgColor : bgColor
                                }`,
                              }}
                            >
                              <div
                                className={C.rulePredicateViewerTreeBox}
                                style={{
                                  marginTop: "10px",
                                  marginRight: "0",
                                  height: isLastChild ? "auto" : "1px",
                                  alignSelf: "base-line",
                                  borderTop: `solid 2px ${
                                    isHover ? hoverBgColor : bgColor
                                  }`,
                                }}
                              >
                                <div
                                  className={C.treeBoxVerticalLine}
                                  style={{
                                    background:
                                      isLastChild &&
                                      !isChildDroppableZoneHovering
                                        ? `white`
                                        : `solid 2px ${
                                            isHover ? hoverBgColor : bgColor
                                          }`,
                                  }}
                                ></div>
                              </div>
                              <RulePredicateViewer
                                conditionId={conditionId}
                                value={condition.predicate}
                                parentId={containerId}
                                conditionsById={conditionsById}
                                setHoveringContainerId={(id) => {
                                  setHoveringContainerId(id);
                                }}
                                savePredicate={(predicate: T.FieldPredicate) =>
                                  setConditionsById((conditionsById) =>
                                    conditionsById.set(conditionId, {
                                      type: "field-predicate" as const,
                                      predicate,
                                    }),
                                  )
                                }
                                setConditionsById={setConditionsById}
                                environment={environment}
                                localFields={localFields}
                                config={config}
                                objectDetails={objectDetails}
                              />
                            </div>
                            <div
                              style={{
                                borderLeft: isLastChild
                                  ? `solid 2px rgba(0,0,0,0)`
                                  : `solid 2px ${
                                      isHover ? hoverBgColor : bgColor
                                    }`,
                                transition: "border-color 0.5s",
                              }}
                            >
                              <ConditionDroppableZone
                                droppable={
                                  !containerDragging && !isParentDragging
                                }
                                zoneIndex={index + 1}
                                onDrop={(item, index) =>
                                  handleDrop(item, index)
                                }
                                containerId={containerId}
                                setIsOver={handleHoveringDroppableZone}
                              />
                            </div>
                          </div>
                        );
                      case "and":
                      case "or":
                        return (
                          <div key={conditionId}>
                            <div
                              className={C.rulePredicateViewerConatiner}
                              style={{
                                borderLeft: `solid 2px ${
                                  isHover ? hoverBgColor : bgColor
                                }`,
                              }}
                            >
                              <div
                                className={C.rulePredicateViewerTreeBox}
                                style={{
                                  height: isLastChild ? "auto" : "1px",
                                  borderTop: `solid 2px ${
                                    isHover ? hoverBgColor : bgColor
                                  }`,
                                }}
                              >
                                <div
                                  className={C.treeBoxVerticalLine}
                                  style={{
                                    background:
                                      isLastChild &&
                                      !isChildDroppableZoneHovering
                                        ? `white`
                                        : `solid 2px ${
                                            isHover ? hoverBgColor : bgColor
                                          }`,
                                  }}
                                ></div>
                              </div>
                              <RuleContainer
                                type={condition.type}
                                conditionsById={conditionsById}
                                rootId={rootId}
                                parentId={containerId}
                                containerId={conditionId}
                                childIds={condition.childIds}
                                isParentDragging={
                                  isParentDragging
                                    ? isParentDragging
                                    : containerDragging
                                }
                                hoveringContainerId={hoveringContainerId}
                                setHoveringContainerId={setHoveringContainerId}
                                setDroppableZoneHoveringStateInParent={(
                                  isOver,
                                ) => {
                                  setIsChildDroppableZoneHovering(isOver);
                                }}
                                setConditionsById={setConditionsById}
                                environment={environment}
                                localFields={localFields}
                                config={config}
                                objectDetails={objectDetails}
                              />
                            </div>
                            <div
                              style={{
                                borderLeft: isLastChild
                                  ? `solid 2px rgba(0,0,0,0)`
                                  : `solid 2px ${
                                      isHover ? hoverBgColor : bgColor
                                    }`,
                                transition: "border-color 0.5s",
                              }}
                            >
                              <ConditionDroppableZone
                                droppable={
                                  !containerDragging && !isParentDragging
                                }
                                zoneIndex={index + 1}
                                onDrop={(item, index) =>
                                  handleDrop(item, index)
                                }
                                containerId={containerId}
                                setIsOver={handleHoveringDroppableZone}
                              />
                            </div>
                          </div>
                        );
                    }
                  })}
                </>
              </div>
            </div>
          </div>
        </div>
        {newPredicateOpen && (
          <FieldPredicateModal
            savePredicate={(predicate: T.FieldPredicate) => {
              setConditionsById((conditionsById) => {
                const newNodeId = generateSafeId() as RuleConditionNodeId;
                const newNode = {
                  type: "field-predicate" as const,
                  predicate,
                };

                const containerNode = conditionsById.get(containerId);
                if (!containerNode) {
                  throw new Error("Container node not found");
                }
                if (containerNode.type === "field-predicate") {
                  throw new Error("Container node cannot be a field predicate");
                }

                return conditionsById
                  .set(containerId, {
                    ...containerNode,
                    childIds: containerNode.childIds.concat([newNodeId]),
                  })
                  .set(newNodeId, newNode);
              });
            }}
            setClose={() => setNewPredicateOpen(false)}
            conditionsById={conditionsById}
            environment={environment}
            localFields={localFields}
            config={config}
          />
        )}
        <Dialog
          className={C.modal}
          open={open}
          closeAfterTransition
          fullWidth
          maxWidth={"md"}
        >
          <Fade in={open}>
            <div
              style={{
                position: "relative",
              }}
            >
              {dialogType === "confirmRemoval" && (
                <>
                  <DialogTitle>
                    {parentId ? "Remove" : "Clear"} this group?
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      All conditions including child containers{" "}
                      {parentId
                        ? "and this group itself will be removed"
                        : "will be cleared"}
                      , but this action is not permanent until the rule is
                      saved.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => {
                        handleRemove();
                        setOpen(false);
                      }}
                    >
                      {parentId ? "Remove" : "Clear"}
                    </Button>
                  </DialogActions>
                </>
              )}
            </div>
          </Fade>
        </Dialog>
      </div>
      // </Animated>
    );
  },
);

const useRulePredicateViewerStyles = makeStyles((theme: Theme) =>
  createStyles({
    predicateContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
      "&:hover": {
        background: "#f9f9f9",
      },
    },
    ruleText: {
      margin: theme.spacing(0, 1),
      "&:hover": {
        cursor: "pointer",
      },
    },
  }),
);

const RulePredicateViewer = React.memo(
  ({
    parentId,
    conditionId,
    value,
    conditionsById,
    setHoveringContainerId,
    savePredicate,
    setConditionsById,
    environment,
    localFields,
    config,
    objectDetails,
  }: {
    conditionId: RuleConditionNodeId;
    parentId: RuleConditionNodeId;
    value: T.FieldPredicate;
    conditionsById: IMap<RuleConditionNodeId, RuleConditionNode | null>;
    setHoveringContainerId: (id: RuleConditionNodeId) => void;
    savePredicate: (p: T.FieldPredicate) => void;
    setConditionsById: Setter<
      IMap<RuleConditionNodeId, RuleConditionNode | null>
    >;
    environment: ISet<T.FieldId>;
    localFields: T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
    objectDetails: ObjectDetails;
  }) => {
    const C = useRulePredicateViewerStyles();

    const [open, setOpen] = useState(false);

    const handleRuleEdit = () => {
      setOpen(true);
    };

    const handlePredicateDelete = () => {
      setConditionsById((conditionsById) => {
        const parentCondition = conditionsById.get(parentId);

        if (!parentCondition) {
          throw new Error("Parent condition not found");
        }

        if (parentCondition.type === "field-predicate") {
          throw new Error(
            "Cannot remove rule predicate from non-container node",
          );
        }

        const parentChildIds = parentCondition.childIds;
        const newParentChildIds = parentChildIds.filter(
          (id) => id !== conditionId,
        );
        const newParentCondition = {
          type: parentCondition.type,
          childIds: newParentChildIds,
        };

        return conditionsById
          .delete(conditionId)
          .set(parentId, newParentCondition);
      });
    };

    const [isHover, setIsHover] = useState(false);

    const [{ isDragging }, dragCondition] = useDrag({
      item: {
        type: ItemTypes.PREDICATE,
        dragId: conditionId,
        dragParentId: parentId,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });

    return (
      <>
        <div
          className={C.predicateContainer}
          key={conditionId}
          ref={dragCondition}
          style={{
            opacity: isDragging ? 0.75 : 1,
            cursor: isDragging ? "move" : "pointer",
          }}
          onMouseEnter={() => {
            setIsHover(true);
            if (!isDragging) {
              setHoveringContainerId(parentId);
            }
          }}
          onMouseLeave={() => setIsHover(false)}
          onClick={handleRuleEdit}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              cursor: "grab",
            }}
          >
            <DragIndicator fontSize={"small"} />
            <div className={C.ruleText} onClick={handleRuleEdit}>
              {fieldPredicateToString(
                environment,
                localFields,
                config,
                objectDetails,
                value,
              )}
            </div>
          </div>

          <Button
            style={{
              color: "darkGray",
              visibility: isHover ? "visible" : "hidden",
              padding: 0,
              minWidth: "40px",
            }}
            onClick={handlePredicateDelete}
          >
            <HighlightOff />
          </Button>
        </div>
        {open && (
          <FieldPredicateModal
            initialValue={value}
            savePredicate={savePredicate}
            setClose={() => setOpen(false)}
            conditionsById={conditionsById}
            environment={environment}
            localFields={localFields}
            config={config}
          />
        )}
      </>
    );
  },
);

const FieldPredicateModal = React.memo(
  ({
    initialValue,
    setClose,
    savePredicate,
    conditionsById,
    environment,
    localFields,
    config,
  }: {
    initialValue?: T.FieldPredicate;
    savePredicate: (p: T.FieldPredicate) => void;
    setClose: () => void;
    conditionsById: IMap<RuleConditionNodeId, RuleConditionNode | null>;
    environment: ISet<T.FieldId>;
    localFields: T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
  }) => {
    const C = useStyles();

    const ruleEditorModalStyles = useStyles();

    const [showValidationErrors, setShowValidationErrors] = useState(false);

    const [state, setState] = useState(() =>
      initialValue
        ? convertFieldPredicateToState(config, initialValue)
        : newFieldPredicateState(),
    );

    const nextError = validateFieldPredicateState(environment, config, state);

    const [isExpandedView, setIsExpandedView] = useState(
      () => initialValue?.type === "enum" && initialValue.variantIds.length > 0,
    );

    const toggleIsExpandedView = () => {
      setIsExpandedView(!isExpandedView);
    };

    return (
      <>
        <Dialog
          className={ruleEditorModalStyles.modal}
          open={true}
          closeAfterTransition
          fullWidth
          maxWidth={"lg"}
        >
          <Fade in={true}>
            <div
              style={{
                position: "relative",
              }}
            >
              <div className={C.dialogTitleContainer}>
                <DialogTitle>Edit condition</DialogTitle>
                {(state.enumPredicate?.op === "matches" ||
                  state.enumPredicate?.op === "does-not-match") && (
                  <Button onClick={toggleIsExpandedView}>
                    <AspectRatio />
                  </Button>
                )}
              </div>
              <DialogContent>
                <div
                  style={{
                    position: "relative",
                    minHeight: "300px",
                  }}
                >
                  <FieldPredicateEditor
                    state={state}
                    setState={setState}
                    showValidationErrors={showValidationErrors}
                    isExpandedView={isExpandedView}
                    environment={environment}
                    localFields={localFields}
                    config={config}
                  />
                  {showValidationErrors && nextError !== null && (
                    <Typography
                      color="error"
                      style={{ maxWidth: isExpandedView ? "600px" : "270px" }}
                    >
                      Error: {nextError.message}
                    </Typography>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setClose()}>Cancel</Button>
                <Button
                  onClick={() => {
                    if (nextError) {
                      setShowValidationErrors(true);
                    } else {
                      savePredicate(
                        convertStateToFieldPredicate(
                          environment,
                          config,
                          state,
                        ),
                      );
                      setClose();
                    }
                  }}
                  color="primary"
                >
                  Save
                </Button>
              </DialogActions>
            </div>
          </Fade>
        </Dialog>
      </>
    );
  },
);
