import { Snapshot } from "..";
import * as T from "types/engine-types";
import { Set as ISet } from "immutable";

export function exportEnumTypes(
  snapshot: Snapshot,
  enumTypeIds: ISet<T.EnumTypeId>,
): T.RawEnumType[] {
  return snapshot.config.rawEnumTypes
    .filter((t) => enumTypeIds.has(t.id))
    .map((t) => {
      if (t.disposition.kind === "native") {
        return {
          oldId: null,
          id: t.id,
          disposition: {
            ...exportNativeEnumDisposition(t.disposition),
            kind: "native",
          },
        };
      } else {
        return {
          oldId: null,
          id: t.id,
          disposition: {
            ...exportInheritedEnumDisposition(t.disposition),
            kind: "inherited",
          },
        };
      }
    });
}

function exportNativeEnumDisposition(
  definition: T.NativeEnumDisposition,
): T.NativeEnumDisposition {
  return {
    name: definition.name,
    variants: definition.variants.map((v) => exportEnumVariant(v)),
  };
}

function exportInheritedEnumDisposition(
  definition: T.InheritedEnumDisposition,
): T.InheritedEnumDisposition {
  return {
    nameAlias: definition.nameAlias,
    excludeVariants: definition.excludeVariants.map((id) => id),
    includeVariants: definition.includeVariants.map((v) => ({
      id: v.id,
      nameAlias: v.nameAlias,
    })),
  };
}

function exportEnumVariant(variant: T.EnumVariant): T.EnumVariant {
  return {
    oldId: null,
    id: variant.id,
    name: variant.name,
  };
}

export function exportEnumTypeId(
  snapshot: Snapshot,
  enumTypeId: T.EnumTypeId,
): T.EnumTypeId {
  const enumType = snapshot.config.enumTypesById.get(enumTypeId);

  if (!enumType) {
    throw new Error("Enum type not found: ID = " + JSON.stringify(enumTypeId));
  }

  return enumType.id;
}

export function exportEnumVariantId(
  snapshot: Snapshot,
  enumTypeId: T.EnumTypeId,
  enumVariantId: T.EnumVariantId,
): T.EnumVariantId {
  const enumType = snapshot.config.enumTypesById.get(enumTypeId);

  if (!enumType) {
    throw new Error("Enum type not found: ID = " + JSON.stringify(enumTypeId));
  }

  const variant = enumType.variants.find((v) => v.id === enumVariantId);

  if (!variant) {
    throw new Error(
      "Enum variant not found: ID = " + JSON.stringify(enumVariantId),
    );
  }

  return variant.id;
}

export function exportEnumVariantValue(
  snapshot: Snapshot,
  value: T.EnumVariantValue,
): T.EnumVariantValue {
  return {
    enumTypeId: exportEnumTypeId(snapshot, value.enumTypeId),
    variantId: exportEnumVariantId(snapshot, value.enumTypeId, value.variantId),
  };
}
