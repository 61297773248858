import { Map as IMap, Set as ISet } from "immutable";
import _ from "lodash";
import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import * as T from "types/generated-types";
import { DecoratedRuleHeader } from "types/engine-types";
import CauseLink from "../cause-link";
import { localAccessId } from "features/access-id";

type AdjustmentValue =
  | T.RateAdjustmentValue
  | T.PriceAdjustmentValue
  | T.MarginAdjustmentValue;

const AdjustmentsSection = React.memo(
  ({
    adjustments,
    adjustmentKindName,
    rulesById,
  }: {
    adjustments: ISet<AdjustmentValue>;
    adjustmentKindName: string;
    rulesById: IMap<T.RuleId, DecoratedRuleHeader>;
  }) => {
    const sortedAdjustments = _.sortBy(adjustments.toArray(), "description");
    const accessId = localAccessId();
    let total = 0;

    for (const adj of sortedAdjustments) {
      total += +adj.amount;
    }

    return (
      <Box
        data-adjustment-kind={adjustmentKindName
          .toLowerCase()
          .replaceAll(" ", "-")}
      >
        <Box mt={3} mb={1} fontSize="1.25rem">
          {adjustmentKindName} Adjustments
        </Box>
        {adjustments.size === 0 && (
          <Box mb={3}>No {adjustmentKindName.toLowerCase()} adjustments.</Box>
        )}
        {adjustments.size > 0 && (
          <Box mb={3}>
            <Table size="small" style={{ width: "auto" }}>
              <TableHead>
                <TableRow>
                  <TableCell>{adjustmentKindName} Adjustment</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAdjustments.map((adj) => {
                  const rule = rulesById.get(adj.ruleId);
                  const ruleName = rule?.name || "<unknown rule>";

                  return (
                    <TableRow>
                      <TableCell>
                        {adj.description}
                        <> - </>
                        <CauseLink
                          title={ruleName}
                          link={`/c/${accessId}/rules/${adj.ruleId}`}
                        />
                      </TableCell>
                      <TableCell align="right">
                        {parseFloat(adj.amount).toFixed(3)}
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell style={{ fontWeight: 700 }}>
                    Total {adjustmentKindName} Adjustment
                  </TableCell>
                  <TableCell align="right" style={{ fontWeight: 700 }}>
                    {total.toFixed(3)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        )}
      </Box>
    );
  },
);

export default AdjustmentsSection;
