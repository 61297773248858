import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DoneIcon from "@material-ui/icons/Done";
import * as Api from "api";
import * as T from "types/engine-types";
import { usePropertySetter } from "features/utils";
import Loader from "react-loader";
import { SearchableDropdown } from "design/molecules/dropdown";
import { localAccessId } from "features/access-id";

export const CreateSubscriptionPage = React.memo(() => {
  const [clientsLoad, reloadClients] = Api.Admin.useClients();
  if (clientsLoad.status === "error") {
    return <>"Error"</>;
  } else if (clientsLoad.status === "loading") {
    return <Loader loaded={false} />;
  } else {
    return (
      <LoadedCreateSubscriptionPage
        clients={clientsLoad.value}
        reloadClients={reloadClients}
      />
    );
  }
});

export const LoadedCreateSubscriptionPage = React.memo(
  ({
    clients,
    reloadClients,
  }: {
    clients: T.Client[];
    reloadClients: () => void;
  }) => {
    const [state, setState] = useState(newSubscriptionState());
    const history = useHistory();
    const accessId = localAccessId();

    // Property setters
    const setSubscribingClient = usePropertySetter(
      setState,
      "subscribingClient",
    );
    const setOptions = usePropertySetter(setState, "options");
    const setInvestingClient = usePropertySetter(setState, "investingClient");
    const setInvestingRole = usePropertySetter(setState, "investingRole");
    const setInvestingPricingProfile = usePropertySetter(
      setState,
      "investingPricingProfile",
    );

    async function fullSetInvestingClient(
      client: T.Client | null,
    ): Promise<void> {
      setInvestingRole(null);
      setInvestingPricingProfile(null);
      setInvestingClient(client);

      if (client) {
        const options = await Api.Admin.clientSubscriptionOptionsGet(
          client.accessId,
        );
        setOptions(options);
      } else {
        setOptions(null);
      }
    }

    async function submit(): Promise<void> {
      const newSubcription = convertStateToNewSubscription(state);
      if (newSubcription) {
        await Api.Admin.subscriptionCreate(newSubcription);
        history.push(`/c/${accessId}/__admin/subscriptions`);
      }
    }

    const currentAccessId = localAccessId();
    const subscribingClient: T.Client | null =
      clients.find(
        (c: T.Client) => c.accessId === state.subscribingClient?.accessId,
      ) || null;
    const investingClient: T.Client | null =
      clients.find(
        (c: T.Client) => c.accessId === state.investingClient?.accessId,
      ) || null;
    const disabled = !convertStateToNewSubscription(state);
    return (
      <Box>
        <Box px={2} my={2} display="flex">
          <Button
            component={Link}
            to={`/c/${currentAccessId}/__admin/subscriptions`}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back to Subscription List
          </Button>
          <Box flex="1" />
          <Button
            variant="outlined"
            startIcon={<DoneIcon />}
            disabled={disabled}
            onClick={submit}
          >
            Create
          </Button>
        </Box>
        <Box px={2} my={2}>
          <Box my={2} style={{ width: "400px" }}>
            <SearchableDropdown<T.Client>
              label="Investing Client"
              options={clients}
              getOptionLabel={(o) => o.name}
              value={investingClient}
              setValue={fullSetInvestingClient}
            />
          </Box>
          <Box my={2} style={{ width: "400px" }}>
            <SearchableDropdown<T.Client>
              label="Subscribing Client"
              options={clients}
              getOptionLabel={(o) => o.name}
              value={subscribingClient}
              setValue={setSubscribingClient}
            />
          </Box>
          {state.investingClient && state.options && (
            <Box>
              <Box my={2} style={{ width: "400px" }}>
                <SearchableDropdown<T.RoleHeader>
                  label="Role in investing client"
                  options={state.options.roles}
                  getOptionLabel={(o) => o.name}
                  value={state.investingRole}
                  setValue={setInvestingRole}
                />
              </Box>
              <Box my={2} style={{ width: "400px" }}>
                <SearchableDropdown<T.PricingProfile>
                  label="Pricing profile in investing client"
                  options={state.options.pricing_profiles}
                  getOptionLabel={(p) => p.name || p.id}
                  value={state.investingPricingProfile}
                  setValue={setInvestingPricingProfile}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  },
);

type SubscriptionState = {
  investingClient: T.Client | null;
  subscribingClient: T.Client | null;
  investingRole: T.RoleHeader | null;
  investingPricingProfile: T.PricingProfile | null;
  options: T.ClientSubscriptionOptions | null;
};

function newSubscriptionState(): SubscriptionState {
  return {
    investingClient: null,
    subscribingClient: null,
    investingPricingProfile: null,
    investingRole: null,
    options: null,
  };
}

function convertStateToNewSubscription(
  state: SubscriptionState,
): T.NewSubscription | null {
  const {
    investingClient,
    subscribingClient,
    investingPricingProfile,
    investingRole,
  } = state;
  if (
    investingClient &&
    subscribingClient &&
    investingPricingProfile &&
    investingRole
  ) {
    return {
      investingClientId: investingClient.id,
      subscribingClientId: subscribingClient.id,
      investingProfileId: investingPricingProfile.id,
      investingRoleId: investingRole.id,
    };
  } else {
    return null;
  }
}
