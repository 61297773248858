import { Set as ISet } from "immutable";
import React, { useCallback } from "react";
import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  TextField as MaterialTextField,
  Paper,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { InvestorChangeset, InvestorId } from "types/engine-types";
import { useMemoizedOnChange } from "features/utils";

// memoize text field to reduce unneccesary re-renders
const TextField = React.memo(MaterialTextField);

export type State = {
  name: string;
  code: string;
  isPricingEnabled: boolean;
};

export function newInvestorState(): State {
  return {
    name: "",
    code: "",
    isPricingEnabled: true,
  };
}

export function investorStateHasValidationErrors(
  state: State,
  existingNames: ISet<string>,
  existingCodes: ISet<string>,
): boolean {
  return validateInvestorState(state, existingNames, existingCodes).any;
}

type InvestorStateErrors = {
  any: boolean;
  name: { any: boolean; empty: boolean; notUnique: boolean };
  code: { any: boolean; empty: boolean; notUnique: boolean };
};

function validateInvestorState(
  state: State,
  existingNames: ISet<string>,
  existingCodes: ISet<string>,
): InvestorStateErrors {
  const name = {
    empty: !state.name.trim(),
    notUnique: existingNames.contains(state.name),
  };
  const code = {
    empty: !state.code.trim(),
    notUnique: existingCodes.contains(state.code),
  };

  const err = {
    any: false,
    name: { ...name, any: name.empty || name.notUnique },
    code: { ...code, any: code.empty || code.notUnique },
  };

  err.any = err.name.any || err.code.any;

  return err;
}

export function convertInvestorToState(investor: InvestorChangeset): State {
  return { ...investor };
}

export function convertStateToInvestor(state: State): InvestorChangeset {
  return { ...state };
}

const useInvestorEditorStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      margin: theme.spacing(2),
      padding: theme.spacing(3, 0),
    },
    title: {
      margin: theme.spacing(0, 3, 4),
    },
    nameField: {
      margin: theme.spacing(0, 2, 0, 3),
      width: 300,
    },
    codeField: {
      margin: theme.spacing(0, 3, 0, 0),
      width: 220,
    },
    isPricingEnabledField: {
      margin: theme.spacing(1, 3, 0, 0),
      width: 220,
    },
  }),
);

type InvestorEditorProps = {
  title: string;
  existingNames: ISet<string>;
  existingCodes: ISet<string>;
  state: State;
  showValidationErrors: boolean;
  onChange: (newState: State) => void;
};

type Action = { type: "simple" } & {
  name?: string;
  code?: string;
  isPricingEnabled?: boolean;
  description?: string;
  activationTimestamp?: Date | null;
  deactivationTimestamp?: Date | null;
  investorId?: InvestorId;
};

const reducer: React.Reducer<State, Action> = (
  state: State,
  action: Action,
): State => {
  switch (action.type) {
    case "simple": {
      const { type, ...newValues } = action;
      return { ...state, ...newValues };
    }
  }
};

export function InvestorEditor({
  title,
  existingNames,
  existingCodes,
  state,
  showValidationErrors,
  onChange: originalOnChange,
}: InvestorEditorProps) {
  const classes = useInvestorEditorStyles();

  const onChange = useMemoizedOnChange(originalOnChange, state);

  const dispatch: React.Dispatch<Action> = useCallback(
    (action) => onChange((oldState) => reducer(oldState, action)),
    [onChange],
  );

  const onNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      dispatch({ type: "simple", name: e.target.value }),
    [dispatch],
  );

  const onCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newCode = e.target.value.toUpperCase().replace(/[^A-Z0-9-_]/g, "");

      dispatch({ type: "simple", code: newCode });
    },
    [dispatch],
  );

  const onIsPricingEnabledChange = useCallback(
    (e, checked) =>
      dispatch({ type: "simple", isPricingEnabled: checked as boolean }),
    [dispatch],
  );

  const { name, code } = state;

  const errors = validateInvestorState(state, existingNames, existingCodes);

  return (
    <Paper className={classes.contentContainer}>
      <Typography className={classes.title} variant="h4">
        {title}
      </Typography>
      <Box>
        <Box>
          <TextField
            className={classes.nameField}
            label="Investor Name"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            defaultValue={name}
            error={showValidationErrors && errors.name.any}
            helperText={
              showValidationErrors && errors.name.notUnique && "Already in use"
            }
            onChange={onNameChange}
          />
          <TextField
            className={classes.codeField}
            label="Investor Code"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={code}
            error={showValidationErrors && errors.code.any}
            helperText={
              showValidationErrors && errors.code.notUnique && "Already in use"
            }
            onChange={onCodeChange}
          />
          <FormControlLabel
            className={classes.isPricingEnabledField}
            control={
              <Switch
                checked={state.isPricingEnabled}
                onChange={onIsPricingEnabledChange}
                color="primary"
              />
            }
            label={
              state.isPricingEnabled ? "Pricing Enabled" : "Pricing Disabled"
            }
          />
        </Box>
      </Box>
    </Paper>
  );
}
