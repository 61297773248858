import {
  BuiltinFunction,
  anyT,
  booleanT,
  numberT,
  durationT,
  stringT,
  dateT,
} from "types/formulas-types";

export const builtinFunctions: readonly BuiltinFunction[] = [
  {
    name: "round",
    description:
      "Rounds the first argument to the number of decimal places specified by the second argument. When a number is halfway between two others, it is rounded toward the nearest number that is further from zero, e.g. 6.4 becomes 6, 6.5 becomes 7, -6.5 becomes -7.",
    paramNames: ["Value", "Precision"],
    typeSignatures: [
      { paramTypes: [numberT(), numberT()], returnType: numberT() },
    ],
  },
  {
    name: "roundhalfdown",
    description:
      "Rounds the first argument to the number of decimal places specified by the second argument. When a number is halfway between two others, it is rounded toward the nearest number that is closer to zero, e.g. 6.4 becomes 6, 6.5 becomes 6, -6.5 becomes -6.",
    paramNames: ["Value", "Precision"],
    typeSignatures: [
      { paramTypes: [numberT(), numberT()], returnType: numberT() },
    ],
  },
  {
    name: "roundup",
    description:
      "Rounds the first argument to the number of decimal places specified by the second argument. The number is always rounded away from zero, e.g. -6.8 becomes -7, 6.8 becomes 7.",
    paramNames: ["Value", "Precision"],
    typeSignatures: [
      { paramTypes: [numberT(), numberT()], returnType: numberT() },
    ],
  },
  {
    name: "rounddown",
    description:
      "Rounds the first argument to the number of decimal places specified by the second argument. The number is always rounded way towards zero, e.g. -6.8 becomes -6, 6.8 becomes 6.",
    paramNames: ["Value", "Precision"],
    typeSignatures: [
      { paramTypes: [numberT(), numberT()], returnType: numberT() },
    ],
  },
  {
    name: "roundbanker",
    description:
      "Rounds the first argument to the number of decimal places specified by the second argument. When a number is halfway between two others, it is rounded toward the nearest even number, e.g. 6.5 becomes 6, 7.5 becomes 8",
    paramNames: ["Value", "Precision"],
    typeSignatures: [
      { paramTypes: [numberT(), numberT()], returnType: numberT() },
    ],
  },
  {
    name: "startofmonth",
    description:
      "Takes a date and returns the date at the beginning of that month.",
    paramNames: ["Date"],
    typeSignatures: [{ paramTypes: [dateT()], returnType: dateT() }],
  },
  {
    name: "endofmonth",
    description: "Takes a date and returns the date at the end of that month.",
    paramNames: ["Date"],
    typeSignatures: [{ paramTypes: [dateT()], returnType: dateT() }],
  },
  {
    name: "startofyear",
    description:
      "Takes a date and returns the date at the beginning of that year.",
    paramNames: ["Date"],
    typeSignatures: [{ paramTypes: [dateT()], returnType: dateT() }],
  },
  {
    name: "endofyear",
    description: "Takes a date and returns the date at the end of that year.",
    paramNames: ["Date"],
    typeSignatures: [{ paramTypes: [dateT()], returnType: dateT() }],
  },
  {
    name: "min",
    description: "Picks the smaller of two values.",
    paramNames: ["First", "Second"],
    typeSignatures: [
      { paramTypes: [numberT(), numberT()], returnType: numberT() },
      { paramTypes: [durationT(), durationT()], returnType: durationT() },
    ],
  },
  {
    name: "max",
    description: "Picks the larger of two values.",
    paramNames: ["First", "Second"],
    typeSignatures: [
      { paramTypes: [numberT(), numberT()], returnType: numberT() },
      { paramTypes: [durationT(), durationT()], returnType: durationT() },
    ],
  },
  {
    name: "add",
    description: "Adds two values together.",
    paramNames: ["First", "Second"],
    typeSignatures: [
      { paramTypes: [numberT(), numberT()], returnType: numberT() },
      { paramTypes: [durationT(), durationT()], returnType: durationT() },
      { paramTypes: [dateT(), durationT()], returnType: dateT() },
      { paramTypes: [durationT(), dateT()], returnType: dateT() },
    ],
  },
  {
    name: "subtract",
    description: "Subtracts the first value from the second value.",
    paramNames: ["First", "Second"],
    typeSignatures: [
      { paramTypes: [numberT(), numberT()], returnType: numberT() },
      { paramTypes: [durationT(), durationT()], returnType: durationT() },
      { paramTypes: [dateT(), durationT()], returnType: dateT() },
      { paramTypes: [dateT(), dateT()], returnType: durationT() },
    ],
  },
  {
    name: "multiply",
    description: "Multiplies two values together.",
    paramNames: ["First", "Second"],
    typeSignatures: [
      { paramTypes: [numberT(), numberT()], returnType: numberT() },
      { paramTypes: [durationT(), numberT()], returnType: durationT() },
      { paramTypes: [numberT(), durationT()], returnType: durationT() },
    ],
  },
  {
    name: "divide",
    description: "Divides the first value from the second value.",
    paramNames: ["First", "Second"],
    typeSignatures: [
      { paramTypes: [numberT(), numberT()], returnType: numberT() },
      { paramTypes: [durationT(), numberT()], returnType: durationT() },
      { paramTypes: [durationT(), durationT()], returnType: numberT() },
    ],
  },
  {
    name: "mod",
    description: "Returns the remainder when Number is divided by Divisor",
    paramNames: ["Number", "Divisor"],
    typeSignatures: [
      { paramTypes: [numberT(), numberT()], returnType: numberT() },
    ],
  },
  {
    name: "quotient",
    description: "Returns the integer portion of Numerator / Denominator",
    paramNames: ["Numerator", "Denominator"],
    typeSignatures: [
      {
        paramTypes: [numberT(), numberT()],
        returnType: numberT(),
      },
    ],
  },
  {
    name: "power",
    description: "Raises the first value to the power of the second.",
    paramNames: ["Base", "Exponent"],
    typeSignatures: [
      { paramTypes: [numberT(), numberT()], returnType: numberT() },
    ],
  },
  {
    name: "negate",
    description:
      "Flips a number's sign. A positive number is made negative, " +
      "and a negative number is made positive.",
    paramNames: ["N"],
    typeSignatures: [{ paramTypes: [numberT()], returnType: numberT() }],
  },
  {
    name: "isblank",
    description: "Checks if the field is blank.",
    paramNames: ["Field1"],
    typeSignatures: [{ paramTypes: [anyT()], returnType: booleanT() }],
  },
  {
    name: "and",
    description: "Checks if all of the provided conditions are TRUE.",
    paramNames: ["Condition1", "Condition2"],
    typeSignatures: [
      { paramTypes: [booleanT(), booleanT()], returnType: booleanT() },
    ],
  },
  {
    name: "or",
    description: "Checks if any of the provided conditions are TRUE.",
    paramNames: ["Condition1", "Condition2"],
    typeSignatures: [
      { paramTypes: [booleanT(), booleanT()], returnType: booleanT() },
    ],
  },
  {
    name: "not",
    description: "Checks if the provided condition is FALSE.",
    paramNames: ["Condition"],
    typeSignatures: [{ paramTypes: [booleanT()], returnType: booleanT() }],
  },
  {
    name: "if",
    description: "If",
    paramNames: ["Condition", "IfTrue", "IfFalse"],
    typeSignatures: [
      { paramTypes: [booleanT(), anyT(), anyT()], returnType: anyT() },
    ],
  },
  {
    name: "concat",
    description:
      'Joins two text values together into one: concat("hello ", "world") becomes "hello world".',
    paramNames: ["Text1", "Text2"],
    typeSignatures: [
      { paramTypes: [stringT(), stringT()], returnType: stringT() },
    ],
  },
  {
    name: "pmt",
    description:
      "Calculates a loan payment amount based on a constant interest " +
      "rate and a total number of payments. The payments are assumed to be equally spaced.",
    paramNames: ["Interest_Rate", "Number_of_Payments", "Starting_Balance"],
    typeSignatures: [
      { paramTypes: [numberT(), numberT(), numberT()], returnType: numberT() },
    ],
  },
  {
    name: "ipmt",
    description:
      "Calculates a loan interest payment amount based on a constant interest " +
      "rate and a total number of payments. The payments are assumed to be equally spaced.",
    paramNames: [
      "Interest_Rate",
      "Which_Payment",
      "Number_of_Payments",
      "Starting_Balance",
    ],
    typeSignatures: [
      {
        paramTypes: [numberT(), numberT(), numberT(), numberT()],
        returnType: numberT(),
      },
    ],
  },
  {
    name: "ppmt",
    description:
      "Calculates a loan principal payment amount based on a constant interest " +
      "rate and a total number of payments. The payments are assumed to be equally spaced.",
    paramNames: [
      "Interest_Rate",
      "Which_Payment",
      "Number_of_Payments",
      "Starting_Balance",
    ],
    typeSignatures: [
      {
        paramTypes: [numberT(), numberT(), numberT(), numberT()],
        returnType: numberT(),
      },
    ],
  },
  {
    name: "pv",
    description:
      "Calculates the present value of a loan based on the per period interest rate, number of periods remaining, " +
      "the per-period payment amount, and the future value expected after the periods have passed." +
      "The future value should be 0 if the loan will be fully paid off.",
    paramNames: [
      "Interest_Rate_Per_Payment",
      "Number_of_Payments",
      "Payment_Amount_Per_Period",
      "Future_Value",
    ],
    typeSignatures: [
      {
        paramTypes: [numberT(), numberT(), numberT(), numberT()],
        returnType: numberT(),
      },
    ],
  },
  {
    name: "rate",
    description: "Calculates the interest rate per payment period of a loan.",
    paramNames: ["Number_of_Payments", "Payment_Amount", "Starting_Balance"],
    typeSignatures: [
      { paramTypes: [numberT(), numberT(), numberT()], returnType: numberT() },
    ],
  },
] as const;

function validateBuiltinFunctions() {
  for (const fn of builtinFunctions) {
    for (const typeSig of fn.typeSignatures) {
      if (fn.paramNames.length !== typeSig.paramTypes.length) {
        throw new Error(
          `builtin function ${fn.name} paramNames length does not match type signature`,
        );
      }
    }
  }
}

validateBuiltinFunctions();
