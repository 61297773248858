import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Api from "api";

export type ChangePasswordState = {
  error: string | undefined;
  status: string | undefined;
  loading: boolean;
};

const initialState: ChangePasswordState = {
  status: undefined,
  error: undefined,
  loading: false,
};

const changePasswordSlice = createSlice({
  name: "ChangePassword",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(changePassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(changePassword.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.status = "Password successfully changed";
    });
  },
  reducers: {},
});

export const changePassword = createAsyncThunk<
  string,
  {
    currentPassword: string;
    newPassword: string;
  },
  {
    rejectValue: string;
  }
>(
  "changePassword/changePassword",
  async ({ currentPassword, newPassword }, thunkAPI) => {
    try {
      await Api.changeCurrentUserPassword({
        currentPassword,
        newPassword,
      });
      return "Success";
    } catch (error) {
      let message;
      if (error instanceof Error) {
        newrelic.noticeError(error);
        message = error.message;
      } else {
        message = String(error);
      }

      throw thunkAPI.rejectWithValue(message);
    }
  },
);

export const changePasswordSelector = (state: {
  changePassword: ChangePasswordState;
}) => state.changePassword;

export default changePasswordSlice;
