import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import * as Api from "api";
import * as T from "types/engine-types";

const initialState: CustomFieldsState = {
  status: { kind: "not-loaded" },
  customFields: [],
};

export type CustomFieldsStatus =
  | { kind: "not-loaded" }
  | { kind: "loading" }
  | { kind: "loaded" }
  | { kind: "error"; error: string };

export type CustomFieldsState = {
  status: CustomFieldsStatus;
  customFields: T.EncompassCustomField[];
};

export const listCustomFields = createAsyncThunk(
  "customFields/list",
  async () => {
    return await Api.listEncompassCustomFields();
  },
);

export const deleteCustomFieldById = createAsyncThunk(
  "customFields/delete",
  async (customFieldId: T.EncompassCustomFieldId, thunkAPI) => {
    await Api.deleteEncompassCustomField(customFieldId);
    thunkAPI.dispatch(
      customFieldsSlice.actions.removeCustomField(customFieldId),
    );
  },
);

const customFieldsSlice = createSlice({
  name: "CustomFields",
  initialState,
  extraReducers: (builder) => {
    // List
    builder.addCase(listCustomFields.pending, (state) => {
      state.status = { kind: "loading" };
    });
    builder.addCase(listCustomFields.fulfilled, (state, { payload }) => {
      state.status = { kind: "loaded" };
      state.customFields = payload;
    });
    builder.addCase(listCustomFields.rejected, (state) => {
      state.status = {
        kind: "error",
        error: "An error occurred while loading custom fields",
      };
    });
  },
  reducers: {
    setCustomField: (
      state,
      { payload }: PayloadAction<T.EncompassCustomField>,
    ) => {
      let existingField = state.customFields.find(
        (field) => field.id === payload.id,
      );

      if (existingField) {
        existingField = payload;
        return {
          ...state,
          customFields: state.customFields.map((field) => {
            if (field.id === payload.id) {
              return payload;
            } else {
              return field;
            }
          }),
        };
      } else {
        return {
          ...state,
          customFields: state.customFields.concat([payload]),
        };
      }
    },
    removeCustomField: (
      state,
      { payload }: PayloadAction<T.EncompassCustomFieldId>,
    ) => {
      return {
        ...state,
        customFields: state.customFields.filter(
          (field) => field.id !== payload,
        ),
      };
    },
  },
});

export const { setCustomField, removeCustomField } = customFieldsSlice.actions;

export default customFieldsSlice.reducer;

export const encompassCustomFieldsSelector = createSelector(
  [
    (state: { encompassCustomFields: CustomFieldsState }): CustomFieldsState =>
      state.encompassCustomFields,
  ],
  (state) => state,
);
