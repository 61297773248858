import React, { useCallback, useRef, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useVirtual } from "react-virtual";
import { useSelector, useDispatch } from "react-redux";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import * as T from "types/engine-types";
import {
  dataTablesSelector,
  filteredDataTablesSelector,
  getDataTables,
  setSearchTerm,
  setSort,
} from "features/data-tables";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import { usePermissions } from "features/roles";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

export default function DataTablesPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const parentRef = useRef<HTMLDivElement>(null);
  const dataTables = useSelector(filteredDataTablesSelector);
  const dataTablesState = useSelector(dataTablesSelector);
  const { searchTerm, sortField } = dataTablesState;
  const hasPermission = usePermissions();
  const hasCreatePermissions = hasPermission("data-tables-create");
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);

  useEffect(() => {
    if (dataTables.length === 0) {
      dispatch(getDataTables());
    }
  }, [dispatch, dataTables.length]);

  const rowVirtualizer = useVirtual({
    size: dataTables?.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, []),
  });

  const onRowClick = useCallback(
    (event: unknown, dataTable: T.DataTableHeader | undefined) => {
      if (dataTable) {
        history.push(`/c/${accessId}/data-tables/${dataTable.id}`);
      }
    },
    [history, accessId],
  );

  return (
    <Table>
      <TableActions>
        <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
          <SearchInput
            label="data tables"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <ResultsCount>
          <span className="visible">
            {dataTables.length} <VisibilityIcon />
          </span>
          <span className="separator">|</span>
          <span className="total">
            {dataTablesState.dataTables.length} Total
          </span>
        </ResultsCount>
        <Button
          disabled={!hasCreatePermissions}
          className="add-data-table"
          component={Link}
          to={`/c/${accessId}/create-data-table`}
          variant="outlined"
          startIcon={<AddIcon />}
        >
          Add Data Table
        </Button>
      </TableActions>

      <TableHeader>
        <TableHeaderCell
          style={{ flexBasis: "100%" }}
          onClick={() => dispatch(setSort("name"))}
        >
          Name{" "}
          {sortField === "name" && (
            <SwapVertIcon
              style={{ color: sortField === "name" ? "#90d18c" : "inherit" }}
            />
          )}
        </TableHeaderCell>
      </TableHeader>

      {dataTables && (
        <TableBodyWrapper ref={parentRef}>
          <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
            {rowVirtualizer.virtualItems.map((virtualRow) => (
              <TableRow
                key={virtualRow.index}
                onClick={(e) => onRowClick(e, dataTables[virtualRow.index])}
                style={{
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <TableCell style={{ flexBasis: "100%" }}>
                  {dataTables[virtualRow.index].name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableBodyWrapper>
      )}
    </Table>
  );
}
