import * as T from "types/engine-types";
import {
  brokenReferencesStateSelector,
  getReferenceErrors,
} from "features/broken-references";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader";
import { Box } from "@material-ui/core";
import Table from "design/atoms/admin-table";
import { Column } from "material-table";
import Link from "design/atoms/link";
import { localAccessId } from "features/access-id";

export default function BrokenReferencesPage() {
  const refState = useSelector(brokenReferencesStateSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!refState.loaded && !refState.loading) {
      dispatch(getReferenceErrors());
    }
  });
  if (refState.loading) {
    return <Loader loaded={false} />;
  }
  if (refState.errors) {
    return <>{refState.errors}</>;
  }
  return (
    <LoadedBrokenReferencesPage brokenReferences={refState.referenceErrors} />
  );
}

function LoadedBrokenReferencesPage({
  brokenReferences,
}: {
  brokenReferences: T.Ref[];
}) {
  return (
    <div>
      <Box>
        <Box px={2} my={2}>
          <Table<DisplayableRef>
            columns={COLUMNS}
            data={brokenReferences.map(createDisplayableClient)}
          />
        </Box>
      </Box>
    </div>
  );
}

const COLUMNS: Column<DisplayableRef>[] = [
  {
    title: "Reference Source",
    field: "source",
  },
  {
    title: "Missing Target",
    field: "target",
  },
];

type DisplayableRef = {
  source: React.ReactElement;
  target: string;
};

function prettifySource(source: T.RefSource) {
  const accessId = localAccessId();
  const sourceString = JSON.stringify(source);

  switch (source.type) {
    case "adjusted-pricing-field-settings":
      return (
        <>
          {sourceString}
          Adjusted Rate, Adjusted Price, or Adjusted Rate Lock Period field
          settings (on{" "}
          <Link to={`/c/${accessId}/rate-sheets`}>Rate Sheets page</Link>)
        </>
      );
    case "application-field":
      return <Link to={`/c/${accessId}/fields`}>{sourceString}</Link>;
    case "calculation-field":
    case "calculation-lookup":
    case "calculation-lookup-field":
      return <Link to={`/c/${accessId}/calculations/`}>{sourceString}</Link>;
    case "data-table":
    case "data-table-column":
    case "data-table-body":
      return (
        <Link to={`/c/${accessId}/data-tables/${source.tableId}`}>
          {sourceString}
        </Link>
      );
    case "product":
      return (
        <Link to={`/c/${accessId}/products/${source.productId}`}>
          {sourceString}
        </Link>
      );
    case "product-field":
      return <Link to={`/c/${accessId}/fields`}>{sourceString}</Link>;
    case "rule":
      return (
        <Link to={`/c/${accessId}/rules/${source.ruleId}`}>{sourceString}</Link>
      );
    default:
      return <p>{sourceString}</p>;
  }
}

function createDisplayableClient(ref: T.Ref): DisplayableRef {
  return {
    source: prettifySource(ref.source),
    target: JSON.stringify(ref.target, undefined, 1),
  };
}
