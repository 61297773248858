import { Map as IMap } from "immutable";
import React from "react";
import { Configuration } from "config";
import * as T from "types/generated-types";
import { useVisibleFields } from "features/roles";
import { BaseFieldDefinition } from "types/engine-types";
import KeyValue, { KeyValueGroup } from "design/atoms/key-value";
import Accordion from "design/molecules/accordion";
import * as Fields from "features/fields";
import { objectDetailsMapSelector } from "features/application-initialization";
import { useSelector } from "react-redux";

const CalculationsSection = React.memo(
  ({
    config,
    stage,
    fieldValuesById,
    color,
  }: {
    config: Configuration;
    stage: T.ExecutionStage.Calculations;
    fieldValuesById: IMap<T.FieldId, T.FieldValue | null>;
    color: string;
  }) => {
    const hasVisibleField = useVisibleFields();
    const objectDetails = useSelector(objectDetailsMapSelector);

    const list = stage.calculations
      .flatMap((calc): BaseFieldDefinition[] =>
        calc.type === "field"
          ? [{ ...calc.field, description: null }]
          : calc.lookup.fields.map((f) => ({
              ...f,
              description: null,
            })),
      )
      .filter((field) => hasVisibleField(field.id));

    if (!list.length) {
      return <></>;
    }

    return (
      <Accordion color={color} open={true} label={stage.name}>
        <KeyValueGroup>
          {list.map((field) => {
            const fieldValue = fieldValuesById.get(field.id);

            if (fieldValue) {
              const value = Fields.fieldValueToString(
                config,
                objectDetails,
                field.valueType,
                fieldValue,
              );

              return (
                <KeyValue
                  key={field.id}
                  label={field.name}
                  value={value || "&mdash;"}
                />
              );
            } else {
              return (
                <KeyValue key={field.id} label={field.name} value={"&mdash;"} />
              );
            }
          })}
        </KeyValueGroup>
      </Accordion>
    );
  },
);

export default CalculationsSection;
