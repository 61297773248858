import * as T from "../../types/engine-types";
import { jobStatus } from "../../api";
import { sleep, unreachable } from "../utils";

/**
 * Repeatedly check the status of a job, until the job has finished. If the job
 * doesn't finish before the max number of polls is reached, then the last
 * job status will be returned instead. The job will be polled at least once.
 */
export async function pollJobStatus(
  jobId: T.JobId,
  opts: { pollRateSeconds?: number; maxPolls?: number } = {},
): Promise<T.JobStatus> {
  const maxPolls = opts.maxPolls ?? 100;
  const pollRateSeconds = opts.pollRateSeconds ?? 3;

  let lastStatus = await jobStatus(jobId);
  console.info(
    `Polling job ${jobId as string}, current status is ${lastStatus.status}`,
  );

  // We subtract 1 from this loop because we already polled once
  for (let i = 0; i < maxPolls - 1; ++i) {
    if (isJobComplete(lastStatus)) {
      break;
    }

    await sleep(pollRateSeconds * 1000);

    const currentStatus = await jobStatus(jobId);
    if (currentStatus.status !== lastStatus.status) {
      console.info(
        `Job ${jobId as string} changed status to ${currentStatus.status}`,
      );
    }

    lastStatus = currentStatus;
  }

  return lastStatus;
}

function isJobComplete(jobStatus: T.JobStatus): boolean {
  switch (jobStatus.status) {
    case "pending":
    case "running":
      return false;
    case "complete":
    case "failed":
      return true;
    default:
      return unreachable(jobStatus);
  }
}
