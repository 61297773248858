import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import {
  summarySelector,
  setSort,
  dynamicFilterSelector,
  ExtraColumnsFilters,
  uniqueInvestorNamesSelector,
  filteredInvestorsSelector,
  uniqueLockSelector,
} from "features/pricing-summaries";
import Popover from "design/layout/popover";
import MultiSelect from "../filter-multi-select";
import NumberFilterInput from "../number-filter-input";
import { SmallText } from "design/atoms/typography";
import {
  ManageFilters,
  FilterWrapper,
  RowWrapper,
  FilterCard,
  FilterText,
  InnerRowWrapper,
} from "./styles";

export default React.memo(({ className }: { className?: string }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const dynamicFilters = useSelector(dynamicFilterSelector);
  const summaryState = useSelector(summarySelector);
  const locks = useSelector(uniqueLockSelector);
  const withApproved = summaryState?.summary?.products.some(
    (s) => s.status === "approved" || s.status === "review-required",
  );
  const lockOptions = useSelector(uniqueLockSelector);
  const investorNames = useSelector(uniqueInvestorNamesSelector);
  const filteredInvestors = useSelector(filteredInvestorsSelector);
  const investorFilterCount = investorNames.length - filteredInvestors.length;

  const investorFiltered =
    filteredInvestors.length &&
    filteredInvestors.length !== investorNames.length;

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);

    params.set("sortField", summaryState.sortField);
    params.set("sortDir", summaryState.sortDir);

    if (summaryState.searchTerm) {
      params.set("searchTerm", summaryState.searchTerm);
    } else {
      params.delete("searchTerm");
    }

    if (summaryState.filters) {
      params.set("filters", JSON.stringify(summaryState.filters));
    } else {
      params.delete("filters");
    }

    if (summaryState.dynamicFilters) {
      params.set("dynamicFilters", JSON.stringify(summaryState.dynamicFilters));
    } else {
      params.delete("dynamicFilters");
    }

    history.push(
      `${location.pathname}?${params.toString()}${
        location.hash ? "#" + location.hash.replaceAll("#", "") : ""
      }`,
    );
  }, [
    history,
    location.hash,
    location.pathname,
    summaryState.sortField,
    summaryState.sortDir,
    summaryState.searchTerm,
    summaryState.filters,
    summaryState.dynamicFilters,
    investorFiltered,
  ]);

  return (
    <ManageFilters
      className={
        className
          ? `page-component-loan-pricing-manage-filters ${className}`
          : "page-component-loan-pricing-manage-filters"
      }
    >
      <RowWrapper>
        <FilterWrapper>
          <FilterCard
            className={
              summaryState.searchTerm?.length ||
              summaryState.sortField === "productName"
                ? "active"
                : "inactive"
            }
          >
            <FilterText>Product Name</FilterText>

            {!summaryState.searchTerm?.length && (
              <SmallText>Not currently filtering</SmallText>
            )}

            {!!summaryState.searchTerm?.length && (
              <SmallText>Matching "{summaryState.searchTerm}"</SmallText>
            )}

            <span
              className="sort"
              onClick={() => {
                if (summaryState.sortField === "productName") {
                  if (summaryState.sortDir === "asc") {
                    dispatch(setSort("desc"));
                  } else {
                    dispatch(setSort("asc"));
                  }
                } else {
                  dispatch(setSort("productName"));
                }
              }}
            >
              {summaryState.sortField === "productName" ? (
                summaryState.sortDir === "asc" ? (
                  <FontAwesomeIcon icon={faSortUp} />
                ) : (
                  <FontAwesomeIcon icon={faSortDown} />
                )
              ) : (
                <FontAwesomeIcon icon={faSort} />
              )}
            </span>
          </FilterCard>
        </FilterWrapper>
      </RowWrapper>

      <RowWrapper>
        <InnerRowWrapper>
          <FilterWrapper>
            <FilterCard
              className={
                summaryState.sortField === "investorName" || investorFiltered
                  ? "active"
                  : "inactive"
              }
            >
              <FilterText>Investors</FilterText>

              {!investorFiltered && (
                <SmallText>Not currently filtering</SmallText>
              )}

              {!!investorFiltered && (
                <SmallText>
                  Filtering out {investorFilterCount}
                  &nbsp;investor
                  {investorFilterCount === 1 ? "" : "s"}
                </SmallText>
              )}

              <Popover
                closeOnClick={false}
                trigger={
                  <FontAwesomeIcon
                    style={{ marginRight: "0px" }}
                    icon={faFilter}
                  />
                }
                content={<MultiSelect />}
                delayShow={500}
              />
              <span
                className="sort"
                onClick={() => {
                  if (summaryState.sortField === "investorName") {
                    if (summaryState.sortDir === "asc") {
                      dispatch(setSort("desc"));
                    } else {
                      dispatch(setSort("asc"));
                    }
                  } else {
                    dispatch(setSort("investorName"));
                  }
                }}
              >
                {summaryState.sortField === "investorName" ? (
                  summaryState.sortDir === "asc" ? (
                    <FontAwesomeIcon icon={faSortUp} />
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} />
                  )
                ) : (
                  <FontAwesomeIcon icon={faSort} />
                )}
              </span>
            </FilterCard>
          </FilterWrapper>
          {withApproved &&
            dynamicFilters.sortedFilters.map((sort) => {
              switch (sort.valueType.type) {
                case "duration": {
                  const durationFiltered =
                    summaryState.filters.rateLockPeriods &&
                    locks.length !==
                      summaryState.filters.rateLockPeriods.length;

                  return (
                    <FilterWrapper key={sort.id}>
                      <FilterCard
                        className={
                          summaryState.sortField === "arlp" || durationFiltered
                            ? "active"
                            : "inactive"
                        }
                      >
                        <FilterText>{sort.name}</FilterText>

                        {!durationFiltered && (
                          <SmallText>Not currently filtering</SmallText>
                        )}

                        {durationFiltered && (
                          <SmallText>
                            Filtering out{" "}
                            {(lockOptions.length || 0) -
                              (summaryState.filters.rateLockPeriods?.length ||
                                0)}{" "}
                            lock option
                            {summaryState.filters.rateLockPeriods?.length === 1
                              ? ""
                              : "s"}
                          </SmallText>
                        )}

                        <Popover
                          closeOnClick={false}
                          trigger={
                            <FontAwesomeIcon
                              style={{ marginRight: "0px" }}
                              icon={faFilter}
                            />
                          }
                          content={<MultiSelect field={sort} />}
                          delayShow={500}
                        />
                        <span
                          className="sort"
                          onClick={() => {
                            if (summaryState.sortField === "arlp") {
                              if (summaryState.sortDir === "asc") {
                                dispatch(setSort("desc"));
                              } else {
                                dispatch(setSort("asc"));
                              }
                            } else {
                              dispatch(setSort("arlp"));
                            }
                          }}
                        >
                          {summaryState.sortField === "arlp" ? (
                            summaryState.sortDir === "asc" ? (
                              <FontAwesomeIcon icon={faSortUp} />
                            ) : (
                              <FontAwesomeIcon icon={faSortDown} />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} />
                          )}
                        </span>
                      </FilterCard>
                    </FilterWrapper>
                  );
                }
                case "number": {
                  for (const [key, value] of Object.entries(
                    dynamicFilters.filtersObj,
                  )) {
                    if (value) {
                      if (
                        typeof value !== "string" &&
                        typeof value !== "boolean" &&
                        "id" in value
                      ) {
                        if (sort.id === value.id) {
                          switch (key) {
                            case "adjustedRateFieldId": {
                              const rateValues =
                                dynamicFilters.filtersObj.adjustedRateFieldId
                                  ?.values;

                              const rateFiltered =
                                rateValues?.max || rateValues?.min;

                              return (
                                <FilterWrapper key={sort.id}>
                                  <FilterCard
                                    className={
                                      summaryState.sortField ===
                                        "adjustedRate" || rateFiltered
                                        ? "active"
                                        : "inactive"
                                    }
                                  >
                                    <FilterText>{sort.name}</FilterText>

                                    {!rateFiltered && (
                                      <SmallText>
                                        Not currently filtering
                                      </SmallText>
                                    )}

                                    {rateFiltered &&
                                      rateValues?.min &&
                                      !rateValues?.max && (
                                        <SmallText>
                                          Filtering out &lt;{" "}
                                          {rateValues?.min || 0}
                                        </SmallText>
                                      )}

                                    {rateFiltered &&
                                      !rateValues?.min &&
                                      rateValues?.max && (
                                        <SmallText>
                                          Filtering out &gt;{" "}
                                          {rateValues?.max || 0}
                                        </SmallText>
                                      )}

                                    {rateFiltered &&
                                      rateValues?.min &&
                                      rateValues?.max && (
                                        <SmallText>
                                          Filtering out &lt;{" "}
                                          {rateValues?.min || 0} and &gt;{" "}
                                          {rateValues?.max || 0}
                                        </SmallText>
                                      )}

                                    <Popover
                                      closeOnClick={false}
                                      trigger={
                                        <FontAwesomeIcon
                                          style={{ marginRight: "0px" }}
                                          icon={faFilter}
                                        />
                                      }
                                      content={
                                        <NumberFilterInput
                                          filterType={"rateField"}
                                          field={sort}
                                        />
                                      }
                                      delayShow={500}
                                    />
                                    <span
                                      className="sort"
                                      onClick={() => {
                                        if (
                                          summaryState.sortField ===
                                          "adjustedRate"
                                        ) {
                                          if (summaryState.sortDir === "asc") {
                                            dispatch(setSort("desc"));
                                          } else {
                                            dispatch(setSort("asc"));
                                          }
                                        } else {
                                          dispatch(setSort("adjustedRate"));
                                        }
                                      }}
                                    >
                                      {summaryState.sortField ===
                                      "adjustedRate" ? (
                                        summaryState.sortDir === "asc" ? (
                                          <FontAwesomeIcon icon={faSortUp} />
                                        ) : (
                                          <FontAwesomeIcon icon={faSortDown} />
                                        )
                                      ) : (
                                        <FontAwesomeIcon icon={faSort} />
                                      )}
                                    </span>
                                  </FilterCard>
                                </FilterWrapper>
                              );
                            }
                            case "adjustedPriceFieldId": {
                              const priceValues =
                                dynamicFilters.filtersObj.adjustedPriceFieldId
                                  ?.values;
                              const priceFiltered =
                                priceValues?.max || priceValues?.min;

                              return (
                                <FilterWrapper key={sort.id}>
                                  <FilterCard
                                    className={
                                      summaryState.sortField ===
                                        "adjustedPrice" || priceFiltered
                                        ? "active"
                                        : "inactive"
                                    }
                                  >
                                    <FilterText>{sort.name}</FilterText>

                                    {!priceFiltered && (
                                      <SmallText>
                                        Not currently filtering
                                      </SmallText>
                                    )}

                                    {priceFiltered &&
                                      priceValues?.min &&
                                      !priceValues?.max && (
                                        <SmallText>
                                          Filtering out &lt;{" "}
                                          {priceValues?.min || 0}
                                        </SmallText>
                                      )}

                                    {priceFiltered &&
                                      !priceValues?.min &&
                                      priceValues?.max && (
                                        <SmallText>
                                          Filtering out &gt;{" "}
                                          {priceValues?.max || 0}
                                        </SmallText>
                                      )}

                                    {priceFiltered &&
                                      priceValues?.min &&
                                      priceValues?.max && (
                                        <SmallText>
                                          Filtering out &lt;{" "}
                                          {priceValues?.min || 0} and &gt;{" "}
                                          {priceValues?.max || 0}
                                        </SmallText>
                                      )}
                                    <Popover
                                      closeOnClick={false}
                                      trigger={
                                        <FontAwesomeIcon
                                          style={{ marginRight: "0px" }}
                                          icon={faFilter}
                                        />
                                      }
                                      content={
                                        <NumberFilterInput
                                          filterType={"priceField"}
                                          field={sort}
                                        />
                                      }
                                      delayShow={500}
                                    />
                                    <span
                                      className="sort"
                                      onClick={() => {
                                        if (
                                          summaryState.sortField ===
                                          "adjustedPrice"
                                        ) {
                                          if (summaryState.sortDir === "asc") {
                                            dispatch(setSort("desc"));
                                          } else {
                                            dispatch(setSort("asc"));
                                          }
                                        } else {
                                          dispatch(setSort("adjustedPrice"));
                                        }
                                      }}
                                    >
                                      {summaryState.sortField ===
                                      "adjustedPrice" ? (
                                        summaryState.sortDir === "asc" ? (
                                          <FontAwesomeIcon icon={faSortUp} />
                                        ) : (
                                          <FontAwesomeIcon icon={faSortDown} />
                                        )
                                      ) : (
                                        <FontAwesomeIcon icon={faSort} />
                                      )}
                                    </span>
                                  </FilterCard>
                                </FilterWrapper>
                              );
                            }
                          }
                        }
                      }

                      if (Array.isArray(value)) {
                        return value.map((column) => {
                          if (sort.id === column) {
                            const filter: ExtraColumnsFilters | undefined =
                              dynamicFilters.filtersObj.columnsValues
                                ? dynamicFilters.filtersObj.columnsValues.find(
                                    (value) => {
                                      if (value.id === sort.id) {
                                        return value;
                                      }
                                    },
                                  )
                                : undefined;

                            return (
                              <>
                                <FilterWrapper key={sort.id}>
                                  <FilterCard
                                    className={
                                      summaryState.sortField === sort.id ||
                                      filter?.columnMin ||
                                      filter?.columnMax
                                        ? "active"
                                        : "inactive"
                                    }
                                  >
                                    <FilterText>{sort.name}</FilterText>

                                    {!filter && (
                                      <SmallText>
                                        Not currently filtering
                                      </SmallText>
                                    )}

                                    {!filter ||
                                      (filter &&
                                        filter.columnMin === null &&
                                        filter.columnMax === null && (
                                          <SmallText>
                                            Not currently filtering
                                          </SmallText>
                                        ))}
                                    {filter &&
                                      filter.columnMin !== null &&
                                      filter.columnMax === null && (
                                        <SmallText>
                                          Filtering out &lt;{" "}
                                          {filter.columnMin || 0}
                                        </SmallText>
                                      )}

                                    {filter &&
                                      filter.columnMin === null &&
                                      filter.columnMax !== null && (
                                        <SmallText>
                                          Filtering out &gt;{" "}
                                          {filter.columnMax || 0}
                                        </SmallText>
                                      )}

                                    {filter &&
                                      filter.columnMin !== null &&
                                      filter.columnMax !== null && (
                                        <SmallText>
                                          Filtering out &lt;{" "}
                                          {filter.columnMin || 0} and &gt;{" "}
                                          {filter.columnMax || 0}
                                        </SmallText>
                                      )}

                                    <Popover
                                      closeOnClick={false}
                                      trigger={
                                        <FontAwesomeIcon
                                          style={{ marginRight: "0px" }}
                                          icon={faFilter}
                                        />
                                      }
                                      content={
                                        <NumberFilterInput
                                          filterType={sort.id}
                                          field={sort}
                                        />
                                      }
                                      delayShow={500}
                                    />
                                    <span
                                      className="sort"
                                      onClick={() => {
                                        if (withApproved) {
                                          if (
                                            summaryState.sortField === sort.id
                                          ) {
                                            if (
                                              summaryState.sortDir === "asc"
                                            ) {
                                              dispatch(setSort("desc"));
                                            } else {
                                              dispatch(setSort("asc"));
                                            }
                                          } else {
                                            dispatch(setSort(sort.id));
                                          }
                                        }
                                      }}
                                    >
                                      {summaryState.sortField === sort.id ? (
                                        summaryState.sortDir === "asc" ? (
                                          <FontAwesomeIcon icon={faSortUp} />
                                        ) : (
                                          <FontAwesomeIcon icon={faSortDown} />
                                        )
                                      ) : (
                                        <FontAwesomeIcon icon={faSort} />
                                      )}
                                    </span>
                                  </FilterCard>
                                </FilterWrapper>
                              </>
                            );
                          }
                        });
                      }
                    }
                  }
                  return <></>;
                }
                case "enum": {
                  return <></>;
                }
                case "string": {
                  return <></>;
                }
                case "object-ref": {
                  return <></>;
                }
                case "header": {
                  return <></>;
                }
                case "date": {
                  return <></>;
                }
              }
            })}
        </InnerRowWrapper>
      </RowWrapper>
    </ManageFilters>
  );
});
