import * as React from "react";
import { Redirect } from "react-router";
import { useSelector } from "react-redux";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

export const HomePage: React.ComponentType = () => {
  const {
    client: { accessId },
    myRole,
  } = useSelector(nonNullApplicationInitializationSelector);

  const redirect =
    accessId === "super"
      ? `/c/${accessId}/__admin/clients`
      : myRole?.isAdmin
      ? `/c/${accessId}/products`
      : `/c/${accessId}/v2/loan-pricing`;

  // just redirect until we get a real home page
  return <Redirect to={redirect} />;
};
