import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import transitions from "design/subatomics/transitions";
import color from "design/subatomics/colors";
import whitespace from "design/subatomics/whitespace";

export const LoanPricingInternal = styled.section`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  overflow: hidden;
`;

export const LoanPricing = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FieldsPanel = styled.aside`
  transition: flex-basis ${transitions.default};
  overflow: hidden;
  scroll-y; auto;
  display: flex;
  flex-direction: column;

  .scrollable-fields-column .molecule-accordion:not(.closed) {
    > div {
      padding-top: ${whitespace("least")};
      padding-left: 0;
      padding-right: 0;
    }
  }
  
  ${breakpoints.mobile} {
    flex: 0 1;

    button {
      flex: 1 1 33%;
    }  

    &.closed {
      flex-basis: 0%;
    }  

    &.open {
      flex-basis: 100%;
    }  

    .fieldDisplay {
      margin: 0;
    }
    
  }

  ${breakpoints.desktop} {
    flex: 0 1 20%;
    min-width: 320px;
    border-right: 1px solid ${color({ color: "border" })};

    > button {
      flex: 1 1 50%;
    }  
  } 
`;
