import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "features/store";
import * as Api from "api";
import * as T from "types/engine-types";
import { createSelector } from "reselect";
import _ from "lodash";
import { parseISO, isPast, isFuture } from "date-fns";
import { DecoratedRuleHeader } from "types/engine-types";
import filterResults from "features/filter-list";
import { getErrorMessage } from "features/utils";

export type RulesState = {
  rules: T.DecoratedRuleHeader[];
  searchTerm: string;
  sortField: string;
  sortDir: string;
  loading: boolean;
  errors: string;
};

const initialState: RulesState = {
  rules: [],
  loading: false,
  searchTerm: "",
  errors: "",
  sortField: "name",
  sortDir: "asc",
};

const ruleSlice = createSlice({
  name: "Rules",
  initialState,
  reducers: {
    setSort: (state, { payload }: PayloadAction<string>) => {
      let sortDir: string;
      if (payload === state.sortField) {
        if (state.sortDir === "asc") {
          sortDir = "desc";
        } else {
          sortDir = "asc";
        }
      } else {
        sortDir = "asc";
      }
      return {
        ...state,
        sortDir,
        sortField: payload,
      };
    },
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },
    setRules: (state, { payload }: PayloadAction<T.DecoratedRuleHeader[]>) => {
      state.rules = payload;
    },
  },
});

export const { setLoading, setErrors, setRules, setSearchTerm, setSort } =
  ruleSlice.actions;

export default ruleSlice.reducer;

export const rulesSelector = (state: { rules: RulesState }) => state.rules;

export function ruleIsActive(rule: DecoratedRuleHeader): boolean {
  const activationDate = rule.activationTimestamp
    ? parseISO(rule.activationTimestamp)
    : null;

  const deactivationDate = rule.deactivationTimestamp
    ? parseISO(rule.deactivationTimestamp)
    : null;

  return !!(
    activationDate &&
    isPast(activationDate) &&
    (!deactivationDate || (deactivationDate && isFuture(deactivationDate)))
  );
}

export const filteredRulesSelector = createSelector(
  [
    (state: { rules: RulesState }) => state.rules.rules,
    (state: { rules: RulesState }) => state.rules.searchTerm,
    (state: { rules: RulesState }) => state.rules.sortField,
    (state: { rules: RulesState }) => state.rules.sortDir,
  ],
  (rules, searchTerm, sortField, sortDir) => {
    const decoratedRules: T.DecoratedRuleHeader[] = rules.map((rule) => {
      const isActive = ruleIsActive(rule);
      return {
        ...rule,
        isActive,
      };
    });

    const filtered: T.DecoratedRuleHeader[] = [];
    decoratedRules.forEach((rule: T.DecoratedRuleHeader) => {
      const { noNotQuoteMatches, quoteMatch, restMatch } = filterResults(
        searchTerm,
        [
          rule.isActive ? "active" : "inactive",
          rule.name || "",
          rule.stageId || "",
        ],
      );

      if (noNotQuoteMatches && quoteMatch && restMatch) filtered.push(rule);
    });

    let sorted = _.sortBy(filtered, [
      (o) => {
        const field = o[sortField as keyof T.DecoratedRuleHeader];
        return field && typeof field === "string" ? field.toLowerCase() : field;
      },
      (o) => o.name.toLowerCase(),
    ]);
    if (sortDir === "desc") {
      sorted = _.reverse(sorted);
    }
    return sorted;
  },
);

// AppThunk sets the type definitions for the dispatch method
export const getRules = (): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const rules = await Api.getRules();
      dispatch(setLoading(false));
      dispatch(setRules(rules));
    } catch (error) {
      newrelic.noticeError(getErrorMessage(error));
      dispatch(setErrors(error as string));
      dispatch(setLoading(false));
    }
  };
};
