import React from "react";
import { ProductResultsSummary } from "./styles";
import { resetFiltersToNone } from "features/pricing-summaries";
import { SmallText } from "design/atoms/typography";
import { useDispatch } from "react-redux";
import Button from "design/atoms/button";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ProductResultsCount from "design/atoms/product-results-count";

type Params = {
  productId: string;
  pricingScenarioRate: string;
  pricingScenarioLock: string;
};

export default React.memo(
  ({
    open,
    setOpen,
    loading,
    activeFilters,
  }: {
    open?: boolean;
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    loading?: boolean;
    activeFilters?: number | undefined;
  }) => {
    const queryParams = new URLSearchParams(document.location.search);
    const params = useParams<Params>();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    if (queryParams.get("inCompareView") === "true") {
      return <></>;
    }

    return (
      <>
        <ProductResultsSummary className="loan-pricing-component-product-results-summary">
          {loading && (
            <SmallText className="updating">
              Updating products and prices.
            </SmallText>
          )}
          {!loading && (
            <ProductResultsCount
              showCounts={!params.productId}
              className="v2Results"
            />
          )}

          {setOpen && (
            <Button
              grow={true}
              className="hide-for-mobile"
              onClick={() => setOpen(!open)}
            >
              {open ? "Close Filters" : `Filters`}
            </Button>
          )}

          {!!activeFilters && (
            <Button
              grow={true}
              className="hide-for-mobile"
              style={{ marginLeft: 0 }}
              onClick={() => {
                queryParams.delete("searchTerm");
                queryParams.delete("filters");
                queryParams.delete("dynamicFilters");
                history.push(
                  `${location.pathname}?${queryParams.toString()}#${
                    location.hash ? "#" + location.hash : ""
                  }`,
                );

                dispatch(resetFiltersToNone());
              }}
            >
              Reset Filters
            </Button>
          )}
        </ProductResultsSummary>
      </>
    );
  },
);
