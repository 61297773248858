import React from "react";
import WarningIcon from "@material-ui/icons/Warning";

const SuspendedPricingWarning = () => {
  return (
    <p
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "red",
        color: "white",
        padding: "16px",
        marginBottom: "4px",
      }}
    >
      <WarningIcon style={{ display: "inline-flex", marginRight: "16px" }} />
      The pricing for this product is currently suspended due to changes or
      updates. This will be updated as quickly as possible.
    </p>
  );
};

export default SuspendedPricingWarning;
