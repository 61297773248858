import React, { useCallback, useMemo, useRef } from "react";
import { Box, Paper, TextField } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { getDataTableColumns, dedupedColumns } from "features/data-tables";
import { SearchableDropdown } from "design/molecules/dropdown";
import {
  FieldValueTypeEditor,
  FieldValueTypeState,
  newFieldValueTypeState,
  convertStateToFieldValueType,
  convertFieldValueTypeToState,
} from "design/organisms/field-value-type-editor";
import {
  ObjectListEditor,
  ObjectListState,
  newObjectListState,
} from "design/organisms/object-list-editor";
import * as T from "types/engine-types";
import {
  resolveEnum,
  Setter,
  UiValidationError,
  usePropertySetter,
  useTargetValue,
  validation,
  generateSafeId,
  XLS_MIME_TYPE,
  XLSM_MIME_TYPE,
  XLSX_MIME_TYPE,
} from "features/utils";
import { useSelector } from "react-redux";
import { useAppDispatch } from "features/store";
import { useEffect } from "react";
import { expandedConfigSelector } from "features/application-initialization";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import { ImportTableButton } from "./ImportTableButton";
import { useDrop } from "react-dnd/lib/hooks/useDrop";
import { NativeTypes } from "react-dnd-html5-backend";
import _ from "lodash";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import PublishIcon from "@material-ui/icons/Publish";
import DataTableBodyViewer from "./DataTableBodyViewer";
import { ExportTableButton } from "./ExportTableButton";

export type DataTableState = {
  name: string;
  oldColumnDefs: T.DataTableColumn[];
  columnsList: ObjectListState<DataTableColumnState>;
  body: DataTableBody | null;
};

type DataTableColumnState = {
  id: T.DataTableColumnId;
  name: string;
  valueType: FieldValueTypeState;
};

export type DataTableBody = T.FieldValue[][];

export function newDataTableState(): DataTableState {
  return {
    name: "",
    oldColumnDefs: [],
    columnsList: newObjectListState([]),
    body: [],
  };
}

export function convertStateToDataTable(state: DataTableState): T.NewDataTable {
  if (state.name.trim() === "") {
    throw new UiValidationError("Table name is missing");
  }

  if (state.columnsList.objects.length === 0) {
    throw new UiValidationError("Table must have at least one column");
  }

  if (!state.body) {
    throw new UiValidationError("Table doesn't have any rows uploaded yet");
  }

  const columnDefs = state.columnsList.objects.map(
    convertStateToDataTableColumnDef,
  );

  if (!_.isEqual(columnDefs, state.oldColumnDefs)) {
    throw new UiValidationError(
      "Table Body must be reuploaded, because Columns have changed",
    );
  }

  return {
    name: state.name,
    columnDefs,
    body: state.body,
  };
}

export function convertDataTableToState(
  dataTable: T.DataTable,
): DataTableState {
  const columnStates = dataTable.columnDefs.map((col) =>
    convertDataTableColumnDefToState(col),
  );

  return {
    name: dataTable.name,
    oldColumnDefs: dataTable.columnDefs,
    columnsList: newObjectListState(columnStates),
    body: dataTable.body,
  };
}

export const validateDataTableState = validation(convertStateToDataTable);

function newDataTableColumnState(): DataTableColumnState {
  return {
    id: generateSafeId() as T.DataTableColumnId,
    name: "",
    valueType: newFieldValueTypeState(),
  };
}

function getColumnName(column: DataTableColumnState): JSX.Element {
  return <>{column.name}</>;
}

function convertDataTableColumnDefToState(
  columnDef: T.DataTableColumn,
): DataTableColumnState {
  return {
    id: columnDef.id,
    name: columnDef.name,
    valueType: convertFieldValueTypeToState(columnDef.valueType),
  };
}

function convertStateToDataTableColumnDef(
  state: DataTableColumnState,
): T.DataTableColumn {
  if (state.name.trim() === "") {
    throw new UiValidationError("Column name is missing");
  }

  return {
    id: state.id,
    name: state.name,
    valueType: convertStateToFieldValueType(state.valueType),
  };
}

const validateDataTableColumnState = validation(
  convertStateToDataTableColumnDef,
);

const useEditorStyles = makeStyles((t) =>
  createStyles({
    container: {
      margin: t.spacing(2),
      padding: t.spacing(3),
    },
    nameField: {
      width: "100%",
      maxWidth: 400,
    },
    columnNameField: {
      margin: t.spacing(1, 0),
    },
  }),
);

export const DataTableEditor = React.memo(function DataTableEditor({
  state,
  setState,
  showErrors,
  title,
}: {
  state: DataTableState;
  setState: Setter<DataTableState>;
  showErrors: boolean;
  title: string;
}) {
  const C = useEditorStyles();

  const setName = usePropertySetter(setState, "name");
  const handleNameChange = useTargetValue(setName);

  const setBody = usePropertySetter(setState, "body");

  const setColumnsList = usePropertySetter(setState, "columnsList");
  const makeColumnEditor = useCallback(
    ({
      value,
      showErrors,
      setValue,
    }: {
      value: DataTableColumnState;
      showErrors: boolean;
      setValue: Setter<DataTableColumnState>;
    }) => (
      <ColumnEditor state={value} showErrors={showErrors} setState={setValue} />
    ),
    [],
  );

  const setOldColumnDefs = usePropertySetter(setState, "oldColumnDefs");

  const bodyIsUpToDateWithColumns = useMemo(() => {
    const oldColumnStates = _(state.oldColumnDefs).map((columnDef) =>
      convertDataTableColumnDefToState(columnDef),
    );

    const currentColumnStates = state.columnsList.objects;

    return _.isEqual(oldColumnStates, currentColumnStates);
  }, [state.columnsList.objects, state.oldColumnDefs]);

  const onImport = useCallback(
    (body: DataTableBody, usedColumnDefs: T.DataTableColumn[]) => {
      setBody(body);
      setOldColumnDefs(usedColumnDefs);
    },
    [setBody, setOldColumnDefs],
  );

  const columnDefsValidated = useMemo(() => {
    try {
      return state.columnsList.objects.map((s) =>
        convertStateToDataTableColumnDef(s),
      );
    } catch (err) {
      if (err instanceof UiValidationError) {
        return null;
      }
    }
  }, [state.columnsList.objects]);

  return (
    <>
      <Paper className={C.container}>
        <Box fontSize="34px" px={3} my={3}>
          {title}
        </Box>
        <Box px={3} my={3}>
          <TextField
            className={C.nameField}
            label="Table Name"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            error={showErrors && state.name.trim() === ""}
            value={state.name}
            onChange={handleNameChange}
          />
        </Box>

        <Box px={3} my={3} fontSize={24}>
          Columns
        </Box>
        <Box px={3} my={3}>
          <ObjectListEditor
            state={state.columnsList}
            additionalButtons={[
              {
                name: "New",
                icon: <AddIcon />,
                onClick: (callbacks) =>
                  callbacks.addObject(newDataTableColumnState()),
              },
            ]}
            getObjectLabel={getColumnName}
            showErrors={showErrors}
            validateObject={validateDataTableColumnState}
            makeObjectEditor={makeColumnEditor}
            setState={setColumnsList}
            emptyText="Click &ldquo;New&rdquo; to insert a new item into this list"
          />
        </Box>
        <Box px={3} my={3} fontSize={24}>
          Body
        </Box>
        <Box px={3} my={3} display="flex">
          {state.body ? (
            <ExportTableButton
              tableName={state.name || "<Unnamed Table>"}
              columnDefs={state.oldColumnDefs}
              body={state.body}
            >
              {bodyIsUpToDateWithColumns
                ? "Export Table"
                : "Export (old) table"}
            </ExportTableButton>
          ) : (
            <ExportTableButton disabled={true}>
              {bodyIsUpToDateWithColumns
                ? "Export Table"
                : "Export (old) table"}
            </ExportTableButton>
          )}

          {columnDefsValidated ? (
            <ImportTableButton
              onImport={onImport}
              columnDefs={columnDefsValidated}
            />
          ) : (
            <ImportTableButton disabled></ImportTableButton>
          )}
        </Box>
        <Box>
          {!bodyIsUpToDateWithColumns && (
            <p>
              Column definitions have changed since the table body was last
              uploaded, so the table body must be uploaded again before the
              table can be saved.
            </p>
          )}
          {!columnDefsValidated && (
            <p>
              Table body cannot be uploaded right now because of an error in the
              Column definitions. Once all columns are defined and valid, the
              Upload Spreasheet button will be enabled.
            </p>
          )}
        </Box>
        <Box>
          {state.body && (
            <DataTableBodyViewer
              columnDefs={state.oldColumnDefs}
              body={state.body}
            />
          )}
        </Box>
      </Paper>
    </>
  );
});

const ColumnEditor = React.memo(
  ({
    state,
    showErrors,
    setState,
  }: {
    state: DataTableColumnState;
    showErrors: boolean;
    setState: Setter<DataTableColumnState>;
  }) => {
    const dispatch = useAppDispatch();
    const C = useEditorStyles();
    const config = useSelector(expandedConfigSelector);
    const { client } = useSelector(nonNullApplicationInitializationSelector);
    const preexistingColumns = useSelector(dedupedColumns);
    const setName = usePropertySetter(setState, "name");
    const handleNameChange = useTargetValue(setName);
    const setValueType = usePropertySetter(setState, "valueType");
    const sortEnumTypeToTop = useCallback(
      (enumType: T.RawEnumType) =>
        resolveEnum(
          enumType,
          config.systemEnumTypesById,
          client.displayNewInheritedEnumVariants,
        ).name.toLowerCase() === state.name.toLowerCase(),
      [state.name, config, client],
    );

    useEffect(() => {
      if (preexistingColumns === null) {
        dispatch(getDataTableColumns());
      }
    }, [dispatch, preexistingColumns]);

    return (
      <>
        <Box>
          {preexistingColumns && (
            <div style={{ margin: "12px 0" }}>
              <SearchableDropdown<T.DataTableColumnNameAndValueType>
                label="Clone an existing column"
                value={null}
                options={preexistingColumns}
                getOptionLabel={(option) =>
                  `${option.name} (${option.valueType.type})`
                }
                setValue={(
                  column: T.DataTableColumnNameAndValueType | null,
                ) => {
                  if (column) {
                    setState({
                      name: column.name,
                      valueType: convertFieldValueTypeToState(column.valueType),
                      id: generateSafeId() as T.DataTableColumnId,
                    });
                  }
                }}
              />
            </div>
          )}

          <TextField
            className={C.columnNameField}
            label="Column Name"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={state.name}
            error={showErrors && state.name.trim() === ""}
            onChange={handleNameChange}
          />
          <FieldValueTypeEditor
            state={state.valueType}
            showErrors={showErrors}
            setState={setValueType}
            enumTypes={config.rawEnumTypes}
            sortEnumTypeToTop={sortEnumTypeToTop}
            allowHeaders={false}
            allowPricingProfiles={true}
          />
        </Box>
      </>
    );
  },
);

const useNoRevisionsStyles = makeStyles((t) =>
  createStyles({
    dropTarget: {
      display: "inline-block",
      position: "relative",
      "&.isOver > table": {
        opacity: "0.3",
      },
    },
    table: {
      transition: "0.2s opacity",
      opacity: "0.8",
      borderCollapse: "collapse",
      width: "100%",
    },
    th: {
      border: "1px solid #ccc",
      padding: "8px 12px",
      height: 40,
    },
    td: {
      border: "1px solid #ccc",
      height: 40,
    },
    dragMessage: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      position: "absolute",
      left: "calc(50% - 225px)",
      top: "calc(50% - 75px)",
      width: 450,
      height: 150,

      border: "1px solid #888",
      background: "white",
      padding: "0 16px",

      color: "#666",
      fontSize: 24,

      "&.error": {
        borderColor: "hsl(4.1, 89.6%, 58.4%)", // palette: error.main
        color: "hsl(4.1, 89.6%, 58.4%)", // palette: error.main
      },

      "&.accept": {
        borderColor: "hsl(122.4, 39.4%, 49.2%)", // palette: success.main
        color: "hsl(122.4, 39.4%, 49.2%)", // palette: success.main
      },
    },
  }),
);

interface DragItem {
  files: File[];
  items: {
    type: string;
    kind: string;
  }[];
}

const ALLOWED_MIME_TYPES = [XLS_MIME_TYPE, XLSX_MIME_TYPE, XLSM_MIME_TYPE];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EmptyBody = React.memo(
  ({
    columnDefs,
    onFileDrop,
  }: {
    columnDefs: T.DataTableColumn[];
    onFileDrop: (file: File) => void;
  }) => {
    const C = useNoRevisionsStyles();

    const dropTargetRef = useRef<HTMLDivElement>(null);

    const [{ isOver, isTooMany, isWrongFileType }, drop] = useDrop({
      accept: NativeTypes.FILE,
      canDrop(item, mon) {
        const dragItem = mon.getItem() as DragItem;

        return (
          dragItem.items.length === 1 &&
          dragItem.items[0].kind === "file" &&
          ALLOWED_MIME_TYPES.includes(dragItem.items[0].type)
        );
      },
      drop(item, mon) {
        const dragItem = mon.getItem() as DragItem;
        const file = dragItem.files[0];

        if (file.size > 3 * 1024 * 1024) {
          alert("Spreadsheet is too large (must be under 3 MB).");
          return;
        }

        onFileDrop(file);
      },
      collect(mon) {
        const dragItem = mon.getItem() as DragItem;

        if (mon.isOver() && dragItem) {
          return {
            isOver: true,
            isTooMany: dragItem.items.length > 1,
            isWrongFileType:
              dragItem.items.length > 0 &&
              (dragItem.items[0]?.kind !== "file" ||
                !ALLOWED_MIME_TYPES.includes(dragItem.items[0]?.type)),
          };
        }

        return {
          isOver: false,
        };
      },
    });
    drop(dropTargetRef);

    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        p={3}
      >
        <div
          ref={dropTargetRef}
          className={C.dropTarget + (isOver ? " isOver" : "")}
        >
          <table className={C.table}>
            <thead>
              <tr>
                {columnDefs.map((column) => (
                  <th key={column.id} className={C.th}>
                    {column.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* Empty rows */}
              {_.range(14).map((rowIndex) => (
                <tr key={rowIndex}>
                  {columnDefs.map((column) => (
                    <td key={column.id} className={C.td}></td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div
            className={
              C.dragMessage +
              (isOver
                ? isTooMany || isWrongFileType
                  ? " error"
                  : " accept"
                : "")
            }
          >
            <Box display="flex" pr={2} fontSize="32px">
              {isOver ? (
                isTooMany || isWrongFileType ? (
                  <ClearIcon fontSize="inherit" />
                ) : (
                  <DoneIcon fontSize="inherit" />
                )
              ) : (
                <PublishIcon fontSize="inherit" />
              )}
            </Box>
            <div>
              {isTooMany
                ? "Too many files."
                : isWrongFileType
                ? "Only spreadsheet files are permitted."
                : "Import spreadsheet data by dragging the file here."}
            </div>
          </div>
        </div>
      </Box>
    );
  },
);
