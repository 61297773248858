import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "features/store";
import * as Api from "api";
import * as T from "types/engine-types";
import { createSelector } from "reselect";
import _ from "lodash";
import { InvestorsState } from "features/investors";
import filterResults from "features/filter-list";
import { getErrorMessage } from "features/utils";

export type RateSheetsState = {
  rateSheets: T.DecoratedRateSheetHeader[];
  searchTerm: string;
  sortField: string;
  sortDir: string;
  loading: boolean;
  errors: string;
};

const initialState: RateSheetsState = {
  rateSheets: [],
  loading: false,
  searchTerm: "",
  errors: "",
  sortField: "creationTimestamp",
  sortDir: "desc",
};

const rateSheetSlice = createSlice({
  name: "RateSheets",
  initialState,
  reducers: {
    setSort: (state, { payload }: PayloadAction<string>) => {
      let sortDir: string;
      if (payload === state.sortField) {
        if (state.sortDir === "asc") {
          sortDir = "desc";
        } else {
          sortDir = "asc";
        }
      } else {
        sortDir = "asc";
      }
      return {
        ...state,
        sortDir,
        sortField: payload,
      };
    },
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },
    setRateSheets: (
      state,
      { payload }: PayloadAction<T.DecoratedRateSheetHeader[]>,
    ) => {
      state.rateSheets = payload;
    },
  },
});

export const { setLoading, setErrors, setRateSheets, setSearchTerm, setSort } =
  rateSheetSlice.actions;

export default rateSheetSlice.reducer;

export const rateSheetsSelector = (state: { rateSheets: RateSheetsState }) =>
  state.rateSheets;

export const filteredRateSheetsSelector = createSelector(
  [
    (state: { investors: InvestorsState }) => state.investors.investors,
    (state: { rateSheets: RateSheetsState }) => state.rateSheets.rateSheets,
    (state: { rateSheets: RateSheetsState }) => state.rateSheets.searchTerm,
    (state: { rateSheets: RateSheetsState }) => state.rateSheets.sortField,
    (state: { rateSheets: RateSheetsState }) => state.rateSheets.sortDir,
  ],
  (investors, rateSheets, searchTerm, sortField, sortDir) => {
    const decoratedRateSheets: T.DecoratedRateSheetHeader[] = rateSheets.map(
      (rateSheet) => {
        const investorName = investors?.find(
          (i) => i.id === rateSheet.investorId,
        )?.name;

        return {
          ...rateSheet,
          investorName: investorName || "",
        };
      },
    );

    const filtered: T.DecoratedRateSheetHeader[] = [];
    decoratedRateSheets.forEach((rateSheet: T.DecoratedRateSheetHeader) => {
      const { noNotQuoteMatches, quoteMatch, restMatch } = filterResults(
        searchTerm,
        [
          rateSheet.investorName || "",
          rateSheet.effectiveTimestamp || "",
          rateSheet.creationTimestamp || "",
        ],
      );

      if (noNotQuoteMatches && quoteMatch && restMatch)
        filtered.push(rateSheet);
    });

    let sorted = _.sortBy(filtered, [
      (o) => o[sortField as keyof T.DecoratedRateSheetHeader]?.toLowerCase(),
    ]);
    if (sortDir === "desc") {
      sorted = _.reverse(sorted);
    }
    return sorted;
  },
);

// AppThunk sets the type definitions for the dispatch method
export const getRateSheets = (): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const rateSheets = await Api.getRateSheets();

      dispatch(setLoading(false));
      dispatch(setRateSheets(rateSheets));
    } catch (error) {
      newrelic.noticeError(getErrorMessage(error));
      dispatch(setErrors(error as string));
      dispatch(setLoading(false));
    }
  };
};
