import React, { CSSProperties } from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import color from "design/subatomics/colors";

type TFontAwesomeProperties = {
  "--fa-primary-color"?: string;
  "--fa-secondary-color"?: string;
};

export function DuotoneFontAwesomeIcon(props: FontAwesomeIconProps) {
  const style: CSSProperties & TFontAwesomeProperties = props.style
    ? { ...props.style }
    : {};

  style["--fa-primary-color"] = color({ color: "green", shade: 4 });
  style["--fa-secondary-color"] = color({ color: "gray" });

  return (
    <FontAwesomeIcon
      {...props}
      style={style}
      className={props.className ? `atom-icon ${props.className}` : "atom-icon"}
    />
  );
}

export default DuotoneFontAwesomeIcon;
