import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ClearIcon from "@material-ui/icons/Clear";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";

import { useArraySetter } from "features/utils";

// TODO: find a library with typescript definitions, or add some of our own
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const RegionSelect = require("react-region-select");

export type DocumentPage = {
  physicalWidth: number;
  physicalHeight: number;
  imageUrl: string;
};

export type DocumentRegion = {
  pageIndex: number;
  physicalX: number;
  physicalY: number;
  physicalWidth: number;
  physicalHeight: number;
};

type ReactRegionSelectRegion = {
  x: number; // percentage
  y: number; // percentage
  width: number; // percentage
  height: number; // percentage
  isChanging: boolean;
  new?: boolean;
  data: {
    index: number;
  };
};

export const DocumentRegionSelect = React.memo(
  ({
    pages,
    onSubmit,
    onCancel,
  }: {
    pages: DocumentPage[];
    onSubmit: (regions: DocumentRegion[]) => void;
    onCancel: () => void;
  }) => {
    const [pageIndex, setPageIndex] = useState(0);
    const [scale, setScale] = useState(1);
    const [regionsPerPage, setRegionsPerPage] = useState<
      readonly ReactRegionSelectRegion[][]
    >(pages.map((p) => []));
    const setRegionsByPageIndex = useArraySetter(setRegionsPerPage);

    const zoomIn = useCallback(() => {
      setScale((scale) => {
        if (scale < 0.25) {
          return 0.25;
        }

        if (scale >= 4) {
          return 4;
        }

        return scale + 0.25;
      });
    }, []);

    const zoomOut = useCallback(() => {
      setScale((scale) => {
        if (scale <= 0.25) {
          return 0.125;
        }

        return scale - 0.25;
      });
    }, []);

    const submit = useCallback(() => {
      onSubmit(
        regionsPerPage.flatMap((regions, pageIndex) => {
          const page = pages[pageIndex];

          return regions.map((region) => ({
            pageIndex,
            physicalX: (region.x / 100) * page.physicalWidth,
            physicalY: (region.y / 100) * page.physicalHeight,
            physicalWidth: (region.width / 100) * page.physicalWidth,
            physicalHeight: (region.height / 100) * page.physicalHeight,
          }));
        }),
      );
    }, [pages, regionsPerPage, onSubmit]);

    return (
      <Dialog open={true} fullWidth maxWidth="xl">
        <DialogTitle disableTypography style={{ display: "flex" }}>
          <Box flex="1">
            <Typography variant="h6">Select regions of document</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              size="small"
              disabled={scale <= 0.125}
              onClick={zoomOut}
            >
              <ZoomOutIcon />
            </IconButton>
          </Box>
          <Box display="flex" alignItems="center" padding="0 8px">
            {scale * 100}%
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton size="small" disabled={scale >= 4} onClick={zoomIn}>
              <ZoomInIcon />
            </IconButton>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              size="small"
              disabled={pageIndex === 0}
              onClick={() => setPageIndex((pageIndex) => pageIndex - 1)}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
          <Box display="flex" alignItems="center" padding="0 8px">
            Page {pageIndex + 1} of {pages.length}
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              size="small"
              disabled={pageIndex >= pages.length - 1}
              onClick={() => setPageIndex((pageIndex) => pageIndex + 1)}
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#ccc",
            alignItems: "start",
            padding: 0,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="40px"
            minWidth="100%"
          >
            <RegionSelect
              regions={regionsPerPage[pageIndex]}
              onChange={setRegionsByPageIndex.withIndex(pageIndex)}
              constraint
              style={{
                display: "block",
                boxShadow: "0 0 40px #666",
                cursor: "crosshair",
              }}
              regionStyle={{ background: "rgba(255, 255, 255, 0.3)" }}
              regionRenderer={({
                index,
                isChanging,
              }: {
                index: number;
                isChanging: boolean;
              }) =>
                isChanging ? null : (
                  <Box
                    display="flex"
                    position="absolute"
                    top="-20px"
                    right="-20px"
                    style={{ cursor: "pointer" }}
                    fontSize="16px"
                    onClick={() => {
                      setRegionsPerPage((regionsPerPage) => {
                        const newRegionsPerPage = [...regionsPerPage];
                        const newRegions = [...regionsPerPage[pageIndex]];
                        newRegions.splice(index, 1);
                        newRegionsPerPage[pageIndex] = newRegions;
                        return newRegionsPerPage;
                      });
                    }}
                  >
                    <ClearIcon fontSize="inherit" />
                  </Box>
                )
              }
            >
              <img
                alt={`Page ${pageIndex + 1} of document`}
                style={{ display: "block", transition: "width 0.15s ease-out" }}
                src={pages[pageIndex].imageUrl}
                width={800 * scale}
              />
            </RegionSelect>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button color="primary" onClick={submit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
