import React, { ReactNode } from "react";
import { ActionButtons } from "./styles";

export default React.memo(
  ({
    children,
    className,
    style,
  }: {
    children: ReactNode;
    className?: string;
    style?: React.CSSProperties | undefined;
  }) => {
    return (
      <ActionButtons
        style={style}
        className={
          className
            ? `page-component-loan-pricing-action-buttons ${className}`
            : "page-component-loan-pricing-action-buttons"
        }
      >
        {children}
      </ActionButtons>
    );
  },
);
