import { Set as ISet } from "immutable";
import React, { useState } from "react";
import { Box, Checkbox, TextField, Button } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { SearchableDropdown } from "design/molecules/dropdown";
import { Configuration } from "config";
import {
  getAllObjectRefsByObjectType,
  emptyObjectDetails,
  fetchObjectDetails,
  getObjectRefLabel,
  getVisibleObjectRefsByObjectType,
  ObjectDetails,
} from "features/objects";
import * as T from "types/engine-types";
import {
  ALL_OBJECT_REF_PREDICATE_OPS,
  ObjectRefFieldPredicateOp,
  getObjectRefFieldPredicateOpName,
} from "features/field-predicates";
import { Setter, UiValidationError, usePropertySetter } from "features/utils";
import { useEffect } from "react";
import _ from "lodash";

export type ObjectRefFieldPredicateState = {
  op: ObjectRefFieldPredicateOp | null;
  objectRefs: T.ObjectRef[];
};

export function newObjectRefFieldPredicateState(
  valueType: T.FieldValueType.ObjectRef,
): ObjectRefFieldPredicateState {
  return {
    op: null,
    objectRefs: [],
  };
}

export function convertObjectRefFieldPredicateToState(
  pred: T.ObjectRefFieldPredicateKind,
): ObjectRefFieldPredicateState {
  switch (pred.op) {
    case "is-blank":
    case "is-not-blank":
      return {
        op: pred.op,
        objectRefs: [],
      };
    case "matches":
    case "does-not-match":
      return {
        op: pred.op,
        objectRefs: pred.objectRefs,
      };
  }
}

export function convertStateToObjectRefFieldPredicate(
  config: Configuration,
  valueType: T.FieldValueType.ObjectRef,
  state: ObjectRefFieldPredicateState,
): T.ObjectRefFieldPredicateKind {
  if (!state.op) {
    throw new UiValidationError("Select an operator");
  }

  switch (state.op) {
    case "is-blank":
    case "is-not-blank":
      return {
        type: "generic",
        op: state.op,
      };
    case "matches":
    case "does-not-match":
      const objectRefs = getAllObjectRefsByObjectType(
        config,
        valueType.objectType,
      );

      if (objectRefs.length === 0) {
        throw new UiValidationError("Could not find any valid objects");
      }

      if (state.objectRefs.length === 0) {
        throw new UiValidationError("Please select at least one option.");
      }

      if (
        state.objectRefs.some(({ id, type }) =>
          objectRefs.every((r) => !(r.type === type && r.id === id)),
        )
      ) {
        throw new UiValidationError("Unknown enumeration variant selected");
      }

      return {
        type: "object-ref",
        op: state.op,
        objectRefs: state.objectRefs,
      };
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fields: {
      "& .MuiAutocomplete-root": {
        width: 270,
      },
      "& .MuiTextField-root": {
        width: 270,
        margin: theme.spacing(1, 0),
      },
    },
    hideClearIcon: {
      "& .MuiAutocomplete-clearIndicator": {
        display: "none",
      },
    },
  }),
);

export const ObjectRefFieldPredicateEditor = React.memo(
  ({
    state,
    fieldId,
    setState,
    showValidationErrors,
    isExpandedView,
    environment,
    localFields,
    config,
  }: {
    state: ObjectRefFieldPredicateState;
    fieldId: T.FieldId;
    setState: Setter<ObjectRefFieldPredicateState>;
    showValidationErrors: boolean;
    isExpandedView: boolean;
    environment: ISet<T.FieldId>;
    localFields: T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
  }) => {
    const C = useStyles();

    const setOp = usePropertySetter(setState, "op");
    const setObjectRefs = usePropertySetter(setState, "objectRefs");

    const [objectDetails, setObjectDetails] = useState<ObjectDetails>(
      emptyObjectDetails(),
    );
    useEffect(() => {
      (async () => {
        const details = await fetchObjectDetails();
        setObjectDetails(details);
      })();
    }, [setObjectDetails]);

    const field = config.allFieldsById.get(fieldId);
    if (!field) {
      return <Box>Error: field not found</Box>;
    }

    const fieldValueType = field.valueType;
    if (fieldValueType.type !== "object-ref") {
      throw new Error(
        "object ref field predicate must point to an object ref field",
      );
    }

    const visibleObjectRefs = getVisibleObjectRefsByObjectType(
      config,
      objectDetails,
      fieldValueType.objectType,
      state.objectRefs,
    );

    return (
      <div className={C.fields}>
        <SearchableDropdown
          label="Operation"
          options={ALL_OBJECT_REF_PREDICATE_OPS}
          getOptionLabel={getObjectRefFieldPredicateOpName}
          value={state.op}
          error={showValidationErrors && !state.op}
          setValue={setOp}
        />
        {state.op && opHasOperand(state.op) && (
          <Autocomplete<T.ObjectRef, true>
            className={isExpandedView ? C.hideClearIcon : undefined}
            style={{
              width: "auto",
            }}
            multiple
            onChange={(event, values) => {
              setObjectRefs(values);
            }}
            options={visibleObjectRefs}
            disableCloseOnSelect
            value={state.objectRefs}
            getOptionLabel={(objectRef) =>
              getObjectRefLabel(objectDetails, objectRef)
            }
            getOptionSelected={(option, value) => _.isEqual(option, value)}
            renderOption={(objectRef, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {getObjectRefLabel(objectDetails, objectRef)}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  style={{ width: isExpandedView ? 900 : 270 }}
                  variant="outlined"
                  label={field.name}
                  placeholder={`Filter ${field.name}...`}
                  error={showValidationErrors && state.objectRefs.length === 0}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: "8px",
                  }}
                >
                  {isExpandedView && (
                    <Button onClick={() => setObjectRefs([])}>Clear All</Button>
                  )}

                  <Button onClick={() => setObjectRefs(visibleObjectRefs)}>
                    Select All
                  </Button>
                </div>
              </>
            )}
          />
        )}
      </div>
    );
  },
);

function opHasOperand(op: ObjectRefFieldPredicateOp): boolean {
  switch (op) {
    case "is-blank":
    case "is-not-blank":
      return false;
    case "matches":
    case "does-not-match":
      return true;
  }
}
