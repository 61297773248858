import {
  listVendorIntegrations,
  vendorIntegrationsStateSelector,
} from "features/vendor-integrations";
import {
  clientVendorIntegrationsStateSelector,
  listClientVendorIntegrations,
} from "features/client-vendor-integrations";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExtensionTwoToneIcon from "@material-ui/icons/ExtensionTwoTone";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import * as T from "types/generated-types";
import Typography from "@material-ui/core/Typography";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Paper,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router-dom";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

const cardImageHeight = "140px";

const usePageStyles = makeStyles((theme) => ({
  paper: {
    flex: "1",
    margin: theme.spacing(2),
    backgroundColor: "#eee",
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(20),
  },
  accordionSummary: {
    borderBottom: "1px solid #aaa",
  },
  cardList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    columnGap: "20px",
    rowGap: "20px",
    flexBasis: "0",
    backgroundColor: "#f8f8f8",
  },
}));

export default function VendorIntegrationsPage() {
  const styles = usePageStyles();
  const dispatch = useDispatch();

  const [activeExpanded, setActiveExpanded] = useState(true);
  const [inactiveExpanded, setInactiveExpanded] = useState(true);

  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);

  const vendorIntegrationsState = useSelector(vendorIntegrationsStateSelector);
  const clientVendorIntegrationsState = useSelector(
    clientVendorIntegrationsStateSelector,
  );

  useEffect(() => {
    if (vendorIntegrationsState.status.kind === "not-loaded") {
      dispatch(listVendorIntegrations());
    }
  }, [dispatch, vendorIntegrationsState.status.kind]);

  useEffect(() => {
    if (clientVendorIntegrationsState.status.kind === "not-loaded") {
      dispatch(listClientVendorIntegrations());
    }
  }, [dispatch, clientVendorIntegrationsState.status.kind]);

  const activeVendorIntegrations: T.VendorIntegration[] = [];
  const inactiveVendorIntegrations: T.VendorIntegration[] = [];

  for (const vendorIntegration of vendorIntegrationsState.vendorIntegrations) {
    if (
      clientVendorIntegrationsState.clientVendorIntegrations.some(
        (cve) =>
          cve.isActive && cve.vendorIntegrationId === vendorIntegration.id,
      )
    ) {
      activeVendorIntegrations.push(vendorIntegration);
    } else {
      inactiveVendorIntegrations.push(vendorIntegration);
    }
  }

  return (
    <Box>
      <Paper className={styles.paper}>
        <Accordion
          expanded={activeExpanded}
          onChange={(_, expanded) => setActiveExpanded(expanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={styles.accordionSummary}
          >
            <Typography className={styles.accordionHeading}>
              Active Vendor Integrations
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={styles.cardList}>
            {activeVendorIntegrations.length === 0
              ? "You don't have any active vendor integrations."
              : ""}
            {activeVendorIntegrations &&
              activeVendorIntegrations.map((vi, i) => (
                <VendorIntegrationCard
                  key={i}
                  clientAccessId={accessId}
                  vi={vi}
                />
              ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={inactiveExpanded}
          onChange={(_, expanded) => setInactiveExpanded(expanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={styles.accordionSummary}
          >
            <Typography className={styles.accordionHeading}>
              Inactive Vendor Integrations
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={styles.cardList}>
            {inactiveVendorIntegrations.length === 0
              ? "No new vendor integrations are available."
              : ""}
            {inactiveVendorIntegrations &&
              inactiveVendorIntegrations.map((vi, i) => (
                <VendorIntegrationCard
                  key={i}
                  clientAccessId={accessId}
                  vi={vi}
                />
              ))}
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Box>
  );
}

const useCardStyles = makeStyles((theme) => ({
  card: {
    minWidth: 300,
    maxWidth: 500,
    flexBasis: "0",
    flex: "1 1 100px",
  },
  noLogo: {
    width: "100%",
    height: cardImageHeight,
    color: "#aaaaaa",
  },
}));

const VendorIntegrationCard = React.memo(
  ({
    vi,
    clientAccessId,
  }: {
    vi: T.VendorIntegration;
    clientAccessId: string;
  }) => {
    const styles = useCardStyles();
    const history = useHistory();

    return (
      <Card className={styles.card}>
        {vi.logoUrl ? (
          <img
            src={vi.logoUrl}
            title={vi.displayName}
            alt={vi.displayName}
            height={cardImageHeight}
            style={{
              padding: "10px",
              objectFit: "contain",
              width: "100%",
            }}
          />
        ) : (
          <ExtensionTwoToneIcon className={styles.noLogo} />
        )}
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {vi.displayName}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {vi.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            title="Configure this integration"
            size="small"
            color="primary"
            onClick={() => {
              history.push(`/c/${clientAccessId}/vendor-integrations/${vi.id}`);
            }}
          >
            Manage
          </Button>
        </CardActions>
      </Card>
    );
  },
);
