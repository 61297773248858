import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Api from "api";
import * as T from "types/generated-types";
import { ClientVendorIntegration } from "types/generated-types";

type ClientVendorIntegrationsStatus =
  | { kind: "not-loaded" }
  | { kind: "loading" }
  | { kind: "loaded" }
  | { kind: "error"; error: string }
  | { kind: "saving" };

export type ClientVendorIntegrationsState = {
  clientVendorIntegrations: ClientVendorIntegration[];
  status: ClientVendorIntegrationsStatus;
};

const initialState: ClientVendorIntegrationsState = {
  clientVendorIntegrations: [],
  status: { kind: "not-loaded" },
};

export const listClientVendorIntegrations = createAsyncThunk(
  "clientVendorIntegrations/list",
  async () => {
    return await Api.listClientVendorIntegrations();
  },
);

const clientVendorIntegrationsSlice = createSlice({
  name: "ClientVendorIntegrations",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(listClientVendorIntegrations.pending, (state) => {
      state.status = { kind: "loading" };
    });
    builder.addCase(listClientVendorIntegrations.rejected, (state) => {
      state.status = {
        kind: "error",
        error: "An error occurred while loading target types",
      };
    });
    builder.addCase(
      listClientVendorIntegrations.fulfilled,
      (state, { payload }) => {
        state.clientVendorIntegrations = payload;
        state.status = { kind: "loaded" };
      },
    );
  },
  reducers: {},
});

export const clientVendorIntegrationsStateSelector = (state: {
  clientVendorIntegrations: ClientVendorIntegrationsState;
}) => state.clientVendorIntegrations;

export default clientVendorIntegrationsSlice.reducer;

export const saveClientVendorIntegration = createAsyncThunk(
  "clientVendorIntegrations/save",
  async (
    clientVendorIntegration: {
      vendorIntegrationId: T.VendorIntegrationId;
      update: T.ClientVendorIntegrationUpdate;
    },
    thunkApi,
  ) => {
    await Api.saveClientVendorIntegration(
      clientVendorIntegration.vendorIntegrationId,
      clientVendorIntegration.update,
    );
    await thunkApi.dispatch(listClientVendorIntegrations());
  },
);
