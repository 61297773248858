import { Map as IMap, Set as ISet } from "immutable";
import _ from "lodash";
import React from "react";
import { Box } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import * as T from "types/generated-types";
import { DecoratedRuleHeader } from "types/engine-types";
import MessageSection from "../message-section";
import CauseLink from "../cause-link";
import { localAccessId } from "features/access-id";

const StipulationsSection = React.memo(
  ({
    stipulations,
    rulesById,
  }: {
    stipulations: ISet<T.StipulationValue>;
    rulesById: IMap<T.RuleId, DecoratedRuleHeader>;
  }) => {
    const sortedStipulations = _.sortBy(stipulations.toArray(), "text");
    const accessId = localAccessId();

    return (
      <Box className="stipulations">
        <Box mt={3} mb={1} fontSize="1.25rem">
          Stipulations
        </Box>
        {stipulations.size === 0 && <Box mb={3}>No stipulations.</Box>}
        {sortedStipulations.map((stip) => {
          const rule = rulesById.get(stip.ruleId);
          const ruleName = rule?.name || "<unknown rule>";

          return (
            <MessageSection isInDialog isListItem icon={<DescriptionIcon />}>
              {stip.text}
              <> &mdash; </>
              <CauseLink
                title={ruleName}
                link={`/c/${accessId}/rules/${stip.ruleId}`}
              />
            </MessageSection>
          );
        })}
      </Box>
    );
  },
);

export default StipulationsSection;
