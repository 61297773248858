import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import * as T from "types/engine-types";
import { useSelector, useDispatch } from "react-redux";
import DetailActions from "design/molecules/detail-actions";
import DetailHeader from "design/molecules/detail-header";
import NextButton from "design/atoms/next-button";
import PrevButton from "design/atoms/prev-button";
import { usePermissions } from "features/roles";
import RateSheetsList from "./_components/rate-sheets-list";
import ProductsList from "./_components/products-list";
import { productsLoadingSelector, getProducts } from "features/products";
import { rateSheetsSelector, getRateSheets } from "features/rate-sheets";
import {
  investorsSelector,
  filteredInvestorsSelector,
  getInvestors,
  updateInvestor,
  deleteInvestorById,
} from "features/investors";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

type Params = {
  id: T.InvestorId;
};

export default function ViewInvestorPage() {
  const { id } = useParams<Params>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const investorsState = useSelector(investorsSelector);
  const productsLoading = useSelector(productsLoadingSelector);
  const rateSheetsState = useSelector(rateSheetsSelector);
  const investors = useSelector(filteredInvestorsSelector);
  const hasPermission = usePermissions();
  const investor = investors.find((i) => i.id === id);
  const [isPricingEnabled, setIsPricingEnabled] = useState(
    investor?.isPricingEnabled || false,
  );
  const positionInList = investor && investors.indexOf(investor) + 1;
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);

  useEffect(() => {
    if (investor) {
      setIsPricingEnabled(investor.isPricingEnabled);
    }
  }, [investor, isPricingEnabled]);

  useEffect(() => {
    if (!productsLoading) dispatch(getProducts());
  }, [dispatch, productsLoading]);

  useEffect(() => {
    if (investors.length === 0) dispatch(getInvestors());
  }, [dispatch, investors.length]);

  useEffect(() => {
    if (rateSheetsState.rateSheets.length === 0) dispatch(getRateSheets());
  }, [dispatch, rateSheetsState.rateSheets.length]);

  // When the switch is toggled, update the investor and change state
  const handleIsPricingEnabled = async (
    _e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (investor) {
      dispatch(
        updateInvestor({
          investor: {
            id: investor.id,
            changeset: {
              name: investor.name,
              code: investor.code,
              isPricingEnabled: checked,
            },
          },
        }),
      );
    }
  };

  const hasAssociatedProducts = !!investor?.products?.length;
  const dialogTitle = hasAssociatedProducts
    ? "Unable to delete"
    : "Delete this investor?";
  const dialogText = hasAssociatedProducts
    ? `This investor cannot be deleted because there are still products
    linked to this investor?. Either delete those products or attach
    those products to another investor first.`
    : `All investor details will be lost.`;

  return (
    <>
      <DetailActions>
        <Button
          data-selector="back-to-investors"
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => history.push(`/c/${accessId}/investors`)}
        >
          Back to Investors ({positionInList}/{investors.length})
          <span
            style={{
              fontWeight: 100,
              marginLeft: "8px",
              textTransform: "none",
            }}
          >
            sorted: {investorsState.sortField} {investorsState.sortDir}
          </span>
          {investorsState.searchTerm && (
            <span style={{ fontWeight: 100, textTransform: "none" }}>
              , filtered: {investorsState.searchTerm}
            </span>
          )}
        </Button>

        <PrevButton
          list={investors}
          path={`/c/${accessId}/investors`}
          id={id}
          label="Previous Investor"
        />
        <NextButton
          list={investors}
          path={`/c/${accessId}/investors`}
          id={id}
          label="Next Investor"
        />

        <Box flex="1" />
        <Box>
          {investor && (
            <FormControlLabel
              data-selector="pricing-enable-switch"
              control={
                <Switch
                  disabled={!hasPermission("investors-edit")}
                  className={isPricingEnabled ? "checked" : "unchecked"}
                  checked={isPricingEnabled}
                  onChange={handleIsPricingEnabled}
                  color="primary"
                />
              }
              label={isPricingEnabled ? "Pricing Enabled" : "Pricing Disabled"}
            />
          )}
          <Button
            disabled={!hasPermission("investors-delete")}
            className="delete-investor"
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => setDeleteConfirmOpen(true)}
          >
            Delete
          </Button>
          <Button
            disabled={!hasPermission("investors-edit")}
            className="edit-investor"
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={() => history.push(`/c/${accessId}/investors/${id}/edit`)}
          >
            Edit
          </Button>
        </Box>
      </DetailActions>

      <DetailHeader>
        <Typography variant="h4">{investor?.name}</Typography>
        <Box flex={1} />
        <Typography variant="h5">{investor?.code}</Typography>
      </DetailHeader>

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogText}</DialogContentText>
        </DialogContent>
        {
          <DialogActions>
            <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
            {!hasAssociatedProducts && (
              <Button
                data-selector="delete-investor-confirm"
                color="secondary"
                onClick={() => {
                  if (investor) {
                    setDeleteConfirmOpen(false);
                    dispatch(deleteInvestorById(investor.id));
                    history.push(`/c/${accessId}/investors`);
                  }
                }}
              >
                Delete
              </Button>
            )}
          </DialogActions>
        }
      </Dialog>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: "1 1 auto",
          overflowX: "scroll",
        }}
      >
        <RateSheetsList rateSheets={investor?.rateSheets || []} />
        <ProductsList products={investor?.products || []} />
      </div>
    </>
  );
}
