import { Set as ISet } from "immutable";
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { SearchableDropdown } from "design/molecules/dropdown";
import {
  NumberFieldExpressionEditor,
  NumberFieldExpressionState,
  newNumberFieldExpressionState,
  convertNumberFieldExpressionToState,
  convertStateToNumberFieldExpression,
} from "../field-expression-editor";
import { Configuration } from "config";
import * as T from "types/engine-types";
import {
  ALL_NUMBER_FIELD_PREDICATE_OPS,
  NumberFieldPredicateOp,
  getNumberFieldPredicateOpName,
} from "features/field-predicates";
import { Setter, UiValidationError, usePropertySetter } from "features/utils";

export type NumberFieldPredicateState = {
  op: NumberFieldPredicateOp | null;
  operand: NumberFieldExpressionState;
  operandBottom: NumberFieldExpressionState;
  operandTop: NumberFieldExpressionState;
};

export function newNumberFieldPredicateState(
  valueType: T.FieldValueType.Number,
): NumberFieldPredicateState {
  return {
    op: null,
    operand: newNumberFieldExpressionState(valueType),
    operandBottom: newNumberFieldExpressionState(valueType),
    operandTop: newNumberFieldExpressionState(valueType),
  };
}

export function convertNumberFieldPredicateToState(
  valueType: T.FieldValueType.Number,
  pred: T.NumberFieldPredicateKind,
): NumberFieldPredicateState {
  switch (pred.op) {
    case "is-blank":
    case "is-not-blank":
      return {
        op: pred.op,
        operand: newNumberFieldExpressionState(valueType),
        operandBottom: newNumberFieldExpressionState(valueType),
        operandTop: newNumberFieldExpressionState(valueType),
      };
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return {
        op: pred.op,
        operand: convertNumberFieldExpressionToState(valueType, pred.other),
        operandBottom: newNumberFieldExpressionState(valueType),
        operandTop: newNumberFieldExpressionState(valueType),
      };
    case "between":
    case "not-between":
      return {
        op: pred.op,
        operand: newNumberFieldExpressionState(valueType),
        operandBottom: convertNumberFieldExpressionToState(
          valueType,
          pred.bottom,
        ),
        operandTop: convertNumberFieldExpressionToState(valueType, pred.top),
      };
  }
}

export function convertStateToNumberFieldPredicate(
  config: Configuration,
  valueType: T.FieldValueType.Number,
  state: NumberFieldPredicateState,
): T.NumberFieldPredicateKind {
  if (!state.op) {
    throw new UiValidationError("Select an operator");
  }

  switch (state.op) {
    case "is-blank":
    case "is-not-blank":
      return {
        type: "generic",
        op: state.op,
      };
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return {
        type: "number",
        op: state.op,
        other: convertStateToNumberFieldExpression(valueType, state.operand),
      };
    case "between":
    case "not-between":
      return {
        type: "number",
        op: state.op,
        bottom: convertStateToNumberFieldExpression(
          valueType,
          state.operandBottom,
        ),
        top: convertStateToNumberFieldExpression(valueType, state.operandTop),
      };
  }
}

const useStyles = makeStyles((t) =>
  createStyles({
    numberField: {
      "& .MuiInputBase-input": {
        textAlign: "right",
      },
    },
    fields: {
      "& .MuiTextField-root": {
        margin: t.spacing(1, 0),
        width: 270,
      },
    },
  }),
);

export const NumberFieldPredicateEditor = React.memo(
  ({
    state,
    valueType,
    setState,
    showValidationErrors,
    environment,
    localFields,
    config,
  }: {
    state: NumberFieldPredicateState;
    valueType: T.FieldValueType.Number;
    setState: Setter<NumberFieldPredicateState>;
    showValidationErrors: boolean;
    environment: ISet<T.FieldId>;
    localFields: T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
  }) => {
    const C = useStyles();

    const setOp = usePropertySetter(setState, "op");
    const setOperand = usePropertySetter(setState, "operand");
    const setOperandBottom = usePropertySetter(setState, "operandBottom");
    const setOperandTop = usePropertySetter(setState, "operandTop");

    return (
      <>
        <div className={C.fields}>
          <SearchableDropdown
            label="Operation"
            options={ALL_NUMBER_FIELD_PREDICATE_OPS}
            getOptionLabel={getNumberFieldPredicateOpName}
            error={showValidationErrors && !state.op}
            value={state.op}
            setValue={setOp}
          />
          {state.op && opHasSingleOperand(state.op) && (
            <NumberFieldExpressionEditor
              label="Value"
              state={state.operand}
              valueType={valueType}
              required={true}
              showErrors={showValidationErrors}
              setState={setOperand}
              environment={environment}
              localFields={localFields}
              config={config}
            />
          )}
          {state.op && opHasBottomTopOperands(state.op) && (
            <>
              <NumberFieldExpressionEditor
                label="Minimum"
                state={state.operandBottom}
                valueType={valueType}
                required={true}
                showErrors={showValidationErrors}
                setState={setOperandBottom}
                environment={environment}
                localFields={localFields}
                config={config}
              />
              <NumberFieldExpressionEditor
                label="Maximum"
                state={state.operandTop}
                valueType={valueType}
                required={true}
                showErrors={showValidationErrors}
                setState={setOperandTop}
                environment={environment}
                localFields={localFields}
                config={config}
              />
            </>
          )}
        </div>
      </>
    );
  },
);

function opHasSingleOperand(op: NumberFieldPredicateOp): boolean {
  switch (op) {
    case "is-blank":
    case "is-not-blank":
      return false;
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return true;
    case "between":
    case "not-between":
      return false;
  }
}

function opHasBottomTopOperands(op: NumberFieldPredicateOp): boolean {
  switch (op) {
    case "is-blank":
    case "is-not-blank":
      return false;
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return false;
    case "between":
    case "not-between":
      return true;
  }
}
