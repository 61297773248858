import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Map as IMap } from "immutable";
import {
  expandedConfigSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";

import * as Types from "types/engine-types";
import * as Fields from "features/fields";
import { unreachable } from "features/utils";

const useStyles = makeStyles((t) =>
  createStyles({
    name: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: "#666",
      fontSize: "0.75rem",
    },
    value: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  }),
);

type FieldViewerProps = {
  fieldId: Types.FieldId;
  fieldsById: IMap<Types.FieldId, Types.BaseFieldDefinition>;
  fieldValuesById: IMap<Types.FieldId, Types.FieldValue | null>;
};

export default function FieldViewer({
  fieldId,
  fieldsById,
  fieldValuesById,
}: FieldViewerProps) {
  const config = useSelector(expandedConfigSelector);
  const objectDetails = useSelector(objectDetailsMapSelector);

  if (!Fields.evaluateFieldCondition(fieldId, fieldsById, fieldValuesById)) {
    return null;
  }

  const field = fieldsById.get(fieldId)!;
  const valueType = field.valueType;
  const fieldValue: Types.FieldValue | null =
    fieldValuesById.get(fieldId) || null;

  let align: "left" | "right";

  switch (valueType.type) {
    case "enum":
    case "object-ref":
    case "string":
    case "duration":
    case "date":
    case "number":
    case "header":
      align = "left";
      break;
    default:
      unreachable(valueType);
  }

  return (
    <RenderedField
      fieldId={field.id}
      name={field.name}
      value={Fields.fieldValueToString(
        config,
        objectDetails,
        valueType,
        fieldValue,
      )}
      align={align}
      width={"200px"}
    />
  );
}

type RenderedFieldProps = {
  name: string;
  value: string | null;
  align: "left" | "right";
  width: string;
  fieldId?: Types.FieldId;
};

export function RenderedField({
  fieldId,
  name,
  value,
  align,
  width,
}: RenderedFieldProps) {
  const classes = useStyles();

  return (
    <Box
      data-field-id={fieldId ? fieldId.replaceAll("@", "-") : ""}
      p={1}
      style={{ flex: "0 0 auto", marginRight: "32px" }}
      title={`${name} = ${value || "—"}`}
    >
      <Typography className={classes.name}>{name}</Typography>
      <Typography align={align} className={classes.value}>
        {value?.replace("$-", "-$") || "—"}
      </Typography>
    </Box>
  );
}
