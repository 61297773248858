import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as Api from "api";
import * as T from "types/engine-types";
import { createSelector } from "reselect";
import _ from "lodash";
import { useSelector } from "react-redux";
import EmailValidator from "email-validator";
import { DetailedInvalidRequest } from "api";
import filterResults from "features/filter-list";
import { getErrorMessage } from "features/utils";
import { AppThunk } from "features/store";

export type UsersState = {
  users: T.DetailedUser[];
  searchTerm: string;
  sortField: string;
  sortDir: string;
  loading: boolean;
  errors: string;
  detailedErrors: T.UserValidation | null;
};

const initialState: UsersState = {
  users: [],
  loading: false,
  searchTerm: "",
  errors: "",
  detailedErrors: null,
  sortField: "displayName",
  sortDir: "asc",
};

export const getUsersThunk = createAsyncThunk("users/getUsers", async () => {
  return await Api.getUsers();
});

export const getUsers = (): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const users = await Api.getUsers();
      dispatch(setLoading(false));
      dispatch(setUsers(users));
    } catch (error) {
      newrelic.noticeError(getErrorMessage(error));
      dispatch(setErrors(error as string));
      dispatch(setLoading(false));
    }
  };
};

export const createUser = createAsyncThunk(
  "users/create",
  async (user: T.NewUser, thunkAPI) => {
    try {
      await Api.createUser(user);
      await thunkAPI.dispatch(getUsersThunk());
      return "success";
    } catch (error) {
      newrelic.noticeError(getErrorMessage(error));
      if (
        error instanceof DetailedInvalidRequest &&
        error.errors.type === "user"
      ) {
        thunkAPI.dispatch(setDetailedErrors(error.errors.value));
      } else if (error instanceof Error && error.name === "NotUniqueError") {
        thunkAPI.dispatch(
          setErrors(
            "Save failed because a user with this name already exists.",
          ),
        );
      } else if (error instanceof Error && error.message) {
        thunkAPI.dispatch(setErrors(error.message));
      } else {
        thunkAPI.dispatch(setErrors("Save failed because of an error."));
      }

      setTimeout(() => thunkAPI.dispatch(setErrors("")), 5000);

      return "error";
    }
  },
);

export const updateUser = createAsyncThunk(
  "users/update",
  async (
    user: {
      id: T.UserId;
      changeset: T.UserChangeset;
      pricingProfiles: T.PricingProfileId[];
    },
    thunkAPI,
  ) => {
    try {
      await Api.updateUser(user.id, user.changeset);
      await Api.assignUserPricingProfiles(user.id, user.pricingProfiles);
      await thunkAPI.dispatch(getUsersThunk());

      return "success";
    } catch (error) {
      newrelic.noticeError(getErrorMessage(error));
      if (
        error instanceof DetailedInvalidRequest &&
        error.errors.type === "user"
      ) {
        thunkAPI.dispatch(setDetailedErrors(error.errors.value));
      } else if (error instanceof Error && error.name === "NotUniqueError") {
        thunkAPI.dispatch(
          setErrors(
            "Save failed because a user with this name already exists.",
          ),
        );
      } else if (error instanceof Error && error.message) {
        thunkAPI.dispatch(setErrors(error.message));
      } else {
        thunkAPI.dispatch(setErrors("Save failed because of an error."));
      }

      setTimeout(() => thunkAPI.dispatch(setErrors("")), 5000);

      return "error";
    }
  },
);

export const deleteUserById = createAsyncThunk(
  "users/delete",
  async (userId: T.UserId, thunkAPI) => {
    await Api.deleteUser(userId);
    thunkAPI.dispatch(getUsersThunk());
  },
);

const userSlice = createSlice({
  name: "Users",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(deleteUserById.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(getUsersThunk.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(getUsersThunk.fulfilled, (state, { payload }) => {
      state.users = payload;
      state.loading = false;
    });
  },
  reducers: {
    setSort: (state, { payload }: PayloadAction<string>) => {
      let sortDir: string;
      if (payload === state.sortField) {
        if (state.sortDir === "asc") {
          sortDir = "desc";
        } else {
          sortDir = "asc";
        }
      } else {
        sortDir = "asc";
      }
      return {
        ...state,
        sortDir,
        sortField: payload,
      };
    },
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setDetailedErrors: (
      state,
      { payload }: PayloadAction<T.UserValidation>,
    ) => {
      return {
        ...state,
        errors: "Save failed because of an error.",
        detailedErrors: payload,
      };
    },
    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },
    setUsers: (state, { payload }: PayloadAction<T.DetailedUser[]>) => {
      state.users = payload;
    },
  },
});

export const {
  setLoading,
  setErrors,
  setUsers,
  setSearchTerm,
  setSort,
  setDetailedErrors,
} = userSlice.actions;

export default userSlice.reducer;

export const usersSelector = (state: { users: UsersState }) => state.users;

export const usersLoadingSelector = createSelector(
  (state: { users: UsersState }) => state.users,
  (users) => {
    return users.users.length !== 0 && !users.loading;
  },
);

export const filteredUsersSelector = createSelector(
  [
    (state: { users: UsersState }) => state.users.users,
    (state: { users: UsersState }) => state.users.searchTerm,
    (state: { users: UsersState }) => state.users.sortField,
    (state: { users: UsersState }) => state.users.sortDir,
  ],
  (users, searchTerm, sortField, sortDir) => {
    const filtered: T.DetailedUser[] = [];
    users.forEach((user: T.DetailedUser) => {
      const { noNotQuoteMatches, quoteMatch, restMatch } = filterResults(
        searchTerm,
        [user.displayName || "", user.emailAddress || "", user.roleName || ""],
      );

      if (noNotQuoteMatches && quoteMatch && restMatch) filtered.push(user);
    });

    let sorted = _.sortBy(filtered, [
      (o) => o[sortField as keyof T.DetailedUser]?.toLowerCase(),
      (o) => o.displayName.toLowerCase(),
    ]);
    if (sortDir === "desc") {
      sorted = _.reverse(sorted);
    }
    return sorted;
  },
);

export function useEmailAddressValidation() {
  const { users } = useSelector(usersSelector);

  const getEmailAddressErrors = (value: string, own?: string) => {
    if (!value) {
      return "Email Address is required!";
    } else if (
      users
        .map((u) => u.emailAddress)
        .filter((u) => u !== own)
        .includes(value)
    ) {
      return "This email address is already taken!";
    } else if (!EmailValidator.validate(value)) {
      return "This email address is malformed!";
    }

    return false;
  };

  return getEmailAddressErrors;
}

export function usePasswordValidation() {
  const getPasswordErrors = (
    password: string,
    passwordConfirmation: string,
  ) => {
    if (password && passwordConfirmation && password !== passwordConfirmation) {
      return "Password and confirmation must match";
    }

    return false;
  };

  return getPasswordErrors;
}

export function useRoleValidation() {
  const getRoleErrors = (value: unknown) => {
    if (!value) {
      return "Role is required!";
    }

    return false;
  };

  return getRoleErrors;
}

export function usePricingProfileValidation() {
  const getPricingProfileErrors = (value: T.PricingProfileId[]) => {
    if (!value || value.length === 0) {
      return "Pricing profile is required!";
    }

    return false;
  };

  return getPricingProfileErrors;
}
