import { Set as ISet } from "immutable";
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import * as T from "types/engine-types";
import { SearchableDropdown } from "design/molecules/dropdown";
import { Configuration } from "config";
import {
  ALL_DATE_FIELD_PREDICATE_OPS,
  DateFieldPredicateOp,
  getDateFieldPredicateOpName,
} from "features/field-predicates";
import { Setter, UiValidationError, usePropertySetter } from "features/utils";
import {
  convertDateFieldExpressionToState,
  convertStateToDateFieldExpression,
  DateFieldExpressionEditor,
  DateFieldExpressionState,
  newDateFieldExpressionState,
} from "../field-expression-editor";

export type DateFieldPredicateState = {
  op: DateFieldPredicateOp | null;
  operand: DateFieldExpressionState;
  operandBottom: DateFieldExpressionState;
  operandTop: DateFieldExpressionState;
};

export function newDateFieldPredicateState(
  valueType: T.FieldValueType.Date,
): DateFieldPredicateState {
  return {
    op: null,
    operand: newDateFieldExpressionState(valueType),
    operandBottom: newDateFieldExpressionState(valueType),
    operandTop: newDateFieldExpressionState(valueType),
  };
}

export function convertDateFieldPredicateToState(
  valueType: T.FieldValueType.Date,
  pred: T.DateFieldPredicateKind,
): DateFieldPredicateState {
  switch (pred.op) {
    case "is-blank":
    case "is-not-blank":
      return {
        op: pred.op,
        operand: newDateFieldExpressionState(valueType),
        operandBottom: newDateFieldExpressionState(valueType),
        operandTop: newDateFieldExpressionState(valueType),
      };
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return {
        op: pred.op,
        operand: convertDateFieldExpressionToState(valueType, pred.other),
        operandBottom: newDateFieldExpressionState(valueType),
        operandTop: newDateFieldExpressionState(valueType),
      };
    case "between":
    case "not-between":
      return {
        op: pred.op,
        operand: newDateFieldExpressionState(valueType),
        operandBottom: convertDateFieldExpressionToState(
          valueType,
          pred.bottom,
        ),
        operandTop: convertDateFieldExpressionToState(valueType, pred.top),
      };
  }
}

export function convertStateToDateFieldPredicate(
  config: Configuration,
  valueType: T.FieldValueType.Date,
  state: DateFieldPredicateState,
): T.DateFieldPredicateKind {
  if (!state.op) {
    throw new UiValidationError("Select an operator");
  }

  switch (state.op) {
    case "is-blank":
    case "is-not-blank":
      return {
        type: "generic",
        op: state.op,
      };
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return {
        type: "date",
        op: state.op,
        other: convertStateToDateFieldExpression(valueType, state.operand),
      };
    case "between":
    case "not-between":
      return {
        type: "date",
        op: state.op,
        bottom: convertStateToDateFieldExpression(
          valueType,
          state.operandBottom,
        ),
        top: convertStateToDateFieldExpression(valueType, state.operandTop),
      };
  }
}

const useStyles = makeStyles((t) =>
  createStyles({
    dateField: {
      "& .MuiInputBase-input": {
        textAlign: "right",
      },
    },
    fields: {
      "& .MuiTextField-root": {
        margin: t.spacing(1, 0),
        width: 270,
      },
    },
  }),
);

export const DateFieldPredicateEditor = React.memo(
  ({
    state,
    valueType,
    setState,
    showValidationErrors,
    environment,
    localFields,
    config,
  }: {
    state: DateFieldPredicateState;
    valueType: T.FieldValueType.Date;
    setState: Setter<DateFieldPredicateState>;
    showValidationErrors: boolean;
    environment: ISet<T.FieldId>;
    localFields: T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
  }) => {
    const C = useStyles();

    const setOp = usePropertySetter(setState, "op");
    const setOperand = usePropertySetter(setState, "operand");
    const setOperandBottom = usePropertySetter(setState, "operandBottom");
    const setOperandTop = usePropertySetter(setState, "operandTop");

    return (
      <div className={C.fields}>
        <SearchableDropdown
          label="Operation"
          options={ALL_DATE_FIELD_PREDICATE_OPS}
          getOptionLabel={getDateFieldPredicateOpName}
          value={state.op}
          error={showValidationErrors && !state.op}
          setValue={setOp}
        />
        {state.op && opHasSingleOperand(state.op) && (
          <DateFieldExpressionEditor
            label="Date"
            state={state.operand}
            valueType={valueType}
            required={true}
            showErrors={showValidationErrors}
            setState={setOperand}
            environment={environment}
            localFields={localFields}
            config={config}
          />
        )}
        {state.op && opHasBottomTopOperands(state.op) && (
          <>
            <DateFieldExpressionEditor
              label="Minimum"
              state={state.operandBottom}
              valueType={valueType}
              required={true}
              showErrors={showValidationErrors}
              setState={setOperandBottom}
              environment={environment}
              localFields={localFields}
              config={config}
            />
            <DateFieldExpressionEditor
              label="Maximum"
              state={state.operandTop}
              valueType={valueType}
              required={true}
              showErrors={showValidationErrors}
              setState={setOperandTop}
              environment={environment}
              localFields={localFields}
              config={config}
            />
          </>
        )}
      </div>
    );
  },
);

function opHasSingleOperand(op: DateFieldPredicateOp): boolean {
  switch (op) {
    case "is-blank":
    case "is-not-blank":
      return false;
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return true;
    case "between":
    case "not-between":
      return false;
  }
}

function opHasBottomTopOperands(op: DateFieldPredicateOp): boolean {
  switch (op) {
    case "is-blank":
    case "is-not-blank":
      return false;
    case "equals":
    case "does-not-equal":
    case "less-than":
    case "less-than-or-equal-to":
    case "greater-than":
    case "greater-than-or-equal-to":
      return false;
    case "between":
    case "not-between":
      return true;
  }
}
