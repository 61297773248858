import React, { useEffect, useState } from "react";
import { FrameWrapper, LoanComparison, DetailsWrapper } from "./styles";
import ProductDetailActionPanel from "../loan-pricing/_components/product-detail-action-panel";
import {
  loansSelector,
  addLoanToCompare,
  removeLoanFromCompare,
} from "features/loans";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import Button from "design/atoms/button";
import ActionButtons from "../loan-pricing/_components/action-buttons";
import whitespace from "design/subatomics/whitespace";

export default React.memo(() => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);
  const [toHide, setToHide] = useState<string[]>([]);

  const { loansToCompare, compareBackUrl } = useSelector(loansSelector);
  const formatedURLs = location.hash
    .replace("#", "")
    .split(",")
    .map((l) => {
      const uriDecoded = decodeURIComponent(l);
      const url = new URL(atob(uriDecoded));
      const queryParams = new URLSearchParams(url.search);

      queryParams.append("inCompareView", "true");

      if (
        !compareBackUrl &&
        !loansToCompare.includes(
          `${url.origin}${url.pathname}?${queryParams.toString()}${url.hash}`,
        )
      ) {
        dispatch(
          addLoanToCompare({
            loanUrl: `${url.origin}${url.pathname}?${queryParams.toString()}${
              url.hash
            }`,
          }),
        );
      }

      return `${url.origin}${url.pathname}?${queryParams.toString()}${
        url.hash
      }`;
    });

  let columnWidth = "calc(24% - 1px)";
  if (formatedURLs.length === 2) {
    columnWidth = "calc(50% - 1px)";
  } else if (formatedURLs.length === 3) {
    columnWidth = "calc((100% / 3) - 1px)";
  }

  type DataObj = {
    command: string;
    data: string;
  };

  useEffect(() => {
    window.addEventListener(
      "message",
      ({ origin, data }: { origin: string; data: DataObj }) => {
        if (origin.includes(window.location.origin)) {
          if (data.data && data.command === "compare::selectLoan") {
            const newUrl = data.data
              .replace(origin, "")
              .replace("?inCompareView=true", "");
            history.push(newUrl);
          }

          if (data.data && data.command === "compare::removeLoan") {
            const newUrl = data.data;
            dispatch(removeLoanFromCompare({ loanUrl: newUrl }));

            const encodedCompareLoans = loansToCompare.map((l) => {
              const hashed = btoa(l);
              return encodeURIComponent(hashed);
            });

            history.push(
              `/c/${accessId}/v2/loan-pricing/compare#${encodedCompareLoans.join(
                ",",
              )}`,
            );
          }
        }
      },
      false,
    );
  });

  return (
    <LoanComparison className="page-loan-comparison">
      <ProductDetailActionPanel loansToHide={toHide} compareMode={true} />

      <DetailsWrapper>
        {formatedURLs.map(
          (url) =>
            !toHide.includes(url) && (
              <FrameWrapper
                style={{
                  width: columnWidth,
                  minWidth: "480px",
                }}
              >
                <ActionButtons style={{ padding: whitespace("less") }}>
                  {loansToCompare.length - toHide.length > 2 && (
                    <Button
                      onClick={() => {
                        setToHide([...toHide, url]);
                      }}
                    >
                      Hide from Compare
                    </Button>
                  )}

                  <Button
                    isPrimary={true}
                    title="Select &amp; Review"
                    onClick={() => {
                      history.push(
                        url
                          .replace(window.location.origin, "")
                          .replace("inCompareView=true", ""),
                      );
                    }}
                  >
                    Select &amp; Review
                  </Button>
                </ActionButtons>

                <iframe
                  title={` Comparing ${url} `}
                  style={{
                    border: "none",
                    width: "100%",
                    height: "100%",
                  }}
                  src={url}
                />
              </FrameWrapper>
            ),
        )}
      </DetailsWrapper>
    </LoanComparison>
  );
});
