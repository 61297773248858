import * as T from "types/engine-types";

type IFrameMessageTransport = {
  inbox: T.IFrameMessage[] | undefined;
  outbox: T.IFrameMessage[] | undefined;
  replySource: MessageEventSource | null | undefined;
  replyOrigin: string | undefined;
  postMessage: (message: T.IFrameMessage) => void;
  onMessage: (
    messageListener: (message: T.IFrameMessage) => void,
  ) => MessageListener;
};

interface MessageListener {
  unsubscribe: () => void;
}

const iFrameMessageTransport: IFrameMessageTransport = {
  inbox: [],
  outbox: [],
  replySource: undefined,
  replyOrigin: undefined,
  postMessage(message: T.IFrameMessage) {
    if (Array.isArray(this.outbox)) {
      this.outbox.push(message);
      return;
    }

    if (!this.replySource || !this.replyOrigin) {
      return;
    }

    if (
      !(this.replySource instanceof MessagePort) &&
      !(this.replySource instanceof ServiceWorker)
    ) {
      this.replySource.postMessage(message, this.replyOrigin);
    }
  },

  onMessage(
    messageListener: (message: T.IFrameMessage) => void,
  ): MessageListener {
    if (Array.isArray(this.inbox)) {
      const messageQueue = this.inbox;
      this.inbox = undefined;

      for (const message of messageQueue) {
        messageListener(message);
      }
    }

    const listener = (event: MessageEvent) => {
      // This comment was generated when upgrading react-scripts and eslint
      // TODO: fix the lint rule and remove this eslint-disable comment
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      messageListener(event.data);
    };

    window.addEventListener("message", listener);

    return {
      unsubscribe: () => {
        window.removeEventListener("message", listener);
      },
    };
  },
};

window.addEventListener("message", (event: MessageEvent) => {
  // This comment was generated when upgrading react-scripts and eslint
  // TODO: fix the lint rule and remove this eslint-disable comment
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (typeof event.data === "object" && event.data.message === "connect") {
    iFrameMessageTransport.replyOrigin = event.origin;
    iFrameMessageTransport.replySource = event.source;

    if (Array.isArray(iFrameMessageTransport.outbox)) {
      const messageQueue = iFrameMessageTransport.outbox;
      iFrameMessageTransport.outbox = undefined;

      for (const message of messageQueue) {
        if (
          iFrameMessageTransport.replySource &&
          !(iFrameMessageTransport.replySource instanceof MessagePort) &&
          !(iFrameMessageTransport.replySource instanceof ServiceWorker)
        ) {
          iFrameMessageTransport.replySource.postMessage(
            message,
            iFrameMessageTransport.replyOrigin,
          );
        }
      }
    }
  }

  if (Array.isArray(iFrameMessageTransport.inbox)) {
    // This comment was generated when upgrading react-scripts and eslint
    // TODO: fix the lint rule and remove this eslint-disable comment
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    iFrameMessageTransport.inbox.push(event.data);
  }
});

export default iFrameMessageTransport;
