import { Map as IMap, OrderedMap as IOrderedMap, Set as ISet } from "immutable";
import React from "react";
import { getInvestorNameById } from "pages/products/list";
import { Box } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CommentIcon from "@material-ui/icons/Comment";
import DescriptionIcon from "@material-ui/icons/Description";
import WarningIcon from "@material-ui/icons/Warning";
import EnglishList from "design/atoms/english-list";
import { Configuration } from "config";
import * as T from "types/engine-types";
import {
  getProductStatus,
  getMissingFieldsOnProduct,
} from "features/execution";
import * as Stages from "features/stages";
import { formatDateTime } from "features/utils";
import { ObjectDetails } from "features/objects";
import PriceScenarioTable from "../price-scenario-table";
import MessageSection from "../message-section";
import CauseLink from "../cause-link";
import CalculationsSection from "../calculations-section";
import ProductFieldsSection from "../product-fields-section";
import ProductResultHeader from "../product-result-header";
import {
  getMissingFieldInfo,
  convertExecutionErrorToString,
  getRejectionReasonInfo,
} from "features/loans";
import { colors } from "pages/loans/styles";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import { useSelector } from "react-redux";

const ProductResultDetailView = React.memo(
  ({
    product,
    globalCalculatedFieldValuesById,
    rulesById,
    request,
    result,
    config,
    objectDetails,
    investors,
  }: {
    investors: T.DecoratedInvestorHeader[];
    request: T.ProductExecutionRequest;
    result: T.ProductExecutionResult;
    globalCalculatedFieldValuesById: IOrderedMap<
      T.FieldId,
      T.FieldValue | null
    >;
    rulesById: IMap<T.RuleId, T.DecoratedRuleHeader>;
    product: T.DecoratedProductHeader;
    config: Configuration;
    objectDetails: ObjectDetails;
  }) => {
    const productStatus = getProductStatus(result);
    const {
      client: { accessId },
    } = useSelector(nonNullApplicationInitializationSelector);
    switch (result.status) {
      case "filtered-out":
        return (
          <Box overflow="hidden">
            <ProductResultHeader
              investorName={getInvestorNameById(investors, product.investorId)}
              product={product}
              fgColor={"text.primary"}
              bgColor={colors.missingDataBg}
            />
            <MessageSection>
              The engine did not access information for this product because
              this product does not meet the search criteria provided. Broaden
              the search parameters to see information on this product.
            </MessageSection>
          </Box>
        );
      case "error": {
        if (productStatus === "available") {
          const missingFieldInfo = getMissingFieldInfo(
            config,
            rulesById,
            getMissingFieldsOnProduct(result),
            accessId,
          );

          return (
            <Box overflow="hidden">
              <ProductResultHeader
                investorName={getInvestorNameById(
                  investors,
                  product.investorId,
                )}
                product={product}
                fgColor={"text.primary"}
                bgColor={colors.missingDataBg}
              />
              <MessageSection>
                More information is needed to produce an Approved or Rejected
                response for this product.
                <br />
                Fill out the following{" "}
                {missingFieldInfo.length === 1 ? "field" : "fields"} to
                continue:
              </MessageSection>
              {missingFieldInfo.map((missingField) => (
                <MessageSection
                  key={missingField.fieldId}
                  icon={<DescriptionIcon />}
                >
                  <strong>{missingField.fieldName}</strong> is needed in{" "}
                  <EnglishList
                    items={missingField.causes.map((cause) => {
                      if (cause.link) {
                        return (
                          <CauseLink title={cause.title} link={cause.link} />
                        );
                      } else {
                        return cause.title;
                      }
                    })}
                  />
                  .
                </MessageSection>
              ))}
            </Box>
          );
        }

        const errorMessages = result.errors.map((err) =>
          convertExecutionErrorToString(err, config.allFieldsById),
        );

        return (
          <Box overflow="hidden">
            <ProductResultHeader
              investorName={getInvestorNameById(investors, product.investorId)}
              product={product}
              fgColor={colors.errorFg}
              bgColor={colors.errorBg}
              statusIcon={<WarningIcon />}
              statusMessage="Error"
            />
            <MessageSection>
              {errorMessages.length === 1 ? "An error " : "Multiple errors "}
              occurred while evaluating this product.
            </MessageSection>
            {errorMessages.map((msg, msgIndex) => (
              <MessageSection key={msgIndex} icon={<WarningIcon />} isListItem>
                {msg}
              </MessageSection>
            ))}
          </Box>
        );
      }
      case "rejected":
        const reasons = getRejectionReasonInfo(
          config,
          rulesById,
          result.rejections,
        );

        return (
          <Box overflow="hidden">
            <ProductResultHeader
              investorName={getInvestorNameById(investors, product.investorId)}
              product={product}
              fgColor={colors.rejectedFg}
              bgColor={colors.rejectedBg}
              statusIcon={<ClearIcon />}
              statusMessage="Rejected"
            />
            <MessageSection>
              Given the information provided, this loan would not meet this
              product's eligibility guidelines.
              <br />
              {reasons.length === 1
                ? "The following reason was "
                : `The following ${reasons.length} reasons were `}
              given for the rejection:
            </MessageSection>
            {reasons.map((reason, reasonIndex) => (
              <MessageSection
                key={reasonIndex}
                icon={<CommentIcon />}
                isListItem
              >
                {reason.title}
                {reason.cause && (
                  <>
                    {" "}
                    (
                    <CauseLink
                      title={reason.cause.name}
                      link={reason.cause.link}
                    />
                    )
                  </>
                )}
              </MessageSection>
            ))}
          </Box>
        );
      case "no-pricing":
      case "ok": {
        const productFieldValuesById = IMap(
          [...result.productFields].map((mapping) => [
            mapping.fieldId,
            mapping.value,
          ]),
        );

        const productCalculatedFieldValuesById = IMap(
          [...result.calculatedFields].map((mapping) => [
            mapping.fieldId,
            mapping.value,
          ]),
        );

        const calcStagesByScope = Stages.getCalcStagesByScope(config);
        const priceScenarios =
          result.status === "ok" ? result.priceScenarios : [];

        const effectiveTimestamp =
          result.status === "ok" ? result.rateSheetEffectiveTimestamp : null;

        return (
          <Box>
            <ProductResultHeader
              investorName={getInvestorNameById(investors, product.investorId)}
              product={product}
              fgColor={"text.primary"}
              bgColor={colors.missingDataBg}
            />
            <Box display="flex" className="flatten-for-print">
              <Box data-selector="price-scenario-table" px={3} my={3}>
                <Box fontSize={"1.125rem"}>Adjusted Pricing</Box>
                {effectiveTimestamp && (
                  <Box mb={3} fontSize={16}>
                    Effective as of: {formatDateTime(effectiveTimestamp)}
                  </Box>
                )}

                <PriceScenarioTable
                  request={request}
                  result={result}
                  product={product}
                  isInvestorPricingEnabled={result.isPricingEnabled}
                  scenarios={ISet(priceScenarios)}
                  config={config}
                  objectDetails={objectDetails}
                  rulesById={rulesById}
                />
              </Box>
              <Box flex="1">
                {calcStagesByScope.global.map((stage) => (
                  <CalculationsSection
                    config={config}
                    stage={stage}
                    fieldValuesById={globalCalculatedFieldValuesById}
                  />
                ))}
                <ProductFieldsSection
                  config={config}
                  fieldValuesById={productFieldValuesById}
                />
                {calcStagesByScope.product.map((stage) => (
                  <CalculationsSection
                    key={stage.id}
                    config={config}
                    stage={stage}
                    fieldValuesById={productCalculatedFieldValuesById}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        );
      }
    }
  },
);

export default ProductResultDetailView;
