import React, { useState, useEffect, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as T from "types/engine-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-date-picker";
import { useDebounce } from "features/utils";
import {
  expandedConfigSelector,
  usePermissions,
} from "features/application-initialization";
import {
  DynamicPipelineFilter,
  PipelineFilters,
  pipelineFiltersSelector,
  setCreatedAt,
  setOwnerId,
} from "features/pipeline";
import whitespace from "design/subatomics/whitespace";
import Popover from "design/layout/popover";
import TextInput from "design/atoms/text-input";
import FilterEditor from "../filter-editor";
import SortButtons from "../sort-buttons";
import {
  ManageFilters,
  RowWrapper,
  FilterCard,
  FilterText,
  PopoverWrapper,
  EllipsisText,
  SortsWrapper,
  TextWrapper,
} from "./styles";

export default React.memo(({ className }: { className?: string }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const config = useSelector(expandedConfigSelector);
  const pipelineFilters = useSelector(pipelineFiltersSelector);
  const hasPermission = usePermissions();
  const hasViewAllRecords = hasPermission("pipeline-records-view-all");

  const [createdAtValue, setCreatedAtValue] = useState<string>(
    pipelineFilters.createdAt,
  );
  const [ownerIdValue, setOwnerIdValue] = useState<string>(
    pipelineFilters.ownerId,
  );

  const [debouncedCreatedAtValue] = useDebounce(createdAtValue, 500);
  const [debouncedOwnerIdValue] = useDebounce(ownerIdValue, 500);

  function handleFilterClass(
    fieldName?: string,
    fieldDefinition?: T.BaseFieldDefinition,
  ): string {
    if (
      (pipelineFilters.sortField === "createdAt" &&
        fieldName === "createdAt") ||
      (!!pipelineFilters.createdAt && fieldName === "createdAt")
    ) {
      return "active";
    } else if (
      (pipelineFilters.sortField === "ownerId" && fieldName === "ownerId") ||
      (!!pipelineFilters.ownerId && fieldName === "ownerId")
    ) {
      return "active";
    } else if (
      pipelineFilters.sortField === fieldName ||
      pipelineFilters.dynamicFilters.find(
        (filter) => filter.id === fieldDefinition?.id,
      )
    ) {
      return "active";
    } else {
      return "inactive";
    }
  }

  function handleFilteringText(
    pipelineFilters: PipelineFilters,
    field?: string,
  ): string {
    const isFiltering = pipelineFilters.dynamicFilters.find(
      (filter) => filter.id === field,
    );
    if (pipelineFilters.createdAt && field === "createdAt") {
      const dateValue = new Date(pipelineFilters.createdAt);

      return dateValue.toDateString();
    } else if (pipelineFilters.ownerId && field === "ownerId") {
      return `Displaying records for user ${pipelineFilters.ownerId}`;
    } else if (pipelineFilters.sortField === field && !isFiltering) {
      return `Sorting by ${pipelineFilters.sortDir}`;
    } else {
      if (!isFiltering) {
        return "Not currently filtering";
      }

      switch (isFiltering?.disposition.type) {
        case "date":
          const dateValue = new Date(isFiltering.disposition.value);

          return dateValue.toDateString();
        case "duration":
          const durations = isFiltering.disposition.values.includedValues.map(
            (value) => {
              return `${value.count} ${value.unit}`;
            },
          );

          return !!durations.length
            ? durations.join(", ")
            : "No included values";
        case "enum":
          const enums = isFiltering.disposition.values.includedValues;
          const enumStrings = enums.map((value) => {
            return value.name;
          });

          return !!enumStrings.length
            ? enumStrings.join(", ")
            : "No included values";
        case "number":
          const min = isFiltering.disposition.values.min;
          const max = isFiltering.disposition.values.max;

          return `${min ? min + " <=" : ""} Value ${max ? "<= " + max : ""} `;
        case "string":
          return isFiltering.disposition.value;
        case undefined:
          return "";
      }
    }
  }

  useEffect(() => {
    dispatch(setCreatedAt(debouncedCreatedAtValue));
  }, [dispatch, debouncedCreatedAtValue]);

  useEffect(() => {
    dispatch(setOwnerId(debouncedOwnerIdValue));
  }, [dispatch, debouncedOwnerIdValue]);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);

    function setPipelineFilterParams(
      pipelineFilter: keyof PipelineFilters,
      emptyFilter: string | DynamicPipelineFilter[],
    ): void {
      if (pipelineFilters[pipelineFilter]) {
        params.set(
          "pipelineFilters",
          JSON.stringify({
            ...JSON.parse(
              params.get("pipelineFilters") || JSON.stringify(pipelineFilters),
            ),
            [pipelineFilter]: pipelineFilters[pipelineFilter],
          }),
        );
      } else {
        params.set(
          "pipelineFilters",
          JSON.stringify({
            ...JSON.parse(
              params.get("pipelineFilters") || JSON.stringify(pipelineFilters),
            ),
            [pipelineFilter]: emptyFilter,
          }),
        );
      }
    }

    setPipelineFilterParams("searchTerm", "");
    // we pass createdAt as the emptyFilter for sortField so that it is the default
    setPipelineFilterParams("sortField", "createdAt");
    setPipelineFilterParams("sortDir", "asc");
    setPipelineFilterParams("createdAt", "");
    setPipelineFilterParams("ownerId", "");
    setPipelineFilterParams("dynamicFilters", []);

    history.push(
      `${location.pathname}?${params.toString()}${
        location.hash ? "#" + location.hash.replaceAll("#", "") : ""
      }`,
    );
  }, [history, location.hash, location.pathname, pipelineFilters]);

  return (
    <ManageFilters
      className={
        className
          ? `page-component-pipeline-manage-filters ${className}`
          : "page-component-pipeline-manage-filters"
      }
    >
      <RowWrapper>
        {config.settings.pipelineFields.slice(0, 1).map((fieldId) => {
          const newField: T.BaseFieldDefinition | undefined =
            config.pipelineOnlyFields.find((field) => field.id === fieldId);

          return (
            newField && (
              <FilterCard
                key={newField.id}
                className={handleFilterClass(newField.id, newField)}
              >
                <TextWrapper>
                  <FilterText>{newField.name}</FilterText>
                  <EllipsisText>
                    {handleFilteringText(pipelineFilters, newField.id)}
                  </EllipsisText>
                </TextWrapper>
                <SortsWrapper>
                  <Popover
                    closeOnClick={false}
                    trigger={
                      <div className="popover-icon-wrapper">
                        <FontAwesomeIcon
                          style={{
                            marginRight: whitespace(),
                          }}
                          icon={faFilter}
                        />
                      </div>
                    }
                    content={
                      newField && (
                        <PopoverWrapper>
                          <FilterEditor filterField={newField} />
                        </PopoverWrapper>
                      )
                    }
                    delayShow={500}
                  />
                  {newField && (
                    <SortButtons
                      pipelineFilters={pipelineFilters}
                      fieldId={newField.id}
                    />
                  )}
                </SortsWrapper>
              </FilterCard>
            )
          );
        })}

        {hasViewAllRecords && (
          <FilterCard className={handleFilterClass("ownerId")}>
            <TextWrapper>
              <FilterText>Owner ID</FilterText>
              <EllipsisText>
                {handleFilteringText(pipelineFilters, "ownerId")}
              </EllipsisText>
            </TextWrapper>
            <SortsWrapper>
              <Popover
                closeOnClick={false}
                trigger={
                  <div className="popover-icon-wrapper">
                    <FontAwesomeIcon
                      style={{ marginRight: whitespace() }}
                      icon={faFilter}
                    />
                  </div>
                }
                content={
                  <PopoverWrapper>
                    <TextInput
                      placeholderText={"Owner ID"}
                      inputProps={{
                        value: ownerIdValue ? ownerIdValue : "",
                        onChange: (e: ChangeEvent<HTMLInputElement>) => {
                          setOwnerIdValue(e.target.value);
                        },
                      }}
                    />
                  </PopoverWrapper>
                }
                delayShow={500}
              />

              <SortButtons
                pipelineFilters={pipelineFilters}
                hardCodedSort="ownerId"
              />
            </SortsWrapper>
          </FilterCard>
        )}
      </RowWrapper>

      <RowWrapper>
        <FilterCard className={handleFilterClass("createdAt")}>
          <TextWrapper>
            <FilterText>Created at</FilterText>
            <EllipsisText>
              {handleFilteringText(pipelineFilters, "createdAt")}
            </EllipsisText>
          </TextWrapper>
          <SortsWrapper>
            <Popover
              closeOnClick={false}
              trigger={
                <div className="popover-icon-wrapper">
                  <FontAwesomeIcon
                    style={{ marginRight: whitespace() }}
                    icon={faFilter}
                  />
                </div>
              }
              content={
                <PopoverWrapper>
                  <DatePicker
                    minDate={new Date("1-1-1900")}
                    maxDate={new Date("12-31-9999")}
                    disabled={false}
                    onChange={(value: Date) => {
                      !!value
                        ? setCreatedAtValue(value.toString())
                        : setCreatedAtValue("");
                    }}
                    value={
                      createdAtValue ? new Date(createdAtValue) : undefined
                    }
                  />
                </PopoverWrapper>
              }
              delayShow={500}
            />
            <SortButtons
              pipelineFilters={pipelineFilters}
              hardCodedSort="createdAt"
            />
          </SortsWrapper>
        </FilterCard>
        {config.settings.pipelineFields.slice(1).map((fieldId) => {
          const newField: T.BaseFieldDefinition | undefined =
            config.allFieldsById.get(fieldId) ??
            config.pipelineOnlyFields.find((field) => field.id === fieldId);

          return (
            newField && (
              <FilterCard
                key={newField.id}
                className={handleFilterClass(newField.id, newField)}
              >
                <TextWrapper>
                  <FilterText>{newField?.name}</FilterText>
                  <EllipsisText>
                    {handleFilteringText(pipelineFilters, newField.id)}
                  </EllipsisText>
                </TextWrapper>
                <SortsWrapper>
                  <Popover
                    closeOnClick={false}
                    trigger={
                      <div className="popover-icon-wrapper">
                        <FontAwesomeIcon
                          style={{ marginRight: whitespace() }}
                          icon={faFilter}
                        />
                      </div>
                    }
                    content={
                      <PopoverWrapper>
                        {newField && <FilterEditor filterField={newField} />}
                      </PopoverWrapper>
                    }
                    delayShow={500}
                  />
                  {newField && (
                    <SortButtons
                      pipelineFilters={pipelineFilters}
                      fieldId={newField.id}
                    />
                  )}
                </SortsWrapper>
              </FilterCard>
            )
          );
        })}
      </RowWrapper>
    </ManageFilters>
  );
});
