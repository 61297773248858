import { Set as ISet } from "immutable";
import _ from "lodash";
import React, { useState } from "react";
import Loader from "react-loader";
import { useParams, useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { getInvestors } from "features/investors";

import * as Api from "api";
import * as T from "types/engine-types";
import {
  State,
  investorStateHasValidationErrors,
  convertInvestorToState,
  convertStateToInvestor,
  InvestorEditor,
} from "../_components/investor-editor";
import MessageBar from "design/atoms/message-bar";
import UnloadPrompt from "design/atoms/unload-prompt";
import { useDispatch } from "react-redux";
import { localAccessId } from "../../../features/access-id";

type Params = { id: T.InvestorId };

export default function EditInvestorPage() {
  const { id } = useParams<Params>();

  const [investorLoadState] = Api.useInvestor(id);
  const [investorsLoadState] = Api.useInvestors();
  const combinedLoadState = Api.combineLoadStates(
    investorLoadState,
    investorsLoadState,
  );

  if (combinedLoadState.status === "loading") {
    return <Loader loaded={false} />;
  }

  if (combinedLoadState.status === "error") {
    return (
      <MessageBar
        text="Something went wrong, please check your internet connection and try again later. If you see this message again, please contact support."
        type="error"
      />
    );
  }

  const [investor, investors] = combinedLoadState.value;

  return <EditInvestorPageLoaded {...{ investor, investors }} />;
}

type LoadedProps = {
  investor: T.Investor;
  investors: T.DecoratedInvestorHeader[];
};

function EditInvestorPageLoaded({ investor, investors }: LoadedProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const [investorStateModified, setInvestorState] = useState(() =>
    convertInvestorToState(investor),
  );

  const [isSave, setIsSave] = useState(false);

  const otherInvestors = investors.filter((p) => p.id !== investor.id);
  const existingNames = ISet(otherInvestors.map((p) => p.name));
  const existingCodes = ISet(otherInvestors.map((p) => p.code));

  const copyOfOriginalInvestorState: State | null = _.cloneDeep(
    convertInvestorToState(investor),
  );

  // Use investor data from server if there have been no state changes yet
  const investorState =
    investorStateModified || convertInvestorToState(investor);

  const hasErrors = investorStateHasValidationErrors(
    investorState,
    existingNames,
    existingCodes,
  );

  const accessId = localAccessId() || "";
  const saveInvestor = async () => {
    setIsSave(true);

    setShowValidationErrors(true);

    if (hasErrors) {
      setIsSave(false);
      return;
    }

    const updatedInvestor = convertStateToInvestor(investorState) as T.Investor;
    updatedInvestor.id = investor.id;

    setLoading(true);
    const newInvestor = await Api.saveInvestor(updatedInvestor);
    setLoading(false);
    dispatch(getInvestors());

    history.push(`/c/${accessId}/investors/${newInvestor.id}`);
  };

  // The route change will be blocked if the state has changed AND user did not hit the save button
  const shouldBlock =
    !_.isEqual(copyOfOriginalInvestorState, investorState) && !isSave;
  return (
    <>
      <UnloadPrompt when={shouldBlock} />
      <Loader loaded={!loading} />
      <Box m={2} display="flex">
        <Button
          variant="outlined"
          startIcon={<CloseIcon />}
          onClick={() =>
            history.push(`/c/${accessId}/investors/${investor.id}`)
          }
        >
          Cancel
        </Button>
        <Box flex="1" />
        {showValidationErrors && hasErrors && (
          <Box display="flex" alignItems="center" px={2}>
            <Typography color="secondary">
              Error: Some fields are missing or have invalid values.
            </Typography>
          </Box>
        )}
        <Button
          variant="outlined"
          startIcon={<DoneIcon />}
          onClick={saveInvestor}
          disabled={loading || (showValidationErrors && hasErrors)}
        >
          Save
        </Button>
      </Box>

      <InvestorEditor
        title="Edit investor"
        existingNames={existingNames}
        existingCodes={existingCodes}
        state={investorState}
        showValidationErrors={showValidationErrors}
        onChange={setInvestorState}
      />
    </>
  );
}
