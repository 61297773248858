import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import Logo from "design/atoms/logo";
import Button from "design/atoms/button";
import Icon from "design/atoms/icon";
import SlidingPanel from "design/layout/sliding-panel";
import Select from "design/atoms/select";
import IconLink from "design/atoms/icon-link";
import Links from "./_components/links";
import ForgotPassword from "./_components/forgot-password";
import * as T from "types/engine-types";
import { SingleValue } from "react-select";
import whitespace from "design/subatomics/whitespace";

import {
  PrimaryTitle,
  PrimaryTitleSub,
  SmallText,
} from "design/atoms/typography";
import {
  ApplicationHeader,
  PrimaryNav,
  UserDetails,
  UserConfig,
  NavigationLinks,
  BuildDetails,
} from "./styles";
import {
  faBars,
  faKey,
  faNoteSticky,
  faHeadset,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import applicationInitializationSlice, {
  nonNullApplicationInitializationSelector,
  getBuildEnvironment,
  getBuildNumber,
  logout,
  usePermissions,
  getMyPricingProfiles,
  overrideMyRole,
} from "features/application-initialization";
import { hasAdminSelector } from "features/roles";
import { loansSelector, loadScenarios } from "features/loans";
import color from "design/subatomics/colors";

export default React.memo(
  ({
    panelOpen,
    setPanelOpen,
  }: {
    panelOpen: boolean;
    setPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const queryParams = new URLSearchParams(document.location.search);
    const dispatch = useDispatch();
    const hasPermission = usePermissions();
    const applicationState = useSelector(
      nonNullApplicationInitializationSelector,
    );
    const sortedAssumableRoles = _.sortBy(applicationState.assumbableRoles, [
      (o) => o.name.toLowerCase(),
    ]);
    const { scenarios } = useSelector(loansSelector);
    const [forgotPasswordOpen, setForgotPasswordOpen] =
      useState<boolean>(false);

    useEffect(() => {
      if (scenarios.clientScoped === null || scenarios.userScoped === null) {
        dispatch(loadScenarios());
      }
    }, [dispatch, scenarios]);

    const userHasAdminRole = useSelector(hasAdminSelector);

    useEffect(() => {
      if (applicationState.myPricingProfiles.length === 0)
        dispatch(getMyPricingProfiles());
    }, [dispatch, applicationState.myPricingProfiles.length]);

    if (queryParams.get("inCompareView") === "true") {
      return <></>;
    }

    return (
      <>
        <div className="only-for-print" style={{ margin: `${whitespace()} 0` }}>
          <PrimaryTitle>{applicationState.client.name}</PrimaryTitle>
          <PrimaryTitleSub>{applicationState.user.displayName}</PrimaryTitleSub>

          <div>
            <SmallText>
              Permissioned as <strong>{applicationState.myRole.name}</strong>
            </SmallText>
          </div>

          <div>
            <SmallText>
              Pricing as{" "}
              <strong>{applicationState.myPricingProfile.name}</strong>
            </SmallText>
          </div>
        </div>

        <ApplicationHeader className="application-header">
          <PrimaryNav>
            <Logo />
            <div
              style={{
                alignItems: "center",
                flex: "1 1 auto",
                justifyContent: "flex-end",
                display: "flex",
                height: "100%",
              }}
            >
              <SmallText
                style={{
                  lineHeight: "12px",
                  textAlign: "right",
                  color: color({ color: "gray", shade: 4 }),
                }}
                className="hide-for-mobile"
                onClick={() => setPanelOpen(!panelOpen)}
              >
                Permissioned as {applicationState.myRole.name}
                <br />
                Pricing as {applicationState.myPricingProfile.name}
              </SmallText>

              <Button
                isPrimary={panelOpen}
                className="nav-trigger"
                onClick={(e) => {
                  e.preventDefault();
                  setPanelOpen(!panelOpen);
                }}
              >
                <Icon icon={faBars} />
              </Button>
            </div>
          </PrimaryNav>

          <SlidingPanel open={panelOpen}>
            {forgotPasswordOpen ? (
              <ForgotPassword setOpen={setForgotPasswordOpen} />
            ) : (
              <>
                <UserDetails>
                  <PrimaryTitle>
                    {applicationState.user.displayName}
                  </PrimaryTitle>
                  <PrimaryTitleSub>
                    {applicationState.client.name}
                  </PrimaryTitleSub>
                </UserDetails>

                <UserConfig>
                  <Select
                    className="pricing-profile-dropdown"
                    label="Pricing as"
                    getOptionLabel={(value: T.PricingProfileHeader) =>
                      value.name
                    }
                    getOptionValue={(value: T.PricingProfileHeader) => value.id}
                    value={applicationState.myPricingProfile}
                    options={applicationState.myPricingProfiles}
                    onChange={(
                      newValue: SingleValue<T.PricingProfileHeader>,
                    ) => {
                      const newProfile =
                        applicationState.myPricingProfiles.find(
                          (p) => p.id === newValue?.id,
                        );
                      if (newProfile) {
                        dispatch(
                          applicationInitializationSlice.actions.setMyPricingProfile(
                            newProfile,
                          ),
                        );
                      }
                    }}
                  />

                  {userHasAdminRole && (
                    <Select
                      className="permission-dropdown"
                      label="Permissioned as"
                      getOptionLabel={(value: T.RoleHeader) => value.name}
                      getOptionValue={(value: T.RoleHeader) => value.id}
                      value={applicationState.myRole}
                      options={sortedAssumableRoles}
                      onChange={(newValue: SingleValue<T.RoleHeader>) => {
                        const newRole = sortedAssumableRoles.find((r) => {
                          return r.id === newValue?.id;
                        });
                        if (newRole) {
                          dispatch(overrideMyRole(newRole.id));
                        }
                      }}
                    />
                  )}
                </UserConfig>

                <NavigationLinks
                  onClick={(e) => {
                    const stayOpen =
                      (
                        e.target as HTMLButtonElement
                      ).textContent?.toLowerCase() === "change password";

                    if (!stayOpen) {
                      setPanelOpen(false);
                    }
                  }}
                >
                  <Links inline={true} showLoanPricing={true} />

                  {hasPermission("change-password") && (
                    <IconLink
                      to="#"
                      icon={<Icon icon={faKey} />}
                      onClick={() => {
                        setForgotPasswordOpen(true);
                      }}
                    >
                      Change Password
                    </IconLink>
                  )}

                  {hasPermission("release-notes-menu-access") && (
                    <IconLink
                      external={true}
                      to="https://blog.loanpass.io/tag/releases"
                      icon={<Icon icon={faNoteSticky} />}
                    >
                      Release Notes
                    </IconLink>
                  )}

                  {hasPermission("support-menu-access") && (
                    <IconLink
                      external={true}
                      to="https://loanpass.atlassian.net/servicedesk/customer/portal/2"
                      icon={<Icon icon={faHeadset} />}
                    >
                      Contact Support
                    </IconLink>
                  )}
                </NavigationLinks>

                <BuildDetails>
                  <SmallText className="build-environment">
                    Build Envinronment: {getBuildEnvironment()}
                  </SmallText>

                  <SmallText className="build-number">
                    Build Number: {getBuildNumber()}
                  </SmallText>
                </BuildDetails>

                <Button
                  className="sign-out"
                  onClick={() => logout(applicationState.client.accessId)}
                  prefix={<Icon icon={faSignOut} />}
                >
                  Sign Out
                </Button>
              </>
            )}
          </SlidingPanel>
        </ApplicationHeader>
      </>
    );
  },
);
