import React, { useState, ReactNode } from "react";
import { Portal } from "react-portal";
import { usePopperTooltip, TriggerType } from "react-popper-tooltip";
import { Container, HoverSpan } from "./styles";
import { Placement } from "@popperjs/core";

export default React.memo(
  ({
    trigger,
    content,
    initialOpen = false,
    action = "click",
    className,
    placement,
    delayShow,
    closeOnClick = true,
  }: {
    trigger: ReactNode;
    content: ReactNode;
    initialOpen?: boolean;
    action?: TriggerType | TriggerType[] | null | undefined;
    className?: string;
    placement?: Placement;
    delayShow?: number;
    closeOnClick?: boolean;
  }) => {
    const [controlledVisible, setControlledVisible] = useState(initialOpen);

    const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
      usePopperTooltip({
        closeOnTriggerHidden: true,
        trigger: action,
        closeOnOutsideClick: true,
        visible: controlledVisible,
        onVisibleChange: setControlledVisible,
        placement: placement ? placement : "bottom",
        interactive: true,
        offset: [0, 0],
        delayShow: delayShow ? delayShow : 0,
      });

    return (
      <>
        <HoverSpan
          onClick={() => setControlledVisible(!controlledVisible)}
          ref={setTriggerRef}
        >
          {trigger}
        </HoverSpan>

        {visible && (
          <Portal>
            <Container
              ref={setTooltipRef}
              {...getTooltipProps({
                className: className
                  ? `tooltip-container ${className}`
                  : "tooltip-container",
              })}
            >
              <div>
                <div
                  onClick={() => {
                    setTimeout(() => {
                      return closeOnClick ? setControlledVisible(false) : null;
                    }, 1);
                  }}
                >
                  {content}
                </div>
              </div>
            </Container>
          </Portal>
        )}
      </>
    );
  },
);
