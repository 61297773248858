import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import whitespace from "design/subatomics/whitespace";

export const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${breakpoints.mobile} {
    gap: ${whitespace("less")};

    .typography-small-text {
      text-align: center;
      flex: 1 1 auto;
    }
    > button {
      flex: 0 1 auto;

      &:only-child {
        flex: 1 1 auto;
      }
    }
    span button {
      margin-right: 0;
    }
  }

  ${breakpoints.desktop} {
    margin: 0;
    gap: ${whitespace("less")};

    button,
    span button {
      flex: 1 0 auto;
      margin: ${whitespace("less")} 0;

      &:last-child {
        margin-right: 0;
        margin-left: 0;
      }

      &:only-child {
        flex: 1 1 auto;
      }
    }
  }
`;
