import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Loader from "react-loader";
import {
  filteredDataTablesSelector,
  getDataTables,
  dataTablesSelector,
} from "features/data-tables";
import { useSelector, useDispatch } from "react-redux";
import DetailActions from "design/molecules/detail-actions";
import DetailHeader from "design/molecules/detail-header";
import NextButton from "design/atoms/next-button";
import PrevButton from "design/atoms/prev-button";
import * as Api from "api";
import { LoadingOverlay } from "design/atoms/loading-overlay";
import MessageBar from "design/atoms/message-bar";
import * as T from "types/engine-types";
import { usePermissions } from "features/roles";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import DataTableBodyViewer from "../_components/DataTableBodyViewer";
import { ExportTableButton } from "../_components/ExportTableButton";

type Params = {
  id: T.DataTableId;
};

export default React.memo(function ViewDataTablePage() {
  const { id } = useParams<Params>();

  const [loadState, reloadDataTable] = Api.useDataTable(id);

  if (loadState.status === "loading") {
    return <Loader loaded={false} />;
  }

  if (loadState.status === "error") {
    return (
      <MessageBar
        text="Something went wrong, please check your internet connection and try again later. If you see this message again, please contact support."
        type="error"
      />
    );
  }

  return (
    <ViewDataTablePageLoaded
      dataTable={loadState.value}
      reloadDataTable={reloadDataTable}
    />
  );
});

const ViewDataTablePageLoaded = React.memo(
  ({
    dataTable,
    reloadDataTable,
  }: {
    dataTable: T.DataTable;
    reloadDataTable: () => void;
  }) => {
    const { id } = dataTable;
    const dispatch = useDispatch();
    const history = useHistory();
    const [loadingText, setLoadingText] = useState<string | null>(null);
    const dataTables = useSelector(filteredDataTablesSelector);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const showDeleteConfirm = useCallback(() => setDeleteConfirmOpen(true), []);
    const hasPermission = usePermissions();
    const hasEditPerm = hasPermission("data-tables-edit");
    const hasDeletePerm = hasPermission("data-tables-delete");
    const dataTableMatch = dataTables.find((i) => i.id === id);
    const positionInList =
      dataTableMatch && dataTables.indexOf(dataTableMatch) + 1;
    const dataTablesState = useSelector(dataTablesSelector);
    const {
      client: { accessId },
    } = useSelector(nonNullApplicationInitializationSelector);

    const onEditClick = useCallback(
      () => history.push(`/c/${accessId}/data-tables/${id}/edit`),
      [history, id, accessId],
    );

    useEffect(() => {
      if (dataTables.length === 0) {
        dispatch(getDataTables());
      }
    }, [dispatch, dataTables.length]);

    const deleteTable = useCallback(() => {
      (async () => {
        setLoadingText("Deleting data table...");
        await Api.deleteDataTable(dataTable.id);
        setLoadingText(null);
        dispatch(getDataTables());
        history.push(`/c/${accessId}/data-tables`);
      })();
    }, [dataTable.id, history, dispatch, accessId]);

    return (
      <>
        <LoadingOverlay when={!!loadingText} text={loadingText} />

        <DetailActions>
          <Button
            component={Link}
            to={`/c/${accessId}/data-tables`}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back to Data Tables ({positionInList}/{dataTables.length})
            <span
              style={{
                fontWeight: 100,
                marginLeft: "8px",
                textTransform: "none",
              }}
            >
              sorted: {dataTablesState.sortField} {dataTablesState.sortDir}
            </span>
            {dataTablesState.searchTerm && (
              <span style={{ fontWeight: 100, textTransform: "none" }}>
                , filtered: {dataTablesState.searchTerm}
              </span>
            )}
          </Button>

          <PrevButton
            list={dataTables}
            path={`/c/${accessId}/data-tables`}
            id={id}
            label="Previous Data Table"
          />
          <NextButton
            list={dataTables}
            path={`/c/${accessId}/data-tables`}
            id={id}
            label="Next Data Table"
          />
          <Box flex="1" />
          <ExportTableButton
            tableName={dataTable.name}
            columnDefs={dataTable.columnDefs}
            body={dataTable.body}
          />
          <Button
            disabled={!hasEditPerm}
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={onEditClick}
          >
            Edit
          </Button>
          <Button
            disabled={!hasDeletePerm}
            style={{ marginRight: 16 }}
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={showDeleteConfirm}
          >
            Delete
          </Button>
        </DetailActions>

        <DataTableViewer dataTable={dataTable} />

        <Dialog
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
        >
          <DialogTitle>
            Delete data table &ldquo;{dataTable.name}&rdquo;?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              All table data and history will be lost.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
            <Button
              color="secondary"
              onClick={() => {
                setDeleteConfirmOpen(false);
                deleteTable();
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  },
);

const DataTableViewer = React.memo(
  ({ dataTable }: { dataTable: T.DataTable }) => {
    return (
      <>
        <DetailHeader>
          <Typography variant="h4">{dataTable.name}</Typography>
        </DetailHeader>

        <DataTableBodyViewer
          columnDefs={dataTable.columnDefs}
          body={dataTable.body}
        />
      </>
    );
  },
);
