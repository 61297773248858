import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import * as Api from "api";
import * as T from "types/engine-types";

const initialState: MappingsByTargetState = {
  status: { kind: "not-loaded" },
  mappingsByTarget: null,
};

export type MappingStatus =
  | { kind: "not-loaded" }
  | { kind: "loading" }
  | { kind: "loaded" }
  | { kind: "error"; error: string };

export type MappingsByTargetState = {
  status: MappingStatus;
  mappingsByTarget: T.CodeOnlyFieldMappings | null;
};

export type MappingsByPurpose = { [key: string]: MappingsByTargetState };

export const createGetFieldMappingsThunk = (purpose: string) =>
  createAsyncThunk(`fieldMappings/${purpose}`, async () => {
    return await Api.listFieldMappings(purpose);
  });

export type MappingPurpose =
  | "encompass-from-transaction-origin"
  | "encompass-to-transaction-response";

const MAPPING_PURPOSES: MappingPurpose[] = [
  "encompass-from-transaction-origin",
  "encompass-to-transaction-response",
];

const fieldMappingsSlice = createSlice({
  name: "FieldMappings",
  initialState: {} as MappingsByPurpose,
  extraReducers: (builder) => {
    for (const purpose of MAPPING_PURPOSES) {
      const getterThunk = createGetFieldMappingsThunk(purpose);

      builder.addCase(getterThunk.pending, (state) => {
        if (!state.hasOwnProperty(purpose))
          state[purpose] = { ...initialState };

        state[purpose].status = { kind: "loading" };
      });

      builder.addCase(getterThunk.rejected, (state) => {
        if (!state.hasOwnProperty(purpose))
          state[purpose] = { ...initialState };

        state[purpose].status = {
          kind: "error",
          error: "An error occurred while loading field mappings",
        };
      });

      builder.addCase(getterThunk.fulfilled, (state, { payload }) => {
        if (!state.hasOwnProperty(purpose))
          state[purpose] = { ...initialState };

        state[purpose] = {
          status: { kind: "loaded" },
          mappingsByTarget: payload,
        };
      });
    }
  },
  reducers: {},
});

export default fieldMappingsSlice.reducer;

const createStateSelector = function (purpose: string) {
  return (state: { fieldMappings: MappingsByPurpose }) => {
    if (!state.fieldMappings.hasOwnProperty(purpose)) {
      return { ...initialState };
    } else {
      return state.fieldMappings[purpose];
    }
  };
};

export const createMappingsByTargetStateSelector = (purpose: string) => {
  return createSelector(createStateSelector(purpose), (state) => state);
};
