import { ObjectDetails } from "features/objects";
import { Set as ISet } from "immutable";
import { unreachable } from "features/utils";

import {
  dateFieldExpressionToString,
  durationFieldExpressionToString,
  numberFieldExpressionToString,
  stringFieldExpressionToString,
} from "pages/rules/_components/field-expression-viewer";
import { Configuration } from "config";
import * as T from "types/engine-types";
import { fieldValueToString } from "features/fields";

export type EnumFieldPredicateOp =
  | T.GenericFieldPredicate["op"]
  | T.EnumFieldPredicate["op"];

export const ALL_ENUM_PREDICATE_OPS: readonly EnumFieldPredicateOp[] = [
  "is-blank",
  "is-not-blank",
  "matches",
  "does-not-match",
];

export type ObjectRefFieldPredicateOp =
  | T.GenericFieldPredicate["op"]
  | T.ObjectRefFieldPredicate["op"];

export const ALL_OBJECT_REF_PREDICATE_OPS: readonly ObjectRefFieldPredicateOp[] =
  ["is-blank", "is-not-blank", "matches", "does-not-match"];

export type StringFieldPredicateOp =
  | T.GenericFieldPredicate["op"]
  | T.StringFieldPredicate["op"];

export const ALL_STRING_FIELD_PREDICATE_OPS: readonly StringFieldPredicateOp[] =
  [
    "is-blank",
    "is-not-blank",
    "equals",
    "does-not-equal",
    "starts-with",
    "does-not-start-with",
    "ends-with",
    "does-not-end-with",
    "contains",
    "does-not-contain",
  ];

export type NumberFieldPredicateOp =
  | T.GenericFieldPredicate["op"]
  | T.NumberFieldPredicate["op"];

export const ALL_NUMBER_FIELD_PREDICATE_OPS: readonly NumberFieldPredicateOp[] =
  [
    "is-blank",
    "is-not-blank",
    "equals",
    "does-not-equal",
    "less-than",
    "less-than-or-equal-to",
    "greater-than",
    "greater-than-or-equal-to",
    "between",
    "not-between",
  ];

export type DurationFieldPredicateOp =
  | T.GenericFieldPredicate["op"]
  | T.DurationFieldPredicate["op"];

export const ALL_DURATION_FIELD_PREDICATE_OPS: readonly DurationFieldPredicateOp[] =
  [
    "is-blank",
    "is-not-blank",
    "equals",
    "does-not-equal",
    "less-than",
    "less-than-or-equal-to",
    "greater-than",
    "greater-than-or-equal-to",
    "between",
    "not-between",
  ];

export type DateFieldPredicateOp =
  | T.GenericFieldPredicate["op"]
  | T.DateFieldPredicate["op"];

export const ALL_DATE_FIELD_PREDICATE_OPS: readonly DateFieldPredicateOp[] = [
  "is-blank",
  "is-not-blank",
  "equals",
  "does-not-equal",
  "less-than",
  "less-than-or-equal-to",
  "greater-than",
  "greater-than-or-equal-to",
  "between",
  "not-between",
];

export function getEnumFieldPredicateOpName(op: EnumFieldPredicateOp): string {
  switch (op) {
    case "is-blank":
      return "is blank";
    case "is-not-blank":
      return "is not blank";
    case "matches":
      return "is one of";
    case "does-not-match":
      return "is not one of";
  }
}

export function getObjectRefFieldPredicateOpName(
  op: ObjectRefFieldPredicateOp,
): string {
  switch (op) {
    case "is-blank":
      return "is blank";
    case "is-not-blank":
      return "is not blank";
    case "matches":
      return "is one of";
    case "does-not-match":
      return "is not one of";
  }
}

export function getStringFieldPredicateOpName(
  op: StringFieldPredicateOp,
): string {
  switch (op) {
    case "is-blank":
      return "is blank";
    case "is-not-blank":
      return "is not blank";
    case "equals":
      return "equals";
    case "does-not-equal":
      return "does not equal";
    case "starts-with":
      return "starts with";
    case "does-not-start-with":
      return "does not start with";
    case "ends-with":
      return "ends with";
    case "does-not-end-with":
      return "does not end with";
    case "contains":
      return "contains";
    case "does-not-contain":
      return "does not contain";
  }
}

export function getNumberFieldPredicateOpName(
  op: NumberFieldPredicateOp,
): string {
  switch (op) {
    case "is-blank":
      return "is blank";
    case "is-not-blank":
      return "is not blank";
    case "equals":
      return "equals";
    case "does-not-equal":
      return "does not equal";
    case "less-than":
      return "less than";
    case "less-than-or-equal-to":
      return "less than or equal to";
    case "greater-than":
      return "greater than";
    case "greater-than-or-equal-to":
      return "greater than or equal to";
    case "between":
      return "is between";
    case "not-between":
      return "is not between";
  }
}

export function getDurationFieldPredicateOpName(
  op: DurationFieldPredicateOp,
): string {
  switch (op) {
    case "is-blank":
      return "is blank";
    case "is-not-blank":
      return "is not blank";
    case "equals":
      return "equals";
    case "does-not-equal":
      return "does not equal";
    case "less-than":
      return "less than";
    case "less-than-or-equal-to":
      return "less than or equal to";
    case "greater-than":
      return "greater than";
    case "greater-than-or-equal-to":
      return "greater than or equal to";
    case "between":
      return "is between";
    case "not-between":
      return "is not between";
  }
}

export function getDateFieldPredicateOpName(op: DateFieldPredicateOp): string {
  switch (op) {
    case "is-blank":
      return "is blank";
    case "is-not-blank":
      return "is not blank";
    case "equals":
      return "is on";
    case "does-not-equal":
      return "is not on";
    case "less-than":
      return "is before";
    case "less-than-or-equal-to":
      return "is on or before";
    case "greater-than":
      return "is after";
    case "greater-than-or-equal-to":
      return "is on or after";
    case "between":
      return "is between";
    case "not-between":
      return "is not between";
  }
}

export function getStringFieldPredicateOperand(
  pred: T.StringFieldPredicateKind.String,
): T.Expression {
  switch (pred.op) {
    case "contains":
    case "does-not-contain":
      return pred.substring;
    case "ends-with":
    case "does-not-end-with":
      return pred.suffix;
    case "equals":
    case "does-not-equal":
      return pred.other;
    case "starts-with":
    case "does-not-start-with":
      return pred.prefix;
  }
}

export function isEnumFieldPredicate(
  pred: T.FieldPredicateKind,
): pred is T.EnumFieldPredicateKind {
  return pred.type === "generic" || pred.type === "enum";
}

export function isObjectFieldPredicate(
  pred: T.FieldPredicateKind,
): pred is T.ObjectRefFieldPredicateKind {
  return pred.type === "generic" || pred.type === "object-ref";
}

export function isStringFieldPredicate(
  pred: T.FieldPredicateKind,
): pred is T.StringFieldPredicateKind {
  return pred.type === "generic" || pred.type === "string";
}

export function isNumberFieldPredicate(
  pred: T.FieldPredicateKind,
): pred is T.NumberFieldPredicateKind {
  return pred.type === "generic" || pred.type === "number";
}

export function isDurationFieldPredicate(
  pred: T.FieldPredicateKind,
): pred is T.DurationFieldPredicateKind {
  return pred.type === "generic" || pred.type === "duration";
}

export function isDateFieldPredicate(
  pred: T.FieldPredicateKind,
): pred is T.DateFieldPredicateKind {
  return pred.type === "generic" || pred.type === "date";
}

export function fieldPredicateToString(
  environment: ISet<T.FieldId>,
  localFields: readonly T.RuleDataTableLookupFieldDefinition[],
  config: Configuration,
  objectDetails: ObjectDetails,
  pred: T.FieldPredicate,
): string {
  const field = config.allFieldsById.get(pred.fieldId);

  if (!field) {
    return "<Error: condition references a field that no longer exists>";
  }

  const valueType = field.valueType;

  switch (valueType.type) {
    case "enum": {
      // Wrap with immediately executed function to work around eslint limitations
      return (function () {
        if (pred.type !== "generic" && pred.type !== "enum") {
          return "<Error: field data type has changed>";
        }

        if (pred.type === "generic") {
          return `${field.name} ${getEnumFieldPredicateOpName(pred.op)}`;
        }

        const enumTypeId = pred.enumTypeId;

        if (pred.variantIds.length === 1) {
          const variantName = fieldValueToString(
            config,
            objectDetails,
            valueType,
            {
              type: "enum",
              enumTypeId,
              variantId: pred.variantIds[0],
            },
          );

          switch (pred.op) {
            case "matches":
              return `${field.name} is ${variantName || ""}`;
            case "does-not-match":
              return `${field.name} is not ${variantName || ""}`;
          }
        }

        const variantsText = pred.variantIds
          .map((variantId) =>
            fieldValueToString(config, objectDetails, valueType, {
              type: "enum",
              enumTypeId,
              variantId,
            }),
          )
          .join(", ");

        switch (pred.op) {
          case "matches":
            return `${field.name} is one of ${variantsText}`;
          case "does-not-match":
            return `${field.name} is not one of ${variantsText}`;
        }
      })();
    }
    case "object-ref": {
      // Wrap with immediately executed function to work around eslint limitations
      return (function () {
        if (pred.type !== "generic" && pred.type !== "object-ref") {
          return "<Error: field data type has changed>";
        }

        if (pred.type === "generic") {
          return `${field.name} ${getObjectRefFieldPredicateOpName(pred.op)}`;
        }

        if (pred.objectRefs.length === 1) {
          const refName = fieldValueToString(config, objectDetails, valueType, {
            type: "object-ref",
            objectRef: pred.objectRefs[0],
          });

          switch (pred.op) {
            case "matches":
              return `${field.name} is ${refName || ""}`;
            case "does-not-match":
              return `${field.name} is not ${refName || ""}`;
            default:
              return unreachable(pred);
          }
        }

        const refNames = pred.objectRefs
          .map((objectRef) =>
            fieldValueToString(config, objectDetails, valueType, {
              type: "object-ref",
              objectRef,
            }),
          )
          .join(", ");

        switch (pred.op) {
          case "matches":
            return `${field.name} is one of ${refNames}`;
          case "does-not-match":
            return `${field.name} is not one of ${refNames}`;
          default:
            return unreachable(pred);
        }
      })();
    }
    case "string": {
      if (pred.type !== "generic" && pred.type !== "string") {
        return "<Error: field data type has changed>";
      }

      const opName = getStringFieldPredicateOpName(pred.op);

      if (pred.type === "generic") {
        return `${field.name} ${opName}`;
      }

      const operandText = stringFieldExpressionToString(
        valueType,
        getStringFieldPredicateOperand(pred),
        config,
        environment,
        localFields,
      );

      return `${field.name} ${opName} ${operandText}`;
    }
    case "number": {
      // Wrap with immediately executed function to work around eslint limitations
      return (function () {
        if (pred.type !== "generic" && pred.type !== "number") {
          return "<Error: field data type has changed>";
        }

        const opName = getNumberFieldPredicateOpName(pred.op);

        if (pred.type === "generic") {
          return `${field.name} ${getNumberFieldPredicateOpName(pred.op)}`;
        }

        switch (pred.op) {
          case "equals":
          case "does-not-equal":
          case "less-than":
          case "less-than-or-equal-to":
          case "greater-than":
          case "greater-than-or-equal-to":
            const operandText = numberFieldExpressionToString(
              environment,
              localFields,
              config,
              valueType,
              pred.other,
            );
            return `${field.name} ${opName} ${operandText}`;
          case "between":
          case "not-between":
            const bottomText = numberFieldExpressionToString(
              environment,
              localFields,
              config,
              valueType,
              pred.bottom,
            );
            const topText = numberFieldExpressionToString(
              environment,
              localFields,
              config,
              valueType,
              pred.top,
            );
            return `${field.name} ${opName} ${bottomText} and ${topText}`;
        }
      })();
    }
    case "duration": {
      if (pred.type !== "generic" && pred.type !== "duration") {
        return "<Error: field data type has changed>";
      }

      const opName = getDurationFieldPredicateOpName(pred.op);

      if (pred.type === "generic") {
        return `${field.name} ${getDurationFieldPredicateOpName(pred.op)}`;
      }

      switch (pred.op) {
        case "equals":
        case "does-not-equal":
        case "less-than":
        case "less-than-or-equal-to":
        case "greater-than":
        case "greater-than-or-equal-to":
          const operandText = durationFieldExpressionToString(
            environment,
            localFields,
            config,
            valueType,
            pred.other,
          );
          return `${field.name} ${opName} ${operandText}`;
        case "between":
        case "not-between":
          const bottomText = durationFieldExpressionToString(
            environment,
            localFields,
            config,
            valueType,
            pred.bottom,
          );
          const topText = durationFieldExpressionToString(
            environment,
            localFields,
            config,
            valueType,
            pred.top,
          );
          return `${field.name} ${opName} ${bottomText} and ${topText}`;
      }
      break;
    }
    case "date": {
      if (pred.type !== "generic" && pred.type !== "date") {
        return "<Error: field data type has changed>";
      }

      const opName = getDateFieldPredicateOpName(pred.op);

      if (pred.type === "generic") {
        return `${field.name} ${getDateFieldPredicateOpName(pred.op)}`;
      }

      switch (pred.op) {
        case "equals":
        case "does-not-equal":
        case "less-than":
        case "less-than-or-equal-to":
        case "greater-than":
        case "greater-than-or-equal-to":
          const operandText = dateFieldExpressionToString(
            environment,
            localFields,
            config,
            valueType,
            pred.other,
          );
          return `${field.name} ${opName} ${operandText}`;
        case "between":
        case "not-between":
          const bottomText = dateFieldExpressionToString(
            environment,
            localFields,
            config,
            valueType,
            pred.bottom,
          );
          const topText = dateFieldExpressionToString(
            environment,
            localFields,
            config,
            valueType,
            pred.top,
          );
          return `${field.name} ${opName} ${bottomText} and ${topText}`;
      }
      break;
    }
    case "header": {
      throw new Error("Headers cannot have predicates.");
    }
    default:
      return unreachable(valueType);
  }
}
