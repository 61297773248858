import React, { useState, useEffect, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Box,
  Button,
  TextField as MaterialTextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import DetailActions from "design/molecules/detail-actions";
import DetailHeader from "design/molecules/detail-header";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import * as T from "types/engine-types";
import {
  usersSelector,
  filteredUsersSelector,
  usersLoadingSelector,
  getUsers,
  createUser,
  useEmailAddressValidation,
  usePasswordValidation,
  useRoleValidation,
  usePricingProfileValidation,
} from "features/users";
import { useAppDispatch } from "features/store";
import {
  pricingProfilesSelector,
  getPricingProfiles,
} from "features/pricing-profiles";
import { rolesSelector, getRoles } from "features/roles";
import FormHelperText from "@material-ui/core/FormHelperText";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

export default function ViewUserPage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const users = useSelector(filteredUsersSelector);
  const usersState = useSelector(usersSelector);
  const rolesState = useSelector(rolesSelector);
  const pricingProfilesState = useSelector(pricingProfilesSelector);
  const { roles } = rolesState;
  const { pricingProfiles } = pricingProfilesState;
  const [cancelConfirmOpen, setCancelConfirmOpen] = useState<boolean>(false);
  const { errors, loading, detailedErrors } = usersState;
  const [displayName, setDisplayName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const getEmailAddressErrors = useEmailAddressValidation();
  const emailAddressErrors = getEmailAddressErrors(emailAddress);
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
  const getPasswordErrors = usePasswordValidation();
  const passwordErrors = getPasswordErrors(password, passwordConfirmation);
  const [role, setRole] = useState<unknown>("");
  const getRoleErrors = useRoleValidation();
  const roleErrors = getRoleErrors(role);
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);
  const usersLoading = useSelector(usersLoadingSelector);
  const [newPricingProfiles, setNewPricingProfiles] = useState<
    T.PricingProfileId[]
  >([]);
  const getPricingProfileErrors = usePricingProfileValidation();
  const pricingProfileErrors = getPricingProfileErrors(newPricingProfiles);

  useEffect(() => {
    if (!roles || roles.length === 0) dispatch(getRoles());
  }, [dispatch, roles, roles.length]);

  useEffect(() => {
    if (!pricingProfiles || pricingProfiles.length === 0) {
      dispatch(getPricingProfiles());
    }
  }, [dispatch, pricingProfiles, pricingProfiles.length]);

  useEffect(() => {
    if (!users || !usersLoading) dispatch(getUsers());
  }, [dispatch, users, usersLoading]);

  useEffect(() => {
    if (!pricingProfiles || pricingProfiles.length === 0)
      dispatch(getPricingProfiles());
  }, [dispatch, pricingProfiles, pricingProfiles.length]);

  if (loading) {
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert>Loading</MuiAlert>
      </Snackbar>
    );
  } else {
    return (
      <>
        {errors && (
          <Snackbar
            open={true}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert severity="error">{errors}</MuiAlert>
          </Snackbar>
        )}
        <DetailActions>
          <>
            <Button
              className="cancel-user"
              variant="outlined"
              startIcon={<CloseIcon />}
              onClick={() => {
                setCancelConfirmOpen(true);
              }}
            >
              Cancel
            </Button>
            <Box flex="1" />

            <Dialog
              open={cancelConfirmOpen}
              onClose={() => setCancelConfirmOpen(false)}
            >
              <DialogTitle>Cancel Creating User</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  <strong>Warning!</strong> All unsaved progress will be lost.
                  Click "Cancel" to finish creating the user, or "Go to Users"
                  to discard the new user.
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setCancelConfirmOpen(false)}>
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    setCancelConfirmOpen(false);
                    history.push(`/c/${accessId}/users`);
                  }}
                >
                  Back to Users
                </Button>
              </DialogActions>
            </Dialog>

            <Button
              className="save-user"
              variant="outlined"
              startIcon={<DoneIcon />}
              disabled={
                !!emailAddressErrors ||
                !!passwordErrors ||
                !!roleErrors ||
                !!pricingProfileErrors
              }
              onClick={async () => {
                dispatch(
                  createUser({
                    emailAddress,
                    displayName,
                    password,
                    roleId: role as T.RoleId,
                    pricingProfileIds: newPricingProfiles,
                  }),
                )
                  .then(unwrapResult)
                  .then((status: "error" | "success") => {
                    if (status === "success") {
                      history.push(`/c/${accessId}/users`);
                    }
                  });
              }}
            >
              Save
            </Button>
          </>
        </DetailActions>

        <DetailHeader style={{ flexDirection: "row" }}>
          <Typography variant="h4">Create New User</Typography>
        </DetailHeader>

        <div
          style={{
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControl style={{ width: 500 }}>
            <MaterialTextField
              autoFocus={true}
              style={{ margin: "16px 0" }}
              label="Display Name"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
          </FormControl>

          <FormControl style={{ width: 500 }}>
            <MaterialTextField
              style={{ margin: "16px 0" }}
              label="Email Address"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              helperText={
                !!emailAddressErrors ? <>{emailAddressErrors}</> : <></>
              }
              error={!!emailAddressErrors}
            />
          </FormControl>

          <FormControl style={{ width: 500 }}>
            <MaterialTextField
              type="password"
              style={{ margin: "16px 0" }}
              label="Password"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              helperText={!!passwordErrors ? <>{passwordErrors}</> : <></>}
              error={!!passwordErrors}
            />
          </FormControl>

          <FormControl style={{ width: 500 }}>
            <MaterialTextField
              type="password"
              style={{ margin: "16px 0 0" }}
              label="Password Confirmation"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              helperText={!!passwordErrors ? <>{passwordErrors}</> : <></>}
              error={!!passwordErrors}
            />
          </FormControl>

          <FormControl style={{ width: 500, margin: "16px 0 8px" }}>
            <InputLabel>Select a Role</InputLabel>
            <Select
              value={role}
              label="Role"
              onChange={(e) => setRole(e.target.value)}
              error={!!roleErrors || !!detailedErrors?.roleId}
            >
              {roles &&
                roles.map((r: T.DecoratedRoleHeader) => (
                  <MenuItem key={r.id} value={r.id}>
                    {r.name}
                  </MenuItem>
                ))}
            </Select>
            {!!roleErrors && <FormHelperText>{roleErrors}</FormHelperText>}
            {detailedErrors?.roleId.map((e) => (
              <FormHelperText>{e.message}</FormHelperText>
            ))}
          </FormControl>

          <FormControl style={{ width: 500, margin: "8px 0 8px" }}>
            <InputLabel>Select a Pricing Profile</InputLabel>
            <Select
              multiple
              label="Pricing Profile"
              value={newPricingProfiles}
              // material ui has imprecise types for multiple selects
              // HTMLInputElement has a type of string for event.target.value despite material returning the value wrapped in an arrau
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(event: ChangeEvent<any>) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                setNewPricingProfiles(event.target.value);
              }}
              error={
                !!pricingProfileErrors || !!detailedErrors?.pricingProfiles
              }
            >
              {pricingProfiles &&
                pricingProfiles.map((pricingProfile) => (
                  <MenuItem key={pricingProfile.name} value={pricingProfile.id}>
                    {pricingProfile.name}
                  </MenuItem>
                ))}
            </Select>
            {!!pricingProfileErrors && (
              <FormHelperText>{pricingProfileErrors}</FormHelperText>
            )}
            {detailedErrors?.pricingProfiles.map((e) => (
              <FormHelperText>{e.message}</FormHelperText>
            ))}
          </FormControl>
        </div>
      </>
    );
  }
}
