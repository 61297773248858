import _ from "lodash";
import React, { useMemo } from "react";
import { Configuration } from "config";
import * as T from "types/engine-types";
import * as Fields from "features/fields";
import { ObjectDetails } from "features/objects";
import { usePriceScenarioTableStyles } from "pages/loans/styles";
import SummaryPriceScenarioTableCell from "../summary-price-scenario-table-cell";
import { getLowest } from "features/pricing-summaries";
import { Box } from "@material-ui/core";
import SuspendedPricingWarning from "../suspended-price-warning";
import { isPresent } from "features/utils";

const SummaryPriceScenarioTable = React.memo(
  ({
    result,
    config,
    objectDetails,
    isInvestorPricingEnabled,
  }: {
    isInvestorPricingEnabled?: Boolean;
    result:
      | T.ExecutionProductSummary.Approved
      | T.ExecutionProductSummary.ReviewRequired;
    config: Configuration;
    objectDetails: ObjectDetails;
  }) => {
    const C = usePriceScenarioTableStyles();
    const lowest = getLowest(result);

    // Whenever the client settings have a priceScenarioTable of type "rate-with-lock-period",
    // we've hard-coded that we have a field for the adjusted rate, rate lock period, and price.
    // However, we still look up these field definitions in order to display the values according
    // to the configured display style for these fields, using Fields.fieldValueToString

    const adjustedRateField =
      config.settings.priceScenarioTable?.type === "rate-with-lock-period"
        ? config.allFieldsById.get(
            config.settings.priceScenarioTable.adjustedRateFieldId,
          )
        : null;
    const adjustedRateLockPeriodField =
      config.settings.priceScenarioTable?.type === "rate-with-lock-period"
        ? config.allFieldsById.get(
            config.settings.priceScenarioTable.adjustedRateLockPeriodFieldId,
          )
        : null;
    const adjustedPriceField =
      config.settings.priceScenarioTable?.type === "rate-with-lock-period"
        ? config.allFieldsById.get(
            config.settings.priceScenarioTable.adjustedPriceFieldId,
          )
        : null;

    // If configuration is missing a rate sheet field
    if (
      !adjustedRateField ||
      !adjustedRateLockPeriodField ||
      !adjustedPriceField
    ) {
      return (
        <Box>
          Pricing is unavailable due to a configuration problem. Check Settings
          on the Rate Sheets page.
        </Box>
      );
    }

    // TODO: this is a potential bug, update this component to not return before calling useMemo
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const columnLabels = useMemo(() => {
      // _.uniq doesn't work here, it thinks the objects are unique already
      const lockPeriods = _.uniqBy(
        result.priceScenarios
          .map((s) => s.adjustedRateLockPeriod)
          .filter(isPresent),
        (l) => l.count,
      );

      return lockPeriods.map((value) => ({
        value,
        label: Fields.fieldValueToString(
          config,
          objectDetails,
          adjustedRateLockPeriodField.valueType,
          {
            type: "duration",
            ...value,
          },
        ),
      }));
    }, [config, objectDetails, result, adjustedRateLockPeriodField]);

    // TODO: this is a potential bug, update this component to not return before calling useMemo
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const rowLabels = useMemo(() => {
      const rates = _.uniq(
        result.priceScenarios.map((s) => s.adjustedRate).filter(isPresent),
      ).sort((a, b) => parseFloat(a) - parseFloat(b));

      return rates.map((value) => ({
        value,
        label: Fields.fieldValueToString(
          config,
          objectDetails,
          adjustedRateField.valueType,
          {
            type: "number",
            value,
          },
        ),
      }));
    }, [config, objectDetails, result, adjustedRateField]);

    const rows = rowLabels.map(
      ({ label: rowLabel, value: adjustedRate }, i) => (
        <tr key={i}>
          <th className={C.rowLabel}>
            <div>{rowLabel}</div>
          </th>
          {columnLabels.map(({ value: adjustedRateLockPeriod }, ii) => {
            const match = result.priceScenarios.find((s) => {
              return (
                s.adjustedRate === adjustedRate &&
                s.adjustedRateLockPeriod?.unit ===
                  adjustedRateLockPeriod.unit &&
                s.adjustedRateLockPeriod?.count === adjustedRateLockPeriod.count
              );
            });

            return (
              <SummaryPriceScenarioTableCell
                isBestEx={
                  match?.adjustedPrice === lowest?.adjustedPrice &&
                  match?.adjustedRate === lowest?.adjustedRate &&
                  match?.adjustedRateLockPeriod?.count ===
                    lowest?.adjustedRateLockPeriod?.count
                }
                scenarioSummary={match || null}
                adjustedPriceField={adjustedPriceField}
                config={config}
                objectDetails={objectDetails}
                key={ii}
              />
            );
          })}
        </tr>
      ),
    );

    return (
      <div>
        {!isInvestorPricingEnabled && <SuspendedPricingWarning />}
        <table style={{ marginTop: "8px", width: "100%" }} className={C.table}>
          <thead>
            <tr>
              <th></th>
              {columnLabels.map(({ label: columnLabel }, i) => (
                <th key={i} align="center" className={C.columnLabel}>
                  {columnLabel}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  },
);
export default SummaryPriceScenarioTable;
