import _ from "lodash";
import { unreachable } from "features/utils";

import { Configuration } from "../config";
import * as T from "types/engine-types";
import * as Util from "features/formulas-util";

export type BuiltinFunction = Readonly<{
  name: string;
  description: string;
  paramNames: readonly string[];
  typeSignatures: readonly FunctionTypeSignature[];
}>;

export type FunctionTypeSignature = Readonly<{
  paramTypes: readonly ValueType[];
  returnType: ValueType;
}>;

export type ValueType = Readonly<
  | { kind: "any" }
  | { kind: "boolean" }
  | { kind: "enum"; enumTypeId: T.EnumTypeId }
  | { kind: "object-ref"; objectType: T.ObjectType }
  | { kind: "number" }
  | { kind: "duration" }
  | { kind: "date" }
  | { kind: "string" }
  | { kind: "header" }
>;

export function anyT(): ValueType {
  return { kind: "any" };
}

export function booleanT(): ValueType {
  return { kind: "boolean" };
}

export function enumT(enumTypeId: T.EnumTypeId): ValueType {
  return { kind: "enum", enumTypeId };
}

export function numberT(): ValueType {
  return { kind: "number" };
}

export function durationT(): ValueType {
  return { kind: "duration" };
}

export function dateT(): ValueType {
  return { kind: "date" };
}

export function stringT(): ValueType {
  return { kind: "string" };
}

export function isSubtype(big: ValueType, small: ValueType): boolean {
  if (big.kind === "any") {
    return true;
  }

  return isSameType(big, small);
}

export function isSameType(t1: ValueType, t2: ValueType): boolean {
  return _.isEqual(t1, t2);
}

export function printValueType(
  config: Configuration,
  valueType: ValueType,
): string {
  switch (valueType.kind) {
    case "any":
      return "any";
    case "boolean":
      return "condition";
    case "enum":
      const enumType = config.enumTypesById.get(valueType.enumTypeId);
      const enumTypeName = enumType
        ? Util.getEnumTypeIdentifier(enumType)
        : "unknown";
      return enumTypeName + " enumerated type";
    case "object-ref":
      return printObjectType(valueType.objectType);
    case "number":
      return "number";
    case "string":
    case "header":
      return "text";
    case "duration":
      return "duration";
    case "date":
      return "date";
    default:
      return unreachable(valueType);
  }
}

function printObjectType(objectType: T.ObjectType): string {
  switch (objectType) {
    case "pricing-profile":
      return "pricing profile";
    default:
      return unreachable(objectType);
  }
}
