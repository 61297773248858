import * as DateFns from "date-fns";
import { Map as IMap, Set as ISet } from "immutable";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import Loader from "react-loader";
import { useParams, useHistory } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CopyIcon from "@material-ui/icons/FileCopy";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import {
  filteredProductsSelector,
  getProducts,
  productsSelector,
  productsLoadingSelector,
} from "features/products";
import { useSelector, useDispatch } from "react-redux";
import NextButton from "design/atoms/next-button";
import PrevButton from "design/atoms/prev-button";
import * as Api from "api";
import FieldViewer from "design/molecules/field-viewer";
import MessageBar from "design/atoms/message-bar";
import MultiSelectListViewer from "design/molecules/multi-select-list-viewer";
import * as T from "types/engine-types";
import DetailActions from "design/molecules/detail-actions";
import DetailHeader from "design/molecules/detail-header";
import { usePermissions } from "features/roles";
import {
  expandedConfigSelector,
  nonNullApplicationInitializationSelector,
} from "features/application-initialization";
import { localAccessId } from "features/access-id";

type Params = {
  id: T.ProductId;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    rightSideButtons: {
      "& > .MuiButton-root": {
        marginLeft: theme.spacing(2),
      },
    },
  }),
);

export default function ViewProductPage() {
  const { id } = useParams<Params>();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [productLoadState, reloadProduct] = Api.useProduct(id);
  const [rulesLoadState] = Api.useRules();
  const [investorsLoadState] = Api.useInvestors();
  const [loading, setLoading] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const products = useSelector(filteredProductsSelector);
  const hasPermission = usePermissions();
  const hasDeletePerm = hasPermission("products-delete");
  const hasEditPerm = hasPermission("products-edit");
  const hasCreatePerm = hasPermission("products-create");
  const productMatch = products.find((i) => i.id === id);
  const positionInList = productMatch && products.indexOf(productMatch) + 1;
  const productsState = useSelector(productsSelector);
  const productsLoading = useSelector(productsLoadingSelector);
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);

  useEffect(() => {
    if (!productsLoading) dispatch(getProducts());
  }, [dispatch, productsLoading]);

  if (
    productLoadState.status === "loading" ||
    rulesLoadState.status === "loading" ||
    investorsLoadState.status === "loading" ||
    loading
  ) {
    return <Loader loaded={false} />;
  }
  if (
    investorsLoadState.status === "error" ||
    productLoadState.status === "error" ||
    rulesLoadState.status === "error"
  ) {
    return (
      <MessageBar
        text="Something went wrong, please check your internet connection and try again later. If you see this message again, please contact support."
        type="error"
      />
    );
  }

  const product = productLoadState.value;
  const rules = rulesLoadState.value;
  const investors = investorsLoadState.value;

  async function deleteProduct() {
    setLoading(true);
    await Api.deleteProduct(id);
    setLoading(false);
    dispatch(getProducts());
    history.push(`/c/${accessId}/products`);
  }

  const toggleActivation = async () => {
    setLoading(true);
    const product = await Api.getProduct(id);
    if (product.activationTimestamp) {
      product.activationTimestamp = null;
    } else {
      product.activationTimestamp = DateFns.formatISO(new Date());
    }
    await Api.saveProduct(product);
    reloadProduct();
    dispatch(getProducts());
    setLoading(false);
  };

  return (
    <>
      <DetailActions>
        <Button
          data-selector="back-to-products"
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => history.push(`/c/${accessId}/products`)}
        >
          Back to Products ({positionInList}/{products.length})
          <span
            style={{
              fontWeight: 100,
              marginLeft: "8px",
              textTransform: "none",
            }}
          >
            sorted: {productsState.sortField} {productsState.sortDir}
          </span>
          {productsState.searchTerm && (
            <span style={{ fontWeight: 100, textTransform: "none" }}>
              , filtered: {productsState.searchTerm}
            </span>
          )}
        </Button>
        <PrevButton
          list={products}
          path={`/c/${accessId}/products`}
          id={id}
          label="Previous Product"
        />
        <NextButton
          list={products}
          path={`/c/${accessId}/products`}
          id={id}
          label="Next Product"
        />

        <Box flex="1" />
        <Box className={classes.rightSideButtons}>
          <Button
            disabled={!hasEditPerm}
            className="deactivate-product"
            variant="outlined"
            startIcon={<PowerSettingsNew />}
            onClick={toggleActivation}
          >
            {product.activationTimestamp ? "Deactivate" : "Activate"}
          </Button>
          <Button
            disabled={!hasDeletePerm}
            className="delete-product"
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => setDeleteConfirmOpen(true)}
          >
            Delete
          </Button>
          <Button
            disabled={!hasEditPerm}
            className="edit-product"
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={() => history.push(`/c/${accessId}/products/${id}/edit`)}
          >
            Edit
          </Button>
          <Button
            disabled={!hasCreatePerm}
            className="copy-product"
            variant="outlined"
            startIcon={<CopyIcon />}
            onClick={() =>
              history.push(
                `/c/${accessId}/create-product?basedOnProductId=${id}`,
              )
            }
          >
            Copy
          </Button>
        </Box>
      </DetailActions>

      <ProductViewer investors={investors} product={product} rules={rules} />

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Delete this product?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            All product specifications will be lost, but any rules associated
            with the product will remain intact and can be attached to another
            product in the future.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button
            color="secondary"
            onClick={() => {
              setDeleteConfirmOpen(false);
              deleteProduct();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

type ProductViewerProps = {
  product: T.Product;
  rules: T.DecoratedRuleHeader[];
  investors: T.DecoratedInvestorHeader[];
};

const useProductViewerStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      overflow: "hidden",
      margin: theme.spacing(2),
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      margin: theme.spacing(3),
    },
    title: {
      display: "flex",
      paddingTop: theme.spacing(2),
    },
    name: {},
    code: {
      color: "#aaa",
    },
    description: {
      margin: theme.spacing(0, 3, 3),
      maxWidth: 800,
      color: "#666",
    },
    fieldsHeading: {
      margin: theme.spacing(1, 3),
    },
    fieldsContainer: {
      display: "flex",
      flexWrap: "wrap",
      margin: theme.spacing(2),
    },
    filtersHeading: {
      margin: theme.spacing(1, 3),
    },
    filtersContainer: {
      margin: theme.spacing(2),
    },
    rulesHeading: {
      margin: theme.spacing(1, 3),
    },
    ruleSelectorsContainer: {
      display: "flex",
      flexWrap: "wrap",
      margin: theme.spacing(2, 0, 3, 3),
    },
    ruleSelector: {
      marginRight: theme.spacing(3),
    },
  }),
);

function ProductViewer({ product, rules, investors }: ProductViewerProps) {
  const classes = useProductViewerStyles();
  const engineConfiguration = useSelector(expandedConfigSelector);

  const productFields = engineConfiguration.productFields;
  const productFieldsById = IMap(productFields.map((f) => [f.id, f]));
  const rulesStages = engineConfiguration.rulesStages;

  const investorName = investors.find((i) => {
    return i.id === product.investorId;
  })?.name;

  let fieldValues = IMap<T.FieldId, T.FieldValue | null>(
    product.fieldValues.map((pair) => [pair.fieldId, pair.value]),
  );

  // Add default values for missing field values
  productFields.forEach((field) => {
    if (!fieldValues.has(field.id)) {
      fieldValues = fieldValues.set(field.id, null);
    }
  });

  const rulesById = IMap(rules.map((r) => [r.id, r]));
  const sortedRules = _.sortBy(rules, (r) => r.name.toLowerCase());
  const assignedRuleIds = ISet(product.ruleIds);
  const accessId = localAccessId();

  return (
    <>
      <DetailHeader
        style={{ flexDirection: "column", justifyContent: "flex-start" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            lineHeight: "48px",
          }}
        >
          <Typography style={{ flex: "0 0 auto" }} variant="h4">
            {product.name}
          </Typography>
          <Typography
            style={{
              flex: "1 1 auto",
              marginLeft: "8px",
              position: "relative",
              top: "-2px",
            }}
          >
            {investorName}
          </Typography>
          <Typography style={{ flex: "0 0 auto" }} variant="h5">
            {product.code}
          </Typography>
        </div>

        {product.description && (
          <Typography style={{ opacity: ".65", marginTop: "16px" }}>
            {product.description}
          </Typography>
        )}

        <Box
          style={{ display: "flex", margin: "8px -8px -8px", flexWrap: "wrap" }}
        >
          {productFields.map((field) => {
            return (
              <FieldViewer
                key={field.id}
                fieldId={field.id}
                fieldsById={productFieldsById}
                fieldValuesById={fieldValues}
              />
            );
          })}
        </Box>
      </DetailHeader>

      <Box className={classes.ruleSelectorsContainer}>
        {rulesStages.map((stage) => {
          const ruleIdsInStage = sortedRules
            .filter((r) => r.stageId === stage.id)
            .map((r) => r.id);
          const assignedRuleIdsInStage =
            ISet(ruleIdsInStage).intersect(assignedRuleIds);

          return (
            <MultiSelectListViewer
              key={stage.id}
              label={stage.name + " Rules"}
              className={classes.ruleSelector}
              noneSelectedMessage="No rules assigned."
              options={ruleIdsInStage}
              selected={assignedRuleIdsInStage}
              getOptionLabel={(ruleId) => rulesById.get(ruleId)!.name}
              getOptionLink={(ruleId) => `/c/${accessId}/rules/${ruleId}`}
            />
          );
        })}
      </Box>
    </>
  );
}
