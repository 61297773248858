import { Map as IMap } from "immutable";
import React from "react";
import { Box } from "@material-ui/core";
import FieldViewer from "design/molecules/field-viewer";
import { Configuration } from "config";
import * as T from "types/generated-types";
import { useVisibleFields } from "features/roles";
import { BaseFieldDefinition } from "types/engine-types";

const CalculationsSection = React.memo(
  ({
    config,
    stage,
    fieldValuesById,
  }: {
    config: Configuration;
    stage: T.ExecutionStage.Calculations;
    fieldValuesById: IMap<T.FieldId, T.FieldValue | null>;
  }) => {
    const hasVisibleField = useVisibleFields();
    return (
      <>
        <Box fontSize={"1.25rem"}>{stage.name}</Box>
        <Box
          data-selector="calculations-section"
          display="flex"
          flexWrap="wrap"
          mx={-1}
        >
          {stage.calculations
            .flatMap((calc): BaseFieldDefinition[] =>
              calc.type === "field"
                ? [{ ...calc.field, description: null }]
                : calc.lookup.fields.map((f) => ({
                    ...f,
                    description: null,
                  })),
            )
            .filter((field) => hasVisibleField(field.id))
            .map((field) => (
              <FieldViewer
                key={field.id}
                fieldId={field.id}
                fieldsById={config.allFieldsById}
                fieldValuesById={fieldValuesById}
              />
            ))}
        </Box>
      </>
    );
  },
);

export default CalculationsSection;
