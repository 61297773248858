import { Map as IMap } from "immutable";
import React, { useCallback } from "react";
import { Box } from "@material-ui/core";
import Field from "design/organisms/field";
import * as T from "types/engine-types";
import {
  FieldValueState,
  newFieldValueState,
} from "design/organisms/field-value-editor";
import { Setter, useById, useByCustomId, useIMapSetter } from "features/utils";
import { evaluateFieldCondition, getParentState } from "features/loans";
import { useFieldsColumnStyles } from "../../styles";
import ApplicationScenariosMenu from "../application-scenarios-menu";

const FieldsColumn = React.memo(
  ({
    applicationFields,
    applicationFieldValueStatesById,
    setApplicationFieldValueStatesById,
    fieldValueMappings,
    defaultFieldValues,
  }: {
    applicationFields: readonly T.BaseFieldDefinition[];
    applicationFieldValueStatesById: IMap<T.FieldId, FieldValueState>;
    setApplicationFieldValueStatesById: Setter<
      IMap<T.FieldId, FieldValueState>
    >;
    fieldValueMappings: T.FieldValueMapping[] | null;
    defaultFieldValues: T.DefaultFieldValue[];
  }) => {
    const C = useFieldsColumnStyles();
    const applicationFieldsById = useById(applicationFields);
    const applicationFieldValueSetter = useIMapSetter(
      setApplicationFieldValueStatesById,
    );
    const defaultFieldValuesByFieldId = useByCustomId(
      "fieldId",
      defaultFieldValues,
    );

    const applicationFieldsToDisplay = applicationFields
      .filter((f) => {
        // Filter out any fields that shouldn't be displayed because they
        // have a default value and have been marked as hidden.

        const defaultFieldValue = defaultFieldValuesByFieldId.get(f.id);
        if (defaultFieldValue != null) {
          return !defaultFieldValue.hidden;
        } else {
          return true;
        }
      })
      .filter((f) =>
        // Filter out any fields that shouldn't be displayed because their
        // conditions or parent's conditions aren't met.
        evaluateFieldCondition(
          f.id,
          applicationFieldsById,
          applicationFieldValueStatesById,
          defaultFieldValuesByFieldId,
        ),
      )
      .filter((f, i, all) => {
        // Filter out any headers that don't currently have fields displayed under them

        // Anything that isn't a header should be left alone
        if (f.valueType.type !== "header") return true;

        // If this is the last item and it's a header, filter it out
        if (i === all.length - 1) return false;

        // If this item and the next one are both headers, filter this one out
        if (all[i + 1].valueType.type === "header") return false;

        // Everything else should be displayed
        return true;
      });

    const clearAllFields = useCallback(() => {
      applicationFieldsById?.forEach((fieldState) => {
        applicationFieldValueSetter.withKey(fieldState.id)(
          newFieldValueState(fieldState.valueType),
        );
      });
    }, [applicationFieldsById, applicationFieldValueSetter]);
    return (
      <Box className={`${C.fieldsColumnContainer} fields-column`}>
        <ApplicationScenariosMenu
          fieldValueMappings={fieldValueMappings}
          clearAllFields={clearAllFields}
        />
        <Box className={`${C.fieldsColumn} fields-column-inner`}>
          {applicationFieldsToDisplay.map((field) => (
            <Field
              key={field.id}
              required={false}
              showErrors={true}
              margin="dense"
              field={field}
              fieldState={
                field
                  ? applicationFieldValueStatesById.get(field.id) ||
                    newFieldValueState(field.valueType)
                  : null
              }
              defaultValue={defaultFieldValuesByFieldId.get(field.id, null)}
              parentState={getParentState(
                field.conditions || [],
                applicationFieldsById,
                applicationFieldValueStatesById,
                defaultFieldValuesByFieldId,
              )}
              setState={(e) => {
                return applicationFieldValueSetter.withKey(field.id)(e);
              }}
            />
          ))}
        </Box>
      </Box>
    );
  },
);

export default FieldsColumn;
