import * as React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { Link as MaterialLink } from "@material-ui/core";
import { LinkBaseProps as MaterialLinkBaseProps } from "@material-ui/core/Link";

export const Link: React.ComponentType<
  MaterialLinkBaseProps & RouterLinkProps
> = (props) => {
  return <MaterialLink component={RouterLink} {...props} />;
};

export default Link;
