import { Set as ISet } from "immutable";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";

import { Configuration } from "config";
import * as T from "types/engine-types";
import { SearchableDropdown } from "design/molecules/dropdown";
import { useById } from "features/utils";

export type LocalOrGlobalFieldId =
  | { type: "local"; fieldId: T.LocalFieldId }
  | { type: "global"; fieldId: T.FieldId };

export const FieldPicker = React.memo(function FieldPicker({
  localFields,
  environment,
  config,
  fieldType,
  selected,
  onChange,
}: {
  fieldType: "any" | "number" | "date";
  localFields: readonly T.RuleDataTableLookupFieldDefinition[];
  environment: ISet<T.FieldId>;
  config: Configuration;
  selected: LocalOrGlobalFieldId | null;
  onChange: (selected: LocalOrGlobalFieldId | null) => void;
}) {
  const localFieldsById = useById(localFields);

  const getFieldName = useCallback(
    (fieldIdOption: LocalOrGlobalFieldId) => {
      switch (fieldIdOption.type) {
        case "local": {
          const localField = localFieldsById.get(fieldIdOption.fieldId);
          return localField?.name || "<Unknown Field>";
        }
        case "global": {
          const globalField = config.allFieldsById.get(fieldIdOption.fieldId);
          if (globalField && !environment.has(globalField.id)) {
            return `Inaccessible Field: ${globalField.name}`;
          }

          return globalField?.name || "<Unknown Field>";
        }
      }
    },
    [config, environment, localFieldsById],
  );

  const fieldIdOptions = useMemo(() => {
    const global = environment
      .toArray()
      .filter(
        (fieldId) =>
          fieldType === "any" ||
          config.allFieldsById.get(fieldId)?.valueType.type === fieldType,
      )
      .map((fieldId): LocalOrGlobalFieldId => ({ type: "global", fieldId }));

    const local = localFields
      .filter(
        ({ valueType }) => fieldType === "any" || valueType.type === fieldType,
      )
      .map(({ id }): LocalOrGlobalFieldId => ({ type: "local", fieldId: id }));

    const sortedByName = _.sortBy(global.concat(local), getFieldName);

    return sortedByName;
  }, [config.allFieldsById, getFieldName, environment, fieldType, localFields]);

  return (
    <SearchableDropdown
      options={fieldIdOptions}
      getOptionLabel={getFieldName}
      value={selected}
      setValue={onChange}
    />
  );
});
