import * as Syntax from "features/formulas-syntax";
import Peg from "pegjs";
import { getErrorMessage } from "features/utils";

const GRAMMAR = String.raw`
{
  function termListToLeftTree(terms, ops, makeNode) {
    if (terms.length === 0) {
      throw new Error("term list has no elements");
    }

    if (ops && ops.length !== (terms.length - 1)) {
      throw new Error("wrong number of ops");
    }

    if (terms.length === 1) {
      return terms[0];
    }

    const left = termListToLeftTree(
      terms.slice(0, terms.length - 1),
      ops && ops.slice(0, ops.length - 1),
      makeNode
    );
    const op = ops && ops[ops.length - 1];
    const right = terms[terms.length - 1];
    return makeNode(left, op, right);
  }

  function termListToRightTree(terms, ops, makeNode) {
    if (terms.length === 0) {
      throw new Error("term list has no elements");
    }

    if (ops && ops.length !== (terms.length - 1)) {
      throw new Error("wrong number of ops");
    }

    if (terms.length === 1) {
      return terms[0];
    }

    const left = terms[0];
    const op = ops && ops[0];
    const right = termListToRightTree(terms.slice(1), ops && ops.slice(1), makeNode);
    return makeNode(left, op, right);
  }

  function parseDurationUnit(keyword) {
    switch (keyword) {
      case "day":
      case "days":
        return "days";
      case "week":
      case "weeks":
        return "weeks";
      case "month":
      case "months":
        return "months";
      case "quarter":
      case "quarters":
        return "quarters";
      case "year":
      case "years":
        return "years";
    }
  }
}

Start = W e:Expression W {return e}

Expression = EqualityTest / EqualityTestTerm
EqualityTestTerm = Addition / AdditionTerm
AdditionTerm = Multiplication / MultiplicationTerm
MultiplicationTerm = Exponentiation / ExponentiationTerm
ExponentiationTerm
  = DateLiteral
  / FunctionCall
  / BooleanLiteral
  / DurationLiteral
  / NumberLiteral
  / StringLiteral
  / PropertyAccess
  / Identifier
  / NumericNegation
  / Parenthetical

EqualityTest = first:EqualityTestTerm rest:(W ("="/"<>"/"<="/">="/"<"/">") W EqualityTestTerm)+ {
  const opsMap = {
    "=": "equality-test",
    "<>": "inequality-test",
    "<": "less-than",
    ">": "greater-than",
    "<=": "less-than-or-equal",
    ">=": "greater-than-or-equal",
  };

  const terms = [first].concat(rest.map((match) => match[3]));
  const ops = rest.map((match) => match[1]);
  return termListToLeftTree(terms, ops, (left, op, right) => ({
    kind: opsMap[op],
    left,
    right,
  }));
}

Addition = first:AdditionTerm rest:(W ("+"/"-") W AdditionTerm)+ {
  const terms = [first].concat(rest.map((match) => match[3]));
  const ops = rest.map((match) => match[1]);
  return termListToLeftTree(terms, ops, (left, op, right) => ({
    kind: op === "+" ? "addition" : "subtraction",
    left,
    right,
  }));
}

Multiplication = first:MultiplicationTerm rest:(W ("*"/"/") W MultiplicationTerm)+ {
  const terms = [first].concat(rest.map((match) => match[3]));
  const ops = rest.map((match) => match[1]);
  return termListToLeftTree(terms, ops, (left, op, right) => ({
    kind: op === "*" ? "multiplication" : "division",
    left,
    right,
  }));
}

Exponentiation = first:ExponentiationTerm rest:(W "^" W ExponentiationTerm)+ {
  const terms = [first].concat(rest.map((match) => match[3]));
  return termListToRightTree(terms, null, (left, op, right) => ({
    kind: "exponentiation",
    left,
    right,
  }));
}

DateLiteral = "DATE(" month:$([0-9]+) "/" day:$([0-9]+) "/" year:$([0-9]+) ")" {
  return {
    kind: "date-literal",
    value: year + "-" + month + "-" + day 
  }
}

FunctionCall = name:Identifier W "(" W args:ArgumentList W ")" {
  return {
    kind: "function-call",
    func: name,
    args: args,
  }
}

ArgumentList =
  first:Expression rest:(W "," W Expression)* {
    return [first].concat(
      rest.map((match) => match[3])
    )
  }
  / "" {return []}

PropertyAccess = objectName:Identifier "." propertyName:Identifier {
  return {
    kind: 'property-access',
    objectName: objectName,
    propertyName: propertyName,
  }
}

Identifier = ident:$([a-zA-Z_] [a-zA-Z0-9_]*) {
  return {
    kind: "identifier",
    text: ident,
  }
}

BooleanLiteral =
  "true" {
    return {
      kind: "boolean-literal",
      value: true,
    }
  }
  / "false" {
    return {
      kind: "boolean-literal",
      value: false,
    }
  }

DurationLiteral =
  count:NumberLiteral Wreq unit:(
    "days" / "day" /
    "weeks" / "week" /
    "months" / "month" /
    "quarters" / "quarter" /
    "years" / "year"
  ) {
    return {
      kind: "duration-literal",
      count: count,
      unit: parseDurationUnit(unit),
    }
  }

NumberLiteral =
  num:$([0-9]* "." [0-9]+) {
    return {
      kind: "number-literal",
      value: num,
    }
  }
  / num:$([1-9] [0-9]*) {
    return {
      kind: "number-literal",
      value: num,
    }
  }
  / "0" {
    return {
      kind: "number-literal",
      value: "0",
    }
  }

StringLiteral =
  "\"" text:$([^"\\\r\n]*) "\"" {
    return {
      kind: "string-literal",
      value: text
    }
  }

NumericNegation = "-" W term:ExponentiationTerm {
  return {
    kind: 'numeric-negation',
    term
  }
}

Parenthetical = "(" W e:Expression W ")" { return e }

W "whitespace" = [ \t\n\r]*
Wreq "required-whitespace" = [ \t\n\r]+
`;

function generateParser(): Peg.Parser {
  try {
    return Peg.generate(GRAMMAR, { cache: true });
  } catch (err) {
    const error = new Error(
      "Formula parser failed to generate. Check GRAMMAR in formulas.ts",
    );
    console.error(error);
    console.error(err);
    newrelic.noticeError(error);
    newrelic.noticeError(getErrorMessage(err));

    throw err;
  }
}

const parser = generateParser();

export function parseFormula(formula: string): Syntax.Expression {
  return parser.parse(formula) as Syntax.Expression;
}
