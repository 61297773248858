import { Set as ISet } from "immutable";
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { SearchableDropdown } from "design/molecules/dropdown";
import {
  StringFieldExpressionEditor,
  StringFieldExpressionState,
  convertStateToStringFieldExpression,
  convertStringFieldExpressionToState,
  newStringFieldExpressionState,
} from "../field-expression-editor";
import { Configuration } from "config";
import * as T from "types/engine-types";
import {
  ALL_STRING_FIELD_PREDICATE_OPS,
  StringFieldPredicateOp,
  getStringFieldPredicateOpName,
  getStringFieldPredicateOperand,
} from "features/field-predicates";
import { Setter, UiValidationError, usePropertySetter } from "features/utils";

export type StringFieldPredicateState = {
  op: StringFieldPredicateOp | null;
  operand: StringFieldExpressionState;
};

export function newStringFieldPredicateState(
  valueType: T.FieldValueType.String,
): StringFieldPredicateState {
  return {
    op: null,
    operand: newStringFieldExpressionState(valueType),
  };
}

export function convertStringFieldPredicateToState(
  valueType: T.FieldValueType.String,
  pred: T.StringFieldPredicateKind,
  config: Configuration,
): StringFieldPredicateState {
  // field predicates don't support interpolation, so just use an empty environment
  const environment: ISet<T.FieldId> = ISet();

  switch (pred.op) {
    case "is-blank":
    case "is-not-blank":
      return {
        op: pred.op,
        operand: newStringFieldExpressionState(valueType),
      };
    case "contains":
    case "does-not-contain":
    case "ends-with":
    case "does-not-end-with":
    case "equals":
    case "does-not-equal":
    case "starts-with":
    case "does-not-start-with":
      return {
        op: pred.op,
        operand: convertStringFieldExpressionToState(
          valueType,
          getStringFieldPredicateOperand(pred),
          config,
          environment,
        ),
      };
  }
}

export function convertStateToStringFieldPredicate(
  config: Configuration,
  valueType: T.FieldValueType.String,
  state: StringFieldPredicateState,
): T.StringFieldPredicateKind {
  if (!state.op) {
    throw new UiValidationError("Select an operator");
  }

  // field predicates don't support interpolation, so just use an empty environment
  const environment: ISet<T.FieldId> = ISet();

  switch (state.op) {
    case "is-blank":
    case "is-not-blank":
      return {
        type: "generic",
        op: state.op,
      };
    case "equals":
    case "does-not-equal":
      return {
        type: "string",
        op: state.op,
        other: convertStateToStringFieldExpression(
          false,
          valueType,
          state.operand,
          config,
          environment,
        ),
      };
    case "starts-with":
    case "does-not-start-with":
      return {
        type: "string",
        op: state.op,
        prefix: convertStateToStringFieldExpression(
          false,
          valueType,
          state.operand,
          config,
          environment,
        ),
      };
    case "ends-with":
    case "does-not-end-with":
      return {
        type: "string",
        op: state.op,
        suffix: convertStateToStringFieldExpression(
          false,
          valueType,
          state.operand,
          config,
          environment,
        ),
      };
    case "contains":
    case "does-not-contain":
      return {
        type: "string",
        op: state.op,
        substring: convertStateToStringFieldExpression(
          false,
          valueType,
          state.operand,
          config,
          environment,
        ),
      };
  }
}

const useStyles = makeStyles((t) =>
  createStyles({
    textFields: {
      "& .MuiTextField-root": {
        margin: t.spacing(1, 0),
        width: 270,
      },
    },
  }),
);

export const StringFieldPredicateEditor = React.memo(
  ({
    state,
    valueType,
    setState,
    showValidationErrors,
    environment,
    localFields,
    config,
  }: {
    state: StringFieldPredicateState;
    valueType: T.FieldValueType.String;
    setState: Setter<StringFieldPredicateState>;
    showValidationErrors: boolean;
    environment: ISet<T.FieldId>;
    localFields: T.RuleDataTableLookupFieldDefinition[];
    config: Configuration;
  }) => {
    const C = useStyles();

    const setOp = usePropertySetter(setState, "op");
    const setOperand = usePropertySetter(setState, "operand");

    return (
      <div className={C.textFields}>
        <SearchableDropdown
          label="Operation"
          options={ALL_STRING_FIELD_PREDICATE_OPS}
          getOptionLabel={getStringFieldPredicateOpName}
          value={state.op}
          error={showValidationErrors && !state.op}
          setValue={setOp}
        />
        {state.op && opHasOperand(state.op) && (
          <StringFieldExpressionEditor
            allowInterpolation
            label="Text"
            state={state.operand}
            valueType={valueType}
            required={true}
            showErrors={showValidationErrors}
            environment={environment}
            localFields={localFields}
            config={config}
            setState={setOperand}
          />
        )}
      </div>
    );
  },
);

function opHasOperand(op: StringFieldPredicateOp): boolean {
  switch (op) {
    case "is-blank":
    case "is-not-blank":
      return false;
    case "contains":
    case "does-not-contain":
    case "starts-with":
    case "does-not-start-with":
    case "ends-with":
    case "does-not-end-with":
    case "equals":
    case "does-not-equal":
      return true;
  }
}
