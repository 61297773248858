import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { usePermissions } from "features/roles";
import * as T from "types/generated-types";
import IFrameListener from "design/application/iframe-listener";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import { useSelector } from "react-redux";

type GuardedRouteProps = {
  // component can't take props, instead, use `useHistory` and `useParams`
  // from within the component
  requiredPermission?: T.PermissionKind;
  component: React.ComponentType;
} & Omit<RouteProps, "component" | "hasAccess">;

const GuardedRoute = ({
  requiredPermission,
  component,
  ...rest
}: GuardedRouteProps) => {
  const hasPermission = usePermissions();
  const hasAccess = hasPermission(requiredPermission);
  const { client } = useSelector(nonNullApplicationInitializationSelector);
  const routeComponent = React.useCallback(
    () =>
      hasAccess ? (
        <>
          {React.createElement(component)}
          <IFrameListener />
        </>
      ) : (
        <Redirect to={{ pathname: `/c/${client.accessId}/v2/loan-pricing` }} />
      ),
    [hasAccess, client.accessId, component],
  );
  return <Route {...rest} render={routeComponent} />;
};

export default GuardedRoute;
