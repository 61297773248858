import React, { useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DoneIcon from "@material-ui/icons/Done";
import Loader from "react-loader";
import { Set as ISet, Map as IMap } from "immutable";
import _ from "lodash";
import {
  expandedConfigSelector,
  nonNullApplicationInitializationSelector,
} from "features/application-initialization";
import { useSelector } from "react-redux";

import * as Api from "api";
import MessageBar from "design/atoms/message-bar";
import {
  ProductEditor,
  ProductState,
  convertProductToState,
  convertStateToProduct,
  newProductState,
  validateProductState,
} from "../_components/product-editor";
import UnloadPrompt from "design/atoms/unload-prompt";
import * as types from "types/engine-types";
import { UiValidationError, multimap } from "features/utils";
import { useDispatch } from "react-redux";
import { getProducts } from "features/products";

export default function CreateProductPage() {
  const basedOnProductId = new URLSearchParams(useLocation().search).get(
    "basedOnProductId",
  ) as types.ProductId | null;

  const basedOnProductPromise = useMemo(() => {
    return basedOnProductId
      ? Api.getProduct(basedOnProductId)
      : Promise.resolve(undefined);
  }, [basedOnProductId]);

  const combinedLoadState = Api.combineLoadStates(
    Api.useRules()[0],
    Api.useProducts()[0],
    Api.useInvestors()[0],
    Api.useLoadState(basedOnProductPromise),
  );

  if (combinedLoadState.status === "loading") {
    return <Loader loaded={false}></Loader>;
  }

  if (combinedLoadState.status === "error") {
    return (
      <MessageBar
        text="Something went wrong, please check your internet connection and try again later. If you see this message again, please contact support."
        type="error"
      />
    );
  }

  const [rules, products, investors, basedOnProduct] = combinedLoadState.value;

  return (
    <CreateProductPageLoaded
      {...{ rules, products, investors, basedOnProduct }}
    />
  );
}

type LoadedProps = {
  rules: types.DecoratedRuleHeader[];
  products: types.DecoratedProductHeader[];
  investors: types.DecoratedInvestorHeader[];
  basedOnProduct: types.Product | undefined;
};

const { newrelic } = window;

function CreateProductPageLoaded({
  rules,
  products,
  investors,
  basedOnProduct,
}: LoadedProps) {
  const history = useHistory();
  const config = useSelector(expandedConfigSelector);
  const dispatch = useDispatch();
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);

  const initialProductState = () => {
    if (basedOnProduct) {
      const rulesById = IMap(rules.map((rule) => [rule.id, rule]));

      return convertProductToState(config, basedOnProduct, rulesById);
    }

    return newProductState(config);
  };

  const copyOfOriginalProductState: ProductState | null = _.cloneDeep(
    initialProductState(),
  );

  const [productState, setProductState] = useState(copyOfOriginalProductState);

  const [isCreate, setIsCreate] = useState(false);

  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const { existingNamesByInvestorId, existingCodes } = React.useMemo(() => {
    return {
      existingNamesByInvestorId: multimap(
        products.map((p) => [p.investorId, p.name]),
      ),
      existingCodes: ISet(products.map((p) => p.code)),
    };
  }, [products]);

  const currentError = validateProductState(
    config,
    productState,
    existingNamesByInvestorId,
    existingCodes,
  );

  const handleCreateProduct = async () => {
    setIsCreate(true);

    setShowValidationErrors(true);

    if (currentError) {
      setIsCreate(false);
      return;
    }

    let product;
    try {
      product = convertStateToProduct(
        productState,
        config,
        existingNamesByInvestorId,
        existingCodes,
      );
    } catch (err) {
      if (err instanceof UiValidationError) {
        newrelic.noticeError(err);
        alert(err.message);
        return;
      }

      throw err;
    }

    setLoading(true);
    const newProduct = await Api.createProduct(product);
    setLoading(false);
    dispatch(getProducts());
    history.push(`/c/${accessId}/products/${newProduct.id}`);
  };

  // The route change will be blocked if the state has changed AND user did not hit the create button
  const shouldBlock =
    !_.isEqual(copyOfOriginalProductState, productState) && !isCreate;

  return (
    <>
      <UnloadPrompt when={shouldBlock} />
      <Loader loaded={!loading} />
      <Box m={2} display="flex">
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => history.push(`/c/${accessId}/products`)}
        >
          Back to Products
        </Button>
        <Box flex="1" />
        {showValidationErrors && currentError && (
          <Box display="flex" alignItems="center" px={2}>
            <Typography color="secondary">
              Error: {currentError.message}
            </Typography>
          </Box>
        )}
        <Button
          variant="outlined"
          startIcon={<DoneIcon />}
          onClick={handleCreateProduct}
          disabled={loading || (showValidationErrors && !!currentError)}
        >
          Create
        </Button>
      </Box>

      <ProductEditor
        title={
          basedOnProduct ? `Copy of ${basedOnProduct.name}` : "Create product"
        }
        rules={rules}
        investors={investors}
        existingNamesByInvestorId={existingNamesByInvestorId}
        existingCodes={existingCodes}
        state={productState}
        showValidationErrors={showValidationErrors}
        setState={setProductState}
      />
    </>
  );
}
