import { ThunkAction } from "redux-thunk";
import { configureStore, Action } from "@reduxjs/toolkit";
import loansSliceReducer from "features/loans";
import rulesSliceReducer from "features/rules";
import usersSliceReducer from "features/users";
import investorsSliceReducer from "features/investors";
import brokenReferencesReducer from "./broken-references";
import rolesSliceReducer from "features/roles";
import pricingProfilesSliceReducer from "features/pricing-profiles";
import productsSliceReducer from "features/products";
import dataTablesSliceReducer from "features/data-tables";
import rateSheetsSliceReducer from "features/rate-sheets";
import applicationInitializationSlice from "features/application-initialization";
import pipelineSlice from "features/pipeline";
import loanPricingSlice from "features/loan-pricing";
import summaryReducer from "features/pricing-summaries";
import changePasswordSlice from "features/change-password";
import vendorIntegrationsSliceReducer from "features/vendor-integrations";
import clientVendorIntegrationsSliceReducer from "features/client-vendor-integrations";
import fieldMappingsSlice from "features/field-mappings";
import codeContextsSlice from "features/code-contexts";
import targetTypesSlice from "features/target-types";
import encompassCustomFieldsSlice from "features/encompass-custom-fields";
import { useDispatch } from "react-redux";

export type AppThunk = ThunkAction<void, object, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;

// typed dispatch, useful when you need a promise returned from dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const store = configureStore({
  reducer: {
    investors: investorsSliceReducer,
    vendorIntegrations: vendorIntegrationsSliceReducer,
    clientVendorIntegrations: clientVendorIntegrationsSliceReducer,
    fieldMappings: fieldMappingsSlice,
    codeContexts: codeContextsSlice,
    targetTypes: targetTypesSlice,
    encompassCustomFields: encompassCustomFieldsSlice,
    roles: rolesSliceReducer,
    pricingProfiles: pricingProfilesSliceReducer,
    users: usersSliceReducer,
    brokenReferences: brokenReferencesReducer,
    products: productsSliceReducer,
    dataTables: dataTablesSliceReducer,
    rateSheets: rateSheetsSliceReducer,
    rules: rulesSliceReducer,
    loans: loansSliceReducer,
    summary: summaryReducer,

    changePassword: changePasswordSlice.reducer,
    applicationInitialization: applicationInitializationSlice.reducer,
    loanPricing: loanPricingSlice.reducer,
    pipeline: pipelineSlice.reducer,
  },
});

export default store;
