import { Link } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";
import { useVirtual } from "react-virtual";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import * as T from "types/engine-types";
import { Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useSelector } from "react-redux";
import {
  pricingProfilesSelector,
  getPricingProfiles,
} from "features/pricing-profiles";
import * as Api from "api";
import { useAppDispatch } from "features/store";
import { localAccessId } from "features/access-id";

export default function PricingProfilesList({
  editMode,
  createMode,
  userId,
}: {
  editMode: Boolean;
  createMode: Boolean;
  userId: T.UserId;
}) {
  const dispatch = useAppDispatch();
  const pricingProfilesState = useSelector(pricingProfilesSelector);
  const { pricingProfiles } = pricingProfilesState;
  const parentRef = useRef<HTMLDivElement>(null);
  const [myPricingProfiles, setMyPricingProfiles] = useState<
    T.PricingProfileHeader[]
  >([]);

  const pricingProfilesToList =
    editMode || createMode ? pricingProfiles : myPricingProfiles;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredPricingProfiles, setFilteredPricingProfiles] = useState<
    T.PricingProfileHeader[]
  >(pricingProfilesToList);
  const assignedPricingProfiles = [];
  const [profilesFetched, setProfilesFetched] = useState<
    T.UserId | undefined
  >();
  const [profilesLoading, setProfilesLoading] = useState(false);

  useEffect(() => {
    if ((!profilesFetched || profilesFetched !== userId) && !profilesLoading) {
      setProfilesLoading(true);
      Api.getUserPricingProfiles(userId).then((fetchedProfiles) => {
        setMyPricingProfiles(fetchedProfiles);
        setProfilesFetched(userId);
        setProfilesLoading(false);
      });
    }
  }, [
    profilesFetched,
    myPricingProfiles,
    myPricingProfiles.length,
    userId,
    profilesLoading,
  ]);

  useEffect(() => {
    if (pricingProfiles?.length === 0) {
      dispatch(getPricingProfiles());
    }
  }, [dispatch, pricingProfiles]);

  useEffect(() => {
    setFilteredPricingProfiles(
      pricingProfilesToList.filter((p: T.PricingProfileHeader) => {
        return p.name.toLowerCase().includes(searchTerm.toLowerCase());
      }),
    );
  }, [searchTerm, pricingProfilesToList, editMode, createMode]);

  const rowVirtualizer = useVirtual({
    size: filteredPricingProfiles?.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, []),
  });
  const accessId = localAccessId();
  return (
    <Table
      style={{ flex: "1 0 24%", borderRight: "1px solid rgba(0,0,0,.15)" }}
    >
      <TableActions style={{ padding: "8px" }}>
        <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
          <SearchInput
            label="Pricing Profiles"
            requireDispatch={false}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <ResultsCount>
          {filteredPricingProfiles.length} <VisibilityIcon />
          <span className="separator">|</span>
          {assignedPricingProfiles.length} <CheckIcon />
          <span className="separator">|</span>
          {pricingProfiles.length} Total
        </ResultsCount>
      </TableActions>

      {editMode || createMode ? (
        <>
          <TableHeader>
            <TableHeaderCell style={{ flexBasis: "50px" }}>
              <Checkbox
                disabled={true}
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              />
            </TableHeaderCell>

            <TableHeaderCell style={{ flexBasis: "calc(100% - 50px)" }}>
              Pricing Profiles
            </TableHeaderCell>
          </TableHeader>

          {filteredPricingProfiles.length ? (
            <TableBodyWrapper ref={parentRef}>
              <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
                {rowVirtualizer.virtualItems.map((virtualRow) => (
                  <TableRow
                    key={virtualRow.index}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <TableCell style={{ flexBasis: "54px" }}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        onChange={(e, checked) => {
                          if (checked) {
                            // setPricingProfilesToAdd([
                            //   ...pricingProfilesToAdd,
                            //   filteredPricingProfiles[virtualRow.index],
                            // ]);
                          } else {
                            // setPricingProfilesToAdd(
                            //   _.without(
                            //     pricingProfilesToAdd,
                            //     filteredPricingProfiles[virtualRow.index],
                            //   ),
                            // );
                          }
                        }}
                      />
                    </TableCell>

                    <TableCell style={{ flexBasis: "calc(100% - 54px)" }}>
                      {filteredPricingProfiles[virtualRow.index].name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableBodyWrapper>
          ) : (
            <p
              style={{ marginTop: "14px", textAlign: "center", opacity: 0.65 }}
            >
              No pricingProfiles have been assigned to this role.
            </p>
          )}
        </>
      ) : (
        <>
          <TableHeader>
            <TableHeaderCell style={{ flexBasis: "100%" }}>
              Pricing Profiles
            </TableHeaderCell>
          </TableHeader>

          {filteredPricingProfiles.length ? (
            <TableBodyWrapper ref={parentRef}>
              <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
                {rowVirtualizer.virtualItems.map((virtualRow) => (
                  <TableRow
                    key={virtualRow.index}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <TableCell style={{ flexBasis: "100%" }}>
                      <Link
                        to={`/c/${accessId}/pricing-profiles/${
                          filteredPricingProfiles[virtualRow.index].id
                        }`}
                      >
                        {filteredPricingProfiles[virtualRow.index].name}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableBodyWrapper>
          ) : (
            <p
              style={{ marginTop: "14px", textAlign: "center", opacity: 0.65 }}
            >
              No pricing profiles have been assigned.
            </p>
          )}
        </>
      )}
    </Table>
  );
}
