import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Box, Button, Grid, PropTypes } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DeleteIcon from "@material-ui/icons/Delete";
import CopyIcon from "@material-ui/icons/FileCopy";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import {
  ObjectWithId,
  Setter,
  UiValidationError,
  genericReactMemo,
  useArraySetter,
  useLazyMemo,
  usePropertySetter,
  generateSafeId,
} from "features/utils";
import {
  stateHasErrors,
  isStateWithValErrors,
} from "features/server-validation";

export type ObjectListState<Obj> = {
  objects: readonly Obj[];
  selectedIndex: number | null;
};

export function newObjectListState<Obj>(objects: Obj[]): ObjectListState<Obj> {
  return {
    objects,
    selectedIndex: null,
  };
}

const useStyles = makeStyles((t) =>
  createStyles({
    objectList: {
      overflow: "auto",
      scrollbarWidth: "thin",
      marginRight: t.spacing(2),
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      width: 400,

      "&.error": {
        borderColor: "hsl(4.1, 89.6%, 58.4%)",
      },
    },
    objectListItemContainer: {
      transition: "height 0.2s ease-out",
    },
    objectListItem: {
      display: "flex",
      alignItems: "center",
      padding: "0 12px",
      cursor: "pointer",

      "&.dragging": {
        opacity: 0,
        height: 0,
      },

      "& .dragIcon": {
        display: "flex",
        marginLeft: -8,
        padding: 8,
        cursor: "grab",
      },

      "& .errorIcon": {
        padding: "8px 8px 8px 0",
      },

      "& .label": {
        flex: "1",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        padding: "8px 0",
      },

      "& .endIcon": {
        display: "flex",
        padding: "8px 0",
        opacity: 0,
      },

      "&:hover": {
        background: "#eee",

        "& .endIcon": {
          opacity: 0.4,
        },
      },

      "&.selected": {
        background: "#ddd",
        cursor: "default",

        "& .endIcon": {
          opacity: 0.8,
        },
      },

      "&.selected:hover": {
        background: "#ddd",
        cursor: "default",

        "& .endIcon": {
          opacity: 0.8,
        },
      },
    },
    objectListItemDragFiller: {
      display: "none",
      height: 40,
      background: "#8888ff",

      "&.show": {
        transition: "height 0.2s ease-out",
        display: "flex",
        height: 0,
      },
    },
    dropIndicator: {
      transition: "height 0.2s ease-out",
    },
  }),
);

export type ObjectListEditorCallbacks<
  Id extends string,
  Obj extends ObjectWithId<Id>,
> = {
  selectObject: (index: number | null) => void;
  addObject: (obj: Obj) => void;
  removeObject: (index: number) => void;
  getSelectedIndex: () => number | null;
  getObject: (index: number) => Obj | null;
  updateObject: (index: number, state: Obj) => void;
  getSelectedObject: () => Obj | null;
  updateSelectedObject: (state: Obj) => void;
};

export type ObjectListEditorButton<
  Id extends string,
  Obj extends ObjectWithId<Id>,
> = {
  name: string | ((callbacks: ObjectListEditorCallbacks<Id, Obj>) => string);
  icon?:
    | JSX.Element
    | ((callbacks: ObjectListEditorCallbacks<Id, Obj>) => JSX.Element | null);
  alignRight?: boolean;
  onClick: (callbacks: ObjectListEditorCallbacks<Id, Obj>) => void;
  disabled?:
    | boolean
    | ((callbacks: ObjectListEditorCallbacks<Id, Obj>) => boolean);
  color?: PropTypes.Color;
  variant?: "text" | "outlined" | "contained";
};

export const ObjectListEditor = genericReactMemo(
  <Id extends string, Obj extends ObjectWithId<Id>>({
    height,
    state,
    getObjectLabel,
    showErrors,
    validateObject,
    additionalButtons,
    allowDelete,
    allowDuplicate,
    makeObjectEditor,
    setState,
    sortBy,
    itemHasAdditionalErrors,
    emptyText,
    disableControls = false,
  }: {
    disableControls?: boolean;
    height?: string;
    state: ObjectListState<Obj>;
    additionalButtons: ObjectListEditorButton<Id, Obj>[];
    allowDelete?: boolean;
    allowDuplicate?: boolean;
    getObjectLabel: (obj: Obj) => JSX.Element;
    showErrors?: boolean;
    validateObject?: (obj: Obj, objIndex: number) => UiValidationError | null;
    makeObjectEditor: (props: {
      value: Obj;
      showErrors: boolean;
      index: number;
      setValue: Setter<Obj>;
    }) => React.ReactElement | null;
    setState: Setter<ObjectListState<Obj>>;
    sortBy?: (obj1: Obj, obj2: Obj) => number;
    itemHasAdditionalErrors?: (obj: Obj) => boolean;
    emptyText: string;
  }) => {
    const C = useStyles();

    const setObjects = usePropertySetter(setState, "objects");
    const setObject = useArraySetter(setObjects);

    const deleteSelectedObject = useCallback(() => {
      if (allowDelete === false) return;

      setState((state) => {
        if (state.selectedIndex === null) {
          return state;
        }

        const newObjects = [...state.objects];
        newObjects.splice(state.selectedIndex, 1);
        return { objects: newObjects, selectedIndex: null };
      });
    }, [setState, allowDelete]);

    const duplicateSelectedObject = useCallback(() => {
      setState((state) => {
        if (state.selectedIndex === null) {
          return state;
        }
        let duplicated = state.objects[state.selectedIndex];
        duplicated = JSON.parse(JSON.stringify(duplicated)) as Obj;
        duplicated.id = generateSafeId() as Id;
        const newObjects = [...state.objects];
        newObjects.splice(state.selectedIndex + 1, 0, duplicated);
        return { objects: newObjects, selectedIndex: null };
      });
    }, [setState]);

    const uiValidationErrors = state.objects.map((obj, objIdx) =>
      showErrors !== false && validateObject
        ? validateObject(obj, objIdx)
        : null,
    );

    const anyError = uiValidationErrors.some((msg) => msg !== null);

    const callbacks: ObjectListEditorCallbacks<Id, Obj> = useMemo(() => {
      const selectObject = (index: number | null) => {
        if (index !== null) {
          // If there's an index to select, make sure it's not out of bounds
          if (index < 0 || index >= state.objects.length) return;
        }

        setState((state) => ({
          ...state,
          selectedIndex: index,
        }));
      };

      const addObject = (obj: Obj) => {
        setState((state) => {
          const newObjects = state.objects.concat([obj]);
          return {
            objects: newObjects,
            selectedIndex: newObjects.length - 1,
          };
        });
      };

      const removeObject = (index: number) => {
        const newObjects = [...state.objects];
        newObjects.splice(index, 1);

        setState((state) => ({
          objects: newObjects,
          selectedIndex: null,
        }));
      };

      const getSelectedIndex = () => state.selectedIndex;

      const getObject = (index: number) => {
        if (state.objects.length > index) {
          return state.objects[index];
        } else {
          return null;
        }
      };

      const updateObject = (index: number, newState: Obj) => {
        if (state.objects.length > index) {
          setObject.withIndex(index)(newState);
        }
      };

      const getSelectedObject = () => {
        if (state.selectedIndex !== null) {
          return state.objects[state.selectedIndex];
        } else {
          return null;
        }
      };

      const updateSelectedObject = (newState: Obj) => {
        if (state.selectedIndex !== null) {
          setObject.withIndex(state.selectedIndex)(newState);
        }
      };

      return {
        selectObject,
        addObject,
        removeObject,
        getSelectedIndex,
        getObject,
        updateObject,
        getSelectedObject,
        updateSelectedObject,
      };
    }, [setState, state.objects, state.selectedIndex, setObject]);

    const buttonDisabled = useCallback(
      (btn: ObjectListEditorButton<Id, Obj>): boolean => {
        if (disableControls) return true;

        if (typeof btn.disabled === "undefined") {
          return false;
        } else if (typeof btn.disabled === "boolean") {
          return btn.disabled;
        } else {
          return btn.disabled(callbacks);
        }
      },
      [callbacks, disableControls],
    );

    const buttonName = useCallback(
      (btn: ObjectListEditorButton<Id, Obj>): string => {
        if (typeof btn.name === "string") {
          return btn.name;
        } else {
          return btn.name(callbacks);
        }
      },
      [callbacks],
    );

    const buttonIcon = useCallback(
      (
        btn: ObjectListEditorButton<Id, Obj>,
      ): JSX.Element | null | undefined => {
        if (typeof btn.icon === "undefined") {
          return null;
        } else if (typeof btn.icon === "object") {
          return btn.icon;
        } else {
          return btn.icon(callbacks);
        }
      },
      [callbacks],
    );

    return (
      <Box display="flex" height={height || 400}>
        <Box
          minWidth="350px"
          className={C.objectList + (anyError ? " error" : "")}
        >
          {state.objects.length === 0 && (
            <Box py={4} color="text.disabled" textAlign="center">
              {emptyText}
            </Box>
          )}
          <DndList
            state={state}
            getObjectLabel={getObjectLabel}
            showErrors={showErrors !== false}
            uiValidationErrors={uiValidationErrors}
            setState={setState}
            sortBy={sortBy}
            itemHasAdditionalErrors={itemHasAdditionalErrors}
          />
        </Box>
        <Box flex="1" display="flex" flexDirection="column">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid>
              <Box mb={2}>
                {(additionalButtons.length > 0 || allowDelete) &&
                  additionalButtons
                    .filter((btn) => !btn.alignRight)
                    .map((btn, i) => {
                      return (
                        <Button
                          key={i}
                          disabled={buttonDisabled(btn)}
                          style={{ marginRight: 16 }}
                          variant={btn.variant ?? "outlined"}
                          startIcon={buttonIcon(btn)}
                          onClick={() => btn.onClick(callbacks)}
                          color={btn.color}
                        >
                          {buttonName(btn)}
                        </Button>
                      );
                    })}
                {(allowDelete === undefined || allowDelete) && (
                  <Button
                    style={{ marginRight: 16 }}
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    disabled={disableControls || state.selectedIndex === null}
                    onClick={deleteSelectedObject}
                  >
                    Delete
                  </Button>
                )}
                {(allowDuplicate === undefined || allowDuplicate) && (
                  <Button
                    style={{ marginRight: 16 }}
                    variant="outlined"
                    startIcon={<CopyIcon />}
                    disabled={disableControls || state.selectedIndex === null}
                    onClick={duplicateSelectedObject}
                  >
                    Duplicate
                  </Button>
                )}
              </Box>
            </Grid>
            <Grid>
              <Box mb={2}>
                {additionalButtons
                  .filter((btn) => btn.alignRight)
                  .map((btn, i) => {
                    return (
                      <Button
                        key={i}
                        disabled={buttonDisabled(btn)}
                        style={{ marginRight: 16 }}
                        variant={btn.variant ?? "outlined"}
                        startIcon={buttonIcon(btn)}
                        onClick={() => btn.onClick(callbacks)}
                        color={btn.color}
                      >
                        {buttonName(btn)}
                      </Button>
                    );
                  })}
              </Box>
            </Grid>
          </Grid>

          <Box flex="1" overflow="auto">
            {state.selectedIndex !== null && (
              <>
                {makeObjectEditor({
                  value: state.objects[state.selectedIndex],
                  index: state.selectedIndex,
                  showErrors: showErrors !== false,
                  setValue: setObject.withIndex(state.selectedIndex),
                })}
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  },
);

const DndList = genericReactMemo(
  <Id extends string, Obj extends ObjectWithId<Id>>({
    state,
    getObjectLabel,
    showErrors,
    uiValidationErrors,
    setState,
    sortBy,
    itemHasAdditionalErrors,
  }: {
    state: ObjectListState<Obj>;
    getObjectLabel: (obj: Obj) => JSX.Element;
    showErrors: boolean;
    uiValidationErrors: (UiValidationError | null)[];
    setState: Setter<ObjectListState<Obj>>;
    sortBy?: (obj1: Obj, obj2: Obj) => number;
    itemHasAdditionalErrors?: (obj: Obj) => boolean;
  }) => {
    const C = useStyles();

    const setObjects = usePropertySetter(setState, "objects");

    const [listId] = useState<string>(() => generateSafeId() as string);

    const setSelectedIndex = usePropertySetter(setState, "selectedIndex");
    const selectionSettersByIndex = useLazyMemo(
      (selectedIndex: number) => () => setSelectedIndex(selectedIndex),
      [setSelectedIndex],
    );

    const [dragIndex, setDragIndex] = useState<number | null>(null);
    const dragIndexSetters = useLazyMemo(
      (dragIndex: number | null) => () => {
        setDragIndex(dragIndex);
      },
      [],
    );

    const [dropIndex, setDropIndex] = useState<number | null>(null);
    const dropIndexSetters = useLazyMemo(
      (dropIndex: number | null) => () => setDropIndex(dropIndex),
      [],
    );

    const dropHandler = useCallback(() => {
      if (dragIndex !== null && dropIndex !== null && dragIndex !== dropIndex) {
        const newIndex = dropIndex > dragIndex ? dropIndex - 1 : dropIndex;
        setObjects((objects) => {
          const newObjects = [...objects];
          newObjects.splice(newIndex, 0, newObjects.splice(dragIndex, 1)[0]);
          return newObjects;
        });
      }

      setDragIndex(null);
      setDropIndex(null);
    }, [dragIndex, dropIndex, setObjects]);

    const sortedObjects = [...state.objects];

    const indexMap: { [key: number]: number } = {};
    for (let i = 0; i < state.objects.length; ++i) {
      indexMap[i] = i;
    }

    if (!!sortBy) {
      // We have to implement our own bubble sort here so that we can keep track
      // of the original indices of the items as they move through the list during
      // sorting.
      let sorted = false;
      let iterations = 0;
      const maxIter = 1000;
      while (!sorted) {
        if (iterations > maxIter) {
          throw new Error(`
            Sorting the ObjectListEditor exceeded ${maxIter} iterations.
            This usually happens when the sort function returns a value 
            greater than 0 regardless of the ordering of the input arguments.
          `);
        }

        sorted = true;
        for (let i = 1; i < sortedObjects.length; ++i) {
          const aIndex = i - 1;
          const bIndex = i;
          const order = sortBy(sortedObjects[aIndex], sortedObjects[bIndex]);
          if (order === 1) {
            sorted = false;

            // Swap the list items
            const tmpObj = sortedObjects[aIndex];
            sortedObjects[aIndex] = sortedObjects[bIndex];
            sortedObjects[bIndex] = tmpObj;

            // Swap their list indices so we can keep track of them
            // in the original state object.
            const tmpIdx = indexMap[aIndex];
            indexMap[aIndex] = indexMap[bIndex];
            indexMap[bIndex] = tmpIdx;
          }
        }
        ++iterations;
      }
    }

    return (
      <>
        {sortedObjects.map((obj, sortedIdx) => {
          // Map the object index back to its index in the state object
          // (not the sorted version that we're displaying)
          const objIdx = indexMap[sortedIdx];

          const isDraggingAtInitialPosition =
            objIdx === dropIndex || dropIndex === null;

          return (
            <React.Fragment key={objIdx}>
              <Box
                className={C.dropIndicator}
                height={
                  dropIndex === objIdx && !isDraggingAtInitialPosition ? 40 : 0
                }
              ></Box>

              <DndListItem
                key={objIdx}
                listId={listId}
                label={getObjectLabel(obj) || "<New>"}
                itemIndex={objIdx}
                uiValidationError={uiValidationErrors[objIdx]}
                hasDomainValidationErrors={
                  (itemHasAdditionalErrors && itemHasAdditionalErrors(obj)) ||
                  (isStateWithValErrors(obj) ? stateHasErrors(obj) : false)
                }
                isSelected={objIdx === state.selectedIndex}
                isDraggingAtInitialPosition={isDraggingAtInitialPosition}
                select={selectionSettersByIndex.get(objIdx)}
                onDragBegin={dragIndexSetters.get(objIdx)}
                onDragEnd={dropHandler}
                onDropHoverAbove={dropIndexSetters.get(objIdx)}
                onDropHoverBelow={dropIndexSetters.get(objIdx + 1)}
                isDraggable={sortBy === undefined}
              />
            </React.Fragment>
          );
        })}

        <Box
          className={C.dropIndicator}
          height={dropIndex === sortedObjects.length ? 40 : 0}
        ></Box>
      </>
    );
  },
);

const DndListItem = genericReactMemo(
  ({
    listId,
    label,
    itemIndex,
    uiValidationError,
    hasDomainValidationErrors,
    isSelected,
    isDraggingAtInitialPosition,
    select,
    onDragBegin,
    onDragEnd,
    onDropHoverAbove,
    onDropHoverBelow,
    isDraggable,
  }: {
    listId: string;
    label: JSX.Element;
    itemIndex: number;
    uiValidationError: UiValidationError | null;
    hasDomainValidationErrors?: boolean;
    isSelected: boolean;
    isDraggingAtInitialPosition: boolean;
    select: () => void;
    onDragBegin: () => void;
    onDragEnd: () => void;
    onDropHoverAbove: () => void;
    onDropHoverBelow: () => void;
    isDraggable: boolean;
  }) => {
    const C = useStyles();

    const errorMessage = uiValidationError && uiValidationError.message;

    const dragDropRef = useRef<HTMLDivElement>(null);
    const [{ isDragging }, dragRef] = useDrag({
      item: {
        type: listId,
        itemIndex,
      },
      begin(monitor) {
        onDragBegin();
      },
      end(item, monitor) {
        onDragEnd();
      },
      collect(monitor) {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    });
    const [, dropRef] = useDrop({
      accept: listId,
      hover(item, monitor) {
        if (!dragDropRef.current) {
          return;
        }

        const cursorViewportOffset = monitor.getClientOffset();

        if (!cursorViewportOffset) {
          return;
        }

        const hoverItemRect = dragDropRef.current.getBoundingClientRect();
        const cursorOffsetY = cursorViewportOffset.y - hoverItemRect.top;

        if (cursorOffsetY < (hoverItemRect.bottom - hoverItemRect.top) / 2) {
          onDropHoverAbove();
        } else {
          onDropHoverBelow();
        }
      },
    });
    dragRef(dropRef(dragDropRef));

    return (
      <Box
        className={C.objectListItemContainer}
        height={!isDragging || isDraggingAtInitialPosition ? 40 : 0}
      >
        <div
          className={
            C.objectListItem +
            (isSelected ? " selected" : "") +
            (isDragging ? " dragging" : "")
          }
          ref={isDraggable ? dragDropRef : undefined}
          onClick={select}
        >
          {isDraggable && (
            <Box
              className="dragIcon"
              title="Click and drag to move this item up or down in the list"
            >
              <DragIndicatorIcon />
            </Box>
          )}
          <Box
            className="errorIcon"
            display={
              uiValidationError === null && !hasDomainValidationErrors
                ? "none"
                : "flex"
            }
            color="error.main"
            title={errorMessage || undefined}
          >
            <ErrorOutlineIcon />
          </Box>
          <Box className="label">{label}</Box>
          <Box className="endIcon">
            <ChevronRightIcon />
          </Box>
        </div>
      </Box>
    );
  },
);
