import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as Api from "api";
import * as T from "types/engine-types";
import { createSelector } from "reselect";
import _ from "lodash";
import { ProductsState } from "features/products";
import { RateSheetsState } from "features/rate-sheets";
import filterResults from "features/filter-list";

export type InvestorsState = {
  investors: T.DecoratedInvestorHeader[];
  searchTerm: string;
  sortField: string;
  sortDir: string;
  loading: boolean;
  errors: string;
};

const initialState: InvestorsState = {
  investors: [],
  loading: false,
  searchTerm: "",
  errors: "",
  sortField: "name",
  sortDir: "asc",
};

export const getInvestors = createAsyncThunk(
  "investors/getInvestors",
  async () => {
    return await Api.getInvestors();
  },
);

export const deleteInvestorById = createAsyncThunk(
  "investors/delete",
  async (investorId: T.InvestorId, thunkAPI) => {
    await Api.deleteInvestor(investorId);
    thunkAPI.dispatch(getInvestors());
  },
);

export const updateInvestor = createAsyncThunk(
  "roles/update",
  async (
    params: {
      investor: { id: T.InvestorId; changeset: T.InvestorChangeset };
    },
    thunkAPI,
  ) => {
    try {
      const { investor } = params;
      await Api.updateInvestor(investor.id, investor.changeset);
      await thunkAPI.dispatch(getInvestors());
      return "success";
    } catch (error) {
      if (error instanceof Error) {
        newrelic.noticeError(error);
        thunkAPI.dispatch(setErrors(error.message));
      } else {
        thunkAPI.dispatch(setErrors("Save failed because of an error."));
      }

      setTimeout(() => thunkAPI.dispatch(setErrors("")), 5000);

      return "error";
    }
  },
);

const investorSlice = createSlice({
  name: "Investors",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(deleteInvestorById.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getInvestors.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInvestors.fulfilled, (state, { payload }) => {
      state.investors = payload;
      state.loading = false;
    });
  },
  reducers: {
    setSort: (state, { payload }: PayloadAction<string>) => {
      let sortDir: string;

      if (payload === state.sortField) {
        if (state.sortDir === "asc") {
          sortDir = "desc";
        } else {
          sortDir = "asc";
        }
      } else {
        sortDir = "asc";
      }
      return {
        ...state,
        sortDir,
        sortField: payload,
      };
    },
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },
    setInvestors: (
      state,
      { payload }: PayloadAction<T.DecoratedInvestorHeader[]>,
    ) => {
      state.investors = payload;
    },
  },
});

export const { setLoading, setErrors, setInvestors, setSearchTerm, setSort } =
  investorSlice.actions;

export default investorSlice.reducer;

export const investorsSelector = (state: { investors: InvestorsState }) =>
  state.investors;

export const filteredInvestorsSelector = createSelector(
  [
    (state: { products: ProductsState }) => state.products.products,
    (state: { rateSheets: RateSheetsState }) => state.rateSheets.rateSheets,
    (state: { investors: InvestorsState }) => state.investors.investors,
    (state: { investors: InvestorsState }) => state.investors.searchTerm,
    (state: { investors: InvestorsState }) => state.investors.sortField,
    (state: { investors: InvestorsState }) => state.investors.sortDir,
  ],
  (products, rateSheets, investors, searchTerm, sortField, sortDir) => {
    const decoratedInvestors: T.DecoratedInvestorHeader[] = [];
    if (products) {
      investors.forEach((investor) => {
        const ownedProducts = products.filter(
          (p) => p.investorId === investor.id,
        );
        const ownedRateSheets = rateSheets.filter(
          (s) => s.investorId === investor.id,
        );
        decoratedInvestors.push({
          ...investor,
          products: ownedProducts,
          rateSheets: ownedRateSheets,
        });
      });
    }

    const filtered: T.DecoratedInvestorHeader[] = [];
    decoratedInvestors.forEach((investor: T.DecoratedInvestorHeader) => {
      const { noNotQuoteMatches, quoteMatch, restMatch } = filterResults(
        searchTerm,
        [
          investor.isPricingEnabled ? "active" : "inactive",
          investor.name,
          investor.code,
          investor.products?.length.toString() || "",
          investor.rateSheets?.length.toString() || "",
        ],
      );

      if (noNotQuoteMatches && quoteMatch && restMatch) filtered.push(investor);
    });

    let sorted = _.sortBy(filtered, [
      (o) => {
        const field = o[sortField as keyof T.DecoratedInvestorHeader];
        return field && typeof field === "string" ? field.toLowerCase() : field;
      },
      (o) => o.name.toLowerCase(),
    ]);
    if (sortDir === "desc") {
      sorted = _.reverse(sorted);
    }
    return sorted;
  },
);
