import React, {
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import _ from "lodash";
import { useVirtual } from "react-virtual";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";
import WarningIcon from "@material-ui/icons/Warning";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import * as T from "types/engine-types";
import { Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useSelector } from "react-redux";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

export default function PageAccessList({
  fields,
  setFields,
  allFields,
  editMode,
  copyMode,
  createMode,
  groupLabel,
  fieldPermIds,
}: {
  groupLabel: String;
  fieldPermIds: T.FieldId[];
  fields: T.BaseFieldDefinition[];
  setFields: Dispatch<SetStateAction<T.FieldId[]>>;
  allFields: T.BaseFieldDefinition[];
  editMode: Boolean;
  copyMode: Boolean;
  createMode: Boolean;
}) {
  const parentRef = useRef<HTMLDivElement>(null);
  const { config } = useSelector(nonNullApplicationInitializationSelector);
  const fieldsToList = editMode || copyMode || createMode ? allFields : fields;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredPerms, setFilteredPerms] =
    useState<T.BaseFieldDefinition[]>(fieldsToList);

  const [fieldsToWarnOn, setFieldsToWarnOn] = useState<T.FieldId[]>([]);

  useEffect(() => {
    if (
      config?.settings?.priceScenarioTable?.type === "rate-with-lock-period"
    ) {
      setFieldsToWarnOn([
        config.settings.priceScenarioTable.adjustedPriceFieldId,
        config.settings.priceScenarioTable.adjustedRateFieldId,
        config.settings.priceScenarioTable.adjustedRateLockPeriodFieldId,
      ]);
    }
  }, [config]);

  useEffect(() => {
    const filtered = fieldsToList.filter((p: T.BaseFieldDefinition) => {
      return p.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const sort = _.sortBy(filtered, [(o) => o.name.toLowerCase()]);

    setFilteredPerms(sort);
  }, [searchTerm, fieldsToList]);

  const rowVirtualizer = useVirtual({
    size: filteredPerms.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, []),
  });

  return (
    <Table
      style={{ flex: "1 0 24%", borderRight: "1px solid rgba(0,0,0,.15)" }}
    >
      <TableActions style={{ padding: "8px" }}>
        <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
          <SearchInput
            label="fields"
            requireDispatch={false}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <ResultsCount>
          {filteredPerms.length} <VisibilityIcon />
          <span className="separator">|</span>
          {
            fieldPermIds.filter((p) => allFields.map((f) => f.id).includes(p))
              .length
          }{" "}
          <CheckIcon />
          <span className="separator">|</span>
          {allFields.length} Total
        </ResultsCount>
      </TableActions>

      {editMode || copyMode || createMode ? (
        <>
          <TableHeader>
            <TableHeaderCell style={{ flexBasis: "50px" }}>
              <Checkbox
                checked={filteredPerms.every((p) =>
                  fieldPermIds.includes(p.id),
                )}
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                onChange={(e, checked) => {
                  const allIds = filteredPerms.map((f) => f.id);
                  if (checked) {
                    const newFields = fieldPermIds.filter(
                      (p) => !allIds.includes(p),
                    );
                    setFields([...newFields, ...allIds]);
                  } else {
                    const newFields = fieldPermIds.filter(
                      (p) => !allIds.includes(p),
                    );
                    setFields(newFields);
                  }
                }}
              />
            </TableHeaderCell>

            <TableHeaderCell style={{ flexBasis: "calc(100% - 50px)" }}>
              {groupLabel}
            </TableHeaderCell>
          </TableHeader>

          {filteredPerms.length ? (
            <TableBodyWrapper ref={parentRef}>
              <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
                {rowVirtualizer.virtualItems.map((virtualRow) => (
                  <TableRow
                    key={virtualRow.index}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <TableCell style={{ flexBasis: "54px" }}>
                      <Checkbox
                        checked={fieldPermIds.includes(
                          filteredPerms[virtualRow.index].id,
                        )}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        onChange={(e, checked) => {
                          if (checked) {
                            setFields([
                              ...fieldPermIds,
                              filteredPerms[virtualRow.index].id,
                            ]);
                          } else {
                            setFields(
                              fieldPermIds.filter(
                                (p) => filteredPerms[virtualRow.index].id !== p,
                              ),
                            );
                          }
                        }}
                      />
                    </TableCell>

                    <TableCell style={{ flexBasis: "calc(100% - 54px)" }}>
                      <>
                        {filteredPerms[virtualRow.index].name}

                        {fieldsToWarnOn.includes(
                          filteredPerms[virtualRow.index].id,
                        ) && (
                          <span
                            className="warning-field"
                            style={{
                              position: "relative",
                              margin: "6px 0 0 8px",
                              color: "#0b9444",
                            }}
                            title="Disabling this permission could result in a broken experience in loan pricing. Do so at great risk!"
                          >
                            <WarningIcon />
                          </span>
                        )}
                      </>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableBodyWrapper>
          ) : (
            <p
              style={{ marginTop: "14px", textAlign: "center", opacity: 0.65 }}
            >
              No additional permissions have been granted for this role.
            </p>
          )}
        </>
      ) : (
        <>
          <TableHeader>
            <TableHeaderCell style={{ flexBasis: "100%" }}>
              {groupLabel}
            </TableHeaderCell>
          </TableHeader>

          {filteredPerms.length ? (
            <TableBodyWrapper ref={parentRef}>
              <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
                {rowVirtualizer.virtualItems.map((virtualRow) => (
                  <TableRow
                    key={virtualRow.index}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <TableCell style={{ flexBasis: "100%" }}>
                      {filteredPerms[virtualRow.index].name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableBodyWrapper>
          ) : (
            <p
              style={{ marginTop: "14px", textAlign: "center", opacity: 0.65 }}
            >
              No additional permissions have been granted for this role.
            </p>
          )}
        </>
      )}
    </Table>
  );
}
