import { Map as IMap } from "immutable";
import * as T from "types/engine-types";

export function importInvestor(
  oldInvestor: T.InvestorHeader | null,
  investor: T.NewInvestor,
): T.InvestorHeader {
  return {
    id: oldInvestor?.id || ("" as T.InvestorId),
    name: investor.name,
    code: investor.code,
    isPricingEnabled: investor.isPricingEnabled,
  };
}

export function importInvestorId(
  investorsByKey: IMap<string, T.InvestorHeader>,
  investorId: T.InvestorId,
): T.InvestorId {
  const investor = investorsByKey.get(investorId);

  if (!investor) {
    throw new Error(
      "Could not resolve reference to investor " + JSON.stringify(investorId),
    );
  }

  return investor.id;
}
