import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Set as ISet } from "immutable";
import { Box, TextField, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import * as T from "types/engine-types";
import { getMessageNode } from "features/server-validation";
import { enumVariantNameToIdentifier } from "features/formulas-util";
import {
  Setter,
  UiValidationError,
  getValidationError,
  usePropertySetter,
  createSlugId,
  resolveEnum,
  usePropertySetter2,
} from "features/utils";
import {
  LoggedInInfo,
  nonNullApplicationInitializationSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";

import {
  ObjectListEditor,
  ObjectListState,
  newObjectListState,
  ObjectListEditorButton,
} from "design/organisms/object-list-editor";
import { RefSourcesViewer } from "design/atoms/ref-sources-viewer";
import { SearchableDropdown } from "design/molecules/dropdown";
import {
  EnumTypeState,
  InheritedEnumState,
  NativeEnumState,
  IncludedEnumVariantState,
  ExcludedEnumVariantState,
  EnumVariantState,
  useObjectEditorStyles,
  getListLabel,
  convertEnumStateListItem,
} from "pages/fields";

function validateEnumVariant(
  previousVariants: T.EnumVariant[],
  variant: T.EnumVariant,
): UiValidationError | null {
  if (variant.name.trim() === "") {
    return new UiValidationError("Enumeration variant must have a name");
  }

  const lowerCaseIdentifier = enumVariantNameToIdentifier(
    variant.name,
  ).toLowerCase();
  const existingVariant = previousVariants.find(
    (v) =>
      enumVariantNameToIdentifier(v.name).toLowerCase() === lowerCaseIdentifier,
  );
  if (existingVariant) {
    if (existingVariant.name.toLowerCase() === variant.name.toLowerCase()) {
      return new UiValidationError(
        `Enumeration already has a variant with the name "${existingVariant.name}"`,
      );
    }

    return new UiValidationError(
      `Enumeration variant names "${variant.name}" and "${existingVariant.name}" are too similar`,
    );
  }

  return null;
}

function convertStateToEnumType(state: EnumTypeState): T.RawEnumType {
  if (state.disposition.kind === "native") {
    return {
      oldId: state.oldId,
      id: state.id,
      disposition: {
        kind: "native",
        name: state.disposition.name,
        variants: state.disposition.variants.objects.map(
          convertStateToEnumVariant,
        ),
      },
    };
  } else {
    return {
      oldId: state.oldId,
      id: state.id,
      disposition: {
        kind: "inherited",
        nameAlias: state.disposition.nameAlias,
        includeVariants: state.disposition.includeVariants.objects.map(
          convertStateToIncludedEnumVariant,
        ),
        excludeVariants: state.disposition.excludeVariants.objects.map(
          convertStateToExcludedEnumVariant,
        ),
      },
    };
  }
}

function convertStateToEnumVariant(state: EnumVariantState): T.EnumVariant {
  return {
    oldId: state.oldId,
    id: state.id,
    name: state.name,
  };
}

function convertStateToIncludedEnumVariant(
  state: IncludedEnumVariantState,
): T.IncludedEnumVariant {
  return {
    id: state.id,
    nameAlias: state.nameAlias,
  };
}

function convertStateToExcludedEnumVariant(
  state: ExcludedEnumVariantState,
): T.ExcludedEnumVariant {
  return {
    id: state.id,
  };
}

function getEnumObjectListLabel(
  state: EnumTypeState,
  loggedInInfo: LoggedInInfo,
): JSX.Element {
  return getListLabel(
    resolveEnum(
      convertStateToEnumType(state),
      loggedInInfo.config.systemEnumTypesById,
      loggedInInfo.client.displayNewInheritedEnumVariants,
    ).name,
    false,
    state.disposition.kind === "native" &&
      loggedInInfo.client.accessId !== "super",
  );
}

function newEnumVariantState(): EnumVariantState {
  return {
    oldId: null,
    id: "" as T.EnumVariantId,
    name: "",
    domainValidationErrors: {
      hasErrors: false,
      id: [],
      name: [],
    },
  };
}

function getEnumVariantName(variant: EnumVariantState): JSX.Element {
  return <>{variant.name}</>;
}

function getIncludedEnumVariantName(
  includedVariant: IncludedEnumVariantState,
  inheritedEnum: T.EnumType | undefined,
): JSX.Element {
  const inheritedVariant = inheritedEnum?.variants.find(
    (v) => v.id === includedVariant.id,
  );
  return (
    <>
      {includedVariant.nameAlias !== null
        ? includedVariant.nameAlias
        : inheritedVariant?.name}
    </>
  );
}

function getExcludedEnumVariantName(
  excludedVariant: ExcludedEnumVariantState,
  inheritedEnum: T.EnumType | undefined,
): string | undefined {
  const inheritedVariant = inheritedEnum?.variants.find(
    (v) => v.id === excludedVariant.id,
  );
  return inheritedVariant?.name;
}

const InheritedEnumerationEditor = React.memo(
  ({
    state,
    showErrors,
    setState,
    disableControls,
  }: {
    state: InheritedEnumState;
    showErrors: boolean;
    setState: Setter<InheritedEnumState>;
    disableControls: boolean;
  }) => {
    const C = useObjectEditorStyles();
    const nonNullState = useSelector(nonNullApplicationInitializationSelector);

    const loggedInInfo = {
      user: nonNullState.user,
      client: nonNullState.client,
      config: nonNullState.config,
      objectDetails: nonNullState.objectDetails,
      notifications: nonNullState.notifications,
    };

    const setNameAlias = usePropertySetter2(
      setState,
      "disposition",
      "nameAlias",
    );
    const setIncludeVariants = usePropertySetter2(
      setState,
      "disposition",
      "includeVariants",
    );
    const setExcludeVariants = usePropertySetter2(
      setState,
      "disposition",
      "excludeVariants",
    );

    const inheritedEnum = loggedInInfo.config.systemEnumTypes.find(
      (e) => e.id === state.id,
    );

    // These are the variants that are in neither the include nor exclude list
    const availableVariants =
      inheritedEnum?.variants.filter((inheritedVariant) => {
        if (
          !!state.disposition.includeVariants.objects.find(
            (includedVariant) => includedVariant.id === inheritedVariant.id,
          )
        )
          return false;

        if (
          !!state.disposition.excludeVariants.objects.find(
            (excludedVariant) => excludedVariant.id === inheritedVariant.id,
          )
        )
          return false;

        return true;
      }) || [];

    const makeIncludedEnumVariantEditor = useCallback(
      ({
        inheritedEnum,
        value,
        showErrors,
        index,
        setValue,
      }: {
        inheritedEnum?: T.EnumType;
        value: IncludedEnumVariantState;
        showErrors: boolean;
        index: number;
        setValue: Setter<IncludedEnumVariantState>;
      }) => {
        const previousVariants =
          state.disposition.includeVariants.objects.slice(0, index);
        return (
          <IncludedEnumVariantEditor
            state={value}
            inheritedEnum={inheritedEnum}
            showErrors={showErrors}
            setState={setValue}
            previousVariants={previousVariants}
          />
        );
      },
      [state.disposition.includeVariants.objects],
    );

    const makeExcludedEnumVariantEditor = useCallback(({ value }) => {
      return (
        <ExcludedEnumVariantEditor state={value as ExcludedEnumVariantState} />
      );
    }, []);

    const inheritableOptions = [...loggedInInfo.config.systemEnumTypes];

    inheritableOptions.sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();

      if (aName > bName) return 1;
      else if (aName < bName) return -1;
      else return 0;
    });

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <Box margin="12px 0" maxWidth="400px">
          <SearchableDropdown<T.EnumType>
            label="Inherit from"
            value={inheritedEnum || null}
            options={inheritableOptions}
            getOptionLabel={(systemEnum) => systemEnum.name}
            setValue={(systemEnum) => {
              if (systemEnum) {
                setState({
                  oldId: null,
                  id: `${systemEnum.id}` as T.EnumTypeId,
                  disposition: {
                    kind: "inherited",
                    nameAlias: null,
                    includeVariants: newObjectListState([]),
                    excludeVariants: newObjectListState([]),
                    domainValidationErrors: {
                      hasErrors: false,
                      nameAlias: [],
                    },
                  },
                  domainValidationErrors: {
                    hasErrors: false,
                    id: [],
                  },
                });
              }
            }}
          />
        </Box>
        <Box>
          <TextField
            className={C.standardField}
            label="Enumeration ID"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            disabled={true}
            value={state.id}
            error={!!state.domainValidationErrors.id.length}
            helperText={getMessageNode(state.domainValidationErrors.id)}
          />
        </Box>
        <Box>
          <TextField
            className={C.standardField}
            label="Enumeration Name Alias"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder={inheritedEnum?.name}
            value={state.disposition.nameAlias || ""}
            error={!!state.disposition.domainValidationErrors.nameAlias.length}
            onChange={(e) =>
              setNameAlias(
                e.target.value?.trim() === "" ? null : e.target.value,
              )
            }
            helperText={getMessageNode(
              state.disposition.domainValidationErrors.nameAlias,
            )}
          />
        </Box>
        <Box mb={2} fontSize="24px">
          Enumeration Values
        </Box>
        <Box flex="1" overflow="hidden">
          <h4>Included Variants</h4>
          <ObjectListEditor
            disableControls={disableControls}
            height="250px"
            state={state.disposition.includeVariants}
            additionalButtons={[]}
            getObjectLabel={(v) => getIncludedEnumVariantName(v, inheritedEnum)}
            showErrors={showErrors}
            makeObjectEditor={(args) =>
              makeIncludedEnumVariantEditor({ ...args, inheritedEnum })
            }
            setState={setIncludeVariants}
            emptyText="Select a variant from &ldquo;Available Variants&rdquo; below to add it to this list"
          />

          <h4>Excluded Variants</h4>
          <ObjectListEditor
            disableControls={disableControls}
            height="250px"
            state={state.disposition.excludeVariants}
            additionalButtons={[]}
            getObjectLabel={(v) => (
              <>{getExcludedEnumVariantName(v, inheritedEnum)}</>
            )}
            showErrors={showErrors}
            makeObjectEditor={makeExcludedEnumVariantEditor}
            setState={setExcludeVariants}
            emptyText="Select a variant from &ldquo;Available Variants&rdquo; below to add it to this list"
            sortBy={(a, b) => {
              const aName = getExcludedEnumVariantName(a, inheritedEnum) || "";
              const bName = getExcludedEnumVariantName(b, inheritedEnum) || "";
              return aName.localeCompare(bName);
            }}
          />

          <h4>Available Variants</h4>
          <Box className={C.availableItemsList}>
            {availableVariants.map((variant, i) => {
              return (
                <div key={i} className={C.availableItemsListItem}>
                  {variant.name}
                  <Box className="buttonIcons">
                    <IconButton
                      title="Explicitly include this variant"
                      onClick={() =>
                        setIncludeVariants({
                          ...state.disposition.includeVariants,
                          objects: [
                            ...state.disposition.includeVariants.objects,
                            {
                              id: variant.id,
                              nameAlias: null,
                              domainValidationErrors: {
                                hasErrors: false,
                                id: [],
                                nameAlias: [],
                              },
                            },
                          ],
                        })
                      }
                    >
                      <CheckCircleIcon />
                    </IconButton>
                    <IconButton
                      title="Explicitly exclude this variant"
                      onClick={() =>
                        setExcludeVariants({
                          ...state.disposition.excludeVariants,
                          objects: [
                            ...state.disposition.excludeVariants.objects,
                            {
                              id: variant.id,
                              domainValidationErrors: {
                                hasErrors: false,
                                id: [],
                              },
                            },
                          ],
                        })
                      }
                    >
                      <CancelIcon />
                    </IconButton>
                  </Box>
                </div>
              );
            })}
          </Box>
          <RefSourcesViewer
            objectId={{
              type: "enum-type",
              enumTypeId: state.id,
            }}
            mt={2}
            style={{ marginBottom: "16px" }}
          />
        </Box>
      </Box>
    );
  },
);

const NativeEnumerationEditor = React.memo(
  ({
    state,
    showErrors,
    setState,
    disableControls,
  }: {
    disableControls: boolean;
    state: NativeEnumState;
    showErrors: boolean;
    setState: Setter<NativeEnumState>;
  }) => {
    const C = useObjectEditorStyles();

    const setId = usePropertySetter(setState, "id");
    const setName = usePropertySetter2(setState, "disposition", "name");
    const setVariants = usePropertySetter2(setState, "disposition", "variants");

    const handleIdChange = useCallback(
      (event: { target: { value: string } }) => {
        const newId = event.target.value as T.EnumTypeId;
        setId(newId);
      },
      [setId],
    );

    const handleNameChange = useCallback(
      (event: { target: { value: string } }) => {
        setName(event.target.value);
        if (!state.oldId) {
          const newId = createSlugId(event.target.value) as T.EnumTypeId;
          setId(newId);
        }
      },
      [setId, setName, state.oldId],
    );

    const makeEnumVariantEditor = useCallback(
      ({
        value,
        showErrors,
        index,
        setValue,
      }: {
        value: EnumVariantState;
        showErrors: boolean;
        index: number;
        setValue: Setter<EnumVariantState>;
      }) => {
        const previousVariants = state.disposition.variants.objects.slice(
          0,

          index,
        );
        return (
          <EnumVariantEditor
            state={value}
            showErrors={showErrors}
            setState={setValue}
            previousVariants={previousVariants}
          />
        );
      },
      [state.disposition.variants.objects],
    );

    const validate = useCallback(
      (variant: EnumVariantState, variantIndex: number) => {
        const previousVariants = state.disposition.variants.objects.slice(
          0,
          variantIndex,
        );
        return validateEnumVariant(previousVariants, variant);
      },
      [state.disposition.variants.objects],
    );

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <Box>
          <TextField
            className={C.standardField}
            label="Enumeration Name"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={state.disposition.name}
            error={
              (showErrors && state.disposition.name.trim() === "") ||
              !!state.disposition.domainValidationErrors.name.length
            }
            onChange={handleNameChange}
            helperText={getMessageNode(
              state.disposition.domainValidationErrors.name,
            )}
          />
        </Box>
        <Box>
          <TextField
            className={C.standardField}
            label="Enumeration ID"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            disabled={!!state.oldId}
            value={state.id}
            error={
              (showErrors && state.id.trim() === "") ||
              !!state.domainValidationErrors.id.length
            }
            onChange={handleIdChange}
            helperText={getMessageNode(state.domainValidationErrors.id)}
          />
        </Box>
        <Box mb={2} fontSize="24px">
          Enumeration Values
        </Box>
        <Box flex="1" overflow="hidden">
          <>
            <ObjectListEditor
              disableControls={disableControls}
              key={state.id}
              height="100%"
              state={state.disposition.variants}
              additionalButtons={[
                {
                  name: "New",
                  icon: <AddIcon />,
                  onClick: (callbacks) =>
                    callbacks.addObject(newEnumVariantState()),
                },
              ]}
              getObjectLabel={getEnumVariantName}
              showErrors={showErrors}
              validateObject={validate}
              makeObjectEditor={makeEnumVariantEditor}
              setState={setVariants}
              emptyText="Click &ldquo;New&rdquo; to insert a new variant into this list"
            />
            <RefSourcesViewer
              objectId={{
                type: "enum-type",
                enumTypeId: state.id,
              }}
              mt={2}
              style={{ marginBottom: "16px" }}
            />
          </>
        </Box>
      </Box>
    );
  },
);

const EnumVariantEditor = React.memo(
  ({
    state,
    showErrors,
    setState,
    previousVariants,
  }: {
    state: EnumVariantState;
    showErrors: boolean;
    setState: Setter<EnumVariantState>;
    previousVariants: EnumVariantState[];
  }) => {
    const C = useObjectEditorStyles();

    const setName = usePropertySetter(setState, "name");
    const setId = usePropertySetter(setState, "id");

    const handleIdChange = useCallback(
      (event: { target: { value: string } }) => {
        const newId = event.target.value as T.EnumVariantId;
        setId(newId);
      },
      [setId],
    );

    const handleNameChange = useCallback(
      (event: { target: { value: string } }) => {
        setName(event.target.value);
        if (!state.oldId) {
          const newId = createSlugId(event.target.value) as T.EnumVariantId;
          setId(newId);
        }
      },
      [setId, setName, state.oldId],
    );

    const duplicateName = !!previousVariants.find(
      (v) => v.name.toLowerCase().trim() === state.name.toLowerCase().trim(),
    );

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <TextField
          className={C.standardField}
          label="Variant Name"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={state.name}
          error={
            (showErrors && (!state.name.trim() || duplicateName)) ||
            !!state.domainValidationErrors.name.length
          }
          onChange={handleNameChange}
          helperText={getMessageNode(state.domainValidationErrors.name)}
        />
        <TextField
          className={C.standardField}
          label="Variant ID"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          disabled={!!state.oldId}
          value={state.id}
          error={
            (showErrors && !state.id.trim()) ||
            !!state.domainValidationErrors.id.length
          }
          onChange={handleIdChange}
          helperText={getMessageNode(state.domainValidationErrors.id)}
        />
      </Box>
    );
  },
);

const ExcludedEnumVariantEditor = React.memo(
  ({ state }: { state: ExcludedEnumVariantState }) => {
    const C = useObjectEditorStyles();

    return (
      <Box>
        <TextField
          className={C.standardField}
          label="Variant ID"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          disabled={true}
          value={state.id}
          helperText={getMessageNode(state.domainValidationErrors.id)}
        />
      </Box>
    );
  },
);

const IncludedEnumVariantEditor = React.memo(
  ({
    state,
    inheritedEnum,
    showErrors,
    setState,
    previousVariants,
  }: {
    state: IncludedEnumVariantState;
    inheritedEnum?: T.EnumType;
    showErrors: boolean;
    setState: Setter<IncludedEnumVariantState>;
    previousVariants: IncludedEnumVariantState[];
  }) => {
    const C = useObjectEditorStyles();

    const setNameAlias = usePropertySetter(setState, "nameAlias");

    const duplicateName = !!previousVariants.find(
      (v) =>
        getIncludedEnumVariantName(v, inheritedEnum) ===
        getIncludedEnumVariantName(state, inheritedEnum),
    );

    const inheritedVariant = inheritedEnum?.variants.find(
      (v) => v.id === state.id,
    );

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <TextField
          className={C.standardField}
          label="Variant Name Alias"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          placeholder={inheritedVariant?.name}
          value={state.nameAlias || ""}
          error={
            duplicateName || !!state.domainValidationErrors.nameAlias.length
          }
          onChange={(e) =>
            setNameAlias(e.target.value?.trim() === "" ? null : e.target.value)
          }
          helperText={getMessageNode(state.domainValidationErrors.nameAlias)}
        />
        <TextField
          className={C.standardField}
          label="Variant ID"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          disabled={true}
          value={state.id}
          error={
            (showErrors && !state.id.trim()) ||
            !!state.domainValidationErrors.id.length
          }
          helperText={getMessageNode(state.domainValidationErrors.id)}
        />
      </Box>
    );
  },
);

export const EnumerationsEditor = React.memo(
  ({
    enumsState,
    setState,
    existingEnumNames,
    disableControls = false,
  }: {
    disableControls: boolean;
    enumsState: ObjectListState<EnumTypeState>;
    setState: Setter<ObjectListState<EnumTypeState>>;
    existingEnumNames: ISet<string>;
  }) => {
    const C = useObjectEditorStyles();
    const nonNullState = useSelector(nonNullApplicationInitializationSelector);
    const objectDetailsMap = useSelector(objectDetailsMapSelector);
    const loggedInInfo = useMemo(
      () => ({
        user: nonNullState.user,
        client: nonNullState.client,
        config: nonNullState.config,
        objectDetails: objectDetailsMap,
        notifications: nonNullState.notifications,
      }),
      [nonNullState, objectDetailsMap],
    );

    const makeEnumEditor = useCallback(
      ({ value, showErrors, setValue, disableControls }) => (
        <Box className={C.objectEditor}>
          {/* This comment was generated when upgrading react-scripts and eslint */}
          {/* TODO: fix the lint rule and remove this eslint-disable comment */}
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
          {value.disposition.kind === "native" && (
            <NativeEnumerationEditor
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              disableControls={disableControls}
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              state={value}
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              showErrors={showErrors}
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              setState={setValue}
            />
          )}
          {/* This comment was generated when upgrading react-scripts and eslint */}
          {/* TODO: fix the lint rule and remove this eslint-disable comment */}
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
          {value.disposition.kind === "inherited" && (
            <InheritedEnumerationEditor
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              disableControls={disableControls}
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              state={value}
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              showErrors={showErrors}
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              setState={setValue}
            />
          )}
        </Box>
      ),
      [C.objectEditor],
    );

    const validateEnumTypeState = useCallback(
      (state: EnumTypeState, enumIndex: number) =>
        getValidationError(() => {
          convertEnumStateListItem(
            existingEnumNames,
            enumsState,
            state,
            enumIndex,
            loggedInInfo,
          );
        }),
      [existingEnumNames, enumsState, loggedInInfo],
    );

    const newButtons: ObjectListEditorButton<T.EnumTypeId, EnumTypeState>[] = [
      {
        name: "New",
        icon: <AddIcon />,
        onClick: (callbacks) =>
          callbacks.addObject({
            oldId: null,
            id: "" as T.EnumTypeId,
            disposition: {
              kind: "native",
              name: "",
              variants: newObjectListState([]),
              domainValidationErrors: {
                hasErrors: false,
                name: [],
              },
            },
            domainValidationErrors: {
              hasErrors: false,
              id: [],
            },
          } as EnumTypeState),
      },
    ];

    // Super client doesn't have anywhere to inherit enums from, so
    // we don't show the "From System" button.
    if (loggedInInfo.client.accessId !== "super") {
      newButtons.unshift({
        name: "From System",
        icon: <AddIcon />,
        onClick: (callbacks) =>
          callbacks.addObject({
            oldId: null,
            id: "" as T.EnumTypeId,
            disposition: {
              kind: "inherited",
              nameAlias: null,
              includeVariants: {
                objects: [],
                selectedIndex: null,
              },
              excludeVariants: {
                objects: [],
                selectedIndex: null,
              },
              domainValidationErrors: {
                hasErrors: false,
                nameAlias: [],
              },
            },
            domainValidationErrors: {
              hasErrors: false,
              id: [],
            },
          } as EnumTypeState),
      });
    }

    return (
      <Box m={3} flex="1">
        <ObjectListEditor
          disableControls={disableControls}
          height="100%"
          state={enumsState}
          additionalButtons={newButtons}
          getObjectLabel={(s) => getEnumObjectListLabel(s, loggedInInfo)}
          validateObject={validateEnumTypeState}
          makeObjectEditor={makeEnumEditor}
          setState={setState}
          sortBy={(a, b) => {
            const aResolved = resolveEnum(
              convertStateToEnumType(a),
              loggedInInfo.config.systemEnumTypesById,
              loggedInInfo.client.displayNewInheritedEnumVariants,
            );
            const bResolved = resolveEnum(
              convertStateToEnumType(b),
              loggedInInfo.config.systemEnumTypesById,
              loggedInInfo.client.displayNewInheritedEnumVariants,
            );
            return aResolved.name.localeCompare(bResolved.name);
          }}
          itemHasAdditionalErrors={(obj) =>
            obj.domainValidationErrors.hasErrors
          }
          emptyText="Click &ldquo;New&rdquo; or &ldquo;Inherit&rdquo; to insert a new enumeration into this list"
        />
      </Box>
    );
  },
);
