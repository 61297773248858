export type UsState = {
  name: string;
  twoLetterCode: string;
  twoDigitCode: string;
};

export const ALL_US_STATES: readonly UsState[] = makeStates([
  ["Alabama", "AL", "01"],
  ["Alaska", "AK", "02"],
  ["American Samoa", "AS", "60"],
  ["Arizona", "AZ", "04"],
  ["Arkansas", "AR", "05"],
  ["California", "CA", "06"],
  ["Colorado", "CO", "08"],
  ["Connecticut", "CT", "09"],
  ["Delaware", "DE", "10"],
  ["District of Columbia", "DC", "11"],
  ["Florida", "FL", "12"],
  ["Georgia", "GA", "13"],
  ["Guam", "GU", "66"],
  ["Hawaii", "HI", "15"],
  ["Idaho", "ID", "16"],
  ["Illinois", "IL", "17"],
  ["Indiana", "IN", "18"],
  ["Iowa", "IA", "19"],
  ["Kansas", "KS", "20"],
  ["Kentucky", "KY", "21"],
  ["Louisiana", "LA", "22"],
  ["Maine", "ME", "23"],
  ["Maryland", "MD", "24"],
  ["Massachusetts", "MA", "25"],
  ["Michigan", "MI", "26"],
  ["Minnesota", "MN", "27"],
  ["Mississippi", "MS", "28"],
  ["Missouri", "MO", "29"],
  ["Montana", "MT", "30"],
  ["Nebraska", "NE", "31"],
  ["Nevada", "NV", "32"],
  ["New Hampshire", "NH", "33"],
  ["New Jersey", "NJ", "34"],
  ["New Mexico", "NM", "35"],
  ["New York", "NY", "36"],
  ["North Carolina", "NC", "37"],
  ["North Dakota", "ND", "38"],
  ["(Northern) Mariana Islands", "MP", "69"],
  ["Ohio", "OH", "39"],
  ["Oklahoma", "OK", "40"],
  ["Oregon", "OR", "41"],
  ["Pennsylvania", "PA", "42"],
  ["Puerto Rico", "PR", "72"],
  ["Rhode Island", "RI", "44"],
  ["South Carolina", "SC", "45"],
  ["South Dakota", "SD", "46"],
  ["Tennessee", "TN", "47"],
  ["Texas", "TX", "48"],
  ["Utah", "UT", "49"],
  ["Vermont", "VT", "50"],
  ["Virgin Islands", "VI", "78"],
  ["Virginia", "VA", "51"],
  ["Washington", "WA", "53"],
  ["West Virginia", "WV", "54"],
  ["Wisconsin", "WI", "55"],
  ["Wyoming", "WY", "56"],
]);

function makeStates(data: [string, string, string][]): UsState[] {
  return data.map(([name, twoLetterCode, twoDigitCode]) => {
    if (twoLetterCode.length !== 2 || !isStringUppercaseAlpha(twoLetterCode)) {
      throw new Error("US State code must be two letters");
    }

    if (twoDigitCode.length !== 2 || !isStringDigits(twoDigitCode)) {
      throw new Error("US State 2-digit code must be two digits");
    }

    return { name, twoLetterCode, twoDigitCode };
  });
}

function isStringDigits(s: string): boolean {
  for (let i = 0; i < s.length; i++) {
    if (!isCharDigit(s.charAt(i))) {
      return false;
    }
  }

  return true;
}

function isCharDigit(c: string): boolean {
  return (
    c.charCodeAt(0) >= "0".charCodeAt(0) && c.charCodeAt(0) <= "9".charCodeAt(0)
  );
}

function isStringUppercaseAlpha(s: string): boolean {
  for (let i = 0; i < s.length; i++) {
    if (!isCharUppercaseAlpha(s.charAt(i))) {
      return false;
    }
  }

  return true;
}

function isCharUppercaseAlpha(c: string): boolean {
  return (
    c.charCodeAt(0) >= "A".charCodeAt(0) && c.charCodeAt(0) <= "Z".charCodeAt(0)
  );
}

export function findUsStateByTwoLetterCode(code: string): UsState | null {
  return ALL_US_STATES.find((s) => s.twoLetterCode === code) || null;
}

export function findUsStateByTwoDigitCode(code: string): UsState | null {
  return ALL_US_STATES.find((s) => s.twoDigitCode === code) || null;
}
