import { Configuration } from "config";
import * as T from "types/engine-types";
import * as Parser from "features/formulas-parser";
import * as Printer from "features/formulas-printer";
import * as Ir from "features/formulas-ir";

export const InvalidFormulaError = Ir.InvalidFormulaError;
export const UnknownFieldError = Ir.UnknownFieldError;
export const UnknownEnumVariantError = Ir.UnknownEnumVariantError;

export function formulaToIr(
  config: Configuration,
  formula: string,
): T.Expression {
  const ast = Parser.parseFormula(formula);
  return Ir.astToIr(config, ast);
}

export function irToFormula(config: Configuration, ir: T.Expression): string {
  const ast = Ir.irToAst(config, ir);
  return Printer.printFormula(ast);
}
