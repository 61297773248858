import React, { ReactNode, MouseEventHandler } from "react";
import { IconLink, IconA, IconSpan } from "./styles";

export default React.memo(
  ({
    children,
    icon,
    to,
    className,
    external,
    inline,
    onClick,
  }: {
    children: ReactNode;
    icon: ReactNode;
    to?: string | undefined;
    className?: string;
    external?: boolean;
    inline?: boolean;
    onClick?: MouseEventHandler<HTMLSpanElement> | undefined;
  }) => {
    if (!to && !external) {
      return (
        <IconSpan
          onClick={onClick}
          className={`${className || ""} ${
            inline ? "inline-icon" : "block-icon"
          } atom-icon-link`}
        >
          <div className="icon">{icon}</div>

          <div className="label">{children}</div>
        </IconSpan>
      );
    } else if (external && to) {
      return (
        <IconA
          onClick={onClick}
          target="blank"
          href={to}
          className={`${className || ""} ${
            inline ? "inline-icon" : "block-icon"
          } atom-icon-link`}
        >
          <div className="icon">{icon}</div>

          <div className="label">{children}</div>
        </IconA>
      );
    } else if (to) {
      return (
        <IconLink
          onClick={onClick}
          to={to}
          className={`${className || ""} ${
            inline ? "inline-icon" : "block-icon"
          } atom-icon-link`}
        >
          <div className="icon">{icon}</div>

          <div className="label">{children}</div>
        </IconLink>
      );
    } else {
      return <></>;
    }
  },
);
