import React, { useRef, useEffect } from "react";
import { useVirtual } from "react-virtual";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import AddIcon from "@material-ui/icons/Add";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import CheckIcon from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import { usersLoadingSelector, getUsers } from "features/users";
import {
  rolesSelector,
  filteredRolesSelector,
  getRoles,
  setSearchTerm,
  setSort,
} from "features/roles";
import { usePermissions } from "features/roles";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

export default function RolesPage() {
  const parentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { searchTerm, sortField } = useSelector(rolesSelector);
  const rolesState = useSelector(rolesSelector);
  const roles = useSelector(filteredRolesSelector);
  const hasPermission = usePermissions();
  const hasCreatePerm = hasPermission("roles-create");
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);
  const usersLoading = useSelector(usersLoadingSelector);

  const rowVirtualizer = useVirtual({
    size: roles?.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, []),
  });

  useEffect(() => {
    if (!usersLoading) dispatch(getUsers());
  }, [dispatch, usersLoading]);

  useEffect(() => {
    if (roles.length === 0) dispatch(getRoles());
  }, [dispatch, roles.length]);

  return (
    <Table>
      <TableActions>
        <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
          <SearchInput
            label="roles"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <ResultsCount>
          <span className="visible">
            {roles.length} <VisibilityIcon />
          </span>
          <span className="separator">|</span>
          <span className="total">{rolesState.roles.length} Total</span>
        </ResultsCount>
        <Button
          disabled={!hasCreatePerm}
          className="add-role"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => history.push(`/c/${accessId}/roles/create`)}
        >
          Add Role
        </Button>
      </TableActions>

      <TableHeader>
        <TableHeaderCell
          style={{ flexBasis: "11%" }}
          onClick={() => dispatch(setSort("name"))}
        >
          Name{" "}
          {sortField === "name" && (
            <SwapVertIcon
              style={{ color: sortField === "name" ? "#90d18c" : "inherit" }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{
            flexBasis: "7%",
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() => dispatch(setSort("hasInvestorsMenuAccess"))}
          title="Investors Page"
        >
          Investors Page{" "}
          {sortField === "hasInvestorsMenuAccess" && (
            <SwapVertIcon
              style={{
                color:
                  sortField === "hasInvestorsMenuAccess"
                    ? "#90d18c"
                    : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{
            flexBasis: "7%",
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title="Products Page"
          onClick={() => dispatch(setSort("hasProductsMenuAccess"))}
        >
          Products Page{" "}
          {sortField === "hasProductsMenuAccess" && (
            <SwapVertIcon
              style={{
                color:
                  sortField === "hasProductsMenuAccess" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{
            flexBasis: "7%",
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title="Fields Page"
          onClick={() => dispatch(setSort("hasFieldsMenuAccess"))}
        >
          Fields Page{" "}
          {sortField === "hasFieldsMenuAccess" && (
            <SwapVertIcon
              style={{
                color:
                  sortField === "hasFieldsMenuAccess" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{
            flexBasis: "7%",
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() => dispatch(setSort("hasCalculationsMenuAccess"))}
          title="Calculations Page"
        >
          Calculations Page{" "}
          {sortField === "hasCalculationsMenuAccess" && (
            <SwapVertIcon
              style={{
                color:
                  sortField === "hasCalculationsMenuAccess"
                    ? "#90d18c"
                    : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{
            flexBasis: "7%",
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() => dispatch(setSort("hasDataTablesMenuAccess"))}
          title="Data Tables Page"
        >
          Data Tables Page{" "}
          {sortField === "hasDataTablesMenuAccess" && (
            <SwapVertIcon
              style={{
                color:
                  sortField === "hasDataTablesMenuAccess"
                    ? "#90d18c"
                    : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{
            flexBasis: "7%",
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() => dispatch(setSort("hasRateSheetsMenuAccess"))}
          title="Rate Sheets Page"
        >
          Rate Sheets Page{" "}
          {sortField === "hasRateSheetsMenuAccess" && (
            <SwapVertIcon
              style={{
                color:
                  sortField === "hasRateSheetsMenuAccess"
                    ? "#90d18c"
                    : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{
            flexBasis: "7%",
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() => dispatch(setSort("hasRulesMenuAccess"))}
          title="Rules Page"
        >
          Rules Page{" "}
          {sortField === "hasRulesMenuAccess" && (
            <SwapVertIcon
              style={{
                color:
                  sortField === "hasRulesMenuAccess" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{
            flexBasis: "7%",
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() => dispatch(setSort("hasUsersMenuAccess"))}
          title="Users Page"
        >
          Users Page{" "}
          {sortField === "hasUsersMenuAccess" && (
            <SwapVertIcon
              style={{
                color:
                  sortField === "hasUsersMenuAccess" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{
            flexBasis: "7%",
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() => dispatch(setSort("hasRolesMenuAccess"))}
          title="Roles Page"
        >
          Roles Page{" "}
          {sortField === "hasRolesMenuAccess" && (
            <SwapVertIcon
              style={{
                color:
                  sortField === "hasRolesMenuAccess" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{
            flexBasis: "7%",
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() => dispatch(setSort("hasPricingProfilesMenuAccess"))}
          title="Pricing Profiles Page"
        >
          Pricing Profiles Page{" "}
          {sortField === "hasPricingProfilesMenuAccess" && (
            <SwapVertIcon
              style={{
                color:
                  sortField === "hasPricingProfilesMenuAccess"
                    ? "#90d18c"
                    : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{
            flexBasis: "7%",
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() => dispatch(setSort("hasProfitView"))}
          title="Profit Adjustments"
        >
          Profit Adjustments{" "}
          {sortField === "hasProfitView" && (
            <SwapVertIcon
              style={{
                color: sortField === "hasProfitView" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{
            flexBasis: "7%",
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() =>
            dispatch(setSort("hasApplicationScenariosModifyClientScoped"))
          }
          title="System Scenarios"
        >
          System Scenarios{" "}
          {sortField === "hasApplicationScenariosModifyClientScoped" && (
            <SwapVertIcon
              style={{
                color:
                  sortField === "hasApplicationScenariosModifyClientScoped"
                    ? "#90d18c"
                    : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{
            flexBasis: "5%",
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() => dispatch(setSort("users"))}
          title="Users Count"
        >
          Users{" "}
          {sortField === "users" && (
            <SwapVertIcon
              style={{ color: sortField === "users" ? "#90d18c" : "inherit" }}
            />
          )}
        </TableHeaderCell>
      </TableHeader>

      {roles && (
        <TableBodyWrapper ref={parentRef}>
          <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
            {rowVirtualizer.virtualItems.map((virtualRow) => (
              <TableRow
                key={virtualRow.index}
                onClick={() =>
                  history.push(
                    `/c/${accessId}/roles/${roles[virtualRow.index].id}`,
                  )
                }
                style={{
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <TableCell style={{ flexBasis: "11%" }}>
                  {roles[virtualRow.index].name}
                </TableCell>

                <TableCell style={{ flexBasis: "7%", textAlign: "center" }}>
                  {roles[virtualRow.index].hasInvestorsMenuAccess ? (
                    <CheckIcon
                      className="pricing-enabled"
                      style={{ fill: "#90d18c" }}
                    />
                  ) : (
                    <CloseIcon
                      className="pricing-disabled"
                      style={{ fill: "red" }}
                    />
                  )}
                </TableCell>

                <TableCell style={{ flexBasis: "7%", textAlign: "center" }}>
                  {roles[virtualRow.index].hasProductsMenuAccess ? (
                    <CheckIcon
                      className="pricing-enabled"
                      style={{ fill: "#90d18c" }}
                    />
                  ) : (
                    <CloseIcon
                      className="pricing-disabled"
                      style={{ fill: "red" }}
                    />
                  )}
                </TableCell>

                <TableCell style={{ flexBasis: "7%", textAlign: "center" }}>
                  {roles[virtualRow.index].hasFieldsMenuAccess ? (
                    <CheckIcon
                      className="pricing-enabled"
                      style={{ fill: "#90d18c" }}
                    />
                  ) : (
                    <CloseIcon
                      className="pricing-disabled"
                      style={{ fill: "red" }}
                    />
                  )}
                </TableCell>

                <TableCell style={{ flexBasis: "7%", textAlign: "center" }}>
                  {roles[virtualRow.index].hasCalculationsMenuAccess ? (
                    <CheckIcon
                      className="pricing-enabled"
                      style={{ fill: "#90d18c" }}
                    />
                  ) : (
                    <CloseIcon
                      className="pricing-disabled"
                      style={{ fill: "red" }}
                    />
                  )}
                </TableCell>

                <TableCell style={{ flexBasis: "7%", textAlign: "center" }}>
                  {roles[virtualRow.index].hasDataTablesMenuAccess ? (
                    <CheckIcon
                      className="pricing-enabled"
                      style={{ fill: "#90d18c" }}
                    />
                  ) : (
                    <CloseIcon
                      className="pricing-disabled"
                      style={{ fill: "red" }}
                    />
                  )}
                </TableCell>

                <TableCell style={{ flexBasis: "7%", textAlign: "center" }}>
                  {roles[virtualRow.index].hasRateSheetsMenuAccess ? (
                    <CheckIcon
                      className="pricing-enabled"
                      style={{ fill: "#90d18c" }}
                    />
                  ) : (
                    <CloseIcon
                      className="pricing-disabled"
                      style={{ fill: "red" }}
                    />
                  )}
                </TableCell>

                <TableCell style={{ flexBasis: "7%", textAlign: "center" }}>
                  {roles[virtualRow.index].hasRulesMenuAccess ? (
                    <CheckIcon
                      className="pricing-enabled"
                      style={{ fill: "#90d18c" }}
                    />
                  ) : (
                    <CloseIcon
                      className="pricing-disabled"
                      style={{ fill: "red" }}
                    />
                  )}
                </TableCell>

                <TableCell style={{ flexBasis: "7%", textAlign: "center" }}>
                  {roles[virtualRow.index].hasUsersMenuAccess ? (
                    <CheckIcon
                      className="pricing-enabled"
                      style={{ fill: "#90d18c" }}
                    />
                  ) : (
                    <CloseIcon
                      className="pricing-disabled"
                      style={{ fill: "red" }}
                    />
                  )}
                </TableCell>

                <TableCell style={{ flexBasis: "7%", textAlign: "center" }}>
                  {roles[virtualRow.index].hasRolesMenuAccess ? (
                    <CheckIcon
                      className="pricing-enabled"
                      style={{ fill: "#90d18c" }}
                    />
                  ) : (
                    <CloseIcon
                      className="pricing-disabled"
                      style={{ fill: "red" }}
                    />
                  )}
                </TableCell>

                <TableCell style={{ flexBasis: "7%", textAlign: "center" }}>
                  {roles[virtualRow.index].hasPricingProfilesMenuAccess ? (
                    <CheckIcon
                      className="pricing-enabled"
                      style={{ fill: "#90d18c" }}
                    />
                  ) : (
                    <CloseIcon
                      className="pricing-disabled"
                      style={{ fill: "red" }}
                    />
                  )}
                </TableCell>

                <TableCell style={{ flexBasis: "7%", textAlign: "center" }}>
                  {roles[virtualRow.index].hasProfitView ? (
                    <CheckIcon
                      className="pricing-enabled"
                      style={{ fill: "#90d18c" }}
                    />
                  ) : (
                    <CloseIcon
                      className="pricing-disabled"
                      style={{ fill: "red" }}
                    />
                  )}
                </TableCell>

                <TableCell style={{ flexBasis: "7%", textAlign: "center" }}>
                  {roles[virtualRow.index]
                    .hasApplicationScenariosModifyClientScoped ? (
                    <CheckIcon
                      className="pricing-enabled"
                      style={{ fill: "#90d18c" }}
                    />
                  ) : (
                    <CloseIcon
                      className="pricing-disabled"
                      style={{ fill: "red" }}
                    />
                  )}
                </TableCell>

                <TableCell style={{ flexBasis: "5%", textAlign: "right" }}>
                  {roles[virtualRow.index].users}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableBodyWrapper>
      )}
    </Table>
  );
}
