import { Column } from "material-table";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import React, { useState } from "react";
import * as Api from "api";
import Table from "design/atoms/admin-table";
import * as T from "types/engine-types";
import { SELECTABLE_TIMEZONES } from "../create";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { InvalidRequest } from "api";
import { Dropdown } from "design/molecules/dropdown";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import { getErrorMessage } from "features/utils";

const { newrelic } = window;
const COLUMNS: Column<DisplayableClient>[] = [
  {
    title: "Database ID",
    field: "id",
    cellStyle: { paddingLeft: 24 },
    headerStyle: { paddingLeft: 24 },
  },
  {
    title: "Client Display Name",
    field: "name",
    defaultSort: "asc",
  },
  {
    title: "Client Login ID",
    field: "accessId",
  },
  {
    title: "Time Zone",
    field: "timezone",
  },
  {
    title: "Deactivation",
    field: "deactivationTimestamp",
  },
];

type DisplayableClient = {
  id: string;
  accessId: string;
  name: string;
  timezone: string;
};

function createDisplayableClient(client: T.Client): DisplayableClient {
  return {
    ...client,
    timezone:
      SELECTABLE_TIMEZONES.find((tz) => tz.key === client.timezone)?.name ||
      client.timezone,
  };
}

export const ClientsPage = React.memo(() => {
  const [clientsLoad, reloadClients] = Api.Admin.useClients();

  if (clientsLoad.status === "error") {
    return <>"Error"</>;
  }

  if (clientsLoad.status === "loading") {
    return <Loader loaded={false} />;
  }

  return (
    <LoadedClientsPage
      clients={clientsLoad.value}
      reloadClients={reloadClients}
    />
  );
});

export const LoadedClientsPage = React.memo(
  ({
    clients,
    reloadClients,
  }: {
    clients: T.Client[];
    reloadClients: () => void;
  }) => {
    const [deactivateClientDialogOpen, setDeactivateClientDialogOpen] =
      useState(false);
    const {
      client: { accessId },
    } = useSelector(nonNullApplicationInitializationSelector);
    const onRowClick = (
      event: unknown,
      client: DisplayableClient | undefined,
    ) => {
      if (client) {
        window.open(`/c/${client.accessId}`, "_blank");
      }
    };
    return (
      <Box>
        <Box px={2} my={2} display="flex">
          <Button
            component={Link}
            to={`/c/${accessId}/__admin/rate-sheet-formats`}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back to Rate Sheet Formats
          </Button>
          <Box flex="1" />
          <Button
            style={{ marginRight: 16 }}
            onClick={() => setDeactivateClientDialogOpen(true)}
            variant="outlined"
          >
            Deactivate Client
          </Button>
          <Button
            component={Link}
            to={`/c/${accessId}/__admin/create-client`}
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Create New Client
          </Button>
        </Box>
        {deactivateClientDialogOpen ? (
          <DeactivateClientDialog
            clients={clients.sort((a, b) => a.name.localeCompare(b.name))}
            onClose={() => {
              setDeactivateClientDialogOpen(false);
              reloadClients();
            }}
          />
        ) : (
          <div></div>
        )}
        <Box px={2} my={2}>
          <Table<DisplayableClient>
            columns={COLUMNS}
            data={clients.map(createDisplayableClient)}
            onRowClick={onRowClick}
          />
        </Box>
      </Box>
    );
  },
);

interface DeactivateClientDialogProps {
  onClose: () => void;
  clients: T.Client[];
}

function DeactivateClientDialog({
  onClose,
  clients,
}: DeactivateClientDialogProps) {
  const C = useStyles();
  const [clientId, setClientId] = useState("");
  const [deactivation, setDeactivation] = useState("Deactivation");
  const [loading, setLoading] = useState(false);
  const [DeactivateClientError, setDeactivateClientError] = useState<
    string | null
  >(null);

  async function submit() {
    try {
      setLoading(true);
      await Api.Admin.clientDeactivate({
        clientId: clientId as T.ClientId,
        deactivation: deactivationValue(deactivation),
      });
      setLoading(false);
      onClose();
    } catch (e) {
      newrelic.noticeError(getErrorMessage(e));
      setLoading(false);
      if (e instanceof InvalidRequest) {
        setDeactivateClientError(e.message);
      }
    }
  }

  return (
    <Loader loaded={!loading}>
      <Dialog open={true}>
        <DialogTitle>Deactivate client</DialogTitle>
        <DialogContent>
          {DeactivateClientError && (
            <Box fontWeight="bold" color="red" mb={1}>
              {DeactivateClientError}
            </Box>
          )}

          <Dropdown<string>
            label="Client Id"
            className={C.numberStyle}
            options={clients.map((client) => client.id)}
            getOptionLabel={(id) => clientName(id, clients)}
            value={clientId}
            setValue={setClientId}
          />

          <Dropdown<string>
            label="Deactivation"
            className={C.numberStyle}
            options={["Deactivation", "Reactivation"]}
            getOptionLabel={(id) => id}
            value={deactivation}
            setValue={setDeactivation}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button color="primary" onClick={submit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Loader>
  );
}

const useStyles = makeStyles((t) =>
  createStyles({
    fieldType: {
      margin: t.spacing(1, 0),
      width: 400,
    },
    enumType: {
      margin: t.spacing(2, 0),
      width: 400,
    },
    objectType: {
      margin: t.spacing(2, 0),
      width: 400,
    },
    numberStyle: {
      margin: t.spacing(1, 0),
      width: 200,
    },
    numberPrecision: {
      margin: t.spacing(1, 0),
      width: 200,
    },
  }),
);

function clientName(id: string, clients: T.Client[]): string {
  for (const client of clients) {
    if (client.id === id) {
      return client.name;
    }
  }
  return "";
}

function deactivationValue(id: string): boolean {
  return id === "Deactivation";
}
