import _ from "lodash";
import { Set as ISet } from "immutable";
import React, { useCallback, useMemo } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Link from "design/atoms/link";
import * as Api from "api";
import { SearchableDropdown } from "design/molecules/dropdown";
import { Configuration } from "config";
import * as T from "types/engine-types";
import * as FormulasTypeCheck from "features/formulas-typecheck";
import * as FormulasTypes from "types/formulas-types";
import * as FormulasUtil from "features/formulas-util";
import {
  Setter,
  UiValidationError,
  enumerate,
  getValidationError,
  useArraySetter,
  useAsyncLoader,
  useLazyMemo,
  usePropertySetter,
  useSetter,
  createSlugId,
  unPrefixId,
  generateSafeId,
} from "features/utils";
import { getMessageNode } from "features/server-validation";
import { localAccessId } from "features/access-id";

export type TableLookupState = {
  id: T.DataTableLookupId;
  tableId: T.DataTableId | null;
  table: T.DataTable | null;
  predicates: readonly TableColumnPredicateState[];
  fields: readonly TableLookupFieldState[];
  domainValidationErrors: T.DataTableLookupValidation;
};

export function newTableLookupState(): TableLookupState {
  return {
    id: generateSafeId() as T.DataTableLookupId,
    tableId: null,
    table: null,
    predicates: [newTableColumnPredicateState()],
    fields: [],
    domainValidationErrors: { fields: [] },
  };
}

export function convertTableLookupToState(
  lookup: T.DataTableLookup,
  domainValidationErrors: T.DataTableLookupValidation | null,
): TableLookupState {
  return {
    id: lookup.id,
    tableId: lookup.tableId,
    table: null,
    predicates: lookup.predicates.map((pred) =>
      convertTableColumnPredicateToState(pred),
    ),
    fields: lookup.fields.map((field) => convertTableLookupFieldToState(field)),
    domainValidationErrors: domainValidationErrors || { fields: {} },
  };
}

export function convertRuleTableLookupToState(
  lookup: T.RuleDataTableLookup,
): TableLookupState {
  return convertTableLookupToState(
    {
      id: lookup.id,
      tableId: lookup.tableId,
      predicates: lookup.predicates,
      fields: lookup.fields.map((field) =>
        convertRuleDataTableLookupFieldToDataTableLookupField(field),
      ),
    },
    { fields: {} },
  );
}

export function convertStateToTableLookup(
  state: TableLookupState,
  environment: ISet<T.FieldId>,
  config: Configuration,
): T.DataTableLookup {
  if (!state.tableId) {
    throw new UiValidationError("Select a data table");
  }

  if (state.predicates.length < 1) {
    throw new UiValidationError("Add at least one column condition");
  }

  if (!state.table) {
    throw new UiValidationError("Table data is still loading");
  }
  const table = state.table;

  const fields = state.fields.map((field) =>
    convertStateToTableLookupField(config, table, field),
  );

  const lowerCaseFieldIdentifiers = fields.map((f) =>
    FormulasUtil.fieldNameToIdentifier(f.name).toLowerCase(),
  );
  for (const [identifier, fieldIndex] of enumerate(lowerCaseFieldIdentifiers)) {
    if (lowerCaseFieldIdentifiers.slice(0, fieldIndex).includes(identifier)) {
      throw new UiValidationError(
        "Duplicate field identifier " + JSON.stringify(identifier),
      );
    }
  }

  return {
    id: state.id,
    tableId: state.tableId,
    predicates: state.predicates.map((pred) =>
      convertStateToTableColumnPredicate(config, table, pred),
    ),
    fields,
  };
}

export function convertStateToRuleTableLookup(
  state: TableLookupState,
  environment: ISet<T.FieldId>,
  config: Configuration,
): T.RuleDataTableLookup {
  const lookup = convertStateToTableLookup(state, environment, config);

  return {
    id: lookup.id,
    tableId: lookup.tableId,
    predicates: lookup.predicates,
    fields: lookup.fields.map(
      convertDataTableLookupFieldToRuleDataTableLookupField,
    ),
  };
}

function convertDataTableLookupFieldToRuleDataTableLookupField(
  field: T.DataTableLookupFieldDefinition,
): T.RuleDataTableLookupFieldDefinition {
  return {
    id: field.id as unknown as T.LocalFieldId,
    name: field.name,
    valueType: field.valueType,
    columnId: field.columnId,
  };
}

function convertRuleDataTableLookupFieldToDataTableLookupField(
  field: T.RuleDataTableLookupFieldDefinition,
): T.DataTableLookupFieldDefinition {
  return {
    oldId: field.id as unknown as T.FieldId,
    id: field.id as unknown as T.FieldId,
    name: field.name,
    valueType: field.valueType,
    columnId: field.columnId,
  };
}

export function validateTableLookupState(
  state: TableLookupState,
  environment: ISet<T.FieldId>,
  config: Configuration,
): UiValidationError | null {
  return getValidationError(() => {
    convertStateToTableLookup(state, environment, config);
  });
}

export const DataTableLookupEditor = React.memo(
  ({
    state,
    setState,
    environment,
    config,
  }: {
    state: TableLookupState;
    setState: Setter<TableLookupState>;
    environment: ISet<T.FieldId>;
    config: Configuration;
  }) => {
    const [tablesLoad] = Api.useDataTables();

    if (tablesLoad.status === "loading") {
      return (
        <Box p={3}>
          <CircularProgress />
        </Box>
      );
    }

    if (tablesLoad.status === "error") {
      return <Box p={3}>Error loading data table list</Box>;
    }

    return (
      <LoadedDataTableLookupEditor
        state={state}
        setState={setState}
        environment={environment}
        config={config}
        tables={tablesLoad.value}
      />
    );
  },
);

const useDataTableLookupEditorStyles = makeStyles((t) =>
  createStyles({
    tableDropdown: {
      width: 300,
    },
  }),
);

const LoadedDataTableLookupEditor = React.memo(
  ({
    state,
    setState,
    environment,
    config,
    tables,
  }: {
    state: TableLookupState;
    setState: Setter<TableLookupState>;
    environment: ISet<T.FieldId>;
    config: Configuration;
    tables: readonly T.DataTableHeader[];
  }) => {
    const C = useDataTableLookupEditorStyles();

    const sortedTableIds = useMemo(
      () => _.sortBy(tables, (t) => t.name.toLowerCase()).map((t) => t.id),
      [tables],
    );
    const getTableName = useCallback(
      (id: T.DataTableId) =>
        tables.find((t) => t.id === id)?.name || "<Unknown Data Table>",
      [tables],
    );

    const setPredicates = usePropertySetter(setState, "predicates");
    const setPredicateByIndex = useArraySetter(setPredicates);
    const setFields = usePropertySetter(setState, "fields");
    const setFieldByIndex = useArraySetter(setFields);

    const setTableId: Setter<T.DataTableId | null> = useSetter(
      setState,
      (oldState, transform) => {
        return {
          id: oldState.id,
          tableId: transform(oldState.tableId),
          table: null,
          predicates: [newTableColumnPredicateState()],
          fields: [],
          domainValidationErrors: { fields: {} },
        };
      },
      [],
    );

    const [, isTableLoading] = useAsyncLoader(async () => {
      if (!state.tableId) {
        return null;
      }

      const table = await Api.getDataTable(state.tableId);
      setState((oldState) => {
        if (oldState.tableId !== state.tableId) {
          return oldState;
        }

        return { ...oldState, table };
      });
      return;
    }, [setState, state.tableId]);

    const table = state.table;

    const addPredicate = useCallback(() => {
      setPredicates((oldPredicates) =>
        oldPredicates.concat([newTableColumnPredicateState()]),
      );
    }, [setPredicates]);

    const removePredicateByIndex = useLazyMemo(
      (index: number) => () => {
        setPredicates((oldPredicates) => {
          const newPredicates = [...oldPredicates];
          newPredicates.splice(index, 1);
          return newPredicates;
        });
      },
      [setPredicates],
    );

    const addField = useCallback(() => {
      setFields((oldFields) => oldFields.concat([newTableLookupFieldState()]));
    }, [setFields]);

    const removeFieldByIndex = useLazyMemo(
      (index: number) => () => {
        setFields((oldFields) => {
          const newFields = [...oldFields];
          newFields.splice(index, 1);
          return newFields;
        });
      },
      [setFields],
    );

    const accessId = localAccessId();

    return (
      <Box>
        <Box fontSize="16px" lineHeight="54px">
          In the&nbsp;
          {state.tableId && (
            <Link
              target="_blank"
              to={`/c/${accessId}/data-tables/${state.tableId}`}
            >
              data table&nbsp;
            </Link>
          )}
          <Box display="inline-block">
            <SearchableDropdown
              className={C.tableDropdown}
              margin="dense"
              options={sortedTableIds}
              getOptionLabel={getTableName}
              value={state.tableId}
              setValue={setTableId}
            />
          </Box>
          , find the first row where:
        </Box>

        {isTableLoading && (
          <Box p={3}>
            <CircularProgress />
          </Box>
        )}

        {table && (
          <>
            <Box>
              {state.predicates.map((predicate, predicateIndex) => {
                return (
                  <DataTableColumnPredicateEditor
                    key={predicateIndex}
                    state={predicate}
                    setState={setPredicateByIndex.withIndex(predicateIndex)}
                    allowRemove={state.predicates.length > 1}
                    remove={removePredicateByIndex.get(predicateIndex)}
                    table={table}
                    environment={environment}
                    config={config}
                  />
                );
              })}
            </Box>
            <Box>
              <Button size="small" onClick={addPredicate}>
                New Column Condition
              </Button>
            </Box>

            <Box mt={2} mb={1} fontSize="16px">
              Save the values from that row into these new fields:
            </Box>
            {state.fields.length === 0 && (
              <Box px={3} py={2} color="text.secondary">
                No output fields.
              </Box>
            )}
            {state.fields.length > 0 && (
              <Box>
                {state.fields.map((field, fieldIndex) => {
                  if (fieldIndex in state.domainValidationErrors.fields) {
                    field.domainValidationErrors =
                      state.domainValidationErrors.fields[fieldIndex];
                  }

                  return (
                    <DataTableLookupFieldEditor
                      key={fieldIndex}
                      state={field}
                      setState={setFieldByIndex.withIndex(fieldIndex)}
                      remove={removeFieldByIndex.get(fieldIndex)}
                      table={table}
                    />
                  );
                })}
              </Box>
            )}
            <Box>
              <Button size="small" onClick={addField}>
                New Output Field
              </Button>
            </Box>
          </>
        )}
      </Box>
    );
  },
);

type TableColumnPredicateState = {
  kind: "equals" | "in-range" | null;
  fieldId: T.FieldId | null;
  columnId: T.DataTableColumnId | null;
  bottomColumnId: T.DataTableColumnId | null;
  topColumnId: T.DataTableColumnId | null;
};

const ALL_TABLE_COLUMN_PREDICATE_KINDS: readonly T.DataTableColumnPredicate["kind"][] =
  ["equals", "in-range"];

function getTableColumnPredicateKindName(
  kind: T.DataTableColumnPredicate["kind"],
): string {
  switch (kind) {
    case "equals":
      return "equals";
    case "in-range":
      return "is between";
  }
}

function newTableColumnPredicateState(): TableColumnPredicateState {
  return {
    kind: null,
    fieldId: null,
    columnId: null,
    bottomColumnId: null,
    topColumnId: null,
  };
}

function convertTableColumnPredicateToState(
  pred: T.DataTableColumnPredicate,
): TableColumnPredicateState {
  function expressionAsFieldIdRef(expr: T.Expression): T.FieldId {
    if (expr.kind === "field-value") {
      return expr.fieldId;
    }

    throw new Error(
      "Unexpected expression inside data table column predicate: " + expr.kind,
    );
  }

  function expectInclusiveRangeBound<T>(bound: T.RangeBound<T>): T {
    if (bound.type === "included") {
      return bound.value;
    }

    throw new Error(
      "Unexpected range bound type inside data table column predicate: " +
        bound.type,
    );
  }

  switch (pred.kind) {
    case "equals":
      return {
        kind: "equals",
        fieldId: expressionAsFieldIdRef(pred.expression),
        columnId: pred.other,
        bottomColumnId: null,
        topColumnId: null,
      };
    case "in-range":
      return {
        kind: "in-range",
        fieldId: expressionAsFieldIdRef(pred.expression),
        columnId: null,
        bottomColumnId: expectInclusiveRangeBound(pred.bottom),
        topColumnId: expectInclusiveRangeBound(pred.top),
      };
  }
}

function convertStateToTableColumnPredicate(
  config: Configuration,
  table: T.DataTable,
  state: TableColumnPredicateState,
): T.DataTableColumnPredicate {
  function checkColumnTypeEqualsFieldType(
    columnId: T.DataTableColumnId,
    fieldId: T.FieldId,
  ): void {
    const column = table.columnDefs.find((c) => c.id === columnId);

    if (!column) {
      throw new UiValidationError("Unknown column");
    }

    const field = config.allFieldsById.get(fieldId);

    if (!field) {
      throw new UiValidationError("Unknown field");
    }

    const columnType = FormulasTypeCheck.fieldValueTypeToValueType(
      column.valueType,
    );
    const fieldType = FormulasTypeCheck.fieldValueTypeToValueType(
      field.valueType,
    );

    if (!FormulasTypes.isSameType(columnType, fieldType)) {
      throw new UiValidationError(
        "Cannot compare field " +
          field.name +
          " to table column " +
          column.name +
          " because they have different data types",
      );
    }
  }

  if (state.fieldId === null) {
    throw new UiValidationError(
      "Select a field to use in the column condition",
    );
  }

  if (state.kind === null) {
    throw new UiValidationError("Select a column condition type");
  }

  switch (state.kind) {
    case "equals":
      if (state.columnId === null) {
        throw new UiValidationError("Select a column in the column condition");
      }

      checkColumnTypeEqualsFieldType(state.columnId, state.fieldId);

      return {
        kind: "equals",
        expression: {
          kind: "field-value",
          fieldId: state.fieldId,
        },
        other: state.columnId,
      };
    case "in-range":
      if (state.bottomColumnId === null) {
        throw new UiValidationError("Select a column in the column condition");
      }

      if (state.topColumnId === null) {
        throw new UiValidationError("Select a column in the column condition");
      }

      checkColumnTypeEqualsFieldType(state.bottomColumnId, state.fieldId);
      checkColumnTypeEqualsFieldType(state.topColumnId, state.fieldId);

      return {
        kind: "in-range",
        expression: {
          kind: "field-value",
          fieldId: state.fieldId,
        },
        bottom: {
          type: "included",
          value: state.bottomColumnId,
        },
        top: {
          type: "included",
          value: state.topColumnId,
        },
      };
  }
}

const useDataTableColumnPredicateEditor = makeStyles((t) =>
  createStyles({
    fieldDropdown: {
      marginRight: t.spacing(1),
      width: 300,
    },
    kindDropdown: {
      marginRight: t.spacing(1),
      width: 200,
    },
    columnDropdown: {
      marginRight: t.spacing(1),
      width: 300,
    },
  }),
);

const DataTableColumnPredicateEditor = React.memo(
  ({
    state,
    setState,
    allowRemove,
    remove,
    table,
    environment,
    config,
  }: {
    state: TableColumnPredicateState;
    setState: Setter<TableColumnPredicateState>;
    allowRemove: boolean;
    remove: () => void;
    table: T.DataTable;
    environment: ISet<T.FieldId>;
    config: Configuration;
  }) => {
    const C = useDataTableColumnPredicateEditor();

    const sortedFieldIds = _.sortBy(
      // We want a pipeline that carries both the field ID from `environment` and the
      // field definition from `allFieldsById` because if the field is missing from
      // `allFieldsById`, we still need its ID so we can display it in the dropdown as
      // '<Unknown Field>'. It would be simpler to do an intersection on `environment`
      // and `allFieldsById`, but then we'd lose any fields that are missing from
      // `allFieldsById`.
      environment
        .toArray()
        .map((id) => ({
          id,
          field: config.allFieldsById.get(id),
        }))
        .filter(
          (idAndField) =>
            idAndField.field === undefined ||
            idAndField.field.valueType.type !== "header",
        ),
      (idAndField) => idAndField.field?.name.toLowerCase() || "zzz",
    ).map((idAndField) => idAndField.id);

    const getFieldName = useCallback(
      (id: T.FieldId) =>
        config.allFieldsById.get(id)?.name || "<Unknown Field>",
      [config],
    );

    const columnIds = useMemo(() => table.columnDefs.map((c) => c.id), [table]);
    const getColumnName = useCallback(
      (id: T.DataTableColumnId) =>
        table.columnDefs.find((c) => c.id === id)?.name ||
        "<Unknown Table Column>",
      [table],
    );

    const setFieldId = usePropertySetter(setState, "fieldId");
    const setKind = usePropertySetter(setState, "kind");
    const setColumnId = usePropertySetter(setState, "columnId");
    const setBottomColumnId = usePropertySetter(setState, "bottomColumnId");
    const setTopColumnId = usePropertySetter(setState, "topColumnId");

    return (
      <Box display="flex">
        <SearchableDropdown
          className={C.fieldDropdown}
          margin="dense"
          label="Field"
          options={sortedFieldIds}
          getOptionLabel={getFieldName}
          value={state.fieldId}
          setValue={setFieldId}
        />
        <SearchableDropdown
          className={C.kindDropdown}
          margin="dense"
          label="Operation"
          options={ALL_TABLE_COLUMN_PREDICATE_KINDS}
          getOptionLabel={getTableColumnPredicateKindName}
          value={state.kind}
          setValue={setKind}
        />

        {state.kind === "equals" && (
          <SearchableDropdown
            className={C.columnDropdown}
            margin="dense"
            label="Table Column"
            options={columnIds}
            getOptionLabel={getColumnName}
            value={state.columnId}
            setValue={setColumnId}
          />
        )}

        {state.kind === "in-range" && (
          <>
            <SearchableDropdown
              className={C.columnDropdown}
              margin="dense"
              label="Table Column (Minimum)"
              options={columnIds}
              getOptionLabel={getColumnName}
              value={state.bottomColumnId}
              setValue={setBottomColumnId}
            />
            <SearchableDropdown
              className={C.columnDropdown}
              margin="dense"
              label="Table Column (Maximum)"
              options={columnIds}
              getOptionLabel={getColumnName}
              value={state.topColumnId}
              setValue={setTopColumnId}
            />
          </>
        )}

        <Box flex="1" />

        {allowRemove && (
          <IconButton onClick={remove}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    );
  },
);

export type TableLookupFieldState = {
  oldId: T.FieldId | null;
  id: T.FieldId;
  name: string;
  columnId: T.DataTableColumnId | null;
  domainValidationErrors: T.DataTableLookupFieldDefinitionValidation;
};

function newTableLookupFieldState(): TableLookupFieldState {
  return {
    oldId: null,
    id: "" as T.FieldId,
    name: "",
    columnId: null,
    domainValidationErrors: { id: [], name: [] },
  };
}

function convertTableLookupFieldToState(
  field: T.DataTableLookupFieldDefinition,
): TableLookupFieldState {
  return {
    oldId: field.oldId,
    id: field.id,
    name: field.name,
    columnId: field.columnId,
    domainValidationErrors: { id: [], name: [] },
  };
}

function convertStateToTableLookupField(
  config: Configuration,
  table: T.DataTable,
  state: TableLookupFieldState,
): T.DataTableLookupFieldDefinition {
  if (!state.name.trim()) {
    throw new UiValidationError("Field name is required");
  }

  if (!state.columnId) {
    throw new UiValidationError(
      "Output field requires a table column to be selected",
    );
  }

  const column = table.columnDefs.find((c) => c.id === state.columnId);

  if (!column) {
    throw new UiValidationError("Unknown table column");
  }

  return {
    oldId: state.oldId,
    id: state.id,
    name: state.name,
    valueType: column.valueType,
    columnId: state.columnId,
  };
}

const useDataTableLookupFieldEditor = makeStyles((t) =>
  createStyles({
    columnDropdown: {
      width: 300,
      marginRight: t.spacing(2),
    },
    nameField: {
      width: 300,
      marginRight: t.spacing(2),
    },
    idField: {
      width: 400,
      marginRight: t.spacing(2),
    },
  }),
);

const DataTableLookupFieldEditor = React.memo(
  ({
    state,
    setState,
    remove,
    table,
  }: {
    state: TableLookupFieldState;
    setState: Setter<TableLookupFieldState>;
    remove: () => void;
    table: T.DataTable;
  }) => {
    const C = useDataTableLookupFieldEditor();

    const columnIds = useMemo(() => table.columnDefs.map((c) => c.id), [table]);
    const getColumnName = useCallback(
      (id: T.DataTableColumnId) =>
        table.columnDefs.find((c) => c.id === id)?.name ||
        "<Unknown Table Column>",
      [table],
    );

    const setColumnId = usePropertySetter(setState, "columnId");
    const setName = usePropertySetter(setState, "name");
    const setId = usePropertySetter(setState, "id");

    const handleIdChange = useCallback(
      (event: { target: { value: string } }) =>
        setId(("calc-field@" + event.target.value) as T.FieldId),
      [setId],
    );

    const handleNameChange = useCallback(
      (event: { target: { value: string } }) => {
        setName(event.target.value);
        if (!state.oldId) {
          setId(createSlugId(event.target.value, "calc-field@") as T.FieldId);
        }
      },
      [setId, setName, state.oldId],
    );

    return (
      <Box display="flex">
        <SearchableDropdown
          className={C.columnDropdown}
          margin="dense"
          label="Table Column"
          options={columnIds}
          getOptionLabel={getColumnName}
          value={state.columnId}
          setValue={setColumnId}
        />
        <TextField
          className={C.nameField}
          margin="dense"
          variant="outlined"
          label="Field Name"
          value={state.name}
          error={!!state.domainValidationErrors.name.length}
          onChange={handleNameChange}
          helperText={getMessageNode(state.domainValidationErrors.name)}
        />
        <TextField
          className={C.idField}
          margin="dense"
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                variant="standard"
                disableTypography={true}
              >
                calc-field@
              </InputAdornment>
            ),
          }}
          variant="outlined"
          label="Field ID"
          disabled={!!state.oldId}
          value={unPrefixId(state.id, "calc-field@")}
          error={!!state.domainValidationErrors.id.length}
          onChange={handleIdChange}
          helperText={getMessageNode(state.domainValidationErrors.id)}
        />

        <Box flex="1" />

        <IconButton onClick={remove}>
          <DeleteIcon />
        </IconButton>
      </Box>
    );
  },
);
