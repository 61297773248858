import React from "react";
import { Box } from "@material-ui/core";
import { useMiddotIconStyles } from "../../styles";

const MiddotIcon = React.memo(() => {
  const C = useMiddotIconStyles();

  return (
    <Box className={C.middotIcon}>
      <div>&middot;&middot;&middot;</div>
    </Box>
  );
});

export default MiddotIcon;
