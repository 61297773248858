import React, { useEffect, useRef } from "react";

export type FileSave = {
  filename: string;
  url: string;
};

export const SaveFileAs = React.memo(
  ({ file }: { file: FileSave | undefined }) => {
    const linkRef = useRef<HTMLAnchorElement | null>(null);

    useEffect(() => {
      if (!file || !linkRef.current) {
        return;
      }

      linkRef.current.click();
    }, [file]);

    if (!file) {
      return null;
    }

    return (
      <a
        ref={linkRef}
        style={{ display: "none" }}
        href={file.url}
        download={file.filename}
      >
        Save File As...
      </a>
    );
  },
);
