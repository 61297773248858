import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";

function PrevButton({
  id,
  label,
  path,
  list,
}: {
  id: string;
  label: string;
  path: string;
  list: Array<{ id: string }>;
}) {
  const history = useHistory();
  const currentIndex = list && list.findIndex((i) => i.id === id);
  const prevId = list && list[currentIndex - 1]?.id;

  return (
    <Button
      data-selector="previous-button"
      style={{ marginLeft: "16px" }}
      variant="outlined"
      startIcon={<ArrowBackIcon />}
      disabled={!prevId}
      onClick={() => history.push(`${path}/${prevId}`)}
    >
      {label}
    </Button>
  );
}

export default PrevButton;
