import React, { useEffect, useState, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useDebounce } from "features/utils";
import { activeFiltersSelector } from "features/pricing-summaries";
import { setSearchTerm } from "features/pricing-summaries";
import {
  resetFiltersToNone,
  summarySelector,
} from "features/pricing-summaries";
import Button from "design/atoms/button";
import ProductResultsSummary from "../product-results-summary";
import ActionButtons from "../action-buttons";
import ManageFilters from "../manage-filters";
import TextInput from "design/atoms/text-input";
import { ProductsActionPanel, HeaderWrapper } from "./styles";

export default React.memo(
  ({
    loading,
    setForceCollapseResults,
  }: {
    loading: boolean;
    setForceCollapseResults: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const history = useHistory();
    const location = useLocation();
    const reviewFilters = () =>
      history.push(
        `${location.pathname}?activeView=inputs${
          location.hash ? `${location.hash}` : ""
        }`,
      );

    const activeFilters = useSelector(activeFiltersSelector);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(document.location.search);
    const summaryState = useSelector(summarySelector);

    const [filterByText, setFilterByText] = useState(summaryState.searchTerm);

    const [debouncedFilterByText] = useDebounce(filterByText, 500);

    useEffect(() => {
      dispatch(setSearchTerm(debouncedFilterByText));
    }, [debouncedFilterByText, dispatch]);

    useEffect(() => {
      if (!!activeFilters) {
        setOpen(true);
      }
    }, [activeFilters]);

    if (queryParams.get("inCompareView") === "true") {
      return <></>;
    }

    return (
      <ProductsActionPanel
        className={"page-loan-pricing-products-action-panel"}
      >
        <HeaderWrapper>
          <div style={{ marginLeft: "6px" }}>
            <TextInput
              width="360px"
              className="hide-for-mobile"
              placeholderText="Filter by Text"
              inputProps={{
                type: "search",
                value: filterByText ?? "",
                onChange: (e: ChangeEvent<HTMLInputElement>) =>
                  setFilterByText(e.target.value),
              }}
            />
          </div>

          <ProductResultsSummary
            activeFilters={activeFilters}
            open={open}
            setOpen={setOpen}
            loading={loading}
          />
        </HeaderWrapper>

        {open && <ManageFilters />}

        <ActionButtons>
          <Button grow={false} onClick={reviewFilters}>
            Review Inputs
          </Button>

          <Button
            grow={false}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <>Close Filters</> : <>Update Filters</>}
          </Button>

          {!!activeFilters && (
            <Button
              onClick={() => {
                queryParams.delete("searchTerm");
                queryParams.delete("filters");
                queryParams.delete("dynamicFilters");
                history.push(
                  `${location.pathname}?${queryParams.toString()}#${
                    location.hash ? "#" + location.hash : ""
                  }`,
                );

                dispatch(resetFiltersToNone());
              }}
            >
              Reset Filters
            </Button>
          )}
        </ActionButtons>
      </ProductsActionPanel>
    );
  },
);
