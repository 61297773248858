import styled from "styled-components";
import whitespace from "design/subatomics/whitespace";
import breakpoints from "design/subatomics/breakpoints";

export const ForgotPassword = styled.header`
  .typography-primary-title {
    text-align: right;
  }

  ${breakpoints.mobile} {
    .typography-primary-title {
      padding: ${whitespace("less")};
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .typography-primary-title-sub {
      padding: ${whitespace("less")};
      padding-top: 0;
      margin-top: ${whitespace("less")};
      margin-bottom: ${whitespace()};
    }
  }

  ${breakpoints.desktop} {
    .typography-primary-title {
      padding: ${whitespace("less")};
      margin-bottom: ${whitespace("less")};
    }

    .typography-primary-title-sub {
      position: absolute;
      top: ${whitespace("less")};
      left: ${whitespace("less")};
      width: 50%;
    }
  }
`;

export const Form = styled.div.attrs(() => ({
  className: "user-config",
}))`
  box-sizing: border-box;

  label {
    display: block;
  }

  ${breakpoints.mobile} {
    label {
      margin: ${whitespace()} ${whitespace("less")};
      flex: 1 1 calc((100% / 3) - ${whitespace("less")} - ${whitespace("less")});

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .molecule-select {
      margin: 0 ${whitespace("less")} ${whitespace()};
    }
  }

  ${breakpoints.desktop} {
    display: flex;
    justify-content: space-around;
    margin-top: ${whitespace()};

    > label {
      box-sizing: border-box;
      margin: 0 ${whitespace("less")};
      flex: 1 1 calc((100% / 3) - ${whitespace("less")} - ${whitespace("less")});
    }

    .molecule-select {
      width: calc(33.3% - ${whitespace()});
      min-width: 200px;
    }
  }
`;

export const ActionButtons = styled.div.attrs(() => ({
  className: "user-config",
}))`
  position: absolute;
  bottom: ${whitespace("less")};
  right: ${whitespace("less")};
  left: ${whitespace("less")};
  display: flex;

  button {
    flex: 1 1 auto;
    margin-left: ${whitespace()};
    display: inline-block;

    &:first-child {
      margin-left: 0;
    }
  }

  ${breakpoints.mobile} {
  }

  ${breakpoints.desktop} {
  }
`;
