import * as DateFns from "date-fns";
import { Map as IMap } from "immutable";
import _ from "lodash";
import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import { useVirtual } from "react-virtual";
import { useSelector, useDispatch } from "react-redux";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import {
  rateSheetsSelector,
  filteredRateSheetsSelector,
  getRateSheets,
  setSearchTerm,
  setSort,
} from "features/rate-sheets";
import {
  productsSelector,
  productsLoadingSelector,
  getProducts,
} from "features/products";
import { filteredInvestorsSelector, getInvestors } from "features/investors";
import {
  Box,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as Api from "api";
import { SearchableDropdown } from "design/molecules/dropdown";
import LinearProgressBar from "../_components/linear-progress-bar";
import { LoadingOverlay } from "design/atoms/loading-overlay";
import * as T from "types/engine-types";
import {
  CSV_APPLICATION_MIME_TYPE,
  CSV_TEXT_MIME_TYPE,
  PDF_MIME_TYPE,
  UiValidationError,
  XLS_MIME_TYPE,
  XLSM_MIME_TYPE,
  XLSX_MIME_TYPE,
  useIMapSetter,
  usePropertySetter,
  getValidationError,
  CSV_ACCEPT_EXTENSION,
  PDF_ACCEPT_EXTENSION,
  XLS_ACCEPT_EXTENSION,
  XLSM_ACCEPT_EXTENSION,
  XLSX_ACCEPT_EXTENSION,
  XLSM_MIME_TYPE_LC,
} from "features/utils";
import { usePermissions } from "features/roles";
import SettingsButton from "../_components/setting-button";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import { uploadBlob } from "features/blobs";
import { localAccessId } from "features/access-id";

type RateSheetState = {
  effectiveTimestamp: Date;
  rateSheetFormatName: string | null;
  pricingTableNamesByProductId: IMap<T.ProductId, string | null>;
  extractedTables: T.ExtractedPricingTable[];
  fileBlobId: T.BlobId;
  fileMimeType: string;
};

export default React.memo(() => {
  const parentRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const rateSheets = useSelector(filteredRateSheetsSelector);
  const investors = useSelector(filteredInvestorsSelector);
  const productsState = useSelector(productsSelector);
  const productsLoading = useSelector(productsLoadingSelector);
  const rateSheetsState = useSelector(rateSheetsSelector);
  const { searchTerm, sortField } = rateSheetsState;
  const hasPermission = usePermissions();
  const hasCreatePerm = hasPermission("rate-sheets-create");
  const accessId = localAccessId();

  useEffect(() => {
    if (!productsLoading) dispatch(getProducts());
  }, [dispatch, productsLoading]);

  useEffect(() => {
    if (investors.length === 0) dispatch(getInvestors());
  }, [dispatch, investors.length]);

  useEffect(() => {
    if (rateSheets.length === 0) dispatch(getRateSheets());
  }, [dispatch, rateSheets.length]);

  const rowVirtualizer = useVirtual({
    size: rateSheets?.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, []),
  });

  const onRowClick = (
    event: unknown,
    rateSheet: T.DecoratedRateSheetHeader | undefined,
  ) => {
    if (rateSheet) {
      history.push(`/c/${accessId}/rate-sheets/${rateSheet.id}`);
    }
  };

  return (
    <Table>
      <TableActions>
        <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
          <SearchInput
            label="rate sheets"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <ResultsCount>
          <span className="visible">
            {rateSheets.length} <VisibilityIcon />
          </span>
          <span className="separator">|</span>
          <span className="total">
            {rateSheetsState.rateSheets.length} Total
          </span>
        </ResultsCount>
        <SettingsButton disabled={!hasCreatePerm} />
        <RateSheetUploadButton
          disabled={!hasCreatePerm}
          products={productsState.products ? productsState.products : []}
          investors={investors}
        />
      </TableActions>

      <TableHeader>
        <TableHeaderCell
          style={{ flexBasis: "34%" }}
          onClick={() => dispatch(setSort("investorName"))}
        >
          Investor Name{" "}
          {sortField === "investorName" && (
            <SwapVertIcon
              style={{
                color: sortField === "investorName" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{ flexBasis: "33%" }}
          onClick={() => dispatch(setSort("creationTimestamp"))}
        >
          Created At{" "}
          {sortField === "creationTimestamp" && (
            <SwapVertIcon
              style={{
                color:
                  sortField === "creationTimestamp" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>

        <TableHeaderCell
          style={{ flexBasis: "33%" }}
          onClick={() => dispatch(setSort("effectiveTimestamp"))}
        >
          Effective At{" "}
          {sortField === "effectiveTimestamp" && (
            <SwapVertIcon
              style={{
                color:
                  sortField === "effectiveTimestamp" ? "#90d18c" : "inherit",
              }}
            />
          )}
        </TableHeaderCell>
      </TableHeader>

      {rateSheets && (
        <TableBodyWrapper ref={parentRef}>
          <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
            {rowVirtualizer.virtualItems.map((virtualRow) => (
              <TableRow
                key={virtualRow.index}
                onClick={(e) => onRowClick(e, rateSheets[virtualRow.index])}
                style={{
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <TableCell style={{ flexBasis: "34%" }}>
                  {rateSheets[virtualRow.index].investorName}
                </TableCell>
                <TableCell style={{ flexBasis: "33%" }}>
                  {rateSheets[virtualRow.index].creationTimestamp}
                </TableCell>
                <TableCell style={{ flexBasis: "33%" }}>
                  {rateSheets[virtualRow.index].effectiveTimestamp}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableBodyWrapper>
      )}
    </Table>
  );
});

function convertPdfResponseToRateSheetState(
  fileBlobId: T.BlobId,
  response: T.ProcessPdfRateSheetResponse.Success,
  products: T.DecoratedProductHeader[],
): RateSheetState {
  const extractedTables = response.pricingTables;

  const pricingTableNamesByProductId = IMap(
    products.map((product) => {
      const table = extractedTables.find(
        (t) =>
          t.name.toLowerCase() === product.name.toLowerCase() ||
          t.name.toLowerCase() === product.code.toLowerCase(),
      );

      return [product.id, table?.name || null];
    }),
  );

  const rateSheetState = {
    effectiveTimestamp: new Date(),
    investorId: null,
    rateSheetFormatName: response.rateSheetFormatName,
    pricingTableNamesByProductId,
    extractedTables,
    fileBlobId,
    fileMimeType: "application/pdf",
  };
  return rateSheetState;
}

function convertSpreadsheetResponseToRateSheetState(
  fileBlobId: T.BlobId,
  fileMimeType: string,
  response: T.ProcessSpreadsheetRateSheetResponse.Success,
  products: T.DecoratedProductHeader[],
): RateSheetState {
  const extractedTables = response.pricingTables;

  const pricingTableNamesByProductId = IMap(
    products.map((product) => {
      const table = extractedTables.find(
        (t) =>
          t.name.toLowerCase() === product.name.toLowerCase() ||
          t.name.toLowerCase() === product.code.toLowerCase(),
      );

      return [product.id, table?.name || null];
    }),
  );

  return {
    effectiveTimestamp: new Date(),
    rateSheetFormatName: response.rateSheetFormatName,
    pricingTableNamesByProductId,
    extractedTables,
    fileBlobId,
    fileMimeType,
  };
}

function convertStateToRateSheet(
  products: T.DecoratedProductHeader[],
  investorId: T.InvestorId,
  state: RateSheetState,
): T.NewRateSheetFromBlob {
  const tableProductMaps = products
    .filter((p) => p.investorId === investorId)
    .flatMap((product) => {
      const tableName = state.pricingTableNamesByProductId.get(product.id);

      if (tableName === undefined) {
        throw new Error("unreachable");
      }

      if (tableName === null) {
        return [];
      }

      if (tableName) {
      }
      const table = state.extractedTables.find((t) => t.name === tableName);

      if (!table) {
        throw new Error("unreachable");
      }

      const productPair: T.ProductPair = {
        productId: product.id,
        tableName,
      };
      return [productPair];
    });

  if (tableProductMaps.length < 1) {
    throw new UiValidationError("Select at least one pricing table to import");
  }

  return {
    investorId,
    creationTimestamp: DateFns.formatISO(new Date()),
    effectiveTimestamp: DateFns.formatISO(state.effectiveTimestamp),
    rateSheetFormatName: state.rateSheetFormatName,
    tableProductMaps,
    fileBlobId: state.fileBlobId,
    fileMimeType: state.fileMimeType,
  };
}

function validateRateSheetState(
  products: T.DecoratedProductHeader[],
  investorId: T.InvestorId,
  state: RateSheetState,
): UiValidationError | null {
  return getValidationError(() => {
    convertStateToRateSheet(products, investorId, state);
  });
}

const RateSheetUploadButton = React.memo(
  ({
    disabled,
    products,
    investors,
  }: {
    disabled: boolean;
    products: T.DecoratedProductHeader[];
    investors: T.DecoratedInvestorHeader[];
  }) => {
    const C = useRateSheetImportDialogStyles();
    const dispatch = useDispatch();
    const { client } = useSelector(nonNullApplicationInitializationSelector);
    const [file, setFile] = useState<File | null>(null);
    const [action, setAction] = useState<
      "viewing" | "selecting" | "uploading" | "editing" | "contact-support"
    >("viewing");
    const [fileInputKey, setFileInputKey] = useState("key");

    const [investor, setInvestor] = useState<
      T.DecoratedInvestorHeader | T.Investor | null
    >(null);

    const investorId = investor && investor.id;

    const [rateSheetState, setRateSheetState] = useState<RateSheetState | null>(
      null,
    );
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadMessage, setUploadMessage] = useState("Initializing...");

    // refresh Investor object to ensure in sync with service
    // prep to inspect isPricingEnabled and potentially re-enable(update) Investor
    useEffect(() => {
      if (investorId) {
        (async () => {
          const refreshedInvestor = await Api.getInvestor(investorId);
          setInvestor(refreshedInvestor);
        })();
      }
    }, [investorId]);

    const handleRateSheetUpload = (event: { target: HTMLInputElement }) => {
      setAction("selecting");

      if (!event.target.files || event.target.files.length === 0) {
        return;
      }

      const newFile = event.target.files[0];
      setFile(newFile);

      if (newFile.size > 10 * 1024 * 1024) {
        alert("File is over 10 MiB limit");
        return;
      }
    };

    // TODO upload would sometimes cause browser to be non-responsive for some reason
    const uploadRateSheet = async () => {
      if (!file || !investor) {
        return;
      }

      switch (file.type) {
        case PDF_MIME_TYPE: {
          setUploadProgress(20);
          setUploadMessage("Uploading rate sheet file...");

          const blobId = await uploadBlob(file, file.type);

          setUploadProgress(50);
          setUploadMessage("Processing rate sheet file...");

          const response = await Api.processPdfRateSheet(
            investor.id,
            blobId,
            true,
            true,
          );

          switch (response.kind) {
            case "success":
              setUploadProgress(100);
              setUploadMessage("Finalizing...");
              setRateSheetState(
                convertPdfResponseToRateSheetState(blobId, response, products),
              );
              setTimeout(() => {
                setAction("editing");
              }, 100);
              break;
            case "no-matching-formats":
              setUploadMessage("Rate sheet not recognized.");
              setTimeout(() => {
                setAction("contact-support");
              }, 500);
              break;
          }
          break;
        }
        case CSV_APPLICATION_MIME_TYPE:
        case CSV_TEXT_MIME_TYPE:
        case XLS_MIME_TYPE:
        case XLSM_MIME_TYPE:
        case XLSM_MIME_TYPE_LC:
        case XLSX_MIME_TYPE: {
          setUploadProgress(20);
          setUploadMessage("Uploading rate sheet file...");

          const blobId = await uploadBlob(file, file.type);

          setUploadProgress(50);
          setUploadMessage("Processing rate sheet file...");

          const response = await Api.processSpreadsheetRateSheet(
            investor.id,
            blobId,
            true,
            true,
          );

          switch (response.kind) {
            case "success":
              setUploadProgress(100);
              setUploadMessage("Finalizing...");
              setRateSheetState(
                convertSpreadsheetResponseToRateSheetState(
                  blobId,
                  file.type,
                  response,
                  products,
                ),
              );
              setTimeout(() => {
                setAction("editing");
              }, 100);
              break;
            case "no-matching-formats":
              setUploadMessage("Rate sheet not recognized.");
              setTimeout(() => {
                setAction("contact-support");
              }, 500);
              break;
          }
          break;
        }
        default:
          alert(`File type ${file.type} not supported`);
          return;
      }
    };

    const resetUploadProgress = () => {
      setUploadMessage("Initializing...");
      setUploadProgress(0);
    };

    const onCancel = () => {
      setAction("viewing");
      setFile(null);
      const randomString = Math.random().toString(36);
      setFileInputKey(randomString);
      resetUploadProgress();

      dispatch(getInvestors());
    };

    const submit = () => {
      setAction("uploading");
      uploadRateSheet();
    };

    const enablePricing = async (
      investor: T.DecoratedInvestorHeader | T.Investor | null,
    ) => {
      if (investor) {
        investor.isPricingEnabled = true;
        await Api.saveInvestor(investor);
        dispatch(getInvestors());
      }
      onCancel();
    };

    const selectingDialogContent = (
      <Box minHeight="50px">
        <Box>File to upload: {file ? `${file.name}` : ""}</Box>
        <Box display="flex" alignItems="center">
          <Box>Investor:</Box>
          <SearchableDropdown<T.DecoratedInvestorHeader | T.Investor>
            className={C.dropdown}
            label="Select Investor"
            margin="dense"
            options={investors}
            getOptionLabel={(investor) => investor.name}
            value={investor}
            setValue={setInvestor}
          />
        </Box>
      </Box>
    );

    const supportDialogContent = `No matching rate sheet format for [${
      file?.name || ""
    }], please contact support. Because of these formatting issues, this rate sheet is unable to be uploaded right now, and pricing for this investor has been disabled until this is resolved.  If you want to enable this pricing, select the button below.`;

    return (
      <>
        <label
          htmlFor="file-upload"
          className={`MuiButtonBase-root MuiButton-root MuiButton-outlined ${
            disabled ? "Mui-disabled" : ""
          }`}
        >
          <span
            style={{
              display: "inherit",
              marginLeft: "-4px",
              marginRight: "8px",
            }}
          >
            <AddIcon fontSize="small" />
          </span>
          Upload Rate Sheet
        </label>
        {!disabled && (
          <input
            id="file-upload"
            key={fileInputKey} // reset file selection after cancel in dialog
            type="file"
            accept={[
              CSV_ACCEPT_EXTENSION,
              PDF_ACCEPT_EXTENSION,
              XLS_ACCEPT_EXTENSION,
              XLSM_ACCEPT_EXTENSION,
              XLSX_ACCEPT_EXTENSION,
            ].join(",")}
            onChange={handleRateSheetUpload}
            style={{
              display: "none",
            }}
          />
        )}

        <Dialog
          open={
            action === "selecting" ||
            action === "uploading" ||
            action === "contact-support"
          }
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Upload Rate Sheet</DialogTitle>

          <DialogContent>
            {(action === "selecting" || action === "uploading") &&
              selectingDialogContent}
            {action === "contact-support" && supportDialogContent}
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>

            {action === "selecting" && (
              <Button color="primary" onClick={submit} disabled={!investor}>
                Upload File
              </Button>
            )}

            {action === "contact-support" && (
              <Button color="primary" onClick={() => enablePricing(investor)}>
                Re-enable Pricing
              </Button>
            )}
          </DialogActions>
          {action === "uploading" && (
            <Backdrop
              open={true}
              style={{ flexDirection: "column", color: "#fff", zIndex: 10 }}
            >
              <LinearProgressBar
                width={"90%"}
                useCustomColor={true}
                progress={uploadProgress}
                message={uploadMessage}
              />
            </Backdrop>
          )}
        </Dialog>
        {action === "editing" &&
          rateSheetState !== null &&
          investor !== null && (
            <RateSheetImportDialog
              initialState={rateSheetState}
              clientId={client.id}
              investor={investor}
              products={products}
              onCancel={onCancel}
            />
          )}
      </>
    );
  },
);

const useRateSheetImportDialogStyles = makeStyles(() =>
  createStyles({
    dropdown: {
      marginLeft: 16,
      width: 270,
    },
  }),
);

const RateSheetImportDialog = React.memo(
  ({
    initialState,
    clientId,
    investor,
    products,
    onCancel,
  }: {
    initialState: RateSheetState;
    clientId: T.ClientId;
    investor: T.DecoratedInvestorHeader | T.Investor;
    products: T.DecoratedProductHeader[];
    onCancel: () => void;
  }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const [loading, setLoading] = useState<string | null>(null);
    const accessId = localAccessId();

    const setPricingTableNamesByProductId = usePropertySetter(
      setState,
      "pricingTableNamesByProductId",
    );
    const setPricingTableNameByProductId = useIMapSetter(
      setPricingTableNamesByProductId,
    );

    const associatedProducts = products.filter(
      (p) => p.investorId === investor.id,
    );

    const sortedProducts = useMemo(
      () => _.sortBy(associatedProducts, (p) => p.name.toLowerCase()),
      [associatedProducts],
    );

    const nextError = validateRateSheetState(products, investor.id, state);

    const saveRateSheet = useCallback(() => {
      async function run() {
        const rateSheet = convertStateToRateSheet(products, investor.id, state);
        setLoading("Completing rate sheet import...");
        const newRateSheet = await Api.createRateSheet(rateSheet);
        dispatch(getRateSheets());
        history.push(`/c/${accessId}/rate-sheets/${newRateSheet.id}`);
      }

      run();
    }, [history, products, investor, state, dispatch, accessId]);

    const saveRateSheetWithEnable = async () => {
      investor.isPricingEnabled = true;
      await Api.saveInvestor(investor);
      dispatch(getInvestors());
      saveRateSheet();
    };

    let topMessage = `${
      state.rateSheetFormatName || "Custom"
    } rate sheet detected.`;

    if (associatedProducts.length > 0) {
      topMessage += ` Please match ${investor.name} products with the corresponding pricing tables in this rate sheet:`;
    } else {
      topMessage += ` ${investor.name} has no products associated with it.`;
    }

    return (
      <Dialog open={true} fullWidth maxWidth="lg">
        <LoadingOverlay when={loading !== null} text={loading || undefined} />
        <DialogTitle>Import Rate Sheet</DialogTitle>

        <DialogContent>
          <Box marginBottom="16px">{topMessage}</Box>

          {sortedProducts.map((product) => {
            const selectedTableName = state.pricingTableNamesByProductId.get(
              product.id,
            );

            if (selectedTableName === undefined) {
              throw new Error("unreachable");
            }

            return (
              <ProductMatcher
                key={product.id}
                product={product}
                tables={state.extractedTables}
                selectedTableName={selectedTableName}
                setSelectedTableName={setPricingTableNameByProductId.withKey(
                  product.id,
                )}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            color="primary"
            disabled={!!nextError}
            onClick={saveRateSheet}
          >
            Import
          </Button>
          {!investor?.isPricingEnabled && (
            <Button color="primary" onClick={saveRateSheetWithEnable}>
              Import and Enable Pricing
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  },
);

const ProductMatcher = React.memo(
  ({
    product,
    tables,
    selectedTableName,
    setSelectedTableName,
  }: {
    product: T.DecoratedProductHeader;
    tables: T.ExtractedPricingTable[];
    selectedTableName: string | null;
    setSelectedTableName: (tableName: string | null) => void;
  }) => {
    const C = useRateSheetImportDialogStyles();

    const tableNames = useMemo(() => tables.map((t) => t.name), [tables]);

    return (
      <Box
        display="flex"
        style={{
          alignItems: "center",
        }}
      >
        <Box fontWeight="bold" display="inline-block" width="300px">
          {`${product.name}: `}
        </Box>
        <SearchableDropdown
          className={C.dropdown}
          label="Select Pricing"
          margin="dense"
          options={tableNames}
          getOptionLabel={(tableName: string) => tableName}
          value={selectedTableName}
          setValue={setSelectedTableName}
        />
      </Box>
    );
  },
);
