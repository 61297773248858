import { List as IList } from "immutable";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import XLSX from "xlsx";
import { Box, Button, Paper, TextField } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import PhotoSizeSelectSmallIcon from "@material-ui/icons/PhotoSizeSelectSmall";
import * as Api from "api";
import {
  DocumentRegionSelect,
  DocumentRegion,
} from "../document-region-select";
import { Dropdown, SearchableDropdown } from "design/molecules/dropdown";
import { LoadingOverlay } from "design/atoms/loading-overlay";
import {
  ObjectListEditor,
  ObjectListState,
  newObjectListState,
} from "design/organisms/object-list-editor";
import { PricingTableViewer } from "design/molecules/pricing-table-viewer";
import * as T from "types/engine-types";
import {
  Setter,
  CSV_APPLICATION_MIME_TYPE,
  CSV_TEXT_MIME_TYPE,
  PDF_MIME_TYPE,
  XLS_MIME_TYPE,
  XLSM_MIME_TYPE,
  XLSM_MIME_TYPE_LC,
  XLSX_MIME_TYPE,
  getValidationError,
  readFileBytes,
  useGuardedSetter,
  usePropertySetter,
  useSetter,
  useTargetValue,
  validation,
  UiValidationError,
  spreadsheetFromCsv,
  spreadsheetFromXlsx,
  CSV_ACCEPT_EXTENSION,
  generateSafeId,
  getErrorMessage,
} from "features/utils";
import { uploadBlob } from "features/blobs";

export type RateSheetFormatState = {
  internalName: string;
  displayName: string;
  initialValue: T.RateSheetFormatChangeset | null;
  fileStructure: FileStructureState | null;
};

type FileStructureState = PdfFileStructureState | SpreadsheetFileStructureState;
type PdfFileStructureState = {
  kind: "pdf";
  sampleFile: File;
  blobId: T.BlobId;
  items: ObjectListState<PdfRateSheetItemState>;
};
type SpreadsheetFileStructureState = {
  kind: "spreadsheet";
  sampleFile: File;
  sampleSpreadsheet: Spreadsheet;
  items: ObjectListState<SpreadsheetRateSheetItemState>;
};

type PdfRateSheetItemState =
  | PdfRateSheetCheckItemState
  | PdfRateSheetPricingTableItemState
  | PdfRateSheetHeaderlessPricingTableItemState;
type PdfRateSheetCheckItemState = {
  kind: "check";
  id: T.PdfRateSheetItemId;
  area: T.PdfTableArea;
  rows: string[][];
  sampleFileRows: string[][];
};
type PdfRateSheetPricingTableItemState = {
  kind: "pricing-table";
  id: T.PdfRateSheetItemId;
  displayName: string;
  area: T.PdfTableArea;
  sampleFileRows: string[][];
  priceFormat: T.MortgagePriceFormat;
  rateFormat: T.MortgageRateFormat;
};
type PdfRateSheetHeaderlessPricingTableItemState = {
  kind: "headerless-pricing-table";
  id: T.PdfRateSheetItemId;
  displayName: string;
  area: T.PdfTableArea;
  sampleFileRows: string[][];
  priceFormat: T.MortgagePriceFormat;
  rateFormat: T.MortgageRateFormat;
  columns: ObjectListState<PricingTableColumnState>;
};
const PDF_ITEM_KINDS: PdfRateSheetItemState["kind"][] = [
  "check",
  "pricing-table",
  "headerless-pricing-table",
];

type SpreadsheetRateSheetItemState =
  | SpreadsheetRateSheetCheckItemState
  | SpreadsheetUnpivotedPricingTablesItemState
  | SpreadsheetRateSheetPricingTableItemState
  | SpreadsheetRateSheetHeaderlessPricingTableItemState;
type SpreadsheetRateSheetCheckItemState = {
  kind: "check";
  id: T.SpreadsheetRateSheetItemId;
  area: SpreadsheetRangeState;
  rows: string[][] | null;
};
type SpreadsheetUnpivotedPricingTablesItemState = {
  kind: "unpivoted-pricing-tables";
  id: T.SpreadsheetRateSheetItemId;
  area: SpreadsheetRangeState;
  priceFormat: T.MortgagePriceFormat;
  rateFormat: T.MortgageRateFormat;
  rateColumn: number;
  priceColumn: number;
  lockColumn: number;
  productNameColumn: number;
  hasHeader: boolean;
};
type SpreadsheetRateSheetPricingTableItemState = {
  kind: "pricing-table";
  id: T.SpreadsheetRateSheetItemId;
  displayName: string;
  area: SpreadsheetRangeState;
  priceFormat: T.MortgagePriceFormat;
  rateFormat: T.MortgageRateFormat;
};
type SpreadsheetRateSheetHeaderlessPricingTableItemState = {
  kind: "headerless-pricing-table";
  id: T.SpreadsheetRateSheetItemId;
  displayName: string;
  area: SpreadsheetRangeState;
  priceFormat: T.MortgagePriceFormat;
  rateFormat: T.MortgageRateFormat;
  orientation: T.PricingTableOrientation;
  rowsOrColumns: ObjectListState<PricingTableColumnState>;
};
const SPREADSHEET_ITEM_KINDS: SpreadsheetRateSheetItemState["kind"][] = [
  "check",
  "pricing-table",
  "headerless-pricing-table",
  "unpivoted-pricing-tables",
];

type Spreadsheet = {
  sheets: SpreadsheetSheet[];
};

type SpreadsheetSheet = {
  name: string;
  rows: string[][];
};

type SpreadsheetRangeState = {
  sheetName: string | null;
  topLeftCellAddress: string;
  bottomRightCellAddress: string;
};

const PRICING_TABLE_ORIENTATIONS: T.PricingTableOrientation[] = [
  "columns",
  "rows",
];

type PricingTableColumnState = {
  id: T.PricingTableColumnId;
  kind: "interest-rate" | "ignore" | "price";
  rateLockPeriodDays: number | null;
};

const PRICING_TABLE_COLUMN_KINDS: PricingTableColumnState["kind"][] = [
  "interest-rate",
  "ignore",
  "price",
];

const { newrelic } = window;

export function newRateSheetFormatState(): RateSheetFormatState {
  return {
    internalName: "",
    displayName: "",
    initialValue: null,
    fileStructure: null,
  };
}

export function convertRateSheetFormatToState(
  format: T.RateSheetFormatChangeset,
): RateSheetFormatState {
  return {
    internalName: format.internalName,
    displayName: format.displayName,
    initialValue: format,
    fileStructure: null,
  };
}

export function convertStateToRateSheetFormat(
  state: RateSheetFormatState,
  originalFileStructure?: T.RateSheetFileStructure,
): T.RateSheetFormatChangeset {
  if (state.internalName.trim() === "") {
    throw new UiValidationError("Internal name is required");
  }

  if (state.displayName.trim() === "") {
    throw new UiValidationError("Display name is required");
  }

  const fileStructure = state.fileStructure
    ? convertStateToFileStructure(state.fileStructure)
    : originalFileStructure;

  if (!fileStructure) {
    throw new UiValidationError("Provide a sample rate sheet file to continue");
  }

  return {
    internalName: state.internalName,
    displayName: state.displayName,
    isEnabled: true,
    fileStructure,
  };
}

export const validateRateSheetFormatState = (
  state: RateSheetFormatState,
  originalFileStructure?: T.RateSheetFileStructure,
) =>
  getValidationError(() => {
    convertStateToRateSheetFormat(state, originalFileStructure);
  });

const useEditorStyles = makeStyles((t) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      margin: 16,
      height: "calc(100% - 32px)",
    },
    internalNameField: {
      width: 300,
      margin: t.spacing(0, 1.5),
    },
    displayNameField: {
      width: 300,
      margin: t.spacing(0, 1.5),
    },
  }),
);

export const RateSheetFormatEditor = React.memo(
  ({
    state,
    setState,
  }: {
    state: RateSheetFormatState;
    setState: Setter<RateSheetFormatState>;
  }) => {
    const C = useEditorStyles();

    const setInternalName = usePropertySetter(setState, "internalName");
    const handleInternalNameChange = useTargetValue(setInternalName);
    const setDisplayName = usePropertySetter(setState, "displayName");
    const handleDisplayNameChange = useTargetValue(setDisplayName);
    const setFileStructure = usePropertySetter(setState, "fileStructure");

    const [loadingText, setLoadingText] = useState<string | null>(null);

    const handleSampleFileChange = useCallback(
      async (event: { target: HTMLInputElement }) => {
        setLoadingText("Processing sample rate sheet file...");

        if (!event.target.files || event.target.files.length === 0) {
          setLoadingText(null);
          return;
        }

        const file = event.target.files[0];

        if (file.size > 10 * 1024 * 1024) {
          // I'm hoping this is a reasonable limit, but who knows
          alert("File is over 10 MiB limit");
          setLoadingText(null);
          return;
        }

        console.log(file.type);
        console.log(XLSM_MIME_TYPE);
        console.log(file.type === XLSM_MIME_TYPE);

        switch (file.type) {
          case CSV_APPLICATION_MIME_TYPE:
          case CSV_TEXT_MIME_TYPE:
          case XLS_MIME_TYPE:
          case XLSM_MIME_TYPE:
          case XLSM_MIME_TYPE_LC:
          case XLSX_MIME_TYPE: {
            setLoadingText("Reading spreadsheet...");
            const data = await readFileBytes(file);

            const sampleSpreadsheet = [
              CSV_TEXT_MIME_TYPE,
              CSV_APPLICATION_MIME_TYPE,
            ].includes(file.type)
              ? spreadsheetFromCsv(data)
              : spreadsheetFromXlsx(data);

            if (!state.initialValue) {
              setFileStructure({
                kind: "spreadsheet",
                sampleFile: file,
                sampleSpreadsheet,
                items: newObjectListState([]),
              });
              setLoadingText(null);
              return;
            }

            const initialFileStructure = state.initialValue.fileStructure;

            if (initialFileStructure.kind !== "spreadsheet") {
              alert(
                "This rate sheet format is not compatible with spreadsheet files.",
              );
              setLoadingText(null);
              return;
            }

            setFileStructure(
              convertSpreadsheetFileStructureToState(
                initialFileStructure,
                file,
                sampleSpreadsheet,
              ),
            );
            setLoadingText(null);
            return;
          }
          case PDF_MIME_TYPE: {
            setLoadingText("Uploading PDF file...");
            const blobId = await uploadBlob(file, "application/pdf");

            if (!state.initialValue) {
              setFileStructure({
                kind: "pdf",
                sampleFile: file,
                blobId,
                items: newObjectListState([]),
              });
              setLoadingText(null);
              return;
            }

            const initialFileStructure = state.initialValue.fileStructure;

            if (initialFileStructure.kind !== "pdf") {
              alert("This rate sheet format is not compatible with PDF files.");
              setLoadingText(null);
              return;
            }

            setLoadingText("Extracting PDF data...");
            const extractResp = await Api.Admin.extractPdfTables({
              pdfFileBlobId: blobId,
              tableAreas: initialFileStructure.items.map((item) => item.area),
            });
            const areaOutputs = extractResp.areaOutputs;

            if (areaOutputs.length !== initialFileStructure.items.length) {
              throw new Error("Server returned incorrect number of areas");
            }

            setFileStructure(
              convertPdfFileStructureToState(
                initialFileStructure,
                file,
                blobId,
                areaOutputs,
              ),
            );
            setLoadingText(null);
            return;
          }
          default:
            alert("File must be an Excel spreadsheet or PDF");
            return;
        }
      },
      [state.initialValue, setFileStructure],
    );

    const setPdfFileStructure = useGuardedSetter(
      setFileStructure,
      (s): s is PdfFileStructureState => s?.kind === "pdf",
      [],
    );
    const setSpreadsheetFileStructure = useGuardedSetter(
      setFileStructure,
      (s): s is SpreadsheetFileStructureState => s?.kind === "spreadsheet",
      [],
    );

    const groupBySheet = () => {
      const groupedSheets = state.fileStructure?.items.objects;
      if (groupedSheets) {
        if ("area" in groupedSheets[0]) {
          if ("page" in groupedSheets[0].area) {
            const asArray: PdfRateSheetItemState[] =
              groupedSheets.slice() as PdfRateSheetItemState[];
            asArray.sort(
              (a: PdfRateSheetItemState, b: PdfRateSheetItemState) => {
                return a.area.page - b.area.page;
              },
            );

            const newState = newRateSheetFormatState();
            if ("blobId" in state.fileStructure!) {
              newState.displayName = state.displayName;
              newState.internalName = state.internalName;
              newState.initialValue = state.initialValue;
              newState.fileStructure = {
                blobId: state.fileStructure.blobId,
                items: {
                  objects: asArray,
                  selectedIndex: null,
                },
                kind: "pdf",
                sampleFile: state.fileStructure?.sampleFile,
              };
              setState(newState);
            }
          }

          if (state.fileStructure) {
            if ("sampleSpreadsheet" in state.fileStructure) {
              const asArray: SpreadsheetRateSheetItemState[] =
                groupedSheets.slice() as SpreadsheetRateSheetItemState[];
              asArray.sort(
                (
                  a: SpreadsheetRateSheetItemState,
                  b: SpreadsheetRateSheetItemState,
                ) => {
                  const aString = String(a.area.sheetName!);
                  const bString = String(b.area.sheetName!);
                  return aString.localeCompare(bString);
                },
              );
              const newState = newRateSheetFormatState();

              newState.displayName = state.displayName;
              newState.internalName = state.internalName;
              newState.initialValue = state.initialValue;
              newState.fileStructure = {
                items: {
                  objects: asArray,
                  selectedIndex: null,
                },
                kind: "spreadsheet",
                sampleFile: state.fileStructure?.sampleFile,
                sampleSpreadsheet: state.fileStructure.sampleSpreadsheet,
              };
              setState(newState);
            }
          }
        }
      }
    };

    return (
      <>
        <LoadingOverlay when={!!loadingText} text={loadingText} />

        <Paper className={C.container}>
          <Box>
            <Box px={3} my={3} fontSize={32}>
              Rate sheet format editor
            </Box>
            <Box px={1.5} my={3}>
              <TextField
                className={C.internalNameField}
                label="Internal Name"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={state.internalName}
                error={state.internalName.trim() === ""}
                onChange={handleInternalNameChange}
              />
              <TextField
                className={C.displayNameField}
                label="Display Name"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={state.displayName}
                error={state.displayName.trim() === ""}
                onChange={handleDisplayNameChange}
              />
            </Box>
          </Box>
          <Box flex="1" mb={3} overflow="scroll">
            {state.fileStructure === null && (
              <>
                <Box px={3} my={3} fontSize={24}>
                  Import a sample rate sheet file
                </Box>
                <Box px={3} my={3}>
                  <input
                    type="file"
                    accept={[
                      CSV_ACCEPT_EXTENSION,
                      CSV_APPLICATION_MIME_TYPE,
                      CSV_TEXT_MIME_TYPE,
                      PDF_MIME_TYPE,
                      XLS_MIME_TYPE,
                      XLSM_MIME_TYPE,
                      XLSX_MIME_TYPE,
                    ].join(", ")}
                    onChange={handleSampleFileChange}
                  />
                </Box>
              </>
            )}
            {state.fileStructure?.kind === "pdf" && (
              <Box px={3} height="100%" position="relative">
                <PdfFileStructureEditor
                  state={state.fileStructure}
                  setState={setPdfFileStructure}
                  groupBySheet={groupBySheet}
                />
              </Box>
            )}
            {state.fileStructure?.kind === "spreadsheet" && (
              <Box px={3} height="100%" position="relative">
                <SpreadsheetFileStructureEditor
                  state={state.fileStructure}
                  setState={setSpreadsheetFileStructure}
                  groupBySheet={groupBySheet}
                />
              </Box>
            )}
          </Box>
        </Paper>
      </>
    );
  },
);

function convertStateToFileStructure(
  state: FileStructureState,
): T.RateSheetFileStructure {
  switch (state.kind) {
    case "pdf":
      return convertStateToPdfFileStructure(state);
    case "spreadsheet":
      return convertStateToSpreadsheetFileStructure(state);
  }
}

const PdfFileStructureEditor = React.memo(
  ({
    state,
    setState,
    groupBySheet,
  }: {
    state: PdfFileStructureState;
    setState: Setter<PdfFileStructureState>;
    groupBySheet: () => void;
  }) => {
    const [renderedSampleFilePages, setRenderedSampleFilePages] = useState<
      T.RenderedPdfPage[] | null
    >(null);
    const [loadingRegionData, setLoadingRegionData] = useState(false);

    useEffect(() => {
      async function run() {
        try {
          const renderResponse = await Api.Admin.renderPdfPages({
            pdfFileBlobId: state.blobId,
          });

          setRenderedSampleFilePages(renderResponse.renderedPages);
        } catch (err) {
          console.error(err);
          if (err instanceof Error) {
            const error = new Error(
              "PDF rendering error (check console for full details): ",
            );
            newrelic.noticeError(error);
            newrelic.noticeError(err);
            alert(
              "PDF rendering error (check console for full details): " +
                err.message,
            );
          }
        }
      }

      run();
    }, [state.blobId]);

    const setItems = usePropertySetter(setState, "items");
    const setItemsObjects = usePropertySetter(setItems, "objects");

    const addRegions = useCallback(
      (regions: DocumentRegion[]) => {
        async function run() {
          setRegionSelectOpen(false);
          setLoadingRegionData(true);

          const areas = regions.map((region) => ({
            page: region.pageIndex + 1,
            extractionMethod: "basic" as const,
            x1: region.physicalX,
            x2: region.physicalX + region.physicalWidth,
            y1: region.physicalY,
            y2: region.physicalY + region.physicalHeight,
          }));

          const blobId = state.blobId;
          const extractResp = await Api.Admin.extractPdfTables({
            pdfFileBlobId: blobId,
            tableAreas: areas,
          });
          const areaOutputs = extractResp.areaOutputs;

          if (areaOutputs.length !== areas.length) {
            throw new Error("Server returned incorrect number of areas");
          }

          const areasWithOutputs = _.zip(areas, areaOutputs);

          setLoadingRegionData(false);
          setItemsObjects((oldItems) => {
            return [
              ...oldItems,
              ...areasWithOutputs.map(([area, sampleFileRows]) => ({
                kind: "check" as const,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                id: generateSafeId(),
                area: area!,
                rows: sampleFileRows!,
                sampleFileRows: sampleFileRows!,
              })),
            ];
          });
        }

        run();
      },
      [state.blobId, setItemsObjects],
    );

    const openRegionSelect = useCallback(() => {
      setRegionSelectOpen(true);
    }, []);
    const closeRegionSelect = useCallback(() => {
      setRegionSelectOpen(false);
    }, []);

    const getItemLabel = useCallback((item: PdfRateSheetItemState) => {
      switch (item.kind) {
        case "check":
          if (
            item.sampleFileRows.length > 0 &&
            item.sampleFileRows[0].length > 0
          ) {
            return <>{`Check: ${item.sampleFileRows[0][0] || "--"}`}</>;
          }

          return <>{"Check"}</>;
        case "pricing-table":
        case "headerless-pricing-table":
          return <>{`Table: ${item.displayName || "--"}`}</>;
      }
    }, []);

    const makeItemEditor = useCallback(
      ({
        value,
        setValue,
      }: {
        value: PdfRateSheetItemState;
        setValue: Setter<PdfRateSheetItemState>;
      }) => <PdfItemEditor state={value} setState={setValue} />,
      [],
    );

    const [regionSelectOpen, setRegionSelectOpen] = useState(false);

    return (
      <>
        <LoadingOverlay
          when={!renderedSampleFilePages || loadingRegionData}
          text={
            loadingRegionData
              ? "Extracting PDF data..."
              : "Preparing PDF for display..."
          }
        />

        {renderedSampleFilePages === null && (
          <>
            <Box my={3} fontSize={24}>
              Import a sample rate sheet file
            </Box>
            <Box my={3} display="flex" alignItems="center">
              <Box pr={1}>
                <DoneIcon />
              </Box>
              <Box>
                <h4>{state.sampleFile.name}</h4>
              </Box>
            </Box>
          </>
        )}
        {renderedSampleFilePages !== null && (
          <>
            <Button
              style={{ marginBottom: "4px" }}
              variant="outlined"
              onClick={() => groupBySheet()}
            >
              Reorder Sheets
            </Button>
            <Box position="absolute" right="24px" top="0">
              <Button
                variant="outlined"
                startIcon={<PhotoSizeSelectSmallIcon />}
                onClick={openRegionSelect}
              >
                Select Regions
              </Button>
            </Box>
            <ObjectListEditor
              height="100%"
              state={state.items}
              additionalButtons={[]}
              getObjectLabel={getItemLabel}
              makeObjectEditor={makeItemEditor}
              validateObject={validatePdfItem}
              setState={setItems}
              emptyText="Click &ldquo;New&rdquo; to insert a new item into this list"
            />
            {regionSelectOpen && (
              <DocumentRegionSelect
                pages={renderedSampleFilePages}
                onSubmit={addRegions}
                onCancel={closeRegionSelect}
              />
            )}
          </>
        )}
      </>
    );
  },
);

const PdfItemEditor = React.memo(
  ({
    state,
    setState,
  }: {
    state: PdfRateSheetItemState;
    setState: Setter<PdfRateSheetItemState>;
  }) => {
    const orientation: T.PricingTableOrientation = "columns";

    const setKind: Setter<PdfRateSheetItemState["kind"]> = useSetter(
      setState,
      (oldState, transform) => {
        const kind = transform(state.kind);

        switch (kind) {
          case "check":
            return {
              kind: "check" as const,
              id: oldState.id,
              area: oldState.area,
              rows: oldState.sampleFileRows,
              sampleFileRows: oldState.sampleFileRows,
            };
          case "pricing-table": {
            const displayName =
              oldState.kind === "check" ? "" : oldState.displayName;
            const priceFormat =
              oldState.kind === "check" ? "absolute" : oldState.priceFormat;
            const rateFormat =
              oldState.kind === "check" ? "percent" : oldState.rateFormat;

            return {
              kind: "pricing-table" as const,
              id: oldState.id,
              displayName,
              area: oldState.area,
              sampleFileRows: oldState.sampleFileRows,
              priceFormat,
              rateFormat,
            };
          }
          case "headerless-pricing-table": {
            const displayName =
              oldState.kind === "check" ? "" : oldState.displayName;
            const priceFormat =
              oldState.kind === "check" ? "absolute" : oldState.priceFormat;
            const rateFormat =
              oldState.kind === "check" ? "percent" : oldState.rateFormat;

            return {
              kind: "headerless-pricing-table" as const,
              id: oldState.id,
              displayName,
              area: oldState.area,
              sampleFileRows: oldState.sampleFileRows,
              priceFormat,
              rateFormat,
              columns: newObjectListState([]),
            };
          }
        }
      },
      [],
    );

    const setDisplayName: Setter<string> = useSetter(
      setState,
      (oldState, transform) => {
        if (oldState.kind === "check") {
          return oldState;
        }

        return {
          ...oldState,
          displayName: transform(oldState.displayName),
        };
      },
      [],
    );
    const handleDisplayNameChange = useTargetValue(setDisplayName);
    const setPriceFormat: Setter<T.MortgagePriceFormat> = useSetter(
      setState,
      (oldState, transform) => {
        if (oldState.kind === "check") {
          return oldState;
        }

        return {
          ...oldState,
          priceFormat: transform(oldState.priceFormat),
        };
      },
      [],
    );
    const setRateFormat: Setter<T.MortgageRateFormat> = useSetter(
      setState,
      (oldState, transform) => {
        if (oldState.kind === "check") {
          return oldState;
        }

        return {
          ...oldState,
          rateFormat: transform(oldState.rateFormat),
        };
      },
      [],
    );
    const setColumns: Setter<ObjectListState<PricingTableColumnState>> =
      useSetter(
        setState,
        (oldState, transform) => {
          if (oldState.kind !== "headerless-pricing-table") {
            return oldState;
          }

          return {
            ...oldState,
            columns: transform(oldState.columns),
          };
        },
        [],
      );

    const samplePriceScenarios = useMemo(() => {
      try {
        switch (state.kind) {
          case "check":
            return null;
          case "pricing-table":
            return parsePricingTable(
              state.sampleFileRows,
              state.priceFormat,
              state.rateFormat,
            );
          case "headerless-pricing-table": {
            const columns = state.columns.objects.map(
              convertStateToPricingTableColumn,
            );

            const requiredColumnCount = Math.max(
              ...state.sampleFileRows.map((row) => row.length),
            );
            if (columns.length < requiredColumnCount) {
              throw new UiValidationError("Too few column definitions");
            }

            return parseHeaderlessPricingTable(
              "columns",
              columns,
              state.sampleFileRows,
              state.priceFormat,
              state.rateFormat,
            );
          }
        }
      } catch (err) {
        if (err instanceof UiValidationError) {
          newrelic.noticeError(err);
          return err;
        }

        throw err;
      }
    }, [state]);

    return (
      <Box display="flex">
        <Box flex="1">
          <Box>
            <div
              style={{
                marginBottom: "5px",
                marginLeft: "5px",
                fontSize: "16px",
              }}
            >
              Rate sheet page: {state.area.page}
            </div>
            <Dropdown
              label="Action"
              margin="dense"
              options={PDF_ITEM_KINDS}
              getOptionLabel={getPdfItemKindName}
              value={state.kind}
              setValue={setKind}
            />
          </Box>
          {state.kind !== "check" && (
            <Box>
              <TextField
                label="Table Name"
                margin="dense"
                fullWidth
                variant="outlined"
                value={state.displayName}
                error={state.displayName.trim() === ""}
                onChange={handleDisplayNameChange}
              />
            </Box>
          )}
          {state.kind !== "check" && (
            <MortgagePriceFormatSelector
              state={state.priceFormat}
              setState={setPriceFormat}
            />
          )}
          {state.kind !== "check" && (
            <MortgageRateFormatSelector
              state={state.rateFormat}
              setState={setRateFormat}
            />
          )}
          {state.kind === "headerless-pricing-table" && (
            <PricingTableColumnsEditor
              state={state.columns}
              setState={setColumns}
              orientation={orientation}
            />
          )}
        </Box>
        <Box pl={2} flex="1">
          <Box my={2} fontSize={18}>
            Selected cells
          </Box>
          <Box>
            <CellRangePreview rows={state.sampleFileRows} />
          </Box>
        </Box>
        {samplePriceScenarios && (
          <Box pl={2} flex="1">
            <Box my={2} fontSize={18}>
              Preview
            </Box>
            {samplePriceScenarios instanceof UiValidationError ? (
              <Box>Error extracting table: {samplePriceScenarios.message}</Box>
            ) : (
              <Box>
                <PricingTableViewer prices={samplePriceScenarios} />
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  },
);

const SpreadsheetFileStructureEditor = React.memo(
  ({
    state,
    setState,
    groupBySheet,
  }: {
    state: SpreadsheetFileStructureState;
    setState: Setter<SpreadsheetFileStructureState>;
    groupBySheet: () => void;
  }) => {
    const setItems = usePropertySetter(setState, "items");

    const getItemLabel = useCallback(
      (item: SpreadsheetRateSheetItemState) => {
        switch (item.kind) {
          case "check": {
            try {
              const range = convertStateToSpreadsheetRange(item.area);
              const sampleFileRows = readSpreadsheetRange(
                state.sampleSpreadsheet,
                range,
              );

              if (sampleFileRows.length === 0 || !sampleFileRows[0][0]) {
                return <>{`Check: --`}</>;
              }

              return <>{`Check: ${sampleFileRows[0][0]}`}</>;
            } catch (err) {
              newrelic.noticeError(getErrorMessage(err));
              return <>{`Check: --`}</>;
            }
          }
          case "pricing-table":
          case "headerless-pricing-table":
            return <>{`Table: ${item.displayName || "--"}`}</>;
          case "unpivoted-pricing-tables":
            return <>Unpivoted Pricing Table</>;
        }
      },
      [state.sampleSpreadsheet],
    );

    const makeItemEditor = useCallback(
      ({
        value,
        setValue,
      }: {
        value: SpreadsheetRateSheetItemState;
        setValue: Setter<SpreadsheetRateSheetItemState>;
      }) => (
        <SpreadsheetItemEditor
          state={value}
          sampleSpreadsheet={state.sampleSpreadsheet}
          setState={setValue}
        />
      ),
      [state.sampleSpreadsheet],
    );

    const validateItem = useCallback(
      (item: SpreadsheetRateSheetItemState) =>
        validateSpreadsheetItem(state.sampleSpreadsheet, item),
      [state.sampleSpreadsheet],
    );

    return (
      <>
        <Button
          style={{ marginBottom: "4px" }}
          variant="outlined"
          onClick={() => groupBySheet()}
        >
          Reorder Sheets
        </Button>
        <ObjectListEditor
          height="100%"
          state={state.items}
          additionalButtons={[
            {
              name: "New",
              icon: <AddIcon />,
              onClick: (callbacks) =>
                callbacks.addObject(newSpreadsheetItemState()),
            },
          ]}
          getObjectLabel={getItemLabel}
          makeObjectEditor={makeItemEditor}
          validateObject={validateItem}
          setState={setItems}
          emptyText="Click &ldquo;New&rdquo; to insert a new item into this list"
        />
      </>
    );
  },
);

function columnCount(sampleDataInRange: string[][]): number {
  return Math.max(...sampleDataInRange.map((row) => row.length));
}

const SpreadsheetItemEditor = React.memo(
  ({
    state,
    sampleSpreadsheet,
    setState,
  }: {
    state: SpreadsheetRateSheetItemState;
    sampleSpreadsheet: Spreadsheet;
    setState: Setter<SpreadsheetRateSheetItemState>;
  }) => {
    const setKind: Setter<SpreadsheetRateSheetItemState["kind"]> = useSetter(
      setState,
      (oldState, transform) => {
        const kind = transform(state.kind);

        switch (kind) {
          case "check":
            return {
              kind: "check" as const,
              id: oldState.id,
              area: oldState.area,
              rows: readSpreadsheetRangeState(sampleSpreadsheet, oldState.area),
            };
          case "pricing-table": {
            const displayName: string =
              oldState.kind === "check" ||
              oldState.kind === "unpivoted-pricing-tables"
                ? ""
                : oldState.displayName;
            const priceFormat =
              oldState.kind === "check" ? "absolute" : oldState.priceFormat;
            const rateFormat =
              oldState.kind === "check" ? "percent" : oldState.rateFormat;

            return {
              kind: "pricing-table" as const,
              id: oldState.id,
              displayName,
              area: oldState.area,
              priceFormat,
              rateFormat,
            };
          }
          case "headerless-pricing-table": {
            const displayName =
              oldState.kind === "check" ||
              oldState.kind === "unpivoted-pricing-tables"
                ? ""
                : oldState.displayName;
            const priceFormat =
              oldState.kind === "check" ? "absolute" : oldState.priceFormat;
            const rateFormat =
              oldState.kind === "check" ? "percent" : oldState.rateFormat;
            return {
              kind: "headerless-pricing-table" as const,
              id: oldState.id,
              displayName,
              area: oldState.area,
              priceFormat,
              rateFormat,
              orientation: "columns" as const,
              rowsOrColumns: newObjectListState([]),
            };
          }
          case "unpivoted-pricing-tables": {
            const priceFormat =
              oldState.kind === "check" ? "absolute" : oldState.priceFormat;
            const rateFormat =
              oldState.kind === "check" ? "percent" : oldState.rateFormat;
            const rateColumn =
              oldState.kind === "unpivoted-pricing-tables"
                ? oldState.rateColumn
                : 0;
            const priceColumn =
              oldState.kind === "unpivoted-pricing-tables"
                ? oldState.priceColumn
                : 1;
            const lockColumn: number =
              oldState.kind === "unpivoted-pricing-tables"
                ? oldState.lockColumn
                : 2;
            const productNameColumn =
              oldState.kind === "unpivoted-pricing-tables"
                ? oldState.productNameColumn
                : 3;
            const hasHeader =
              oldState.kind === "unpivoted-pricing-tables"
                ? oldState.hasHeader
                : true;
            return {
              kind: "unpivoted-pricing-tables" as const,
              priceFormat,
              rateFormat,
              area: oldState.area,
              id: oldState.id,
              rateColumn,
              priceColumn,
              lockColumn,
              productNameColumn,
              hasHeader,
            };
          }
        }
      },
      [sampleSpreadsheet],
    );

    const setDisplayName: Setter<string> = useSetter(
      setState,
      (oldState, transform) => {
        if (
          oldState.kind === "check" ||
          oldState.kind === "unpivoted-pricing-tables"
        ) {
          return oldState;
        }

        return {
          ...oldState,
          displayName: transform(oldState.displayName),
        };
      },
      [],
    );
    const handleDisplayNameChange = useTargetValue(setDisplayName);
    const setArea: Setter<SpreadsheetRangeState> = useSetter(
      setState,
      (oldState, transform) => {
        const area = transform(oldState.area);

        if (oldState.kind === "check") {
          const rows = readSpreadsheetRangeState(sampleSpreadsheet, area);
          return { ...oldState, area, rows };
        }

        return { ...oldState, area };
      },
      [sampleSpreadsheet],
    );
    const setOrientation: Setter<T.PricingTableOrientation> = useSetter(
      setState,
      (oldState, transformOrientation) => {
        if (oldState.kind !== "headerless-pricing-table") {
          return { ...oldState };
        } else {
          return {
            ...oldState,
            orientation: transformOrientation(oldState.orientation),
          };
        }
      },
      [],
    );
    const setRateColumn: Setter<number> = useSetter(
      setState,
      (oldState, transform) => {
        return oldState.kind === "unpivoted-pricing-tables"
          ? { ...oldState, rateColumn: transform(oldState.rateColumn) }
          : { ...oldState };
      },
      [],
    );
    const setPriceColumn: Setter<number> = useSetter(
      setState,
      (oldState, transform) => {
        return oldState.kind === "unpivoted-pricing-tables"
          ? { ...oldState, priceColumn: transform(oldState.priceColumn) }
          : { ...oldState };
      },
      [],
    );
    const setLockColumn: Setter<number> = useSetter(
      setState,
      (oldState, transform) => {
        return oldState.kind === "unpivoted-pricing-tables"
          ? { ...oldState, lockColumn: transform(oldState.lockColumn) }
          : { ...oldState };
      },
      [],
    );
    const setProductNameColumn: Setter<number> = useSetter(
      setState,
      (oldState, transform) => {
        return oldState.kind === "unpivoted-pricing-tables"
          ? {
              ...oldState,
              productNameColumn: transform(oldState.productNameColumn),
            }
          : { ...oldState };
      },
      [],
    );
    const setHasHeader: Setter<number> = useSetter(
      setState,
      (oldState, transform) => {
        return oldState.kind === "unpivoted-pricing-tables"
          ? {
              ...oldState,
              // Using hack to convert state between number and boolean and back since
              // Dropdown requires strings or numbers.
              hasHeader: transform(oldState.hasHeader ? 1 : 0) ? true : false,
            }
          : { ...oldState };
      },
      [],
    );
    const setPriceFormat: Setter<T.MortgagePriceFormat> = useSetter(
      setState,
      (oldState, transform) => {
        if (oldState.kind === "check") {
          return oldState;
        }

        return {
          ...oldState,
          priceFormat: transform(oldState.priceFormat),
        };
      },
      [],
    );
    const setRateFormat: Setter<T.MortgageRateFormat> = useSetter(
      setState,
      (oldState, transform) => {
        if (oldState.kind === "check") {
          return oldState;
        }

        return {
          ...oldState,
          rateFormat: transform(oldState.rateFormat),
        };
      },
      [],
    );
    const setColumns: Setter<ObjectListState<PricingTableColumnState>> =
      useSetter(
        setState,
        (oldState, transform) => {
          if (oldState.kind !== "headerless-pricing-table") {
            return oldState;
          }

          return {
            ...oldState,
            rowsOrColumns: transform(oldState.rowsOrColumns),
          };
        },
        [],
      );
    const sampleDataInRange = useMemo(
      () =>
        state.kind === "unpivoted-pricing-tables"
          ? readWholeSheet(sampleSpreadsheet, state.area).slice(0, 1000)
          : readSpreadsheetRangeState(sampleSpreadsheet, state.area),
      [sampleSpreadsheet, state.area, state.kind],
    );
    const samplePriceScenarios = useMemo(() => {
      if (!sampleDataInRange) {
        return null;
      }

      try {
        switch (state.kind) {
          case "check":
            return null;
          case "unpivoted-pricing-tables":
            return parseUnpivotedTable(sampleDataInRange, state);
          case "pricing-table":
            return parsePricingTable(
              sampleDataInRange,
              state.priceFormat,
              state.rateFormat,
            );
          case "headerless-pricing-table": {
            const rowsOrColumns = state.rowsOrColumns.objects.map(
              convertStateToPricingTableColumn,
            );

            switch (state.orientation) {
              case "columns":
                const requiredColumnCount = Math.max(
                  ...sampleDataInRange.map((row) => row.length),
                );
                if (rowsOrColumns.length < requiredColumnCount) {
                  throw new UiValidationError("Too few column definitions");
                }
                break;
              case "rows":
                const requiredRowCount = sampleDataInRange.length;
                if (rowsOrColumns.length < requiredRowCount) {
                  throw new UiValidationError("Too few row definitions");
                }
                break;
            }

            return parseHeaderlessPricingTable(
              state.orientation,
              rowsOrColumns,
              sampleDataInRange,
              state.priceFormat,
              state.rateFormat,
            );
          }
        }
      } catch (err) {
        if (err instanceof UiValidationError) {
          newrelic.noticeError(err);
          return err;
        }

        throw err;
      }
    }, [sampleDataInRange, state]);
    const dropDownColors = new Map<string, string>();
    const columnColors = new Map<number, string>();
    const columnOptions: number[] = [];
    if (state.kind === "unpivoted-pricing-tables") {
      if (sampleDataInRange) {
        for (let i = 0; i < columnCount(sampleDataInRange); i++) {
          columnOptions.push(i);
        }
      }
      dropDownColors.set("rate", "lightyellow");
      dropDownColors.set("price", "pink");
      dropDownColors.set("lock", "lightblue");
      dropDownColors.set("product", "lightgreen");
      columnColors.set(state.rateColumn, dropDownColors.get("rate")!);
      columnColors.set(state.priceColumn, dropDownColors.get("price")!);
      columnColors.set(state.lockColumn, dropDownColors.get("lock")!);
      columnColors.set(state.productNameColumn, dropDownColors.get("product")!);
    }
    return (
      <Box display="flex">
        <Box flex="1">
          <Box>
            <Dropdown
              label="Action"
              margin="dense"
              options={SPREADSHEET_ITEM_KINDS}
              getOptionLabel={getSpreadsheetItemKindName}
              value={state.kind}
              setValue={setKind}
            />
          </Box>
          {state.kind !== "check" && state.kind !== "unpivoted-pricing-tables" && (
            <Box>
              <TextField
                label="Table Name"
                margin="dense"
                fullWidth
                variant="outlined"
                value={state.displayName}
                error={state.displayName.trim() === ""}
                onChange={handleDisplayNameChange}
              />
            </Box>
          )}
          <SpreadsheetRangeEditor
            state={state.area}
            sampleSpreadsheet={sampleSpreadsheet}
            setState={setArea}
            showRange={state.kind !== "unpivoted-pricing-tables"}
          />
          {state.kind !== "check" && (
            <MortgagePriceFormatSelector
              state={state.priceFormat}
              setState={setPriceFormat}
            />
          )}
          {state.kind !== "check" && (
            <MortgageRateFormatSelector
              state={state.rateFormat}
              setState={setRateFormat}
            />
          )}
          {state.kind === "unpivoted-pricing-tables" && (
            <>
              <Dropdown
                label="Rates Column"
                margin="dense"
                options={columnOptions}
                getOptionLabel={(n) => `Rates on Column ${n}`}
                value={state.rateColumn}
                setValue={setRateColumn}
                backgroundColor={dropDownColors.get("rate")}
              />
              <Dropdown
                label="Prices Column"
                margin="dense"
                options={columnOptions}
                getOptionLabel={(n) => `Prices on Column ${n}`}
                value={state.priceColumn}
                setValue={setPriceColumn}
                backgroundColor={dropDownColors.get("price")}
              />
              <Dropdown
                label="Locks Column"
                margin="dense"
                options={columnOptions}
                getOptionLabel={(n) => `Locks on Column ${n}`}
                value={state.lockColumn}
                setValue={setLockColumn}
                backgroundColor={dropDownColors.get("lock")}
              />
              <Dropdown
                label="Products Column"
                margin="dense"
                options={columnOptions}
                getOptionLabel={(n) => `Products on Column ${n}`}
                value={state.productNameColumn}
                setValue={setProductNameColumn}
                backgroundColor={dropDownColors.get("product")}
              />
              <Dropdown
                label="Has Header"
                margin="dense"
                options={[0, 1]}
                getOptionLabel={(b) => `${b ? "Has Header" : "No Header"}`}
                value={state.hasHeader ? 1 : 0}
                setValue={setHasHeader}
              />
            </>
          )}
          {state.kind === "headerless-pricing-table" && (
            <>
              <Dropdown
                label="Orientation"
                margin="dense"
                options={PRICING_TABLE_ORIENTATIONS}
                getOptionLabel={getPricingTableOrientationName}
                value={state.orientation}
                setValue={setOrientation}
              />
              <PricingTableColumnsEditor
                state={state.rowsOrColumns}
                setState={setColumns}
                orientation={state.orientation}
              />
            </>
          )}
          {sampleDataInRange && (
            <Box pl={2} flex="1">
              <Box my={2} fontSize={18}>
                Selected cells
              </Box>
              <Box>
                <CellRangePreview
                  rows={sampleDataInRange}
                  columnColors={columnColors}
                />
              </Box>
            </Box>
          )}
        </Box>
        {samplePriceScenarios && (
          <Box pl={2} flex="1">
            <Box my={2} fontSize={18}>
              Preview
            </Box>
            {samplePriceScenarios instanceof UiValidationError ? (
              <Box>Error extracting table: {samplePriceScenarios.message}</Box>
            ) : (
              <Box>
                <PricingTableViewer prices={samplePriceScenarios} />
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  },
);

const SpreadsheetRangeEditor = React.memo(
  ({
    state,
    sampleSpreadsheet,
    setState,
    showRange,
  }: {
    state: SpreadsheetRangeState;
    sampleSpreadsheet: Spreadsheet;
    setState: Setter<SpreadsheetRangeState>;
    showRange: boolean;
  }) => {
    const setSheetName = usePropertySetter(setState, "sheetName");
    const setTopLeftCellAddress = usePropertySetter(
      setState,
      "topLeftCellAddress",
    );
    const handleTopLeftChange = useTargetValue(setTopLeftCellAddress);
    const setBottomRightCellAddress = usePropertySetter(
      setState,
      "bottomRightCellAddress",
    );
    const handleBottomRightChange = useTargetValue(setBottomRightCellAddress);

    const sheetNames = useMemo(
      () => sampleSpreadsheet.sheets.map((sheet) => sheet.name),
      [sampleSpreadsheet.sheets],
    );

    useEffect(() => {
      if (sheetNames.length === 1) {
        setSheetName(sheetNames[0]);
      }
    }, [sheetNames, setSheetName]);

    const getSheetName = useCallback((sheetName: string) => sheetName, []);

    return (
      <Box>
        <SearchableDropdown
          label="Worksheet"
          margin="dense"
          value={state.sheetName}
          options={sheetNames}
          getOptionLabel={getSheetName}
          error={state.sheetName === null}
          setValue={setSheetName}
        />
        {showRange && (
          <Box display="flex">
            <Box flex="1">
              <TextField
                label="From cell address (top-left)"
                margin="dense"
                fullWidth
                variant="outlined"
                value={state.topLeftCellAddress}
                error={state.topLeftCellAddress.trim() === ""}
                onChange={handleTopLeftChange}
              />
            </Box>
            <Box pl={2} flex="1">
              <TextField
                label="To cell address (bottom-right)"
                margin="dense"
                fullWidth
                variant="outlined"
                value={state.bottomRightCellAddress}
                onChange={handleBottomRightChange}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  },
);

const usePriceFormatStyles = makeStyles((t) =>
  createStyles({
    dropdown: {
      width: "100%",
    },
  }),
);

const MortgagePriceFormatSelector = React.memo(
  ({
    state,
    setState,
  }: {
    state: T.MortgagePriceFormat;
    setState: Setter<T.MortgagePriceFormat>;
  }) => {
    const C = usePriceFormatStyles();

    const options: T.MortgagePriceFormat[] = useMemo(
      () => ["absolute", "relative"],
      [],
    );
    const getOptionLabel = useCallback((option: T.MortgagePriceFormat) => {
      switch (option) {
        case "absolute":
          return "Absolute (par = 100)";
        case "relative":
          return "Relative (par = 0)";
      }
    }, []);

    return (
      <Dropdown
        className={C.dropdown}
        label="Price Format"
        margin="dense"
        value={state}
        options={options}
        getOptionLabel={getOptionLabel}
        setValue={setState}
      />
    );
  },
);
const MortgageRateFormatSelector = React.memo(
  ({
    state,
    setState,
  }: {
    state: T.MortgageRateFormat;
    setState: Setter<T.MortgageRateFormat>;
  }) => {
    const C = usePriceFormatStyles();

    const options: T.MortgageRateFormat[] = useMemo(
      () => ["percent", "decimal"],
      [],
    );
    const getOptionLabel = useCallback((option: T.MortgageRateFormat) => {
      switch (option) {
        case "percent":
          return "Percent (One percent is 1)";
        case "decimal":
          return "Decimal (One percent is 0.01)";
      }
    }, []);

    return (
      <Dropdown
        className={C.dropdown}
        label="Rate Format"
        margin="dense"
        value={state}
        options={options}
        getOptionLabel={getOptionLabel}
        setValue={setState}
      />
    );
  },
);

const PricingTableColumnsEditor = React.memo(
  ({
    state,
    setState,
    orientation,
  }: {
    state: ObjectListState<PricingTableColumnState>;
    setState: Setter<ObjectListState<PricingTableColumnState>>;
    orientation: T.PricingTableOrientation;
  }) => {
    const getColumnLabel = useCallback((column: PricingTableColumnState) => {
      if (column.kind === null) {
        return <>{"<New Column>"}</>;
      }

      switch (column.kind) {
        case "interest-rate":
          return <>{"Interest Rate"}</>;
        case "ignore":
          return <>{"Ignore"}</>;
        case "price":
          return (
            <>{`Price (${
              column.rateLockPeriodDays === null
                ? "unknown"
                : column.rateLockPeriodDays
            } days)`}</>
          );
      }
    }, []);

    const makeColumnEditor = useCallback(
      ({
        value,
        setValue,
      }: {
        value: PricingTableColumnState;
        setValue: Setter<PricingTableColumnState>;
      }) => (
        <PricingTableColumnEditor
          state={value}
          setState={setValue}
          orientation={orientation}
        />
      ),
      [orientation],
    );

    return (
      <ObjectListEditor
        state={state}
        additionalButtons={[
          {
            name: "New",
            icon: <AddIcon />,
            onClick: (callbacks) =>
              callbacks.addObject(newPricingTableColumnState()),
          },
        ]}
        getObjectLabel={getColumnLabel}
        makeObjectEditor={makeColumnEditor}
        setState={setState}
        emptyText="Click &ldquo;New&rdquo; to insert a new item into this list"
      />
    );
  },
);

const usePricingTableColumnEditorStyles = makeStyles((t) =>
  createStyles({
    columnType: {
      width: "100%",
    },
  }),
);

const PricingTableColumnEditor = React.memo(
  ({
    state,
    setState,
    orientation,
  }: {
    state: PricingTableColumnState;
    setState: Setter<PricingTableColumnState>;
    orientation: T.PricingTableOrientation;
  }) => {
    const C = usePricingTableColumnEditorStyles();

    const setKind = usePropertySetter(setState, "kind");
    const setRateLockPeriodDays = usePropertySetter(
      setState,
      "rateLockPeriodDays",
    );

    const handleLockPeriodChange = useCallback(
      (event: { target: { value: string } }) => {
        if (event.target.value.trim() === "") {
          setRateLockPeriodDays(null);
          return;
        }

        const parsed = +event.target.value;
        setRateLockPeriodDays(isNaN(parsed) ? null : parsed);
      },
      [setRateLockPeriodDays],
    );

    return (
      <>
        <Dropdown
          className={C.columnType}
          label={orientation === "columns" ? "Column Type" : "Row Type"}
          margin="dense"
          value={state.kind}
          options={PRICING_TABLE_COLUMN_KINDS}
          getOptionLabel={getPricingTableColumnKindName}
          setValue={setKind}
        />
        {state.kind === "price" && (
          <TextField
            label="Rate Lock Period Days"
            margin="dense"
            variant="outlined"
            value={
              state.rateLockPeriodDays === null
                ? ""
                : String(state.rateLockPeriodDays) + ""
            }
            error={
              state.rateLockPeriodDays === null || state.rateLockPeriodDays <= 0
            }
            onChange={handleLockPeriodChange}
          />
        )}
      </>
    );
  },
);

const useCellRangePreviewStyles = makeStyles((t) =>
  createStyles({
    table: {
      borderCollapse: "collapse",

      "& td": {
        border: "1px solid #666",
        padding: "2px 4px",
      },
    },
  }),
);

const CellRangePreview = React.memo(
  ({
    rows,
    columnColors,
  }: {
    rows: readonly string[][];
    columnColors?: Map<number, string>;
  }) => {
    const C = useCellRangePreviewStyles();
    return (
      <table className={C.table}>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cellText, columnIndex) => {
                if (columnColors) {
                  const color = columnColors.get(columnIndex);
                  return (
                    <td key={columnIndex} style={{ backgroundColor: color }}>
                      {cellText}
                    </td>
                  );
                } else {
                  return <td key={columnIndex}>{cellText}</td>;
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  },
);

export function convertPdfFileStructureToState(
  structure: T.RateSheetFileStructure.Pdf,
  sampleFile: File,
  blobId: T.BlobId,
  sampleFileOutputs: string[][][],
): PdfFileStructureState {
  return {
    kind: "pdf",
    sampleFile,
    blobId,
    items: newObjectListState(
      structure.items.map((item, itemIndex) =>
        convertPdfItemToState(item, sampleFileOutputs[itemIndex]),
      ),
    ),
  };
}

function convertStateToPdfFileStructure(
  state: PdfFileStructureState,
): T.RateSheetFileStructure.Pdf {
  const items = state.items.objects.map(convertStateToPdfItem);

  const displayNames = items.flatMap((i) =>
    i.kind === "check" ? [] : [i.displayName.toLowerCase()],
  );
  checkDuplicateDisplayNames(displayNames);

  return {
    kind: "pdf",
    items,
  };
}

function convertPdfItemToState(
  item: T.PdfRateSheetItem,
  sampleFileRows: string[][],
): PdfRateSheetItemState {
  switch (item.kind) {
    case "check":
      return convertPdfCheckItemToState(item, sampleFileRows);
    case "pricing-table":
      return convertPdfPricingTableItemToState(item, sampleFileRows);
    case "headerless-pricing-table":
      return convertPdfHeaderlessPricingTableItemToState(item, sampleFileRows);
  }
}

function convertStateToPdfItem(
  state: PdfRateSheetItemState,
): T.PdfRateSheetItem {
  switch (state.kind) {
    case "check":
      return convertStateToPdfCheckItem(state);
    case "pricing-table":
      return convertStateToPdfPricingTableItem(state);
    case "headerless-pricing-table":
      return convertStateToPdfHeaderlessPricingTableItem(state);
  }
}

const validatePdfItem = validation(convertStateToPdfItem);

function convertPdfCheckItemToState(
  item: T.PdfRateSheetItem.Check,
  sampleFileRows: string[][],
): PdfRateSheetCheckItemState {
  return {
    kind: "check",
    id: item.id,
    area: item.area,
    rows: item.rows,
    sampleFileRows,
  };
}

function convertStateToPdfCheckItem(
  state: PdfRateSheetCheckItemState,
): T.PdfRateSheetItem.Check {
  if (!_.isEqual(state.rows, state.sampleFileRows)) {
    throw new UiValidationError(
      "Check action failed: data from sample file does not match",
    );
  }

  return {
    kind: "check",
    id: state.id,
    area: state.area,
    rows: state.rows,
  };
}

function convertPdfPricingTableItemToState(
  item: T.PdfRateSheetItem.PricingTable,
  sampleFileRows: string[][],
): PdfRateSheetPricingTableItemState {
  return {
    kind: "pricing-table",
    id: item.id,
    displayName: item.displayName,
    area: item.area,
    priceFormat: item.priceFormat,
    rateFormat: item.rateFormat,
    sampleFileRows,
  };
}

function convertStateToPdfPricingTableItem(
  state: PdfRateSheetPricingTableItemState,
): T.PdfRateSheetItem.PricingTable {
  if (state.displayName.trim() === "") {
    throw new UiValidationError("Pricing table display name required");
  }

  // Require that pricing table in sample file actually parses
  try {
    parsePricingTable(
      state.sampleFileRows,
      state.priceFormat,
      state.rateFormat,
    );
  } catch (err) {
    if (err instanceof UiValidationError) {
      newrelic.noticeError(
        getErrorMessage("Pricing table in sample file failed to parse: "),
      );
      newrelic.noticeError(err);
      throw new UiValidationError(
        "Pricing table in sample file failed to parse: " + err.message,
      );
    }

    throw err;
  }

  return {
    kind: "pricing-table",
    id: state.id,
    displayName: state.displayName,
    area: state.area,
    priceFormat: state.priceFormat,
    rateFormat: state.rateFormat,
  };
}

function convertPdfHeaderlessPricingTableItemToState(
  item: T.PdfRateSheetItem.HeaderlessPricingTable,
  sampleFileRows: string[][],
): PdfRateSheetHeaderlessPricingTableItemState {
  return {
    kind: "headerless-pricing-table",
    id: item.id,
    displayName: item.displayName,
    area: item.area,
    priceFormat: item.priceFormat,
    rateFormat: item.rateFormat,
    columns: newObjectListState(
      item.columns.map(convertPricingTableColumnToState),
    ),
    sampleFileRows,
  };
}

function convertStateToPdfHeaderlessPricingTableItem(
  state: PdfRateSheetHeaderlessPricingTableItemState,
): T.PdfRateSheetItem.HeaderlessPricingTable {
  if (state.displayName.trim() === "") {
    throw new UiValidationError("Pricing table display name required");
  }

  const columns = state.columns.objects.map(convertStateToPricingTableColumn);

  if (columns.filter((c) => c.kind === "interest-rate").length !== 1) {
    throw new UiValidationError(
      "Pricing table must be configured to have exactly one interest rate column",
    );
  }

  if (!columns.some((c) => c.kind === "price")) {
    throw new UiValidationError(
      "Pricing table must be configured to have at least one price column",
    );
  }

  // Require that pricing table in sample file actually parses
  try {
    parseHeaderlessPricingTable(
      "columns",
      columns,
      state.sampleFileRows,
      state.priceFormat,
      state.rateFormat,
    );
  } catch (err) {
    if (err instanceof UiValidationError) {
      newrelic.noticeError(
        getErrorMessage("Pricing table in sample file failed to parse: "),
      );
      newrelic.noticeError(err);
      throw new UiValidationError(
        "Pricing table in sample file failed to parse: " + err.message,
      );
    }

    throw err;
  }

  return {
    kind: "headerless-pricing-table",
    id: state.id,
    displayName: state.displayName,
    area: state.area,
    priceFormat: state.priceFormat,
    rateFormat: state.rateFormat,
    columns,
  };
}

function convertSpreadsheetFileStructureToState(
  structure: T.RateSheetFileStructure.Spreadsheet,
  sampleFile: File,
  sampleSpreadsheet: Spreadsheet,
): SpreadsheetFileStructureState {
  return {
    kind: "spreadsheet",
    sampleFile,
    sampleSpreadsheet,
    items: newObjectListState(
      structure.items.map((item, itemIndex) =>
        convertSpreadsheetItemToState(sampleSpreadsheet, item),
      ),
    ),
  };
}

function convertStateToSpreadsheetFileStructure(
  state: SpreadsheetFileStructureState,
): T.RateSheetFileStructure.Spreadsheet {
  const items = state.items.objects.map((item) =>
    convertStateToSpreadsheetItem(state.sampleSpreadsheet, item),
  );
  const displayNames = items.flatMap((i) =>
    i.kind === "check" || i.kind === "unpivoted-pricing-tables"
      ? []
      : [i.displayName],
  );

  checkDuplicateDisplayNames(displayNames);

  return {
    kind: "spreadsheet",
    items,
  };
}

function checkDuplicateDisplayNames(displayNames: string[]) {
  // First check with case preserved for easy search on front end.
  const names = IList(displayNames);
  const nameCounts = names.countBy((n) => n);

  for (const [displayName, count] of nameCounts.toArray()) {
    if (count > 1) {
      throw new UiValidationError(
        "Duplicate pricing table display name: " + displayName,
      );
    }
  }

  // Then check in lower case for more thorough error prevention.
  const lowerCaseNames = IList(displayNames.map((s) => s.toLowerCase()));
  const lowerCaseNameCounts = lowerCaseNames.countBy((n) => n);
  for (const [displayName, count] of lowerCaseNameCounts.toArray()) {
    if (count > 1) {
      throw new UiValidationError(
        "Duplicate pricing table display name (same string but different cases): " +
          displayName,
      );
    }
  }
}

function newSpreadsheetItemState(): SpreadsheetRateSheetItemState {
  return {
    kind: "check",
    id: generateSafeId() as T.SpreadsheetRateSheetItemId,
    area: newSpreadsheetRangeState(),
    rows: null,
  };
}

function newSpreadsheetRangeState(): SpreadsheetRangeState {
  return {
    sheetName: null,
    topLeftCellAddress: "",
    bottomRightCellAddress: "",
  };
}

function convertSpreadsheetItemToState(
  sampleSpreadsheet: Spreadsheet,
  item: T.SpreadsheetRateSheetItem,
): SpreadsheetRateSheetItemState {
  switch (item.kind) {
    case "check":
      return convertSpreadsheetCheckItemToState(sampleSpreadsheet, item);
    case "pricing-table":
      return convertSpreadsheetPricingTableItemToState(sampleSpreadsheet, item);
    case "unpivoted-pricing-tables":
      return convertUnpivotedPricingTablesItemToState(sampleSpreadsheet, item);
    case "headerless-pricing-table":
      return convertSpreadsheetHeaderlessPricingTableItemToState(
        sampleSpreadsheet,
        item,
      );
  }
}

function convertStateToUnpivotedPricingTablesItem(
  sampleSpreadsheet: Spreadsheet,
  {
    kind,
    priceFormat,
    rateFormat,
    id,
    rateColumn,
    priceColumn,
    lockColumn,
    productNameColumn,
    hasHeader,
    area,
  }: SpreadsheetUnpivotedPricingTablesItemState,
): T.SpreadsheetRateSheetItem.UnpivotedPricingTables {
  return {
    id,
    kind,
    priceFormat,
    rateFormat,
    rateColumn,
    priceColumn,
    lockColumn,
    productNameColumn,
    hasHeader,
    sheetName: area.sheetName || "",
  };
}

function convertStateToSpreadsheetItem(
  sampleSpreadsheet: Spreadsheet,
  state: SpreadsheetRateSheetItemState,
): T.SpreadsheetRateSheetItem {
  switch (state.kind) {
    case "check":
      return convertStateToSpreadsheetCheckItem(sampleSpreadsheet, state);
    case "pricing-table":
      return convertStateToSpreadsheetPricingTableItem(
        sampleSpreadsheet,
        state,
      );
    case "headerless-pricing-table":
      return convertStateToSpreadsheetHeaderlessPricingTableItem(
        sampleSpreadsheet,
        state,
      );
    case "unpivoted-pricing-tables":
      return convertStateToUnpivotedPricingTablesItem(sampleSpreadsheet, state);
  }
}

function validateSpreadsheetItem(
  sampleSpreadsheet: Spreadsheet,
  state: SpreadsheetRateSheetItemState,
): UiValidationError | null {
  return getValidationError(() => {
    convertStateToSpreadsheetItem(sampleSpreadsheet, state);
  });
}

function convertUnpivotedPricingTablesItemToState(
  sampleSpreadsheet: Spreadsheet,
  {
    id,
    priceFormat,
    rateFormat,
    rateColumn,
    priceColumn,
    lockColumn,
    productNameColumn,
    hasHeader,
    sheetName,
  }: T.SpreadsheetRateSheetItem.UnpivotedPricingTables,
): SpreadsheetUnpivotedPricingTablesItemState {
  return {
    kind: "unpivoted-pricing-tables",
    id,
    area: {
      sheetName,
      topLeftCellAddress: "A1",
      bottomRightCellAddress: "G1000",
    },
    priceFormat,
    rateFormat,
    priceColumn,
    lockColumn,
    rateColumn,
    productNameColumn,
    hasHeader,
  };
}
function convertSpreadsheetCheckItemToState(
  sampleSpreadsheet: Spreadsheet,
  item: T.SpreadsheetRateSheetItem.Check,
): SpreadsheetRateSheetCheckItemState {
  return {
    kind: "check",
    id: item.id,
    area: convertSpreadsheetRangeToState(item.area),
    rows: item.rows,
  };
}

function cleanRows(rows: string[][]): string[][] {
  // posix / windows line ending convention changes shouldn't trigger check fails.
  // keep this function in sync with clean_rows on the rust side.
  return rows.map((row) => row.map((s) => s.replace("\r\n", "\n")));
}

function convertStateToSpreadsheetCheckItem(
  sampleSpreadsheet: Spreadsheet,
  state: SpreadsheetRateSheetCheckItemState,
): T.SpreadsheetRateSheetItem.Check {
  const area = convertStateToSpreadsheetRange(state.area);

  if (!state.rows) {
    throw new UiValidationError("Check region must be selected");
  }

  const actualRows = readSpreadsheetRange(sampleSpreadsheet, area);

  if (!_.isEqual(actualRows, state.rows)) {
    if (!_.isEqual(cleanRows(actualRows), cleanRows(state.rows))) {
      throw new UiValidationError(
        "Check action failed: data from sample file does not match",
      );
    }
  }

  return {
    kind: "check",
    id: state.id,
    area,
    rows: state.rows,
  };
}

function convertSpreadsheetPricingTableItemToState(
  sampleSpreadsheet: Spreadsheet,
  item: T.SpreadsheetRateSheetItem.PricingTable,
): SpreadsheetRateSheetPricingTableItemState {
  return {
    kind: "pricing-table",
    id: item.id,
    displayName: item.displayName,
    area: convertSpreadsheetRangeToState(item.area),
    priceFormat: item.priceFormat,
    rateFormat: item.rateFormat,
  };
}

function convertStateToSpreadsheetPricingTableItem(
  sampleSpreadsheet: Spreadsheet,
  state: SpreadsheetRateSheetPricingTableItemState,
): T.SpreadsheetRateSheetItem.PricingTable {
  if (state.displayName.trim() === "") {
    throw new UiValidationError("Pricing table display name required");
  }

  const area = convertStateToSpreadsheetRange(state.area);
  const sampleFileRows = readSpreadsheetRange(sampleSpreadsheet, area);

  // Require that pricing table in sample file actually parses
  try {
    parsePricingTable(sampleFileRows, state.priceFormat, state.rateFormat);
  } catch (err) {
    if (err instanceof UiValidationError) {
      newrelic.noticeError(
        getErrorMessage("Pricing table in sample file failed to parse: "),
      );
      newrelic.noticeError(err);
      throw new UiValidationError(
        "Pricing table in sample file failed to parse: " + err.message,
      );
    }

    throw err;
  }

  return {
    kind: "pricing-table",
    id: state.id,
    displayName: state.displayName,
    area,
    priceFormat: state.priceFormat,
    rateFormat: state.rateFormat,
  };
}

function convertSpreadsheetHeaderlessPricingTableItemToState(
  sampleSpreadsheet: Spreadsheet,
  item: T.SpreadsheetRateSheetItem.HeaderlessPricingTable,
): SpreadsheetRateSheetHeaderlessPricingTableItemState {
  return {
    kind: "headerless-pricing-table",
    id: item.id,
    displayName: item.displayName,
    area: convertSpreadsheetRangeToState(item.area),
    priceFormat: item.priceFormat,
    rateFormat: item.rateFormat,
    orientation: item.orientation,
    rowsOrColumns: newObjectListState(
      item.rowsOrColumns.map(convertPricingTableColumnToState),
    ),
  };
}

function convertStateToSpreadsheetHeaderlessPricingTableItem(
  sampleSpreadsheet: Spreadsheet,
  state: SpreadsheetRateSheetHeaderlessPricingTableItemState,
): T.SpreadsheetRateSheetItem.HeaderlessPricingTable {
  if (state.displayName.trim() === "") {
    throw new UiValidationError("Pricing table display name required");
  }

  const area = convertStateToSpreadsheetRange(state.area);
  const areaColumnCount = area.toColumnIndex - area.fromColumnIndex + 1;
  const areaRowCount = area.toRowIndex - area.fromRowIndex + 1;
  const sampleFileRows = readSpreadsheetRange(sampleSpreadsheet, area);

  if (
    state.orientation === "columns" &&
    areaColumnCount !== state.rowsOrColumns.objects.length
  ) {
    throw new UiValidationError(
      `The cell addresses specified cover ${areaColumnCount} column(s),` +
        ` but definitions for ${state.rowsOrColumns.objects.length} column(s) were provided.`,
    );
  }

  if (
    state.orientation === "rows" &&
    areaRowCount !== state.rowsOrColumns.objects.length
  ) {
    throw new UiValidationError(
      `The cell adresses specified cover ${areaRowCount} row(s),` +
        ` but definitions for ${state.rowsOrColumns.objects.length} row(s) were provided.`,
    );
  }

  const rowsOrColumns = state.rowsOrColumns.objects.map(
    convertStateToPricingTableColumn,
  );

  if (rowsOrColumns.filter((c) => c.kind === "interest-rate").length !== 1) {
    throw new UiValidationError(
      "Pricing table must be configured to have exactly one interest rate row/column",
    );
  }

  if (!rowsOrColumns.some((c) => c.kind === "price")) {
    throw new UiValidationError(
      "Pricing table must be configured to have at least one price row/column",
    );
  }

  // Require that pricing table in sample file actually parses
  try {
    parseHeaderlessPricingTable(
      state.orientation,
      rowsOrColumns,
      sampleFileRows,
      state.priceFormat,
      state.rateFormat,
    );
  } catch (err) {
    if (err instanceof UiValidationError) {
      newrelic.noticeError(
        getErrorMessage("Pricing table in sample file failed to parse: "),
      );
      newrelic.noticeError(err);
      throw new UiValidationError(
        "Pricing table in sample file failed to parse: " + err.message,
      );
    }

    throw err;
  }

  return {
    kind: "headerless-pricing-table",
    id: state.id,
    displayName: state.displayName,
    area,
    priceFormat: state.priceFormat,
    rateFormat: state.rateFormat,
    orientation: state.orientation,
    rowsOrColumns,
  };
}

function convertSpreadsheetRangeToState(
  range: T.SpreadsheetRange,
): SpreadsheetRangeState {
  return {
    sheetName: range.sheetName,
    topLeftCellAddress: XLSX.utils.encode_cell({
      r: range.fromRowIndex,
      c: range.fromColumnIndex,
    }),
    bottomRightCellAddress: XLSX.utils.encode_cell({
      r: range.toRowIndex,
      c: range.toColumnIndex,
    }),
  };
}

function convertStateToSpreadsheetRange(
  state: SpreadsheetRangeState,
): T.SpreadsheetRange {
  if (!state.sheetName) {
    throw new UiValidationError("Select a worksheet");
  }

  if (!state.topLeftCellAddress) {
    throw new UiValidationError(
      "Spreadsheet range requires a top-left cell address",
    );
  }

  const { r: fromRowIndex, c: fromColumnIndex } = parseCellAddress(
    state.topLeftCellAddress,
  );

  if (!state.bottomRightCellAddress.trim()) {
    // If second address not provided, return a single-cell range
    return {
      sheetName: state.sheetName,
      fromRowIndex,
      fromColumnIndex,
      toRowIndex: fromRowIndex,
      toColumnIndex: fromColumnIndex,
    };
  }

  const { r: toRowIndex, c: toColumnIndex } = parseCellAddress(
    state.bottomRightCellAddress,
  );

  if (toRowIndex < fromRowIndex) {
    throw new UiValidationError(
      '"To" cell address must not be above the "From" cell address',
    );
  }

  if (toColumnIndex < fromColumnIndex) {
    throw new UiValidationError(
      '"To" cell address must not be to the left of the "From" cell address',
    );
  }

  return {
    sheetName: state.sheetName,
    fromRowIndex,
    fromColumnIndex,
    toRowIndex,
    toColumnIndex,
  };
}

function parseCellAddress(addr: string): { r: number; c: number } {
  const result = XLSX.utils.decode_cell(addr.toUpperCase());

  if (result.c < 0 || result.r < 0) {
    throw new UiValidationError("Invalid cell address");
  }

  return result;
}

function readWholeSheet(
  spreadsheet: Spreadsheet,
  rangeState: SpreadsheetRangeState,
): string[][] {
  const sheetName = rangeState.sheetName || "";
  const sheet = sheetName
    ? spreadsheet.sheets.find((sheet) => sheet.name === sheetName)
    : spreadsheet.sheets[0];
  const rows = sheet ? sheet.rows : [];
  return rows;
}

function readSpreadsheetRangeState(
  spreadsheet: Spreadsheet,
  rangeState: SpreadsheetRangeState,
): string[][] | null {
  try {
    const range = convertStateToSpreadsheetRange(rangeState);
    return readSpreadsheetRange(spreadsheet, range);
  } catch (err) {
    if (err instanceof UiValidationError) {
      newrelic.noticeError(err);
      return null;
    }

    throw err;
  }
}

function readSpreadsheetRange(
  spreadsheet: Spreadsheet,
  range: T.SpreadsheetRange,
): string[][] {
  const sheet = spreadsheet.sheets.find(
    (sheet) => sheet.name === range.sheetName,
  );

  if (!sheet) {
    throw new UiValidationError("Could not find worksheet");
  }

  return _.range(range.fromRowIndex, range.toRowIndex + 1).map((rowIndex) =>
    _.range(range.fromColumnIndex, range.toColumnIndex + 1).map(
      (columnIndex) => {
        try {
          const cellValue = sheet.rows[rowIndex][columnIndex];
          return cellValue ? cellValue + "" : "";
        } catch (error) {
          newrelic.noticeError(
            getErrorMessage("Issue with worksheet formatting"),
          );
          newrelic.noticeError(getErrorMessage(error));
          throw new UiValidationError("Issue with worksheet formatting");
        }
      },
    ),
  );
}

function convertPricingTableColumnToState(
  column: T.PricingTableColumn,
): PricingTableColumnState {
  switch (column.kind) {
    case "interest-rate":
      return {
        id: column.id,
        kind: "interest-rate",
        rateLockPeriodDays: null,
      };
    case "ignore":
      return {
        id: column.id,
        kind: "ignore",
        rateLockPeriodDays: null,
      };
    case "price":
      return {
        id: column.id,
        kind: "price",
        rateLockPeriodDays: column.rateLockPeriodDays,
      };
  }
}

function convertStateToPricingTableColumn(
  state: PricingTableColumnState,
): T.PricingTableColumn {
  switch (state.kind) {
    case "interest-rate":
      return {
        id: state.id,
        kind: "interest-rate",
      };
    case "ignore":
      return {
        id: state.id,
        kind: "ignore",
      };
    case "price":
      if (state.rateLockPeriodDays === null) {
        throw new UiValidationError(
          "Pricing table column must have a rate lock period days number defined",
        );
      }

      return {
        id: state.id,
        kind: "price",
        rateLockPeriodDays: state.rateLockPeriodDays,
      };
  }
}

function newPricingTableColumnState(): PricingTableColumnState {
  return {
    id: generateSafeId() as T.PricingTableColumnId,
    kind: "price",
    rateLockPeriodDays: null,
  };
}

function parseUnpivotedTable(
  rows: readonly string[][],
  state: SpreadsheetUnpivotedPricingTablesItemState,
): T.PriceScenario[] {
  const firstRow = state.hasHeader ? 1 : 0;
  return rows.slice(firstRow).map((r) => {
    const rateString = r[state.rateColumn];
    const priceString = r[state.priceColumn];
    const lockString = r[state.lockColumn];
    const productString = r[state.productNameColumn];
    if (!rateString) {
      throw new UiValidationError(
        `Rates column is missing for a row with contents ${r.join(",")}`,
      );
    }
    if (!priceString) {
      throw new UiValidationError(
        `Price column is missing for a row with contents ${r.join(",")}`,
      );
    }
    if (!lockString) {
      throw new UiValidationError(
        `Lock column is missing for a row with contents ${r.join(",")}`,
      );
    }
    if (!productString) {
      throw new UiValidationError(
        `Product column is missing for a row with contents ${r.join(",")}`,
      );
    }

    const interestRate = parseInterestRate(rateString, state.rateFormat);
    const price = "" + String(parsePrice(priceString, state.priceFormat));
    const lockDays = parseRateLockPeriodDays(lockString);
    return {
      rate: "" + String(interestRate),
      lockPeriod: {
        count: "" + String(lockDays),
        unit: "days",
      },
      price: "" + String(price),
    };
  });
}

function parsePricingTable(
  rows: readonly string[][],
  priceFormat: T.MortgagePriceFormat,
  rateFormat: T.MortgageRateFormat,
): T.PriceScenario[] {
  // Note: keep compatible with server/src/api/rate_sheets.rs#parse_pricing_table
  if (rows.length < 1) {
    throw new UiValidationError(
      "Cannot extract a pricing table from a table with no header row",
    );
  }

  const headerRow = rows[0];
  const dataRows = rows.slice(1);

  const columnDefinitions = headerRow.map((headerText, columnIndex) => {
    const columnId = (String(columnIndex) + "") as T.PricingTableColumnId;

    if (columnIndex === 0) {
      return {
        kind: "interest-rate" as const,
        id: columnId,
      };
    }

    return {
      kind: "price" as const,
      id: columnId,
      rateLockPeriodDays: parseRateLockPeriodDays(headerText),
    };
  });

  return parseHeaderlessPricingTable(
    "columns",
    columnDefinitions,
    dataRows,
    priceFormat,
    rateFormat,
  );
}

function parseRateLockPeriodDays(text: string): number {
  // Note: keep compatible with server/src/api/rate_sheets.rs#parse_rate_lock_period

  const match1 = /^\D*(\d+)[\s-]*(?:day).*$/i.exec(text);
  const match2 = /^\s*(\d+)\s*$/i.exec(text);
  const match = match1 || match2;
  const parsed = match ? +match[1] : NaN;

  if (isNaN(parsed)) {
    throw new UiValidationError(
      "Could not interpret text as a rate lock period: " + JSON.stringify(text),
    );
  }

  return parsed;
}

function parseHeaderlessPricingTable(
  orientation: T.PricingTableOrientation,
  rowOrColumnDefinitions: readonly T.PricingTableColumn[],
  rows: readonly string[][],
  priceFormat: T.MortgagePriceFormat,
  rateFormat: T.MortgageRateFormat,
): T.PriceScenario[] {
  // Note: keep compatible with server/src/api/rate_sheets.rs#parse_headerless_pricing_table

  const maybeTransposedRows =
    orientation === "columns" ? rows : transposeArrayOfArrays(rows);

  // Skip empty rows like the backend does.
  const nonEmptyRows = maybeTransposedRows.filter(
    (row) => !row.every((c) => c.trim() === ""),
  );
  return nonEmptyRows.flatMap((row) => {
    let interestRate: number | null = null;
    const prices: [number, number][] = [];
    if (rowOrColumnDefinitions.length !== row.length) {
      throw new UiValidationError(
        `Definition count is (${rowOrColumnDefinitions.length}) but data count is (${row.length}).`,
      );
    }
    row.forEach((cell, columnIndex) => {
      const columnDef: T.PricingTableColumn =
        rowOrColumnDefinitions[columnIndex];
      switch (columnDef.kind) {
        case "ignore":
          return;
        case "interest-rate":
          interestRate = parseInterestRate(cell, rateFormat);
          return;
        case "price": {
          const price = parsePrice(cell, priceFormat);
          if (price !== null) {
            prices.push([columnDef.rateLockPeriodDays, price]);
          }
          return;
        }
      }
    });

    if (interestRate === null) {
      throw new UiValidationError(
        "Pricing table row is missing an interest rate",
      );
    }

    return prices.map(([rateLockPeriodDays, price]) => ({
      rate: "" + String(interestRate!),
      lockPeriod: {
        count: "" + String(rateLockPeriodDays),
        unit: "days",
      },
      price: "" + String(price),
    }));
  });
}

function parseInterestRate(
  text: string,
  rateFormat: T.MortgageRateFormat,
): number {
  // Note: keep compatible with server/src/api/rate_sheets.rs#parse_interest_rate

  let trimmed = text.trim();

  if (trimmed.endsWith("%")) {
    trimmed = trimmed.substring(0, trimmed.length - 1).trim();
  }
  if (trimmed === "") {
    throw new UiValidationError("No interest rate is present for this cell.");
  }

  let parsed = +trimmed;

  if (isNaN(parsed)) {
    throw new UiValidationError(
      "Could not parse interest rate: " + JSON.stringify(text),
    );
  }

  if (rateFormat === "decimal") {
    parsed *= 100;
  }

  return parsed;
}

export function parsePrice(
  text: string,
  format: T.MortgagePriceFormat,
): number | null {
  // Note: keep compatible with server/src/api/rate_sheets.rs#parse_price

  let trimmed = text.trim().toLowerCase();

  if (
    !trimmed ||
    trimmed === "na" ||
    trimmed === "n/a" ||
    trimmed === "#n/a" ||
    trimmed === "%" ||
    trimmed === "-" ||
    trimmed === "--"
  ) {
    return null;
  }

  if (trimmed.endsWith("%")) {
    trimmed = trimmed.substring(0, trimmed.length - 1).trim();
  }

  let absolutePrice = null;

  switch (format) {
    case "absolute": {
      const parsed = +trimmed;

      if (isNaN(parsed)) {
        throw new UiValidationError(
          "Could not parse price: " + JSON.stringify(text),
        );
      }

      absolutePrice = parsed;
      break;
    }
    case "relative": {
      let relativePrice = null;
      if (trimmed.startsWith("(") && trimmed.endsWith(")")) {
        const parsed = +trimmed.substring(1, trimmed.length - 1);

        if (isNaN(parsed) || parsed < 0) {
          throw new UiValidationError(
            "Could not parse price: " + JSON.stringify(text),
          );
        }

        relativePrice = -parsed;
      } else {
        const parsed = +trimmed;

        if (isNaN(parsed)) {
          throw new UiValidationError(
            "Could not parse price: " + JSON.stringify(text),
          );
        }

        relativePrice = parsed;
      }

      absolutePrice = 100 - relativePrice;
      break;
    }
  }

  if (absolutePrice < 0 || absolutePrice > 200) {
    throw new UiValidationError(
      `Price ${trimmed} is not in expected range (0 to 200 after conversion to absolute). Wrong price format?`,
    );
  }

  absolutePrice = Math.round(1e6 * absolutePrice) / 1e6;

  return absolutePrice;
}

function getPdfItemKindName(kind: PdfRateSheetItemState["kind"]): string {
  switch (kind) {
    case "check":
      return "Check data at location";
    case "pricing-table":
      return "Extract pricing table";
    case "headerless-pricing-table":
      return "Extract headerless pricing table";
  }
}

function getSpreadsheetItemKindName(
  kind: SpreadsheetRateSheetItemState["kind"],
): string {
  switch (kind) {
    case "check":
      return "Check data at location";
    case "pricing-table":
      return "Extract pricing table";
    case "headerless-pricing-table":
      return "Extract headerless pricing table";
    case "unpivoted-pricing-tables":
      return "Extract unpivoted pricing tables";
  }
}

function getPricingTableOrientationName(
  orientation: T.PricingTableOrientation,
): string {
  switch (orientation) {
    case "columns":
      return "Columns (default)";
    case "rows":
      return "Rows";
  }
}

function getPricingTableColumnKindName(
  kind: PricingTableColumnState["kind"],
): string {
  switch (kind) {
    case "interest-rate":
      return "Interest Rate";
    case "ignore":
      return "Ignore";
    case "price":
      return "Price";
  }
}

/* takes an array of rows of cell values, and transposes it into an
  array of columns of cell values, and vice versa. For example:

  [[1,2,3], [4,5,6]] <-> [[1,4], [2,5], [3,6]]
 */
function transposeArrayOfArrays<T>(outerArray: readonly T[][]): T[][] {
  const rows = outerArray;
  const numColumns = (rows[0] || []).length;

  const newColumns: T[][] = Array(numColumns)
    .fill(undefined)
    .map(() => []);

  for (const row of rows) {
    row.forEach((cellValue, columnIndex) => {
      newColumns[columnIndex].push(cellValue);
    });
  }

  return newColumns;
}
