import React from "react";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import * as Types from "types/engine-types";
import {
  FieldValueEditor,
  FieldValueState,
} from "design/organisms/field-value-editor";
import { Setter } from "features/utils";
import styled from "styled-components";
import {
  expandedConfigSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import { SmallText } from "design/atoms/typography";
import color from "design/subatomics/colors";

// Duration format
interface DurationFormatCustomProps {
  inputRef: (instance: NumberFormat<object> | null) => void;
  onChange: (event: { target: { value: string } }) => void;
  minimum: string | null;
  maximum: string | null;
}

export function DurationFormatCustom({
  inputRef,
  onChange,
  minimum,
  maximum,
  ...other
}: DurationFormatCustomProps) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
      isAllowed={(values) => {
        let greaterThanOrEqualToMinimum = true;
        let lessThanOrEqualToMaximum = true;

        if (minimum) {
          greaterThanOrEqualToMinimum =
            parseFloat(values.value) >= parseFloat(minimum);
        }

        if (maximum) {
          lessThanOrEqualToMaximum =
            parseFloat(maximum) <= parseFloat(values.value);
        }

        const isBlank = values.value === "";

        return (
          (greaterThanOrEqualToMinimum && lessThanOrEqualToMaximum) || isBlank
        );
      }}
    />
  );
}

// Number format
interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat<object> | null) => void;
  onChange: (event: { target: { value: string } }) => void;
  minimum: string | null;
  maximum: string | null;
  precision: number;
  numberFieldStyle: Types.NumberFieldStyle;
}

export function NumberFormatCustom({
  inputRef,
  onChange,
  minimum,
  maximum,
  precision,
  numberFieldStyle,
  ...other
}: NumberFormatCustomProps) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      fixedDecimalScale
      decimalScale={precision}
      isAllowed={(values) => {
        // TODO  enforcement of min/max here is not complete. Eg. if min > 10, user won't be able to input at all
        let greaterThanOrEqualToMinimum = true;
        let lessThanOrEqualToMaximum = true;

        if (minimum) {
          greaterThanOrEqualToMinimum =
            parseFloat(values.value) >= parseFloat(minimum);
        }

        if (maximum) {
          lessThanOrEqualToMaximum =
            parseFloat(maximum) <= parseFloat(values.value);
        }

        const isBlank = values.value === "";

        return (
          (greaterThanOrEqualToMinimum && lessThanOrEqualToMaximum) || isBlank
        );
      }}
    />
  );
}

export default React.memo(function Field({
  showDescription = false,
  field,
  fieldState,
  defaultValue,
  parentState,
  required,
  showErrors,
  setState,
  margin,
  disabled = false,
}: {
  showDescription?: boolean;
  field: Types.BaseFieldDefinition;
  fieldState: FieldValueState | null;
  defaultValue?: Types.DefaultFieldValue | null;
  parentState: FieldValueState | null;
  required?: boolean;
  showErrors: boolean;
  setState: Setter<FieldValueState>;
  margin?: "normal" | "dense";
  disabled?: boolean | undefined;
}) {
  const config = useSelector(expandedConfigSelector);
  const objectDetails = useSelector(objectDetailsMapSelector);

  if (!field || !fieldState) {
    return <></>;
  }

  return (
    <div
      title={field.description || ""}
      className="field"
      data-field-id={field.id}
      data-field-name={field.name.match(/[^\s\\]/g)?.join("")}
    >
      <FieldDisplay className={"fieldDisplay"} margin={margin}>
        {field.valueType.type === "header" ? (
          <HeaderDisplay className="header">{field.name}</HeaderDisplay>
        ) : (
          <>
            <FieldValueEditor
              disabled={disabled}
              state={fieldState}
              defaultValue={defaultValue}
              required={required}
              showErrors={showErrors}
              setState={setState}
              parentState={parentState}
              valueType={field.valueType}
              label={field.name}
              margin={margin}
              config={config}
              objectDetails={objectDetails}
            />
            {field.description && showDescription && (
              <SmallText
                className="hide-for-desktop"
                style={{
                  margin: "-12px 0 12px 4px",
                  display: "block",
                  color: color({ color: "gray", shade: 5 }),
                }}
              >
                {field.description}
              </SmallText>
            )}
          </>
        )}
      </FieldDisplay>
    </div>
  );
});

const FieldDisplay = styled.div<{ margin?: "normal" | "dense" }>`
  box-sizing: border-box;
  margin: 0;

  &:first-child .header {
    margin-top: 12px;
  }

  &.fieldDisplay > div > div > div > input {
    width: auto;
  }
`;

const HeaderDisplay = styled.div`
  font-weight: bold;
  margin: 30px 0px 12px 0px;
  color: #777;
  border-bottom: 1px solid #ccc;
`;
