import React, { useEffect } from "react";
import { Prompt, useHistory } from "react-router";

const MESSAGE =
  "You have unsaved changes. Are you sure you want to discard those changes?";

export default React.memo(function UnloadPrompt({
  when,
  nextUrl,
}: {
  when: boolean; // When to block navigation and prompt
  nextUrl?: string | null; // Pass in a URL to trigger a navigation without prompt
}) {
  const history = useHistory();

  useEffect(() => {
    if (nextUrl) {
      history.push(nextUrl);
      return;
    }

    const listener = (event: BeforeUnloadEvent) => {
      if (when) {
        event.preventDefault();
        event.returnValue = MESSAGE;
        return MESSAGE;
      }

      return undefined;
    };
    window.addEventListener("beforeunload", listener, false);

    return () => {
      window.removeEventListener("beforeunload", listener, false);
    };
  }, [when, nextUrl, history]);

  return <Prompt when={when && !nextUrl} message={MESSAGE} />;
});
