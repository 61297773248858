import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BaseFieldDefinition } from "types/engine-types";
import {
  setColumnsValues,
  dynamicFilterSelector,
} from "features/pricing-summaries";
import {
  setMinRate,
  setMaxRate,
  setMinPrice,
  setMaxPrice,
} from "features/pricing-summaries";
import { FilterWrapper } from "./styles";
import RangeInput from "design/atoms/range-input";

export default React.memo(
  ({
    field,
    filterType,
  }: {
    field: BaseFieldDefinition;
    filterType: string;
  }) => {
    const dispatch = useDispatch();
    const dynamicFilters = useSelector(dynamicFilterSelector);
    const extraColumn = dynamicFilters.dynamicColumns.find(
      (filter) => filter.id === field.id,
    );

    let prefix = null;
    let suffix = null;

    if (field.valueType.type === "number") {
      switch (field.valueType.style) {
        case "plain":
          break;
        case "percent":
          suffix = "%";
          break;
        case "dollar":
          prefix = "$";
          break;
      }
    }

    let adornment: "suffix" | "prefix" | null = null;
    let adornmentText: string | undefined = undefined;

    if (suffix) {
      adornment = "suffix";
      adornmentText = suffix;
    }
    if (prefix) {
      adornment = "prefix";
      adornmentText = prefix;
    }

    let stateValueMin: string | null | undefined = null;
    let stateValueMax: string | null | undefined = null;

    switch (filterType) {
      case "rateField":
        stateValueMin =
          dynamicFilters.filtersObj.adjustedRateFieldId?.values.min;
        stateValueMax =
          dynamicFilters.filtersObj.adjustedRateFieldId?.values.max;
        break;
      case "priceField":
        stateValueMin =
          dynamicFilters.filtersObj.adjustedPriceFieldId?.values.min;
        stateValueMax =
          dynamicFilters.filtersObj.adjustedPriceFieldId?.values.max;
        break;
      default:
        stateValueMin = dynamicFilters.dynamicColumns.find(
          (column) => column.id === field.id,
        )?.columnMin;
        stateValueMax = dynamicFilters.dynamicColumns.find(
          (column) => column.id === field.id,
        )?.columnMax;
    }

    const currentMin = extraColumn ? extraColumn.columnMin : null;
    const currentMax = extraColumn ? extraColumn.columnMax : null;

    const [inputMin, setInputMin] = useState<string>(stateValueMin ?? "");
    const [inputMax, setInputMax] = useState<string>(stateValueMax ?? "");

    useEffect(() => {
      if (filterType === "rateField") {
        dispatch(setMinRate(inputMin));
      } else if (filterType === "priceField") {
        dispatch(setMinPrice(inputMin));
      } else {
        const newColumnValues = dynamicFilters.dynamicColumns.map((filter) => {
          if (filter.id === field.id) {
            return {
              id: field.id,
              columnMin: inputMin ?? null,
              columnMax: currentMax ?? null,
            };
          } else {
            return filter;
          }
        });

        dispatch(setColumnsValues(newColumnValues));
      }
      // eslint wants to included dynamicFilters.dynamicColumns as a dependancy which causes an infinite rerender
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMax, inputMin, dispatch, filterType, field.id]);

    useEffect(() => {
      if (filterType === "rateField") {
        dispatch(setMaxRate(inputMax));
      } else if (filterType === "priceField") {
        dispatch(setMaxPrice(inputMax));
      } else {
        const newColumnValues = dynamicFilters.dynamicColumns.map((filter) => {
          if (filter.id === field.id) {
            return {
              id: field.id,
              columnMin: currentMin ?? null,
              columnMax: inputMax ?? null,
            };
          } else {
            return filter;
          }
        });
        dispatch(setColumnsValues(newColumnValues));
      }
      // eslint wants to included dynamicFilters.dynamicColumns as a dependancy which causes an infinite rerender
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMin, inputMax, dispatch, filterType, field.id]);

    return (
      <>
        <FilterWrapper>
          <RangeInput
            minValue={inputMin}
            minSetter={setInputMin}
            maxValue={inputMax}
            maxSetter={setInputMax}
            adornment={adornment}
            adornmentText={adornmentText}
          />
        </FilterWrapper>
      </>
    );
  },
);
