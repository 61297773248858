import opacities from "design/subatomics/opacities";
import { palette } from "../colors";

const shadowOptions = {
  titles: `.5px .5px 0px rgba(${palette.black}, ${opacities.nearlyTransparent})`,
  text: `.5px .5px 0px rgba(${palette.black}, ${opacities.nearlyTransparent})`,
  controls: `1px 1px 1px rgba(${palette.black}, ${opacities.nearlyTransparent})`,
  dropdowns: `1px 1px 4px rgba(${palette.black}, ${opacities.nearlyTransparent})`,
  modals: `4px 4px 16px rgba(${palette.black}, ${opacities.nearlyTransparent})`,
};

export default shadowOptions;
